import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
	Box,
	Step,
	StepDescription,
	StepIndicator,
	StepNumber,
	Stepper,
	StepStatus,
	StepTitle,
} from '@chakra-ui/react'

import Button from '@UI/Button/Button'
import Footer from '@UI/Footer/Footer'
import Switch from '@UI/Switch/Switch'

import useAuth from '@Hooks/useAuth'

type DeviceConfigurationProps = {
	activeStep: number
	setActiveStep: (step: number) => void
	bracketInstalled: boolean
	setBracketInstalled: (checked: boolean) => void
	siteId: number
	installationId: number
}

const DeviceConfiguration = ({
	activeStep,
	setActiveStep,
	bracketInstalled,
	setBracketInstalled,
	siteId,
	installationId,
}: DeviceConfigurationProps) => {
	const navigate = useNavigate()
	const { t } = useTranslation('panels', {
		keyPrefix: 'siteInstallations.cameraCalibration',
	})

	const { isAdmin } = useAuth()

	const handleToggleBracketInstalled = ({
		target: { checked },
	}: {
		target: { checked: boolean }
	}) => {
		setBracketInstalled(checked)
	}

	const handleStartCalibration = () => {
		setActiveStep(1)
	}

	const handleCancel = () =>
		navigate(`/${siteId}/installations/${installationId}/camera`)

	return (
		<>
			<Box my={4}>
				<Stepper
					size='sm'
					orientation='vertical'
					index={activeStep}
					variant='calibration'
					colorScheme='primary'
				>
					<Step>
						<StepIndicator>
							<StepStatus active={<StepNumber />} />
						</StepIndicator>
						<Box w='fit-content'>
							<StepTitle>{t('deviceStepTitle')}</StepTitle>
							<StepDescription>{t('deviceStepDescription')}</StepDescription>
							{isAdmin && (
								<Switch
									label={t('bracketCalibration.installedToggle')}
									onChange={handleToggleBracketInstalled}
									defaultChecked={bracketInstalled}
								/>
							)}
						</Box>
					</Step>
				</Stepper>
			</Box>
			<Footer>
				<Button
					label={t('buttons.startCalibration')}
					testId='start-calibration'
					variant='outline'
					onClick={handleStartCalibration}
				/>
				<Button
					label={t('buttons.cancel')}
					testId='cancel'
					onClick={handleCancel}
				/>
			</Footer>
		</>
	)
}

export default DeviceConfiguration
