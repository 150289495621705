import type { Detection } from '@Store/types'

import BirdMarkerIcon from './BirdMarkerIcon'
import DroneMarkerIcon from './DroneMarkerIcon'
import HelicopterMarkerIcon from './HelicopterMarkerIcon'
import PlaneMarkerIcon from './PlaneMarkerIcon'
import UnknownMarkerIcon from './UnknownMarkerIcon'
import ControllerMarkerIcon from './ControllerMarkerIcon'

export type DetectionMarkerIconProps = {
	iconType: Detection['classification']
	color: string
}

const DetectionMarkerIcon = ({ iconType, color }: DetectionMarkerIconProps) => {
	switch (iconType) {
		case 'drone':
			return <DroneMarkerIcon color={color} />
		case 'bird':
			return <BirdMarkerIcon color={color} />
		case 'helicopter':
			return <HelicopterMarkerIcon color={color} />
		case 'plane':
			return <PlaneMarkerIcon color={color} />
		case 'controller':
			return <ControllerMarkerIcon color={color} />
		default:
			return <UnknownMarkerIcon color={color} />
	}
}

export default DetectionMarkerIcon
