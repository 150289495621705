import { baseApi } from '@Store/baseApi'
import { type CoreApiError, formatApiErrors } from '@Store/utils/errorUtils'

import type { Radar, Site } from '../types'
import type { RadarSchema } from '@Components/Forms/RadarForm/RadarForm.schema'

type GetRadarRequest = { siteId: Site['id']; sensorId: Radar['id'] }
type CreateRadarRequest = RadarSchema & { siteId: Site['id'] }
type UpdateRadarRequest = RadarSchema & { id: Radar['id']; siteId: Site['id'] }
type DeleteRadarRequest = { siteId: Site['id']; sensorId: Radar['id'] }
type ToggleRadarRequest = {
	siteId: Site['id']
	sensorId: Radar['id']
	active: boolean
}
type RestartAllRadarsRequest = {
	siteId: Site['id']
}

export const radarsApi = baseApi
	.enhanceEndpoints({
		addTagTypes: ['Radar'],
	})
	.injectEndpoints({
		endpoints: (builder) => ({
			getRadar: builder.query<Radar, GetRadarRequest>({
				query: ({ siteId, sensorId }) => ({
					url: `/api/sites/${siteId}/radars/${sensorId}`,
				}),
				providesTags: ['Radar'],
				keepUnusedDataFor: 0,
				transformResponse: (response: { radar: Radar }) => response.radar,
			}),
			getRadars: builder.query<Radar[], { siteId: number }>({
				query: ({ siteId }) => ({
					url: `/api/sites/${siteId}/radars`,
				}),
				providesTags: ['Radar'],
				keepUnusedDataFor: 0,
				transformResponse: (response: { radars: Radar[] }) => response.radars,
			}),
			createRadar: builder.mutation<Radar, CreateRadarRequest>({
				query: ({ siteId, ...radar }) => ({
					url: `/api/sites/${siteId}/radars`,
					method: 'POST',
					body: {
						radar,
					},
				}),
				transformErrorResponse: (response: CoreApiError) =>
					formatApiErrors<Radar>(response),
				invalidatesTags: ['Radar'],
			}),
			updateRadar: builder.mutation<Radar, UpdateRadarRequest>({
				query: ({ id: sensorId, siteId, ...radar }) => ({
					url: `/api/sites/${siteId}/radars/${sensorId}`,
					method: 'PUT',
					body: {
						radar,
					},
				}),
				transformErrorResponse: (response: CoreApiError) =>
					formatApiErrors<Radar>(response),
				invalidatesTags: ['Radar'],
			}),
			toggleRadar: builder.mutation<Radar, ToggleRadarRequest>({
				query: ({ siteId, sensorId, active }) => ({
					url: `/api/sites/${siteId}/radars/${sensorId}`,
					method: 'PATCH',
					body: {
						radar: {
							status: active ? 'transmitting' : 'idle',
						},
					},
				}),
				transformResponse: (response: { radar: Radar }) => response.radar,
				invalidatesTags: ['Radar'],
			}),
			deleteRadar: builder.mutation<{ message: string }, DeleteRadarRequest>({
				query: ({ siteId, sensorId }) => ({
					url: `/api/sites/${siteId}/radars/${sensorId}`,
					method: 'DELETE',
				}),
				invalidatesTags: ['Radar'],
			}),
			restartSiteRadars: builder.mutation<string, RestartAllRadarsRequest>({
				query: ({ siteId }) => ({
					url: `/api/sites/${siteId}/restart_sensors`,
					method: 'POST',
					body: {
						sensor_type: 'radars',
					},
				}),
				invalidatesTags: ['Radar'],
			}),
		}),
	})

export const {
	useGetRadarQuery,
	useGetRadarsQuery,
	useCreateRadarMutation,
	useUpdateRadarMutation,
	useToggleRadarMutation,
	useDeleteRadarMutation,
	useRestartSiteRadarsMutation,
} = radarsApi
