// React
import { useCallback, useLayoutEffect, useState } from 'react'
import { useMap, useMapEvents } from 'react-leaflet'
// Store
import { useAppSelector } from '@Store/index'
import { selectLeftPanelWidth } from '@/store/ui/uiSlice'
// Types
import type { LatLng } from 'leaflet'

type useCenterMapArgs = {
	latitude: number | undefined
	longitude: number | undefined
	zoomLevel?: number
	animation?: boolean
}

const useCenterMap = ({
	latitude,
	longitude,
	zoomLevel,
	animation = true,
}: useCenterMapArgs) => {
	const map = useMap()

	const leftPanelWidth = useAppSelector(selectLeftPanelWidth)
	const [originalMapCenter] = useState<LatLng>(map?.getCenter())
	const [originalZoomLevel] = useState<number>(map?.getZoom())

	const handleSetMapCenter = useCallback(() => {
		if (latitude === undefined || longitude === undefined) return
		if (isNaN(latitude) || isNaN(longitude)) return

		// convert lat long center to x, y in pixels
		const centerPx = map.latLngToContainerPoint([latitude, longitude])
		// convert x + leftpanel, y in pixels to lat long center
		const centerInCoords = map.containerPointToLatLng([
			centerPx.x - leftPanelWidth / 2,
			centerPx.y,
		])
		if (animation) {
			map.flyTo(centerInCoords, zoomLevel)
		} else {
			map.panTo(centerInCoords)
		}
	}, [map, latitude, longitude, leftPanelWidth, zoomLevel, animation])

	const handleRestoreMapCenter = useCallback(() => {
		if (animation) {
			map.flyTo(originalMapCenter, originalZoomLevel)
		} else {
			map.panTo(originalMapCenter)
		}
	}, [map, originalMapCenter, originalZoomLevel, animation])

	// Reposition map center
	useLayoutEffect(() => {
		handleSetMapCenter()
		return () => {
			// Restore original center & zoom on unmount
			handleRestoreMapCenter()
		}
	}, [
		latitude,
		longitude,
		handleRestoreMapCenter,
		handleSetMapCenter,
		zoomLevel,
	])

	useMapEvents({
		zoomend: () => {
			handleSetMapCenter()
		},
	})
}

export default useCenterMap
