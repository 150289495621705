import { defineStyleConfig } from '@chakra-ui/react'
import FIGMA from '@Theme/DRO/_FIGMA'

const FormErrorStyles = defineStyleConfig({
	baseStyle: {
		icon: {
			_dark: {
				'--form-error-color': FIGMA.colors.error,
			},
		},
		text: {
			_dark: {
				'--form-error-color': FIGMA.colors.error,
			},
		},
	},
})

export const FormError = {
	...FormErrorStyles,
}
