import {
	Table as ChakraTable,
	TableCaption,
	TableContainer,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	Flex,
} from '@chakra-ui/react'

import { BsSortDown, BsSortDownAlt } from 'react-icons/bs'

import { flexRender, type Table } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

type TanStackTableProps<TData> = {
	table: Table<TData>
	sortableColumns?: unknown[]
	testId?: string
}

const TanStackTable = <TData = unknown,>({
	table,
	sortableColumns = [],
	testId,
}: TanStackTableProps<TData>) => {
	const tableHeaderGroup = table.getHeaderGroups()
	const tableRows = table.getRowModel().rows
	const hasRows = tableRows.length > 0

	const { t } = useTranslation('app', { keyPrefix: 'global.tables' })

	return (
		<TableContainer>
			<ChakraTable data-testid={testId}>
				<Thead>
					{tableHeaderGroup.map((headerGroup) => (
						<Tr key={headerGroup.id}>
							{headerGroup.headers.map((header) => {
								const headerId = header.id
								const isSortable = sortableColumns.includes(headerId)
								// TODO: add .d.ts file to extend what we can include in meta
								const { meta } = header.column.columnDef as any
								return (
									<Th
										key={header.id}
										onClick={
											isSortable
												? header.column.getToggleSortingHandler()
												: undefined
										}
										style={{ cursor: isSortable ? 'pointer' : 'auto' }}
									>
										<Flex
											gap='4px'
											alignItems='center'
											justifyContent={
												meta?.isCentered ? 'center' : 'flex-start'
											}
										>
											{flexRender(
												header.column.columnDef.header,
												header.getContext()
											)}
											{isSortable &&
												({
													asc: <BsSortDownAlt />,
													desc: <BsSortDown />,
												}[header.column.getIsSorted() as string] ??
													null)}
										</Flex>
									</Th>
								)
							})}
						</Tr>
					))}
				</Thead>
				{!hasRows && <TableCaption>{t('noData')}</TableCaption>}
				<Tbody>
					{tableRows.map((row) => (
						<Tr key={row.id}>
							{row.getVisibleCells().map((cell) => {
								const { meta } = cell.column.columnDef as any
								return (
									<Td data-testid={`event-${cell.column.id}`} key={cell.id}>
										<Flex
											justifyContent={
												meta?.isCentered ? 'center' : 'flex-start'
											}
										>
											{flexRender(
												cell.column.columnDef.cell,
												cell.getContext()
											)}
										</Flex>
									</Td>
								)
							})}
						</Tr>
					))}
				</Tbody>
			</ChakraTable>
		</TableContainer>
	)
}

export default TanStackTable
