import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

// Form
import { useNavigate, useLocation } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import type { TypeOf } from 'zod'

import Field from '@Components/FormElements'
import { FormButtons } from '@Components/FormElements/FormButtons/FormButtons'
import FormHeader from '../FormHeader'
import FormWrapper from '@Components/FormElements/FormWrapper/FormWrapper'
import ErrorBoundary from '@Components/App/ErrorHandling/ErrorBoundary'

import {
	useGetRadarMaskQuery,
	useCreateRadarMaskMutation,
	useUpdateRadarMaskMutation,
} from '@Store/radarMasks/radarMasksApi'
import { useGetRadarQuery } from '@Store/radars/radarsApi'
import { useAppDispatch } from '@Store/index'
import { updateSensorPreview } from '@Store/ui/uiSlice'
import { skipToken } from '@reduxjs/toolkit/query'

import {
	radarMaskFormSchema,
	ADD_RADAR_MASK_DEFAULT_VALUES,
	type DefaultValues,
} from './RadarMaskForm.schema'
import type { RadarMaskZone } from '@Store/types'
import { getCoordinatesFromSector } from '@Utils/turfUtils'

type RadarMaskFormProps = {
	siteId: number
	sensorId: number
	maskId?: number
}

const RadarMaskForm = ({ siteId, sensorId, maskId }: RadarMaskFormProps) => {
	const { t } = useTranslation('forms', { keyPrefix: 'radarMaskForm' })
	// Looks for props passed through from navigate() via quick actions
	const { state } = useLocation()

	const isQuickAction = !!state?.quickActionsDefaultValues
	const isEditForm = !!maskId

	const {
		isLoading,
		isError,
		refetch,
		isSuccess,
		data: radarMask,
	} = useGetRadarMaskQuery(maskId ? { siteId, sensorId, maskId } : skipToken, {
		refetchOnMountOrArgChange: true,
	})

	const { radarReach } = useGetRadarQuery(
		{ siteId, sensorId },
		{
			selectFromResult: ({ data }) => ({
				radarReach: data?.reach_max,
			}),
		}
	)

	// Use reach from radar as default max range
	const defaultValuesWithRadarReach = {
		...ADD_RADAR_MASK_DEFAULT_VALUES,
		range_max:
			radarReach && radarReach >= 20
				? radarReach
				: ADD_RADAR_MASK_DEFAULT_VALUES.range_max,
	}

	const addRadarMaskDefaultValues = isQuickAction
		? state.quickActionsDefaultValues
		: defaultValuesWithRadarReach

	const defaultValues = isEditForm ? radarMask : addRadarMaskDefaultValues

	return (
		<FormWrapper
			entity={t('entity')}
			isEditForm={isEditForm}
			isLoading={isLoading}
			isError={isError}
			isSuccess={isSuccess}
			refetch={refetch}
		>
			{defaultValues && (
				<Form
					isEditForm={isEditForm}
					defaultValues={defaultValues}
					siteId={siteId}
					sensorId={sensorId}
					maskId={maskId}
				/>
			)}
		</FormWrapper>
	)
}

const Form = ({
	defaultValues,
	isEditForm,
	siteId,
	sensorId,
	maskId,
}: {
	defaultValues: RadarMaskZone | DefaultValues
	isEditForm: boolean
	siteId: number
	sensorId: number
	maskId?: number
}) => {
	const navigate = useNavigate()
	const { t } = useTranslation('forms', { keyPrefix: 'radarMaskForm' })

	type Schema = TypeOf<typeof radarMaskFormSchema>

	const methods = useForm<Schema>({
		resolver: zodResolver(radarMaskFormSchema),
		defaultValues,
	})
	const {
		register,
		formState: { errors, isSubmitting, isDirty },
		handleSubmit,
		setValue,
		watch,
	} = methods

	const radarMaskRoute = `/${siteId}/radar/${sensorId}/masks`

	const [createRadarMask] = useCreateRadarMaskMutation()
	const [updateRadarMask] = useUpdateRadarMaskMutation()

	const { radarData } = useGetRadarQuery(
		{ siteId, sensorId },
		{
			selectFromResult: ({ data }) => ({ radarData: data }),
		}
	)

	const handleSave = async (data: Schema) => {
		const mask = { siteId, sensorId, ...data }
		if (radarData?.radar_type === 'echo_shield') {
			mask.coordinates = getCoordinatesFromSector({
				latitude: radarData?.latitude,
				longitude: radarData?.longitude,
				direction: radarData?.direction,
				azimuth_min: data.azimuth_min,
				azimuth_max: data.azimuth_max,
				range_min: data.range_min,
				range_max: data.range_max,
			})
		}

		try {
			if (isEditForm && maskId) {
				await updateRadarMask({ maskId, ...mask }).unwrap()
			} else {
				await createRadarMask(mask).unwrap()
			}
			navigate(radarMaskRoute)
		} catch (error) {
			console.error('Update radar mask form error', error)
		}
	}

	const handleCancel = () => navigate(radarMaskRoute)

	const [azimuth_min, azimuth_max, range_min, range_max] = watch([
		'azimuth_min',
		'azimuth_max',
		'range_min',
		'range_max',
	])

	const dispatch = useAppDispatch()
	useEffect(() => {
		dispatch(
			updateSensorPreview({
				sensorId,
				maskId,
				azimuth_min,
				azimuth_max,
				range_min,
				range_max,
			})
		)
		return () => {
			dispatch(updateSensorPreview(null))
		}
	}, [
		sensorId,
		maskId,
		azimuth_min,
		azimuth_max,
		range_min,
		range_max,
		dispatch,
	])

	return (
		<>
			<FormHeader
				title={
					isEditForm
						? t('headings.radarMaskSettings')
						: t('headings.addRadarMask')
				}
				backRoute={radarMaskRoute}
			/>
			<ErrorBoundary>
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(handleSave)}>
						<Field.TextInput
							title={t('api.name')}
							register={register('name')}
							error={errors?.name?.message}
							testId='name'
						/>
						<Field.RangeSlider
							units='deg'
							title={t('api.azimuth')}
							defaultValue={[
								defaultValues.azimuth_min,
								defaultValues.azimuth_max,
							]}
							step={1}
							min={-60}
							max={60}
							onChangeEnd={(value: [number, number]) => {
								setValue('azimuth_min', value[0], {
									shouldDirty: true,
									shouldValidate: true,
									shouldTouch: true,
								})
								setValue('azimuth_max', value[1], {
									shouldDirty: true,
									shouldValidate: true,
									shouldTouch: true,
								})
							}}
							error={[
								errors?.azimuth_min?.message,
								errors?.azimuth_max?.message,
							]
								.filter((error) => !!error)
								.join(', ')}
							testId='azimuth-range'
						/>
						<Field.RangeSlider
							units='m'
							title={t('api.range')}
							defaultValue={[defaultValues.range_min, defaultValues.range_max]}
							step={1}
							min={radarData?.radar_type === 'echo_shield' ? 100 : 20}
							max={radarData?.radar_type === 'echo_shield' ? 10000 : 3000}
							onChangeEnd={(value: [number, number]) => {
								setValue('range_min', value[0], {
									shouldDirty: true,
									shouldValidate: true,
									shouldTouch: true,
								})
								setValue('range_max', value[1], {
									shouldDirty: true,
									shouldValidate: true,
									shouldTouch: true,
								})
							}}
							error={[errors?.range_min?.message, errors?.range_max?.message]
								.filter((error) => !!error)
								.join(', ')}
							testId='range-range'
						/>
						{radarData?.radar_type === 'echo_shield' ? (
							<Field.UnitsSlider
								units='m'
								title={t('api.maxAgl')}
								defaultValue={defaultValues.max_agl}
								step={1}
								min={0}
								max={1000}
								register={register('max_agl', { valueAsNumber: true })}
								error={errors?.max_agl?.message}
								testId='max-agl'
							/>
						) : (
							<>
								<Field.RangeSlider
									units='deg'
									title={t('api.elevation')}
									defaultValue={[
										defaultValues.elevation_min,
										defaultValues.elevation_max,
									]}
									step={1}
									min={-40}
									max={40}
									onChangeEnd={(value: [number, number]) => {
										setValue('elevation_min', value[0], {
											shouldDirty: true,
											shouldValidate: true,
											shouldTouch: true,
										})
										setValue('elevation_max', value[1], {
											shouldDirty: true,
											shouldValidate: true,
											shouldTouch: true,
										})
									}}
									error={[
										errors?.elevation_min?.message,
										errors?.elevation_max?.message,
									]
										.filter((error) => !!error)
										.join(', ')}
									testId='elevation-range'
								/>
								<Field.RangeSlider
									title={t('api.rcs')}
									defaultValue={[defaultValues.rcs_min, defaultValues.rcs_max]}
									step={1}
									min={-50}
									max={100}
									onChangeEnd={(value: [number, number]) => {
										setValue('rcs_min', value[0], {
											shouldDirty: true,
											shouldValidate: true,
											shouldTouch: true,
										})
										setValue('rcs_max', value[1], {
											shouldDirty: true,
											shouldValidate: true,
											shouldTouch: true,
										})
									}}
									error={[errors?.rcs_min?.message, errors?.rcs_max?.message]
										.filter((error) => !!error)
										.join(', ')}
									testId='rcs-range'
								/>
								<Field.Switch
									title={t('api.hardMask')}
									register={register('hard_mask')}
									error={errors?.hard_mask?.message}
									testId='hard-mask-switch'
									tooltip={t('tooltips.hardMask')}
								/>
							</>
						)}
						<FormButtons
							isSubmitting={isSubmitting}
							isDirty={isDirty}
							handleCancel={handleCancel}
						/>
					</form>
				</FormProvider>
			</ErrorBoundary>
		</>
	)
}

export default RadarMaskForm
