import bbox from '@turf/bbox'
import bboxPolygon from '@turf/bbox-polygon'
import circle from '@turf/circle'

import type { LatLngExpression } from 'leaflet'

export const createNewDefaultZone = (latitude: number, longitude: number) => {
	const distance = 1
	const zone = bboxPolygon(
		bbox(circle([longitude, latitude], distance, { units: 'kilometers' }))
	)
	const coordinates = zone.geometry.coordinates[0]
	return coordinates.map(([lng, lat]) => [lat, lng]) as LatLngExpression[]
}
