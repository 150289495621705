import {
	type GroupBase,
	type OptionsOrGroups,
	Select as ChakraReactSelect,
} from 'chakra-react-select'

import { Box, FormLabel } from '@chakra-ui/react'
import { multiSelectStyles } from '@UI/Select/Select.styles'

export type MultiSelectOption = {
	label: string
	value: string | number
}

export type MultiSelectOptions = {
	label: string
	options: MultiSelectOption[]
}

type SelectProps<T> = {
	title?: string
	id?: string
	options:
		| OptionsOrGroups<MultiSelectOptions, GroupBase<MultiSelectOptions>>
		| T[]
	defaultValue?: MultiSelectOption[]
	value?: MultiSelectOption[] | null
	isLoading?: boolean
	isDisabled?: boolean
	placeholder?: string
	onChange?: any
	testId?: string
}

function MultiSelect<T>({
	title = undefined,
	id,
	options,
	onChange,
	defaultValue,
	value,
	isDisabled = false,
	placeholder,
	testId,
	...props
}: SelectProps<T>) {
	return (
		<Box data-testid={testId}>
			{title && <FormLabel htmlFor={id}>{title}</FormLabel>}
			<ChakraReactSelect
				// isClearable={false} Removes clear all options 'X' action
				className='MultiSelect'
				isMulti
				id={id}
				useBasicStyles
				options={options}
				defaultValue={defaultValue}
				value={value}
				onChange={onChange}
				menuPortalTarget={document.body}
				focusBorderColor='transparent'
				isDisabled={isDisabled}
				placeholder={placeholder}
				selectedOptionStyle='check'
				closeMenuOnSelect={false}
				hideSelectedOptions={false}
				chakraStyles={multiSelectStyles}
				styles={{
					menuPortal: (provided) => ({
						...provided,
						zIndex: 9999,
					}),
				}}
				{...props}
			/>
		</Box>
	)
}

export default MultiSelect
