import type { LatLngExpression } from 'leaflet'
import Marker from '../index'

import compassRoseIcon from '@Assets/icons/markers/compass-rose.png'

type CompassMarkerProps = {
	position: LatLngExpression
}

const ICON_SIZE = [600, 600]
const ICON_ANCHOR = [303, 300]

const style = {
	width: '600px',
	height: '600px',
}

const CompassMarker = ({ position }: CompassMarkerProps) => {
	return (
		<Marker
			position={position}
			icon={<img src={compassRoseIcon} style={style} alt='Compass Rose' />}
			iconSize={ICON_SIZE}
			iconAnchor={ICON_ANCHOR}
			alt='Compass Rose Marker'
		/>
	)
}

export default CompassMarker
