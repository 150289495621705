import { useEffect, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// Form
import { useForm, FormProvider } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import type { TypeOf } from 'zod'

// Chakra
import { Flex } from '@chakra-ui/react'
import { MdFilterTiltShift, MdHome } from 'react-icons/md'
import Headings from '@UI/Headings/Headings'
import FormHeader from '../FormHeader'
import Field from '@Components/FormElements'
import Accordion from '@Components/FormElements/Accordion/Accordion'

// Store
import {
	useGetSiteInstallationQuery,
	useGetSiteInstallationsQuery,
	selectInstallationsOptions,
} from '@Store/sites/sitesApi'
import {
	useGetCameraQuery,
	useCreateCameraMutation,
	useUpdateCameraMutation,
} from '@Store/cameras/camerasApi'
import { useAppDispatch } from '@Store/index'
import { updateSensorPreview } from '@Store/ui/uiSlice'
import { skipToken } from '@reduxjs/toolkit/query'

// Schema
import type { Camera } from '@Store/types'
import {
	cameraFormSchema,
	ADD_CAMERA_DEFAULT_VALUES,
} from './CameraForm.schema'
// Components
import IconButton from '@UI/IconButton/IconButton'
import FormWrapper from '@Components/FormElements/FormWrapper/FormWrapper'
import { FormButtons } from '@Components/FormElements/FormButtons/FormButtons'

import { useExpandFormAccordion } from '@Hooks/useExpandFormAccordion'
import ErrorBoundary from '@/components/App/ErrorHandling/ErrorBoundary'

type CameraFormProps = {
	siteId: number
	installationId: number
	cameraId?: number
}

const CameraForm = ({ siteId, installationId, cameraId }: CameraFormProps) => {
	const { t } = useTranslation('forms', { keyPrefix: 'cameraForm' })
	const isEditForm = !!cameraId
	const {
		isLoading,
		isError,
		isSuccess,
		refetch,
		data: camera,
	} = useGetCameraQuery(siteId && cameraId ? { siteId, cameraId } : skipToken, {
		refetchOnMountOrArgChange: true,
	})

	// Looks for props passed through from navigate() via Unregistered Sensors
	const { state } = useLocation()
	const addCameraDefaultValues = {
		...ADD_CAMERA_DEFAULT_VALUES,
		...state,
		sentry_id: installationId,
	} as unknown as Camera

	const defaultValues = isEditForm ? camera : addCameraDefaultValues

	return (
		<FormWrapper
			entity={t('entity')}
			isEditForm={isEditForm}
			isLoading={isLoading}
			isError={isError}
			isSuccess={isSuccess}
			refetch={refetch}
		>
			{defaultValues && (
				<Form
					key={cameraId}
					defaultValues={defaultValues}
					isEditForm={isEditForm}
					siteId={siteId}
					installationId={installationId}
					cameraId={cameraId}
				/>
			)}
		</FormWrapper>
	)
}

const Form = ({
	defaultValues,
	isEditForm,
	siteId,
	installationId,
	cameraId,
}: {
	defaultValues: Camera
	isEditForm: boolean
	siteId: number
	installationId: number
	cameraId?: number
}) => {
	const navigate = useNavigate()

	const ref = useRef(null)

	// Translations
	const { t } = useTranslation('forms', { keyPrefix: 'cameraForm' })

	type Schema = TypeOf<typeof cameraFormSchema>

	const methods = useForm<Schema>({
		resolver: zodResolver(cameraFormSchema),
		defaultValues,
	})
	const {
		register,
		formState: { errors, isSubmitting, isDirty },
		handleSubmit,
		setValue,
		setError,
		watch,
	} = methods

	const { expandedIndex, setExpandedIndex, errorIndexes } =
		useExpandFormAccordion(ref, errors)

	// Handle absolute offset values
	const { sentryDirection, sentryAltitude } = useGetSiteInstallationQuery(
		{ siteId: Number(siteId), installationId: Number(installationId) },
		{
			skip: !installationId || !siteId,
			selectFromResult: ({ data, isSuccess }) => ({
				sentryDirection: isSuccess ? data?.direction : 0,
				sentryAltitude: isSuccess ? data?.altitude : 0,
			}),
		}
	)

	const { installationsOptions, installationsOptionsReady } =
		useGetSiteInstallationsQuery(Number(siteId), {
			selectFromResult: ({ isSuccess, data }) => ({
				...selectInstallationsOptions(data),
				installationsOptionsReady: isSuccess,
			}),
		})

	const installationDefaultValue = installationsOptions.find(
		(option) =>
			option.value ===
			(isEditForm ? defaultValues.sentry_id : Number(installationId))
	)

	const [directionOffset, altitudeOffset] = watch([
		'direction_offset',
		'altitude',
	])

	const absoluteDirection = Number((directionOffset + sentryDirection) % 360)
	const absoluteAltitude = Number(altitudeOffset + sentryAltitude)

	const dispatch = useAppDispatch()
	useEffect(() => {
		dispatch(
			updateSensorPreview({
				directionOffset,
				pan: defaultValues.pan,
			})
		)
		return () => {
			dispatch(updateSensorPreview(null))
		}
	}, [directionOffset, defaultValues.pan, dispatch])

	const [createCamera] = useCreateCameraMutation()
	const [updateCamera] = useUpdateCameraMutation()

	const camerasRoute = `/${siteId}/installations/${installationId}/camera`

	const handleSave = async (data: Schema) => {
		try {
			if (isEditForm) {
				await updateCamera({
					siteId: Number(siteId),
					id: Number(cameraId),
					...data,
				}).unwrap()
			} else {
				await createCamera({ siteId: Number(siteId), ...data }).unwrap()
			}
			navigate(camerasRoute)
		} catch (e: unknown) {
			const errors = e as { [name in keyof Schema]: string }
			for (const field in errors) {
				setError(field as keyof Schema, {
					type: 'custom',
					message: errors[field as keyof Schema] as string,
				})
			}
		}
	}

	const handleCancel = () => navigate(camerasRoute)

	return (
		<>
			<FormHeader
				title={
					isEditForm ? t('headings.cameraSettings') : t('headings.addCamera')
				}
			/>
			<ErrorBoundary>
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(handleSave)} ref={ref}>
						<Field.Divider title={t('headings.generalParameters')} />
						{installationsOptionsReady && (
							<Field.Select
								title={t('api.installation')}
								defaultValue={installationDefaultValue}
								register={register('sentry_id')}
								options={installationsOptions}
								error={errors?.sentry_id?.message}
							/>
						)}
						<Field.TextInput
							title={t('api.name')}
							register={register('name')}
							error={errors?.name?.message}
							testId='name'
						/>
						<Field.TextInput
							title={t('api.serialNumber')}
							register={register('serial_number')}
							disabled={isEditForm}
							tooltip={isEditForm ? t('tooltips.serial_number_disabled') : ''}
							error={errors?.serial_number?.message}
							testId='serial-number'
						/>
						<Field.UnitsSlider
							isAbsolute
							units='m'
							customLabel={absoluteAltitude}
							title={t('api.altitude')}
							tooltip={t('tooltips.altitudeOffset')}
							min={0}
							max={164}
							step={0.1}
							register={register('altitude', { valueAsNumber: true })}
							error={errors?.altitude?.message}
							testId='altitude-offset'
						/>
						<Field.UnitsSlider
							isAbsolute
							units='deg'
							customLabel={absoluteDirection}
							title={t('api.direction_offset')}
							tooltip={t('tooltips.direction_offset')}
							min={0}
							max={359.99}
							step={0.1}
							register={register('direction_offset', { valueAsNumber: true })}
							error={errors?.direction_offset?.message}
							testId='direction-offset'
						/>
						{/*TODO: add handlers to buttons*/}
						<Flex mt={2} gap={1}>
							{/*TODO: Enable buttons ans tooltips*/}
							<IconButton
								variant='ghost'
								icon={<MdHome />}
								aria-label={t('buttons.homePosition')}
								tooltip={t('tooltips.homePosition')}
								tooltipPlacement='bottom-start'
								testId='home-position'
								isDisabled
							/>
							<IconButton
								variant='ghost'
								icon={<MdFilterTiltShift />}
								aria-label={t('buttons.autoCalibrate')}
								tooltip={t('tooltips.autoCalibrate')}
								tooltipPlacement='bottom-start'
								testId='auto-calibrate'
								isDisabled
							/>
						</Flex>

						<Accordion.Container
							setExpandedIndex={setExpandedIndex}
							index={expandedIndex}
						>
							<Accordion.Item
								title={t('headings.advancedParameters')}
								testId='advanced-parameters'
								isError={errorIndexes.has(0)}
							>
								<Headings.SectionSubheading title={t('headings.credentials')} />
								<Field.TextInput
									title={t('api.username')}
									register={register('username')}
									error={errors?.username?.message}
									testId='username'
								/>
								<Field.TextInput
									type='password'
									placeholder='••••••••••'
									title={t('api.password')}
									register={register('password')}
									error={errors?.password?.message}
									testId='password'
								/>
								<Field.TextInput
									title={t('api.mac_address')}
									register={register('mac_address')}
									error={errors?.mac_address?.message}
									disabled
									testId='mac-address'
								/>
								<Field.TextInput
									title={t('api.ip')}
									register={register('ip')}
									error={errors?.ip?.message}
									disabled
									testId='ip'
								/>
								<Headings.SectionSubheading
									title={t('headings.opticalSensorSpecifications')}
								/>
								<Field.RangeSlider
									units='deg'
									title={t('api.fov_angle_range')}
									tooltip={t('tooltips.fov_angle_range')}
									defaultValue={[
										defaultValues.min_fov_angle,
										defaultValues.max_fov_angle,
									]}
									step={0.1}
									min={0}
									max={160}
									onChangeEnd={(value: [number, number]) => {
										setValue('min_fov_angle', value[0], {
											shouldDirty: true,
											shouldValidate: true,
											shouldTouch: true,
										})
										setValue('max_fov_angle', value[1], {
											shouldDirty: true,
											shouldValidate: true,
											shouldTouch: true,
										})
									}}
									error={[
										errors?.min_fov_angle?.message,
										errors?.max_fov_angle?.message,
									]
										.filter((error) => !!error)
										.join(', ')}
									testId='fov-angle-range'
								/>
								<Field.RangeSlider
									units='deg'
									title={t('api.tilt_angle_range')}
									tooltip={t('tooltips.tilt_angle_range')}
									defaultValue={[
										defaultValues.min_tilt_angle,
										defaultValues.max_tilt_angle,
									]}
									step={0.1}
									min={-130}
									max={130}
									onChangeEnd={(value: [number, number]) => {
										setValue('min_tilt_angle', value[0], {
											shouldDirty: true,
											shouldValidate: true,
											shouldTouch: true,
										})
										setValue('max_tilt_angle', value[1], {
											shouldDirty: true,
											shouldValidate: true,
											shouldTouch: true,
										})
									}}
									error={[
										errors?.min_tilt_angle?.message,
										errors?.max_tilt_angle?.message,
									]
										.filter((error) => !!error)
										.join(', ')}
									testId='tilt-angle-range'
								/>
								{/*TODO: Add Reach and Zoom calculation*/}
								{/*TODO: Syntax for tooltips with values : {t('tooltips.reach', { reach: '52' })}*/}
								<Headings.SectionSubheading
									title={t('headings.trackingParameters')}
								/>
								<Field.Select
									title={t('api.auto_track')}
									tooltip={t('tooltips.auto_track')}
									options={t('auto_track_options', {
										returnObjects: true,
									})}
									register={register('auto_track')}
									error={errors?.auto_track?.message}
								/>
								<Field.Slider
									title={t('api.droneoptid_prob_threshold')}
									tooltip={t('tooltips.droneoptid_prob_threshold')}
									min={0}
									max={100}
									step={1}
									register={register('droneoptid_prob_threshold', {
										valueAsNumber: true,
									})}
									error={errors?.droneoptid_prob_threshold?.message}
									testId='probability-threshold'
								/>
								<Field.Slider
									title={t('api.tracking_slew_hfov_percent')}
									tooltip={t('tooltips.tracking_slew_hfov_percent')}
									min={0}
									max={25}
									step={0.1}
									register={register('tracking_slew_hfov_percent', {
										valueAsNumber: true,
									})}
									error={errors?.droneoptid_prob_threshold?.message}
									testId='tracking-slew-hfov-percent'
								/>
								<Flex mt={2}>
									<Field.Switch
										title={t('api.tracking_auto_focus')}
										tooltip={t('tooltips.tracking_auto_focus')}
										register={register('tracking_auto_focus')}
										error={errors?.tracking_auto_focus?.message}
										testId='auto-focus-switch'
									/>

									<Field.Switch
										title={t('api.tracking_manual_focus')}
										tooltip={t('tooltips.tracking_manual_focus')}
										register={register('tracking_manual_focus')}
										error={errors?.tracking_manual_focus?.message}
										testId='manual-focus-switch'
									/>
								</Flex>
								<Field.Slider
									title={t('api.tracking_slew_retry_delay')}
									tooltip={t('tooltips.tracking_slew_retry_delay')}
									min={0}
									max={60}
									step={0.5}
									register={register('tracking_slew_retry_delay', {
										valueAsNumber: true,
									})}
									error={errors?.tracking_slew_retry_delay?.message}
									testId='retry-delay'
								/>
								<Field.Select
									title={t('api.tracking_control_loop_speed')}
									tooltip={t('tooltips.tracking_control_loop_speed')}
									options={t('tracking_control_loop_speed', {
										returnObjects: true,
									})}
									register={register('tracking_control_loop_speed')}
									error={errors?.tracking_control_loop_speed?.message}
								/>
								<Headings.SectionSubheading
									title={t('headings.recordingParameters')}
								/>
								<Field.Slider
									title={t('api.recording_max_duration')}
									tooltip={t('tooltips.recording_max_duration')}
									min={1}
									max={30}
									register={register('recording_max_duration', {
										valueAsNumber: true,
									})}
									error={errors?.recording_max_duration?.message}
									testId='recording-max-duration'
								/>
								<Field.Switch
									title={t('api.auto_record')}
									tooltip={t('tooltips.auto_record')}
									register={register('auto_record')}
									error={errors?.auto_record?.message}
									testId='auto-record-switch'
								/>
								<Headings.SectionSubheading
									title={t('headings.streamingParameters')}
								/>
								<Field.Slider
									title={t('api.streaming_bitrate')}
									tooltip={t('tooltips.streaming_bitrate')}
									min={1}
									max={5}
									step={0.5}
									register={register('streaming_bitrate', {
										valueAsNumber: true,
									})}
									error={errors?.streaming_bitrate?.message}
									testId='streaming-bitrate'
								/>
								<Field.Switch
									title={t('api.always_stream')}
									tooltip={t('tooltips.always_stream')}
									register={register('always_stream')}
									error={errors?.always_stream?.message}
									testId='always-stream-switch'
								/>
							</Accordion.Item>
						</Accordion.Container>
						<FormButtons
							isSubmitting={isSubmitting}
							isDirty={isDirty}
							handleCancel={handleCancel}
						/>
					</form>
				</FormProvider>
			</ErrorBoundary>
		</>
	)
}

export default CameraForm
