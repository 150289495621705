import type { CSSProperties } from 'react'
import { COLORS } from '@Constants/colors'

// Can move these out if they end up being needed by
// another component as well
const PROBABILITY_THRESHOLD = {
	LOW: 0,
	LOW_MEDIUM: 0.2,
	MEDIUM: 0.4,
	MEDIUM_HIGH: 0.6,
	HIGH: 0.8,
}

const selectStyle = {
	position: 'absolute',
	left: '50%',
	top: '50%',
	transform: 'translate(-50%, -50%)',
} as CSSProperties

type FusionDetectionMarkerIconProps = {
	probability: number
	isThreat: boolean
	isSelected: boolean
}

const FusionDetectionMarkerIcon = ({
	probability,
	isThreat,
	isSelected,
}: FusionDetectionMarkerIconProps) => {
	const fill = isThreat ? COLORS.RED : COLORS.BLUE
	const stroke = probability >= 0.2 ? fill : ''

	return (
		<div>
			<svg
				width='50'
				height='50'
				viewBox='0 0 80 80'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<g id='object-confidence-bars'>
					<path
						id='object-confidence-bar-1'
						d='M24.9567 37.0044C25.1556 35.9896 25.4553 34.9946 25.853 34.0345C26.637 32.1419 27.786 30.4221 29.2346 28.9735C30.6832 27.5249 32.403 26.3758 34.2956 25.5919C35.2557 25.1942 36.2507 24.8944 37.2655 24.6956L37.2655 16.1926C35.1446 16.4598 33.0643 17.0098 31.0811 17.8313C28.1693 19.0374 25.5235 20.8052 23.2949 23.0338C21.0663 25.2624 19.2985 27.9082 18.0924 30.82C17.2709 32.8032 16.7209 34.8835 16.4537 37.0044L24.9567 37.0044Z'
						stroke={stroke}
						fill={probability >= PROBABILITY_THRESHOLD.LOW_MEDIUM ? fill : ''}
					/>
					<path
						id='object-confidence-bar-2'
						d='M43.4537 24.6956C44.4686 24.8944 45.4636 25.1942 46.4236 25.5919C48.3163 26.3758 50.036 27.5249 51.4846 28.9735C52.9332 30.4221 54.0823 32.1418 54.8663 34.0345C55.2639 34.9946 55.5637 35.9896 55.7626 37.0044L64.2655 37.0044C63.9983 34.8835 63.4483 32.8032 62.6268 30.82C61.4207 27.9082 59.6529 25.2624 57.4243 23.0338C55.1957 20.8052 52.55 19.0374 49.6381 17.8313C47.6549 17.0098 45.5746 16.4598 43.4537 16.1926L43.4537 24.6956Z'
						stroke={stroke}
						fill={probability >= PROBABILITY_THRESHOLD.MEDIUM ? fill : ''}
					/>
					<path
						id='object-confidence-bar-3'
						d='M55.7626 43.0044C55.5637 44.0192 55.2639 45.0142 54.8663 45.9743C54.0823 47.8669 52.9332 49.5867 51.4846 51.0353C50.036 52.4839 48.3163 53.6329 46.4236 54.4169C45.4636 54.8146 44.4686 55.1143 43.4537 55.3132L43.4537 63.8162C45.5747 63.5489 47.6549 62.999 49.6381 62.1775C52.55 60.9714 55.1957 59.2036 57.4243 56.975C59.6529 54.7464 61.4207 52.1006 62.6268 49.1888C63.4483 47.2055 63.9983 45.1253 64.2655 43.0044L55.7626 43.0044Z'
						stroke={stroke}
						fill={probability >= PROBABILITY_THRESHOLD.MEDIUM_HIGH ? fill : ''}
					/>
					<path
						id='object-confidence-bar-4'
						d='M37.2655 55.3132C36.2507 55.1143 35.2557 54.8146 34.2956 54.4169C32.403 53.6329 30.6832 52.4839 29.2346 51.0353C27.786 49.5867 26.637 47.8669 25.853 45.9743C25.4553 45.0142 25.1556 44.0192 24.9567 43.0044L16.4537 43.0044C16.7209 45.1253 17.2709 47.2055 18.0924 49.1888C19.2985 52.1006 21.0663 54.7464 23.2949 56.975C25.5235 59.2036 28.1693 60.9714 31.0811 62.1775C33.0644 62.999 35.1446 63.549 37.2655 63.8162L37.2655 55.3132Z'
						stroke={stroke}
						fill={probability >= PROBABILITY_THRESHOLD.HIGH ? fill : ''}
					/>
				</g>
				<path
					id='centre-dot'
					d='M47.7096 40.0044C47.7096 44.0637 44.4189 47.3544 40.3596 47.3544C36.3003 47.3544 33.0096 44.0637 33.0096 40.0044C33.0096 35.9451 36.3003 32.6544 40.3596 32.6544C44.4189 32.6544 47.7096 35.9451 47.7096 40.0044Z'
					fill={probability >= PROBABILITY_THRESHOLD.LOW ? fill : ''}
					stroke='#212121'
					strokeWidth='1.3'
				/>
			</svg>
			{isSelected && (
				<svg
					width='80'
					height='80'
					fill='none'
					version='1.1'
					viewBox='0 0 100 100'
					xmlns='http://www.w3.org/2000/svg'
					style={selectStyle}
				>
					<path
						id='selection-bars'
						d='m88.653 69.314c0.53568-1.07 1.0283-2.1654 1.4755-3.2838 1.9817-4.9566 3.0718-10.366 3.0718-16.03 0-5.6641-1.0901-11.073-3.0718-16.03-0.44724-1.1184-0.93984-2.2138-1.4755-3.2838-0.53976-1.0781-1.1232-2.1304-1.7482-3.1547-3.3166 1.656-4.8626 5.6078-3.4618 9.1115 1.6475 4.1206 2.5572 8.6238 2.5572 13.357s-0.90972 9.2365-2.5572 13.357c-1.4009 3.5038 0.1452 7.4556 3.4618 9.1116 0.62496-1.0243 1.2084-2.0766 1.7482-3.1547zm-16.184 17.591c-1.656-3.3166-5.6078-4.8628-9.1115-3.4618-4.1206 1.6475-8.6238 2.5572-13.357 2.5572s-9.2365-0.90972-13.357-2.5572c-3.5038-1.401-7.4556 0.1452-9.1116 3.4618 1.0243 0.62496 2.0767 1.2084 3.1547 1.7482 1.07 0.53568 2.1654 1.0283 3.2838 1.4754 4.9566 1.9818 10.366 3.0719 16.03 3.0719 5.6641 0 11.074-1.0901 16.03-3.0719 1.1184-0.44712 2.2138-0.93972 3.2838-1.4754 1.0781-0.53976 2.1304-1.1232 3.1547-1.7482zm-59.373-14.436c3.3166-1.656 4.8627-5.6078 3.4618-9.1115-1.6476-4.1206-2.5573-8.6238-2.5573-13.357s0.90972-9.2365 2.5573-13.357c1.4009-3.5036-0.1452-7.4555-3.4618-9.1115-0.62495 1.0243-1.2084 2.0766-1.7482 3.1547-0.5357 1.07-1.0283 2.1654-1.4755 3.2838-1.9818 4.9566-3.0718 10.366-3.0718 16.03 0 5.664 1.0901 11.073 3.0718 16.03 0.44718 1.1184 0.93977 2.2138 1.4755 3.2838 0.53972 1.0781 1.1232 2.1304 1.7482 3.1547zm14.436-59.373c1.656 3.3165 5.6078 4.8626 9.1116 3.4617 4.1204-1.6475 8.6237-2.5572 13.357-2.5572s9.2365 0.90972 13.357 2.5572c3.5036 1.4009 7.4555-0.1452 9.1115-3.4617-1.0243-0.62494-2.0766-1.2084-3.1547-1.7482-1.07-0.5357-2.1654-1.0283-3.2838-1.4755-4.9565-1.9818-10.366-3.0718-16.03-3.0718-5.664 0-11.073 1.0901-16.03 3.0718-1.1184 0.44718-2.2138 0.93976-3.2838 1.4755-1.078 0.53972-2.1304 1.1232-3.1547 1.7482z'
						fill='#ffa726'
					/>
				</svg>
			)}
		</div>
	)
}

export default FusionDetectionMarkerIcon
