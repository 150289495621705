// Chakra
import {
	Flex,
	FormControl,
	FormErrorMessage,
	Text,
	Switch,
	type SwitchProps,
} from '@chakra-ui/react'

// Components
import Tooltip from '@UI/Tooltip/Tooltip'

// Types
import type { RegisterType } from '@/components/FormElements/types/RegisterType'
import type { FormElementProps } from '@Components/FormElements/types/FormElementProps'

type FormSwitchProps = FormElementProps & {
	description?: string
	// TODO: get rid of styles and make it Chakra props
	styles?: React.CSSProperties
} & RegisterType<string> &
	SwitchProps

const FormSwitch = ({
	id,
	title,
	register,
	error,
	disabled = false,
	styles,
	testId,
	onChange = undefined,
	isChecked = false,
	defaultChecked = false,
	description,
	tooltip,
}: FormSwitchProps) => {
	const htmlId = id ?? register?.name ?? 'switch'
	return (
		<Tooltip label={tooltip} type='info'>
			<FormControl mt='4' userSelect='none' isInvalid={!!error} sx={styles}>
				<Flex direction='row'>
					<Switch
						id={htmlId}
						isDisabled={disabled}
						{...(register ?? {})}
						{...(onChange && { onChange: onChange })}
						{...(isChecked && { isChecked: isChecked })}
						{...(defaultChecked && { defaultChecked: defaultChecked })}
						data-testid={testId}
						sx={{
							width: '32px',
							top: '4px',
							left: '2px',
						}}
					/>
					<Flex direction='column' paddingInlineStart='2'>
						<Text
							as='label'
							htmlFor={htmlId}
							mb='0'
							fontSize='sm'
							color='gray.300'
							opacity={disabled ? 0.4 : undefined}
							whiteSpace='pre-wrap'
						>
							{title}
						</Text>
						{description && (
							<Text fontSize='xs' color='label_color'>
								{description}
							</Text>
						)}
					</Flex>
				</Flex>
				<FormErrorMessage>{error}</FormErrorMessage>
			</FormControl>
		</Tooltip>
	)
}

export default FormSwitch
