import { useParams } from 'react-router-dom'

// Redux
import { useGetSiteInstallationQuery } from '@Store/sites/sitesApi'
import { useAppSelector } from '@Store/index'
import { selectSensorPreview } from '@Store/ui/uiSlice'
import type { CameraPreview as CameraPreviewType } from '@Store/types'

import SentryMarker from '@/components/Markers/SentryMarker/SentryMarker'
import { CameraSector } from '@Components/Sectors'
import CompassMarker from '@/components/Markers/CompassMarker/CompassMarker'
import Detections from '@Components/Site/MapLayers/Detections'

import useCenterMap from './useCenterMap'

const CameraPreview = () => {
	const { siteId: siteIdParam, installationId: installationIdParam } =
		useParams()
	const siteId = Number(siteIdParam)
	const installationId = Number(installationIdParam)

	// Sector details
	const sectorPreview = useAppSelector(selectSensorPreview) as CameraPreviewType

	// Installation details
	const { isSuccess, installation } = useGetSiteInstallationQuery(
		{ siteId, installationId: installationId as number },
		{
			skip: !sectorPreview || !installationId || !siteId,
			selectFromResult: ({ data, isSuccess }) => ({
				isSuccess,
				installation: data,
			}),
		}
	)

	// Temporarily center the map
	useCenterMap({
		latitude: installation?.latitude,
		longitude: installation?.longitude,
	})

	if (isSuccess && installation && sectorPreview) {
		const bearing =
			(installation.direction + sectorPreview.directionOffset) % 360
		return (
			<>
				<Detections siteId={siteId} />
				<CompassMarker
					position={[installation.latitude, installation.longitude]}
					bearing={bearing}
				/>
				<CameraSector
					latitude={installation.latitude}
					longitude={installation.longitude}
					bearing={bearing}
					min_fov_angle={30}
					max_fov_angle={30}
					pan={0}
					zoom={1}
					isActive={true}
				/>
				<SentryMarker
					name={installation.name}
					position={[installation.latitude, installation.longitude]}
					sentryType={installation.sentry_type}
					iconColor={installation.status_color}
				/>
			</>
		)
	} else return null
}

export default CameraPreview
