import type { Detection, SimplifiedSiteInstallation } from '@Store/types'

// Logic taken from vue - look into cleaning up.
export const getInstallationLocation = (
	track: Detection,
	installations: SimplifiedSiteInstallation[]
) => {
	const locations =
		installations.find((installation) =>
			track.detection_contributions.some(
				(contribution) => contribution.sensor_id === installation.id
			)
		)?.sentry_locations ?? []

	if (locations?.length === 0) return null
	if (locations?.length === 1) return { ...locations[0] }

	const trackCreatedAt = new Date(track?.created_at)
	const differences = locations?.map((location) => {
		const locationCreatedAt = new Date(location.created_at)
		return {
			location,
			createdAtDifference: Math.abs(
				trackCreatedAt.getTime() - locationCreatedAt.getTime()
			),
		}
	})
	const smallestDifference = differences?.reduce((res, obj) => {
		return obj.createdAtDifference < res.createdAtDifference ? obj : res
	})
	return smallestDifference.location
}
