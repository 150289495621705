import { defineStyleConfig } from '@chakra-ui/react'
import FIGMA from '../_FIGMA'

const NumberInputStyles = defineStyleConfig({
	baseStyle: {
		field: {
			_focusVisible: {
				border: `1px solid ${FIGMA.colors.primary}`,
			},
			_dark: {
				color: FIGMA.colors.input_color,
				background: FIGMA.colors.input_bg,
				border: '1px solid transparent',
			},
		},
	},
})

export const NumberInput = {
	defaultProps: {
		size: 'sm',
		focusBorderColor: FIGMA.colors.primary,
		errorBorderColor: FIGMA.colors.error,
	},
	...NumberInputStyles,
}
