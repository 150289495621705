import CameraStreamButton from './CameraStreamButton'
import OpticalScanButton from './OpticalScanButton'
import TrackObjectButton from './TrackObjectButton'
import TrackNextButton from './TrackNextButton'
import useAuth from '@Hooks/useAuth'

const CameraActions = ({ siteId }: { siteId: number }) => {
	const { isAdmin } = useAuth()
	return (
		<>
			<CameraStreamButton siteId={siteId} />
			{isAdmin && <OpticalScanButton siteId={siteId} />}
			<TrackObjectButton siteId={siteId} />
			<TrackNextButton siteId={siteId} />
		</>
	)
}

export default CameraActions
