// React
import { useMemo } from 'react'
import { nanoid } from '@reduxjs/toolkit'
// Chakra
import { Box, Flex } from '@chakra-ui/react'
// Components
import RfFiltersOverviewFrequenciesRanges from './RfFiltersOverviewFrequenciesRanges'
// Types
import type { RfSensorFilter } from '@Store/types'
export type FrequencyValuesType = {
	frequency: number
	frequency_tolerance: number
	name: string
	id: number
}

export type FrequencyObjectType = Record<string, FrequencyValuesType[]>

const RfFiltersOverviewFrequencies = ({
	filters,
}: {
	filters: RfSensorFilter[]
}) => {
	const frequenciesObject = useMemo(() => {
		const bandNames = [...new Set(filters.map((value) => value.frequency_band))]
		const frequencies: FrequencyObjectType = {}
		for (let i = 0; i < bandNames.length; i++) frequencies[bandNames[i]] = []

		filters.forEach(
			({ frequency_band, frequency, frequency_tolerance, name, id }) => {
				// Called with object prototype due to '61:21 error Do not access Object.prototype method 'hasOwnProperty' from target object no-prototype-builtins
				if (Object.prototype.hasOwnProperty.call(frequencies, frequency_band)) {
					frequencies[frequency_band].push({
						frequency,
						frequency_tolerance,
						name,
						id,
					})
				}
			}
		)
		return frequencies
	}, [filters])

	const sortedRangeEntries = Object.entries(frequenciesObject).sort(
		(a, b) => Number(a[0].replace('MHz', '')) - Number(b[0].replace('MHz', ''))
	)

	return (
		<Flex direction='column' gap={4}>
			{sortedRangeEntries.map((entry) => {
				const [bandName, frequencies] = entry
				return (
					<Box key={nanoid()} mt={4}>
						<Box>{bandName}</Box>
						<RfFiltersOverviewFrequenciesRanges
							frequencyBand={bandName}
							ranges={frequencies}
						/>
					</Box>
				)
			})}
		</Flex>
	)
}

export default RfFiltersOverviewFrequencies
