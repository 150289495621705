import { useTranslation } from 'react-i18next'

import { Flex } from '@chakra-ui/react'

import FetchError from '@UI/FetchError/FetchError'
import PieChart from '@UI/Charts/PieChart'
import Headings from '@UI/Headings/Headings'
import Skeleton from '@UI/Skeleton/Skeleton'

import { useGetDisruptionsSummaryQuery } from '@Store/analytics/analyticsApi'

type DisruptionsPieChartsProps = {
	startTime: string
	endTime: string
	siteId: number
}

const DisruptionsPieChart = ({
	startTime,
	endTime,
	siteId,
}: DisruptionsPieChartsProps) => {
	const { t } = useTranslation('pages', {
		keyPrefix: 'analytics.disruptionsSummary',
	})

	const { isSuccess, isError, isLoading, isFetching, refetch, data } =
		useGetDisruptionsSummaryQuery({
			siteId: Number(siteId),
			format: 'graph',
			startTime: startTime,
			endTime: endTime,
		})

	const disruptionMethodData = data?.by_method

	const automaticCount = disruptionMethodData?.['Automatic']
	const manualCount = disruptionMethodData?.['Manual']

	const pieData = [
		{ id: 'Automatic', label: t('automatic'), value: automaticCount ?? 0 },
		{ id: 'Manual', label: t('manual'), value: manualCount ?? 0 },
	]

	return (
		<Skeleton isLoaded={!isLoading && !isFetching}>
			{isSuccess && (
				<Flex
					h='250px'
					w='100%'
					direction='column'
					gap='16px'
					marginBlockEnd={8}
					alignItems='center'
				>
					<Headings.FormHeading title={t('disruptionMethod')} />
					<PieChart data={pieData} />
				</Flex>
			)}
			{isError && <FetchError refetch={refetch} entity={t('entities.chart')} />}
		</Skeleton>
	)
}

export default DisruptionsPieChart
