import { useEffect, useRef } from 'react'
import ReactPlayer, { type ReactPlayerProps } from 'react-player'

import { baseUrl, isProduction } from '@Constants/api'

type VideoPlayerProps = {
	url: string | string[]
	seekTime?: number
} & ReactPlayerProps

const VideoPlayer = ({
	url,
	width = '100%',
	height = '100%',
	seekTime,
	...props
}: VideoPlayerProps) => {
	const playerRef = useRef<ReactPlayer>(null)

	useEffect(() => {
		if (playerRef.current && seekTime !== undefined) {
			playerRef.current.seekTo(seekTime, 'seconds')
		}
	}, [seekTime])

	return (
		<ReactPlayer
			ref={playerRef}
			url={`${isProduction ? '' : baseUrl}${url}`} // Ensures videos can be played locally
			width={width}
			height={height}
			{...props}
		/>
	)
}

export default VideoPlayer
