// React
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// Chakra
import { Card, CardHeader, CardBody, useDisclosure } from '@chakra-ui/react'

// Components
import UpdateModal from '@UI/Modals/UpdateModal'
import EditButton from '../../EditButton'

import RfContent from './RfContent'

import { useRestartSiteRfSensorsMutation } from '@Store/rfSensors/rfSensorsApi'
import type { SiteInstallation } from '@Store/types'

import useAuth from '@Hooks/useAuth'
import {
	selectIsTactical,
	useGetSystemInfoQuery,
} from '@Store/system/systemApi'

// Types
type RfContentProps = {
	devices: SiteInstallation['rf_sensors']
	acceptLocationUpdates: SiteInstallation['accept_location_updates']
}

const RFContent = ({
	devices,
	acceptLocationUpdates = false,
}: RfContentProps) => {
	const { isSiteManager } = useAuth()

	const { siteId: siteIdParam, installationId: installationIdParam } =
		useParams()
	const siteId = Number(siteIdParam)
	const installationId = Number(installationIdParam)

	const { t } = useTranslation('panels', {
		keyPrefix: 'devicesContent',
	})

	const baseRoute = `/${siteId}/installation/${installationId}`

	const { isOpen, onOpen, onClose } = useDisclosure()

	const [restartRfSensors] = useRestartSiteRfSensorsMutation()

	const handleRestart = async () => {
		try {
			await restartRfSensors({ siteId }).unwrap()
		} catch (error) {
			console.error('Restart RF sensor status error', error)
		}
	}

	// C2T
	const { isTactical, isSuccess: isTacticalSuccess } = useGetSystemInfoQuery(
		undefined,
		{
			selectFromResult: ({ data, isSuccess }) => ({
				isTactical: selectIsTactical(data),
				isSuccess,
			}),
		}
	)

	return (
		<>
			<Card variant='devices'>
				{isSiteManager && (
					<CardHeader>
						{isTacticalSuccess && !isTactical && (
							<EditButton
								onClick={onOpen}
								type='restart'
								label={t('buttons.restart')}
								testId='restart-all'
								disabled={devices.length < 1}
							/>
						)}
						<EditButton
							to={`${baseRoute}/rf_sensors/add`}
							type='add'
							label={t('buttons.add')}
							testId='add-rfsensor'
						/>
					</CardHeader>
				)}
				<CardBody>
					{devices.map((device) => (
						<RfContent
							key={device.id}
							device={device}
							acceptLocationUpdates={acceptLocationUpdates}
							siteId={siteId}
							installationId={installationId}
						/>
					))}
				</CardBody>
			</Card>

			<UpdateModal
				isOpen={isOpen}
				onClose={onClose}
				handleDelete={handleRestart}
				name=''
				headerText={t('modals.restartRfSensors')}
				modalBodyText={t('modals.cannotUndone')}
			/>
		</>
	)
}

export default RFContent
