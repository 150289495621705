import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// Chakra
import {
	Box,
	Card,
	CardHeader,
	CardBody,
	Flex,
	Text,
	useDisclosure,
} from '@chakra-ui/react'
// Components
import { Chunk, StatusDot } from '.'
import ActionSection from '../ActionSection'
import ActiveSensorButton from '../ActiveSensorButton'
import EditButton from '../EditButton'
import EditPalette from '../EditPalette'
import DeleteModal from '@Components/_UI/Modals/DeleteModal'
import { formatDirection } from '@Utils/formatUtils'
// Redux
import type { SiteInstallation } from '@Store/types'
import {
	useDeleteRadarMutation,
	useToggleRadarMutation,
	useRestartSiteRadarsMutation,
} from '@Store/radars/radarsApi'
import useAuth from '@Hooks/useAuth'
import UpdateModal from '@UI/Modals/UpdateModal'
import { formatRadarFrequencyChannel } from '@Utils/formatUtils'

type RadarContentProps = {
	devices: SiteInstallation['radars']
}

const RadarContent = ({ devices }: RadarContentProps) => {
	const { siteId: siteIdParam, installationId } = useParams()
	const siteId = Number(siteIdParam)

	const { t } = useTranslation('panels', {
		keyPrefix: 'devicesContent',
	})

	const [radarToDelete, setRadarToDelete] = useState<{
		id: number
		name: string
	} | null>(null)

	const { isOpen, onOpen, onClose } = useDisclosure()
	const {
		isOpen: isRestartOpen,
		onOpen: onRestartOpen,
		onClose: onRestartClose,
	} = useDisclosure()
	const [deleteRadar] = useDeleteRadarMutation()
	const [toggleRadar] = useToggleRadarMutation()
	const [restartRadars] = useRestartSiteRadarsMutation()

	const handleRestart = async () => {
		try {
			await restartRadars({ siteId }).unwrap()
		} catch (error) {
			console.error('Restart Radars status error', error)
		}
	}

	const handleToggleActive = async (sensorId: number, active: boolean) => {
		try {
			await toggleRadar({ siteId, sensorId, active: active }).unwrap()
		} catch (error) {
			console.error('Update Radar status error', error)
		}
	}

	const handleOpenDeleteModal = (id: number, name: string) => {
		onOpen()
		setRadarToDelete((prev) => ({ ...prev, id, name }))
	}

	const handleDeleteRadarSensor = async () => {
		try {
			if (radarToDelete) {
				await deleteRadar({ siteId, sensorId: radarToDelete.id })
				onClose()
			}
		} catch (error) {
			console.error('Delete radar error', error)
		}
	}

	const handleCloseDeleteModal = () => {
		onClose()
		setRadarToDelete(null)
	}

	const { isSiteManager } = useAuth()

	return (
		<>
			<Card variant='devices'>
				{isSiteManager && (
					<CardHeader textAlign='right'>
						<EditButton
							onClick={onRestartOpen}
							type='restart'
							label={t('buttons.restart')}
							disabled={devices.length < 1}
							testId='restart-all'
						/>
						<EditButton
							to={`/${siteId}/installation/${installationId}/radar/add`}
							type='add'
							label={t('buttons.add')}
							testId='add-radar'
						/>
					</CardHeader>
				)}
				<CardBody>
					{devices.map((device) => {
						const radarMaskZoneCount = (device.radar_mask_zones ?? []).filter(
							(filter) => filter.active
						).length
						const radarAglMaskEnabled =
							device.radar_agl_mask && device.radar_agl_mask[0].enabled
						return (
							<Box key={device.id}>
								<Flex paddingInlineStart={2} marginBlockEnd={1}>
									<Box w={6}>
										<StatusDot
											color={device.status_color}
											type='radar'
											id={device.id}
										/>
									</Box>
									<Box>
										<Text fontWeight={500} data-testid='device-name'>
											{device.name}
										</Text>
										{device.status === 'idle' && (
											<Text fontWeight={400} color='label_color'>
												{t('detectionDisabled')}
											</Text>
										)}
									</Box>
									<ActionSection>
										<ActiveSensorButton deviceId={device.id} />
										{isSiteManager && (
											<EditPalette testId={`device-menu-${device.name}`}>
												<EditButton
													to={`/${siteId}/installation/${installationId}/radar/${device.id}/settings`}
													label={t('buttons.edit')}
													testId='edit-device'
												/>
												<EditButton
													to={`/${siteId}/radar/${device.id}/masks`}
													type='mask'
													label={t('buttons.editRadarMask')}
													testId='edit-masks'
												/>
												<EditButton
													onClick={() =>
														handleToggleActive(
															device.id,
															device.status === 'transmitting' ? false : true
														)
													}
													type={
														device.status === 'transmitting'
															? 'disable'
															: 'enable'
													}
													label={
														device.status === 'transmitting'
															? t('buttons.disableDetections')
															: t('buttons.enableDetections')
													}
													disabled={
														!['green', 'grey'].includes(device.status_color)
													}
													testId='enable-detections'
												/>
												<EditButton
													onClick={() =>
														handleOpenDeleteModal(device.id, device.name)
													}
													label={t('buttons.delete')}
													type='delete'
													testId='delete-device'
												/>
											</EditPalette>
										)}
									</ActionSection>
								</Flex>
								<Flex
									flexWrap='wrap'
									paddingInlineStart={8}
									paddingBlockEnd={4}
								>
									<Chunk
										label={t('content.channel')}
										value={formatRadarFrequencyChannel(
											device.radar_type,
											device.channel
										)}
										testId='channel'
									/>
									{device.radar_type === 'radar_zero' && (
										<Chunk
											label={t('content.tcmState')}
											value={device.meta?.tcm_state ?? '-'}
											testId='tcm-state'
										/>
									)}
									<Chunk
										units='deg'
										label={t('content.direction')}
										value={formatDirection(device.direction) ?? '-'}
										testId='direction'
									/>
									<Chunk
										label={t('content.status')}
										value={device.status}
										testId='status'
									/>
									<Chunk
										units='deg'
										label={t('content.roll')}
										value={device.roll ?? 0}
										testId='roll'
									/>
									<Chunk
										units='deg'
										label={t('content.tilt')}
										value={device.tilt}
										testId='tilt'
									/>
									<Chunk
										label={t('content.scanRate')}
										value={`${device.meta?.search_time?.toFixed(3)}s`}
										testId='scan-rate'
									/>
									<Chunk
										label={t('content.serial')}
										value={device.serial_number}
										testId='serial'
									/>
									<Chunk
										label={t('content.version')}
										value={device.fw_version ?? '-'}
										testId='software-version'
									/>
									<Chunk
										label={t('content.activeZoneMask')}
										value={radarMaskZoneCount}
										testId={`active-zone-masks-${device.name}`}
									/>
									<Chunk
										label={t('content.aglActiveZone')}
										value={radarAglMaskEnabled ? 'Enabled' : 'Disabled'}
										testId={`agl-zone-active-${device.name}`}
									/>
								</Flex>
							</Box>
						)
					})}
				</CardBody>
			</Card>

			<DeleteModal
				isOpen={isOpen}
				onClose={handleCloseDeleteModal}
				handleDelete={handleDeleteRadarSensor}
				name={radarToDelete?.name || ''}
			/>

			<UpdateModal
				isOpen={isRestartOpen}
				onClose={onRestartClose}
				handleDelete={handleRestart}
				name=''
				headerText={t('modals.restartRadars')}
				modalBodyText={t('modals.cannotUndone')}
			/>
		</>
	)
}

export default RadarContent
