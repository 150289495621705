import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
	Flex,
	IconButton,
	Table,
	TableContainer,
	Tbody,
	Td,
	Tr,
	Text,
	useDisclosure,
} from '@chakra-ui/react'
import { MdDelete, MdEdit } from 'react-icons/md'

import DeleteModal from '@Components/_UI/Modals/DeleteModal'

import type { SiteMarker } from '@Store/types'
import { useDeleteSiteMarkerMutation } from '@Store/siteMarkers/siteMarkersApi'

type MapMarkersTableProps = {
	markers: SiteMarker[]
}

const MapMarkersTable = ({ markers }: MapMarkersTableProps) => {
	return (
		<TableContainer>
			<Table
				size='sm'
				variant='simple'
				maxW='520px'
				data-testid='site-markers-table'
			>
				<Tbody background='body_bg'>
					{markers.map(({ id, number, nickname }) => (
						<Tr key={id}>
							<Td w='100%' isTruncated>
								<Flex gap={2}>
									<Text w='20px'>{number}</Text>
									<Text maxW='320px' isTruncated>
										{nickname}
									</Text>
								</Flex>
							</Td>
							<Td maxW='80px'>
								<ActionButtons id={id} name={nickname} />
							</Td>
						</Tr>
					))}
				</Tbody>
			</Table>
		</TableContainer>
	)
}

const ActionButtons = ({ name, id }: { name: string; id: number }) => {
	const { siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)

	const navigate = useNavigate()
	const { t } = useTranslation('forms', { keyPrefix: 'mapMarkersForm' })

	const [markerToDelete, setMarkerToDelete] = useState<{
		id: number
		name: string
	} | null>(null)

	const [deleteSiteMarker] = useDeleteSiteMarkerMutation()

	const { isOpen, onOpen, onClose } = useDisclosure()

	const handleOpenDeleteModal = () => {
		onOpen()
		setMarkerToDelete((prev) => ({ ...prev, id, name }))
	}

	const handleDelete = async () => {
		try {
			if (markerToDelete) {
				await deleteSiteMarker({
					siteId: siteId,
					markerId: markerToDelete.id,
				})
			}
		} catch (error) {
			console.error(t('errors.deleteError'), error)
		}
	}

	const handleCloseDeleteModal = () => {
		onClose()
		setMarkerToDelete(null)
	}

	const handleEditMapMarker = () =>
		navigate(`/${siteId}/markers/${id}/settings`)

	return (
		<>
			<Flex gap={0} justifyContent='center'>
				<IconButton
					variant='ghost'
					colorScheme='white'
					aria-label='edit'
					icon={<MdEdit />}
					onClick={() => handleEditMapMarker()}
					data-testid={`${name}-edit`}
				/>
				<IconButton
					variant='ghost'
					colorScheme='white'
					aria-label='delete'
					icon={<MdDelete />}
					onClick={() => handleOpenDeleteModal()}
					data-testid={`${name}-delete`}
				/>
			</Flex>
			<DeleteModal
				isOpen={isOpen}
				handleDelete={handleDelete}
				onClose={handleCloseDeleteModal}
				name={markerToDelete?.name || ''}
				headerText={t('modal.header')}
				fromText={t('modal.fromText')}
			/>
		</>
	)
}

export default MapMarkersTable
