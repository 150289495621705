import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// Chakra
import {
	Box,
	Card,
	CardHeader,
	CardBody,
	Flex,
	Text,
	IconButton,
	useDisclosure,
} from '@chakra-ui/react'
import { MdCamera } from 'react-icons/md'

// Components
import { Chunk, StatusDot } from '.'
import ActionSection from '../ActionSection'
import ActiveSensorButton from '../ActiveSensorButton'
import EditButton from '../EditButton'
import EditPalette from '../EditPalette'
import Tooltip from '@UI/Tooltip/Tooltip'
import DeleteModal from '@UI/Modals/DeleteModal'
import InfoText from '@UI/InfoText/InfoText'
import { formatDirection } from '@Utils/formatUtils'

import { type SiteInstallation } from '@Store/types'
import { setActiveCameraId } from '@Store/ui/uiSlice'
import { useAppDispatch } from '@Store/index'
import { useDeleteCameraMutation } from '@Store/cameras/camerasApi'
import {
	useGetSiteLiveQuery,
	selectIsSiteCalibrationMode,
	selectSiteHasActiveRadar,
} from '@Store/sites/sitesWsApi'

import useAuth from '@Hooks/useAuth'

// Types
type CameraContentProps = {
	devices: SiteInstallation['cameras']
}

const CameraContent = ({ devices }: CameraContentProps) => {
	const { siteId: siteIdParam, installationId } = useParams()
	const siteId = Number(siteIdParam)
	const dispatch = useAppDispatch()

	const { t } = useTranslation('panels', {
		keyPrefix: 'devicesContent',
	})

	const { isCalibrationMode, hasActiveRadar } = useGetSiteLiveQuery(siteId, {
		selectFromResult: ({ data }) => ({
			isCalibrationMode: selectIsSiteCalibrationMode(data),
			hasActiveRadar: selectSiteHasActiveRadar(data),
		}),
	})

	const calibrationDisabled = !isCalibrationMode || !hasActiveRadar

	const calibrationTooltip = !hasActiveRadar
		? t('cameraContent.calibrationActiveRadar')
		: !isCalibrationMode
				? t('cameraContent.calibrationMode') // eslint-disable-line prettier/prettier
				: undefined // eslint-disable-line prettier/prettier

	const baseRoute = `/${siteId}/installation/${installationId}`

	const [cameraToDelete, setCameraToDelete] = useState<{
		id: number
		name: string
	} | null>(null)

	const { isOpen, onOpen, onClose } = useDisclosure()

	const [deleteCamera] = useDeleteCameraMutation()

	const handleOpenDeleteModal = (id: number, name: string) => {
		onOpen()
		setCameraToDelete((prev) => ({ ...prev, id, name }))
	}

	const handleDeleteCamera = async () => {
		if (cameraToDelete?.id) {
			try {
				await deleteCamera({ siteId, sensorId: cameraToDelete.id })
			} catch (error) {
				console.error('Delete camera error', error)
			}
		}
	}

	const handleCloseDeleteModal = () => {
		onClose()
		setCameraToDelete(null)
	}

	const { isSiteManager } = useAuth()

	return (
		<>
			<Card variant='devices'>
				{isSiteManager && (
					<CardHeader textAlign='right' marginLeft='auto'>
						<EditButton
							to={`${baseRoute}/camera/add`}
							type='add'
							label={t('buttons.add')}
							testId='add-camera'
						/>
					</CardHeader>
				)}
				<CardBody>
					{devices.map((device) => (
						<Box key={device.id} paddingBlockEnd={4}>
							<Flex paddingInlineStart={2}>
								<Box w={6}>
									<StatusDot
										color={device.status_color}
										type='camera'
										id={device.id}
									/>
								</Box>
								<Text fontSize='md' fontWeight={500} data-testid='device-name'>
									{device.name}
								</Text>
								<ActionSection>
									<Tooltip label={t('tooltips.openCamera')}>
										<IconButton
											icon={<MdCamera />}
											variant='icon'
											aria-label='open camera'
											isDisabled={device.status_color !== 'green'}
											onClick={() => {
												dispatch(setActiveCameraId(device.id))
											}}
										/>
									</Tooltip>
									<ActiveSensorButton deviceId={device.id} />
									{isSiteManager && (
										<EditPalette testId={`device-menu-${device.name}`}>
											<EditButton
												to={`${baseRoute}/camera/${device.id}/settings`}
												label={t('buttons.edit')}
												testId='edit-device'
											/>
											<EditButton
												to={`${baseRoute}/camera/${device.id}/calibrate`}
												type='calibrate'
												disabled={calibrationDisabled}
												tooltip={calibrationTooltip}
											/>
											<EditButton
												onClick={() =>
													handleOpenDeleteModal(device.id, device.name)
												}
												label={t('buttons.delete')}
												type='delete'
												testId='delete-device'
											/>
										</EditPalette>
									)}
								</ActionSection>
							</Flex>
							{device.status_color === 'orange' && device.error && (
								<Box my={3}>
									<InfoText
										title={t('cameraContent.unauthorisedCamera')}
										lineHeight={1.2}
									>
										{t('cameraContent.unauthorisedConnection')}
									</InfoText>
								</Box>
							)}
							<Flex flexWrap='wrap' paddingInlineStart={8} paddingBlockEnd={4}>
								<Chunk
									units='deg'
									label={t('content.direction')}
									value={formatDirection(device.direction) ?? '-'}
									testId='direction'
								/>
								<Chunk
									label={t('content.temperature')}
									value={
										device.status_color !== 'green'
											? '-'
											: device.droneoptid_temperature
									}
									testId='temperature'
								/>
								<Chunk
									label={t('content.serial')}
									value={device.serial_number}
									testId='serial-number'
								/>
								<Chunk
									label={t('content.version')}
									value={device.software_version ?? '-'}
									testId='software-version'
								/>
							</Flex>
						</Box>
					))}
				</CardBody>
			</Card>

			<DeleteModal
				isOpen={isOpen}
				onClose={handleCloseDeleteModal}
				handleDelete={handleDeleteCamera}
				name={cameraToDelete?.name || ''}
			/>
		</>
	)
}

export default CameraContent
