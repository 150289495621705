import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { distance } from '@turf/turf'

import { Flex } from '@chakra-ui/react'

import Headings from '@UI/Headings/Headings'
import SpinnerText from '@UI/SpinnerText/SpinnerText'

import { useAppDispatch, useAppSelector } from '@Store/index'
import {
	selectActiveTargetId,
	setCameraCalibrationStep,
} from '@Store/ui/uiSlice'

import {
	selectSiteCameraIsThermal,
	selectSiteCameraAltitude,
	selectSiteCameraPosition,
	selectSiteInstallationAltitude,
	useGetSiteLiveQuery,
} from '@Store/sites/sitesWsApi'
import { useCalibrateCameraMutation } from '@Store/cameras/camerasApi'

import type {
	RadarDetectionMap,
	TiltResult,
	CalibrationTilt,
} from '../../types'
import type { Feature, Point, Properties } from '@turf/turf'

import AltitudeChart from './AltitudeChart'
import CameraTiltChart from './CameraTiltChart'
import LostDetectionAlert from '../LostDetectionAlert'

import { getLinearRegression } from '@Utils/cameraCalibration'
import { useInterval } from '@Hooks/useInterval'

const MIN_ALTITUDE_DIFF = 50
const MIN_ALTITUDE_TOLERANCE = 2
const DEFAULT_TARGET_ALTITUDE = 75

type TiltCalibrationProps = {
	radarDetections: RadarDetectionMap
	sensorCoordinates: Feature<Point, Properties>
	tiltResult?: TiltResult
	setTiltResult: any
}

const TiltCalibration = ({
	radarDetections,
	sensorCoordinates,
	tiltResult,
	setTiltResult,
}: TiltCalibrationProps) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'siteInstallations.cameraCalibration',
	})
	const {
		sensorId: sensorIdParam,
		installationId: installationIdParam,
		siteId: siteIdParam,
	} = useParams()
	const siteId = Number(siteIdParam)
	const installationId = Number(installationIdParam)
	const sensorId = Number(sensorIdParam)

	const dispatch = useAppDispatch()
	const [calibrateCamera] = useCalibrateCameraMutation()

	const {
		cameraIsThermal,
		cameraPosition,
		cameraAltitude,
		installationAltitude,
	} = useGetSiteLiveQuery(siteId, {
		selectFromResult: ({ data }) => ({
			cameraIsThermal: selectSiteCameraIsThermal(data, sensorId),
			cameraPosition: selectSiteCameraPosition(data, sensorId),
			cameraAltitude: selectSiteCameraAltitude(data, sensorId),
			installationAltitude: selectSiteInstallationAltitude(
				data,
				installationId
			),
		}),
	})

	const targetId = useAppSelector(selectActiveTargetId) ?? ''

	const [lastCameraTilt, setLastCameraTilt] = useState(cameraPosition.tilt)
	const [calibrationTilt, setCalibrationTilt] = useState<CalibrationTilt>({
		x: [],
		y: [],
	})

	const { altitude, longitude, latitude } = radarDetections?.[targetId] || {}

	const [targetAltitude, setTargetAltitude] = useState<number>(
		DEFAULT_TARGET_ALTITUDE
	)

	// Set target altitude to first radar detected altitude
	useEffect(() => {
		if (targetAltitude === DEFAULT_TARGET_ALTITUDE && altitude) {
			setTargetAltitude(altitude + MIN_ALTITUDE_DIFF)
		}
	}, [altitude, targetAltitude])

	useInterval(() => {
		// If cameraPosition.tilt is 1, maximum tilt was reached thus tilt values are irrelevant for calibration
		if (cameraPosition.tilt !== lastCameraTilt && cameraPosition.tilt !== 1) {
			setLastCameraTilt(cameraPosition.tilt)

			const detectionDistance =
				1000 * distance(sensorCoordinates, [longitude, latitude])
			const totalAltitude = cameraAltitude + installationAltitude

			const verticalAngle =
				Math.atan((altitude - totalAltitude) / detectionDistance) *
				(180 / Math.PI)

			setCalibrationTilt((prev) => ({
				x: [...prev.x, verticalAngle],
				y: [...prev.y, cameraPosition.tilt],
			}))
		}
	}, 250)

	// Check if the target altitude has been reached
	useInterval(() => {
		if (altitude - targetAltitude > -MIN_ALTITUDE_TOLERANCE) {
			calibrateCamera({ cameraId: sensorId, siteId, action: 'start-3' })
			dispatch(setCameraCalibrationStep(3))
		}
		setTiltResult(getLinearRegression(calibrationTilt, cameraIsThermal))
	}, 2000)

	return (
		<Flex w='430px' p='16px' bgColor='body_bg' direction='column' gap='16px'>
			{!targetId && <LostDetectionAlert />}
			<SpinnerText
				text={`Fly to ${targetAltitude.toFixed(2)}m high`}
				status='active'
			/>
			<Headings.FieldHeading title={t('headers.tiltCalibration')} />
			<Flex w='410px'>
				<AltitudeChart altitude={altitude} targetAltitude={targetAltitude} />
				<CameraTiltChart
					tiltResult={tiltResult}
					calibrationTilt={calibrationTilt}
				/>
			</Flex>
		</Flex>
	)
}

export default TiltCalibration
