const EpirusLogo = () => {
	return (
		<svg
			width='308'
			height='122'
			viewBox='0 0 308 122'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='navigation-customer-epirus-logo'
		>
			<path
				d='M264.558 51.0855C262.445 53.328 260.41 55.5484 258.302 57.6915C257.822 58.1831 257.662 58.4813 258.049 59.0834C258.622 59.9781 259.108 60.9337 259.643 61.8948C261.7 59.7904 263.659 57.8019 265.601 55.7914C266.378 54.985 266.472 54.9629 266.88 56.0455C267.669 58.1389 268.43 60.2433 269.181 62.3532C271.233 68.0864 274.013 73.4441 277.362 78.5311C282.481 86.3025 288.637 93.1073 296.073 98.73C297.204 99.5862 298.401 100.359 299.565 101.171C299.538 101.271 299.505 101.365 299.477 101.464C298.925 101.304 298.324 101.227 297.822 100.967C291.76 97.8132 285.631 94.7643 279.673 91.4226C272.695 87.5121 266.367 82.6681 260.652 77.0619C254.904 71.417 250.557 64.8939 247.313 57.4761C241.554 44.3084 233.6 32.5657 222.914 22.8059C221.342 21.3699 219.533 20.1934 217.834 18.8954C217.541 18.6689 217.254 18.437 216.962 18.2105C216.995 18.1055 217.028 18.0061 217.067 17.9012C217.475 17.9619 217.9 17.9675 218.286 18.0945C244.412 26.3409 257.061 38.321 264.552 51.0911L264.558 51.0855Z'
				fill='white'
			/>
			<path
				d='M131.733 83.5077C129.173 83.5077 126.856 83.6016 124.556 83.4414C123.949 83.3972 123.265 82.6681 122.846 82.0882C120.242 78.4814 117.688 74.836 115.156 71.174C114.626 70.4063 114.097 70.0749 113.098 70.1467C111.068 70.2903 109.027 70.2571 106.986 70.2406C106.219 70.235 105.949 70.467 105.96 71.2679C106.032 74.9796 106.032 78.6968 106.092 82.4085C106.103 83.1984 105.921 83.574 105.044 83.5463C103.395 83.4911 101.74 83.5077 100.09 83.5187C99.3954 83.5187 99.1141 83.2978 99.1141 82.5356C99.1361 70.4118 99.1361 58.288 99.1141 46.1642C99.1141 45.402 99.3954 45.1921 100.102 45.1976C105.69 45.2142 111.278 45.1369 116.866 45.2529C119.613 45.3081 122.311 45.9267 124.766 47.2358C130.122 50.0858 131.347 56.5813 130.089 60.8398C128.942 64.7172 126.421 67.1861 122.702 68.567C122.283 68.7216 121.864 68.8818 121.323 69.0807C124.793 73.8915 128.197 78.614 131.722 83.5077H131.733ZM106.004 57.8351H105.949C105.949 59.6909 105.977 61.5413 105.938 63.3971C105.927 64.0544 106.081 64.3471 106.804 64.3361C110.031 64.2864 113.264 64.3637 116.491 64.2201C117.782 64.1593 119.144 63.839 120.319 63.2977C122.956 62.0826 124.197 59.4921 123.806 56.7415C123.486 54.4548 121.268 51.7484 118.526 51.6765C114.566 51.5716 110.605 51.5495 106.644 51.555C106.429 51.555 106.048 52.1516 106.037 52.4774C105.977 54.2615 106.01 56.0455 106.01 57.8296L106.004 57.8351Z'
				fill='white'
			/>
			<path
				d='M15.2916 77.5701C15.7109 77.5922 16.086 77.6253 16.4666 77.6253C23.0036 77.6253 29.5461 77.6253 36.0831 77.5811C37.0595 77.5756 37.396 77.8573 37.3409 78.8294C37.2691 80.0501 37.2967 81.2818 37.3353 82.508C37.3574 83.2647 37.1092 83.5629 36.3093 83.5574C28.4704 83.5132 20.6315 83.4967 12.7927 83.4746C11.3915 83.4746 9.9903 83.4746 8.50085 83.4746V45.391C8.83184 45.3523 9.20144 45.2805 9.57105 45.2805C18.3588 45.2529 27.1465 45.2418 35.9342 45.2032C36.6844 45.2032 37.0099 45.4076 36.9713 46.225C36.9106 47.6224 36.9216 49.0309 36.9713 50.4283C36.9933 51.1629 36.7341 51.4004 36.0004 51.3949C29.5682 51.3617 23.136 51.3562 16.7038 51.3396C16.2901 51.3396 15.8708 51.3396 15.3633 51.3396C15.3357 51.8367 15.2971 52.2455 15.2971 52.6542C15.2806 55.1066 15.3302 57.5589 15.242 60.0113C15.2089 60.9613 15.4792 61.2209 16.4004 61.2154C21.9555 61.1878 27.5105 61.2154 33.0601 61.2154C34.6213 61.2154 34.6102 61.2154 34.5882 62.7675C34.5716 63.9605 34.5495 65.1536 34.5992 66.3411C34.6323 67.1696 34.3013 67.4402 33.4849 67.4292C27.825 67.385 22.1651 67.4016 16.5107 67.3353C15.5288 67.3243 15.253 67.6004 15.2751 68.5781C15.3413 71.5165 15.2971 74.4604 15.2971 77.5646L15.2916 77.5701Z'
				fill='white'
			/>
			<path
				d='M45.1798 64.3527C45.1798 58.288 45.1908 52.2234 45.1632 46.1587C45.1632 45.391 45.4556 45.1921 46.1562 45.1977C51.1817 45.2198 56.2182 45.0596 61.2381 45.2695C65.9326 45.4628 70.2189 46.9486 72.922 51.1132C75.0238 54.3499 75.4761 58.0008 74.4556 61.7125C73.2199 66.2085 70.0976 68.8653 65.7285 70.3179C62.2697 71.4668 58.7282 71.2845 55.1866 71.3066C54.3757 71.3066 53.5703 71.3287 52.7594 71.29C52.125 71.2624 51.9043 71.4889 51.9098 72.1351C51.9484 75.5706 51.9209 79.0061 51.9815 82.4417C51.9981 83.2591 51.794 83.585 50.9389 83.5574C49.3612 83.5077 47.7725 83.5298 46.1948 83.5574C45.4556 83.574 45.1467 83.3806 45.1522 82.5466C45.1908 76.482 45.1742 70.4173 45.1742 64.3527H45.1798ZM51.9209 58.4427C51.9209 58.4427 51.9153 58.4427 51.9098 58.4427C51.9098 60.3648 51.9374 62.2925 51.8933 64.2146C51.8767 64.9105 52.1029 65.1425 52.7925 65.1259C55.7769 65.0541 58.7557 65.021 61.7401 64.9713C63.2351 64.9437 64.559 64.4466 65.6844 63.4689C67.8248 61.6131 68.746 59.3319 67.9903 56.5261C67.2345 53.7036 65.4251 51.892 62.4683 51.6987C59.186 51.4832 55.8817 51.5661 52.5883 51.5219C51.9153 51.5109 51.9153 51.9693 51.9153 52.4554C51.9209 54.4493 51.9153 56.4432 51.9153 58.4372L51.9209 58.4427Z'
				fill='white'
			/>
			<path
				d='M144.961 57.5368C144.961 61.2154 144.807 64.905 145 68.5781C145.232 72.9139 147.151 76.2113 151.046 77.4651C157.412 79.5198 163.629 76.5869 164.33 69.1801C164.512 67.269 164.429 65.3303 164.423 63.4027C164.407 57.686 164.385 51.9748 164.324 46.2581C164.313 45.4131 164.578 45.159 165.405 45.1921C166.983 45.2474 168.572 45.2253 170.149 45.1921C170.861 45.1811 171.137 45.4186 171.137 46.1698C171.109 53.3225 171.187 60.4753 171.076 67.6225C171.021 71.3397 170.221 74.941 167.992 78.0396C165.642 81.3039 162.432 83.1266 158.432 83.7673C155.073 84.3086 151.747 84.3859 148.536 83.3309C142.644 81.3922 139.428 77.1448 138.623 71.0746C138.38 69.2409 138.253 67.3795 138.242 65.5347C138.187 59.1552 138.22 52.7757 138.176 46.3962C138.171 45.4849 138.408 45.1424 139.357 45.1866C140.863 45.2584 142.38 45.2363 143.891 45.1921C144.702 45.17 144.994 45.4296 144.983 46.2802C144.939 50.0306 144.967 53.781 144.967 57.5313C144.967 57.5313 144.967 57.5313 144.972 57.5313L144.961 57.5368Z'
				fill='white'
			/>
			<path
				d='M206.409 49.3181C205.024 51.2513 203.844 52.8917 202.603 54.615C199.811 52.1792 196.551 50.9917 192.988 50.6382C190.941 50.4338 188.9 50.8149 187.35 52.2068C184.878 54.4217 185.695 58.2439 188.916 59.4479C191.636 60.4642 194.499 61.0994 197.246 62.0494C199.221 62.7343 201.213 63.48 203.005 64.5239C205.984 66.2527 207.319 68.9371 207.435 72.461C207.645 78.73 202.586 82.7068 198.085 83.6127C190.417 85.1481 183.902 82.9167 177.817 78.0727C178.562 77.1669 179.246 76.3052 179.969 75.4712C180.559 74.7918 181.116 73.7479 181.833 73.6043C182.418 73.4883 183.229 74.4549 183.935 74.952C187.62 77.5756 191.691 78.614 196.193 78.0285C199.69 77.5756 201.781 74.1346 200.253 71.2017C199.96 70.6438 199.464 70.0418 198.912 69.7987C197.064 68.9813 195.161 68.2688 193.252 67.6115C190.472 66.6559 187.581 65.9821 184.884 64.8387C180.884 63.1486 179.202 59.7683 179.367 55.3441C179.555 50.246 183.152 46.6724 188.282 45.2032C192.375 44.0322 196.38 44.5404 200.225 46.1587C202.31 47.037 204.269 48.2079 206.409 49.3071V49.3181Z'
				fill='white'
			/>
			<path
				d='M298.241 20.6353C298.076 21.0275 297.949 21.4362 297.739 21.8063C294.645 27.1805 291.539 32.5437 288.444 37.9179C286.486 41.3203 284.671 44.8055 282.564 48.114C280.787 50.9033 278.029 52.3781 274.703 52.5382C272.176 52.6598 269.639 52.5824 267.107 52.5824C266.549 52.5824 266.058 52.5217 266.086 51.7318C266.158 49.3126 266.141 46.8934 266.24 44.4797C266.4 40.7569 267.967 37.7909 271.26 35.9019C279.055 31.439 286.878 27.0258 294.683 22.5795C295.825 21.9278 296.912 21.1932 298.026 20.4917C298.098 20.5414 298.164 20.5856 298.236 20.6353H298.241Z'
				fill='white'
			/>
			<path
				d='M82.0903 64.4632C82.0903 58.3267 82.0958 52.1958 82.0793 46.0593C82.0793 45.4683 82.2779 45.2087 82.8516 45.2087C84.6775 45.2087 86.509 45.2087 88.3349 45.2253C89.0797 45.2308 89.008 45.7832 89.008 46.2803C89.008 49.7876 88.9914 53.2894 88.9969 56.7968C89.0024 65.3524 89.0079 73.9026 89.0466 82.4583C89.0466 83.2868 88.8094 83.5685 87.9653 83.5409C86.3159 83.4856 84.661 83.5188 83.0116 83.5409C82.3717 83.5519 82.0793 83.3973 82.0848 82.6516C82.1179 76.587 82.1014 70.5223 82.1014 64.4577L82.0903 64.4632Z'
				fill='white'
			/>
			<path
				d='M298.33 58.0837C298.192 58.393 298.081 58.7133 297.916 59.0005C295.246 63.6402 292.576 68.2798 289.884 72.9084C289.288 73.9357 288.621 74.9244 287.964 75.9186C286.988 77.4044 285.537 78.15 283.81 78.2605C282.133 78.3655 280.445 78.3434 278.763 78.2826C278.454 78.2716 277.908 77.8297 277.897 77.5646C277.797 75.8965 277.687 74.2064 277.836 72.5438C278.051 70.2019 279.485 68.6499 281.499 67.5065C286.74 64.5294 291.953 61.5026 297.177 58.4924C297.502 58.3046 297.817 58.1002 298.131 57.9069C298.197 57.9677 298.263 58.0229 298.33 58.0837Z'
				fill='white'
			/>
			<path
				d='M270.24 20.6297C270.141 20.8783 270.074 21.1434 269.942 21.3699C267.564 25.4516 265.099 29.4892 262.82 33.6207C261.673 35.703 260.001 36.6475 257.74 36.7635C256.829 36.8132 255.908 36.8298 255.003 36.7469C254.667 36.7138 254.099 36.3879 254.071 36.1449C253.862 34.1344 253.564 32.1018 254.65 30.1962C255.246 29.1468 256.101 28.484 257.149 27.8985C261.215 25.6118 265.225 23.2368 269.252 20.8893C269.517 20.7347 269.782 20.5911 270.052 20.442C270.113 20.5027 270.179 20.5635 270.24 20.6187V20.6297Z'
				fill='white'
			/>
			<path
				d='M231.228 61.9334C230.737 61.0276 230.201 60.1715 229.81 59.2491C229.683 58.9564 229.799 58.3764 230.025 58.1444C233.103 55.0348 236.22 51.9638 239.337 48.8817C239.48 48.7381 239.657 48.6332 239.949 48.4178C240.611 49.4065 241.245 50.351 241.94 51.3894C238.355 54.9188 234.824 58.393 231.228 61.9279V61.9334Z'
				fill='white'
			/>
			<path
				d='M249.382 67.0591C249.995 67.9097 250.634 68.7603 251.214 69.6496C251.285 69.7545 251.12 70.108 250.971 70.2572C247.744 73.5159 244.511 76.7637 241.267 80.0114C241.13 80.1495 240.931 80.2268 240.76 80.3318C240.203 79.4646 239.629 78.6803 239.193 77.8242C239.072 77.5922 239.271 77.0454 239.497 76.8134C241.858 74.4218 244.246 72.0578 246.641 69.6993C247.507 68.8432 248.395 68.0092 249.377 67.0591H249.382Z'
				fill='white'
			/>
			<path
				d='M261.987 96.3826C261.491 95.8579 260.845 95.1841 260.211 94.4992C259.703 93.9523 260.194 93.5933 260.514 93.2675C262.059 91.6933 263.609 90.1247 265.198 88.5947C265.981 87.8435 266.853 87.1862 267.796 86.3909C268.436 86.8659 269.379 87.5563 270.306 88.2357C267.553 90.9311 264.839 93.5878 261.987 96.3771V96.3826Z'
				fill='white'
			/>
		</svg>
	)
}

export default EpirusLogo
