type ControllerMarkerIconProps = {
	color?: string
}

const ControllerMarkerIcon = ({ color }: ControllerMarkerIconProps) => {
	return (
		<svg
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g
				fill={color}
				transform='translate(6.00000, 0.000000)'
				data-testid={`controller-icon-${color}`}
			>
				<path d='M0,16.000025 C0,14.895425 0.895431,14.000025 2,14.000025 L26,14.000025 C27.1046,14.000025 28,14.895425 28,16.000025 L28,27.000025 C28,28.104525 27.1046,29.000025 26,29.000025 L2,29.000025 C0.895431,29.000025 0,28.104525 0,27.000025 L0,16.000025 Z M6,26.000025 C8.20914,26.000025 10,24.209125 10,22.000025 C10,19.790825 8.20914,18.000025 6,18.000025 C3.79086,18.000025 2,19.790825 2,22.000025 C2,24.209125 3.79086,26.000025 6,26.000025 Z M22,26.000025 C24.2091,26.000025 26,24.209125 26,22.000025 C26,19.790825 24.2091,18.000025 22,18.000025 C19.7909,18.000025 18,19.790825 18,22.000025 C18,24.209125 19.7909,26.000025 22,26.000025 Z M18,25.000025 L10,25.000025 L10,27.000025 L18,27.000025 L18,25.000025 Z' />
				<rect x='5' y='8.8817842e-16' width='2' height='13' rx='1' />
				<rect x='21' y='8.8817842e-16' width='2' height='13' rx='1' />
			</g>
		</svg>
	)
}

export default ControllerMarkerIcon
