import { useTranslation } from 'react-i18next'

import {
	Table,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react'

import Modal from '@UI/Modals/Modal'

import type { SiteLog } from '@Store/types'

type ChangesModalProps = {
	isOpen: boolean
	onClose: () => void
	objectChanges: SiteLog['object_changes']
}

const ChangesModal = ({
	isOpen,
	onClose,
	objectChanges,
}: ChangesModalProps) => {
	const { t } = useTranslation('pages', {
		keyPrefix: 'analytics.siteLogs.modal',
	})

	return (
		<Modal headerText='Changes' isOpen={isOpen} onClose={onClose} size='3xl'>
			<TableContainer pb={5} m={2}>
				<Table data-testid='changes-table' variant='simple' size='lg'>
					<Thead>
						<Tr>
							<Th>{t('attribute')}</Th>
							<Th>{t('oldValue')}</Th>
							<Th>{t('newValue')}</Th>
						</Tr>
					</Thead>
					<Tbody>
						{/* An example of a log change is {A: ['b', 'c']}. This converts
             the data structure into a table row where 'A', 'b', and 'c' are the cells.  */}
						{objectChanges &&
							Object.entries(objectChanges).map(([key, values]) => (
								<Tr key={key}>
									<Td>{key}</Td>
									{values.map((value, index) => (
										<Td key={index}>{value}</Td>
									))}
								</Tr>
							))}
					</Tbody>
				</Table>
			</TableContainer>
		</Modal>
	)
}

export default ChangesModal
