import { useMemo, useRef, type ReactElement } from 'react'
import { nanoid } from '@reduxjs/toolkit'

import { Marker } from 'react-leaflet'
import L, {
	type LatLngExpression,
	type LatLngLiteral,
	type LeafletEventHandlerFnMap,
} from 'leaflet'
import { renderToStaticMarkup } from 'react-dom/server'

export type BaseMarkerProps = {
	position: LatLngExpression
	draggable?: boolean
	onClick?: (e?: L.LeafletMouseEvent) => void
	onMouseDown?: (e?: L.LeafletMouseEvent) => void
	onDragEnd?: (position: LatLngLiteral) => void
	icon?: ReactElement
	iconSize?: number[]
	iconAnchor?: number[]
	children?: ReactElement | null
	alt?: string
	interactive?: boolean
}

const BaseMarker = ({
	position,
	draggable = false,
	interactive = false,
	onClick,
	onMouseDown,
	onDragEnd,
	children,
	alt,
	icon,
	iconSize = [50, 50],
	iconAnchor = [25, 25],
}: BaseMarkerProps) => {
	const markerRef = useRef<L.Marker>(null)

	const eventHandlers: LeafletEventHandlerFnMap = useMemo(
		() => ({
			dragend() {
				const marker = markerRef.current
				if (marker) {
					typeof onDragEnd === 'function' &&
						onDragEnd(marker.getLatLng().wrap())
				}
			},
			click(e) {
				typeof onClick === 'function' && onClick(e)
			},
			mousedown(e) {
				typeof onMouseDown === 'function' && onMouseDown(e)
			},
		}),
		[onDragEnd, onClick, onMouseDown]
	)

	const newIcon = new (L.divIcon as any)({
		html: icon && renderToStaticMarkup(icon),
		iconSize,
		iconAnchor,
		className: '',
	})
	return (
		<Marker
			key={nanoid()}
			position={position}
			icon={newIcon}
			eventHandlers={eventHandlers}
			draggable={draggable}
			ref={markerRef}
			alt={alt}
			interactive={
				interactive ||
				typeof onDragEnd === 'function' ||
				typeof onMouseDown === 'function' ||
				typeof onClick === 'function'
			}
		>
			{children ?? children}
		</Marker>
	)
}

export default BaseMarker
