type GroundLineProps = {
	yPos?: string // percent
}

// TODO: generate y1, y2 value based on actual ground level
const GroundLine = ({ yPos = '50%' }: GroundLineProps) => {
	return (
		<g id='agl-layer-groundline'>
			<line
				y1={yPos}
				y2={yPos}
				x1='0'
				x2='100%'
				strokeWidth={3}
				stroke='var(--c2-colors-white)'
			/>
		</g>
	)
}

export default GroundLine
