import { defineStyleConfig } from '@chakra-ui/react'
import FIGMA from '@Theme/DRO/_FIGMA'

const AccordionStyles = defineStyleConfig({
	baseStyle: {
		root: {
			mt: '1.5rem',
		},
		container: {
			border: 0,
		},
		panel: {
			pt: 0,
			px: '1px',
			pb: '1px',
		},
		button: {
			px: 3,
			py: 2,
			mt: '1rem',
			textTransform: 'uppercase',
			color: FIGMA.colors.headings,
			fontWeight: 500,
			fontSize: 'sm',
			bgColor: 'divider_bg',
			_hover: {
				bgColor: 'divider_bg',
			},
			_active: {
				bgColor: 'divider_bg',
			},
			justifyContent: 'space-between',
		},
	},
})

export const Accordion = {
	...AccordionStyles,
}
