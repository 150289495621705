const stroke = 'var(--c2-colors-gray-800)'

type RadarProps = {
	x?: number
	y?: number
}

// TODO: probably need to move this up and down according to altitude setting
const Radar = ({ x = 20, y = 295 }: RadarProps) => {
	return (
		<svg width='100%' height='100%' viewBox='0 0 1240 697.50'>
			<g transform={`translate(${x}, ${y}) scale(1.5)`}>
				<line
					x1='0.5'
					y1='1'
					x2='0.5'
					y2='29.935'
					strokeWidth='2'
					stroke={stroke}
				/>
				<line
					x1='0.5'
					y1='0.405'
					x2='3.5'
					y2='11.5'
					strokeWidth='6'
					stroke={stroke}
				/>
			</g>
		</svg>
	)
}

export default Radar
