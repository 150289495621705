// Chakra
import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	Textarea,
} from '@chakra-ui/react'
// Components
import Tooltip from '@UI/Tooltip/Tooltip'
// Types
import type { RegisterType } from '@Components/FormElements/types/RegisterType'
import type { InputProps } from '@chakra-ui/input/dist/input'
import type { FormElementProps } from '@Components/FormElements/types/FormElementProps'

type TextAreaProps = FormElementProps & RegisterType<string> & InputProps

const TextArea = ({
	id,
	title,
	register,
	error,
	disabled = undefined,
	testId,
	tooltip,
	maxLength,
}: TextAreaProps) => {
	const htmlId = id ?? register?.name ?? 'textInput'
	return (
		<Tooltip label={tooltip} type='info'>
			<FormControl isInvalid={!!error} isDisabled={disabled}>
				<FormLabel htmlFor={htmlId}>{title}</FormLabel>
				<Textarea
					id={htmlId}
					{...(register ?? {})}
					w='calc(100% - 2px)'
					data-testid={testId}
					variant='filled'
					maxLength={maxLength}
				/>
				<FormErrorMessage>{error}</FormErrorMessage>
			</FormControl>
		</Tooltip>
	)
}

export default TextArea
