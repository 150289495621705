// Types
import type { FC } from 'react'
type DroneShieldLogoType = {
	width?: string
	height?: string
	testId?: string
	isPoweredBy?: boolean
}

const DroneShieldLogo: FC<DroneShieldLogoType> = ({
	width = '421px',
	height = '129px',
	testId,
	isPoweredBy = false,
}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 421 129'
			fill='none'
			data-testid={testId}
		>
			<g
				id='droneshield-logo'
				style={{ transform: isPoweredBy ? undefined : 'translateY(-12px)' }}
			>
				<path
					d='M266.1 72.5V69.7C266.1 66.8 266.8 64.8 268.3 63.7C269.8 62.6 271.7 62 274 62C276.3 62 280.1 62.3 285.3 63V65.6L274.6 65.2H273.9C272.4 65.2 271.3 65.4 270.5 66C269.7 66.5 269.3 67.7 269.3 69.7V72.5C269.3 74.3 269.6 75.4 270.3 75.9C271 76.4 272.1 76.7 273.6 76.7H279.7C284.2 76.7 286.4 79.2 286.4 84.1V87C286.4 89.9 285.5 91.8 283.6 92.8C282.1 93.6 280.1 94 277.7 94C276.7 94 274.9 93.9 272.4 93.6C269.9 93.3 268.1 93.1 267 92.9V90.3C270.1 90.6 273.7 90.8 277.7 90.8C281.4 90.8 283.2 89.5 283.2 86.9V84C283.2 82.7 282.9 81.6 282.4 80.9C281.9 80.2 281 79.8 279.7 79.8H273.7C270.4 79.8 268.3 79 267.3 77.5C266.5 76.2 266.1 74.5 266.1 72.5Z'
					fill='white'
				/>
				<path
					d='M296 93.6V62.3H299.2V76.9H314.2V62.3H317.4V93.6H314.2V80.1H299.2V93.6H296Z'
					fill='white'
				/>
				<path d='M330.9 62.3H327.7V93.7H330.9V62.3Z' fill='white' />
				<path
					d='M341.1 67.8C341.1 65.8 341.6 64.4 342.6 63.5C343.6 62.6 344.8 62.2 346.3 62.2H346.5L360 62.9V65.4H346.5C345.8 65.4 345.3 65.5 344.9 65.8C344.5 66.1 344.3 66.3 344.3 66.6C344.3 66.9 344.2 67.3 344.2 67.8V76.4L358.3 76.8V79.3L344.2 79.7V88.3C344.2 89 344.4 89.5 344.7 89.9C345 90.2 345.3 90.4 345.6 90.5C345.9 90.5 346.3 90.6 346.9 90.6H360V93L346.9 93.7H346.3C342.8 93.7 341.1 91.9 341.1 88.3V67.8Z'
					fill='white'
				/>
				<path
					d='M369.7 88.2V62.2H372.9V88.2C372.9 89.3 373.2 89.9 373.8 90.2C374.3 90.4 374.8 90.5 375.6 90.5H388.7V93.7H375.6C374 93.7 372.7 93.4 371.7 92.9C370.3 92.1 369.7 90.6 369.7 88.2Z'
					fill='white'
				/>
				<path
					d='M401 90.3H409.5C411.3 90.3 412.8 89.7 414 88.5C416.3 86.4 417.4 83 417.4 78.3C417.4 73.6 416.7 70.3 415.4 68.3C414 66.3 412.1 65.3 409.5 65.3H401V90.3ZM397.8 93.6V62.3H409.5C411.4 62.3 413 62.6 414.4 63.4C415.8 64.1 416.9 65 417.6 66C418.4 67.1 419 68.3 419.5 69.8C420.3 72.2 420.7 74.9 420.7 78.1C420.7 81.2 420.2 84.1 419.2 86.6C418 89.5 416.2 91.5 413.9 92.7C412.6 93.3 411.2 93.6 409.6 93.6H397.8Z'
					fill='white'
				/>
				<path
					d='M36.1 67.1C28.3 71.3 21.1 73.2 20.1 71.4C19.1 69.5 24.6 64.6 32.4 60.4C40.2 56.2 47.4 54.3 48.4 56.1C49.4 58 43.9 62.9 36.1 67.1ZM67.3 72.4C68.8 75.2 59.5 83.2 46.6 90.2C33.6 97.2 21.9 100.6 20.4 97.7C18.9 94.9 28.2 86.9 41.1 79.9C54 73 65.7 69.6 67.3 72.4ZM60.4 56.5C67.2 52.8 73.6 50.4 77.5 49.8V34.8L38.9 27.7L0.299988 34.8V90.2C3.29999 87.6 7.8 84.6 13 81.8C23.4 76.2 32.8 73.4 34 75.6C35.2 77.8 27.7 84.2 17.3 89.8C11.2 93.1 5.39999 95.4 1.39999 96.3C2.29999 98.8 3.79999 101.3 5.89999 103.9C9.29999 108.2 14 112.5 20 116.9C27.5 122.3 35.1 126.3 38.9 128.2C42.6 126.4 50.3 122.3 57.8 116.9C63.8 112.5 68.6 108.2 71.9 103.9C75.7 99.1 77.6 94.5 77.6 90.2V56.2C74.6 58.8 70.1 61.8 64.9 64.6C54.5 70.2 45.1 72.9 43.9 70.7C42.5 68.4 50 62.1 60.4 56.5Z'
					fill='#F58720'
				/>
				<path
					d='M127.1 78C127.1 72.8 126.3 69.6 124.6 68.4C123.9 67.9 122.9 67.6 121.7 67.6H114.8V88.3H121.7C125.3 88.3 127.1 84.9 127.1 78ZM108.9 93.6V62.3H122.2C129.5 62.3 133.1 67.6 133.1 78.1C133.1 84.6 131.5 89.1 128.4 91.6C126.8 92.9 124.7 93.6 122.2 93.6H108.9Z'
					fill='white'
				/>
				<path
					d='M147.1 76.4H154.2C155.3 76.4 156.1 76.1 156.6 75.6C157.1 75.1 157.3 73.9 157.3 72.1C157.3 70.3 157 69.2 156.5 68.5C156 67.9 155.2 67.5 154.2 67.5H147.1V76.4ZM154.8 62.3C157.5 62.3 159.6 63 160.9 64.4C162.5 66.1 163.3 68.6 163.3 72.1C163.3 76.1 162.5 78.8 160.8 80.1C160.3 80.5 159.8 80.8 159.6 80.9C159.3 81 158.9 81.2 158.4 81.3L164.4 93.6H157.6L152.4 81.7L147.1 81.1V93.6H141.1V62.3H154.8Z'
					fill='white'
				/>
				<path
					d='M177 71.7C176.9 72.5 176.7 73.4 176.7 74.4C176.6 75.5 176.6 76.8 176.6 78.4C176.6 80 176.6 81.4 176.8 82.5C176.9 83.6 177.1 84.6 177.4 85.4C177.7 86.2 178.1 86.8 178.6 87.2C179.6 87.9 181.1 88.3 183.2 88.3C185.2 88.3 186.7 87.9 187.7 87.2C189.1 86.1 189.8 83.1 189.8 78.2C189.8 74.9 189.6 72.6 189.3 71.6C189 70.6 188.6 69.7 188.3 69.3C188 68.8 187.6 68.5 187 68.2C186.1 67.8 185.1 67.6 183.9 67.6C182.7 67.6 181.9 67.6 181.4 67.7C180.9 67.7 180.4 67.8 179.9 68C179.4 68.1 179 68.4 178.7 68.7C178.4 69 178.1 69.4 177.8 69.9C177.3 70.3 177.1 71 177 71.7ZM171.1 85.9C170.9 84.9 170.7 83.8 170.6 82.5C170.5 81.2 170.4 79.7 170.4 77.8C170.4 76 170.5 74.3 170.7 72.7C170.9 71.1 171.2 69.7 171.6 68.6C172 67.5 172.5 66.5 173.1 65.7C173.7 64.9 174.4 64.3 175 63.8C175.6 63.3 176.4 63 177.4 62.7C178.9 62.3 180.4 62.1 182.1 62.1C183.7 62.1 184.9 62.1 185.7 62.2C186.5 62.3 187.3 62.4 188.1 62.6C188.9 62.8 189.7 63 190.3 63.4C190.9 63.7 191.5 64.2 192.1 64.7C192.7 65.2 193.2 65.9 193.6 66.7C194 67.5 194.4 68.5 194.7 69.5C195.3 71.6 195.6 74.1 195.6 76.9C195.6 79.7 195.5 81.9 195.3 83.4C195.1 84.9 194.8 86.2 194.5 87.3C194.2 88.4 193.7 89.3 193.1 90.1C192.5 90.9 191.9 91.5 191.3 92C190.7 92.5 189.9 92.8 189 93.1C186.9 93.7 184.1 93.9 180.6 93.7C178.8 93.6 177.5 93.4 176.7 93.1C175.9 92.8 175.2 92.4 174.6 92C174 91.6 173.5 91.1 173.1 90.6C172.7 90 172.3 89.4 171.9 88.6C171.6 87.8 171.4 86.9 171.1 85.9Z'
					fill='white'
				/>
				<path
					d='M204 93.6V62.3H210.1L222.5 82.5V62.3H228.5V93.6H222.5L210 73.6V93.6H204Z'
					fill='white'
				/>
				<path
					d='M239.5 63.4C240.2 63 240.9 62.7 241.6 62.5C242.3 62.3 242.9 62.2 243.3 62.2C243.7 62.2 245.8 62.3 249.5 62.4C253.2 62.5 256 62.7 257.8 62.9V67.9H245.4C244.3 67.9 243.7 68.2 243.4 68.7C243.2 69.1 243.1 69.6 243.1 70.3V75.2L255.8 75.6V80.6L243.1 81V85.9C243.1 87 243.4 87.6 244 87.9C244.4 88.1 245 88.2 245.8 88.2H257.8V93.1C256.4 93.3 252.8 93.5 247.2 93.7L244.4 93.8H243.6C243.3 93.8 242.9 93.8 242.4 93.7C241.9 93.6 241.3 93.5 240.6 93.3C239.9 93 239.3 92.7 238.8 92.3C237.7 91.3 237.1 89.7 237.1 87.5V68.8C237.2 66.2 238 64.4 239.5 63.4Z'
					fill='white'
				/>
			</g>
			{isPoweredBy && (
				<g id='droneshield-logo__powered-by'>
					<path
						d='M164.3 0.200012H170.9C172.1 0.200012 173.3 0.400012 174.4 0.700012C175.5 1.10001 176.5 1.60001 177.3 2.30001C178.1 3.00001 178.8 3.90001 179.3 5.00001C179.8 6.10001 180 7.30001 180 8.70001C180 10.1 179.8 11.3 179.3 12.4C178.9 13.5 178.2 14.4 177.4 15.2C176.6 16 175.6 16.5 174.4 16.9C173.2 17.3 172 17.5 170.6 17.5H167.5V26.8H164.3V0.200012ZM170.2 14.7C171.2 14.7 172.1 14.6 172.9 14.4C173.7 14.2 174.4 13.8 175 13.4C175.6 12.9 176 12.3 176.3 11.6C176.6 10.9 176.8 10 176.8 9.00001C176.8 7.00001 176.2 5.50001 175.1 4.60001C174 3.70001 172.4 3.20001 170.3 3.20001H167.6V14.8L170.2 14.7Z'
						fill='white'
					/>
					<path
						d='M197.4 17.7C197.4 19 197.3 20.2 197 21.4C196.7 22.5 196.2 23.6 195.6 24.4C195 25.3 194.1 26 193.2 26.5C192.2 27 191 27.3 189.6 27.3C188.2 27.3 187 27.1 186 26.5C185 25.9 184.2 25.3 183.5 24.4C182.9 23.5 182.4 22.5 182.1 21.4C181.8 20.3 181.7 19 181.7 17.7C181.7 16.5 181.8 15.2 182.1 14.1C182.4 13 182.9 11.9 183.5 11C184.1 10.1 184.9 9.40004 185.9 8.90004C186.9 8.40004 188.1 8.10004 189.5 8.10004C190.9 8.10004 192.1 8.40004 193.1 8.90004C194.1 9.40004 194.9 10.1 195.5 11C196.1 11.9 196.6 12.9 196.9 14.1C197.3 15.2 197.4 16.4 197.4 17.7ZM194.3 17.6C194.3 16.8 194.2 15.9 194.1 15.1C194 14.3 193.8 13.5 193.4 12.9C193.1 12.3 192.6 11.7 192 11.3C191.4 10.9 190.6 10.7 189.6 10.7C188.6 10.7 187.8 10.9 187.2 11.3C186.6 11.7 186.1 12.2 185.8 12.9C185.5 13.5 185.2 14.3 185.1 15.1C185 15.9 184.9 16.8 184.9 17.6C184.9 18.5 185 19.4 185.1 20.2C185.2 21 185.5 21.8 185.8 22.4C186.1 23.1 186.6 23.6 187.2 24C187.8 24.4 188.6 24.6 189.6 24.6C190.6 24.6 191.4 24.4 192 24C192.6 23.6 193.1 23.1 193.4 22.4C193.7 21.7 194 21 194.1 20.2C194.2 19.4 194.3 18.5 194.3 17.6Z'
						fill='white'
					/>
					<path
						d='M217 22.7L220.8 8.60004H223.7L218.4 26.8H215.3L211.5 12.4L207.7 26.8H204.6L199.1 8.60004H202.2L206.2 22.7L210 8.60004H213.1L217 22.7Z'
						fill='white'
					/>
					<path
						d='M228.8 18.6C228.8 19.4 228.9 20.2 229.1 20.9C229.3 21.6 229.6 22.3 230 22.8C230.4 23.3 231 23.8 231.7 24.1C232.4 24.4 233.2 24.6 234.2 24.6C235.8 24.6 237.5 24.2 239.3 23.4V26C238.5 26.4 237.6 26.7 236.6 26.9C235.6 27.1 234.6 27.2 233.6 27.2C232.2 27.2 231 27 230 26.5C229 26 228.1 25.4 227.5 24.5C226.8 23.7 226.4 22.7 226 21.5C225.7 20.3 225.5 19 225.5 17.6C225.5 16.3 225.6 15.1 226 13.9C226.3 12.7 226.8 11.7 227.4 10.9C228 10.1 228.8 9.4 229.8 8.8C230.8 8.3 231.9 8 233.1 8C234.3 8 235.4 8.2 236.3 8.7C237.2 9.2 237.9 9.8 238.4 10.6C238.9 11.4 239.3 12.3 239.6 13.4C239.8 14.5 240 15.6 240 16.7C240 17.1 240 17.5 240 17.7C240 17.9 240 18.2 240 18.4L228.8 18.6ZM236.7 16C236.7 15.3 236.7 14.7 236.6 14C236.5 13.4 236.3 12.8 236 12.3C235.7 11.8 235.3 11.4 234.8 11.1C234.3 10.8 233.6 10.6 232.8 10.6C232.2 10.6 231.6 10.7 231.1 11C230.6 11.3 230.2 11.6 229.9 12.1C229.5 12.6 229.3 13.1 229.1 13.8C228.9 14.5 228.8 15.1 228.8 15.9L236.7 16Z'
						fill='white'
					/>
					<path
						d='M247 11.5C247.5 10.6 248.2 9.80004 249.1 9.10004C250 8.40004 251 8.10004 252.1 8.10004C252.4 8.10004 252.8 8.10004 253.2 8.20004V10.7C253 10.7 252.8 10.7 252.7 10.7C252.6 10.7 252.4 10.7 252.2 10.7C251.3 10.7 250.5 10.9 249.6 11.4C248.7 11.8 247.9 12.5 247.1 13.2V26.8H244V8.60004H246.7L247 11.5Z'
						fill='white'
					/>
					<path
						d='M257.6 18.6C257.6 19.4 257.7 20.2 257.9 20.9C258.1 21.6 258.4 22.3 258.8 22.8C259.2 23.3 259.8 23.8 260.5 24.1C261.2 24.4 262 24.6 263 24.6C264.6 24.6 266.3 24.2 268.1 23.4V26C267.3 26.4 266.4 26.7 265.4 26.9C264.4 27.1 263.4 27.2 262.4 27.2C261 27.2 259.8 27 258.8 26.5C257.8 26 256.9 25.4 256.3 24.5C255.6 23.7 255.1 22.7 254.8 21.5C254.5 20.3 254.3 19 254.3 17.6C254.3 16.3 254.5 15.1 254.8 13.9C255.1 12.7 255.6 11.7 256.2 10.9C256.8 10.1 257.6 9.4 258.6 8.8C259.6 8.3 260.7 8 261.9 8C263.1 8 264.2 8.2 265.1 8.7C266 9.2 266.7 9.8 267.2 10.6C267.7 11.4 268.1 12.3 268.4 13.4C268.6 14.5 268.7 15.6 268.7 16.7C268.7 17.1 268.7 17.5 268.7 17.7C268.7 17.9 268.7 18.2 268.7 18.4L257.6 18.6ZM265.5 16C265.5 15.3 265.5 14.7 265.4 14C265.3 13.4 265.1 12.8 264.8 12.3C264.5 11.8 264.1 11.4 263.6 11.1C263.1 10.8 262.4 10.6 261.6 10.6C261 10.6 260.4 10.7 259.9 11C259.4 11.3 259 11.6 258.7 12.1C258.4 12.6 258.1 13.1 257.9 13.8C257.7 14.5 257.6 15.1 257.6 15.9L265.5 16Z'
						fill='white'
					/>
					<path
						d='M286.5 26.8H284L283.6 25.2C283.1 25.7 282.5 26.2 281.7 26.6C280.9 27 280 27.3 278.9 27.3C277.6 27.3 276.5 27.1 275.6 26.6C274.7 26.1 273.9 25.5 273.4 24.6C272.8 23.8 272.4 22.8 272.1 21.6C271.8 20.5 271.7 19.3 271.7 17.9C271.7 16.6 271.9 15.3 272.1 14.1C272.4 12.9 272.9 11.9 273.5 11C274.1 10.1 274.9 9.40001 275.8 8.90001C276.8 8.40001 277.9 8.10001 279.2 8.10001C280.1 8.10001 281 8.30001 281.7 8.60001C282.4 8.90001 283 9.30001 283.5 9.60001V0.200012H286.6L286.5 26.8ZM283.5 12.1C283 11.7 282.4 11.4 281.8 11.1C281.2 10.8 280.5 10.7 279.8 10.7C278.8 10.7 278 10.9 277.4 11.3C276.8 11.7 276.3 12.2 275.9 12.8C275.5 13.4 275.3 14.2 275.1 15C274.9 15.8 274.9 16.8 274.9 17.7C274.9 18.6 275 19.5 275.1 20.3C275.3 21.1 275.5 21.9 275.9 22.5C276.3 23.1 276.8 23.6 277.4 24C278 24.4 278.8 24.6 279.7 24.6C281.1 24.6 282.4 24.1 283.5 23.1V12.1Z'
						fill='white'
					/>
					<path
						d='M314 17.3C314 18.7 313.9 20.1 313.6 21.3C313.3 22.5 312.9 23.6 312.2 24.5C311.5 25.4 310.7 26.1 309.7 26.6C308.7 27.1 307.4 27.3 305.9 27.3C305.3 27.3 304.7 27.3 304.1 27.2C303.5 27.1 302.9 27 302.3 26.9C301.7 26.8 301.1 26.6 300.6 26.4C300.1 26.2 299.6 26 299.2 25.8V0.200012H302.3V10.4C303 9.70001 303.8 9.20001 304.5 8.70001C305.3 8.30001 306.3 8.10001 307.4 8.10001C308.6 8.10001 309.7 8.30001 310.5 8.80001C311.3 9.30001 312 10 312.6 10.8C313.1 11.6 313.5 12.6 313.7 13.7C313.9 14.9 314 16.1 314 17.3ZM310.8 17.4C310.8 16.6 310.7 15.7 310.6 14.9C310.4 14.1 310.2 13.4 309.9 12.7C309.6 12 309.1 11.6 308.6 11.2C308.1 10.8 307.4 10.6 306.5 10.6C305.7 10.6 305 10.8 304.2 11.2C303.5 11.6 302.8 12.1 302.2 12.7V23.7C302.8 24 303.4 24.2 304 24.4C304.6 24.6 305.3 24.6 306 24.6C307 24.6 307.7 24.4 308.4 24C309 23.6 309.5 23.1 309.9 22.4C310.3 21.7 310.6 21 310.7 20.1C310.8 19.3 310.8 18.4 310.8 17.4Z'
						fill='white'
					/>
					<path
						d='M323.6 22.7L328.2 8.6H331.4L325 26.6C324.7 27.6 324.3 28.5 323.8 29.3C323.3 30.1 322.8 30.8 322.3 31.4C321.7 32 321 32.4 320.3 32.8C319.5 33.1 318.6 33.3 317.7 33.3C317.4 33.3 317 33.3 316.7 33.2C316.4 33.1 316 33.1 315.6 33V30.4C315.9 30.5 316.3 30.5 316.7 30.6C317.1 30.7 317.4 30.7 317.7 30.7C318.4 30.7 319 30.6 319.4 30.4C319.9 30.2 320.3 29.9 320.6 29.5C320.9 29.1 321.3 28.7 321.5 28.1C321.8 27.6 322 27 322.3 26.3L315.7 8.5H319L323.6 22.7Z'
						fill='white'
					/>
				</g>
			)}
		</svg>
	)
}

export default DroneShieldLogo
