// Redux
import {
	createApi,
	fetchBaseQuery /*, retry */,
} from '@reduxjs/toolkit/query/react'
import type { RootState } from '.'

import { baseUrl } from '@Constants/api'

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
	baseUrl,
	prepareHeaders: (headers: Headers, { getState }) => {
		const token = (getState() as RootState).auth.token
		if (token) headers.set('Authorization', `Bearer ${token}`)
		return headers
	},
})
// const baseQueryWithRetry = retry(baseQuery, { maxRetries: 6 })

// Core API
export const baseApi = createApi({
	baseQuery, //baseQuery: baseQueryWithRetry
	endpoints: (builder) => ({}),
})

// NOTES
// https://redux-toolkit.js.org/rtk-query/usage/error-handling#handling-errors-at-a-macro-level
// https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#automatic-re-authorization-by-extending-fetchbasequery
// https://github.com/reduxjs/redux-toolkit/issues/1622
