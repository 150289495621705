import { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
	useReactTable,
	getCoreRowModel,
	type SortingState,
	type PaginationState,
	createColumnHelper,
} from '@tanstack/react-table'
import { Flex, useDisclosure } from '@chakra-ui/react'
import { MdDownload, MdPlayCircleOutline, MdDelete } from 'react-icons/md'

import {
	useLazyGetVideoRecordingQuery,
	useDeleteVideoRecordingMutation,
	useGetVideoRecordingsSummaryQuery,
} from '@Store/analytics/analyticsApi'

import IconButton from '@UI/IconButton/IconButton'
import DeleteModal from '@UI/Modals/DeleteModal'
import FetchError from '@UI/FetchError/FetchError'
import TanStackTable from '@UI/TanStackTable/Table'
import Pagination from '@UI/TanStackTable/Pagination'
import Skeleton from '@UI/Skeleton/Skeleton'

import VideoRecordingModal from './VideoRecordingModal'

import { formatDateTz } from '@Utils/dates'
import { formatMillisecondsDuration, formatBytes } from '@Utils/formatUtils'
import { getSorting } from '@Utils/tables'
import { capitalize } from '@Utils/stringUtils'

import type { VideoRecording } from '@Store/types'
import { baseUrl, isProduction } from '@Constants/api'

type VideoRecordingsTableProps = {
	siteId: number
	startTime: string
	endTime: string
	siteTimeZone: string
}

const columnHelper = createColumnHelper<VideoRecording>()

const VideoRecordingsTable = ({
	siteId,
	startTime,
	endTime,
	siteTimeZone,
}: VideoRecordingsTableProps) => {
	const { t } = useTranslation('pages', {
		keyPrefix: 'analytics.videoRecordings',
	})

	const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 10,
	})
	const [sorting, setSorting] = useState<SortingState>([
		{ id: 'created_at', desc: true },
	])

	const pagination = useMemo(
		() => ({
			pageIndex,
			pageSize,
		}),
		[pageIndex, pageSize]
	)

	const [getVideoRecording] = useLazyGetVideoRecordingQuery()
	const [deleteVideoRecording] = useDeleteVideoRecordingMutation()
	const [videoRecording, setVideoRecording] = useState<VideoRecording>()

	const {
		isOpen: isRecordingModalOpen,
		onOpen: onRecordingModalOpen,
		onClose: onRecordingModalClose,
	} = useDisclosure()
	const {
		isOpen: isDeleteModalOpen,
		onOpen: onDeleteModalOpen,
		onClose: onDeleteModalClose,
	} = useDisclosure()

	const {
		isSuccess,
		isError,
		isFetching,
		isLoading,
		refetch,
		totalCount,
		videoRecordings,
	} = useGetVideoRecordingsSummaryQuery(
		{
			siteId,
			format: 'table',
			startTime: startTime,
			endTime: endTime,
			page: pageIndex,
			perPage: pageSize,
			...getSorting(sorting),
		},
		{
			selectFromResult: ({
				isSuccess,
				isError,
				isFetching,
				isLoading,
				data,
			}) => ({
				isSuccess,
				isError,
				isFetching,
				isLoading,
				totalCount: data?.meta.total,
				videoRecordings: data?.records,
			}),
		}
	)

	const handleOpenVideoRecordingModal = (recording: VideoRecording) => {
		onRecordingModalOpen()
		setVideoRecording(recording)
	}

	const handleOpenDeleteModal = (recording: VideoRecording) => {
		onDeleteModalOpen()
		setVideoRecording(recording)
	}

	const handleDownloadVideoRecording = async (recordingId: number) => {
		try {
			const { data: videoRecording } = await getVideoRecording({ recordingId })
			if (videoRecording) {
				window.location.replace(
					`${isProduction ? '' : baseUrl}${videoRecording.url}`
				)
			}
		} catch (error) {
			console.error('TODO: unhandled download video recording error', error)
		}
	}

	const handleDeleteVideoRecording = async (recordingId: number) => {
		try {
			await deleteVideoRecording({ recordingId }).unwrap()
		} catch (error) {
			console.error('TODO: unhandled delete video recording error', error)
		}
	}

	const getLabels = (labels: string[]) => {
		if (!labels || labels.length === 0) return t('unlabelled')

		return labels.map((label) => capitalize(label)).join(', ')
	}

	const columns = [
		columnHelper.accessor('filename', {
			id: 'filename',
			header: t('table.fileName'),
		}),
		columnHelper.accessor('created_at', {
			id: 'created_at',
			header: t('table.date'),
			cell: (info) => formatDateTz(info.getValue(), siteTimeZone),
		}),
		columnHelper.accessor('duration', {
			id: 'duration',
			header: t('table.duration'),
			cell: (info) => formatMillisecondsDuration(info.getValue()),
		}),
		columnHelper.accessor('labels', {
			id: 'labels',
			header: t('table.label'),
			cell: (info) => getLabels(info.getValue()),
		}),
		columnHelper.accessor('size', {
			id: 'size',
			header: t('table.size'),
			cell: (info) => formatBytes(info.getValue()),
		}),
		columnHelper.display({
			id: 'actions',
			header: t('table.actions'),
			meta: {
				isCentered: true,
			},
			cell: ({ row }) => (
				<Flex gap={1} w='100px'>
					<IconButton
						icon={<MdPlayCircleOutline />}
						testId='details-icon'
						aria-label='details-icon'
						tooltip='Play Recording'
						onClick={() => handleOpenVideoRecordingModal(row.original)}
					/>
					<IconButton
						icon={<MdDownload />}
						testId='details-icon'
						aria-label='details-icon'
						tooltip='Download Recording'
						onClick={() => handleDownloadVideoRecording(row.original.id)}
					/>
					<IconButton
						icon={<MdDelete />}
						testId='details-icon'
						aria-label='details-icon'
						tooltip='Delete Recording'
						onClick={() => handleOpenDeleteModal(row.original)}
					/>
				</Flex>
			),
		}),
	]

	const pageCount = totalCount ? Math.ceil(totalCount / pageSize) : 0

	const table = useReactTable({
		columns,
		data: videoRecordings as VideoRecording[],
		getCoreRowModel: getCoreRowModel(),
		pageCount: pageCount ?? -1,
		state: {
			pagination,
			sorting,
		},
		onPaginationChange: setPagination,
		onSortingChange: setSorting,
		manualPagination: true,
		manualSorting: true,
		enableSortingRemoval: false,
	})

	return (
		<Skeleton isLoaded={!isLoading && !isFetching}>
			{isSuccess && (
				<>
					<Flex direction='column' gap='8px'>
						<TanStackTable
							table={table}
							sortableColumns={['filename', 'created_at', 'duration']}
						/>
						<Pagination table={table} totalCount={totalCount ?? 0} />
					</Flex>
					{videoRecording && (
						<>
							<VideoRecordingModal
								isOpen={isRecordingModalOpen}
								onClose={onRecordingModalClose}
								recordingId={videoRecording.id}
							/>
							<DeleteModal
								isOpen={isDeleteModalOpen}
								onClose={onDeleteModalClose}
								name={videoRecording.filename}
								handleDelete={() =>
									handleDeleteVideoRecording(videoRecording.id)
								}
								headerText={t('deleteVideoRecording')}
								fromText={t('fromSite')}
							/>
						</>
					)}
				</>
			)}
			{isError && (
				<FetchError
					refetch={refetch}
					errorMessage={t('fetchRecordingsError')}
				/>
			)}
		</Skeleton>
	)
}

export default VideoRecordingsTable
