import { createSlice } from '@reduxjs/toolkit'

import type { RootState } from '@Store/index'

type InitialState = {
	networkStatus: {
		authenticating: boolean
		subscribing: boolean
		disconnected: boolean
		slowLinkCount: number | null
		reconnecting: boolean
	}
}

const initialState: InitialState = {
	networkStatus: {
		authenticating: false,
		subscribing: false,
		disconnected: true,
		slowLinkCount: null,
		reconnecting: false,
	},
}

export const systemSlice = createSlice({
	name: 'system',
	initialState,
	reducers: {
		setNetworkAuthenticating: (state, { payload }) => {
			state.networkStatus.authenticating = payload
		},
		setNetworkSubscribing: (state, { payload }) => {
			state.networkStatus.subscribing = payload
		},
		setNetworkConnected: (state) => {
			state.networkStatus.disconnected = false
			state.networkStatus.slowLinkCount = 0
		},
		setNetworkDisconnected: (state) => {
			state.networkStatus.disconnected = true
		},
		setNetworkSlowLinkEvent: (state) => {
			if (state.networkStatus.slowLinkCount === null)
				state.networkStatus.slowLinkCount = 1
			else state.networkStatus.slowLinkCount++
		},
		setNetworkReconnecting: (state, { payload }) => {
			state.networkStatus.reconnecting = payload
		},
		clearNetworkSlowLinkEvent: (state) => {
			state.networkStatus.slowLinkCount = null
		},
	},
})

// Selectors
export const selectNetworkAuthenticating = (state: RootState) =>
	state.system.networkStatus.authenticating

export const selectNetworkDisconnected = (state: RootState) =>
	state.system.networkStatus.disconnected ||
	(state.system.networkStatus.slowLinkCount &&
		state.system.networkStatus.slowLinkCount > 5)

export const selectNetworkSlowLink = (state: RootState) =>
	state.system.networkStatus.slowLinkCount &&
	state.system.networkStatus.slowLinkCount > 2

export const selectNetworkReconnecting = (state: RootState) =>
	state.system.networkStatus.reconnecting

export default systemSlice.reducer

export const {
	setNetworkAuthenticating,
	setNetworkSubscribing,
	setNetworkConnected,
	setNetworkDisconnected,
	setNetworkSlowLinkEvent,
	setNetworkReconnecting,
	clearNetworkSlowLinkEvent,
} = systemSlice.actions
