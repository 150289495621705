// Redux
import { useAppSelector } from '@Store/index'
import { selectUserDefaultSiteId } from '@Store/user/userSlice'
import { useGetSitesListQuery, selectFirstSiteId } from '@Store/sites/sitesApi'

// Auth
import useAuth from '@Hooks/useAuth'

// React Router
import { Navigate } from 'react-router-dom'

import NoSite from '@Components/Site/NoSite'
import { useTranslation } from 'react-i18next'

// Handle the / route
const Preload = () => {
	const { role } = useAuth()
	const { t } = useTranslation('app', { keyPrefix: 'global.messages' })

	// API returns list of sites available to the logged in user
	const { isSuccess, isError, sitesList, firstSiteId } = useGetSitesListQuery(
		undefined,
		{
			selectFromResult: ({ isSuccess, isError, data }) => ({
				isSuccess,
				isError,
				sitesList: data,
				firstSiteId: selectFirstSiteId(data),
			}),
		}
	)

	const userDefaultSiteId = useAppSelector(selectUserDefaultSiteId)

	// Sites list was loaded
	if (isSuccess) {
		// Redirect to /:userDefaultSiteId (if the saved default siteId exists)
		if (
			userDefaultSiteId &&
			sitesList?.some((site) => site.id === userDefaultSiteId)
		) {
			return <Navigate to={`/${userDefaultSiteId}`} />
		}
		// Redirect to /:firstSiteId (according to /api/sites, this siteId exists)
		else if (firstSiteId) {
			return <Navigate to={`/${firstSiteId}`} />
		}
		// Redirect to /site/add (no sites found, admin/manager only)
		else if (['admin', 'manager'].includes(role)) {
			return <Navigate to='/site/add' />
		}
		// No available site for this user
		else if (sitesList?.length === 0) {
			return <NoSite />
		}
	}
	// Error loading sites list
	else if (isError) {
		return t('errorOccurred')
	}
	// TODO: show a loader?
	else return null
}

// Catch-all component to redirect failures to this Preloader
export const SiteLoadError = () => {
	return <Navigate to='/' />
}

export default Preload
