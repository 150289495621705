// React
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
// Components
import Headings from '@UI/Headings/Headings'
import Field from '@Components/FormElements'
import { FormButtons } from '@Components/FormElements/FormButtons/FormButtons'
// Form
import type { TypeOf } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import {
	ADD_NOTE_DEFAULT_VALUES,
	notesFormSchema,
} from '@Forms/NotesForm/NotesForm.schema'
// Store
import { useAppSelector } from '@/store'
import { selectUser } from '@Store/user/userSlice'
import { useCreateNoteMutation } from '@Store/notes/notesApi'
import type { Note } from '@Store/types'

const NotesForm = ({ siteId }: { siteId: number }) => {
	const user = useAppSelector(selectUser)
	if (!user) return null

	const defaultValues = { ...ADD_NOTE_DEFAULT_VALUES, name: user?.name }

	return <Form defaultValues={defaultValues} siteId={siteId} />
}

const Form = ({
	defaultValues,
	siteId,
}: {
	defaultValues: { body: Note['body']; name: Note['name'] }
	siteId: number
}) => {
	const navigate = useNavigate()
	const { t } = useTranslation('forms', { keyPrefix: 'notesForm' })

	type Schema = TypeOf<typeof notesFormSchema>

	const methods = useForm<Schema>({
		resolver: zodResolver(notesFormSchema),
		defaultValues,
	})
	const {
		register,
		formState: { errors, isSubmitting, isDirty },
		handleSubmit,
	} = methods

	const [createNote] = useCreateNoteMutation()

	const handleSave = async (data: Schema) => {
		const note = {
			...data,
		}
		try {
			await createNote({ siteId, ...note }).unwrap()
			navigate(`/${siteId}/notes`)
		} catch (error) {
			console.error('Note form error', error)
		}
	}

	const handleCancel = () => navigate(`/${siteId}/notes`)

	return (
		<>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(handleSave)}>
					<Headings.FormHeading
						title={t('headings.add')}
						textTransform='uppercase'
					/>
					<Field.TextArea
						title={t('headings.newNote')}
						register={register('body')}
						error={errors?.body?.message}
						testId='body'
						maxLength={5000}
					/>
					<FormButtons
						isSubmitting={isSubmitting}
						isDirty={isDirty}
						handleCancel={handleCancel}
					/>
				</form>
			</FormProvider>
		</>
	)
}
export default NotesForm
