import { useCallback } from 'react'
import type { Route } from '@Components/App/Router'

const usePermissionRoute = (routes: Route[]) => {
	const recursiveFilterPermissionRoutes = useCallback(
		(routes: Route[]): Route[] => {
			return routes?.filter((route) => {
				const hasPermissions = route.include
				if (route?.children && route?.children.length > 0) {
					route.children = recursiveFilterPermissionRoutes(route?.children)
					return (
						hasPermissions || (route?.children && route?.children.length > 0)
					)
				}
				return hasPermissions
			})
		},
		[]
	)

	return recursiveFilterPermissionRoutes(routes)
}

export default usePermissionRoute
