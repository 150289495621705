import { useGetSiteLiveQuery, selectSiteData } from '@/store/sites/sitesWsApi'
import {
	Box,
	Card,
	CardHeader,
	CardBody,
	Flex,
	Heading,
} from '@chakra-ui/react'

import { Subheading } from '@/components/_UI/Headings/Headings'

import EditButton from './EditButton'

import { formatLatLng } from '@Utils/formatUtils'
import useAuth from '@Hooks/useAuth'
import { useTranslation } from 'react-i18next'

const Site = ({ siteId }: { siteId: number }) => {
	const { name, latitude, longitude } = useGetSiteLiveQuery(siteId, {
		selectFromResult: ({ data }) => ({
			...selectSiteData(data),
		}),
	})
	const { t } = useTranslation('panels', {
		keyPrefix: 'siteInstallations.headings',
	})
	const { isSiteManager } = useAuth()
	return (
		<Card className='Site' variant='site' marginBlockStart={1}>
			<CardHeader data-testid='site-header'>{t('site')}</CardHeader>
			<CardBody>
				<Flex alignItems='center' justifyContent='space-between'>
					<Box>
						<Heading size='sm' data-testid='site-name'>
							{name}
						</Heading>
						<Subheading data-testid='site-coordinates'>
							{latitude ? formatLatLng(latitude) : '-'}{' '}
							{longitude ? formatLatLng(longitude) : '-'}
						</Subheading>
					</Box>
					{isSiteManager && (
						<Box pr={4}>
							<EditButton
								to={`/${siteId}/settings`}
								type='edit'
								testId='edit-site'
							/>
						</Box>
					)}
				</Flex>
			</CardBody>
		</Card>
	)
}

export default Site
