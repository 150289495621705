// React
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
// Chakra
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
// Components
import ReportRange from '@Pages/Analytics/ReportRange'
import TrackSummary from '@Pages/Analytics/TrackSummary/TrackSummary'
import DisruptionsSummary from '@Pages/Analytics/DisruptionsSummary/DisruptionsSummary'
import AlertsSummary from '@Pages/Analytics/AlertsSummary/AlertsSummary'
import VideoRecordings from '@Pages/Analytics/VideoRecordings/VideoRecordings'
import SiteLogs from '@Pages/Analytics/SiteLogs/SiteLogs'
import ErrorBoundary from '@Components/App/ErrorHandling/ErrorBoundary'
// Hooks/utils
import useUTCTime from '@Pages/Analytics/useUTCTime'
import { formatSiteTime, todaySiteDate, yesterdaySiteDate } from '@Utils/dates'
import {
	selectIsDefeatEnabled,
	selectIsTactical,
	useGetSystemInfoQuery,
} from '@Store/system/systemApi'
import useAuth from '@Hooks/useAuth'

const Report = ({
	siteTimeZone,
	siteId,
}: {
	siteTimeZone: string
	siteId: number
}) => {
	const { t } = useTranslation('pages', { keyPrefix: 'analytics.tabs' })

	const navigate = useNavigate()
	const location = useLocation()
	const siteTime = formatSiteTime(siteTimeZone)

	const {
		isDefeatEnabled,
		isTactical,
		isSuccess: isSystemInfoSuccess,
	} = useGetSystemInfoQuery(undefined, {
		selectFromResult: ({ data, isSuccess }) => ({
			isTactical: selectIsTactical(data),
			isDefeatEnabled: selectIsDefeatEnabled(data),
			isSuccess,
		}),
	})

	const [startTime, setStartTime] = useState<string>(
		yesterdaySiteDate(siteTime)
	)
	const [endTime, setEndTime] = useState<string>(todaySiteDate(siteTime))

	// Convert time to UTC for server request
	const utcStartTime = useUTCTime(startTime)
	const utcEndTime = useUTCTime(endTime)

	const { isAdmin } = useAuth()

	if (!isSystemInfoSuccess) return null

	const getDefaultIndex = () => {
		switch (location.hash) {
			case '#disruptions':
				return 1
			case '#alerts':
				return isDefeatEnabled ? 2 : 1
			case '#video':
				return isDefeatEnabled ? 3 : 2
			case '#logs':
				if (isDefeatEnabled && !isTactical) return 4
				else if (!isDefeatEnabled && !isTactical) return 3
				else if (isDefeatEnabled && isTactical) return 3
				else return 2
			default:
				return 0
		}
	}
	const defaultIndex = getDefaultIndex()

	return (
		<>
			<ReportRange
				startTime={startTime}
				endTime={endTime}
				setStartTime={setStartTime}
				setEndTime={setEndTime}
			/>
			<Tabs defaultIndex={defaultIndex} isLazy>
				<TabList>
					<Tab onClick={() => navigate('')}>{t('trackSummary')}</Tab>
					{isDefeatEnabled && (
						<Tab onClick={() => navigate('#disruptions')}>
							{t('disruptionsSummary')}
						</Tab>
					)}
					<Tab onClick={() => navigate('#alerts')}>{t('alertsSummary')}</Tab>
					{!isTactical && (
						<Tab onClick={() => navigate('#video')}>{t('videoRecordings')}</Tab>
					)}
					{isAdmin && (
						<Tab onClick={() => navigate('#logs')}>{t('siteLogs')}</Tab>
					)}
				</TabList>
				<TabPanels>
					<TabPanel>
						<ErrorBoundary>
							<TrackSummary
								siteId={siteId}
								startTime={utcStartTime}
								endTime={utcEndTime}
								siteTimeZone={siteTimeZone}
							/>
						</ErrorBoundary>
					</TabPanel>
					{isDefeatEnabled && (
						<TabPanel>
							<ErrorBoundary>
								<DisruptionsSummary
									siteId={siteId}
									startTime={utcStartTime}
									endTime={utcEndTime}
									siteTimeZone={siteTimeZone}
								/>
							</ErrorBoundary>
						</TabPanel>
					)}
					<TabPanel>
						<ErrorBoundary>
							<AlertsSummary
								siteId={siteId}
								startTime={utcStartTime}
								endTime={utcEndTime}
								siteTimeZone={siteTimeZone}
							/>
						</ErrorBoundary>
					</TabPanel>
					{!isTactical && (
						<TabPanel>
							<ErrorBoundary>
								<VideoRecordings
									siteId={siteId}
									startTime={utcStartTime}
									endTime={utcEndTime}
									siteTimeZone={siteTimeZone}
								/>
							</ErrorBoundary>
						</TabPanel>
					)}
					{isAdmin && (
						<TabPanel>
							<ErrorBoundary>
								<SiteLogs
									siteId={siteId}
									startTime={utcStartTime}
									endTime={utcEndTime}
									siteTimeZone={siteTimeZone}
								/>
							</ErrorBoundary>
						</TabPanel>
					)}
				</TabPanels>
			</Tabs>
			<Outlet />
		</>
	)
}

export default Report
