// Chakra
import { Box } from '@chakra-ui/react'
// Store
import {
	selectHasUnreadWarnings,
	useGetSiteWarningsQuery,
} from '@Store/siteWarnings/siteWarningsApi'

const SystemWarningsUnreadButton = ({ siteId }: { siteId: number }) => {
	const { isUnread } = useGetSiteWarningsQuery(siteId, {
		skip: !siteId,
		selectFromResult: ({ isSuccess, data }) => ({
			isUnread: isSuccess ? selectHasUnreadWarnings(data) : null,
		}),
	})
	return (
		<>
			{isUnread && (
				<Box
					boxSize={2}
					bgColor='error'
					position='absolute'
					borderRadius='100%'
					top='0'
					right='0'
				/>
			)}
		</>
	)
}

export default SystemWarningsUnreadButton
