// React
import { type ReactElement } from 'react'
// Chakra
import { Box, Flex } from '@chakra-ui/react'
// Components
import Map from '@Components/Map/Map'
import MapTileLayer from '../Map/MapTileLayer/MapTileLayer'
import ErrorBoundary from '../App/ErrorHandling/ErrorBoundary'
// Assets
import PreviewModes from '@Components/Site/MapLayers/PreviewModes'
import useShowPreview from '@Components/Site/MapLayers/PreviewModes/useShowPreviewMode'
import { ADD_SITE_DEFAULT_VALUES } from '../Forms/SiteForm/SiteForm.schema'

const AddSite = ({ children }: { children: ReactElement }) => {
	// Default values
	const zoomLevel = 13

	const { isPreviewRoute } = useShowPreview()

	return (
		<ErrorBoundary showBackButton={false} showLocationReloadButton>
			<Flex w='100%' h='100%' position='relative'>
				<Box
					position='absolute'
					height='calc(100vh - 66px)'
					top={2}
					left={2}
					zIndex='1000'
					bgColor='panel_bg'
					pb={4}
				>
					<Box overflowY='scroll' h='100%' w='495px' p={4}>
						{children}
					</Box>
				</Box>
				<Map
					center={[
						ADD_SITE_DEFAULT_VALUES.latitude,
						ADD_SITE_DEFAULT_VALUES.longitude,
					]}
					zoom={zoomLevel}
					scrollWheelZoom={true}
					showMeasurementTools={true}
				>
					<MapTileLayer />
					{isPreviewRoute && <PreviewModes />}
				</Map>
			</Flex>
			<style>{'.leaflet-left { left: calc(495px + var(--c2-space-2)) }'}</style>
		</ErrorBoundary>
	)
}

export default AddSite
