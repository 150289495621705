import type { ForwardRefExoticComponent, RefAttributes } from 'react'
import {
	createElementObject,
	createTileLayerComponent,
	updateGridLayer,
	withPane,
} from '@react-leaflet/core'
import type { LayerProps, LeafletContextInterface } from '@react-leaflet/core'
import { Util, TileLayer } from 'leaflet'
import type {
	Control,
	Coords,
	Map as LeafletMap,
	TileLayerOptions,
	TileErrorEvent,
} from 'leaflet'

interface TileLayerProps extends TileLayerOptions, LayerProps {
	url: string
	minZoom: number
	maxZoom: number
	fallbackTileUrl?: string
	fallbackTileAttribution?: string
	mapRef: LeafletMap
}

const getQuadKey = (x: number, y: number, z: number) => {
	let quadKey = ''
	for (let i = z; i > 0; i--) {
		let digit = 0
		const mask = 1 << (i - 1)
		if ((x & mask) !== 0) digit += 1
		if ((y & mask) !== 0) digit += 2
		quadKey = quadKey + digit
	}
	return quadKey
}

const getTileUrl = (url: string, coordinates: Coords) => {
	try {
		return Util.template(url, {
			q: getQuadKey(coordinates.x, coordinates.y, coordinates.z),
			x: coordinates.x,
			y: coordinates.y,
			z: coordinates.z,
		})
	} catch (e) {
		return undefined
	}
}

const getMapAttributions = (map: LeafletMap) =>
	Object.keys(
		(
			map.attributionControl as Control.Attribution & {
				_attributions: { [attribution: string]: number }[]
			}
		)._attributions
	)

const tileErrors = new Set<string>()

const handleTileError = (
	e: TileErrorEvent,
	mapRef: LeafletMap,
	fallbackTileUrl: TileLayerProps['fallbackTileUrl'],
	fallbackTileAttribution: TileLayerProps['fallbackTileAttribution'],
	errorTileUrl: TileLayerProps['errorTileUrl'] = ''
) => {
	// remember tileerror url, so we don't get into a 404 loop
	tileErrors.add(e.tile.src)

	if (fallbackTileUrl) {
		// generate a fallback tile url
		const fallbackUrl = getTileUrl(fallbackTileUrl, e.coords)

		// attempt fallback url, unless it has already triggered an error
		if (fallbackUrl && !tileErrors.has(fallbackUrl)) {
			e.tile.src = fallbackUrl
			if (fallbackTileAttribution)
				mapRef.attributionControl.addAttribution(fallbackTileAttribution)
		}

		// default to error tile
		else if (errorTileUrl) {
			e.tile.src = errorTileUrl
		}
	} else if (errorTileUrl) e.tile.src = errorTileUrl
}

const createTileLayer = (
	{
		url,
		minZoom,
		maxZoom,
		fallbackTileUrl,
		fallbackTileAttribution,
		errorTileUrl = '',
		...options
	}: TileLayerProps,
	context: LeafletContextInterface
) => {
	const layer = new TileLayer(
		url,
		withPane(
			{
				minZoom,
				maxZoom,
				fallbackTileUrl,
				fallbackTileAttribution,
				// errorTileUrl, // handled via error handler
				...options,
			},
			context
		)
	)
	layer.getTileUrl = (coordinates) =>
		getTileUrl(url, coordinates) ?? errorTileUrl

	if (!layer.hasEventListeners('tileerror'))
		layer.addEventListener('tileerror', (e) =>
			handleTileError(
				e,
				context.map,
				fallbackTileUrl,
				fallbackTileAttribution,
				errorTileUrl
			)
		)

	if (minZoom && maxZoom) {
		context.map.setMinZoom(minZoom)
		context.map.setMaxZoom(maxZoom)
	}

	return createElementObject(layer, context)
}

const updateTileLayer = (
	layer: TileLayer,
	props: TileLayerProps,
	prevProps: TileLayerProps
) => {
	const { mapRef } = props

	if (props.url !== null && props.url !== prevProps.url) {
		layer.getTileUrl = (coordinates) =>
			getTileUrl(props.url, coordinates) ?? props.errorTileUrl ?? ''
		layer.setUrl(props.url)
	}

	if (props.fallbackTileUrl !== prevProps.fallbackTileUrl) {
		// resetTileErrors()
		layer.removeEventListener('tileerror')
		layer.addEventListener('tileerror', (e) =>
			handleTileError(
				e,
				mapRef,
				props.fallbackTileUrl,
				props.fallbackTileAttribution,
				props.errorTileUrl
			)
		)
	}

	if (props.attribution !== prevProps.attribution && props.attribution) {
		// remove existing attributions
		getMapAttributions(mapRef).forEach((attribution) => {
			mapRef.attributionControl.removeAttribution(attribution)
		})
	}

	if (props.minZoom && props.minZoom !== prevProps.minZoom) {
		layer.options.minZoom = props.minZoom
		mapRef.setMinZoom(props.minZoom)
	}

	if (props.maxZoom && props.maxZoom !== prevProps.maxZoom) {
		layer.options.maxZoom = props.maxZoom
		mapRef.setMaxZoom(props.maxZoom)
	}
	updateGridLayer(layer, props, prevProps)
}

const CustomTileLayer: ForwardRefExoticComponent<
	TileLayerProps & RefAttributes<TileLayer>
> = createTileLayerComponent(createTileLayer, updateTileLayer)

export default CustomTileLayer
