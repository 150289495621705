import type { LatLngExpression } from 'leaflet'
import Marker from '../index'

import compassIcon from '@Assets/icons/markers/compass-direction.png'

type DirectionPointerProps = {
	position: LatLngExpression
	bearing?: number
}

const ICON_SIZE = [600, 600]
const ICON_ANCHOR = [301.25, 300.5]

const DirectionPointer = ({ position, bearing }: DirectionPointerProps) => {
	const style = {
		transform: `rotate(${bearing}deg)`,
	}
	return (
		<Marker
			position={position}
			icon={<img src={compassIcon} style={style} alt='Compass Pointer' />}
			iconSize={ICON_SIZE}
			iconAnchor={ICON_ANCHOR}
			alt='Compass Pointer'
		/>
	)
}

export default DirectionPointer
