// https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/
export const openInNewTab = (url: string): void => {
	const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
	if (newWindow) newWindow.opener = null
}

export const checkUrlReachability = async (url: string) => {
	try {
		const response = await fetch(url, {
			method: 'HEAD',
		})
		return response.ok
	} catch (err) {
		return false
	}
}
