import { useParams } from 'react-router-dom'
import { format, zonedTimeToUtc } from 'date-fns-tz'
import enAU from 'date-fns/locale/en-AU'
import { selectSiteTimezone } from '@Store/sites/sitesWsApi'
import { useGetSiteQuery } from '@Store/sites/sitesApi'

const useUTCTime = (date: string) => {
	const { siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)

	const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
	// Site Time Zone
	const { isSuccess: isSiteSuccess, siteTimeZone } = useGetSiteQuery(siteId, {
		skip: !siteId,
		selectFromResult: ({ isSuccess, data }) => ({
			isSuccess,
			siteTimeZone: selectSiteTimezone(data),
		}),
	})

	const timeZone = isSiteSuccess && siteTimeZone ? siteTimeZone : userTimeZone
	const convertedDate = format(new Date(date), 'yyyy-MM-dd HH:mm', {
		timeZone,
		locale: enAU,
	})

	const converted = zonedTimeToUtc(convertedDate, timeZone).toISOString()
	// Return next string format "2024-04-11 08:49" which is required for server request
	return converted.substring(16, 0).split('T').join(' ')
}

export default useUTCTime
