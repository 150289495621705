import RadarMaskButton from './RadarMaskButton'
import RfFilterButton from './RfFilterButton'
import WhitelistButton from './WhitelistButton'
import {
	useGetSystemInfoQuery,
	selectIsTactical,
} from '@Store/system/systemApi'

const MaskActions = ({ siteId }: { siteId: number }) => {
	// C2T
	const { isTactical } = useGetSystemInfoQuery(undefined, {
		selectFromResult: ({ data }) => ({
			isTactical: selectIsTactical(data),
		}),
	})
	return (
		<>
			{!isTactical && <RadarMaskButton siteId={siteId} />}
			<RfFilterButton />
			<WhitelistButton />
		</>
	)
}

export default MaskActions
