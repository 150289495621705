import { useParams } from 'react-router-dom'
import { skipToken } from '@reduxjs/toolkit/query/react'
import { useTranslation } from 'react-i18next'
import { Icon } from '@chakra-ui/react'
import { IoShieldOutline } from 'react-icons/io5'
import { MdMonitor, MdOutlineBuild } from 'react-icons/md'

import Select from '@UI/Select/Select'
import type { Option } from '@UI/Select/Select'

import type { SiteMode } from '@Store/types'
import { useGetSiteLiveQuery, selectSiteMode } from '@Store/sites/sitesWsApi'
import { useUpdateSiteModeMutation } from '@Store/sites/sitesApi'
import { useAppSelector } from '@Store/index'
import { selectShowNetworkDisconnected } from '@Store/ui/uiSlice'

import useAuth from '@Hooks/useAuth'

// TODO: add simulation option when ready

const icons = {
	operational: <Icon as={IoShieldOutline} h={4} w={4} />,
	monitor: <Icon as={MdMonitor} h={4} w={4} />,
	calibration: <Icon as={MdOutlineBuild} h={4} w={4} />,
}

const SiteModeSelect = () => {
	const { t } = useTranslation('navigation', { keyPrefix: 'siteModeSelect' })

	const { siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)

	const { isOperator } = useAuth()

	const [changeSiteMode] = useUpdateSiteModeMutation()
	const { siteMode } = useGetSiteLiveQuery(siteId > 0 ? siteId : skipToken, {
		selectFromResult: ({ isSuccess, data }) => ({
			siteMode: isSuccess ? selectSiteMode(data) : undefined,
		}),
	})

	const isNetworkDisconnected = useAppSelector(selectShowNetworkDisconnected)

	const handleChange = async (option: Option<SiteMode>) => {
		const mode = option.value as SiteMode
		if (siteId) {
			try {
				await changeSiteMode({ siteId, mode }).unwrap()
			} catch (error) {
				console.error(error)
			}
		}
	}

	const modeOptions = (
		t('modes', { returnObjects: true }) as Array<{
			value: SiteMode
			label: string
			icon?: JSX.Element
		}>
	).map((option) => ({
		...option,
		icon: icons[option.value],
	}))

	const defaultValue = modeOptions.find((type) => type.value === siteMode)

	return (
		isOperator &&
		siteMode && (
			<Select
				value={defaultValue}
				onChange={handleChange}
				options={modeOptions}
				variant='site_mode_select'
				id='site-mode-select'
				isDisabled={isNetworkDisconnected}
			/>
		)
	)
}

export default SiteModeSelect
