// React
import { useParams, useMatch, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// Chakra
import { Button, Flex } from '@chakra-ui/react'
// Components
import Headings from '@UI/Headings/Headings'
import NotesList from '@Components/Site/Panels/Notes/NotesList'
import NoteAdd from '@Components/Site/Panels/Notes/NoteAdd'
import NoteExport from '@Components/Site/Panels/Notes/NoteExport'
import ErrorBoundary from '@Components/App/ErrorHandling/ErrorBoundary'
import NotesForm from '@Components/Forms/NotesForm/NotesForm'
import { MdArrowBackIosNew } from 'react-icons/md'

const Notes = () => {
	const { siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)
	const mode = useMatch('/:siteId/notes/add') ? 'add' : 'list'
	const navigate = useNavigate()

	const { t } = useTranslation(['app', 'panels'])
	// Leave it here as an example of using multiply translations from different namespaces
	const backButtonTitle = t('global.buttons.back')
	// ^^ could be app:global.buttons.back as well
	const title = t('panels:notes.entity')
	// namespaces have to separated by semicolon

	const handleBackClick = () => {
		navigate(`/${siteId}/notes`)
	}

	return (
		<>
			<Flex justifyContent='space-between' alignItems='center'>
				<Headings.Section title={title} testId='notes-header' />
				{mode === 'list' && <NoteAdd siteId={siteId} />}
				{mode === 'add' && (
					<Button
						leftIcon={<MdArrowBackIosNew />}
						variant='ghost'
						onClick={handleBackClick}
						data-testid='back'
					>
						{backButtonTitle}
					</Button>
				)}
			</Flex>
			{mode === 'add' && (
				<ErrorBoundary>
					<Flex marginBlockStart={1} display='block'>
						<NotesForm siteId={siteId} />
					</Flex>
				</ErrorBoundary>
			)}
			{mode === 'list' && (
				<ErrorBoundary>
					<Flex flexDirection='column' mt={3} gap={3}>
						<NotesList siteId={siteId} />
						<Flex>
							<NoteExport siteId={siteId} />
						</Flex>
					</Flex>
				</ErrorBoundary>
			)}
		</>
	)
}

export default Notes
