import { useTheme } from '@chakra-ui/react'
import SemiCircle from '@Components/MapShapes/SemiCircle/SemiCircle'
import type { RfFilterSectorProps } from '@Components/Sectors/RfFilterSector/RfFilterSector'

type DsxFilterSectorProps = {
	tolerance: number
	bearing: number
	angle: number
} & RfFilterSectorProps

const DsxFilterSector = ({
	latitude,
	longitude,
	bearing,
	angle,
	tolerance,
	reach = 0,
	isActive = false,
	isDisabled = false,
}: DsxFilterSectorProps) => {
	const {
		semanticTokens: { colors },
	} = useTheme()
	const fillColor = colors.primary
	const strokeColor = colors.sectors.highlight
	const disabledColor = colors.filter_bg__disabled
	const errorColor = colors.error

	const color = isDisabled ? disabledColor : isActive ? strokeColor : fillColor
	const fill = isDisabled ? disabledColor : fillColor
	const strokeWidth = isDisabled ? 0 : 2

	// Display DSX filter sector just with appropriate values
	const isDsxWrongBearing =
		angle < 0 || angle > 359 || tolerance < 1 || tolerance > 180
	if (isNaN(angle) || isNaN(tolerance)) return null

	return (
		<>
			{isDsxWrongBearing ? (
				<SemiCircle
					latitude={latitude}
					longitude={longitude}
					startAngle={0}
					stopAngle={360}
					radius={reach}
					fillColor={errorColor}
					color={errorColor}
					weight={strokeWidth}
				/>
			) : (
				<>
					<SemiCircle
						latitude={latitude}
						longitude={longitude}
						startAngle={bearing + angle - tolerance}
						stopAngle={bearing + angle}
						radius={reach}
						fillColor={fill}
						color={color}
						weight={strokeWidth}
					/>
					<SemiCircle
						latitude={latitude}
						longitude={longitude}
						startAngle={bearing + angle}
						stopAngle={bearing + angle + tolerance}
						radius={reach}
						fillColor={fill}
						color={color}
						weight={strokeWidth}
					/>
				</>
			)}
		</>
	)
}

export default DsxFilterSector
