import useCopyToClipboard from '@Hooks/useCopyToClipboard'
import { Icon, useToast } from '@chakra-ui/react'
import { MdContentCopy } from 'react-icons/md'

const CopyButton = ({
	content,
	successTitle = 'Copied!',
	successDescription = 'Successfully copied to clipboard',
	errorTitle = 'Failed to copy!',
	errorDescription = 'Failed to copy to clipboard',
}: {
	content: string
	successTitle: string
	successDescription: string
	errorTitle: string
	errorDescription: string
}) => {
	const [, copy] = useCopyToClipboard()
	const toast = useToast()

	const handleClick = (text: string) => {
		copy(text)
			.then(() => {
				toast({
					status: 'success',
					title: successTitle,
					description: successDescription,
					duration: 2500,
					position: 'top-right',
					variant: 'left-accent',
				})
			})
			.catch(() => {
				toast({
					status: 'error',
					title: errorTitle,
					description: errorDescription,
					duration: 2500,
					position: 'top-right',
					variant: 'left-accent',
				})
			})
	}

	return (
		<Icon
			as={MdContentCopy}
			onClick={() => handleClick(content)}
			cursor='pointer'
		/>
	)
}

export default CopyButton
