// Packages
import { useState } from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// Alias
import { selectActiveTargetId } from '@Store/ui/uiSlice'
import { useAppSelector } from '@Store/index'
import {
	useGetSiteLiveQuery,
	selectSiteAlertTargetIds,
	selectSiteStaleAlerts,
	selectSiteDetections,
	selectSiteRadarDetections,
} from '@Store/sites/sitesWsApi'
import { skipToken } from '@reduxjs/toolkit/query/react'
import ErrorBoundary from '@Components/App/ErrorHandling/ErrorBoundary'
import type { Detection } from '@/store/types'

// Relatives
import DetectionHeader from '../DetectionDetail/DetectionHeader'
import DetectionDetail from '../DetectionDetail/DetectionDetail'
import ThreatLevelSelector from './ThreatLevelSelector'
import ClearAlertsButton from './ClearAlertsButton'
import useShowPreviewMode from '../../MapLayers/PreviewModes/useShowPreviewMode'
import NotificationDetection from './NotificationDetection'
import AlertSoundButton from './AlertSoundButton'

const Notifications = () => {
	const { siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)
	const { t } = useTranslation('panels', { keyPrefix: 'notifications' })

	const { showCameraCalibrationPreview } = useShowPreviewMode()

	const { alertTargetIds, detections, staleAlerts } = useGetSiteLiveQuery(
		Number.isInteger(siteId) ? siteId : skipToken,
		{
			selectFromResult: ({ data }) => ({
				alertTargetIds: selectSiteAlertTargetIds(data),
				detections: showCameraCalibrationPreview
					? selectSiteRadarDetections(data)
					: selectSiteDetections(data),
				staleAlerts: selectSiteStaleAlerts(data),
			}),
		}
	)
	const activeTargetId = useAppSelector(selectActiveTargetId)
	const [filterThreatLevel, setFilterThreatLevel] = useState<number>(0)

	const filterDetections = (detection: Detection) =>
		detection.threat_level >= filterThreatLevel

	// detections with active alerts first, and user-selected detection on top
	const sortDetections = (a: Detection, b: Detection) =>
		(activeTargetId === a.target_id
			? -2
			: alertTargetIds.includes(a.target_id)
				? -1
				: 1) -
		(activeTargetId === b.target_id
			? -2
			: alertTargetIds.includes(b.target_id)
				? -1
				: 1)

	return (
		(detections.length > 0 || staleAlerts.length > 0) && (
			<ErrorBoundary>
				<Flex
					bgColor='body_bg'
					paddingInline={2}
					paddingBlock={3}
					pointerEvents='all'
					gap={2}
				>
					<Text
						fontWeight='bold'
						textTransform='uppercase'
						color='label_color'
						flexGrow={1}
					>
						{t('notificationsPanelHeading')}
					</Text>
					<AlertSoundButton />
					<ThreatLevelSelector
						value={filterThreatLevel}
						disabled={detections.length < 1}
						onChange={(value) => {
							setFilterThreatLevel(value)
						}}
					/>
					<ClearAlertsButton disabled={alertTargetIds.length < 1} />
				</Flex>
				<Flex
					flexGrow={1}
					flexShrink={0}
					flexDirection='column'
					position='relative'
				>
					<Box
						display='flex'
						flexDirection='column'
						flexGrow={1}
						pointerEvents='none'
						position='absolute'
						h='100%'
						w='100%'
					>
						<Box overflowY='scroll'>
							{detections
								.filter(filterDetections)
								.sort(sortDetections)
								.map((detection) => (
									<NotificationDetection
										key={detection.target_id}
										detection={detection}
										activeTargetId={activeTargetId}
									/>
								))}
							{staleAlerts.map(
								(alert) =>
									alert.detection && (
										<Box
											key={alert.track.id}
											marginBlockEnd='1px'
											data-testid='stale-detection-notification-box'
											pointerEvents='all'
										>
											<ErrorBoundary quiet>
												<DetectionHeader
													key={alert.track.id}
													detection={alert.detection}
													stale={true}
												/>
											</ErrorBoundary>
											{activeTargetId === alert.track.id && (
												<ErrorBoundary quiet>
													<DetectionDetail detection={alert.detection} />
												</ErrorBoundary>
											)}
										</Box>
									)
							)}
						</Box>
					</Box>
				</Flex>
			</ErrorBoundary>
		)
	)
}

export default Notifications
