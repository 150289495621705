import { z } from 'zod'
import globalSchema from '@/components/Forms/BaseForm/BaseForm.schema'

const pickedFromGlobal = globalSchema.pick({
	name: true,
})
const cameraFormFields = z.object({
	altitude: z.number(),
	always_stream: z.boolean(),
	auto_record: z.boolean(),
	auto_track: z.number({
		errorMap: (issue, ctx) => ({ message: 'Please select option' }),
	}),
	control: z.string().optional(),
	direction_offset: globalSchema.shape.direction_offset,
	droneoptid_prob_threshold: z.number(),
	edit_type: z.string(),
	ip: z.string().optional(),
	mac_address: z.string().optional(),
	max_fov_angle: z.number(),
	max_tilt_angle: z.number(),
	min_fov_angle: z.number(),
	min_tilt_angle: z.number(),
	name: z.string(),
	password: z.string().nullable().optional(),
	recording_max_duration: z.number(),
	serial_number: z.string().min(1),
	sentry_id: z.number(),
	streaming_bitrate: z.number(),
	tracking_auto_focus: z.boolean(),
	tracking_control_loop_speed: z.number(),
	tracking_manual_focus: z.boolean(),
	tracking_slew_hfov_percent: z.number(),
	tracking_slew_retry_delay: z.number(),
	username: z.string(),
})

export const cameraFormSchema = cameraFormFields.merge(pickedFromGlobal)
export type CameraSchema = z.infer<typeof cameraFormSchema>

const defaultValuesSchema = cameraFormSchema.omit({
	name: true,
	sentry_id: true,
	serial_number: true,
	ip: true,
	mac_address: true,
	password: true,
	control: true,
})

type DefaultValues = z.infer<typeof defaultValuesSchema>

export const ADD_CAMERA_DEFAULT_VALUES: DefaultValues = {
	altitude: 0,
	always_stream: true,
	auto_record: false,
	auto_track: 0,
	direction_offset: 0,
	droneoptid_prob_threshold: 30,
	edit_type: 'form',
	max_fov_angle: 63.7,
	max_tilt_angle: 90,
	min_fov_angle: 2.3,
	min_tilt_angle: -55,
	recording_max_duration: 10,
	streaming_bitrate: 1,
	tracking_auto_focus: true,
	tracking_control_loop_speed: 0,
	tracking_manual_focus: true,
	tracking_slew_hfov_percent: 3,
	tracking_slew_retry_delay: 0.5,
	username: 'service',
}
