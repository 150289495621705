import { z } from 'zod'
import globalSchema, {
	passwordOptionalSchema,
} from '@/components/Forms/BaseForm/BaseForm.schema'

// Translations
import i18n from '@/i18n'
const t = (key: string) => i18n.t(`forms:profileForm.validation.${key}`)

export const profileFormSchema = z.object({
	name: globalSchema.shape.name,
	email: globalSchema.shape.email,
	phone: globalSchema.shape.phone,
	phone_region: globalSchema.shape.countryCode,
	id: z.number().optional(),
	role: z.enum(['admin', 'manager', 'user'], {
		errorMap: (issue, ctx) => ({ message: t('role') }),
	}),
	username: z.string().optional(),
	company: z.string().optional(),
	current_password: z.string().optional(),
	default_site_id: z.number().optional(),
	language: z.string(),
	play_alarm_sounds: z.boolean(),
	settings: z.object({
		displayUnit: z.string().optional(),
		mgrsEnabled: z.boolean(),
		milsEnabled: z.boolean(),
	}),
	password: passwordOptionalSchema.shape.password,
	password_confirmation: passwordOptionalSchema.shape.password_confirmation,
})

// TODO: Add global password, email, phone schemas

export type ProfileSchema = z.infer<typeof profileFormSchema>
