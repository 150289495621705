import { Box, Flex } from '@chakra-ui/react'
import { useGetSystemInfoQuery, selectLogo } from '@Store/system/systemApi'

// DroneShield Logo
import DroneShieldLogo from '@UI/DroneShieldLogo/DroneShieldLogo'

// CUSTOMERS LOGOS
import BtLogo from './BtLogo'
import EpirusLogo from './EpirusLogo'
import TrakkaLogo from './TrakkaLogo'

const logoSet = {
	bt: <BtLogo />,
	trakka: <TrakkaLogo />,
	epirus: <EpirusLogo />,
}

const TopNavigationLogo = () => {
	const { clientLogo } = useGetSystemInfoQuery(undefined, {
		selectFromResult: ({ isSuccess, data }) => ({
			clientLogo: isSuccess ? selectLogo(data) : undefined,
		}),
	})

	return (
		<Flex justifyContent='flex-start' alignItems='center' gap={4}>
			{clientLogo !== 'none' ? (
				<>
					<Flex
						height='42px'
						maxW='80px'
						sx={{ '& > svg': { height: '100%' } }}
					>
						{logoSet[clientLogo as keyof typeof logoSet]}
					</Flex>
					<DroneShieldLogo
						height='34px'
						width='100%'
						testId='navigation-logo'
						isPoweredBy
					/>
				</>
			) : (
				<DroneShieldLogo height='42px' width='100%' testId='navigation-logo' />
			)}
		</Flex>
	)
}

const LoginPageLogo = () => {
	const { clientLogo } = useGetSystemInfoQuery(undefined, {
		selectFromResult: ({ isSuccess, data }) => ({
			clientLogo: isSuccess ? selectLogo(data) : undefined,
		}),
	})

	return (
		<Flex direction='column'>
			{clientLogo !== 'none' ? (
				<>
					<Box
						data-testid='login-customer-logo'
						flex={1}
						maxHeight='120px'
						marginBlockEnd={4}
						sx={{ '& > svg': { maxHeight: '100%' } }}
					>
						{logoSet[clientLogo as keyof typeof logoSet]}
					</Box>
					<DroneShieldLogo
						height='36px'
						width='100%'
						testId='login-logo'
						isPoweredBy
					/>
				</>
			) : (
				<DroneShieldLogo height='60px' width='256px' testId='login-logo' />
			)}
		</Flex>
	)
}

const Logos = {
	TopNavigation: TopNavigationLogo,
	Login: LoginPageLogo,
}

export default Logos
