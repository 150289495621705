import { IconButton } from '@chakra-ui/react'
import { MdMyLocation } from 'react-icons/md'

import { useMoveCameraHomeMutation } from '@Store/cameras/camerasApi'
import { useParams } from 'react-router-dom'

const GotoHomeButton = ({
	cameraId,
	isDisabled,
}: {
	cameraId: number
	isDisabled: boolean
}) => {
	const { siteId: siteIdParam } = useParams()
	const [moveCameraHome] = useMoveCameraHomeMutation()
	const handleClick = () => {
		moveCameraHome({ siteId: Number(siteIdParam), cameraId }).catch(
			(reason: any) => {
				console.error('camera reset', reason)
			}
		)
	}
	return (
		<IconButton
			icon={<MdMyLocation size={24} />}
			variant='unstyled'
			onClick={handleClick}
			sx={{ svg: { margin: '0 auto' } }}
			aria-label='trigger camera wiper'
			isDisabled={isDisabled}
		/>
	)
}

export default GotoHomeButton
