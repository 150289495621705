//
// Typescript docs
// https://github.com/convert-units/convert-units#typescript
//
// For additional units beyond length, frequency, speed, time:
// https://github.com/convert-units/convert-units#packaged-units

import configureMeasurements from 'convert-units'

import length from 'convert-units/definitions/length'
import type {
	LengthSystems,
	LengthUnits,
} from 'convert-units/definitions/length'

import frequency from 'convert-units/definitions/frequency'
import type {
	FrequencySystems,
	FrequencyUnits,
} from 'convert-units/definitions/frequency'

import speed from 'convert-units/definitions/speed'
import type { SpeedSystems, SpeedUnits } from 'convert-units/definitions/speed'

import time from 'convert-units/definitions/time'
import type { TimeSystems, TimeUnits } from 'convert-units/definitions/time'

type Measures = 'length' | 'frequency' | 'speed' | 'time'
type Systems = LengthSystems | FrequencySystems | SpeedSystems | TimeSystems
type Units = LengthUnits | FrequencyUnits | SpeedUnits | TimeUnits

const convertUnits = configureMeasurements<Measures, Systems, Units>({
	length,
	frequency,
	speed,
	time,
})

export default convertUnits
