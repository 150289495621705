import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import {
	Box,
	Flex,
	Step,
	StepDescription,
	StepIndicator,
	StepNumber,
	Stepper,
	StepSeparator,
	StepStatus,
	StepTitle,
	SlideFade,
	Text,
} from '@chakra-ui/react'

import Button from '@UI/Button/Button'
import FetchError from '@UI/FetchError/FetchError'
import SpinnerText from '@UI/SpinnerText/SpinnerText'

import { useGetCameraQuery } from '@Store/cameras/camerasApi'

import { useCalibrateCameraBracketMutation } from '@Store/cameras/camerasApi'

type BracketCalibrationProps = {
	activeStep: number
	siteId: number
	installationId: number
	sensorId: number
}

const BracketCalibration = ({
	activeStep,
	siteId,
	installationId,
	sensorId,
}: BracketCalibrationProps) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'siteInstallations.cameraCalibration',
	})

	const navigate = useNavigate()
	const [calibrateCameraBracket] = useCalibrateCameraBracketMutation()

	const {
		// data: camera,
		isSuccess,
		isError,
		refetch,
	} = useGetCameraQuery({
		siteId,
		cameraId: sensorId,
	})

	const camerasRoute = `/${siteId}/installations/${installationId}/cameras`

	const startCalibration = useCallback(() => {
		try {
			calibrateCameraBracket({
				cameraId: sensorId,
				siteId,
				action: 'start',
			}).unwrap()
			return true
		} catch (error) {
			console.error('Unhandled start bracket calibration error')
			return false
		}
	}, [calibrateCameraBracket, sensorId, siteId])

	const stopCalibration = useCallback(() => {
		try {
			calibrateCameraBracket({
				cameraId: sensorId,
				siteId,
				action: 'stop',
			}).unwrap()
			return true
		} catch (error) {
			console.error('Unhandled stop bracket calibration error')
			return false
		}
	}, [calibrateCameraBracket, sensorId, siteId])

	useEffect(() => {
		startCalibration()
		return () => {
			stopCalibration()
		}
	}, [startCalibration, stopCalibration])

	const handleStop = () => {
		if (stopCalibration()) navigate(camerasRoute)
	}

	const steps = Array(
		t('bracketCalibration.steps', { returnObjects: true })
	).flat() as string[]

	return (
		<>
			{isError && <FetchError refetch={refetch} entity={t('entity')} />}
			{isSuccess && (
				<>
					<Box my={4}>
						<Stepper
							size='sm'
							orientation='vertical'
							index={activeStep}
							variant='calibration'
							colorScheme='primary'
						>
							{steps.map((step, index) => (
								<Step key={index}>
									<StepIndicator>
										<StepStatus
											complete={<StepNumber />}
											incomplete={<StepNumber />}
											active={<StepNumber />}
										/>
									</StepIndicator>

									<Box w='fit-content'>
										<StepTitle>{step}</StepTitle>
										<Box mt={3}>
											{index === activeStep ? (
												{
													1: (
														<SlideFade in unmountOnExit>
															<Flex direction='column' p={4} bgColor='body_bg'>
																<SpinnerText
																	text={t('bracketCalibration.intro')}
																	status='active'
																/>
																<Text
																	fontSize='sm'
																	color='gray.400'
																	marginInlineStart={7}
																	paddingBlock={3}
																>
																	{t('bracketCalibration.stayText')}
																</Text>
																<Flex>
																	<Button
																		label={t('buttons.stopCalibration')}
																		onClick={handleStop}
																		variant='outline'
																		testId='stop-bracket-calibration'
																	/>
																</Flex>
															</Flex>
														</SlideFade>
													),
												}[index]
											) : (
												<StepDescription style={{ minHeight: '25px' }} />
											)}
										</Box>
									</Box>

									<StepSeparator />
								</Step>
							))}
						</Stepper>
					</Box>
				</>
			)}
		</>
	)
}

export default BracketCalibration
