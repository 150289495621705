import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import {
	Box,
	Step,
	StepDescription,
	StepIndicator,
	StepNumber,
	Stepper,
	StepSeparator,
	StepStatus,
	StepTitle,
	SlideFade,
} from '@chakra-ui/react'

import { point } from '@turf/helpers'

import Button from '@UI/Button/Button'
import Footer from '@UI/Footer/Footer'
import FetchError from '@UI/FetchError/FetchError'

import {
	useGetSiteLiveQuery,
	selectSiteRadarDetections,
	selectSiteCameraDetections,
} from '@Store/sites/sitesWsApi'
import {
	useGetCameraQuery,
	useCalibrateCameraMutation,
	useUpdateCameraMutation,
} from '@Store/cameras/camerasApi'
import { useGetSiteInstallationQuery } from '@Store/sites/sitesApi'
import { useAppSelector } from '@Store/index'
import { selectActiveTargetId } from '@Store/ui/uiSlice'

import TargetAcquisition from './TargetAcquisition/TargetAcquisition'
import TiltCalibration from './TiltCalibration/TiltCalibration'
import PanCalibration from './PanCalibration/PanCalibration'
import CalibrationCheck from './CalibrationCheck'

import { getCircularBounds } from '@Utils/cameraCalibration'

import type {
	RadarDetectionMap,
	CameraDetectionMap,
	TiltResult,
} from '../types'

const TILT_THRESHOLD = 130

type DroneCalibrationProps = {
	activeStep: number
	siteId: number
	installationId: number
	sensorId: number
}

const DroneCalibration = ({
	activeStep,
	siteId,
	installationId,
	sensorId,
}: DroneCalibrationProps) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'siteInstallations.cameraCalibration',
	})
	const [radarDetections, setRadarDetections] = useState<RadarDetectionMap>({})
	const [cameraDetections, setCameraDetections] = useState<CameraDetectionMap>(
		{}
	)
	const [tiltResult, setTiltResult] = useState<TiltResult>()
	const [panResult, setPanResult] = useState<number>()

	const [calibrateCamera] = useCalibrateCameraMutation()
	const [updateCameraCalibration] = useUpdateCameraMutation()
	const navigate = useNavigate()

	const {
		data: camera,
		isSuccess,
		isError,
		refetch,
	} = useGetCameraQuery({
		siteId,
		cameraId: sensorId,
	})

	const { installationDirection } = useGetSiteInstallationQuery(
		{ siteId, installationId },
		{
			selectFromResult: ({ data }) => ({
				installationDirection: data?.direction || 0,
			}),
		}
	)

	const { radarDetection, cameraDetection } = useGetSiteLiveQuery(siteId, {
		selectFromResult: ({ data }) => ({
			radarDetection: selectSiteRadarDetections(data)[0],
			cameraDetection: selectSiteCameraDetections(data)[0],
		}),
	})

	useEffect(() => {
		return () => {
			calibrateCamera({ cameraId: sensorId, siteId, action: 'stop' })
		}
	}, [calibrateCamera, sensorId, siteId])

	const sensorCoordinates = point([
		camera?.longitude || 0,
		camera?.latitude || 0,
	])

	if (radarDetection) {
		const { target_id, altitude, longitude, latitude } = radarDetection

		const oldDetection = radarDetections[target_id]

		if (radarDetection.id !== radarDetections[target_id]?.id) {
			// Create radar detection map with added fields. E.g. count, coord history.
			setRadarDetections((prev: RadarDetectionMap) => ({
				...prev,
				[target_id]: {
					...radarDetection,
					count: (oldDetection?.count || 0) + 1,
					coordinateHistory: [
						[longitude, latitude],
						...(oldDetection?.coordinateHistory || []),
					],
					altitudeHistory: [altitude, ...(oldDetection?.altitudeHistory || [])],
				},
			}))
		}
	}

	if (cameraDetection) {
		const { x, y } = cameraDetection.detection_contributions[0] || {}
		const { target_id } = cameraDetection

		const oldDetection = cameraDetections[target_id]

		if (cameraDetection.id !== cameraDetections[target_id]?.id) {
			// Create camera detection map with added fields. E.g. position history.
			setCameraDetections((prev: CameraDetectionMap) => ({
				...prev,
				[target_id]: {
					...cameraDetection,
					count: (oldDetection?.count || 0) + 1,
					positionHistory: [[x, y], ...(oldDetection?.positionHistory || [])],
				},
			}))
		}
	}

	const targetId = useAppSelector(selectActiveTargetId) ?? ''

	useEffect(() => {
		if (!targetId) {
			setRadarDetections({})
		}
	}, [targetId])

	const camerasRoute = `/${siteId}/installations/${installationId}/cameras`

	const handleSubmit = async () => {
		if (tiltResult && panResult && camera) {
			const { min: minTilt, max: maxTilt } = tiltResult
			// TODO: Handle error
			if (
				![minTilt, maxTilt].every((t) => Math.abs(t) <= TILT_THRESHOLD) ||
				!(panResult <= 360 || panResult >= 0)
			) {
				console.error('Unhandled calibration error - invalid values')
				return
			}

			const updatedCameraCalibration = {
				name: camera.name,
				serial_number: camera.serial_number,
				sentry_id: installationId,
				direction_offset: getCircularBounds(
					panResult - installationDirection,
					[0, 360]
				),
				min_tilt_angle: minTilt,
				max_tilt_angle: maxTilt,
			}

			try {
				await updateCameraCalibration({
					...camera,
					siteId,
					id: sensorId,
					...updatedCameraCalibration,
				}).unwrap()
				navigate(camerasRoute)
			} catch (error) {
				console.error('Unhandled calibration error - form submit erro')
			}
		}
	}

	const steps = Array(
		t('droneCalibrationSteps', { returnObjects: true })
	).flat() as string[]

	return (
		<>
			{isSuccess && (
				<>
					<Box my={4}>
						<Stepper
							size='sm'
							orientation='vertical'
							index={activeStep}
							variant='calibration'
							colorScheme='primary'
						>
							{steps.map((step, index) => (
								<Step key={index}>
									<StepIndicator>
										<StepStatus
											complete={<StepNumber />}
											incomplete={<StepNumber />}
											active={<StepNumber />}
										/>
									</StepIndicator>

									<Box w='fit-content'>
										<StepTitle>{step}</StepTitle>
										<Box mt={3}>
											{index === activeStep ? (
												{
													1: (
														<SlideFade in unmountOnExit>
															<TargetAcquisition
																radarDetections={radarDetections}
																cameraDetections={cameraDetections}
																sensorCoordinates={sensorCoordinates}
															/>
														</SlideFade>
													),
													2: (
														<SlideFade in unmountOnExit>
															<TiltCalibration
																radarDetections={radarDetections}
																sensorCoordinates={sensorCoordinates}
																tiltResult={tiltResult}
																setTiltResult={setTiltResult}
															/>
														</SlideFade>
													),
													3: (
														<SlideFade in unmountOnExit>
															<PanCalibration
																radarDetections={radarDetections}
																sensorCoordinates={sensorCoordinates}
																setPanResult={setPanResult}
															/>
														</SlideFade>
													),
													4: (
														<SlideFade in unmountOnExit>
															<CalibrationCheck
																tiltResult={tiltResult}
																panResult={panResult}
																siteId={siteId}
																sensorId={sensorId}
															/>
														</SlideFade>
													),
												}[index]
											) : (
												<StepDescription style={{ minHeight: '25px' }} />
											)}
										</Box>
									</Box>

									<StepSeparator />
								</Step>
							))}
						</Stepper>
					</Box>
					<Footer>
						<Button
							label={t('buttons.submit')}
							testId='submit'
							variant='outline'
							onClick={handleSubmit}
							isDisabled={activeStep !== 4}
						/>
						<Button
							label={t('buttons.cancel')}
							testId='cancel'
							onClick={() => navigate(camerasRoute)}
						/>
					</Footer>
				</>
			)}
			{isError && <FetchError refetch={refetch} entity={t('entity')} />}
		</>
	)
}

export default DroneCalibration
