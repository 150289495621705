import Skeleton from '@UI/Skeleton/Skeleton'
import Note from './Note'
import { useGetNotesQuery } from '@Store/notes/notesApi'
import { selectSiteTimeZone, useGetSiteQuery } from '@Store/sites/sitesApi'

const NotesList = ({ siteId }: { siteId: number }) => {
	const {
		isLoading,
		isError,
		isSuccess,
		data: notes,
	} = useGetNotesQuery({ siteId }, { skip: !siteId })

	const { siteTimeZone, isTimeZoneSuccess, isTimeZoneLoading } =
		useGetSiteQuery(siteId, {
			selectFromResult: ({ data, isSuccess, isLoading }) => ({
				siteTimeZone: selectSiteTimeZone(data),
				isTimeZoneSuccess: isSuccess,
				isTimeZoneLoading: isLoading,
			}),
		})

	const isLoaded =
		isSuccess &&
		!isError &&
		!isLoading &&
		isTimeZoneSuccess &&
		!isTimeZoneLoading

	if (!siteTimeZone) return null

	return (
		<Skeleton isLoaded={isLoaded} w='full'>
			{notes?.map((note) => (
				<Note key={note.id} note={note} timeZone={siteTimeZone} />
			))}
		</Skeleton>
	)
}

export default NotesList
