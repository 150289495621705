// TODO: May? need to move this one up and down according to altitude

type TerrainProps = {
	x?: number
	y?: number
}

const Terrain = ({ x = 0, y = 340.75 }: TerrainProps) => {
	return (
		<svg width='100%' height='100%' viewBox='0 0 1240 697.50'>
			<g
				transform={`translate(${x}, ${y})`}
				fill='none'
				strokeWidth={4}
				stroke='var(--c2-colors-gray-400)'
			>
				<path d='M0,8.00064553 C10.24,8.00064553 9.24,-0.623964174 15.52,0.0360358263 C21.8,0.696035826 29.4,3.10603583 33.7,2.91603583 C38,2.72603583 41.19,2.80603583 43.28,4.01603583 C45.37,5.22603583 57.17,15.9460358 63.28,17.0160358 C69.39,18.0860358 91.59,24.3060358 96.11,24.5260358 C100.63,24.7460358 125.42,28.1660358 128.72,29.5260358 C132.02,30.8860358 142.5,36.5260358 147.23,36.9260358 C151.96,37.3260358 161.34,35.1560358 166.63,32.6160358 C171.92,30.0760358 189.43,22.6760358 193.18,22.4560358 C196.93,22.2360358 205.7,30.1160358 208.18,31.6260358 C210.66,33.1360358 226.5,38.9860358 229.3,39.7560358 C232.1,40.5260358 246.57,48.4460358 253,49.1060358 C264.83,50.2560358 367.48,49.1060358 367.48,49.1060358 C367.48,49.1060358 577.98,45.9460358 586.57,45.1060358 C595.16,44.2660358 991.52,21.6160358 1005.33,18.9660358 C1020.11,16.1360358 1020.14,16.2460358 1110.67,9.83603583 C1110.67,9.83603583 1238.02,8.01064553 1240,8.00064553' />
			</g>
		</svg>
	)
}

export default Terrain
