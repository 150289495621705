// React Router
import { Outlet } from 'react-router-dom'

// Components
import { LeftNavigation, TopNavigation } from '@Components/Navigation'
import { Grid, GridItem } from '@chakra-ui/react'
import ToastProvider from '@UI/ToastProvider/ToastProvider'
import ErrorBoundary from '@Components/App/ErrorHandling/ErrorBoundary'

// Main Dashboard Layout
const Dashboard = () => {
	return (
		<Grid
			className='Grid'
			templateAreas={`
				"header header" 
				"aside main"
			`}
			gridTemplateRows={'50px 1fr'}
			gridTemplateColumns={'60px 1fr'}
			h='100vh'
		>
			<GridItem
				className='GridHeader'
				area='header'
				backgroundColor='navbar.top'
			>
				<ErrorBoundary quiet>
					<TopNavigation />
				</ErrorBoundary>
			</GridItem>
			<GridItem
				className='GridAside'
				area='aside'
				backgroundColor='navbar.left'
				zIndex={2000}
			>
				<ErrorBoundary quiet>
					<LeftNavigation />
				</ErrorBoundary>
			</GridItem>
			<GridItem className='GridMain' area='main' h='100%' overflow='auto'>
				<Outlet />
				<ToastProvider />
			</GridItem>
		</Grid>
	)
}

export default Dashboard
