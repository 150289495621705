// React
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
// React-table
import { createColumnHelper } from '@tanstack/react-table'
// Chakra
import { Box, Flex, Text } from '@chakra-ui/react'
// Components
import UsersDataTable from '@UI/UsersDataTable/UsersDataTable'
import { UserFormModal } from '@Forms/UserForm/UserForm'
// Store
import type { Client, User } from '@Store/types'
import { useGetSitesListQuery } from '@Store/sites/sitesApi'
import {
	useGetUserQuery,
	useGetUsersListWithDeletedUsersQuery,
} from '@Store/user/usersApi'
import { useGetClientsListQuery } from '@Store/clients/clientsApi'
import UserManagementActionButtons from '../UserManagementActionButtons'
import { useAppSelector } from '@/store'
import { selectUserId } from '@Store/user/userSlice'
import Skeleton from '@UI/Skeleton/Skeleton'
import useAuth from '@Hooks/useAuth'
type ColumnsType = {
	name: string
	username: string
	email: string
	phone: string
	company: string
	role: string
	client?: string
	sites: string
	actions: string
}

const UserManagementUsersTable = () => {
	const { t } = useTranslation('pages', {
		keyPrefix: 'userManagement',
	})

	const { isAdmin } = useAuth()

	const userId = useAppSelector(selectUserId) || 0

	const { data: user } = useGetUserQuery(userId, {
		skip: !userId,
	})

	const { data: users, isSuccess: isUsersSuccess } =
		useGetUsersListWithDeletedUsersQuery(
			{
				user: user as User,
			},
			{ skip: !user }
		)
	const { data: sites, isSuccess: isSitesSuccess } = useGetSitesListQuery()
	const { data: clients, isSuccess: isClientsSuccess } = useGetClientsListQuery(
		user?.client_id,
		{ skip: !user }
	)

	const usersWithSiteAndClientsNames = useMemo(() => {
		const usersSiteNames = (clientId: number, user: User) => {
			switch (user.role) {
				case 'admin':
					return ''
				case 'manager':
					return sites
						?.filter((site) => site.client_id === clientId)
						.map((site) => site.name)
						.join(', ')
				case 'user':
					return user?.site_associations
						?.map((site_user) =>
							sites?.find((site) => site.id === site_user.site_id)
						)
						.map((site) => site?.name)
						.join(', ')
			}
		}

		const usersClientName = (clientId: number) => {
			return clients
				?.filter((client) => client.id === clientId)
				.map((client) => client.name)
		}

		if (!isUsersSuccess || !isClientsSuccess || !isSitesSuccess) return []

		return users?.map((user) => {
			const { client_id } = user
			return {
				...user,
				sites: client_id ? usersSiteNames(client_id, user) : '',
				client: client_id ? usersClientName(client_id) : '',
			}
		})
	}, [users, sites, clients, isUsersSuccess, isClientsSuccess, isSitesSuccess])

	const columnsWidth: ColumnsType = {
		name: '245px',
		username: '245px',
		email: '270px',
		phone: '245px',
		company: '245px',
		role: '245px',
		client: '245px',
		sites: '245px',
		actions: '80px',
	}
	if (!isAdmin) delete columnsWidth?.client

	const columns = Object.entries(columnsWidth).map(([field, width]) => {
		if (field !== 'actions') {
			return createColumnHelper<Client>().accessor(field as keyof Client, {
				header: field,
				cell: (info) => (
					<Box maxW={width} w='100%'>
						<Text isTruncated>{info.getValue()}</Text>
					</Box>
				),
			})
		} else {
			return {
				header: t('table.actions'),
				cell: (
					{ row }: any // Probably CellContext[row] lets skip it please for now
				) => {
					const user = row?.original
					return (
						<Flex alignItems='center' gap={2}>
							{userId !== user.id && (
								<>
									<UserFormModal
										userId={user?.id}
										userName={user.username}
										isActive={!user?.deleted_at}
									/>
									<UserManagementActionButtons user={user} />
								</>
							)}
						</Flex>
					)
				},
			}
		}
	})

	return (
		<Skeleton isLoaded={isUsersSuccess && isClientsSuccess && isSitesSuccess}>
			<UsersDataTable
				columns={columns}
				data={usersWithSiteAndClientsNames || []}
				actionComponent={
					<Box>
						{/*TODO: Invite User*/}
						{/*<Button leftIcon={<MdSend />} variant='ghost' isDisabled>*/}
						{/*	{t('buttons.inviteUser')}*/}
						{/*</Button>*/}
						<UserFormModal userId={0} />
					</Box>
				}
			/>
		</Skeleton>
	)
}

export default UserManagementUsersTable
