// React
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
// Components
import { Box } from '@chakra-ui/react'
import Field from '@Components/FormElements'
import { FormButtons } from '@Components/FormElements/FormButtons/FormButtons'
// ZOD
import { zodResolver } from '@hookform/resolvers/zod'
import type { TypeOf } from 'zod'
import {
	defaultValues,
	mapLayersFormSchema,
} from '@Forms/MapsZonesForms/MapLayersForm/MapLayersForm.schema'
// Store
import { useCreateMapLayerMutation } from '@Store/mapLayers/mapLayersApi'

const CreateOnlineMapLayerForm = ({ onClose }: { onClose: () => void }) => {
	// Translations
	const { t } = useTranslation('forms', { keyPrefix: 'mapsZonesForm' })

	type Schema = TypeOf<typeof mapLayersFormSchema>

	const methods = useForm<Schema>({
		resolver: zodResolver(mapLayersFormSchema),
		defaultValues,
	})
	const {
		register,
		formState: { errors, isSubmitting, isDirty },
		handleSubmit,
		setError,
		setValue,
	} = methods

	const [createMapLayer] = useCreateMapLayerMutation()
	const handleSave = async (data: Schema) => {
		try {
			await createMapLayer({ ...data })
				.unwrap()
				.then(() => {
					onClose()
				})
		} catch (errors) {
			// Surface server-side validation errors to react-hook-form
			for (const field in errors as { [name in keyof Schema]: string }) {
				setError(field as keyof Schema, {
					type: 'custom',
					message: (errors as { [name in keyof Schema]: string })[
						field as keyof Schema
					] as string,
				})
			}
		}
	}

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(handleSave)}>
				<Field.TextInput
					title={t('mapLayersForm.name')}
					register={register('name')}
					error={errors?.name?.message}
					isRequired
				/>
				<Field.TextInput
					title={t('mapLayersForm.attribution')}
					register={register('attribution')}
					error={errors?.attribution?.message}
					isRequired
				/>
				<Field.TextInput
					title={t('mapLayersForm.url')}
					register={register('url')}
					error={errors?.url?.message}
					isRequired
				/>
				<Field.RangeSlider
					title={t('mapLayersForm.minMaxZoom')}
					defaultValue={[defaultValues.min_zoom, defaultValues.max_zoom]}
					step={1}
					min={1}
					max={30}
					onChangeEnd={(value: [number, number]) => {
						setValue('min_zoom', value[0], {
							shouldDirty: true,
							shouldValidate: true,
							shouldTouch: true,
						})
						setValue('max_zoom', value[1], {
							shouldDirty: true,
							shouldValidate: true,
							shouldTouch: true,
						})
					}}
					testId='zoom'
				/>
				{/* 32px is switch width + length of ch of translated title */}
				<Box width={`calc(32px + ${t('mapLayersForm.fallback').length}ch)`}>
					<Field.Switch
						title={t('mapLayersForm.fallback')}
						tooltip={t('mapLayersForm.fallbackMessage')}
						register={register('fallback')}
						error={errors?.fallback?.message}
					/>
				</Box>
				<FormButtons
					isSubmitting={isSubmitting}
					isDirty={isDirty}
					handleCancel={onClose}
				/>
			</form>
		</FormProvider>
	)
}
export default CreateOnlineMapLayerForm
