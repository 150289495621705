import { useParams } from 'react-router-dom'

// Leaflet
import {
	TileLayer as ReactLeafletTileLayer,
	// useMapEvents,
	useMap,
} from 'react-leaflet'
import CustomTileLayer from './CustomTileLayer'

// Redux
import { skipToken } from '@reduxjs/toolkit/query/react'
import { useAppSelector } from '@Store/index'
import { selectUserMapLayerId } from '@Store/user/userSlice'
import {
	useGetSiteMapLayersQuery,
	selectUserMapLayer,
	selectFallbackMapLayer,
	useGetMapLayersQuery,
} from '@Store/mapLayers/mapLayersApi'

import errorTileImg from '@Assets/no_map_tile.png'

const MapTileLayer = () => {
	const { siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)
	const map = useMap()

	const userMapLayerId = useAppSelector(selectUserMapLayerId)

	const { userMapLayer } = useGetSiteMapLayersQuery(
		siteId > 0 ? siteId : skipToken,
		{
			selectFromResult: ({ isError, isSuccess, data }) => ({
				isError,
				isSuccess,
				userMapLayer: isSuccess
					? selectUserMapLayer(data, userMapLayerId)
					: null,
			}),
		}
	)

	const { fallBackMapLayer } = useGetMapLayersQuery(undefined, {
		selectFromResult: ({ data, isSuccess }) => ({
			fallBackMapLayer: isSuccess ? selectFallbackMapLayer(data) : null,
		}),
	})

	if (userMapLayer && userMapLayer?.tileLayerUrl) {
		return (
			<CustomTileLayer
				attribution={userMapLayer.attribution}
				url={userMapLayer.tileLayerUrl}
				mapRef={map}
				fallbackTileUrl={fallBackMapLayer?.tileLayerUrl}
				fallbackTileAttribution={fallBackMapLayer?.attribution}
				errorTileUrl={errorTileImg}
				minZoom={userMapLayer.min_zoom ?? 3}
				maxZoom={userMapLayer.max_zoom}
			/>
		)
	} else if (fallBackMapLayer?.tileLayerUrl) {
		return (
			<CustomTileLayer
				attribution={fallBackMapLayer.attribution}
				url={fallBackMapLayer.tileLayerUrl}
				mapRef={map}
				errorTileUrl={errorTileImg}
				minZoom={fallBackMapLayer.min_zoom ?? 3}
				maxZoom={fallBackMapLayer.max_zoom}
			/>
		)
	} else {
		return <ReactLeafletTileLayer url='' errorTileUrl={errorTileImg} />
	}
}

export default MapTileLayer
