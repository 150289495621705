import type { SiteMode } from '@Store/types'

export const SITE_MODES = {
	OPERATIONAL: 'operational' as SiteMode,
	CALIBRATION: 'calibration' as SiteMode,
	MONITOR: 'monitor' as SiteMode,
}

export const smarthubTimeoutMs = 11000
export const disconnectionTimeoutMs = 2000

export const TEST_DETECTIONS_ENABLED_SITES = [
	'Radar+RF',
	'Radar',
	'RF',
	'RF Intersection',
]

export const TEST_DETECTIONS_HEARTBEAT_INTERVAL = 5000

export const DEFAULT_SMARTHUB_IP = '10.10.15.10'
