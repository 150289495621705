import { useEffect, type ReactNode } from 'react'

// Redux
import { useAppSelector } from '@Store/index'
import { selectUserLanguage } from '@Store/user/userSlice'

// Translations
import i18n from 'i18next'
import { I18nextProvider, initReactI18next } from 'react-i18next'
import { i18nresources } from '../../i18n'

i18n.use(initReactI18next)

type I18nProviderProps = {
	children: ReactNode
}

const I18nProvider = ({ children }: I18nProviderProps) => {
	const userLanguage = useAppSelector(selectUserLanguage)

	if (!i18n.isInitialized && !i18n.isInitializing) {
		i18n.init({
			debug: false, // Set to true if necessary, will be in console
			lng: userLanguage,
			fallbackLng: 'en',
			resources: i18nresources,
			interpolation: {
				escapeValue: false, // react already safe from xss
			},
		})
	}

	useEffect(() => {
		if (i18n.language !== userLanguage) {
			i18n.changeLanguage(userLanguage)
		}
	}, [userLanguage])

	return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
}

export default I18nProvider
export { i18n }
