import { baseApi } from '@Store/baseApi'

import type { Site, SiteMarker } from '../types'
import type { MapMarkerSchema } from '@Forms/MapsZonesForms/MapMarkersForm/MapMarkersForm.schema'
import { createSelector } from '@reduxjs/toolkit'

type GetSiteMarkerRequest = { siteId: Site['id'] }
type CreateSiteMarkerRequest = MapMarkerSchema & { siteId: Site['id'] }
type UpdateSiteMarkerRequest = MapMarkerSchema & {
	siteId: Site['id']
	markerId: SiteMarker['id']
}
type DeleteSiteMarkerRequest = {
	siteId: Site['id']
	markerId: SiteMarker['id']
}

export const siteMarkersApi = baseApi
	.enhanceEndpoints({ addTagTypes: ['SiteMarkers'] })
	.injectEndpoints({
		endpoints: (builder) => ({
			getSiteMarkers: builder.query<SiteMarker[], GetSiteMarkerRequest>({
				query: ({ siteId }) => ({
					url: `/api/sites/${siteId}/site_markers`,
				}),
				providesTags: ['SiteMarkers'],
				transformResponse: (response: { site_markers: SiteMarker[] }) =>
					response.site_markers,
			}),
			createSiteMarker: builder.mutation<SiteMarker, CreateSiteMarkerRequest>({
				query: ({ siteId, ...marker }) => ({
					url: `/api/sites/${siteId}/site_markers`,
					method: 'POST',
					body: {
						site_marker: { ...marker },
					},
				}),
				invalidatesTags: ['SiteMarkers'],
			}),
			updateSiteMarker: builder.mutation<SiteMarker, UpdateSiteMarkerRequest>({
				query: ({ siteId, markerId, ...marker }) => ({
					url: `/api/sites/${siteId}/site_markers/${markerId}`,
					method: 'PUT',
					body: {
						site_marker: { ...marker },
					},
				}),
				invalidatesTags: ['SiteMarkers'],
			}),
			deleteSiteMarker: builder.mutation<SiteMarker, DeleteSiteMarkerRequest>({
				query: ({ siteId, markerId }) => ({
					url: `/api/sites/${siteId}/site_markers/${markerId}`,
					method: 'DELETE',
				}),
				invalidatesTags: ['SiteMarkers'],
			}),
		}),
	})

export const selectSiteMarker = createSelector(
	[(data?: SiteMarker[]) => data, (siteMarkers, markerId?: number) => markerId],
	(siteMarkers, markerId) =>
		siteMarkers?.find((siteMarker) => siteMarker.id === markerId) ??
		siteMarkers?.[0]
)

export const selectNewSiteMarkerNumber = createSelector(
	[(data?: SiteMarker[]) => data],
	(siteMarkers) =>
		// Add new marker's number (the greatest marker number + 1)
		Math.max(...(siteMarkers?.map((marker) => Number(marker.number)) || [0])) +
		1
)

export const {
	useGetSiteMarkersQuery,
	useCreateSiteMarkerMutation,
	useUpdateSiteMarkerMutation,
	useDeleteSiteMarkerMutation,
} = siteMarkersApi
