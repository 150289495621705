import type { DetectionMarkerIconProps } from '.'

type DroneMarkerIconProps = {
	color: DetectionMarkerIconProps['color']
}

const DroneMarkerIcon = ({ color }: DroneMarkerIconProps) => {
	return (
		<svg
			width='40px'
			height='40px'
			viewBox='0 0 40 40'
			version='1.1'
			xmlns='http://www.w3.org/2000/svg'
			xmlnsXlink='http://www.w3.org/1999/xlink'
		>
			<g
				transform='translate(1.800000, 2.600000)'
				data-testid={`drone-icon-${color}`}
				fill={color}
			>
				<path d='M7.47475437,4.97465437 C10.3839244,10.0655444 14.7820244,13.5201444 18.3383244,13.5201444 L18.3383244,17.8838444 L12.5201244,17.8838444 C12.5201244,15.2627444 9.97472437,9.97465437 4.97475437,7.47465437 C4.97475437,7.47465437 5.88896437,7.06044437 6.47475437,6.47465437 C7.06054437,5.88886437 7.47475437,4.97465437 7.47475437,4.97465437 Z' />
				<path d='M5.68520437,5.46330437 C3.53731437,7.61118437 0.853614366,8.63174437 0.185104366,7.96327437 C-0.483405634,7.29476437 0.715854366,5.01162437 2.86374437,2.86374437 C5.01162437,0.715854366 7.29476437,-0.483405634 7.96327437,0.185104366 C8.63182437,0.853614366 7.83308437,3.31541437 5.68520437,5.46330437 Z' />
				<path d='M28.8381244,4.97465437 C25.9289244,10.0655444 21.5308244,13.5201444 17.9745244,13.5201444 L17.9745244,17.8838444 L23.7927244,17.8838444 C23.7927244,15.2627444 26.3381244,9.97465437 31.3381244,7.47465437 C31.3381244,7.47465437 30.4239244,7.06044437 29.8381244,6.47465437 C29.2523244,5.88886437 28.8381244,4.97465437 28.8381244,4.97465437 Z' />
				<path d='M30.6276244,5.46330437 C32.7755244,7.61118437 35.4592244,8.63174437 36.1277244,7.96327437 C36.7962244,7.29476437 35.5970244,5.01162437 33.4491244,2.86374437 C31.3012244,0.715854366 29.0181244,-0.483405634 28.3495244,0.185104366 C27.6810244,0.853614366 28.4797244,3.31541437 30.6276244,5.46330437 Z' />
				<path d='M7.47475437,29.8838444 C10.3839244,24.7929444 14.7820244,21.3383444 18.3383244,21.3383444 L18.3383244,16.9746444 L12.5201244,16.9746444 C12.5201244,19.5957444 9.97472437,24.8838444 4.97475437,27.3838444 C4.97475437,27.3838444 5.88896437,27.7980444 6.47475437,28.3838444 C7.06054437,28.9696444 7.47475437,29.8838444 7.47475437,29.8838444 Z' />
				<path d='M5.68520437,29.3951444 C3.53731437,27.2473444 0.853614366,26.2267444 0.185104366,26.8952444 C-0.483405634,27.5637444 0.715854366,29.8468444 2.86374437,31.9947444 C5.01162437,34.1426444 7.29476437,35.3419444 7.96327437,34.6733444 C8.63182437,34.0048444 7.83308437,31.5430444 5.68520437,29.3951444 Z' />
				<path d='M28.8381244,29.8838444 C25.9289244,24.7929444 21.5308244,21.3383444 17.9745244,21.3383444 L17.9745244,16.9746444 L23.7927244,16.9746444 C23.7927244,19.5957444 26.3381244,24.8838444 31.3381244,27.3838444 C31.3381244,27.3838444 30.4239244,27.7980444 29.8381244,28.3838444 C29.2523244,28.9696444 28.8381244,29.8838444 28.8381244,29.8838444 Z' />
				<path d='M30.6276244,29.3951444 C32.7755244,27.2473444 35.4592244,26.2267444 36.1277244,26.8952444 C36.7962244,27.5637444 35.5970244,29.8468444 33.4491244,31.9947444 C31.3012244,34.1426444 29.0181244,35.3419444 28.3495244,34.6733444 C27.6810244,34.0048444 28.4797244,31.5430444 30.6276244,29.3951444 Z' />
			</g>
		</svg>
	)
}

export default DroneMarkerIcon
