import { baseApi } from '@Store/baseApi'
import { type CoreApiError, formatApiErrors } from '@Store/utils/errorUtils'

import type { Camera, Site } from '../types'
import type { CameraSchema } from '@Components/Forms/CameraForm/CameraForm.schema'

type GetCamerasRequest = Site['id']
type GetCameraRequest = { siteId: Site['id']; cameraId: Camera['id'] }
type CreateCameraRequest = CameraSchema & { siteId: Site['id'] }
type UpdateCameraRequest = (Camera | CameraSchema) & {
	id: Camera['id']
	siteId: Site['id']
}
type DeleteCameraRequest = { siteId: number } & { sensorId: Camera['id'] }
type MoveCameraRequest = {
	cameraId: Camera['id']
	siteId: Site['id']
	x?: number
	y?: number
	z?: number
	timeout?: number // seconds
}
type MoveCameraStopRequest = { cameraId: Camera['id']; siteId: Site['id'] }
type MoveCameraHomeRequest = { cameraId: Camera['id']; siteId: Site['id'] }
type StartCameraRecordingRequest = {
	cameraId: Camera['id']
	siteId: Site['id']
}
type StopCameraRecordingRequest = { cameraId: Camera['id']; siteId: Site['id'] }
type TriggerCameraWiperRequest = { cameraId: Camera['id']; siteId: Site['id'] }
type StartCameraScanningRequest = { cameraId: Camera['id']; siteId: Site['id'] }
type StopCameraScanningRequest = { cameraId: Camera['id']; siteId: Site['id'] }
type TrackCameraDetectionRequest = {
	cameraId: Camera['id']
	siteId: Site['id']
	targetId: Camera['tracked_target_id'] | null
}
type ToggleInfraredRequest = { cameraId: Camera['id']; siteId: Site['id'] }
type CalibrateCameraRequest = {
	cameraId: Camera['id']
	siteId: Site['id']
	action: 'start' | 'start-2' | 'start-3' | 'start-4' | 'stop'
}
type CalibrateCameraBracketRequest = {
	cameraId: Camera['id']
	siteId: Site['id']
	action: 'start' | 'stop'
}

const transformCameraResponse = (response: { camera: Camera }) => ({
	...response.camera,
	// C2-7828 for Camera Form
	droneoptid_prob_threshold: response.camera.droneoptid_prob_threshold * 100,
	tracking_slew_hfov_percent: response.camera.tracking_slew_hfov_percent * 100,
	// Clear password for Camera Form
	password: null,
})

export const camerasApi = baseApi
	.enhanceEndpoints({ addTagTypes: ['Cameras'] })
	.injectEndpoints({
		endpoints: (builder) => ({
			getCamerasList: builder.query<
				Array<Pick<Camera, 'id' | 'name'>>,
				GetCamerasRequest
			>({
				query: (siteId) => ({
					url: `/api/sites/${siteId}/cameras`,
				}),
				transformResponse: (response: { cameras: Camera[] | null }) =>
					response.cameras ??
					[]
						.map((camera: Camera) => ({
							id: camera.id,
							name: camera.name,
						}))
						.sort((a, b) => a.id - b.id),
			}),
			getCamera: builder.query<Camera, GetCameraRequest>({
				query: ({ siteId, cameraId }) => ({
					url: `/api/sites/${siteId}/cameras/${cameraId}`,
				}),
				providesTags: ['Cameras'],
				transformResponse: transformCameraResponse,
				keepUnusedDataFor: 0,
			}),
			getCameras: builder.query<Array<Camera>, GetCamerasRequest>({
				query: (siteId) => ({
					url: `/api/sites/${siteId}/cameras`,
				}),
				transformResponse: (response: { cameras: Camera[] | null }) =>
					response.cameras ?? [],
			}),
			updateCamera: builder.mutation<Camera, UpdateCameraRequest>({
				query: ({ siteId, id: cameraId, ...patch }) => ({
					url: `/api/sites/${siteId}/cameras/${cameraId}`,
					method: 'PATCH',
					body: {
						camera: {
							...patch,
							edit_type: 'form',
							// C2-7828
							droneoptid_prob_threshold: patch.droneoptid_prob_threshold / 100,
							tracking_slew_hfov_percent:
								patch.tracking_slew_hfov_percent / 100,
						},
					},
				}),
				transformResponse: transformCameraResponse,
				transformErrorResponse: (response: CoreApiError) =>
					formatApiErrors<Camera>(response),
				invalidatesTags: ['Cameras'],
			}),
			createCamera: builder.mutation<Camera, CreateCameraRequest>({
				query: ({ siteId, ...data }) => ({
					url: `/api/sites/${siteId}/cameras`,
					method: 'POST',
					body: {
						camera: {
							...data,
							edit_type: 'form',
							// C2-7828
							droneoptid_prob_threshold: data.droneoptid_prob_threshold / 100,
							tracking_slew_hfov_percent: data.tracking_slew_hfov_percent / 100,
						},
					},
				}),
				transformResponse: transformCameraResponse,
				transformErrorResponse: (response: CoreApiError) =>
					formatApiErrors<Camera>(response),
				invalidatesTags: ['Cameras'],
			}),
			deleteCamera: builder.mutation<{ message: string }, DeleteCameraRequest>({
				query: ({ siteId, sensorId }) => ({
					url: `/api/sites/${siteId}/cameras/${sensorId}`,
					method: 'DELETE',
				}),
				invalidatesTags: ['Cameras'],
			}),
			moveCamera: builder.mutation<Camera, MoveCameraRequest>({
				query: ({ siteId, cameraId, x = 0, y = 0, z = 0, timeout = 15 }) => ({
					url: `/api/sites/${siteId}/cameras/${cameraId}`,
					method: 'PATCH',
					body: {
						camera: {
							control: `{"command": "continuousMove", "velocity": {"x": ${x}, "y": ${y}, "z": ${z}}, "timeout": ${timeout}}`,
							edit_type: 'control',
						},
					},
				}),
				transformResponse: transformCameraResponse,
			}),
			moveCameraStop: builder.mutation<Camera, MoveCameraStopRequest>({
				query: ({ siteId, cameraId }) => ({
					url: `/api/sites/${siteId}/cameras/${cameraId}`,
					method: 'PATCH',
					body: {
						camera: {
							control: '{"command": "stopMove", "pantilt": true, "zoom": true}',
							edit_type: 'control',
						},
					},
				}),
				transformResponse: transformCameraResponse,
			}),
			moveCameraHome: builder.mutation<Camera, MoveCameraHomeRequest>({
				query: ({ siteId, cameraId }) => ({
					url: `/api/sites/${siteId}/cameras/${cameraId}`,
					method: 'PATCH',
					body: {
						camera: {
							control:
								'{"command": "absoluteMove", "position": { "x": 0, "y": 0, "z": 0}}',
							edit_type: 'control',
						},
					},
				}),
				transformResponse: transformCameraResponse,
			}),
			startRecording: builder.mutation<Camera, StartCameraRecordingRequest>({
				query: ({ siteId, cameraId }) => ({
					url: `/api/sites/${siteId}/cameras/${cameraId}`,
					method: 'PATCH',
					body: {
						camera: {
							control: '{"command": "startRecording"}',
							edit_type: 'control',
						},
					},
				}),
				transformResponse: transformCameraResponse,
			}),
			stopRecording: builder.mutation<Camera, StopCameraRecordingRequest>({
				query: ({ siteId, cameraId }) => ({
					url: `/api/sites/${siteId}/cameras/${cameraId}`,
					method: 'PATCH',
					body: {
						camera: {
							control: '{"command":"stopRecording"}',
							edit_type: 'control',
						},
					},
				}),
				transformResponse: transformCameraResponse,
			}),
			triggerWiper: builder.mutation<Camera, TriggerCameraWiperRequest>({
				query: ({ siteId, cameraId }) => ({
					url: `/api/sites/${siteId}/cameras/${cameraId}`,
					method: 'PATCH',
					body: {
						camera: {
							control: '{"command":"triggerWiper"}',
							edit_type: 'control',
						},
					},
				}),
				transformResponse: transformCameraResponse,
			}),
			startScanning: builder.mutation<Camera, StartCameraScanningRequest>({
				query: ({ siteId, cameraId }) => ({
					url: `/api/sites/${siteId}/cameras/${cameraId}`,
					method: 'PATCH',
					body: {
						camera: {
							control: '{"command": "startScanning"}',
							edit_type: 'control',
						},
					},
				}),
				transformResponse: transformCameraResponse,
			}),
			stopScanning: builder.mutation<Camera, StopCameraScanningRequest>({
				query: ({ siteId, cameraId }) => ({
					url: `/api/sites/${siteId}/cameras/${cameraId}`,
					method: 'PATCH',
					body: {
						camera: {
							control: '{"command":"stopScanning"}',
							edit_type: 'control',
						},
					},
				}),
				transformResponse: transformCameraResponse,
			}),
			trackDetection: builder.mutation<Camera, TrackCameraDetectionRequest>({
				query: ({ siteId, cameraId, targetId }) => ({
					url: `/api/sites/${siteId}/cameras/${cameraId}`,
					method: 'PATCH',
					body: {
						camera: {
							tracked_target_id: targetId,
							radar_tracked_target: null,
							rf_tracked_target: null,
							edit_type: 'track',
						},
					},
				}),
				transformResponse: transformCameraResponse,
			}),
			toggleInfrared: builder.mutation<Camera, ToggleInfraredRequest>({
				query: ({ siteId, cameraId }) => ({
					url: `/api/sites/${siteId}/cameras/${cameraId}`,
					method: 'PATCH',
					body: {
						camera: {
							control: '{"command":"toggleStream"}',
							edit_type: 'control',
						},
					},
				}),
				transformResponse: transformCameraResponse,
			}),
			calibrateCamera: builder.mutation<Camera, CalibrateCameraRequest>({
				query: ({ siteId, cameraId, action }) => ({
					url: `/api/sites/${siteId}/cameras/${cameraId}`,
					method: 'PATCH',
					body: {
						camera: {
							control: `{"command":"calibrateCamera","action":"${action}"}`,
							edit_type: 'control',
						},
					},
				}),
				transformResponse: transformCameraResponse,
			}),
			calibrateCameraBracket: builder.mutation<
				Camera,
				CalibrateCameraBracketRequest
			>({
				query: ({ siteId, cameraId, action }) => ({
					url: `/api/sites/${siteId}/cameras/${cameraId}`,
					method: 'PATCH',
					body: {
						camera: {
							control: `{"command":"calibrateCameraBracket","action":"${action}"}`,
							edit_type: 'control',
						},
					},
				}),
				transformResponse: transformCameraResponse,
			}),
		}),
	})

export const {
	useGetCamerasListQuery,
	useGetCameraQuery,
	useGetCamerasQuery,
	useCreateCameraMutation,
	useUpdateCameraMutation,
	useDeleteCameraMutation,
	useTriggerWiperMutation,
	useStartRecordingMutation,
	useStopRecordingMutation,
	useMoveCameraMutation,
	useMoveCameraStopMutation,
	useMoveCameraHomeMutation,
	useStartScanningMutation,
	useStopScanningMutation,
	useTrackDetectionMutation,
	useToggleInfraredMutation,
	useCalibrateCameraMutation,
	useCalibrateCameraBracketMutation,
} = camerasApi
