import { z } from 'zod'
import globalSchema from '@/components/Forms/BaseForm/BaseForm.schema'

const pickedFromGlobal = globalSchema.pick({
	name: true,
})

const disruptorFormFields = z.object({
	sentry_id: z.number(),
	direction_offset: globalSchema.shape.direction_offset,
	name: z.string(),
	reach: z.number().min(100).max(5000),
	serial_number: z.string(),
	gnss_trigger_engaged: z.boolean(),
	band_24_58_trigger_engaged: z.boolean(),
	band_433_trigger_engaged: z.boolean(),
	band_915_trigger_engaged: z.boolean(),
	shutoff_time: z.number().max(600),
})

export const disruptorFormSchema = disruptorFormFields.merge(pickedFromGlobal)

const defaultValuesSchema = disruptorFormSchema.omit({
	name: true,
	sentry_id: true,
	serial_number: true,
})
type DefaultValues = z.infer<typeof defaultValuesSchema>

export const ADD_DISRUPTOR_DEFAULT_VALUES: DefaultValues = {
	direction_offset: 0,
	reach: 2000,
	shutoff_time: 300,
	gnss_trigger_engaged: false,
	band_24_58_trigger_engaged: false,
	band_433_trigger_engaged: false,
	band_915_trigger_engaged: false,
}
