import { useTranslation } from 'react-i18next'
import { MdFlare as OpticalScanIcon } from 'react-icons/md'

import {
	useGetSiteLiveQuery,
	selectCurrentCamera,
	selectIsCameraDisabled,
} from '@Store/sites/sitesWsApi'

import {
	useStartScanningMutation,
	useStopScanningMutation,
} from '@Store/cameras/camerasApi'

import QuickActionsButton from '../QuickActionsButton/QuickActionsButton'

const OpticalScanButton = ({ siteId }: { siteId: number }) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'quickActions.cameraActions',
	})

	const { currentCamera, isDisabled } = useGetSiteLiveQuery(siteId, {
		selectFromResult: ({ data }) => ({
			currentCamera: selectCurrentCamera(data),
			isDisabled: selectIsCameraDisabled(data),
		}),
	})

	const [startScanning] = useStartScanningMutation()
	const [stopScanning] = useStopScanningMutation()

	const isScanning = currentCamera?.scanning

	const toggleOpticalScan = () => {
		if (isScanning) {
			stopScanning({ siteId, cameraId: currentCamera.id })
		} else {
			startScanning({ siteId, cameraId: currentCamera.id })
		}
	}

	return (
		<QuickActionsButton
			icon={OpticalScanIcon}
			label={t('opticalScan')}
			onClick={toggleOpticalScan}
			isActive={isScanning}
			isDisabled={isDisabled}
			testId='quick-action-optical-scan'
		/>
	)
}

export default OpticalScanButton
