import { Center, Button, Icon, Text } from '@chakra-ui/react'
import {
	MdTripOrigin as StartDisruptIcon,
	MdReport as StopDisruptIcon,
} from 'react-icons/md'

import Tooltip from '@UI/Tooltip/Tooltip'

type DisruptorButtonProps = {
	name: string
	onClick: () => void
	isActive: boolean
	isDisabled?: boolean
	isError?: boolean
	testId?: string
	tooltip?: string
	isLoading?: boolean
}

const style = {
	w: 'calc(25% - 6px)',
	h: '76px',
	whiteSpace: 'normal',
	wordWrap: 'break-word',
}

const DisruptorButton = ({
	name,
	onClick,
	isActive,
	isDisabled,
	testId,
	tooltip,
	isError = false,
	isLoading = false,
}: DisruptorButtonProps) => {
	return (
		<Tooltip label={tooltip} placement='bottom'>
			<Button
				variant='quickActions'
				sx={style}
				onClick={onClick}
				isDisabled={isDisabled}
				isActive={isActive}
				data-testid={testId}
				border={`${isError ? '1px solid' : 'none'}`}
				borderColor={`${isError ? 'error' : ''}`}
				isLoading={isLoading && !isDisabled}
			>
				<Center flexDirection='column' gap='18px'>
					<Icon
						as={isActive ? StopDisruptIcon : StartDisruptIcon}
						boxSize='20px'
						color={isDisabled ? 'disabled' : 'success'}
					/>
					<Text fontSize='10px' noOfLines={2} maxW='60px'>
						{name}
					</Text>
				</Center>
			</Button>
		</Tooltip>
	)
}

export default DisruptorButton
