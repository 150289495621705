// React
import { useTranslation } from 'react-i18next'
// Chakra
import {
	Button,
	IconButton,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useDisclosure,
} from '@chakra-ui/react'
import { MdDelete } from 'react-icons/md'
// Components
import EditButton from '@Components/Site/Panels/SiteInstallations/EditButton'
// Store
import { useDeleteMapLayerMutation } from '@Store/mapLayers/mapLayersApi'
import type { MapLayer } from '@Store/types/mapLayers'

const DeleteMapLayerModal = ({
	layer,
	variant,
}: {
	layer: MapLayer
	variant: 'icon' | 'button'
}) => {
	const { id, name } = layer
	const { isOpen, onOpen, onClose } = useDisclosure()
	// Translations
	const { t } = useTranslation('forms', {
		keyPrefix: 'mapsZonesForm.mapLayersForm',
	})

	const [deleteLayer] = useDeleteMapLayerMutation()

	const handleDeleteLayer = async (id: number) => {
		try {
			await deleteLayer({ id })
			onClose()
		} catch (error) {
			console.error('unhandled layer selector delete error', error)
		}
	}

	return (
		<>
			{variant === 'icon' ? (
				<IconButton
					variant='ghost'
					colorScheme='white'
					aria-label='delete'
					icon={<MdDelete />}
					onClick={onOpen}
				/>
			) : (
				<EditButton
					onClick={onOpen}
					label={t('buttons.deleteLayer')}
					type='delete'
					testId='delete-map-layer'
				/>
			)}
			<Modal isOpen={isOpen} onClose={onClose} isCentered>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{t('deleteMapLayer')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						{t('deleteNotificationText')}:
						<Text fontWeight='500' as='span' px={1}>
							{name}
						</Text>
						?
					</ModalBody>
					<ModalFooter>
						<Button onClick={() => handleDeleteLayer(id)}>
							{t('buttons.delete')}
						</Button>
						<Button mr={3} onClick={onClose} variant='ghost'>
							{t('buttons.cancel')}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}

export default DeleteMapLayerModal
