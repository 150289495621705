// React
import { useEffect, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
// Chakra
import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	SliderFilledTrack,
	SliderThumb,
	SliderTrack,
	Grid,
	Box,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
	Slider,
} from '@chakra-ui/react'
// Components
import Tooltip from '@UI/Tooltip/Tooltip'
import SliderCustomLabel from './SliderCustomLabel'
// Types
import type { SliderProps } from '@chakra-ui/slider/dist/slider'
import type { RegisterType } from '@/components/FormElements/types/RegisterType'
import type { FormElementProps } from '@Components/FormElements/types/FormElementProps'
import type { Units } from '@Components/FormElements/types/Units'
// Utils
import { formatDegToMils, formatMilsToDeg } from '@Utils/formatUtils'
// Hooks
import useUnits from '@Hooks/useUnits'

export type FormSliderProps = FormElementProps & {
	defaultValue?: number
	min: number
	max: number
	step?: number
	inputWidth?: number
	units: Units
	isAbsolute?: boolean
	customLabel?: string | number
	showStepper?: boolean
} & SliderProps &
	RegisterType<string>

const UnitsSlider = ({
	id,
	title,
	error,
	register,
	min,
	max,
	step = 1,
	disabled = false,
	inputWidth = undefined,
	testId,
	tooltip,
	units,
	customLabel,
	isAbsolute = false,
	showStepper = false,
}: FormSliderProps) => {
	const { control, watch } = useFormContext()

	const htmlId = id ?? register?.name ?? 'slider'

	const { unit, isMils } = useUnits(units)

	const inputMax = useMemo(() => {
		return isMils ? Number(formatDegToMils(max)) : max
	}, [isMils, max])
	const inputMin = useMemo(() => {
		return isMils ? Number(formatDegToMils(min)) : min
	}, [isMils, min])

	const defaultValue = useMemo(() => {
		const observableValue = register?.name && watch(register?.name)
		return isMils && observableValue
			? formatDegToMils(observableValue)
			: observableValue
	}, [register, isMils, watch])

	const [inputValue, setInputValue] = useState<number>(
		Number(Number(defaultValue)?.toFixed(2))
	)

	const calculatedInputWidth = useMemo(() => {
		if (inputWidth) return `${inputWidth}ch`
		else if (String(inputMax).length + 3 >= 5)
			return `${String(inputMax).length + 5}ch`
		else if (isMils) return showStepper ? '11ch' : '9ch'
		else return showStepper ? '9ch' : '7ch'
	}, [inputWidth, isMils, showStepper, inputMax])

	const handleChange = (
		changedValue: string | number,
		onChange: (...event: number[]) => void
	) => {
		const value = Number(changedValue)
		if (isMils) {
			const milValue = Number(formatMilsToDeg(value))
			if (milValue >= min && milValue <= max) {
				onChange(milValue)
				setInputValue(Number(value.toFixed(2)))
			}
		} else {
			if (value >= min && value <= max) {
				onChange(value)
				setInputValue(Number(value.toFixed(2)))
			}
		}
	}

	// Force recalculating default value when mils has been changed
	useEffect(() => {
		if (defaultValue) {
			setInputValue(Number(Number(defaultValue)?.toFixed(2)))
		}
	}, [isMils, defaultValue])

	return (
		<Tooltip label={tooltip} type='info'>
			<FormControl isInvalid={!!error} isDisabled={disabled} userSelect='none'>
				<Controller
					control={control}
					name={htmlId}
					render={({ field: { onChange } }) => {
						return (
							<Box position='relative'>
								<Box position='relative' right={tooltip ? 8 : undefined}>
									<SliderCustomLabel
										customLabel={customLabel}
										isAbsolute={isAbsolute}
										units={units}
									/>
								</Box>
								<FormLabel flex='1 0 auto'>
									{title} {!!unit && `(${unit})`}
								</FormLabel>
								<Grid templateColumns={`${calculatedInputWidth} 1fr`} gap={2}>
									<NumberInput
										value={inputValue}
										onChange={(value) => handleChange(value, onChange)}
										precision={2}
										step={step}
										min={inputMin}
										max={inputMax}
										w={calculatedInputWidth}
									>
										<NumberInputField
											p={2}
											textAlign='center'
											data-testid={testId}
										/>
										{showStepper && (
											<NumberInputStepper>
												<NumberIncrementStepper />
												<NumberDecrementStepper />
											</NumberInputStepper>
										)}
									</NumberInput>
									<Slider
										focusThumbOnChange={false}
										value={Number(inputValue)}
										onChange={(value) => handleChange(value, onChange)}
										step={step}
										min={inputMin}
										max={inputMax}
									>
										<SliderTrack>
											<SliderFilledTrack />
										</SliderTrack>
										<SliderThumb />
									</Slider>
								</Grid>
							</Box>
						)
					}}
				></Controller>
				<FormErrorMessage>{error}</FormErrorMessage>
			</FormControl>
		</Tooltip>
	)
}

export default UnitsSlider
