import { useState, useCallback } from 'react'
import { isProduction } from '@Constants/api'

type StatusObject = Record<string, boolean>

export const useTestIp = (initialStatus: StatusObject = {}) => {
	const [status, setStatus] = useState<StatusObject>(initialStatus)

	const testIp = useCallback(async (ip: string, key: string) => {
		try {
			await fetch(`http://${ip}`, {
				mode: 'no-cors',
				signal: AbortSignal.timeout(5000),
			})
			setStatus((prev) => ({ ...prev, [key]: true }))
		} catch (err) {
			setStatus((prev) => ({ ...prev, [key]: false }))
		}
	}, [])

	return { status, testIp }
}

export const useIpValidation = () => {
	const [isLoading, setIsLoading] = useState(false)

	const validateIps = async (ips: string[]) => {
		setIsLoading(true)
		for (const ip of ips) {
			try {
				await fetch(`http://${ip}`, {
					mode: 'no-cors',
					signal: AbortSignal.timeout(3000),
				})
				setIsLoading(false)
				return ip
			} catch (error) {
				!isProduction && console.error(`Invalid IP: ${ip}:`, error)
			}
		}
		setIsLoading(false)
		return null
	}

	return { validateIps, isLoading }
}

export default useIpValidation
