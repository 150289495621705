export const capitalize = (string: string | undefined) =>
	string && string.charAt(0).toUpperCase() + string.slice(1)

export const titleCase = (title: string) => {
	return title
		.trim()
		.split(' ')
		.map((word) => capitalize(word))
		.join(' ')
}
