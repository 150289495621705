import { memo, useEffect, useMemo } from 'react'
import MapMarker from '@Components/Markers/MapMarker/MapMarker'
import { useAppDispatch, useAppSelector } from '@/store'
import { selectSiteMarkerData, setSiteMarkerData } from '@Store/ui/uiSlice'
import type { LatLngLiteral } from 'leaflet'
import useValidCoordinates from '@Components/Site/MapLayers/PreviewModes/useValidCoordinates'

const SiteMarkersPreview = () => {
	const dispatch = useAppDispatch()
	const siteMarker = useAppSelector(selectSiteMarkerData)

	const latitude = useMemo(() => siteMarker?.lat, [siteMarker])
	const longitude = useMemo(() => siteMarker?.lng, [siteMarker])

	const coordinates = useValidCoordinates(latitude, longitude)

	const handleDragEnd = (coords: LatLngLiteral) => {
		dispatch(setSiteMarkerData({ ...coords }))
	}

	// Clean-up store after unmount
	useEffect(
		() => () => {
			dispatch(setSiteMarkerData(null))
		},
		[dispatch]
	)

	if (!siteMarker) return null
	const { number, nickname, colour } = siteMarker
	const validLat = Number(coordinates[0])
	const validLng = Number(coordinates[1])

	return (
		<MapMarker
			latitude={validLat}
			longitude={validLng}
			onDragEnd={handleDragEnd}
			number={+number}
			nickname={nickname}
			color={colour}
			draggable
		/>
	)
}

export default memo(SiteMarkersPreview)
