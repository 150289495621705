// React
import { useTranslation } from 'react-i18next'
// Chakra
import { Box, Flex } from '@chakra-ui/react'
import { useFormContext, useFormState } from 'react-hook-form'
// Components
import Field from '@Components/FormElements'
import AlertHelper from '@UI/AlertHelper/AlertHelper'

import type { ProfileSchema } from '@Forms/ProfileForm/ProfileForm.schema'

const ProfileFormPassword = () => {
	const { t } = useTranslation(['forms'])
	const { register } = useFormContext()
	const { errors } = useFormState<ProfileSchema>()
	return (
		<>
			<Field.PasswordInput
				title={t('forms:profileForm.api.password')}
				register={register('password')}
				error={errors?.password?.message}
				testId='password'
				autoComplete='new-password'
				isRequired={true}
				tooltip={
					<Flex direction='column'>
						<Box>{t('forms:global.validation.min8char')}</Box>
						<Box>{t('forms:global.validation.min1lowercase')}</Box>
						<Box>{t('forms:global.validation.min1uppercase')}</Box>
						<Box>{t('forms:global.validation.min1numeric')}</Box>
						<Box>{t('forms:global.validation.min1special')}</Box>
						<Box>{t('forms:global.validation.max128char')}</Box>
					</Flex>
				}
			/>
			<Field.PasswordInput
				title={t('forms:profileForm.api.password_confirmation')}
				register={register('password_confirmation')}
				error={errors?.password_confirmation?.message}
				testId='password-confirmation'
				autoComplete='new-password'
				isRequired={true}
			/>
			<AlertHelper
				title={t('forms:profileForm.passwordWarning')}
				variant='outer'
			/>
			<Field.PasswordInput
				title={t('forms:profileForm.api.current_password')}
				register={register('current_password')}
				error={errors?.current_password?.message}
				testId='current_password'
				autoComplete='new-password'
				isRequired={true}
				tooltip={t('forms:profileForm.tooltips.current_password')}
			/>
		</>
	)
}

export default ProfileFormPassword
