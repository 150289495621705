import { baseApi } from '@Store/baseApi'
import { type CoreApiError, formatApiErrors } from '@Store/utils/errorUtils'

import type { GpsCompass, Site } from '../types'
import type { GpsCompassSchema } from '@Components/Forms/GpsCompassForm/GpsCompassForm.schema'

type GetGpsCompassRequest = { siteId: Site['id']; sensorId: GpsCompass['id'] }
type CreateGpsCompassRequest = GpsCompassSchema & { siteId: Site['id'] }
type UpdateGpsCompassRequest = GpsCompassSchema & {
	id: GpsCompass['id']
	siteId: Site['id']
}
type DeleteGpsCompassRequest = {
	siteId: Site['id']
	sensorId: GpsCompass['id']
}

export const gpsCompassesApi = baseApi
	.enhanceEndpoints({
		addTagTypes: ['GpsCompass'],
	})
	.injectEndpoints({
		endpoints: (builder) => ({
			getGpsCompass: builder.query<GpsCompass, GetGpsCompassRequest>({
				query: ({ siteId, sensorId }) => ({
					url: `/api/sites/${siteId}/gps_compasses/${sensorId}`,
				}),
				providesTags: ['GpsCompass'],
				keepUnusedDataFor: 0,
				transformResponse: (response: { gps_compass: GpsCompass }) =>
					response.gps_compass,
			}),
			createGpsCompass: builder.mutation<GpsCompass, CreateGpsCompassRequest>({
				query: ({ siteId, ...gps_compass }) => ({
					url: `/api/sites/${siteId}/gps_compasses`,
					method: 'POST',
					body: {
						gps_compass,
					},
				}),
				transformResponse: (response: { gps_compass: GpsCompass }) =>
					response.gps_compass,
				transformErrorResponse: (response: CoreApiError) =>
					formatApiErrors<GpsCompass>(response),
				invalidatesTags: ['GpsCompass'],
			}),
			updateGpsCompass: builder.mutation<GpsCompass, UpdateGpsCompassRequest>({
				query: ({ id: sensorId, siteId, ...gps_compass }) => ({
					url: `/api/sites/${siteId}/gps_compasses/${sensorId}`,
					method: 'PUT',
					body: {
						gps_compass,
					},
				}),
				transformResponse: (response: { gps_compass: GpsCompass }) =>
					response.gps_compass,
				transformErrorResponse: (response: CoreApiError) =>
					formatApiErrors<GpsCompass>(response),
				invalidatesTags: ['GpsCompass'],
			}),
			deleteGpsCompass: builder.mutation<
				{ message: string },
				DeleteGpsCompassRequest
			>({
				query: ({ siteId, sensorId }) => ({
					url: `/api/sites/${siteId}/gps_compasses/${sensorId}`,
					method: 'DELETE',
				}),
				invalidatesTags: ['GpsCompass'],
			}),
		}),
	})

export const {
	useGetGpsCompassQuery,
	useCreateGpsCompassMutation,
	useUpdateGpsCompassMutation,
	useDeleteGpsCompassMutation,
} = gpsCompassesApi
