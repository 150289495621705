import { useState } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'

// Leaflet
import type { LatLngTuple } from 'leaflet'
import Polygon from '@Components/MapShapes/Polygon/Polygon'

// Turf
import {
	createCirclePolygon,
	createSectorPolygon,
	createIntersectionPolygons,
} from '@Utils/turfUtils'

// Redux
import {
	useGetSiteLiveQuery,
	selectSiteRfSensorsNoDsx,
	selectSiteRfSensorsDsx,
} from '@Store/sites/sitesWsApi'
import { skipToken } from '@reduxjs/toolkit/query'

// Chakra
import { useToken } from '@chakra-ui/react'

import { useShowSectors } from '@Components/Sectors/useShowSectors'

import RfIntersectionPattern, {
	rfIntersectionPatternFill,
} from './RfIntersectionPattern'

// Rf Intersections
const RfIntersections = ({ siteId }: { siteId?: number }) => {
	const { rfSensors, dsxSensors } = useGetSiteLiveQuery(
		siteId ? siteId : skipToken,
		{
			selectFromResult: ({ data }) => ({
				rfSensors: selectSiteRfSensorsNoDsx(data)
					.filter((sensor) => sensor.status_color === 'green')
					.map((sensor) => ({
						latitude: sensor.latitude,
						longitude: sensor.longitude,
						reach: sensor.reach,
						direction: sensor.direction,
					})),
				dsxSensors: selectSiteRfSensorsDsx(data)
					.filter((sensor) => sensor.status_color === 'green')
					.map((sensor) => ({
						latitude: sensor.latitude,
						longitude: sensor.longitude,
						reach: sensor.reach,
					})),
			}),
		}
	)
	const { showRfSensorSector, showDsxSensorSector } = useShowSectors()
	const [intersectionColor] = useToken('colors', ['sectors.rfSensorStroke'])

	const [intersectionPolygons, setIntersectionPolygons] = useState<
		LatLngTuple[][] | null
	>(null)

	// Deep Compare Effect runs only when the nested dependency values change.
	// https://github.com/kentcdodds/use-deep-compare-effect
	// In this instance, it means the intersections are only re-calculated
	// when some sensor's lat/lng, direction, reach value changes
	//
	useDeepCompareEffect(() => {
		// Create list of Polygons to Intersect
		const polygons: LatLngTuple[][] = []

		// Generate RF Sensor Polygons
		if (rfSensors.length > 0) {
			const rfPolygons = rfSensors.map((rfSensor) => {
				return createSectorPolygon(
					[rfSensor.latitude, rfSensor.longitude],
					rfSensor.reach,
					rfSensor.direction - 45,
					rfSensor.direction + 45
				) as LatLngTuple[]
			})
			polygons.push(...rfPolygons)
		}

		// Generate DSX Sensor Polygons
		if (dsxSensors.length > 0) {
			const dsxPolygons = dsxSensors.map((dsxSensor) => {
				return createCirclePolygon(
					[dsxSensor.latitude, dsxSensor.longitude],
					dsxSensor.reach
				) as LatLngTuple[]
			})
			polygons.push(...dsxPolygons)
		}

		// Generate Intersection Polygons
		const intersectionPolygons = createIntersectionPolygons(polygons)
		setIntersectionPolygons(intersectionPolygons)
	}, [rfSensors, dsxSensors])

	// Render Intersections
	if (intersectionPolygons && intersectionPolygons.length > 0) {
		// Remove the stroke if already drawn by the device sectors
		const strokeWeight =
			(rfSensors.length > 0 &&
				showRfSensorSector &&
				dsxSensors.length > 0 &&
				showDsxSensorSector) ||
			(rfSensors.length > 0 && dsxSensors.length === 0 && showRfSensorSector) ||
			(dsxSensors.length > 0 && rfSensors.length === 0 && showDsxSensorSector)
				? 0
				: 2

		return (
			<>
				{intersectionPolygons.map((intersection, index) => (
					<Polygon
						key={index}
						interactive={false}
						positions={intersection}
						fillColor={rfIntersectionPatternFill}
						fillOpacity={1}
						color={intersectionColor}
						weight={strokeWeight}
					/>
				))}
				<RfIntersectionPattern color={intersectionColor} />
			</>
		)
	} else return null
}

export default RfIntersections
