// Utility
import { jwtDecode } from 'jwt-decode'

// Types
import type { JwtPayload } from 'jwt-decode'

interface DSJwtPayload extends JwtPayload {
	user_id: number
}

export const jwtTokenStillValid = (token: string): boolean => {
	const { exp: expiry } = jwtDecode<JwtPayload>(token)
	if (expiry !== undefined && expiry > Math.floor(Date.now() / 1000)) {
		return true
	}
	return false
}

export const jwtTokenUserId = (token: string): number => {
	const { user_id } = jwtDecode<DSJwtPayload>(token)
	return user_id
}
