// React Router
import { Link, useParams, useMatch, useLocation } from 'react-router-dom'
// Chakra
import { Flex, IconButton } from '@chakra-ui/react'
// Icons
import {
	MdSettings,
	MdMap,
	MdNote,
	// MdNetworkCheck,
	MdPieChart,
} from 'react-icons/md'
import ErrorBoundary from '@Components/App/ErrorHandling/ErrorBoundary'
// Store
import {
	useGetSystemInfoQuery,
	selectIsTactical,
} from '@Store/system/systemApi'
import useAuth from '@Hooks/useAuth'

const LeftNavigation = () => {
	const location = useLocation()
	const { siteId: siteIdParams } = useParams<string>()
	const siteId = Number(siteIdParams)
	const { isGuest } = useAuth()
	const { isTactical } = useGetSystemInfoQuery(undefined, {
		selectFromResult: ({ data }) => ({
			isTactical: selectIsTactical(data),
		}),
	})

	// Starts URLs /siteId/radar, /siteId/rf_sensors, where installation icon should be highlighted
	const installationsPaths = ['rf_sensors', 'radar', 'installation']
	const zonePaths = ['map-layers', 'zone', 'markers']

	//
	const checkActive = (url: string) => {
		const regex = new RegExp(`^\\/[0-9]+\\/${url}\\b`)
		if (url === 'installation') {
			return installationsPaths.some((path) => location.pathname.includes(path))
		}
		if (url === 'zones') {
			return zonePaths.some((path) => location.pathname.includes(path))
		}
		return regex.test(location.pathname)
	}

	const LINKS = [
		{
			slug: 'installations',
			icon: <MdSettings />,
			disabled: !siteId,
			include: isGuest,
			includeTactical: true,
			isActive: checkActive('installation') || checkActive('installations'),
			match: useMatch({
				path: '/:siteId/installations',
				end: false,
			}),
		},
		{
			slug: 'zones',
			icon: <MdMap />,
			disabled: !siteId,
			include: isGuest,
			includeTactical: true,
			isActive: checkActive('zones'),
			match: useMatch({
				path: '/:siteId/zones',
				end: false,
			}),
		},
		{
			slug: 'notes',
			icon: <MdNote />,
			include: isGuest,
			includeTactical: true,
			disabled: !siteId,
			isActive: checkActive('notes'),
			match: useMatch({
				path: '/:siteId/notes',
				end: false,
			}),
		},
		{
			slug: 'analytics',
			icon: <MdPieChart style={{ transform: 'rotate(-40deg)' }} />,
			disabled: !siteId,
			include: isGuest,
			includeTactical: true,
			isActive: checkActive('analytics'),
			match: useMatch({
				path: '/:siteId/analytics',
				end: false,
			}),
		},
		// Control Panel
		// {
		// 	slug: 'panel',
		// 	icon: <MdNetworkCheck />,
		// 	disabled: true,
		// 	include: isGuest,
		// 	includeTactical: true,
		// },
	]

	const filteredTacticalLinks = LINKS.filter((link) => link.includeTactical)

	const filteredLinks = isTactical
		? filteredTacticalLinks.filter((link) => link.include)
		: LINKS.filter((link) => link.include)

	return (
		<Flex
			className='LeftNavigation'
			height='100%'
			flexDirection='column'
			alignItems='center'
			justifyContent='center'
			gap='1rem'
			position='relative'
			zIndex='1100'
			data-testid='left-navigation'
		>
			{filteredLinks.map(({ icon, disabled, slug, match, isActive }) => {
				return disabled ? (
					<ErrorBoundary quiet key={slug}>
						<IconButton
							aria-label={slug}
							variant='navigation_icon'
							isDisabled={disabled}
							icon={icon}
						/>
					</ErrorBoundary>
				) : (
					<ErrorBoundary quiet key={slug}>
						<Link
							to={`/${siteId && Number(siteId)}/${match ? '' : slug}`}
							data-testid={slug}
							replace={!!match}
						>
							<IconButton
								aria-label={slug}
								variant='navigation_icon'
								isActive={isActive}
								icon={icon}
							/>
						</Link>
					</ErrorBoundary>
				)
			})}
		</Flex>
	)
}

export default LeftNavigation
