import { defineStyleConfig } from '@chakra-ui/react'

const TableStyles = defineStyleConfig({
	baseStyle: {
		tr: {
			'& > td, & > th': {
				fontSize: 'sm',
				p: '8px 12px',
				borderBottomColor: 'chakra-border-color',
			},
			'& > th': {
				fontWeight: 500,
			},
			'&:last-child > td': {
				borderBottom: 0,
			},
		},
	},
})

export const Table = {
	...TableStyles,
}
