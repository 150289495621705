import type { Disruptor, RfSensor } from '@Store/types'
import { STATUS_COLORS } from '@Constants/installations'

export const isDsxDisabled = (rfSensorDsx: RfSensor) =>
	rfSensorDsx.status_color === STATUS_COLORS.RED ||
	!(
		!!rfSensorDsx.gnss_trigger_engaged ||
		!!rfSensorDsx.band_24_58_trigger_engaged ||
		// DSX Mk2
		!!rfSensorDsx.Cannon?.v2_bands_statuses?.gnss_enabled ||
		!!rfSensorDsx.Cannon?.v2_bands_statuses?.spectrum_enabled
	)

export const isEveryDsxDisabled = (rfSensorDsx: RfSensor[]) => {
	return !(
		!!rfSensorDsx.find((dsx) => !!dsx.gnss_trigger_engaged) ||
		!!rfSensorDsx.find((dsx) => !!dsx.band_24_58_trigger_engaged) ||
		// DSX Mk2
		!!rfSensorDsx.find(
			(dsx) => !!dsx.Cannon?.v2_bands_statuses?.gnss_enabled
		) ||
		!!rfSensorDsx.find(
			(dsx) => !!dsx.Cannon?.v2_bands_statuses?.spectrum_enabled
		)
	)
}

export const isDisruptorDisabled = (disruptor: Disruptor) =>
	disruptor.status_color === STATUS_COLORS.RED ||
	!(
		!!disruptor.gnss_trigger_engaged ||
		!!disruptor.band_24_58_trigger_engaged ||
		!!disruptor.band_433_trigger_engaged ||
		!!disruptor.band_915_trigger_engaged ||
		!!disruptor.band_433_915_trigger_engaged
	)

export const isEveryDisruptorDisabled = (disruptors: Disruptor[]) => {
	return !(
		!!disruptors.find((d) => !!d.gnss_trigger_engaged) ||
		!!disruptors.find((d) => !!d.band_24_58_trigger_engaged) ||
		!!disruptors.find((d) => !!d.band_433_trigger_engaged) ||
		!!disruptors.find((d) => !!d.band_915_trigger_engaged) ||
		!!disruptors.find((d) => !!d.band_433_915_trigger_engaged)
	)
}
