// Chakra
import { Divider as ChakraDivider, Stack, Text } from '@chakra-ui/react'

// Types
import type { FC } from 'react'

type DividerProps = {
	title: string
	line?: boolean
}

const Divider: FC<DividerProps> = ({ title = 'Title', line = false }) => {
	return (
		<Stack
			direction='row'
			alignItems='center'
			px={3}
			py={2}
			my={4}
			bg='divider_bg'
		>
			<Text
				textTransform='uppercase'
				color='headings'
				fontSize='sm'
				fontWeight={500}
			>
				{title}
			</Text>
			{line && <ChakraDivider flex='1' />}
		</Stack>
	)
}

export default Divider
