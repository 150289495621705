import { useEffect, useState } from 'react'

import { Box } from '@chakra-ui/react'

import CustomSvgLayer from '@UI/Charts/CustomSvgLayer'
import ScatterPlot from '@UI/Charts/ScatterPlot'

import type { ScatterPlotRawSerie, ScatterPlotDatum } from '@nivo/scatterplot'
import { useTranslation } from 'react-i18next'

const LeftCustomLayer = CustomSvgLayer({
	startX: -180,
	endX: -29,
	startY: 1,
	endY: -1,
	color: 'var(--c2-colors-charts-customGreen)',
})

const RightCustomLayer = CustomSvgLayer({
	startX: 29,
	endX: 180,
	startY: 1,
	endY: -1,
	color: 'var(--c2-colors-charts-customGreen)',
})

type CameraOffsetChartProps = {
	bearingDifference: number
}

const CameraOffsetChart = ({ bearingDifference }: CameraOffsetChartProps) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'siteInstallations.cameraCalibration',
	})
	const [panData, setPanData] = useState<
		ScatterPlotRawSerie<ScatterPlotDatum>[]
	>([{ id: 'pan', data: [] }])

	const lastBearing = bearingDifference ?? 0

	useEffect(
		() =>
			setPanData((prevCoordinates) => [
				{
					id: 'pan',
					data: [...prevCoordinates[0].data, { x: bearingDifference, y: 0 }],
				},
			]),
		[bearingDifference]
	)

	return (
		<Box h='100px' w='400px'>
			<ScatterPlot
				data={panData}
				xLabel={t('cameraAzimuthOffset')}
				layers={[
					'grid',
					'axes',
					'mesh',
					LeftCustomLayer,
					RightCustomLayer,
					'nodes',
				]}
				xScale={{
					type: 'linear',
					min: lastBearing - 30,
					max: lastBearing + 30,
				}}
				yTickValues={[]}
				yScale={{
					type: 'linear',
					min: -1,
					max: 1,
				}}
				margin={{ top: 5, right: 10, bottom: 55, left: 10 }}
				enableGridY={false}
				nodeSize={11}
			/>
		</Box>
	)
}

export default CameraOffsetChart
