import { useTheme } from '@chakra-ui/react'
import type { RfSensor } from '@Store/types'

import Sector from '@Components/MapShapes/Sector/Sector'

type DsxSectorProps = {
	latitude: number
	longitude: number
	isActive: boolean
} & Pick<RfSensor, 'reach'>

const DsxSector = ({
	latitude,
	longitude,
	reach = 0,
	isActive = false,
}: DsxSectorProps) => {
	const {
		semanticTokens: { colors },
	} = useTheme()
	const fillColor = colors.sectors.rfSensorFill

	const strokeColor = isActive
		? colors.sectors.highlight
		: colors.sectors.rfSensorStroke

	const strokeWeight = isActive ? 4 : 2

	return (
		<Sector
			latitude={latitude}
			longitude={longitude}
			reach={reach}
			color={strokeColor}
			fillColor={fillColor}
			weight={strokeWeight}
			testId='dsx-sector'
		/>
	)
}

export default DsxSector
