import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
	Box,
	Flex,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Tab,
} from '@chakra-ui/react'

import FormHeader from '@Components/Forms/FormHeader'
import Headings from '@Components/_UI/Headings/Headings'
import { StatusDot } from '../DevicesContent'

import AglMask from './AglMask'
import ZoneMasks from './ZoneMasks'
import {
	selectSiteRadarById,
	useGetSiteLiveQuery,
} from '@Store/sites/sitesWsApi'

const RadarMasks = () => {
	const { sensorId: sensorIdParam, siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)
	const sensorId = Number(sensorIdParam)
	const { sensor } = useGetSiteLiveQuery(siteId, {
		selectFromResult: ({ data }) => ({
			sensor: selectSiteRadarById(data, sensorId),
		}),
	})
	const { t } = useTranslation('panels', { keyPrefix: 'radarMasks' })

	return (
		<>
			<FormHeader title='Radar Mask' backRoute={`/${siteId}/installations`} />
			{sensor?.name && (
				<Flex gap={2} mb={4}>
					<Flex mt={5}>
						<StatusDot color={sensor.status_color} />
					</Flex>
					<Headings.SectionSubheading title={sensor?.name} />
				</Flex>
			)}
			<Box>
				<Tabs>
					<TabList>
						<Tab data-testid='zone-mask'>{t('zoneMask')}</Tab>
						<Tab data-testid='agl-mask'>{t('aglMask')}</Tab>
					</TabList>
					<TabPanels>
						<TabPanel>
							<ZoneMasks siteId={siteId} sensorId={sensorId} />
						</TabPanel>
						<TabPanel>
							<AglMask siteId={siteId} sensorId={sensorId} />
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Box>
		</>
	)
}

export default RadarMasks
