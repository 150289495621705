import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { isISO8601 } from 'validator'

import { Flex, Input } from '@chakra-ui/react'

import Headings from '@UI/Headings/Headings'
import { debounce } from '@Utils/functions'
import { isAfter, isBefore } from 'date-fns'

type ReportRangeProps = {
	startTime: string
	endTime: string
	setStartTime: (startTime: string) => void
	setEndTime: (endTime: string) => void
	isDisabled?: boolean
}

const debounceMs = 1000

const ReportRange = ({
	startTime,
	endTime,
	setStartTime,
	setEndTime,
	isDisabled,
}: ReportRangeProps) => {
	const { t } = useTranslation('pages', {
		keyPrefix: 'analytics.reportRange',
	})
	const [isError, setIsError] = useState<boolean>(false)

	const handleChangeStartTime = (time: string) => {
		if (isISO8601(time)) {
			const isStartAfterEnd = isAfter(new Date(time), new Date(endTime))
			if (isStartAfterEnd) {
				setIsError(true)
			} else {
				setIsError(false)
				setStartTime(time)
			}
		}
	}

	const handleChangeEndTime = (time: string) => {
		if (isISO8601(time)) {
			const isEndBeforeStart = isBefore(new Date(time), new Date(startTime))
			if (isEndBeforeStart) {
				setIsError(true)
			} else {
				setIsError(false)
				setEndTime(time)
			}
		}
	}

	const debouncedHandleChangeStartTime = debounce(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			handleChangeStartTime(e.target.value)
		},
		debounceMs
	)

	const debouncedHandleChangeEndTime = debounce(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			handleChangeEndTime(e.target.value)
		},
		debounceMs
	)

	return (
		<Flex p='16px 0px' alignItems='center' gap='6px'>
			<Headings.FieldHeading title={t('viewReport')} />
			<Input
				type='datetime-local'
				max='9999-12-31T23:59'
				w='225px'
				h='28px'
				defaultValue={startTime}
				onChange={debouncedHandleChangeStartTime}
				isDisabled={isDisabled}
				isInvalid={isError}
				data-testid='report-from'
			/>
			<Headings.FieldHeading title={t('to')} />
			<Input
				type='datetime-local'
				max='9999-12-31T23:59'
				w='225px'
				h='28px'
				defaultValue={endTime}
				onChange={debouncedHandleChangeEndTime}
				isDisabled={isDisabled}
				isInvalid={isError}
				data-testid='report-to'
			/>
		</Flex>
	)
}

export default ReportRange
