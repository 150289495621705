// React
import { useTranslation } from 'react-i18next'
// Chakra
import {
	Button,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
} from '@chakra-ui/react'
import { MdOpenInNew } from 'react-icons/md'

// Types
import type { MapLayer } from '@Store/types/mapLayers'
import MapLayersTable from '@Components/Site/Panels/MapsAndZones/MapLayers/Tables/MapLayersTable'
import CreateMapLayerModal from '@Components/Site/Panels/MapsAndZones/MapLayers/Modals/CreateMapLayerModal'

const TableMapLayersModal = ({ mapLayers }: { mapLayers?: MapLayer[] }) => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	// Translations
	const { t } = useTranslation('forms', {
		keyPrefix: 'mapsZonesForm.mapLayersForm',
	})

	return (
		<>
			<Button
				variant='action'
				leftIcon={<MdOpenInNew />}
				onClick={onOpen}
				isDisabled={!mapLayers?.length}
			>
				{t('buttons.tableView')}
			</Button>

			<Modal isOpen={isOpen} onClose={onClose} isCentered>
				<ModalOverlay />
				<ModalContent minW='calc(100% - 400px)'>
					<ModalHeader>{t('entity')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody mb={6}>
						<Flex justifyContent='flex-end' mb={4}>
							<CreateMapLayerModal variant='button' />
						</Flex>

						<MapLayersTable mapLayers={mapLayers} variant='expanded' />
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	)
}

export default TableMapLayersModal
