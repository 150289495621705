import { useTranslation } from 'react-i18next'
import { useState } from 'react'

import { MdCenterFocusWeak as TrackObjectIcon } from 'react-icons/md'
import { MdZoomOutMap as ReleaseObjectIcon } from 'react-icons/md'
import { MdLock as LockObjectIcon } from 'react-icons/md'

import { useAppDispatch, useAppSelector } from '@Store/index'

import {
	useGetSiteLiveQuery,
	selectCurrentCamera,
	selectCameraTrackedTargetId,
	selectIsCameraDisabled,
	selectHasSiteDetections,
} from '@Store/sites/sitesWsApi'

import { useTrackDetectionMutation } from '@Store/cameras/camerasApi'
import {
	setActiveCameraId,
	selectActiveTargetId,
	addToast,
} from '@Store/ui/uiSlice'

import QuickActionsButton from '../QuickActionsButton/QuickActionsButton'
import type { CoreApiError } from '@Store/utils/errorUtils'

const TrackObjectButton = ({ siteId }: { siteId: number }) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'quickActions.cameraActions',
	})

	const { currentCamera, trackedTargetId, isCameraDisabled, hasDetections } =
		useGetSiteLiveQuery(siteId, {
			selectFromResult: ({ data }) => ({
				currentCamera: selectCurrentCamera(data),
				trackedTargetId: selectCameraTrackedTargetId(data),
				isCameraDisabled: selectIsCameraDisabled(data),
				hasDetections: selectHasSiteDetections(data),
			}),
		})

	const dispatch = useAppDispatch()
	const [trackDetection] = useTrackDetectionMutation()
	const activeTargetId = useAppSelector(selectActiveTargetId)

	const isTracking =
		currentCamera?.auto_track === 0
			? !!trackedTargetId
			: currentCamera?.target_locked
	const [isError, setError] = useState<boolean>(false)

	const handleTrackDetection = async () => {
		try {
			// default targetId
			let targetId = isTracking ? null : activeTargetId

			// when auto-tracking is enabled and not tracking
			if (currentCamera?.auto_track !== 0 && !isTracking) {
				if (trackedTargetId && !activeTargetId) {
					// detection not selected and Lock On Target button is pressed
					targetId = trackedTargetId
				} else if (activeTargetId) {
					// detection is selected and Lock On Target button is pressed
					targetId = activeTargetId
				}
			}

			await trackDetection({
				siteId,
				cameraId: currentCamera?.id,
				targetId,
			}).unwrap()
			dispatch(setActiveCameraId(currentCamera?.id))
			setError(false)
		} catch (error) {
			const errorDescription = t('status.error', {
				cameraName: currentCamera?.name,
			})

			dispatch(
				addToast({
					type: 'error',
					description: errorDescription,
					siteId,
					error,
					id: `track-object-${(error as CoreApiError).status}`,
				})
			)
			setError(true)
			console.error(errorDescription, error)
			throw new Error(errorDescription)
		}
	}

	const isButtonDisabled = () => {
		if (isCameraDisabled) return true
		else if (isTracking)
			return false // handle legacy tracking
		else if (currentCamera?.auto_track !== 0 && trackedTargetId)
			return false // handle lock on target click without selecting a detection
		else if (activeTargetId) return false
		else if (!hasDetections) return true
		else return true
	}

	let buttonIcon = TrackObjectIcon
	let buttonLabel = t('trackObject')
	if (currentCamera?.auto_track !== 0 && !isTracking) {
		buttonIcon = LockObjectIcon
		buttonLabel = t('lockOnTarget')
	} else if (isTracking) {
		buttonIcon = ReleaseObjectIcon
		buttonLabel = t('releaseObject')
	}

	return (
		<QuickActionsButton
			icon={buttonIcon}
			label={buttonLabel}
			onClick={handleTrackDetection}
			isActive={isTracking}
			isDisabled={isButtonDisabled()}
			testId='quick-action-track-object'
			isError={isError}
		/>
	)
}

export default TrackObjectButton
