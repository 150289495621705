// React
import { useCallback, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// Chakra
import { Box } from '@chakra-ui/react'
// Components
import RfFiltersOverviewFiltering from './RfFiltersOverviewFiltering'
import RfFiltersOverviewFrequencies from './RfFiltersOverviewFrequencies'
import RfFiltersOverviewBearings from './RfFiltersOverviewBearings'
// Types
import type { RfSensorFilter } from '@Store/types'

const RfFiltersOverview = ({
	isWhiteListed,
	filters,
}: {
	isWhiteListed: boolean
	filters: RfSensorFilter[]
}) => {
	const [filteredFilters, setFilteredFilters] = useState(filters)
	// Translations
	const { t } = useTranslation('panels', {
		keyPrefix: 'rfFilters.overview',
	})
	// Handlers
	const handleFilteringChange = useCallback((filters: RfSensorFilter[]) => {
		setFilteredFilters(filters)
	}, [])

	const filteredWithFrequencies = useMemo(() => {
		return filteredFilters.filter(
			(filter) => filter.frequency_band !== undefined
		)
	}, [filteredFilters])

	const filteredWithBearings = useMemo(() => {
		return filteredFilters.filter((filter) => filter.bearing !== undefined)
	}, [filteredFilters])

	// DSX
	const location = useLocation()
	const isDsx = location.pathname.includes('rf_sensors_dsx')

	return (
		<Box data-testid='rf_filters_overview' mb={6}>
			<Box mt={2}>{t(isWhiteListed ? 'filterWhitelist' : 'filterFilters')}</Box>
			<RfFiltersOverviewFiltering
				filters={filters}
				handleFilteringChange={handleFilteringChange}
			/>
			{!!filteredWithFrequencies.length && (
				<RfFiltersOverviewFrequencies filters={filteredWithFrequencies} />
			)}
			{!!filteredWithBearings.length && (
				<RfFiltersOverviewBearings
					filters={filteredWithBearings}
					isDsx={isDsx}
				/>
			)}
		</Box>
	)
}

export default RfFiltersOverview
