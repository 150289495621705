import { baseUrl, isProduction } from '@Constants/api'

export const convertToCSV = <T extends object>(array: T[]): string => {
	if (array.length === 0) return ''
	// probably ought to move the CSV generation to backend
	// or use a library for generating CSV (e.g. node-csv's csv-stringify lib)
	// but this works for now
	const headers = Object.keys(array[0]).join(',')
	const rows = array
		.map((obj) =>
			Object.values(obj)
				.map((value) => {
					if (typeof value === 'string')
						// convert " to "" and wrap each string value with ""
						return value.replace(/"/g, '""').replace(/^(.*)$/, '"$1"')
					else return value
				})
				.join(',')
		)
		.join('\n')
	return `${headers}\n${rows}`
}

export const handleDownload = (blob: Blob, fileName: string) => {
	const link = document.createElement('a')

	link.href = URL.createObjectURL(blob)
	link.download = fileName
	link.click()
	URL.revokeObjectURL(link.href)
}

export const downloadCsv = (csvContent: any, fileName: string) => {
	const blob = new Blob([csvContent], { type: 'text/csv' })
	handleDownload(blob, fileName)
}

// https://github.com/microsoft/TypeScript-DOM-lib-generator/issues/1568
const myUrlSearchParams = (
	init: Record<string, string | number | boolean | undefined>
) => new URLSearchParams(init as Record<string, string>)

export const fetchFile = async (
	url: string,
	parameters?: Record<string, string | number | boolean | undefined>
) => {
	const token = localStorage.getItem('token')

	let fetchUrl = isProduction ? url : `${baseUrl}${url}`
	if (parameters) {
		const filteredParams = Object.fromEntries(
			Object.entries(parameters).filter(
				([key, value]) => value !== '' && value !== undefined
			)
		)
		fetchUrl = fetchUrl + '?' + myUrlSearchParams(filteredParams)
	}

	const response = await fetch(fetchUrl, {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${token}`,
		},
	})

	if (!response.ok) {
		throw new Error(response.statusText)
	}

	return response.blob()
}
