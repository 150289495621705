// React
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
// Chakra
import { Box, Grid } from '@chakra-ui/react'
// Components
import MultiSelect from '@UI/Select/MultiSelect'
// Types
import type { RfSensorFilter } from '@Store/types'
type OptionType = {
	label: string
	value: string
}
type SelectedFieldsType = {
	name: OptionType[]
	frequency_band: OptionType[]
	vendor: OptionType[]
	protocol: OptionType[]
}
type FieldType = keyof RfSensorFilter
const RfFiltersOverviewFiltering = ({
	filters,
	handleFilteringChange,
}: {
	filters: RfSensorFilter[]
	handleFilteringChange: (filters: RfSensorFilter[]) => void
}) => {
	// Translations
	const { t } = useTranslation('panels', {
		keyPrefix: 'rfFilters.overview',
	})
	const [selectedFields, setSelectedFields] = useState<SelectedFieldsType>({
		name: [],
		frequency_band: [],
		vendor: [],
		protocol: [],
	})
	// Helpers
	const convertToMultiSelectOptions = (field: FieldType) => {
		const uniqOptions = [
			...new Set(
				filters.map((filter) => filter[field]).filter((value) => value)
			),
		]
		if (uniqOptions.length) {
			return uniqOptions.map((option) => {
				return { label: option, value: option }
			})
		} else return []
	}
	// Listeners
	const handleChange = (options: OptionType[], field: FieldType) => {
		setSelectedFields((prev) => ({
			...prev,
			[field]: options,
		}))
	}

	useEffect(() => {
		const selectedOptions = Object.values(selectedFields)
		if (!selectedOptions.flat().length) {
			handleFilteringChange(filters)
		} else {
			const filteredFilters: RfSensorFilter[][] = []

			Object.entries(selectedFields).forEach(([key, options]) => {
				const filtersWithKey = filters.filter(
					(value) => value[key as FieldType]
				)
				options.forEach((option) => {
					const filteredOption = filtersWithKey.filter(
						(filter) => filter[key as FieldType] === option.value
					)
					filteredFilters.push(filteredOption)
				})
			})
			handleFilteringChange([...new Set(filteredFilters.flat())])
		}
	}, [selectedFields, filters, handleFilteringChange])

	return (
		<Grid gap={2} gridTemplateColumns='1fr 1fr'>
			{['frequency_band', 'vendor', 'protocol', 'name'].map((field) => {
				const options = convertToMultiSelectOptions(field as FieldType)
				return (
					<Box key={field}>
						<MultiSelect
							title={t(field)}
							options={options}
							placeholder={t(field)}
							isDisabled={!options.length}
							onChange={(options: OptionType[]) =>
								handleChange(options, field as FieldType)
							}
							testId={`rf-filter-overview-${field}`}
						/>
					</Box>
				)
			})}
		</Grid>
	)
}

export default RfFiltersOverviewFiltering
