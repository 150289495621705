// TODO: pagination
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
	Box,
	Flex,
	Icon,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useDisclosure,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { MdAdd, MdCheckCircle, MdDelete, MdEdit } from 'react-icons/md'
import { HiEye, HiEyeSlash } from 'react-icons/hi2'

import { useDeleteRadarMaskMutation } from '@Store/radarMasks/radarMasksApi'
import {
	useGetSiteLiveQuery,
	selectIsSiteCalibrationMode,
} from '@Store/sites/sitesWsApi'

import Button from '@Components/_UI/Button/Button'
import DeleteModal from '@Components/_UI/Modals/DeleteModal'
import IconButton from '@Components/_UI/IconButton/IconButton'
import RadarMaskActiveSwitch from './ZoneMaskActiveSwitch'

import { MAX_RADAR_MASKS } from './ZoneMasks'
import type { RadarMaskZone } from '@Store/types'
import { useGetRadarQuery } from '@Store/radars/radarsApi'

type ZoneMasksTableProps = {
	radarMasks: RadarMaskZone[]
	enableHardMask: boolean
	isExpanded?: boolean
}

const ZoneMasksTable = ({
	radarMasks,
	enableHardMask,
	isExpanded = false,
}: ZoneMasksTableProps) => {
	return (
		<>
			{isExpanded ? (
				<ExpandedZoneMasksTable
					enableHardMask={enableHardMask}
					radarMasks={radarMasks}
				/>
			) : (
				<CollapsedRadarMasksTable radarMasks={radarMasks} />
			)}
		</>
	)
}

const ExpandedZoneMasksTable = ({
	radarMasks,
	enableHardMask,
}: {
	radarMasks: RadarMaskZone[]
	enableHardMask: boolean
}) => {
	const { siteId: siteIdParam, sensorId: sensorIdParam } = useParams()
	const siteId = Number(siteIdParam)
	const sensorId = Number(sensorIdParam)

	const navigate = useNavigate()
	const { t } = useTranslation('panels', { keyPrefix: 'radarMasks' })

	const { isCalibrationMode } = useGetSiteLiveQuery(siteId, {
		selectFromResult: ({ data }) => ({
			isCalibrationMode: selectIsSiteCalibrationMode(data),
		}),
	})

	const { isEchoShield } = useGetRadarQuery(
		{ siteId, sensorId },
		{
			selectFromResult: ({ data }) => ({
				isEchoShield: data?.radar_type === 'echo_shield',
			}),
		}
	)

	const handleAddRadarMask = () =>
		navigate(`/${siteId}/radar/${sensorId}/mask/add`)

	const baseTableHeaders = [
		'', // Active Switch Header
		t('table.id'),
		t('table.name'),
		t('table.azimuth'),
		t('table.range'),
	]
	const echoGuardHeaders = [t('table.elevation'), t('table.rcs')]
	const echoShieldHeader = [t('table.max_agl')]
	const tableHeaders = [
		...baseTableHeaders,
		...(isEchoShield ? echoShieldHeader : echoGuardHeaders),
	]
	if (enableHardMask) tableHeaders.push(t('table.hardMask'))

	const hasMaxRadarMasks = radarMasks.length >= MAX_RADAR_MASKS

	const getTooltip = () => {
		if (!isCalibrationMode) {
			return t('calibration')
		} else if (hasMaxRadarMasks) {
			return t('maxMasks')
		}
		return undefined
	}

	return (
		<>
			<Flex width='100%' justifyContent='flex-end'>
				<Button
					leftIcon={<MdAdd />}
					variant='ghost'
					onClick={handleAddRadarMask}
					size='sm'
					testId='add-radar-mask'
					isDisabled={!isCalibrationMode || hasMaxRadarMasks}
					tooltip={getTooltip()}
					label={t('add')}
				/>
			</Flex>
			<TableContainer>
				<Table variant='simple' mt={4} data-testid='radar-mask-list'>
					<Thead>
						<Tr>
							{tableHeaders.map((header) => (
								<Th key={header}>
									<Text>{header}</Text>
								</Th>
							))}
							<Th w='80px'>
								<Text align='center'>{t('table.actions')}</Text>
							</Th>
						</Tr>
					</Thead>
					<Tbody>
						{radarMasks.map((mask) => {
							return (
								<Tr key={mask.id}>
									<Td w='70px'>
										<RadarMaskActiveSwitch radarMask={mask} />
									</Td>
									<Td w='80px' data-testid='mask-id'>
										{mask.id}
									</Td>
									<Td data-testid='mask-name'>{mask.name}</Td>
									<Td data-testid='mask-azimuth'>{`${mask.azimuth_min}/${mask.azimuth_max}`}</Td>
									<Td data-testid='mask-range'>{`${mask.range_min}/${mask.range_max}`}</Td>
									{isEchoShield ? (
										<Td data-testid='max-agl'>{mask.max_agl}</Td>
									) : (
										<>
											<Td data-testid='mask-elevation'>{`${mask.elevation_min}/${mask.elevation_max}`}</Td>
											<Td data-testid='mask-rcs'>{`${mask.rcs_min}/${mask.rcs_max}`}</Td>
											{enableHardMask && (
												<Td>
													<Box
														paddingLeft='35px'
														data-testid={`mask-status-${mask.hard_mask}`}
													>
														<Icon
															as={MdCheckCircle}
															color={
																mask.hard_mask ? 'primary' : 'status.no_status'
															}
														/>
													</Box>
												</Td>
											)}
										</>
									)}
									<Td maxW='80px'>
										<ActionButtons id={mask.id} name={mask.name} />
									</Td>
								</Tr>
							)
						})}
					</Tbody>
				</Table>
			</TableContainer>
		</>
	)
}

const CollapsedRadarMasksTable = ({
	radarMasks,
}: {
	radarMasks: RadarMaskZone[]
}) => {
	const { t } = useTranslation('panels', { keyPrefix: 'radarMasks.table' })

	return (
		<TableContainer>
			<Table variant='simple' maxW='520px' mt={4}>
				<Thead>
					<Tr>
						<Th>{/* ACTIVE */}</Th>
						<Th>{t('id')}</Th>
						<Th>{t('name')}</Th>
						<Th w='80px'>
							<Text align='center'>{t('actions')}</Text>
						</Th>
					</Tr>
				</Thead>
				<Tbody>
					{radarMasks.map((mask) => {
						const { id, name } = mask
						return (
							<Tr key={id} data-testid={`simple: ${name}`}>
								<Td w='50px'>
									<RadarMaskActiveSwitch radarMask={mask} />
								</Td>
								<Td w='50px' data-testid='radar-mask-id'>
									{id}
								</Td>
								<Td w='195px'>
									<Text
										maxW='195px'
										whiteSpace='nowrap'
										overflow='hidden'
										textOverflow='ellipsis'
										data-testid='radar-mask-name'
									>
										{name}
									</Text>
								</Td>
								<Td maxW='80px'>
									<ActionButtons id={id} name={name} isCollapsed />
								</Td>
							</Tr>
						)
					})}
				</Tbody>
			</Table>
		</TableContainer>
	)
}

const ActionButtons = ({
	id,
	name,
	isCollapsed,
}: {
	id: number
	name: string
	isCollapsed?: boolean
}) => {
	const {
		siteId: siteIdParam,
		sensorId: sensorIdParam,
		maskId: maskIdParam,
	} = useParams()
	const siteId = Number(siteIdParam)
	const sensorId = Number(sensorIdParam)
	const maskId = Number(maskIdParam)

	const [radarMaskToDelete, setRadarMaskToDelete] = useState<{
		id: number
		name: string
	} | null>(null)

	const navigate = useNavigate()
	const { t } = useTranslation('panels', { keyPrefix: 'radarMasks' })

	const { isOpen, onOpen, onClose } = useDisclosure()
	const [deleteRadarMask] = useDeleteRadarMaskMutation()

	const { isCalibrationMode } = useGetSiteLiveQuery(siteId, {
		selectFromResult: ({ data }) => ({
			isCalibrationMode: selectIsSiteCalibrationMode(data),
		}),
	})

	const isSelected = id === maskId

	const handleOpenDeleteModal = (id: number, name: string) => {
		onOpen()
		setRadarMaskToDelete((prev) => ({ ...prev, id, name }))
	}

	const handleDelete = async () => {
		try {
			if (radarMaskToDelete) {
				await deleteRadarMask({
					siteId,
					sensorId,
					maskId: radarMaskToDelete.id,
				})
			}
		} catch (error) {
			console.error('Delete radar mask error', error)
		}
	}

	const handleCloseDeleteModal = () => {
		onClose()
		setRadarMaskToDelete(null)
	}

	const handleEditRadarMask = (id: number) =>
		navigate(`/${siteId}/radar/${sensorId}/mask/${id}/settings`)

	const handleToggleSelectedRadarMask = (id: number) => {
		const basePath = `/${siteId}/radar/${sensorId}/masks`
		const toggledPath = `/${siteId}/radar/${sensorId}/masks/${id}`

		navigate(isSelected ? basePath : toggledPath)
	}

	return (
		<>
			<Flex gap={0} justifyContent='center'>
				{isCollapsed && (
					<IconButton
						aria-label='select-radar-mask'
						icon={isSelected ? <HiEyeSlash /> : <HiEye />}
						onClick={() => handleToggleSelectedRadarMask(id)}
						testId='select-radar-mask'
						tooltip={isSelected ? t('unselectMask') : t('selectMask')}
					/>
				)}
				<IconButton
					aria-label='edit'
					icon={<MdEdit />}
					onClick={() => handleEditRadarMask(id)}
					testId={`edit-radar-mask-${id}`}
					isDisabled={!isCalibrationMode}
					tooltip={isCalibrationMode ? undefined : t('calibration')}
				/>
				<IconButton
					aria-label='delete'
					icon={<MdDelete />}
					onClick={() => handleOpenDeleteModal(id, name)}
					testId={`delete-radar-mask-${id}`}
				/>
			</Flex>
			<DeleteModal
				isOpen={isOpen}
				handleDelete={handleDelete}
				onClose={handleCloseDeleteModal}
				name={radarMaskToDelete?.name || ''}
				headerText={t('table.deleteRadarMask')}
				fromText={t('table.fromText')}
			/>
		</>
	)
}

export default ZoneMasksTable
