import { useTranslation } from 'react-i18next'
import {
	Box,
	Button,
	Flex,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react'
import { MdWarning } from 'react-icons/md'

type DeleteModalProps = {
	isOpen: boolean
	onClose: () => void
	handleDelete: () => void
	name: string
	headerText?: string
	fromText?: string
	testId?: string
}

// TODO: Add headerText as required for translations
const DeleteModal = ({
	// testId,
	isOpen,
	onClose,
	handleDelete,
	name,
	headerText,
	fromText,
}: DeleteModalProps) => {
	const onConfirm = async () => {
		handleDelete()
		onClose()
	}

	const { t } = useTranslation('app', { keyPrefix: 'global' })
	const headerTitle = headerText || t('modals.deleteDevice')
	const bodyText = fromText || t('modals.from')

	return (
		<>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				variant='deleteConfirmation'
				isCentered
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						<Flex alignItems='center' gap='8px' data-testid='modal-title'>
							<Icon as={MdWarning} h={6} w={6} color='error' />
							{headerTitle}
						</Flex>
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Box data-testid='modal-body'>
							{t('modals.removing')}
							<Text
								fontWeight='500'
								as='span'
								px={1}
								data-testid='removal-name'
							>
								{name}
							</Text>
							{bodyText}. {t('modals.cantUndo')}.
						</Box>
					</ModalBody>
					<ModalFooter>
						<Button variant='delete' onClick={onConfirm} data-testid='confirm'>
							{t('buttons.confirm')}
						</Button>
						<Button
							mr={3}
							onClick={onClose}
							variant='ghost'
							data-testid='cancel'
						>
							{t('buttons.cancel')}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}

export default DeleteModal
