// React
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
// Chakra
import {
	Button,
	Flex,
	Icon,
	IconButton,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useDisclosure,
} from '@chakra-ui/react'
import { MdDelete, MdWarning } from 'react-icons/md'
// Store
import type { RfSensorFilter } from '@Store/types'
import { useDeleteRfSensorFilterMutation } from '@Store/rfFilters/rfFiltersApi'

const RfFilterDeleteModal = ({ rfFilter }: { rfFilter: RfSensorFilter }) => {
	const { siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)
	const { id, name } = rfFilter
	const { isOpen, onOpen, onClose } = useDisclosure()
	// Translations
	const { t } = useTranslation('forms', { keyPrefix: 'rfFiltersForm' })

	const [deleteRfFilter] = useDeleteRfSensorFilterMutation()

	const handleDeleteRfFilter = async (id: number) => {
		try {
			await deleteRfFilter({ id, siteId })
			onClose()
		} catch (error) {
			console.error('unhandled layer selector delete error', error)
		}
	}

	return (
		<>
			<IconButton
				variant='ghost'
				colorScheme='white'
				aria-label='delete'
				icon={<MdDelete />}
				onClick={onOpen}
			/>

			<Modal isOpen={isOpen} onClose={onClose} isCentered>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						<Flex alignItems='center' gap={2}>
							<Icon as={MdWarning} color='error' boxSize={6} />
							{t('deleteFilter')} # {rfFilter?.id}
						</Flex>
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						{t('deleteNotificationText')}
						<Text fontWeight='500' as='span' px={1}>
							{name} ?
						</Text>
						<Text as='div'>{t('deleteCantUndo')}</Text>
					</ModalBody>
					<ModalFooter>
						<Button
							onClick={() => handleDeleteRfFilter(id)}
							colorScheme='error'
						>
							{t('buttons.confirm')}
						</Button>
						<Button mr={3} onClick={onClose} variant='ghost'>
							{t('buttons.cancel')}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}

export default RfFilterDeleteModal
