import { IconButton } from '@chakra-ui/react'
import { MdClose } from 'react-icons/md'
import { useAppDispatch } from '@Store/index'
import { setActiveCameraId } from '@Store/ui/uiSlice'

const CloseButton = () => {
	const dispatch = useAppDispatch()
	const handleClick = () => {
		dispatch(setActiveCameraId(null))
	}
	return (
		<IconButton
			icon={<MdClose size={24} />}
			variant='unstyled'
			onClick={handleClick}
			aria-label='close camera panel'
			color='camera.control_color'
		/>
	)
}

export default CloseButton
