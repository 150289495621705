import { Circle as LeafletCircle } from 'leaflet'
import type { CircleOptions } from 'leaflet'
import type { LeafletContextInterface } from '@react-leaflet/core'
import {
	createElementObject,
	createPathComponent,
	extendContext,
} from '@react-leaflet/core'

import type { LatLngExpression } from 'leaflet'
import type { ReactNode } from 'react'

type CircleProps = {
	latitude: number
	longitude: number
	testId?: string
	children?: ReactNode
} & Omit<CircleOptions, 'center'>

const createCircle = (
	{
		latitude,
		longitude,
		radius,
		testId,
		children: _c,
		...options
	}: CircleProps,
	context: LeafletContextInterface
) => {
	const center = [latitude, longitude] as LatLngExpression
	const circle = new LeafletCircle(center, { radius, ...options })
	if (testId) {
		circle.addEventListener('add', (e) =>
			e.target.getElement().setAttribute('data-testid', testId)
		)
	}
	return createElementObject(
		circle,
		extendContext(context, {
			overlayContainer: circle,
		})
	)
}

const updateCircle = (
	circle: LeafletCircle,
	props: CircleProps,
	prevProps: CircleProps
) => {
	if (
		props.latitude !== prevProps.latitude ||
		props.longitude !== prevProps.longitude
	)
		circle.setLatLng([props.latitude, props.longitude])
	if (props.radius !== prevProps.radius) circle.setRadius(props.radius)
}

const Circle = createPathComponent(createCircle, updateCircle)

export default Circle
