import { Button } from '@chakra-ui/react'

type AlertButtonProps = {
	label: string
	testId?: string
	onClick?: () => void
}

const AlertButton = ({ label, testId, onClick }: AlertButtonProps) => (
	<Button onClick={onClick} size='sm' data-testid={testId}>
		{label}
	</Button>
)

export default AlertButton
