import { useParams } from 'react-router-dom'
// Components
import Site from './Site'
import Installations from './Installations'
import Smarthubs from './Smarthubs'

import useAuth from '@Hooks/useAuth'

const SiteInstallations = () => {
	const { siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)

	const { isAdmin } = useAuth()

	return (
		<>
			<Site siteId={siteId} />
			<Installations siteId={siteId} />
			{isAdmin && <Smarthubs siteId={siteId} />}
		</>
	)
}

export default SiteInstallations
