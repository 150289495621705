import { useTranslation } from 'react-i18next'
import { useState } from 'react'

import {
	useGetSiteLiveQuery,
	selectSiteRfSensorsDsx,
} from '@Store/sites/sitesWsApi'
import { useToggleDsxJammingMutation } from '@Store/rfSensors/rfSensorsApi'

import DisruptorButton from './DisruptorButton/DisruptorButton'

import { isDsxDisabled } from '@Utils/installations'

import type { RfSensor } from '@/store/types'
import { addToast } from '@Store/ui/uiSlice'
import { useAppDispatch } from '@/store'
import type { CoreApiError } from '@/store/utils/errorUtils'

type DsxDisruptorsProps = {
	siteId: number
	isAllLoading: boolean
}

const DsxDisruptors = ({ siteId, isAllLoading }: DsxDisruptorsProps) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'quickActions.disruptorActions',
	})

	const [loadingDsx, setLoadingDsx] = useState<number | null>(null)

	const { rfSensorsDsx } = useGetSiteLiveQuery(siteId, {
		selectFromResult: ({ data }) => ({
			rfSensorsDsx: selectSiteRfSensorsDsx(data),
		}),
	})

	const [updateDsx, { isLoading }] = useToggleDsxJammingMutation()

	const dispatch = useAppDispatch()

	const rfSensorDsxJammers = rfSensorsDsx.filter(
		(s) => !s.model.includes('no_jam')
	)

	const toggleDsxDisruptor = async (sensor: RfSensor) => {
		try {
			const payload = {
				siteId,
				sensorId: sensor.id,
				power_trigger_command: !sensor.jamming,
			}

			setLoadingDsx(sensor.id)
			await updateDsx(payload).unwrap()
			setLoadingDsx(null)
		} catch (error: unknown) {
			const errorDescription = t('status.error', {
				sensorName: sensor?.name,
			})
			dispatch(
				addToast({
					type: 'error',
					description: errorDescription,
					siteId,
					error,
					id: `dsx-disrupt-${(error as CoreApiError).status}`,
				})
			)
			setLoadingDsx(null)
			console.error(errorDescription, error)
			throw new Error(errorDescription)
		}
	}

	return (
		<>
			{rfSensorDsxJammers.map((sensor, index) => (
				<DisruptorButton
					key={sensor.id}
					onClick={() => toggleDsxDisruptor(sensor)}
					isActive={sensor.jamming}
					name={sensor.name}
					isDisabled={isDsxDisabled(sensor)}
					testId={`dxdisabler-${index}`}
					tooltip={sensor.name}
					isLoading={(isLoading && loadingDsx === sensor.id) || isAllLoading}
				/>
			))}
		</>
	)
}

export default DsxDisruptors
