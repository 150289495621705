import { Flex, Icon, Text } from '@chakra-ui/react'
import { MdInfo } from 'react-icons/md'

const AlertHelper = ({
	title,
	variant = 'default',
}: {
	title: string
	variant?: 'default' | 'outer'
}) => {
	switch (variant) {
		case 'outer':
			return (
				<Flex gap={3} mt={3} p={1} bgColor='input_bg' alignItems='center'>
					<Icon as={MdInfo} marginInlineStart={1} color='primary' />
					<Flex direction='column'>
						<Text fontSize='sm'>{title}</Text>
					</Flex>
				</Flex>
			)
		default:
			return (
				<Flex gap={3} mt={3} alignItems='center'>
					<Icon as={MdInfo} color='primary' />
					<Flex direction='column'>
						<Text fontSize='sm'>{title}</Text>
					</Flex>
				</Flex>
			)
	}
}

export default AlertHelper
