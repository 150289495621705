import { useAppSelector } from '@Store/index'
import { selectNetworkSlowLink } from '@Store/system/systemSlice'
import { selectShowNetworkDisconnected } from '@Store/ui/uiSlice'

import { MdSignalCellularNodata as DisconnectedIcon } from 'react-icons/md'
import { MdSignalCellularConnectedNoInternet1Bar as SlowLinkIcon } from 'react-icons/md'

import { Flex, Icon, Text, Tooltip } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

const NetworkStatus = () => {
	const disconnected = useAppSelector(selectShowNetworkDisconnected)
	const slowLink = useAppSelector(selectNetworkSlowLink)
	const { t } = useTranslation('navigation', { keyPrefix: 'networkStatus' })

	if (!disconnected && !slowLink) return null
	else
		return (
			<Flex marginInline={4} gap={1}>
				{disconnected && (
					<Tooltip label={t('tooltips.disconnected')}>
						<>
							<Icon boxSize={6} color='status.red' as={DisconnectedIcon} />
							<Text fontSize='sm' paddingBlockStart={0.5}>
								{t('disconnected')}
							</Text>
						</>
					</Tooltip>
				)}
				{!disconnected && slowLink && (
					<Tooltip label={t('tooltips.slowLink')}>
						<>
							<Icon boxSize={6} color='primary' as={SlowLinkIcon} />
							<Text fontSize='sm' paddingBlockStart={0.5}>
								{t('slowLink')}
							</Text>
						</>
					</Tooltip>
				)}
			</Flex>
		)
}

export default NetworkStatus
