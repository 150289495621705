import { Flex } from '@chakra-ui/react'
import { MdOutlineReplay } from 'react-icons/md'

import Button from '@UI/Button/Button'
import Input from '@UI/Input/Input'
import Headings from '@UI/Headings/Headings'
import SpinnerText from '@UI/SpinnerText/SpinnerText'

import { setCameraCalibrationStep, setActiveTargetId } from '@Store/ui/uiSlice'
import { useCalibrateCameraMutation } from '@Store/cameras/camerasApi'
import { useAppDispatch } from '@Store/index'

import type { TiltResult } from '../types'
import { useTranslation } from 'react-i18next'

type CalibrationCheckProps = {
	tiltResult?: TiltResult
	panResult?: number
	siteId: number
	sensorId: number
}

const CalibrationCheck = ({
	tiltResult,
	panResult = 0,
	sensorId,
	siteId,
}: CalibrationCheckProps) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'siteInstallations.cameraCalibration',
	})
	const dispatch = useAppDispatch()
	const [calibrateCamera] = useCalibrateCameraMutation()

	const handleRestartCalibration = () => {
		calibrateCamera({ cameraId: sensorId, siteId, action: 'stop' })
		dispatch(setCameraCalibrationStep(1))
		dispatch(setActiveTargetId(null))
	}

	return (
		<Flex w='430px' p='16px' bgColor='body_bg' direction='column' gap='16px'>
			<SpinnerText text={t('confirmDeviceSettings')} status='active' />
			<Headings.FieldHeading title={t('headers.calibrationCheck')} />
			<Flex gap='16px'>
				<Input label={t('azimuthOffset')} value={panResult} />
				<Input label={t('minTilt')} value={tiltResult?.min || 0} />
				<Input label={t('maxTilt')} value={tiltResult?.max || 0} />
			</Flex>
			<Button
				label={t('buttons.restartCalibration')}
				testId='restart-calibration'
				variant='action'
				size='sm'
				w='150px'
				alignItems='center'
				leftIcon={<MdOutlineReplay />}
				onClick={handleRestartCalibration}
			/>
		</Flex>
	)
}

export default CalibrationCheck
