import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useMapEvents } from 'react-leaflet'

import { mapBounds } from '@Components/Map/Map'

const MapEventsHandler = () => {
	const { siteId, installationId, sensorType, sensorId } = useParams()
	const navigate = useNavigate()
	const location = useLocation()
	const handleDeactivateSensorsClick = () => {
		// Deactivate all active sensors sectors
		if (sensorId) {
			// TODO: set case of urls where sensors highlighting and go back should be disabled
			const isEditSensorSettingsUrl = location.pathname.includes('settings')
			const isEditRfFiltersUrl = location.pathname.includes('rf_filters')
			const isEditRadarMasksUrl = location.pathname.includes('mask')
			const isCameraCalibrationUrl = location.pathname.includes('calibrate')

			if (
				!isEditSensorSettingsUrl &&
				!isEditRfFiltersUrl &&
				!isEditRadarMasksUrl &&
				!isCameraCalibrationUrl
			) {
				navigate(`/${siteId}/installations/${installationId}/${sensorType}`)
			}
		}
	}

	const map = useMapEvents({
		drag() {
			// prevent dragging beyond the mapBounds,
			// at this time meaning the north and south pole
			map.panInsideBounds(mapBounds, { animate: false })
		},
		click() {
			handleDeactivateSensorsClick()
		},
	})
	return null
}

export default MapEventsHandler
