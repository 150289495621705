import { useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
	Badge,
	Flex,
	Icon,
	Menu,
	MenuButton,
	MenuGroup,
	MenuList,
	MenuItem,
	MenuDivider,
	Text,
} from '@chakra-ui/react'
import {
	MdPerson,
	MdPeople,
	MdNotifications,
	MdExitToApp,
	MdApartment,
} from 'react-icons/md'

// Redux
import { useAppDispatch, useAppSelector } from '@Store/index'
import { logout } from '@Store/auth/authSlice'
import { selectUser } from '@Store/user/userSlice'
import useAuth from '@Hooks/useAuth'
import { selectClientsName, useGetClientQuery } from '@Store/clients/clientsApi'
import ErrorBoundary from '@Components/App/ErrorHandling/ErrorBoundary'

import UserProfileMenuItem from './UserProfileMenuItem'

const UserMenu = () => {
	const { t } = useTranslation('navigation', { keyPrefix: 'userMenu' })
	const { siteId: siteIdParam } = useParams()
	const { isManager, isSiteManager, role } = useAuth()
	const siteId = Number(siteIdParam)
	const dispatch = useAppDispatch()

	const navigate = useNavigate()
	const handleSignOut = () => {
		dispatch(logout())
			.unwrap()
			.then(() => {
				navigate('/')
				navigate(0) // reload equivalent
			})
	}

	const user = useAppSelector(selectUser)

	const { clientName } = useGetClientQuery(Number(user?.client_id), {
		skip: !user?.client_id,
		selectFromResult: ({ data }) => ({
			clientName: selectClientsName(data),
		}),
	})

	if (!user) return null
	return (
		<Menu
			placement='bottom-start'
			strategy='fixed'
			gutter={1}
			autoSelect={false}
			variant='userMenu'
		>
			<MenuButton data-testid='user-settings'>
				<Icon as={MdPerson} boxSize={6} />
			</MenuButton>
			<MenuList>
				<ErrorBoundary quiet>
					<MenuGroup title='Account'>
						<MenuItem icon={<MdPerson size='1.25rem' />} pointerEvents='none'>
							<Flex alignItems='center'>
								<Flex flexGrow={1} flexDirection='column'>
									<Flex justifyContent='space-between' alignItems='center'>
										<Text
											color='white'
											fontWeight={500}
											data-testid='user-name'
										>
											{user?.name}
										</Text>
										<Badge textTransform='uppercase' data-testid='user-role'>
											{String(role).replaceAll('_', ' ')}
										</Badge>
									</Flex>
									<Text color='gray.400' data-testid='user-email'>
										{user?.email}
									</Text>
								</Flex>
							</Flex>
						</MenuItem>
						{clientName && (
							<MenuItem
								icon={<MdApartment size='1.25rem' />}
								pointerEvents='none'
							>
								<Flex alignItems='center'>
									<Flex flexGrow={1} flexDirection='column'>
										<Flex justifyContent='space-between' alignItems='center'>
											<Text color='white' fontWeight={500}>
												{t('client')}:
											</Text>
										</Flex>
										<Text color='gray.400' data-testid='client-name'>
											{clientName}
										</Text>
									</Flex>
								</Flex>
							</MenuItem>
						)}
					</MenuGroup>
				</ErrorBoundary>
				<MenuDivider />
				<MenuGroup title='Actions'>
					{isManager && (
						<ErrorBoundary quiet>
							<MenuItem
								icon={<MdPeople size='1rem' />}
								onClick={() => navigate(`/${siteId}/user-management`)}
								data-testid='user-management'
							>
								{t('userManagement')}
							</MenuItem>
						</ErrorBoundary>
					)}
					{isSiteManager && (
						<ErrorBoundary quiet>
							<MenuItem
								icon={<MdNotifications size='1rem' />}
								onClick={() => navigate(`${siteId}/alert-settings`)}
								data-testid='alert-settings'
								isDisabled={!siteId}
							>
								{t('alertSettings')}
							</MenuItem>
						</ErrorBoundary>
					)}
					<ErrorBoundary quiet>
						<UserProfileMenuItem userId={user.id} />
					</ErrorBoundary>
					<MenuItem
						icon={<MdExitToApp size='1rem' />}
						onClick={handleSignOut}
						data-testid='logout'
					>
						{t('logout')}
					</MenuItem>
				</MenuGroup>
			</MenuList>
		</Menu>
	)
}

export default UserMenu
