import { createContext, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import type { EventFilters } from '@Store/analytics/types'
import type { OnChangeFn, ColumnFiltersState } from '@tanstack/react-table'
import type { EventColumnType } from './columnDefinitions'

type EventFiltersContext = {
	columnFilters: ColumnFiltersState
	setColumnFilters?: OnChangeFn<ColumnFiltersState>
	globalFilter: string
	setGlobalFilter?: OnChangeFn<string>
	filterParams: EventFilters
}

export const useEventFilter = () => {
	const context = useContext<EventFiltersContext | undefined>(
		EventFilterContext
	)
	if (context === undefined) {
		throw new Error('useEventFilter must be used within an EventFilterProvider')
	}
	return context
}

export const EventFilterContext = createContext<
	EventFiltersContext | undefined
>(undefined)

export const EventFilterProvider = ({
	children,
}: {
	children: React.ReactNode
}) => {
	const { t } = useTranslation('pages', {
		keyPrefix: 'analytics.trackSummary',
	})

	const defaultFilters: ColumnFiltersState = t('defaultFilters', {
		returnObjects: true,
	})

	const [columnFilters, setColumnFilters] =
		useState<ColumnFiltersState>(defaultFilters)
	const [globalFilter, setGlobalFilter] = useState('')

	const getFilters = (columnType: EventColumnType): string | undefined =>
		columnFilters.find((filter) => filter.id === columnType)?.value as
			| string
			| undefined

	const getConfirmedByFilter = (sensorType: string) => {
		const filterValue = getFilters('confirmed_by')
		if (filterValue?.split(',').includes(sensorType)) {
			return true
		}
		return undefined
	}

	const filterParams: EventFilters = {
		filter_by_target_or_serial: globalFilter,
		filter_by_probability: getFilters('probability'),
		filter_by_false_positive: getFilters('false_positive'),
		filter_by_state: getFilters('state'),
		filter_by_vendor: getFilters('vendor'),
		filter_by_protocol: getFilters('protocol'),
		filter_by_rf_confirmed: getConfirmedByFilter('rf'),
		filter_by_radar_confirmed: getConfirmedByFilter('radar'),
		filter_by_camera_confirmed: getConfirmedByFilter('camera'),
		filter_by_drone_locator_confirmed: getConfirmedByFilter('droneLocator'),
	}

	return (
		<EventFilterContext.Provider
			value={{
				columnFilters,
				setColumnFilters,
				globalFilter,
				setGlobalFilter,
				filterParams,
			}}
		>
			{children}
		</EventFilterContext.Provider>
	)
}
