import type { ControlPosition } from 'leaflet'
import { createControlComponent } from '@react-leaflet/core'
import { SimpleMapScreenshoter } from 'leaflet-simple-map-screenshoter'
import './ScreenshotTool.css'
import i18n from '@/i18n'

const controlPosition: ControlPosition = 'bottomleft'

const createControlLayer = () => {
	const t = (key: string) => i18n.t(`map:mapControls.tooltips.${key}`)

	const screenshotTool = new SimpleMapScreenshoter({
		position: controlPosition,
	})
	// Extend the onAdd method to include setting a data attribute

	if (screenshotTool) {
		const originalOnAdd = screenshotTool?.onAdd?.bind(screenshotTool)
		if (originalOnAdd) {
			screenshotTool.onAdd = (map) => {
				const container = originalOnAdd(map)
				// Set the data attribute on the container
				container.setAttribute('data-tooltip', t('screenShot'))
				return container
			}
		}
	}

	return screenshotTool
}

const ScreenshotTool = createControlComponent(createControlLayer)

export default ScreenshotTool
