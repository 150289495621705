// React
import { type ChangeEvent, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
// Chakra
import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	Slider as ChakraSlider,
	SliderFilledTrack,
	SliderThumb,
	SliderTrack,
	Input,
	Grid,
	Box,
} from '@chakra-ui/react'
// Components
import Tooltip from '@UI/Tooltip/Tooltip'
import SliderCustomLabel from './SliderCustomLabel'
// Types
import type { SliderProps } from '@chakra-ui/slider/dist/slider'
import type { RegisterType } from '@/components/FormElements/types/RegisterType'
import type { FormElementProps } from '@Components/FormElements/types/FormElementProps'
import type { Units } from '@Components/FormElements/types/Units'
// Hooks
import useUnits from '@Hooks/useUnits'

export type FormSliderProps = FormElementProps & {
	defaultValue?: number
	min: number
	max: number
	step?: number
	inputWidth?: number
	units?: Units
	isAbsolute?: boolean
	customLabel?: string | number
} & SliderProps &
	RegisterType<string>

const Slider = ({
	id,
	title,
	error,
	register,
	defaultValue, // deprecated, don't use it
	min,
	max,
	step = 1,
	disabled = false,
	inputWidth = undefined,
	testId,
	tooltip,
	units,
	customLabel,
	isAbsolute = false,
}: FormSliderProps) => {
	const { control } = useFormContext()

	const htmlId = id ?? register?.name ?? 'slider'

	const { unit } = useUnits(units)

	const calculatedInputWidth = useMemo(() => {
		if (inputWidth) return `${inputWidth}ch`
		else if (String(max).length >= 5) return `${String(max).length + 2}ch`
		else return '6ch'
	}, [inputWidth, max])

	const handleChange = (
		e: ChangeEvent<HTMLInputElement>,
		onChange: (...event: number[]) => void
	) => {
		const value = Number(e.target.value)
		value >= min && value <= max && onChange(value)
		setTimeout(() => e.target.focus(), 50)
	}

	return (
		<Tooltip label={tooltip} type='info'>
			<FormControl isInvalid={!!error} isDisabled={disabled} userSelect='none'>
				<Controller
					control={control}
					name={htmlId}
					defaultValue={defaultValue} // need to remove
					render={({ field: { value, onChange } }) => {
						return (
							<Box position='relative'>
								<SliderCustomLabel
									customLabel={customLabel}
									isAbsolute={isAbsolute}
									units={units}
								/>
								<FormLabel flex='1 0 auto'>
									{title} {!!unit && `(${unit})`}
								</FormLabel>
								<Grid templateColumns={`${calculatedInputWidth} 1fr`}>
									<Input
										type='number'
										w={calculatedInputWidth}
										p={0}
										bgColor='transparent'
										borderBottom='1px solid'
										borderBottomColor='headings.500'
										textAlign='center'
										value={value}
										onChange={(e) => handleChange(e, onChange)}
										data-testid={testId}
									/>
									<ChakraSlider
										id={htmlId}
										onChange={onChange}
										value={value}
										min={min}
										max={max}
										isDisabled={disabled}
										step={step}
									>
										<SliderTrack>
											<SliderFilledTrack />
										</SliderTrack>
										<SliderThumb />
									</ChakraSlider>
								</Grid>
							</Box>
						)
					}}
				></Controller>
				<FormErrorMessage>{error}</FormErrorMessage>
			</FormControl>
		</Tooltip>
	)
}

export default Slider
