// Chakra
import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
} from '@chakra-ui/react'
// Components
import Tooltip from '@UI/Tooltip/Tooltip'
// Types
import type { FC } from 'react'
import type { RegisterType } from '@Components/FormElements/types/RegisterType'
import type { InputProps } from '@chakra-ui/input/dist/input'
import type { FormElementProps } from '@Components/FormElements/types/FormElementProps'

type TextInputProps = FormElementProps & RegisterType<string> & InputProps

const TextInput: FC<TextInputProps> = ({
	id,
	title,
	register,
	error,
	disabled = undefined,
	type = 'text',
	placeholder = title,
	testId,
	tooltip,
	isRequired,
}) => {
	const htmlId = id ?? register?.name ?? 'textInput'
	return (
		<Tooltip label={tooltip} type='info'>
			<FormControl isInvalid={!!error} isDisabled={disabled}>
				<FormLabel
					htmlFor={htmlId}
					_after={{
						content: isRequired ? '"*"' : undefined,
					}}
				>
					{title}
				</FormLabel>
				<Input
					id={htmlId}
					placeholder={placeholder}
					{...(register ?? {})}
					type={type}
					w='calc(100% - 2px)'
					data-testid={testId}
				/>
				<FormErrorMessage>{error}</FormErrorMessage>
			</FormControl>
		</Tooltip>
	)
}

export default TextInput
