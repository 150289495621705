import { format, formatInTimeZone, utcToZonedTime } from 'date-fns-tz'
import enAU from 'date-fns/locale/en-AU'
import { formatMillisecondsDuration } from '@Utils/formatUtils'
import { subDays } from 'date-fns'

export const todaySiteDate = (date: string) =>
	format(new Date(date), 'yyyy-MM-dd HH:mm')

export const formatSiteTime = (timeZone: string) => {
	return formatInTimeZone(new Date(), timeZone, 'yyyy-MM-dd HH:mm', {
		locale: enAU,
	})
}

export const yesterdaySiteDate = (date: string) => {
	const parsedDate = new Date(date)
	const yesterday = subDays(parsedDate, 1)
	return format(yesterday, 'yyyy-MM-dd HH:mm')
}

// WARNING this function will always resolve to browser's timezone
export const formatDate = (date: string | number) => {
	console.warn('formatDate function defaults to browser timezone')
	const newDate = new Date(date)
	return format(newDate, 'yyyy-MM-dd HH:mm')
}

export const formatDateTz = (date: string, timeZone: string) => {
	const newDate = Date.parse(date)
	return formatInTimeZone(newDate, timeZone, 'yyyy-MM-dd HH:mm', {
		locale: enAU,
	})
}

export const formatDateWithMonth = (date: string | number) => {
	const newDate = new Date(date)
	return format(newDate, 'dd MMMM yyyy')
}

export const formatDateTime = (date: string | number) => {
	const newDate = new Date(date)
	return format(newDate, 'yyyy-MM-dd HH:mm:ss')
}

export const getDate = (date: string | number) => {
	const newDate = new Date(date)
	return format(newDate, 'yyyy-MM-dd')
}

export const getTime = (date: string | number) => {
	const newDate = new Date(date)
	return format(newDate, 'HH:mm:ss z')
}

export const getMilliseconds = (date: string | number) =>
	new Date(date).getTime()

export const getDuration = (startTime: string, endTime: string) => {
	const start = getMilliseconds(startTime)
	const end = getMilliseconds(endTime)

	return formatMillisecondsDuration(Math.abs(start - end))
}

export const getTimeZone = (date: string | number, timezone: string) => {
	const newDate = new Date(date)

	return formatInTimeZone(newDate, timezone, 'z', {
		locale: enAU,
	})
}

export const getTimeZoneDate = (date: string | number, timeZone: string) => {
	const zonedDate = utcToZonedTime(date, timeZone)

	return format(zonedDate, 'yyyy-MM-dd HH:mm', { timeZone })
}

export const getDurationSeconds = (startTime: string, endTime: string) => {
	const start = getMilliseconds(startTime)
	const end = getMilliseconds(endTime)

	return (end - start) / 1000
}
