import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// Form
import { useForm, FormProvider } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import type { TypeOf } from 'zod'

// Chakra
import { Collapse } from '@chakra-ui/react'
import Headings from '@UI/Headings/Headings'
import Field from '@Components/FormElements'
import { FormButtons } from '@Components/FormElements/FormButtons/FormButtons'
import FormHeader from '../FormHeader'

// Store
import {
	useGetRfSensorQuery,
	useCreateRfSensorMutation,
	useUpdateRfSensorMutation,
} from '@Store/rfSensors/rfSensorsApi'
import {
	useGetSiteInstallationsQuery,
	selectInstallationsOptions,
	useGetSiteInstallationQuery,
} from '@Store/sites/sitesApi'
import { useAppDispatch } from '@Store/index'
import { updateSensorPreview } from '@Store/ui/uiSlice'
import { skipToken } from '@reduxjs/toolkit/query'

// Schema
import type { RfSensor } from '@Store/types'
import {
	rfSensorFormSchema,
	ADD_RF_DEFAULT_VALUES,
} from './RfSensorForm.schema'
import FormWrapper from '@Components/FormElements/FormWrapper/FormWrapper'
import ErrorBoundary from '@/components/App/ErrorHandling/ErrorBoundary'

type RfSensorFormProps = {
	siteId: number
	installationId: number
	sensorId?: number
}

type DetectionVisualisationValue = 'sector' | 'line' | 'sector_and_line'

const RfSensorForm = ({
	siteId,
	installationId,
	sensorId,
}: RfSensorFormProps) => {
	const { t } = useTranslation('forms', { keyPrefix: 'rfSensorForm' })
	const isEditForm = !!sensorId

	const {
		isLoading,
		isError,
		refetch,
		isSuccess,
		data: rfSensor,
	} = useGetRfSensorQuery(
		siteId && sensorId ? { siteId, sensorId } : skipToken,
		{ refetchOnMountOrArgChange: true }
	)

	// Looks for props passed through from navigate() via Unregistered Sensors
	const { state } = useLocation()
	const addRfDefaultValues = {
		...ADD_RF_DEFAULT_VALUES,
		...state,
		sentry_id: installationId,
	} as RfSensor

	const defaultValues = isEditForm ? rfSensor : addRfDefaultValues

	// TODO: add standard error component
	return (
		<FormWrapper
			entity={t('entity')}
			isEditForm={isEditForm}
			isLoading={isLoading}
			isError={isError}
			isSuccess={isSuccess}
			refetch={refetch}
		>
			{defaultValues && (
				<Form
					key={sensorId}
					defaultValues={defaultValues}
					isEditForm={isEditForm}
					siteId={siteId}
					installationId={installationId}
					sensorId={sensorId}
				/>
			)}
		</FormWrapper>
	)
}

const Form = ({
	defaultValues,
	isEditForm,
	siteId,
	installationId,
	sensorId,
}: {
	defaultValues: RfSensor
	isEditForm: boolean
	siteId: number
	installationId: number
	sensorId?: number
}) => {
	const navigate = useNavigate()

	// Translations
	const { t } = useTranslation('forms', { keyPrefix: 'rfSensorForm' })

	type Schema = TypeOf<typeof rfSensorFormSchema>

	const methods = useForm<Schema>({
		resolver: zodResolver(rfSensorFormSchema),
		defaultValues,
	})
	const {
		register,
		formState: { errors, isSubmitting, isDirty },
		handleSubmit,
		setValue,
		setError,
		watch,
	} = methods

	const [createRfSensor] = useCreateRfSensorMutation()
	const [updateRfSensor] = useUpdateRfSensorMutation()

	const { installationsOptions, installationsOptionsReady } =
		useGetSiteInstallationsQuery(siteId, {
			selectFromResult: ({ isSuccess, data }) => ({
				...selectInstallationsOptions(data),
				installationsOptionsReady: isSuccess,
			}),
		})

	// Handle absolute offset values
	const { sentryDirection } = useGetSiteInstallationQuery(
		siteId && installationId ? { siteId, installationId } : skipToken,
		{
			selectFromResult: ({ data, isSuccess }) => ({
				sentryDirection: isSuccess ? data?.direction : 0,
			}),
		}
	)
	const [directionOffset, reach, model, sentryId] = watch([
		'direction_offset',
		'reach',
		'model',
		'sentry_id',
	])
	const absoluteDirection = Number((directionOffset + sentryDirection) % 360)

	const dispatch = useAppDispatch()
	useEffect(() => {
		dispatch(
			updateSensorPreview({
				sentryId,
				sensorType: 'rfSensor',
				model,
				directionOffset,
				reach,
			})
		)
		return () => {
			dispatch(updateSensorPreview(null))
		}
	}, [sentryId, model, directionOffset, reach, dispatch])

	const handleSave = async (data: Schema) => {
		if (data.model === 'rf_patrol') {
			data.show_720_detection = false
			data.show_sectors = false
			data.show_sector_as_line = false
			data.show_outside_detections = false
		}
		try {
			if (isEditForm && sensorId) {
				await updateRfSensor({ siteId, sensorId, ...data }).unwrap()
			} else {
				await createRfSensor({ siteId, ...data }).unwrap()
			}
			navigate(`/${siteId}/installations/${installationId}/rf_sensors`)
		} catch (e: unknown) {
			const errors = e as { [name in keyof Schema]: string }
			for (const field in errors) {
				setError(field as keyof Schema, {
					type: 'custom',
					message: errors[field as keyof Schema] as string,
				})
			}
		}
	}

	const handleCancel = () => navigate(`/${siteId}/installations`)

	const getDefaultDetectionVisualisation = () => {
		const { show_sectors, show_sector_as_line } = defaultValues
		if (show_sectors && show_sector_as_line) return 'sector_and_line'
		else if (show_sectors) return 'sector'
		else if (show_sector_as_line) return 'line'
		// https://github.com/chakra-ui/chakra-ui/issues/2451
		// This case should ideally never be reached, as one of the conditions
		// above should always be met. Just in case, we return an 'invalid' string as
		// opposed to undefined as chakra doesn't deal with components going from
		// uncontrolled to controlled.
		else return 'unknown'
	}

	const [detectionVisualisation, setDetectionVisualisation] = useState(
		getDefaultDetectionVisualisation()
	)

	const setRadioValue = (
		name: 'show_sectors' | 'show_sector_as_line',
		value: boolean
	) => setValue(name, value, { shouldDirty: true })

	const installationDefaultValue = installationsOptions.find(
		(option) =>
			option.value === (isEditForm ? defaultValues.sentry_id : installationId)
	)

	return (
		<>
			<FormHeader
				title={
					isEditForm ? t('headings.sensorSettings') : t('headings.addSensor')
				}
			/>
			<ErrorBoundary>
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(handleSave)}>
						<Field.Divider title={t('headings.generalParameters')} />
						{installationsOptionsReady && (
							<Field.Select
								title={t('installation')}
								defaultValue={installationDefaultValue}
								register={register('sentry_id')}
								options={installationsOptions}
								error={errors?.sentry_id?.message}
							/>
						)}
						<Field.TextInput
							title={t('name')}
							register={register('name')}
							error={errors?.name?.message}
							testId='name'
						/>
						<Field.Select
							title={t('model')}
							register={register('model')}
							options={t('modelOptions', { returnObjects: true })}
							disabled={isEditForm}
							tooltip={isEditForm ? t('tooltips.model_disabled') : ''}
							error={errors?.model?.message}
						/>
						<Field.TextInput
							title={t('serialNumber')}
							register={register('serial_number')}
							disabled={isEditForm}
							tooltip={isEditForm ? t('tooltips.serial_number_disabled') : ''}
							error={errors?.serial_number?.message}
							testId='serial-number'
						/>
						<Field.UnitsSlider
							isAbsolute
							units='deg'
							customLabel={absoluteDirection}
							title={t('directionOffset')}
							min={0}
							max={359.99}
							step={0.1}
							register={register('direction_offset', { valueAsNumber: true })}
							error={errors?.direction_offset?.message}
							testId='direction-offset'
						/>
						<Field.Slider
							units='m'
							title={t('displayRange')}
							min={500}
							max={8000}
							register={register('reach', { valueAsNumber: true })}
							error={errors?.reach?.message}
							testId='display-range'
						/>
						<Collapse in={model !== 'rf_patrol'}>
							<Field.Divider title={t('headings.advancedParameters')} />
							<Field.Switch
								title={t('showOutsideDetections')}
								tooltip={t('tooltips.showOutsideDetections')}
								register={register('show_outside_detections')}
								error={errors?.show_outside_detections?.message}
								testId='show-outside-detections'
							/>
							<Field.Switch
								title={t('showUnknownAoa')}
								tooltip={t('tooltips.showUnknownAoa')}
								register={register('show_720_detection')}
								error={errors?.show_720_detection?.message}
								testId='show-unknown-aoa'
							/>
							<Headings.SectionSubheading
								title={t('headings.detectionVisualisation')}
							/>
							<Field.Radio
								id='detectionVisualisation'
								options={t('detectionVisualisationOptions', {
									returnObjects: true,
								})}
								error={errors?.show_sectors?.message}
								value={detectionVisualisation}
								onChange={(value) => {
									setDetectionVisualisation(
										value as DetectionVisualisationValue
									)
									if (value === 'sector') {
										setRadioValue('show_sectors', true)
										setRadioValue('show_sector_as_line', false)
									} else if (value === 'line') {
										setRadioValue('show_sectors', false)
										setRadioValue('show_sector_as_line', true)
									} else if (value === 'sector_and_line') {
										setRadioValue('show_sectors', true)
										setRadioValue('show_sector_as_line', true)
									}
								}}
							/>
						</Collapse>
						<FormButtons
							isSubmitting={isSubmitting}
							isDirty={isDirty}
							handleCancel={handleCancel}
						/>
					</form>
				</FormProvider>
			</ErrorBoundary>
		</>
	)
}

export default RfSensorForm
