// Translations
import i18n from '@/i18n'
// Table
import { createColumnHelper } from '@tanstack/react-table'
import type {
	DisruptionColumnType,
	DisruptionRow,
} from '@Pages/Analytics/DisruptionsSummary/DisruptionDetailsTable/columnDefinitions'

type ColumnInfo = {
	header: string
	type: DisruptionColumnType
	dataTransform?: (value: string & number) => void
}

const columnHelper = createColumnHelper<DisruptionRow>()

const UseDisruptionColumns = () => {
	const t = (key: string) => i18n.t(`pages:analytics.table.${key}`)

	const columns: ColumnInfo[] = [
		{
			header: t('id'),
			type: 'id',
		},
		{
			header: t('activatedAt'),
			type: 'activated_at',
		},
		{
			header: t('deactivatedAt'),
			type: 'deactivated_at',
		},
		{
			header: t('effector'),
			type: 'disruptor',
		},
		{
			header: t('serialNumber'),
			type: 'serial_number',
		},
		{
			header: t('activatedBy'),
			type: 'activated_by',
		},
		{
			header: t('deactivatedBy'),
			type: 'deactivated_by',
		},
		{
			header: t('duration'),
			type: 'duration',
		},
	]

	return columns.map((column) => {
		return columnHelper.accessor(column.type as keyof DisruptionRow, {
			header: column.header,
			cell: (info) =>
				column.dataTransform
					? column.dataTransform(info.getValue() as string & number & boolean)
					: info.getValue(),
		})
	})
}

export default UseDisruptionColumns
