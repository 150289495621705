// React Router
import Router from './Router'

// Translations
import I18Provider from '@Components/App/I18nProvider'

// Chakra
import { CSSReset, ChakraProvider } from '@chakra-ui/react'
import Theme from '@Theme/DRO'

// App HOC
const App = () => {
	return (
		<I18Provider>
			<ChakraProvider theme={Theme}>
				<CSSReset />
				<Router />
			</ChakraProvider>
		</I18Provider>
	)
}

// Export
export default App
