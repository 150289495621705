import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useParams, useNavigate } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import type { TypeOf } from 'zod'
import { Box, CheckboxGroup, Flex, Spacer, Text } from '@chakra-ui/react'

import Headings from '@UI/Headings/Headings'
import Field from '@Components/FormElements'
import { FormButtons } from '@Components/FormElements/FormButtons/FormButtons'

import { useAppSelector } from '@Store/index'
import {
	selectUserId,
	selectUserSettings,
	selectUserZoneSettings,
} from '@Store/user/userSlice'
import { useUpdateUserSettingsMutation } from '@Store/user/usersApi'
import {
	useGetSiteExportQuery,
	selectConcentricMarkerOptions,
	selectAvailableSectors,
} from '@Store/sites/sitesApi'
import useAuth from '@Hooks/useAuth'

import {
	displaySettingsSchema,
	displaySettingsDefaultValues,
} from './DisplaySettings.schema'

import type { RadiatingCircle, ZoneSettings } from '@Store/types/user'
import type { MultiSelectOption } from '@Components/_UI/Select/MultiSelect'
import type { FormSelectValueType } from '@Components/FormElements/Select/Select'
import FetchError from '@UI/FetchError/FetchError'

const DisplaySettings = () => {
	const { t } = useTranslation('forms', {
		keyPrefix: 'mapsZonesForm.displaySettings',
	})
	const userId = useAppSelector(selectUserId)
	const zoneSettings = useAppSelector(selectUserZoneSettings)

	const defaultValues =
		zoneSettings ?? (displaySettingsDefaultValues as ZoneSettings)
	const navigate = useNavigate()

	return (
		<>
			{defaultValues ? (
				<Form key={userId} defaultValues={defaultValues} />
			) : (
				<FetchError entity={t('entity')} refetch={() => navigate(0)} />
			)}
		</>
	)
}

const Form = ({ defaultValues }: { defaultValues: ZoneSettings }) => {
	const { t } = useTranslation('forms', {
		keyPrefix: 'mapsZonesForm.displaySettings',
	})

	type Schema = TypeOf<typeof displaySettingsSchema>

	const methods = useForm<Schema>({
		resolver: zodResolver(displaySettingsSchema),
		defaultValues,
	})
	const {
		register,
		formState: { errors, isSubmitting, isDirty },
		handleSubmit,
		setValue,
		reset,
	} = methods

	const { siteId } = useParams()
	const userId = useAppSelector(selectUserId)
	const userSettings = useAppSelector(selectUserSettings)
	const { isAdmin } = useAuth()

	const [updateUserSettings] = useUpdateUserSettingsMutation()
	const navigate = useNavigate()

	const zonesDisplayOptions = (
		t('zonesDisplayOptions', {
			returnObjects: true,
		}) as MultiSelectOption[]
	).filter((option) => option.value !== 'origin' || isAdmin)

	const { availableSectors, availableSectorsReady, concentricMarkerOptions } =
		useGetSiteExportQuery(Number(siteId), {
			selectFromResult: ({ isSuccess, data }) => ({
				availableSectors: selectAvailableSectors(data),
				availableSectorsReady: isSuccess,
				concentricMarkerOptions: selectConcentricMarkerOptions(data),
			}),
		})

	const concentricMarkerDefaultValue =
		concentricMarkerOptions.find(
			(option) =>
				option.value.name ===
				defaultValues.radiatingCircle?.[Number(siteId)]?.name
		) ?? concentricMarkerOptions[0]

	useEffect(() => {
		if (defaultValues.radiatingCircle === null) {
			const concentricMarker = {
				[Number(siteId)]: {
					...concentricMarkerDefaultValue?.value,
				},
			}
			setValue('radiatingCircle', concentricMarker)
		}
	}, [
		concentricMarkerDefaultValue,
		defaultValues.radiatingCircle,
		siteId,
		setValue,
	])

	const handleSave = async (data: Schema) => {
		try {
			await updateUserSettings({
				userId,
				settings: {
					...userSettings,
					zoneSettings: {
						...data,
					},
				},
			})
				.unwrap()
				.then((user) => {
					reset(user.settings.zoneSettings)
				})
		} catch (error) {
			console.error('Update display settings error', error)
		}
	}

	const zoneDisplayDefaultValues = zonesDisplayOptions.filter((option) =>
		defaultValues.visibleZoneTypes?.includes(option.value as string)
	)

	const handleConcentricMarkerChange = (value: RadiatingCircle) => {
		const concentricMarkers = { ...defaultValues.radiatingCircle }

		// This condition deals with the old data structure and can be phased out with time
		if (concentricMarkers.coordinates || concentricMarkers.name) {
			const newConcentricMarker = {
				[Number(siteId)]: {
					name: value.name,
					coordinates: value.coordinates,
				},
			}
			setValue('radiatingCircle', newConcentricMarker, { shouldDirty: true })
		}
		concentricMarkers[Number(siteId)] = value
		setValue('radiatingCircle', concentricMarkers, { shouldDirty: true })
	}

	const siteDisplayedSectors =
		defaultValues.displayedSectors?.[Number(siteId)] ?? []

	const displayedSectorsDefaultValue = siteDisplayedSectors.map(
		(sector) => sector.value
	)

	const handleDeviceDisplayChange = (devices: string[]) => {
		const selectedValues = availableSectors.filter((sector) =>
			devices.includes(sector.value)
		)
		const deviceDisplayValues = { ...defaultValues.displayedSectors }
		deviceDisplayValues[Number(siteId)] = selectedValues

		setValue('displayedSectors', deviceDisplayValues, { shouldDirty: true })
	}

	const handleCancel = () => navigate(`/${siteId}/installations`)

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(handleSave)}>
				<Flex direction='column' gap='16px'>
					<Spacer />
					<Box>
						<Headings.FormHeading title={t('headings.generalDisplay')} />
						<Flex direction='column'>
							{availableSectorsReady && (
								<Field.Select
									title={t('markerCenter')}
									placeholder={t('markerCenterPlaceholder')}
									register={register('radiatingCircle')}
									options={concentricMarkerOptions}
									defaultValue={concentricMarkerDefaultValue}
									onCustomChange={(e: FormSelectValueType) =>
										handleConcentricMarkerChange(e.value as RadiatingCircle)
									}
									styles={{ marginTop: '0.5rem' }}
									error={errors?.radiatingCircle?.message as string}
									testId='concentric-marker'
								/>
							)}
							<Field.Switch
								title={t('mapMarkers')}
								register={register('showMarkers')}
								styles={{ padding: '0.2rem 0.3rem' }}
								testId='show-markers'
							/>
						</Flex>
					</Box>

					<Box>
						<Headings.FormHeading title={t('headings.zonesDisplay')} />
						<Field.MultiSelect
							title={t('zonesDisplay')}
							register={register('visibleZoneTypes')}
							options={zonesDisplayOptions}
							defaultValue={zoneDisplayDefaultValues}
							styles={{ marginTop: '0.5rem' }}
							testId='visible-zones-types'
							placeholder={t('zonesDisplayPlaceholder')}
						/>
					</Box>

					<Box>
						<Headings.FormHeading title={t('headings.deviceDisplay')} />
						<Text fontSize='sm' paddingTop='8px'>
							{t('deviceSectors')}
						</Text>
						<CheckboxGroup
							defaultValue={displayedSectorsDefaultValue}
							onChange={handleDeviceDisplayChange}
						>
							{availableSectors.map(({ name, value }) => (
								<Field.Checkbox
									id={name}
									key={name}
									title={name}
									value={value}
									testId={`${value}-device-sector`}
								/>
							))}
						</CheckboxGroup>
					</Box>
				</Flex>

				<FormButtons
					isSubmitting={isSubmitting}
					isDirty={isDirty}
					handleCancel={handleCancel}
					showCancelButton={false}
				/>
			</form>
		</FormProvider>
	)
}

export default DisplaySettings
