import { type ReactNode, useState } from 'react'
// Chakra
import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	InputGroup,
	InputRightElement,
	type PlacementWithLogical,
} from '@chakra-ui/react'
import { IoEye, IoEyeOff } from 'react-icons/io5'
// Components
import IconButton from '@UI/IconButton/IconButton'
import Tooltip from '@UI/Tooltip/Tooltip'
// Types
import type { RegisterType } from '@Components/FormElements/types/RegisterType'
import type { InputProps } from '@chakra-ui/input/dist/input'
import type { FormElementProps } from '@Components/FormElements/types/FormElementProps'

type TextInputProps = FormElementProps &
	RegisterType<string> &
	InputProps & {
		children?: ReactNode
		tooltipPlacement?: PlacementWithLogical
	}

const PasswordInput = ({
	id,
	title,
	register,
	error,
	placeholder = title,
	tooltip,
	tooltipPlacement = 'top-start',
	isRequired = false,
	disabled = undefined,
	testId = 'password-input',
	...rest
}: TextInputProps) => {
	const htmlId = id ?? register?.name ?? 'passwordInput'

	const [showPassword, setShowPassword] = useState(false)

	const handleShowPassword = () => setShowPassword((prev) => !prev)

	return (
		<Tooltip label={tooltip} placement={tooltipPlacement} type='info'>
			<FormControl isInvalid={!!error} isDisabled={disabled}>
				<FormLabel
					htmlFor={htmlId}
					_after={{
						content: isRequired ? '"*"' : undefined,
					}}
					display='flex'
					gap={1}
					alignItems='center'
				>
					{title}
				</FormLabel>
				<InputGroup>
					<Input
						id={htmlId}
						placeholder={placeholder}
						{...(register ?? {})}
						type={showPassword ? 'text' : 'password'}
						w='calc(100% - 2px)'
						data-testid={testId}
						isRequired={isRequired}
						{...rest}
					/>
					<InputRightElement>
						<IconButton
							testId='show-hide-password'
							icon={showPassword ? <IoEyeOff size={16} /> : <IoEye size={16} />}
							aria-label='show-hide-password'
							onClick={handleShowPassword}
							variant='icon'
							isDisabled={disabled}
							marginInlineEnd={2}
						/>
					</InputRightElement>
				</InputGroup>
				<FormErrorMessage>{error}</FormErrorMessage>
			</FormControl>
		</Tooltip>
	)
}

export default PasswordInput
