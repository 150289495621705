// React
import { useTranslation } from 'react-i18next'
import { nanoid } from '@reduxjs/toolkit'
// Chakra
import { Box, Flex } from '@chakra-ui/react'
import { MdDelete } from 'react-icons/md'
// Components
import IconButton from '@UI/IconButton/IconButton'
// Store
import { useGetSitesListQuery } from '@Store/sites/sitesApi'
import type { UserSiteAssociation } from '@Store/types'

const UserSiteListAssociation = ({
	userSiteList,
	handleDelete,
}: {
	userSiteList: UserSiteAssociation[]
	handleDelete: (id: number) => void
}) => {
	const { t } = useTranslation('forms', { keyPrefix: 'userForm' })
	const { data: sites } = useGetSitesListQuery()
	const roles = Array(
		t('selects.siteUserRoleOptions', {
			returnObjects: true,
		})
	).flat() as { label: string; value: string }[]

	return (
		<>
			{userSiteList?.map(({ site_id, role }) => {
				return (
					<Flex key={nanoid()}>
						<Box flex={1} fontSize='sm' px={2}>
							{sites?.find((site) => site.id === site_id)?.name}
						</Box>
						<Box flex={1} fontSize='sm' px={2}>
							{roles?.find((r) => r?.value === role)?.label}
						</Box>
						<Box w='78px' textAlign='center'>
							<IconButton
								testId={`delete-site-${site_id}`}
								aria-label='delete'
								icon={<MdDelete />}
								onClick={() => handleDelete(site_id)}
							/>
						</Box>
					</Flex>
				)
			})}
		</>
	)
}

export default UserSiteListAssociation
