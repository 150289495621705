// React
import { useTranslation } from 'react-i18next'
// Chakra
import { IconButton, useDisclosure } from '@chakra-ui/react'
import { MdDelete } from 'react-icons/md'
import DeleteModal from '@UI/Modals/DeleteModal'
// Store
import { useDeleteCotMutation } from '@Store/cots/cotsApi'
import type { Cot } from '@Store/types/cot'
import type { Site } from '@Store/types'

const CotDeleteModal = ({
	siteId,
	cotId,
}: {
	siteId: Site['id']
	cotId: Cot['id']
}) => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	// Translations
	const { t } = useTranslation('forms', { keyPrefix: 'cotForm' })

	const [deleteCot] = useDeleteCotMutation()

	const handleDelete = async (cotId: number) => {
		try {
			await deleteCot({ cotId, siteId }).unwrap()
			onClose()
		} catch (error) {
			console.error('unhandled layer selector delete error', error)
		}
	}

	return (
		<>
			<IconButton
				variant='ghost'
				colorScheme='white'
				aria-label='delete'
				icon={<MdDelete />}
				onClick={onOpen}
			/>
			<DeleteModal
				isOpen={isOpen}
				handleDelete={() => handleDelete(cotId)}
				onClose={onClose}
				name={cotId.toString()}
				headerText={t('modals.deleteHeader')}
				fromText={t('modals.deleteText')}
			/>
		</>
	)
}
export default CotDeleteModal
