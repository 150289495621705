import { useParams } from 'react-router-dom'
import {
	useMoveCameraMutation,
	useMoveCameraStopMutation,
} from '@Store/cameras/camerasApi'
import { Flex, IconButton } from '@chakra-ui/react'
import { MdAdd, MdOutlineRemove } from 'react-icons/md'

const JoystickControl = ({ cameraId }: { cameraId: number }) => {
	const { siteId: siteIdParam } = useParams()
	const [moveCamera] = useMoveCameraMutation()
	const [moveCameraStop] = useMoveCameraStopMutation()

	const handleZoomIn = () => {
		handleZoom(1)
	}

	const handleZoomOut = () => {
		handleZoom(-1)
	}

	const handleZoom = (z: -1 | 1) => {
		moveCamera({
			siteId: Number(siteIdParam),
			cameraId: cameraId,
			z: z,
		})
	}
	const handleStop = () => {
		moveCameraStop({
			siteId: Number(siteIdParam),
			cameraId: cameraId,
		})
	}

	return (
		<Flex direction='column'>
			<IconButton
				icon={<MdAdd size={20} />}
				variant='unstyled'
				onPointerUp={handleStop}
				onPointerOut={handleStop}
				onPointerDown={handleZoomIn}
				aria-label='Zoom In'
				bgColor='#212121'
				borderBlockEnd='1px solid #7a7a7a'
				borderBottomRadius={0}
				sx={{ svg: { margin: '0 auto' } }}
			/>
			<IconButton
				icon={<MdOutlineRemove size={20} />}
				variant='unstyled'
				onPointerUp={handleStop}
				onPointerDown={handleZoomOut}
				aria-label='Zoom Out'
				bgColor='#212121'
				borderTopRadius={0}
				sx={{ svg: { margin: '0 auto' } }}
			/>
		</Flex>
	)
}

export default JoystickControl
