// React
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// Redux
import type { NotificationRecipient } from '@Store/types'

// Chakra
import { IconButton, useDisclosure } from '@chakra-ui/react'
import { MdEdit } from 'react-icons/md'

import { AlertRecipientFormModal } from '@Forms/AlertRecipientForm/AlertRecipientForm'
import AlertRecipientDelete from '@Pages/AlertSettings/AlertRecipientsTable/AlertRecipientDelete'
import AlertRecipientSend from '@Pages/AlertSettings/AlertRecipientsTable/AlertRecipientSend'

type AlertRecipientActionsProps = {
	recipient: NotificationRecipient
}

const AlertRecipientActions = ({ recipient }: AlertRecipientActionsProps) => {
	const { siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)
	const { t } = useTranslation('pages', {
		keyPrefix: 'alertSettings.alertRecipientsTable',
	})

	const { isOpen, onOpen, onClose } = useDisclosure()

	return (
		<>
			<AlertRecipientSend recipient={recipient} siteId={siteId} />
			<IconButton
				onClick={onOpen}
				icon={<MdEdit />}
				aria-label={t('actions.editRecipient')}
				title={t('actions.editRecipient')}
				variant='actions'
				data-testid={`edit-recipient-${recipient.name}`}
			/>
			<AlertRecipientDelete
				name={recipient.name}
				recipientId={recipient.id}
				siteId={siteId}
				testId={`delete-recipient-${recipient.name}`}
			/>
			<AlertRecipientFormModal
				siteId={siteId}
				recipientId={recipient.id}
				onClose={onClose}
				isOpen={isOpen}
			/>
		</>
	)
}

export default AlertRecipientActions
