import { Box } from '@chakra-ui/react'

type Props = {
	value: number
	thumbIndex: number
	thumbProps: any
	onKeyDownStepBy: (
		e: React.KeyboardEvent<HTMLDivElement>,
		thumbIndex: number
	) => void
	bgColor: string
	disabled?: boolean
}

const Thumb = ({
	bgColor,
	thumbIndex,
	onKeyDownStepBy,
	thumbProps,
	disabled,
}: Props) => {
	return (
		<Box
			key={thumbIndex}
			onKeyDown={(e) => onKeyDownStepBy(e, thumbIndex)}
			top='1%'
			boxSize='var(--c2-sizes-3-5)'
			bgColor={bgColor}
			borderRadius='full'
			_focusVisible={{
				outline: 'none',
			}}
			{...thumbProps}
			opacity={disabled ? 0.4 : undefined}
		/>
	)
}
export default Thumb
