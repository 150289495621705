import { z } from 'zod'
import globalSchema from '@/components/Forms/BaseForm/BaseForm.schema'

const pickedFromGlobal = globalSchema.pick({
	name: true,
})

const radarMaskFormFields = z.object({
	azimuth_max: z.number().min(-60).max(60),
	azimuth_min: z.number().min(-60).max(60),
	elevation_max: z.number().min(-40).max(40),
	elevation_min: z.number().min(-40).max(40),
	hard_mask: z.boolean(),
	range_min: z.number().min(20),
	range_max: z.number().max(10000),
	rcs_max: z.number().min(-50).max(100),
	rcs_min: z.number().min(-50).max(100),
	max_agl: z.number().min(0).max(1000),
	coordinates: z.array(z.array(z.number())).optional().nullable(),
})

export type RadarMaskSchema = z.infer<typeof radarMaskFormSchema>

export const radarMaskFormSchema = radarMaskFormFields.merge(pickedFromGlobal)

export type DefaultValues = z.infer<typeof radarMaskFormSchema>

export const ADD_RADAR_MASK_DEFAULT_VALUES: DefaultValues = {
	name: '',
	azimuth_max: 5,
	azimuth_min: -5,
	elevation_max: 0,
	elevation_min: 0,
	range_min: 20,
	range_max: 3000,
	rcs_min: -50,
	rcs_max: 100,
	max_agl: 100,
	hard_mask: false,
	coordinates: [],
}
