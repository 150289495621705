import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
	useGetSiteLiveQuery,
	selectSiteUnregisteredSensors,
	selectSiteInstallationsFirstId,
} from '@Store/sites/sitesWsApi'

import type { UnregisteredSensor } from '@Store/types/index'
import { Button, Icon, Flex, Text, Grid } from '@chakra-ui/react'
import { MdError } from 'react-icons/md'

const UnregisteredSensors = ({ siteId }: { siteId: number }) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'siteInstallations.headings',
	})
	const { unregisteredSensors, defaultInstallationId } = useGetSiteLiveQuery(
		siteId,
		{
			selectFromResult: ({ data }) => ({
				unregisteredSensors: selectSiteUnregisteredSensors(data),
				defaultInstallationId: selectSiteInstallationsFirstId(data),
			}),
		}
	)
	const navigate = useNavigate()

	const handleCreateDevice = (sensor: UnregisteredSensor) => {
		const { type } = sensor
		switch (type) {
			case 'rf_patrol':
				navigate(
					`/${siteId}/installation/${defaultInstallationId}/rf_sensors/add`,
					{ state: { serial_number: sensor.serial, model: 'rf_patrol' } }
				)
				break
			case 'rfone':
				navigate(
					`/${siteId}/installation/${defaultInstallationId}/rf_sensors/add`,
					{ state: { serial_number: sensor.serial, model: 'rf_one' } }
				)
				break
			case 'dsx_mk1':
				navigate(
					`/${siteId}/installation/${defaultInstallationId}/rf_sensors_dsx/add`,
					{
						state: { serial_number: sensor.serial, model: 'dsx_direct_no_jam' },
					}
				)
				break
			case 'dsx_mk2':
				navigate(
					`/${siteId}/installation/${defaultInstallationId}/rf_sensors_dsx/add`,
					{ state: { serial_number: sensor.serial, model: 'dsx_mk2_no_jam' } }
				)
				break
			case 'radarzero':
				navigate(`/${siteId}/installation/${defaultInstallationId}/radar/add`, {
					state: { serial_number: sensor.serial, radar_type: 'radar_zero' },
				})
				break
			case 'echo_shield':
				navigate(`/${siteId}/installation/${defaultInstallationId}/radar/add`, {
					state: { serial_number: sensor.serial, radar_type: 'echo_shield' },
				})
				break
			case 'compass':
				navigate(
					`/${siteId}/installation/${defaultInstallationId}/gpscompass/add`,
					{ state: { serial_number: sensor.serial } }
				)
				break
			case 'cannonmk2':
				navigate(
					`/${siteId}/installation/${defaultInstallationId}/disruptor/add`,
					{ state: { serial_number: sensor.serial } }
				)
				break
			case 'camera':
			case 'droneoptid':
				navigate(
					`/${siteId}/installation/${defaultInstallationId}/camera/add`,
					{ state: { serial_number: sensor.serial } }
				)
				break
			default:
				console.error(['TODO: unknown sensor type', sensor])
		}
	}

	return (
		unregisteredSensors.length > 0 && (
			<Flex direction='column' marginBlockEnd={4}>
				<Flex
					bgColor='input_bg'
					paddingInline={2}
					paddingBlockStart={2}
					paddingBlockEnd={2}
					marginBlockEnd={3}
				>
					<Icon
						as={MdError}
						color='error'
						marginBlockStart={1}
						marginInlineEnd={1}
					/>
					<Flex direction='column'>
						<Text fontSize='sm' fontWeight='bold'>
							{t('unregisteredDevice')}
						</Text>
						<Text fontSize='sm' color='label_color'>
							{t('clickToAdd')}
						</Text>
					</Flex>
				</Flex>
				<Grid templateColumns='repeat(3, 1fr)' gap={2}>
					{unregisteredSensors.map((sensor, index) => (
						<Button
							key={index}
							variant='outline'
							display='block'
							paddingBlock={1}
							boxSize='full'
							lineHeight='1.5'
							onClick={() => handleCreateDevice(sensor)}
							disabled={!defaultInstallationId}
						>
							<Text fontSize='sm'>{sensor.type}</Text>
							<Text fontSize='xs'>{sensor.serial}</Text>
							{sensor.ip && <Text fontSize='xs'>({sensor.ip})</Text>}
						</Button>
					))}
				</Grid>
			</Flex>
		)
	)
}

export default UnregisteredSensors
