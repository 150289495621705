import { Trans, useTranslation } from 'react-i18next'
import Accordion from '@Components/FormElements/Accordion/Accordion'
import {
	Box,
	ListItem,
	OrderedList,
	Step,
	StepDescription,
	StepIndicator,
	StepNumber,
	Stepper,
	StepSeparator,
	StepStatus,
	StepTitle,
	Text,
} from '@chakra-ui/react'
import AlertHelper from '@UI/AlertHelper/AlertHelper'

const InstructionsStepper = ({ type }: { type: 'online' | 'offline' }) => {
	const { t } = useTranslation('forms', { keyPrefix: 'mapsZonesForm' })
	const isOffline = type === 'offline'

	const steps = [
		{ title: t('mapLayersForm.step1Title') },
		{ title: t('mapLayersForm.step2Title') },
		{
			title: (
				<Trans
					t={t}
					i18nKey={
						isOffline
							? 'mapLayersForm.step3TitleOffline'
							: 'mapLayersForm.step3Title'
					}
					components={{ linked: <Text color='primary' as='span' /> }}
				/>
			),
			description: !isOffline && (
				<OrderedList spacing={3}>
					<Trans
						t={t}
						i18nKey='mapLayersForm.step3Description'
						components={{
							list1: <ListItem my={4} />,
							list2: <ListItem my={4} />,
						}}
					/>
				</OrderedList>
			),
		},
	]

	return (
		<Box mt={4} p={4} bg='input_bg'>
			{/*Index is NaN because Stepper expecting number as active step, we need to show all active steps*/}
			<Stepper
				orientation='vertical'
				gap='0'
				index={NaN}
				variant='instructions'
			>
				{steps.map((step, index) => (
					<Step key={index}>
						<StepIndicator>
							<StepStatus active={<StepNumber />} />
						</StepIndicator>

						<Box w='fit-content'>
							<StepTitle>{step.title}</StepTitle>
							<StepDescription as='div'>{step.description}</StepDescription>
						</Box>

						<StepSeparator />
					</Step>
				))}
			</Stepper>
			<AlertHelper title={t('mapLayersForm.stepInfo')} />
		</Box>
	)
}

const Instructions = ({ type }: { type: 'online' | 'offline' }) => {
	const { t } = useTranslation('forms', { keyPrefix: 'mapsZonesForm' })
	return (
		<>
			<Accordion.Container>
				<Accordion.Item title={t('mapLayersForm.instructions')} isFocusable>
					<InstructionsStepper type={type} />
				</Accordion.Item>
			</Accordion.Container>
		</>
	)
}

export default Instructions
