//
// Note: Is it worth migrating these to convert-units 'Angle' system?

export const convertDegreeToRad = (degrees: number) => degrees * (Math.PI / 180)

export const convertRadToDegree = (radian: number) => radian * (180 / Math.PI)

export const convertHzToGHz = (hz: number) => hz / 1e9
export const convertGHzToHz = (hz: number) => hz * 1e9

export const convertDegreesToCardinal = (angle: number) => {
	const index = Math.round(angle / 45) % 8
	return ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'][index]
}

export const convertDegreesToCardinalWithPointer = (angle: number) => {
	const index = Math.round(angle / 45) % 8
	return ['↑ N', '↗ NE', '→ E', '↘ SE', '↓ S', '↙ SW', '← W', '↖ NW'][index]
}

export const convertDegreesToCardinal16 = (angle: number) => {
	const index = Math.round(angle / 22.5) % 16
	return [
		'N',
		'NNE',
		'NE',
		'ENE',
		'E',
		'ESE',
		'SE',
		'SSE',
		'S',
		'SSW',
		'SW',
		'WSW',
		'W',
		'WNW',
		'NW',
		'NNW',
	][index]
}

export const convertRadtToCardinal = (radian: number) =>
	convertDegreesToCardinal(convertRadToDegree(radian))

export const convertRadToCardinalWithPointer = (radian: number) =>
	convertDegreesToCardinalWithPointer(convertRadToDegree(radian))
