import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

// Form
import { useForm, FormProvider } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import type { TypeOf } from 'zod'

import { useAppSelector } from '@Store/index'
import {
	selectZoneCoordinates,
	selectIsZoneCoordinatesDirty,
} from '@Store/ui/uiSlice'
import useAuth from '@Hooks/useAuth'

import Field from '@Components/FormElements'
import { FormButtons } from '@Components/FormElements/FormButtons/FormButtons'
import Headings from '@Components/_UI/Headings/Headings'
import FormHeader from '@Components/Forms/FormHeader'
import FormWrapper from '@Components/FormElements/FormWrapper/FormWrapper'
import type { FormSelectValueType } from '@Components/FormElements/Select/Select'
import ErrorBoundary from '@Components/App/ErrorHandling/ErrorBoundary'

import {
	useGetZoneQuery,
	useCreateZoneMutation,
	useUpdateZoneMutation,
} from '@Store/zones/zonesApi'
import { skipToken } from '@reduxjs/toolkit/query'

import type { Zone } from '@Store/types'
import { zoneFormSchema, ADD_ZONE_DEFAULT_VALUES } from './ZoneForm.schema'

const ZoneForm = ({ siteId, zoneId }: { siteId: number; zoneId?: number }) => {
	const { t } = useTranslation('forms', { keyPrefix: 'zoneForm' })

	const isEditForm = !!zoneId

	const {
		isLoading,
		isError,
		isSuccess,
		refetch,
		data: zone,
	} = useGetZoneQuery(zoneId ? { siteId, zoneId } : skipToken, {
		refetchOnMountOrArgChange: true,
	})

	const defaultValues = isEditForm ? zone : (ADD_ZONE_DEFAULT_VALUES as Zone)

	return (
		<FormWrapper
			entity={t('entity')}
			isEditForm={isEditForm}
			isLoading={isLoading}
			isError={isError}
			isSuccess={isSuccess}
			refetch={refetch}
		>
			{defaultValues && (
				<Form
					isEditForm={isEditForm}
					defaultValues={defaultValues}
					siteId={siteId}
					zoneId={zoneId}
				/>
			)}
		</FormWrapper>
	)
}

const Form = ({
	isEditForm,
	defaultValues,
	siteId,
	zoneId,
}: {
	isEditForm: boolean
	defaultValues: Zone
	siteId: number
	zoneId?: number
}) => {
	const { isAdmin } = useAuth()
	const navigate = useNavigate()
	const { t } = useTranslation('forms', { keyPrefix: 'zoneForm' })

	type Schema = TypeOf<typeof zoneFormSchema>

	const methods = useForm<Schema>({
		resolver: zodResolver(zoneFormSchema),
		defaultValues,
	})
	const {
		register,
		formState: { errors, isSubmitting, isDirty },
		handleSubmit,
	} = methods

	const zoneTypeOptions = (
		t('zoneTypeOptions', {
			returnObjects: true,
		}) as FormSelectValueType[]
	).filter((option) => option.value !== 'origin' || isAdmin)

	const [createZone] = useCreateZoneMutation()
	const [updateZone] = useUpdateZoneMutation()

	const zoneCoordinates = useAppSelector(selectZoneCoordinates)
	const isZonesCoordinatesDirty = useAppSelector(selectIsZoneCoordinatesDirty)

	const handleSave = async (data: Schema) => {
		const zone = {
			...data,
			coordinates: zoneCoordinates,
		}
		try {
			if (isEditForm && zoneId) {
				await updateZone({ siteId, zoneId, ...zone }).unwrap()
			} else {
				await createZone({ siteId, ...zone }).unwrap()
			}
			navigate(`/${siteId}/zones`)
		} catch (error) {
			console.error('Zone mask form error', error)
		}
	}

	const handleCancel = () => navigate(`/${siteId}/zones`)

	return (
		<>
			<FormHeader title='Maps and Zones' />
			<ErrorBoundary>
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(handleSave)}>
						<Headings.FormHeading
							title={isEditForm ? 'Zone Settings' : 'Add Zone'}
							textTransform='uppercase'
						/>
						<Field.TextInput
							title={t('name')}
							register={register('name')}
							error={errors?.name?.message}
							testId='name'
						/>
						<Field.Select
							title={t('zoneType')}
							register={register('zone_type')}
							options={zoneTypeOptions}
							error={errors?.zone_type?.message}
							testId='zone-type'
						/>
						<Field.TextArea
							title={t('note')}
							register={register('note')}
							error={errors?.note?.message}
							testId='note'
						/>
						<FormButtons
							isSubmitting={isSubmitting}
							isDirty={isDirty || isZonesCoordinatesDirty}
							handleCancel={handleCancel}
						/>
					</form>
				</FormProvider>
			</ErrorBoundary>
		</>
	)
}

export default ZoneForm
