import { useTranslation } from 'react-i18next'
import { Box, Center } from '@chakra-ui/react'

import FetchError from '@UI/FetchError/FetchError'
import Skeleton from '@UI/Skeleton/Skeleton'

import { useGetTrackSummaryQuery } from '@Store/analytics/analyticsApi'

import NumberDisplay from '../NumberDisplay'
import { useEventFilter } from './EventDetailsTable/EventFilterContext'
import { formatSecondsDuration } from '@Utils/formatUtils'

export const DEFAULT_PARAMS = {
	page: 0,
	perPage: 10,
	sort_by_start_time: 'DESC' as const,
}

type EventDetailsProps = {
	siteId: number
	startTime: string
	endTime: string
}

const EventDetails = ({ siteId, startTime, endTime }: EventDetailsProps) => {
	const { t } = useTranslation('pages', {
		keyPrefix: 'analytics.trackSummary',
	})

	const { filterParams } = useEventFilter()

	const { isSuccess, isLoading, isError, isFetching, refetch, totalCount } =
		useGetTrackSummaryQuery(
			{
				siteId: Number(siteId),
				format: 'table',
				startTime: startTime,
				endTime: endTime,
				...DEFAULT_PARAMS,
				...filterParams,
			},
			{
				selectFromResult: ({
					isSuccess,
					isLoading,
					isError,
					isFetching,
					data,
				}) => ({
					isSuccess,
					isLoading,
					isError,
					isFetching,
					totalCount: data?.meta.total,
				}),
			}
		)

	const { averageFlightTime } = useGetTrackSummaryQuery(
		{
			siteId: Number(siteId),
			format: 'graph',
			startTime: startTime,
			endTime: endTime,
			...filterParams,
		},
		{
			selectFromResult: ({ data }) => ({
				averageFlightTime: formatSecondsDuration(data?.avg_duration ?? 0),
			}),
		}
	)

	return (
		<Skeleton isLoaded={!isLoading && !isFetching}>
			{isSuccess && (
				<Center w='250px' h='330px'>
					<Box>
						<NumberDisplay
							title={t('numberOfFlights')}
							number={totalCount ?? 0}
							testId='number-of-flights'
						/>
						<NumberDisplay
							title={t('avgFlightTime')}
							number={averageFlightTime}
						/>
					</Box>
				</Center>
			)}
			{isError && (
				<FetchError refetch={refetch} entity={t('entities.eventDetails')} />
			)}
		</Skeleton>
	)
}

export default EventDetails
