import { z } from 'zod'
// Translations
import i18n from '@/i18n'
const t = (key: string) => i18n.t(`forms:global.validation.${key}`)

const mapMarkerFormFields = z.object({
	nickname: z.string().min(2, t('nameIsTooShort')),
	number: z.string().max(2),
	lat: z.number(),
	lng: z.number(),
	colour: z.string(),
})

export const mapMarkerFormSchema = mapMarkerFormFields

export type MapMarkerSchema = z.infer<typeof mapMarkerFormSchema>

export const ADD_MAP_MARKER_DEFAULT_VALUES: MapMarkerSchema = {
	nickname: '',
	number: '0',
	lat: 0,
	lng: 0,
	colour: '#225e97',
}
