import { Center, Button, Icon, Text } from '@chakra-ui/react'
import type { IconType } from 'react-icons'

type QuickActionsButtonProps = {
	icon: IconType
	label: string
	isDisabled?: boolean
	onClick?: () => void
	isActive?: boolean
	isError?: boolean
	testId?: string
	isLoading?: boolean
}

const style = {
	height: '60px',
	minW: 'calc(50% - 4px)',
	flexGrow: 1,
}

const iconSize = '24px'

const QuickActionsButton = ({
	icon,
	label,
	isDisabled,
	onClick,
	isActive,
	testId,
	isError = false,
	isLoading = false,
}: QuickActionsButtonProps) => {
	return (
		<Button
			variant='quickActions'
			sx={style}
			isDisabled={isDisabled}
			onClick={onClick}
			isActive={isActive}
			data-testid={testId}
			border={`${isError ? '1px solid' : 'none'}`}
			borderColor={`${isError ? 'error' : ''}`}
			isLoading={isLoading}
		>
			<Center flexDirection='column' gap='8px'>
				<Icon as={icon} boxSize={iconSize} />
				<Text fontSize='10px'>{label}</Text>
			</Center>
		</Button>
	)
}

export default QuickActionsButton
