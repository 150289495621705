// React
import { useTranslation } from 'react-i18next'
import { useFormContext, useFormState } from 'react-hook-form'
// Components
import Field from '@Components/FormElements'
import type { ProfileSchema } from '@Forms/ProfileForm/ProfileForm.schema'

const ProfileFormProfile = () => {
	const { register } = useFormContext()
	const { errors } = useFormState<ProfileSchema>()
	const { t } = useTranslation('forms', { keyPrefix: 'profileForm' })

	return (
		<>
			<Field.TextInput
				isRequired
				title={t('api.name')}
				register={register('name')}
				error={errors?.name?.message}
				testId='name'
			/>
			<Field.TextInput
				isRequired
				title={t('api.username')}
				register={register('username')}
				error={errors?.username?.message}
				testId='username'
			/>
			<Field.TextInput
				isRequired
				title={t('api.email')}
				register={register('email')}
				error={errors?.email?.message}
				testId='email'
			/>
			<Field.TextInput
				title={t('api.company')}
				register={register('company')}
				error={errors?.company?.message}
				testId='company'
			/>
			<Field.PhoneNumberInput
				title={t('api.phone')}
				error={errors?.phone?.message}
				testId='phone'
				register={register('phone')}
				countryCodeField={'phone_region'}
			/>
		</>
	)
}

export default ProfileFormProfile
