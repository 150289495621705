// React
import { useTranslation } from 'react-i18next'
// Components
import Field from '@Components/FormElements'
import { Box } from '@chakra-ui/react'
import { FormButtons } from '@Components/FormElements/FormButtons/FormButtons'
// Form
import { FormProvider, useForm } from 'react-hook-form'
import type { TypeOf } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { offlineMapLayersFormSchema } from '@Forms/MapsZonesForms/MapLayersForm/MapLayersForm.schema'
import { mapLayersFormSchema } from '@Forms/MapsZonesForms/MapLayersForm/MapLayersForm.schema'
// Store
import type { MapLayer } from '@Store/types/mapLayers'
import { useUpdateMapLayerMutation } from '@Store/mapLayers/mapLayersApi'

const EditMapLayerForm = ({
	defaultValues,
	onClose,
}: {
	defaultValues: MapLayer
	onClose: () => void
}) => {
	const { id, offline } = defaultValues
	// Translations
	const { t } = useTranslation('forms', { keyPrefix: 'mapsZonesForm' })

	type OnlineSchema = TypeOf<typeof mapLayersFormSchema>
	type OfflineSchema = TypeOf<typeof offlineMapLayersFormSchema>

	type Schema = typeof offline extends true ? OfflineSchema : OnlineSchema

	const methods = useForm({
		resolver: zodResolver(
			offline
				? offlineMapLayersFormSchema.omit({ file: true })
				: mapLayersFormSchema
		),
		defaultValues,
	})
	const {
		register,
		formState: { errors, isSubmitting, isDirty },
		handleSubmit,
		setError,
		setValue,
	} = methods

	const [updateMapLayer] = useUpdateMapLayerMutation()

	const handleSave = async (data: Schema) => {
		try {
			await updateMapLayer({ id, ...data })
				.unwrap()
				.then(() => {
					onClose()
				})
		} catch (errors) {
			// Surface server-side validation errors to react-hook-form
			for (const field in errors as { [name in keyof Schema]: string }) {
				setError(field as keyof Schema, {
					type: 'custom',
					message: (errors as { [name in keyof Schema]: string })[
						field as keyof Schema
					] as string,
				})
			}
		}
	}

	const handleCancel = () => onClose()

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(handleSave)}>
				<Field.TextInput
					title={t('mapLayersForm.name')}
					register={register('name')}
					error={errors?.name?.message}
				/>
				<Field.TextInput
					title={t('mapLayersForm.attribution')}
					register={register('attribution')}
					error={errors?.attribution?.message}
				/>
				{!offline && (
					<Field.TextInput
						title={t('mapLayersForm.url')}
						register={register('url')}
						error={errors?.url?.message}
					/>
				)}
				<Field.RangeSlider
					title={t('mapLayersForm.minMaxZoom')}
					defaultValue={[defaultValues.min_zoom, defaultValues.max_zoom]}
					step={1}
					min={1}
					max={30}
					onChangeEnd={(value: [number, number]) => {
						setValue('min_zoom', value[0], {
							shouldDirty: true,
							shouldValidate: true,
							shouldTouch: true,
						})
						setValue('max_zoom', value[1], {
							shouldDirty: true,
							shouldValidate: true,
							shouldTouch: true,
						})
					}}
					testId='zoom'
				/>
				{/* 32px is switch width + length of ch of translated title */}
				<Box width={`calc(32px + ${t('mapLayersForm.fallback').length}ch)`}>
					<Field.Switch
						title={t('mapLayersForm.fallback')}
						tooltip={t('mapLayersForm.fallbackMessage')}
						register={register('fallback')}
						error={errors?.fallback?.message}
					/>
				</Box>
				<FormButtons
					isSubmitting={isSubmitting}
					isDirty={isDirty}
					handleCancel={handleCancel}
				/>
			</form>
		</FormProvider>
	)
}

export default EditMapLayerForm
