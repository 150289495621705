import { Box, Flex, Text } from '@chakra-ui/react'

import {
	SensorFusionDetail,
	BeaconDetail,
	RFDetail,
	RcsDetail,
	LocationDetail,
	EventDetail,
	SensorDetail,
} from '@Components/Site/Panels/DetectionDetail/DetectionDetail'

import { useGetEventSummaryQuery } from '@Store/analytics/analyticsApi'
import {
	selectEventContributionSensors,
	selectEventSiteTimeZone,
} from '@Store/analytics/analyticsSelectors'
import type { Detection } from '@Store/types'

import { formatDateTz, getTimeZone } from '@Utils/dates'

type EventReplayPanelProps = {
	detection: Detection
	detections: Detection[]
	eventId: number
}

const EventReplayPanel = ({
	detection,
	detections,
	eventId,
}: EventReplayPanelProps) => {
	const { isSuccess, contributionSensors, timezone } = useGetEventSummaryQuery(
		{
			eventId,
		},
		{
			selectFromResult: ({ isSuccess, data }) => ({
				isSuccess,
				contributionSensors: selectEventContributionSensors(data),
				timezone: selectEventSiteTimeZone(data),
			}),
		}
	)

	const hasRfSensor = detections.some(
		({ detection_contributions: contributions }) =>
			contributions.filter(
				(sensor) =>
					sensor.sensor_type === 'rfSensor' || sensor.sensor_type === 'dsx'
			).length > 0
	)

	const hasRadar = detections.some(
		({ detection_contributions: contributions }) =>
			contributions.filter((sensor) => sensor.sensor_type === 'radar').length >
			0
	)

	if (!isSuccess) return null

	const date = formatDateTz(detection.created_at, timezone)

	return (
		<Box
			width='316px'
			position='absolute'
			top={0}
			right={0}
			bottom='calc(55px + var(--c2-sizes-2))'
			zIndex={999}
			m='var(--c2-sizes-2)'
			mb='28px'
			overflow='hidden'
			overflowY='auto'
		>
			<Flex h='40px' bgColor='input_bg' p='10px' gap={2}>
				<Text fontSize='md' as='b'>
					{date}
				</Text>
				{timezone && (
					<Text fontSize='md' color='label_color'>
						{getTimeZone(detection.created_at, timezone)}
					</Text>
				)}
			</Flex>
			<Flex
				bgColor='panel_bg'
				paddingInlineStart={4}
				paddingBlock={2}
				flexWrap='wrap'
			>
				<EventDetail detection={detection} />
				<SensorFusionDetail detection={detection} />
				{hasRfSensor && <RFDetail detection={detection} />}
				<LocationDetail detection={detection} isEventReplay />
				{hasRadar && <RcsDetail detection={detection} />}
				{detection.drone_locator_confirmed && (
					<BeaconDetail detection={detection} />
				)}
				<SensorDetail
					detection={detection}
					eventReplayContributors={contributionSensors}
					isEventReplay
				/>
			</Flex>
		</Box>
	)
}

export default EventReplayPanel
