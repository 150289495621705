// React
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { skipToken } from '@reduxjs/toolkit/query'

// Chakra and UI
import { Button, Flex } from '@chakra-ui/react'
import { MdEdit } from 'react-icons/md'
import MultiSelect, { type MultiSelectOption } from '@UI/Select/MultiSelect'

// Store
import {
	useGetMapLayersQuery,
	useCreateSiteMapLayerAssociationMutation,
	useDeleteSiteMapLayerAssociationMutation,
	useDeleteAllSiteMapLayersAssociationMutation,
	selectSiteMapLayerAssociationOptions,
	selectSiteMapLayerAssociationDefaultValues,
	useGetSiteMapLayersQuery,
} from '@Store/mapLayers/mapLayersApi'

const MapLayersSelect = ({ siteId }: { siteId: number }) => {
	const navigate = useNavigate()
	// Translations
	const { t } = useTranslation('forms', { keyPrefix: 'mapsZonesForm' })

	const {
		isLoading: isMapLayersLoading,
		isSuccess: isMapLayersSuccess,
		isError: isMapLayersError,
		siteMapLayerAssociationOptions,
	} = useGetMapLayersQuery(undefined, {
		selectFromResult: ({ isLoading, isError, isSuccess, data }) => ({
			isSuccess,
			isLoading,
			isError,
			siteMapLayerAssociationOptions:
				selectSiteMapLayerAssociationOptions(data),
		}),
	})

	const {
		isLoading: isSiteMapLayersLoading,
		isSuccess: isSiteMapLayersSuccess,
		isError: isSiteMapLayersError,
		defaultValues,
		refetch: siteLayersRefetch,
	} = useGetSiteMapLayersQuery(siteId ? siteId : skipToken, {
		selectFromResult: ({ isLoading, isSuccess, isError, data }) => ({
			isLoading,
			isSuccess,
			isError,
			defaultValues: selectSiteMapLayerAssociationDefaultValues(data),
		}),
	})

	const [createMapLayerAssociation] = useCreateSiteMapLayerAssociationMutation()
	const [deleteMapLayerAssociation] = useDeleteSiteMapLayerAssociationMutation()
	const [deleteAllMapLayersAssociation] =
		useDeleteAllSiteMapLayersAssociationMutation()

	// Actions
	const updateLayers = async (mapLayerId: number) => {
		try {
			await createMapLayerAssociation({ siteId, mapLayerId }).unwrap()
		} catch (error) {
			console.error('unhandled layer selector update error', error)
		}
	}
	const deleteLayer = async (layerId: number) => {
		try {
			await deleteMapLayerAssociation({
				siteId,
				layerId,
			}).unwrap()
		} catch (error) {
			console.error('unhandled layer selector delete error', error)
		}
	}
	const deleteAllLayers = async (layers: number[]) => {
		try {
			await deleteAllMapLayersAssociation({
				siteId,
				layers,
			}).unwrap()
		} catch (error) {
			console.error('unhandled all layers delete error', error)
		}
	}

	// Utils
	function differenceNumberAndAction(arr1: number[], arr2: number[]) {
		for (let i = 0; i < arr1.length; i++) {
			if (arr1[i] !== arr2[i])
				return {
					id: arr1[i],
					action: 'delete',
				}
		}
		for (let i = 0; i < arr2.length; i++) {
			if (arr1[i] !== arr2[i])
				return {
					id: arr2[i],
					action: 'add',
				}
		}
		return null
	}

	const handleSelectChange = (selectedLayers: MultiSelectOption[]) => {
		const defaultLayersIDs = defaultValues.map((layer) => Number(layer.value))
		const selectedLayersIDs = selectedLayers.map((layer: MultiSelectOption) =>
			Number(layer.value)
		)

		const difference = differenceNumberAndAction(
			defaultLayersIDs,
			selectedLayersIDs
		)

		if (!selectedLayers.length) {
			deleteAllLayers(defaultLayersIDs).then(siteLayersRefetch)
		} else {
			if (difference) {
				const { id, action } = difference
				if (action === 'add') {
					updateLayers(id).then(siteLayersRefetch)
				}
				if (action === 'delete') {
					deleteLayer(id).then(siteLayersRefetch)
				}
			}
		}
	}

	const handleNavigationClick = () => {
		navigate(`/${siteId}/map-layers`)
	}

	if (isMapLayersError || isSiteMapLayersError) return <>Error</>
	return (
		<Flex gap={4} alignItems='flex-end' mt={4}>
			<Flex flexGrow={1} direction='column' className='MapLayersSelect'>
				{isSiteMapLayersSuccess && isMapLayersSuccess && (
					<MultiSelect
						title={t('mapLayersForm.mapLayers')}
						placeholder={t('mapLayersForm.selectMapLayers')}
						isLoading={isMapLayersLoading || isSiteMapLayersLoading}
						defaultValue={defaultValues}
						onChange={handleSelectChange}
						options={siteMapLayerAssociationOptions}
						testId='map-layers-select'
					/>
				)}
			</Flex>
			<Button
				variant='action'
				leftIcon={<MdEdit />}
				onClick={handleNavigationClick}
				data-testid='edit-map-layers'
			>
				{t('mapLayersForm.editButton')}
			</Button>
		</Flex>
	)
}

export default MapLayersSelect
