// React
import { memo, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useMap } from 'react-leaflet'
// Chakra
import { useSize } from '@chakra-ui/react-use-size'
import {
	Box,
	Button as ChakraButton,
	Center,
	Icon,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	Stack,
} from '@chakra-ui/react'
// Icons
import {
	MdClose,
	MdCloseFullscreen,
	MdFullscreen,
	MdInfo,
} from 'react-icons/md'
// Components
import Button from '@UI/Button/Button'

// Map Legend Parts Components
import MapLegendSectionMap from './MapLegendSectionMap'
import MapLegendSectionSensorFusion from './MapLegendSectionSensorFusion'
import MapLegendSectionDetectionSelectors from './MapLegendSectionDetectionSelectors'
import MapLegendSectionDroneLocator from './MapLegendSectionDroneLocator'
import MapLegendSectionRfDetections from './MapLegendSectionRfDetections'
// Store
import { useAppSelector } from '@/store'
import { selectLeftPanelWidth } from '@Store/ui/uiSlice'
import Control from 'react-leaflet-custom-control'

const MapLegend = ({
	onClick,
	isActive,
}: {
	onClick: () => void
	isActive: boolean
}) => {
	const map = useMap()
	const { t } = useTranslation('map', { keyPrefix: 'legend' })

	const body: HTMLElement | null = document.querySelector('body')
	const sidebarContentEl: HTMLElement | null = document.querySelector(
		'.leaflet-control-zoom.leaflet-bar.leaflet-control'
	)
	const [isFullScreen, setFullScreen] = useState(true)
	const leftPanelWidth = useAppSelector(selectLeftPanelWidth)
	const { width: screenWidth } = useSize(map.getContainer()) ?? { width: null }

	const leftPadding = useMemo(() => {
		if (leftPanelWidth) {
			return leftPanelWidth + 76
		}
		return 68
	}, [leftPanelWidth])

	const legendWidth = useMemo(() => {
		if (!isFullScreen)
			return 286 // Default value
		else {
			if (screenWidth) return screenWidth - leftPadding + 12
		}
	}, [screenWidth, isFullScreen, leftPadding])

	const PopoverExpandButton = () => (
		<Button
			shape='icon'
			variant='icon'
			icon={isFullScreen ? <MdCloseFullscreen /> : <MdFullscreen />}
			label='Show fullscreen map legend'
			testId='show-fullscreen-map-legend'
			onClick={() => setFullScreen(!isFullScreen)}
			position='absolute'
			right='36px'
			top='12px'
			color='primary'
			cursor='pointer'
		/>
	)

	const PopoverCloseButton = () => (
		<Button
			shape='icon'
			variant='icon'
			icon={<MdClose />}
			label='Close map legend'
			testId='close-map-legend'
			onClick={onClick}
			position='absolute'
			right='12px'
			top='12px'
			color='primary'
			cursor='pointer'
		/>
	)

	const LegendPopover = ({ onToggle }: { onToggle: () => void }) => (
		<>
			{body &&
				sidebarContentEl &&
				createPortal(
					<Box position='fixed' zIndex={1000} left={leftPadding} bottom={2}>
						<Popover
							isOpen={true}
							onClose={onToggle}
							placement='right-end'
							closeOnBlur={false}
						>
							<PopoverTrigger>
								<Box pl={8}></Box>
							</PopoverTrigger>
							<PopoverContent w={legendWidth}>
								<PopoverHeader>{t('entity')}</PopoverHeader>
								<PopoverExpandButton />
								<PopoverCloseButton />
								<PopoverBody>
									<Stack
										className={isFullScreen ? 'MapLegendFullScreen' : ''}
										gap={6}
										flexDirection={isFullScreen ? 'row' : 'column'}
										justifyContent='space-between'
										// CSS Magic to show and hide borders for fullscreen view
										sx={{
											':is(div.MapLegendFullScreen) > div': {
												flex: 1,
												borderRight: '1px solid',
												'&:last-of-type': {
													border: 'none',
												},
											},
											':not(div.MapLegendFullScreen)': {
												h2: { borderTop: '1px solid' },
												'& > div:first-of-type h2': {
													border: 'none',
												},
											},
										}}
									>
										{/*Map*/}
										<MapLegendSectionMap />
										{/*SensorFusion*/}
										<MapLegendSectionSensorFusion />
										{/*DetectionSelectors*/}
										<MapLegendSectionDetectionSelectors />
										{/*DroneLocator Detections*/}
										<MapLegendSectionDroneLocator />
										{/*RF Detections*/}
										<MapLegendSectionRfDetections />
									</Stack>
								</PopoverBody>
							</PopoverContent>
						</Popover>
					</Box>,
					sidebarContentEl
				)}
		</>
	)

	return (
		<Control position='bottomleft'>
			<ChakraButton
				onClick={onClick}
				variant='mapControl'
				className='MapLegendFullScreen'
			>
				<Center>
					<Icon
						aria-label='Show map legend'
						as={MdInfo}
						h='20px'
						w='20px'
						cursor='pointer'
					/>
				</Center>
			</ChakraButton>
			{isActive && <LegendPopover onToggle={onClick} />}
		</Control>
	)
}

export default memo(MapLegend)
