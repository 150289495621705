import { useTranslation } from 'react-i18next'
import { formatInTimeZone } from 'date-fns-tz'

import { Flex } from '@chakra-ui/react'

import BarChart from '@UI/Charts/BarChart'
import FetchError from '@UI/FetchError/FetchError'
import Headings from '@UI/Headings/Headings'
import Skeleton from '@UI/Skeleton/Skeleton'

import { useGetAlertsSummaryQuery } from '@Store/analytics/analyticsApi'

type AlertsData = {
	[date: string]: {
		[source: string]: number
	}
}

type AlertsGraphData = {
	date: string
	'Event Intrusion'?: number
	'Sensor Offline'?: number
}

type AlertsBarChartProps = {
	siteId: number
	startTime: string
	endTime: string
	siteTimeZone: string
}

const getDateTz = (date: string, timeZone: string) => {
	const d = new Date(date)
	return formatInTimeZone(d, timeZone, 'yyyy-MM-dd')
}

const AlertsBarChart = ({
	startTime,
	endTime,
	siteId,
	siteTimeZone,
}: AlertsBarChartProps) => {
	const { t } = useTranslation('pages', {
		keyPrefix: 'analytics.alertsSummary',
	})

	const { isSuccess, isError, isLoading, isFetching, refetch, alertsByDay } =
		useGetAlertsSummaryQuery(
			{
				siteId,
				format: 'graph',
				startTime: startTime,
				endTime: endTime,
			},
			{
				selectFromResult: ({
					isSuccess,
					isError,
					isLoading,
					isFetching,
					data,
				}) => ({
					isSuccess,
					isError,
					isLoading,
					isFetching,
					alertsByDay: data?.by_day ?? {},
				}),
			}
		)

	const transformData = (data: AlertsData) => {
		return Object.entries(data).reduce<AlertsGraphData[]>(
			(acc, [utcDate, values]) => {
				const nonZeroValues = Object.fromEntries(
					Object.entries(values).filter(([_, value]) => value !== 0)
				)

				if (Object.keys(nonZeroValues).length > 0) {
					acc.push({
						date: getDateTz(utcDate, siteTimeZone),
						...nonZeroValues,
					})
				}

				return acc
			},
			[]
		)
	}

	const graphData = transformData(alertsByDay)

	return (
		<Skeleton isLoaded={!isLoading && !isFetching} w='100%'>
			{isSuccess && (
				<Flex
					h='340px'
					width='100%'
					direction='column'
					gap='16px'
					marginBlockEnd={8}
					alignItems='center'
				>
					<Headings.ChartHeading title={t('alertsOverTime')} />
					<BarChart
						data={graphData}
						indexBy='date'
						keys={['Event Intrusion', 'Sensor Offline']}
						margin={{ top: 30, right: 30, bottom: 35, left: 30 }}
						includeLegend
						isLabelTilted={graphData.length > 12}
					/>
				</Flex>
			)}
			{isError && <FetchError refetch={refetch} entity={t('entities.data')} />}
		</Skeleton>
	)
}

export default AlertsBarChart
