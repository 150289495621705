import { isAnyOf } from '@reduxjs/toolkit'
import type { AppStartListening } from '@Store/middleware'

import { authApi } from '@Store/auth/authApi'
import { usersApi } from '@Store/user/usersApi'
import { logout } from '@Store/auth/authSlice'

import { jwtRefreshAfterMinutes } from '@Constants/api'

let jwtRefreshTimer: ReturnType<typeof setInterval> | null = null

const addJwtRefreshMiddleware = (startListening: AppStartListening) => {
	// Upon successful login set up a refresh interval for the JWT token
	startListening({
		matcher: isAnyOf(
			authApi.endpoints.login.matchFulfilled, // login event
			usersApi.endpoints.getUser.matchFulfilled // browser reload event
		),
		effect: (_, { dispatch }) => {
			if (jwtRefreshTimer) clearInterval(jwtRefreshTimer)
			jwtRefreshTimer = setInterval(
				() => {
					dispatch(authApi.endpoints.refresh.initiate(undefined))
				},
				jwtRefreshAfterMinutes * 1000 * 60
			)
		},
	})
	// On logout, cancel it
	startListening({
		matcher: isAnyOf(logout.fulfilled),
		effect: () => {
			if (jwtRefreshTimer) clearInterval(jwtRefreshTimer)
		},
	})
}

// Exports
export { addJwtRefreshMiddleware }
