import { useMemo } from 'react'
import { useGetSitesListQuery } from '@Store/sites/sitesApi'
import type { UserSiteAssociation } from '@Store/types'

const useSiteListOptions = (
	clientId: number,
	userSiteAssociationList: UserSiteAssociation[]
) => {
	const { data: sites } = useGetSitesListQuery()
	const clientSitesList = sites?.filter((site) => site.client_id === clientId)
	const userSiteAssociationListIds = userSiteAssociationList?.map(
		({ site_id }: { site_id: number }) => site_id
	)

	return useMemo(() => {
		if (userSiteAssociationList?.length) {
			return clientSitesList
				?.filter((site) => !userSiteAssociationListIds?.includes(site.id))
				.map((site) => ({
					value: site?.id,
					label: site?.name,
				}))
		} else {
			return clientSitesList?.map((site) => ({
				value: site?.id,
				label: site?.name,
			}))
		}
	}, [clientSitesList, userSiteAssociationList, userSiteAssociationListIds])
}

export default useSiteListOptions
