import { Flex } from '@chakra-ui/react'

import DisruptionDetailsTable from './DisruptionDetailsTable/DisruptionDetailsTable'
import DisruptionDetails from './DisruptionDetails'
import DisruptionsLineChart from './DisruptionsLineChart'
import DisruptionsPieChart from './DisruptionsPieChart'

type DisruptionsSummaryProps = {
	siteId: number
	startTime: string
	endTime: string
	siteTimeZone: string
}

const DisruptionsSummary = ({
	siteId,
	startTime,
	endTime,
	siteTimeZone,
}: DisruptionsSummaryProps) => (
	<>
		<Flex alignItems='center' gap='30px' marginBlockEnd={4}>
			<Flex direction='column' gap='20px'>
				<DisruptionDetails
					siteId={siteId}
					startTime={startTime}
					endTime={endTime}
				/>
				<DisruptionsPieChart
					siteId={siteId}
					startTime={startTime}
					endTime={endTime}
				/>
			</Flex>
			<DisruptionsLineChart
				siteId={siteId}
				startTime={startTime}
				endTime={endTime}
				siteTimeZone={siteTimeZone}
			/>
		</Flex>
		<DisruptionDetailsTable
			siteId={siteId}
			startTime={startTime}
			endTime={endTime}
			siteTimeZone={siteTimeZone}
		/>
	</>
)

export default DisruptionsSummary
