import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Flex } from '@chakra-ui/react'

import Switch from '@UI/Switch/Switch'

import { useUpdateAllDisruptorsMutation } from '@Store/disruptors/disruptorsApi'

import { type SiteInstallation, type DisruptorBandsEngaged } from '@Store/types'
import type { UpdateDisruptorBandsRequest } from '@Store/disruptors/disruptorsApi'

type ConfigureAllBandsProps = {
	siteId: number
	devices: SiteInstallation['disruptors']
}

const ConfigureAllBands = ({ siteId, devices }: ConfigureAllBandsProps) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'devicesContent',
	})

	const [isConfigureAllBands, setIsConfigureAllBands] = useState(false)

	const [updateAllDisruptors] = useUpdateAllDisruptorsMutation()

	const toggleConfigureAllBands = (
		value: React.ChangeEvent<HTMLInputElement>
	) => {
		setIsConfigureAllBands(value.target.checked)
	}

	const isEveryBandOn = (band: DisruptorBandsEngaged) =>
		devices.every((device) => device[`${band}`])

	const toggleBand = async (
		value: React.ChangeEvent<HTMLInputElement>,
		band: keyof UpdateDisruptorBandsRequest
	) => {
		try {
			await updateAllDisruptors({
				siteId,
				disruptors: devices,
				[`${band}`]: value.target.checked,
			}).unwrap()
		} catch (error) {
			console.error('TODO: unhandled configure all bands error')
		}
	}

	return (
		<Flex p='4px 8px 28px 8px' direction='column' gap='24px'>
			<Switch
				label={t('configureAllBands')}
				onChange={toggleConfigureAllBands}
			/>
			{isConfigureAllBands && (
				<Flex gap='24px' w='370px'>
					<Switch
						label={'2.4\n5.8'}
						defaultChecked={isEveryBandOn('band_24_58_trigger_engaged')}
						onChange={(event) =>
							toggleBand(event, 'band_24_58_trigger_command')
						}
					/>
					<Switch
						label='915'
						defaultChecked={isEveryBandOn('band_915_trigger_engaged')}
						onChange={(event) => toggleBand(event, 'band_915_trigger_command')}
					/>
					<Switch
						label='433'
						defaultChecked={isEveryBandOn('band_433_trigger_engaged')}
						onChange={(event) => toggleBand(event, 'band_433_trigger_command')}
					/>
					<Switch
						label='GNSS'
						defaultChecked={isEveryBandOn('gnss_trigger_engaged')}
						onChange={(event) => toggleBand(event, 'gnss_trigger_command')}
					/>
				</Flex>
			)}
		</Flex>
	)
}

export default ConfigureAllBands
