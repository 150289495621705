import { defineStyleConfig } from '@chakra-ui/react'
import FIGMA from '@Theme/DRO/_FIGMA'

const SliderStyles = defineStyleConfig({
	baseStyle: {
		container: {
			left: '0.5rem',
			right: '0.5rem',
			width: 'calc(100% - 1rem)',
		},
		thumb: {
			default: {
				bg: FIGMA.colors.primary,
			},
			_dark: {
				bg: FIGMA.colors.primary,
			},
		},
	},
})

export const Slider = {
	...SliderStyles,
}
