// React
import i18n from '@/i18n'
import { memo } from 'react'
// Map
import { useMap } from 'react-leaflet'
import type { LatLngExpression } from 'leaflet'
// Chakra
import { Button, Center, Icon } from '@chakra-ui/react'
import { BiTargetLock } from 'react-icons/bi'
// Components
import Tooltip from '@UI/Tooltip/Tooltip'
// Types
type CenterMapButtonProps = {
	center: LatLngExpression | undefined
	zoom: number
}

const CenterMapButton = ({ center, zoom }: CenterMapButtonProps) => {
	const map = useMap()
	const t = (key: string) => i18n.t(`map:mapControls.tooltips.${key}`)
	const handleClick = () => {
		if (center) map.flyTo(center, zoom)
	}

	if (!center) return null
	return (
		<Tooltip label={t('mapCenter')} placement='right-start'>
			<Button onClick={handleClick} variant='mapControl'>
				<Center>
					<Icon as={BiTargetLock} w='24px' h='24px' />
				</Center>
			</Button>
		</Tooltip>
	)
}

export default memo(CenterMapButton)
