import { useTheme } from '@chakra-ui/react'
import type { RfSensor } from '@Store/types'

import Sector from '@Components/MapShapes/Sector/Sector'

type DsxSectorProps = {
	latitude: number
	longitude: number
	isActive: boolean
} & Pick<RfSensor, 'reach_jamming' | 'jamming'>

const DsxDisruptSector = ({
	latitude,
	longitude,
	reach_jamming = 0,
	isActive = false,
	jamming,
}: DsxSectorProps) => {
	const {
		semanticTokens: { colors },
	} = useTheme()
	const fillColor = colors.sectors.rfSensorFill

	const strokeColor = isActive
		? colors.sectors.highlight
		: colors.sectors.rfSensorStroke

	const strokeWeight = isActive ? 4 : 2

	return (
		<Sector
			latitude={latitude}
			longitude={longitude}
			reach={reach_jamming}
			color={strokeColor}
			fillColor={fillColor}
			weight={strokeWeight}
			className={jamming ? 'disruptor-sector-animation' : ''}
			testId='dsx-disruption-reach-sector'
		/>
	)
}

export default DsxDisruptSector
