import { defineStyleConfig } from '@chakra-ui/react'

const ModalStyles = defineStyleConfig({
	baseStyle: {
		overlay: {
			background: 'modal_bg',
		},
		dialog: {
			maxWidth: '620px',
			bgColor: 'body_bg',
			borderRadius: 0,
		},
		dialogContainer: {
			width: 'calc(100vw - 60px)',
			height: 'calc(100vh - 50px)',
			marginLeft: '60px',
			marginTop: '50px',
			padding: '10px',
		},
		header: {
			bgColor: 'input_bg',
			color: 'modal_heading',
			fontSize: '16px',
			fontWeight: 500,
			textTransform: 'uppercase',
			position: 'relative',
			padding: 4,
		},
		closeButton: {
			top: 3,
			right: 4,
			color: 'primary',
			fontSize: 'xs',
		},
		body: {
			padding: '1rem 1rem 0 1rem',
			bgColor: 'body_bg',
		},
		footer: {
			padding: 4,
			gap: 2,
			justifyContent: 'flex-start',
		},
	},
	// Note: Down the line, we can customise the modal sizes instead.
	// i.e. size='sm' being custom.
	// https://chakra-ui.com/docs/components/modal/theming
	variants: {
		deleteConfirmation: {
			dialog: {
				maxWidth: '414px',
			},
		},
		table: {
			dialog: {
				minWidth: '60%',
				minHeight: '450px',
			},
			body: {
				padding: '16px 16px 48px 16px',
			},
		},
		analytics: {
			dialog: {
				minWidth: '80%',
			},
			body: {
				padding: '0',
			},
		},
		sm: {
			dialog: {
				minWidth: '40%',
				minHeight: '500px',
			},
			body: {
				padding: '0px 16px 16px 16px',
			},
		},
	},
})

export const Modal = {
	defaultProps: {
		colorScheme: 'primary',
	},
	...ModalStyles,
}
