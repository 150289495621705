// React
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// Form
import { useForm, FormProvider } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import type { TypeOf } from 'zod'

// Chakra
import { Flex } from '@chakra-ui/react'
import Field from '@Components/FormElements'
import { FormButtons } from '@Components/FormElements/FormButtons/FormButtons'
// Components
import FormWrapper from '@Components/FormElements/FormWrapper/FormWrapper'
import FormHeader from '@Forms/FormHeader'
import FormStatusErrors from '@Components/FormElements/FormStatusErrors/FormStatusErrors'
import ErrorBoundary from '@Components/App/ErrorHandling/ErrorBoundary'
// Schema
import {
	cotFormSchema,
	ADD_COT_DEFAULT_VALUES,
	type CotSchema,
} from './CotForm.schema'
// Store
import {
	useCreateCotMutation,
	useGetCotQuery,
	useUpdateCotMutation,
} from '@Store/cots/cotsApi'
import { skipToken } from '@reduxjs/toolkit/query'

// Utils
import type { FormFieldsError, ServerError } from '@Store/utils/errorUtils'

type CotFormProps = {
	siteId: number
	cotId?: number
}

const CotForm = ({ siteId, cotId }: CotFormProps) => {
	const isEditForm = !!cotId
	const { t } = useTranslation('forms', { keyPrefix: 'cotForm' })

	const {
		isLoading,
		isError,
		isSuccess,
		refetch,
		data: cot,
	} = useGetCotQuery(cotId ? { siteId, cotId } : skipToken, {
		refetchOnMountOrArgChange: true,
	})

	const addCotDefaultValues = {
		...ADD_COT_DEFAULT_VALUES,
	}

	const defaultValues = isEditForm ? cot : addCotDefaultValues

	// TODO: add standard error component
	return (
		<FormWrapper
			entity={t('entity')}
			isEditForm={isEditForm}
			isLoading={isLoading}
			isError={isError}
			isSuccess={isSuccess}
			refetch={refetch}
		>
			{defaultValues && (
				<Form
					defaultValues={defaultValues as CotSchema}
					isEditForm={isEditForm}
					siteId={siteId}
					cotId={cotId}
				/>
			)}
		</FormWrapper>
	)
}

const Form = ({
	defaultValues,
	isEditForm,
	siteId,
	cotId,
}: {
	defaultValues: CotSchema
	isEditForm: boolean
	siteId: number
	cotId?: number
}) => {
	const navigate = useNavigate()

	// Translations
	const { t } = useTranslation('forms', { keyPrefix: 'cotForm' })

	type Schema = TypeOf<typeof cotFormSchema>

	const methods = useForm<Schema>({
		resolver: zodResolver(cotFormSchema),
		defaultValues,
	})
	const {
		register,
		formState: { errors, isSubmitting, isDirty },
		handleSubmit,
		setError,
	} = methods

	const [createCot] = useCreateCotMutation()
	const [updateCot] = useUpdateCotMutation()

	const handleSave = async (data: Schema) => {
		try {
			if (isEditForm && cotId) {
				await updateCot({
					siteId,
					cotId,
					cot: { ...data },
				}).unwrap()
			} else if (siteId) {
				await createCot({
					siteId,
					...data,
				}).unwrap()
			}
			navigate(`/${siteId}/settings`)
		} catch (e: unknown) {
			if ((e as ServerError).status) {
				setError('root.serverError', {
					type: 'response.statusCode',
					message: String((e as ServerError).status),
				})
			} else {
				for (const field in e as FormFieldsError<Schema>) {
					setError(field as keyof Schema, {
						type: 'custom',
						message: (e as FormFieldsError<Schema>)[field as keyof Schema],
					})
				}
			}
		}
	}

	const handleCancel = () => navigate(`/${siteId}/settings`)

	return (
		<>
			<FormHeader
				title={isEditForm ? t('headings.editCot') : t('headings.addCot')}
				backRoute={`/${siteId}/settings`}
			/>
			<ErrorBoundary>
				<FormProvider {...methods}>
					<FormStatusErrors errors={errors} />
					<form onSubmit={handleSubmit(handleSave)}>
						<Field.Select
							title={t('headings.minProb')}
							defaultValue={defaultValues.minimum_probability}
							register={register('minimum_probability')}
							options={t('api.minimum_probability', { returnObjects: true })}
							error={errors?.minimum_probability?.message}
							testId='minimum_probability'
						/>
						<Field.Slider
							title={t('api.rate_limit')}
							min={0}
							max={100}
							register={register('rate_limit', { valueAsNumber: true })}
							error={errors?.rate_limit?.message}
							testId='direction-rate_limit'
						/>
						<Field.TextInput
							title={t('api.symbol_type')}
							register={register('symbol_type')}
							error={errors?.symbol_type?.message}
							testId='symbol_type'
						/>
						<Field.TextInput
							title={t('api.how')}
							register={register('how')}
							error={errors?.how?.message}
							testId='how'
						/>
						<Flex gap={2}>
							<Field.TextInput
								title={t('api.field_of_view')}
								register={register('field_of_view', {
									valueAsNumber: true,
								})}
								error={errors?.field_of_view?.message}
								testId='field_of_view'
							/>
							<Field.NumberInput
								title={t('api.event_version')}
								register={register('event_version', {
									valueAsNumber: true,
								})}
								error={errors?.event_version?.message}
								testId='event_version'
							/>
							<Field.Select
								title={t('headings.protocol')}
								register={register('protocol')}
								options={t('api.protocol', { returnObjects: true })}
								error={errors?.protocol?.message}
							/>
						</Flex>
						<Flex gap={2}>
							<Field.TextInput
								title={t('api.ip')}
								register={register('ip')}
								error={errors?.ip?.message}
								testId='ip'
							/>
							<Field.NumberInput
								title={t('api.port')}
								register={register('port', {
									valueAsNumber: true,
								})}
								error={errors?.port?.message}
								testId='port'
							/>
						</Flex>

						<FormButtons
							isSubmitting={isSubmitting}
							isDirty={isDirty}
							handleCancel={handleCancel}
						/>
					</form>
				</FormProvider>
			</ErrorBoundary>
		</>
	)
}

export default CotForm
