// React
import { memo, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
// Store
import { useAppDispatch, useAppSelector } from '@/store'
import { useGetSiteQuery } from '@Store/sites/sitesApi'
import { selectSentryData, setSentryData } from '@Store/ui/uiSlice'
// Components
import SiteMarker from '@Components/Markers/SiteMarker/SiteMarker'
import SentryMarker from '@Components/Markers/SentryMarker/SentryMarker'
import CompassMarker from '@Components/Markers/CompassMarker/CompassMarker'
import Detections from '@Components/Site/MapLayers/Detections'
// Hooks
import useCenterMap from './useCenterMap'
import useValidCoordinates from './useValidCoordinates'
// Types
import type { LatLngLiteral, LatLngExpression } from 'leaflet'

const InstallationPreview = () => {
	const { siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)

	const { sitePosition, isSuccess } = useGetSiteQuery(siteId, {
		selectFromResult: ({ data, isSuccess }) => ({
			sitePosition:
				data && ([data?.latitude, data?.longitude] as LatLngExpression),
			isSuccess,
		}),
	})

	const dispatch = useAppDispatch()
	const sentryData = useAppSelector(selectSentryData)

	const latitude = useMemo(() => sentryData?.latitude, [sentryData])
	const longitude = useMemo(() => sentryData?.longitude, [sentryData])

	const coordinates = useValidCoordinates(latitude, longitude)

	useCenterMap({
		latitude: coordinates[0],
		longitude: coordinates[1],
		animation: false,
	})

	const handleDragEnd = (coords: LatLngLiteral) => {
		const { lat: latitude, lng: longitude } = coords
		dispatch(setSentryData({ latitude, longitude }))
	}

	// Clean-up store after unmount
	useEffect(
		() => () => {
			dispatch(setSentryData(null))
		},
		[dispatch]
	)

	if (!sentryData) return null

	const {
		name,
		accept_location_updates,
		sentry_type,
		status_color,
		direction,
	} = sentryData

	return (
		<>
			<Detections siteId={siteId} />
			{isSuccess && sitePosition && <SiteMarker position={sitePosition} />}
			<CompassMarker
				position={coordinates as LatLngExpression}
				bearing={direction}
			/>
			<SentryMarker
				name={name}
				position={coordinates as LatLngExpression}
				sentryType={sentry_type}
				iconColor={status_color}
				onDragEnd={!accept_location_updates ? handleDragEnd : undefined}
				draggable={!accept_location_updates}
			/>
		</>
	)
}

export default memo(InstallationPreview)
