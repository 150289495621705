import { baseApi } from '@Store/baseApi'
import type { SiteWarning } from '@Store/types/siteWarning'
import type { Site } from '@Store/types'
import { utcToZonedTime } from 'date-fns-tz'
import { format, subDays } from 'date-fns'
import { createSelector } from '@reduxjs/toolkit'

type UpdateSiteWarningRequest = {
	siteId: Site['id']
	siteWarningId: SiteWarning['id']
}

export const siteWarningsApi = baseApi
	.enhanceEndpoints({
		addTagTypes: ['SiteWarnings'],
	})
	.injectEndpoints({
		endpoints: (builder) => ({
			// https://au-dev.droneshield.xyz/api/docs#get-/api/sites/-site_id-/site_warnings
			getSiteWarnings: builder.query<SiteWarning[], Site['id']>({
				query: (siteId) => ({
					url: `/api/sites/${siteId}/site_warnings?read=false`,
				}),
				providesTags: ['SiteWarnings'],
				keepUnusedDataFor: 0,
				transformResponse: (response: { site_warning: SiteWarning[] }) =>
					(response.site_warning ?? []).sort((a, b) => b.id - a.id),
			}),
			getReadSiteWarnings: builder.query<
				SiteWarning[],
				{ siteId: number; timezone: string }
			>({
				query: ({ siteId, timezone }) => {
					const nowInZone = utcToZonedTime(new Date(), timezone)
					const twoDaysAgo = subDays(nowInZone, 2)
					const timeFormat = 'T'
					const formattedDate = new Date(
						format(
							twoDaysAgo,
							`yyyy-MM-dd'${timeFormat}'HH:mm:ss.SSS` // Dirty hack to prevent prettier single-quote auto-format
						)
					).toISOString()
					return {
						url: `/api/sites/${siteId}/site_warnings?read=true&end_time=${formattedDate}`,
					}
				},
				providesTags: ['SiteWarnings'],
				keepUnusedDataFor: 0,
				transformResponse: (response: { site_warning: SiteWarning[] }) =>
					(response.site_warning ?? []).sort((a, b) => b.id - a.id),
			}),

			updateSiteWarningAsRead: builder.mutation<
				SiteWarning,
				UpdateSiteWarningRequest
			>({
				query: ({ siteId, siteWarningId }) => ({
					url: `/api/sites/${siteId}/site_warnings/${siteWarningId}`,
					method: 'PATCH',
					body: {
						site_warning: {
							read: true,
						},
					},
				}),
				invalidatesTags: ['SiteWarnings'],
			}),
			// https://au-dev.droneshield.xyz/api/docs#put-/api/sites/-site_id-/site_warnings/clear
			updateAllSiteWarningsAsRead: builder.mutation<
				string,
				{ siteId: Site['id'] }
			>({
				query: ({ siteId }) => ({
					url: `/api/sites/${siteId}/site_warnings/clear`,
					method: 'PUT',
				}),
				invalidatesTags: ['SiteWarnings'],
			}),
		}),
	})

export const selectHasUnreadWarnings = createSelector(
	(data?: SiteWarning[]) => data,
	(data) => data?.some((warning) => !warning.read)
)

export const selectUnreadWarningsCount = createSelector(
	(data?: SiteWarning[]) => data,
	(data) => data?.filter((warning) => !warning.read).length
)

export const {
	useGetSiteWarningsQuery,
	useGetReadSiteWarningsQuery,
	useUpdateSiteWarningAsReadMutation,
	useUpdateAllSiteWarningsAsReadMutation,
} = siteWarningsApi
