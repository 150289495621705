import { useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Box, Flex } from '@chakra-ui/react'
import { MdHome } from 'react-icons/md'

import Button from '@UI/Button/Button'
import Headings from '@UI/Headings/Headings'

type PageProps = {
	heading: string
	children: React.ReactNode
}
// TODO: Add backButtonText and get rid of translations
// A page wrapper for pages outside of the main dashboard
// e.g. Analytics, user management
const Page = ({ heading, children }: PageProps) => {
	const navigate = useNavigate()
	const { siteId } = useParams()

	const { t } = useTranslation('layouts', {
		keyPrefix: 'buttons',
	})

	const handleBackToDashboard = () => {
		if (siteId) {
			navigate(`/${siteId}`)
		} else {
			navigate('/')
		}
	}

	return (
		<Flex w='100%' h='100%' p='16px' bgColor='body_bg'>
			<Box w='100%' h='100%' bgColor='card_bg' p='16px' overflowY='scroll'>
				<Flex justifyContent='space-between'>
					<Headings.Page title={heading} />
					<Button
						label={t('backToDashboard')}
						testId='back-to-dashboard'
						leftIcon={<MdHome />}
						size='sm'
						onClick={handleBackToDashboard}
					/>
				</Flex>
				{children}
			</Box>
		</Flex>
	)
}

export default Page
