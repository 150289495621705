// set to 0 and core-api backend sets to 1 hour
export const jwtExpireAfterHours = 24
export const jwtRefreshAfterMinutes = 5

// System Stats redux subscription
export const systemStatsPollingIntervalSecs = 3

// Redux connections
export const connectionRetries = 6
export const wsReconnectMultiplyTimes = 7
export const wsReconnectInitialDelay = Math.round(500 + Math.random() * 250) // stagger client reconnects

// Vite access to runtime ENV
export const env = import.meta.env

const wsProtocol = document.location.protocol === 'https:' ? 'wss:' : 'ws:'
const wsHost = document.location.host // needs hostname:port
const coturnHost = document.location.hostname // needs hostname

// API endpoints
const apiEndpoints = {
	production: {
		baseUrl: '/',
		wsUrl: `${wsProtocol}//${wsHost}/api/ws`,
		janusUrl: '/janus',
		coturnUrl: `turn:${coturnHost}:3478`,
		stunUrl: 'stun:stun.l.google.com:19302',
	},
	local: {
		baseUrl: 'http://localhost:3380',
		wsUrl: 'ws://localhost:3380/api/ws',
		janusUrl: '',
		coturnUrl: '',
		stunUrl: '',
	},
	test: {
		baseUrl: 'http://localhost:3380',
		wsUrl: 'ws://localhost:8089/api/ws',
		janusUrl: '',
		coturnUrl: '',
		stunUrl: '',
	},
}

const buildHostName = (subdomain: string) => {
	if (subdomain.match(/^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/))
		return subdomain
	return subdomain + '.droneshield.xyz'
}

const buildEndpoints = () => {
	const hostname = buildHostName(env.VITE_API ?? 'au-dev')
	const isNotSecure = env.VITE_API_INSECURE
	const protoHttp = isNotSecure ? 'http:' : 'https:'
	const protoWs = isNotSecure ? 'ws:' : 'wss:'
	return {
		baseUrl: `${protoHttp}//${hostname}`,
		wsUrl: `${protoWs}//${hostname}/api/ws`,
		janusUrl: `${protoHttp}//${hostname}/janus`,
		coturnUrl: `turn:${hostname}:3478`,
		stunUrl: 'stun:stun.l.google.com:19302',
	}
}

let environment: 'production' | 'test' | 'local' | undefined

if (env.PROD) environment = 'production'
else if (env.MODE === 'test' || env.VITEST) environment = 'test'
else if (
	['local', 'localhost', 'docker', 'local-docker'].includes(env.VITE_API)
)
	environment = 'local'
else {
	environment = undefined
}

const endpoints = environment ? apiEndpoints[environment] : buildEndpoints()

export const baseUrl = endpoints.baseUrl
export const wsUrl = endpoints.wsUrl
export const janusUrl = endpoints.janusUrl
export const stunUrl = endpoints.stunUrl
export const coturnUrl = endpoints.coturnUrl
export const isProduction = env.PROD
