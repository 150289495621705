import { IconButton } from '@chakra-ui/react'
import { MdOutlineFullscreen, MdOutlineCloseFullscreen } from 'react-icons/md'
import { useCallback, useEffect, useState, type MutableRefObject } from 'react'

type FullScreenButtonProps = {
	cameraRef: MutableRefObject<HTMLDivElement | null>
}

const FullScreenButton = ({ cameraRef }: FullScreenButtonProps) => {
	const [isFullScreen, setIsFullScreen] = useState(!!document.fullscreenElement)

	const handleClick = useCallback(() => {
		if (!isFullScreen) {
			cameraRef?.current?.requestFullscreen().catch((err) => {
				alert(
					`Error attempting to enable fullscreen mode: ${err.message} (${err.name})`
				)
			})
		} else {
			document.exitFullscreen()
		}
	}, [isFullScreen, cameraRef])

	const handleFullScreenChange = useCallback(
		() => setIsFullScreen(!!document.fullscreenElement),
		[]
	)

	useEffect(() => {
		document.addEventListener('fullscreenchange', handleFullScreenChange)

		return () => {
			document.removeEventListener('fullscreenchange', handleFullScreenChange)
		}
	}, [handleFullScreenChange])

	let icon
	if (isFullScreen) {
		icon = <MdOutlineCloseFullscreen />
	} else {
		icon = <MdOutlineFullscreen size={24} />
	}

	return (
		<IconButton
			icon={icon}
			variant='unstyled'
			onClick={handleClick}
			aria-label='full screen'
			color='camera.control_color'
			display='flex'
			justifyContent='center'
			alignItems='center'
		/>
	)
}

export default FullScreenButton

//
