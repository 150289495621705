import { nanoid } from '@reduxjs/toolkit'
import {
	Switch as ChakraSwitch,
	type SwitchProps as ChakraSwitchProps,
	FormLabel,
	FormControl,
} from '@chakra-ui/react'

type SwitchProps = ChakraSwitchProps & {
	label: string
	testId?: string
}

const Switch = ({
	label,
	isChecked,
	defaultChecked,
	onChange,
	testId,
}: SwitchProps) => {
	const id = nanoid()
	return (
		<FormControl
			id={id}
			gap='12px'
			alignItems='center'
			display='flex'
			alignSelf='center'
		>
			<ChakraSwitch
				onChange={onChange}
				isChecked={isChecked}
				defaultChecked={defaultChecked}
				data-testid={testId}
			/>
			<FormLabel
				htmlFor={id}
				fontSize='sm'
				color='label_color'
				userSelect='none'
				mb={0}
			>
				{label}
			</FormLabel>
		</FormControl>
	)
}

export default Switch
