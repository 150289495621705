// React
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
// React-table
import { createColumnHelper } from '@tanstack/react-table'
// Chakra
import { Flex, Box, useDisclosure } from '@chakra-ui/react'
import { MdDelete } from 'react-icons/md'
// Components
import { ClientFormModal } from '@Forms/ClientForm/ClientForm'
import IconButton from '@UI/IconButton/IconButton'
import DeleteModal from '@UI/Modals/DeleteModal'
import UsersDataTable from '@UI/UsersDataTable/UsersDataTable'
// Store
import type { Client } from '@Store/types'
import {
	useDeleteClientMutation,
	useGetClientsListQuery,
} from '@Store/clients/clientsApi'
import { useGetSitesListQuery } from '@Store/sites/sitesApi'
import Skeleton from '@UI/Skeleton/Skeleton'

const UserManagementClientsTable = () => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const { t } = useTranslation('pages', {
		keyPrefix: 'userManagement',
	})

	const { data: clients, isSuccess: isClientsSuccess } =
		useGetClientsListQuery(undefined)
	const { data: sites, isSuccess: isSitesSuccess } = useGetSitesListQuery()

	const clientsWithSiteNames = useMemo(() => {
		const clientsSiteNames = (clientId: number) => {
			return sites
				?.filter((site) => site.client_id === clientId)
				.map((site) => site.name)
				.join(', ')
		}

		return clients?.map((client) => {
			const { id } = client
			return {
				...client,
				sites: client?.id ? clientsSiteNames(id) : '',
			}
		})
	}, [clients, sites])

	// DELETE CLIENT
	const [deleteClient] = useDeleteClientMutation()
	const [clientToDelete, setClientToDelete] = useState<{
		id: number
		name: string
	} | null>({ name: '', id: 0 })

	const handleOpenDeleteModal = (id: number, name: string) => {
		onOpen()
		setClientToDelete((prev) => ({ ...prev, id, name }))
	}

	const handleDeleteClient = async () => {
		try {
			if (clientToDelete) {
				await deleteClient({ clientId: Number(clientToDelete.id) }).unwrap()
				onClose()
			}
		} catch (error) {
			console.error('Delete client error', error)
		}
	}

	const handleCloseDeleteModal = () => {
		onClose()
		setClientToDelete(null)
	}

	const columnsWidth = {
		id: '245px',
		name: '245px',
		contact: '245px',
		email: '270px',
		address: '245px',
		city: '245px',
		state: '245px',
		sites: '245px',
		actions: '80px',
	}

	const columns = Object.entries(columnsWidth).map(([field, width]) => {
		if (field !== 'actions') {
			return createColumnHelper<Client>().accessor(field as keyof Client, {
				cell: (info) => info.getValue(),
				header: field,
			})
		} else {
			return {
				header: t('table.actions'),
				cell: (
					{ row }: any // Probably CellContext[row] lets skip it please for now
				) => (
					<Flex>
						<ClientFormModal clientId={row?.original?.id} />
						<IconButton
							onClick={() =>
								handleOpenDeleteModal(row?.original?.id, row?.original?.name)
							}
							icon={<MdDelete />}
							aria-label='delete-client'
							testId='delete-client'
						/>
					</Flex>
				),
			}
		}
	})

	return (
		<Skeleton isLoaded={isClientsSuccess && isSitesSuccess}>
			<UsersDataTable
				columns={columns}
				data={clientsWithSiteNames || []}
				variant='client'
				actionComponent={
					<Box>
						<ClientFormModal clientId={0} />
					</Box>
				}
			/>
			{/*DELETE CLIENT*/}
			<DeleteModal
				isOpen={isOpen}
				onClose={handleCloseDeleteModal}
				handleDelete={handleDeleteClient}
				name={clientToDelete?.name || ''}
				headerText={t('headings.deleteClient')}
				fromText={t('headings.fromInstance')}
			/>
		</Skeleton>
	)
}

export default UserManagementClientsTable
