// React
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
// Components
import Field from '@Components/FormElements'
import UserFormSitesAssociation from '@Forms/UserForm/UserFormSitesAssociation'
import { FormButtons } from '@Components/FormElements/FormButtons/FormButtons'
import type { FormSelectValueType } from '@Components/FormElements/Select/Select'
// Forms
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
	userFormSchema,
	type UserSchema,
} from '@Forms/UserForm/UserForm.schema'
// Store
import { useAppSelector } from '@/store'
import { useCreateUserMutation } from '@Store/user/usersApi'
import { selectUserSiteAssociations } from '@Store/ui/uiSlice'
import type { User } from '@Store/types'

const UserFormAdd = ({
	defaultValues,
	onClose,
	clientsOptionsReady,
	clientsOptions,
	roleOptions,
}: {
	defaultValues: UserSchema
	onClose: () => void
	clientsOptionsReady: boolean
	clientsOptions: FormSelectValueType[]
	roleOptions: FormSelectValueType[]
}) => {
	const { t } = useTranslation('forms', { keyPrefix: 'userForm' })
	const methods = useForm<UserSchema>({
		resolver: zodResolver(userFormSchema),
		defaultValues,
	})

	const {
		register,
		formState: { errors, isSubmitting, isDirty },
		handleSubmit,
		setError,
		watch,
		setValue,
	} = methods

	const [createUser] = useCreateUserMutation()

	const handleSave = async (payload: UserSchema) => {
		try {
			await createUser({
				...payload,
			}).unwrap()
			onClose()
		} catch (errors) {
			// Surface server-side validation errors to react-hook-form
			for (const field in errors as {
				[name in keyof UserSchema]: string
			}) {
				setError(field as keyof UserSchema, {
					type: 'custom',
					message: (errors as { [name in keyof UserSchema]: string })[
						field as keyof UserSchema
					] as string,
				})
			}
		}
	}

	const handleCancel = () => onClose()

	// Handle site associations
	const siteAssociations = useAppSelector(selectUserSiteAssociations)
	useEffect(() => {
		setValue('site_associations', siteAssociations, {
			shouldDirty: !!siteAssociations?.length,
		})
	}, [siteAssociations, setValue])

	const [role, client_id] = watch(['role', 'client_id'])
	const isAdminRoleSelected = role === 'admin'
	const showClientsOptions = clientsOptionsReady && !isAdminRoleSelected

	return (
		<>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(handleSave)}>
					<Field.TextInput
						isRequired
						title={t('api.name')}
						register={register('name')}
						error={errors?.name?.message}
						testId='name'
					/>

					<Field.TextInput
						isRequired
						title={t('api.email')}
						register={register('email')}
						error={errors?.email?.message}
						testId='email'
					/>

					<Field.TextInput
						isRequired
						title={t('api.username')}
						register={register('username')}
						error={errors?.username?.message}
						testId='username'
					/>

					<Field.TextInput
						title={t('api.company')}
						register={register('company')}
						error={errors?.company?.message}
						testId='company'
					/>

					<Field.PhoneNumberInput
						title={t('api.phone')}
						register={register('phone')}
						countryCodeField={'phone_region'}
						error={errors?.phone?.message}
						testId='phone'
					/>

					<Field.PasswordInput
						title={t('api.password')}
						register={register('password')}
						error={errors?.password?.message}
						testId='password'
						isRequired
					/>

					<Field.PasswordInput
						title={t('api.password_confirmation')}
						register={register('password_confirmation')}
						error={errors?.password_confirmation?.message}
						testId='password-confirmation'
						isRequired
					/>

					{!!roleOptions.length && (
						<Field.Select
							title={t('headings.role')}
							register={register('role')}
							defaultValue={defaultValues?.role}
							options={roleOptions}
							error={errors?.role?.message}
							testId='role'
						/>
					)}

					{showClientsOptions && (
						<Field.Select
							title={t('headings.client')}
							options={clientsOptions}
							register={register('client_id', { valueAsNumber: true })}
							error={errors?.client_id?.message}
							testId='client'
						/>
					)}
					<UserFormSitesAssociation
						clientId={
							(client_id || defaultValues.client_id) as User['client_id']
						}
						role={role}
						user={defaultValues as UserSchema}
					/>

					<FormButtons
						isSubmitting={isSubmitting}
						isDirty={isDirty}
						handleCancel={handleCancel}
					/>
				</form>
			</FormProvider>
		</>
	)
}

export default UserFormAdd
