import { baseApi } from '@Store/baseApi'

import type { Note, Site } from '../types'

type GetNotesRequest = { siteId: Site['id'] }
type CreateNotesRequest = { siteId: Site['id'] }

export const notesApi = baseApi
	.enhanceEndpoints({ addTagTypes: ['Notes'] })
	.injectEndpoints({
		endpoints: (builder) => ({
			getNotes: builder.query<Note[], GetNotesRequest>({
				query: ({ siteId }) => ({
					url: `/api/sites/${siteId}/notes`,
				}),
				providesTags: ['Notes'],
				transformResponse: (response: { notes: Note[] }) => response.notes,
			}),

			createNote: builder.mutation<Note, CreateNotesRequest>({
				query: ({ siteId, ...note }) => ({
					url: `/api/sites/${siteId}/notes`,
					method: 'POST',
					body: {
						note: { ...note },
					},
				}),
				invalidatesTags: ['Notes'],
			}),
		}),
	})

export const { useGetNotesQuery, useCreateNoteMutation } = notesApi
