// React
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useNavigate } from 'react-router-dom'
// Form
import { useForm, FormProvider } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import type { TypeOf } from 'zod'
import {
	mapMarkerFormSchema,
	ADD_MAP_MARKER_DEFAULT_VALUES,
	type MapMarkerSchema,
} from './MapMarkersForm.schema'
// Components
import Field from '@Components/FormElements'
import { FormButtons } from '@Components/FormElements/FormButtons/FormButtons'
import FormHeader from '@Components/Forms/FormHeader'
import Headings from '@Components/_UI/Headings/Headings'
// Store
import {
	selectNewSiteMarkerNumber,
	selectSiteMarker,
	useCreateSiteMarkerMutation,
	useGetSiteMarkersQuery,
	useUpdateSiteMarkerMutation,
} from '@Store/siteMarkers/siteMarkersApi'
import FetchError from '@UI/FetchError/FetchError'
import { useAppDispatch, useAppSelector } from '@/store'
import { selectSiteMarkerData, setSiteMarkerData } from '@Store/ui/uiSlice'
import { selectSiteMapCenter, useGetSiteQuery } from '@Store/sites/sitesApi'
import ErrorBoundary from '@/components/App/ErrorHandling/ErrorBoundary'

const MapMarkersForm = ({ siteId }: { siteId: number }) => {
	const { t } = useTranslation('forms', { keyPrefix: 'mapMarkersForm' })
	const { markerId: markerIdParam } = useParams()
	const markerId = Number(markerIdParam)

	const isEditForm = !!markerId

	const {
		isError: isMarkerError,
		isLoading: isMarkerLoading,
		isSuccess: isMarkerSuccess,
		marker,
		newMarkerNumber,
		refetch,
	} = useGetSiteMarkersQuery(
		{ siteId },
		{
			refetchOnMountOrArgChange: true,
			selectFromResult: ({ isError, isSuccess, isLoading, data }) => ({
				isError,
				isLoading,
				isSuccess,
				marker: selectSiteMarker(data, markerId),
				newMarkerNumber: selectNewSiteMarkerNumber(data),
			}),
		}
	)
	// Map Center
	const {
		isError: isSiteError,
		isLoading: isSiteLoading,
		isSuccess: isSiteSuccess,
		mapCenter,
		refetch: siteRefetch,
	} = useGetSiteQuery(siteId, {
		skip: !siteId,
		selectFromResult: ({ isError, isSuccess, isLoading, data }) => ({
			isError,
			isLoading,
			isSuccess,
			mapCenter: selectSiteMapCenter(data),
		}),
	})

	const coordinates = useMemo(() => {
		if (isMarkerSuccess && marker)
			return { lat: Number(marker.lat), lng: Number(marker.lng) }
		if (isSiteSuccess && mapCenter)
			return { lat: Number(mapCenter.lat), lng: Number(mapCenter.lng) }
		return { lat: 0, lng: 0 }
	}, [isMarkerSuccess, isSiteSuccess, marker, mapCenter])

	// ADD lat and long as default values from Site Map Center
	const addMarkerDefaultValues = useMemo(() => {
		return {
			...ADD_MAP_MARKER_DEFAULT_VALUES,
			...coordinates,
			number: String(newMarkerNumber),
		}
	}, [newMarkerNumber, coordinates])

	const defaultValues = isEditForm ? marker : addMarkerDefaultValues

	// Dispatch
	const dispatch = useAppDispatch()
	// Dispatch Marker Data
	useEffect(() => {
		dispatch(setSiteMarkerData({ ...defaultValues, ...coordinates }))
	}, [coordinates, defaultValues, dispatch])

	return (
		<>
			{isSiteError && (
				<FetchError
					refetch={siteRefetch}
					errorMessage={t('errors.mapCenterError')}
				/>
			)}
			{isMarkerError && (
				<FetchError refetch={refetch} errorMessage={t('fetchStatus.error')} />
			)}
			{(isMarkerLoading || isSiteLoading) && <p>Loading</p>}
			{isSiteSuccess && isMarkerSuccess && defaultValues && (
				<Form
					isEditForm={isEditForm}
					defaultValues={defaultValues as MapMarkerSchema}
					siteId={siteId}
					markerId={markerId}
				/>
			)}
		</>
	)
}

const Form = ({
	isEditForm,
	defaultValues,
	siteId,
	markerId,
}: {
	isEditForm: boolean
	defaultValues: MapMarkerSchema
	siteId: number
	markerId: number
}) => {
	const navigate = useNavigate()
	const { t } = useTranslation('forms', { keyPrefix: 'mapMarkersForm' })

	type Schema = TypeOf<typeof mapMarkerFormSchema>

	const methods = useForm<Schema>({
		resolver: zodResolver(mapMarkerFormSchema),
		defaultValues,
	})
	const {
		register,
		formState: { errors, isSubmitting, dirtyFields },
		handleSubmit,
		setValue,
		watch,
	} = methods
	const isDirty = Object.values(dirtyFields).length > 0

	const [createMarker] = useCreateSiteMarkerMutation()
	const [updateMarker] = useUpdateSiteMarkerMutation()

	const handleSave = async (data: Schema) => {
		try {
			if (isEditForm) {
				await updateMarker({ siteId, markerId, ...data }).unwrap()
			} else {
				await createMarker({ siteId, ...data }).unwrap()
			}
			navigate(`/${siteId}/zones`)
		} catch (error) {
			console.error(t('errors.createUpdateError'), error)
		}
	}

	const handleCancel = () => navigate(`/${siteId}/zones`)
	// Watchers
	const { colour, nickname, number } = watch()

	const dispatch = useAppDispatch()
	const siteMarker = useAppSelector(selectSiteMarkerData)

	useEffect(() => {
		dispatch(
			setSiteMarkerData({
				colour,
				nickname,
				number,
			})
		)
	}, [dispatch, colour, nickname, number])

	const handleCoordinatesChange = (
		type: 'latitude' | 'longitude',
		value: string
	) => {
		const coordinateType = type === 'latitude' ? 'lat' : 'lng'
		dispatch(
			setSiteMarkerData({
				[coordinateType]: value,
			})
		)
	}

	useEffect(() => {
		setValue('lat', Number(siteMarker?.lat), { shouldDirty: true })
		setValue('lng', Number(siteMarker?.lng), { shouldDirty: true })
	}, [siteMarker, setValue, defaultValues])

	return (
		<>
			<FormHeader title={t('entity')} />
			<ErrorBoundary>
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(handleSave)}>
						<Headings.FormHeading
							title={isEditForm ? t('headings.edit') : t('headings.add')}
							textTransform='uppercase'
						/>
						<Field.TextInput
							title={t('nickname')}
							register={register('nickname')}
							error={errors?.nickname?.message}
							testId='map-marker-nickname'
						/>
						<Field.TextInput
							type='number'
							title={t('number')}
							register={register('number')}
							error={errors?.number?.message}
							testId='map-marker-number'
						/>

						<Field.LatLongInput
							title={t('lat')}
							type='latitude'
							defaultValue={siteMarker?.lat}
							value={siteMarker?.lat}
							error={errors?.lat?.message}
							testId='map-marker-latitude'
							onChange={(value) => handleCoordinatesChange('latitude', value)}
						/>

						<Field.LatLongInput
							title={t('lng')}
							defaultValue={siteMarker?.lng}
							value={siteMarker?.lng}
							type='longitude'
							error={errors?.lng?.message}
							testId='map-marker-longitude'
							onChange={(value) => handleCoordinatesChange('longitude', value)}
						/>

						<Field.ColorInput
							title={t('color')}
							register={register('colour')}
							error={errors?.colour?.message}
							testId='map-marker-color'
							onChange={(e) =>
								setValue('colour', e.target.value, {
									shouldDirty: true,
								})
							}
							value={colour}
						/>

						<FormButtons
							isSubmitting={isSubmitting}
							isDirty={isDirty}
							handleCancel={handleCancel}
						/>
					</form>
				</FormProvider>
			</ErrorBoundary>
		</>
	)
}

export default MapMarkersForm
