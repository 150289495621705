import { useState } from 'react'
import type { ReactNode } from 'react'
import {
	Flex,
	Icon,
	Tooltip as ChakraTooltip,
	type TooltipProps as ChakraTooltipProps,
} from '@chakra-ui/react'
import { MdInfo } from 'react-icons/md'

type TooltipProps = {
	children: ReactNode
	type?: 'wrapper' | 'info'
} & ChakraTooltipProps
const Tooltip = ({
	children,
	label,
	placement = 'right-start',
	type = 'wrapper',
	...rest
}: TooltipProps) => {
	const [isOpen, setIsOpen] = useState<boolean | undefined>(undefined)
	const handleInfoClick = () => {
		setIsOpen(!isOpen)
	}
	switch (type) {
		case 'info':
			return (
				<Flex position='relative' w='full'>
					{children}
					{label && (
						<ChakraTooltip
							label={label}
							placement={placement}
							closeOnClick={false}
							isOpen={isOpen}
							{...rest}
						>
							<Flex
								cursor='pointer'
								position='absolute'
								right='12px'
								top='18px' // 1rem from top field indent + half of bottom field indent
								color='button_text'
							>
								<Icon as={MdInfo} w={4} h={4} onTouchEnd={handleInfoClick} />
							</Flex>
						</ChakraTooltip>
					)}
				</Flex>
			)
		default:
			return (
				<ChakraTooltip
					label={label}
					placement={placement}
					closeOnClick={false}
					{...rest}
				>
					{children}
				</ChakraTooltip>
			)
	}
}

export default Tooltip
