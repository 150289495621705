import type { DetectionMarkerIconProps } from '.'

type UnknownMarkerIconProps = {
	color: DetectionMarkerIconProps['color']
}

const UnknownMarkerIcon = ({ color }: UnknownMarkerIconProps) => {
	return (
		<svg
			width='40px'
			height='40px'
			viewBox='0 0 40 40'
			version='1.1'
			xmlns='http://www.w3.org/2000/svg'
			xmlnsXlink='http://www.w3.org/1999/xlink'
		>
			<g fill={color} data-testid={`unknown-icon-${color}`}>
				<circle cx='20' cy='20' r='4' />
			</g>
		</svg>
	)
}

export default UnknownMarkerIcon
