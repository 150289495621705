import type { RfSensorFilter } from '@Store/types'
import { useEffect, useMemo, useState } from 'react'
import {
	DEFAULT_FREQUENCY,
	DEFAULT_FREQUENCY_BAND,
	DEFAULT_FREQUENCY_TOLERANCE,
	type FrequencyKeys,
} from '@Constants/sensorFilters'

const useDefaultFrequency = (
	defaultValues: RfSensorFilter | undefined,
	frequencyBand: string,
	isFilterByFrequency: boolean | undefined
) => {
	const frequencyValue = useMemo(() => {
		const defaultFrequencyBand = frequencyBand
			? frequencyBand
			: DEFAULT_FREQUENCY_BAND
		return defaultFrequencyBand?.replace('MHz', '') as FrequencyKeys
	}, [frequencyBand])

	const defaultFrequency = useMemo(() => {
		return DEFAULT_FREQUENCY[frequencyValue]
	}, [frequencyValue])

	const defaultFrequencyTolerance = useMemo(() => {
		return DEFAULT_FREQUENCY_TOLERANCE[frequencyValue]
	}, [frequencyValue])

	const [frequency, setFrequency] = useState(0)
	const [frequency_tolerance, setFrequencyTolerance] = useState(0)

	useEffect(() => {
		if (frequencyBand === defaultValues?.frequency_band) {
			if (defaultValues?.frequency && defaultValues.frequency_tolerance) {
				setFrequency(defaultValues.frequency)
				setFrequencyTolerance(defaultValues.frequency_tolerance)
			} else {
				setFrequency(defaultFrequency)
				setFrequencyTolerance(defaultFrequencyTolerance)
			}
		} else {
			setFrequency(defaultFrequency)
			setFrequencyTolerance(defaultFrequencyTolerance)
		}
	}, [
		isFilterByFrequency,
		defaultValues,
		frequencyBand,
		frequencyValue,
		defaultFrequency,
		defaultFrequencyTolerance,
	])

	return {
		defaultFrequency: frequency,
		defaultFrequencyTolerance: frequency_tolerance,
		frequencyValue,
	}
}

export default useDefaultFrequency
