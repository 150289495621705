import { Flex, Icon, Text } from '@chakra-ui/react'
import { MdError } from 'react-icons/md'

type InfoTextProps = {
	title: string
	lineHeight?: number
	children?: React.ReactNode
}

const InfoText = ({ title, lineHeight, children }: InfoTextProps) => (
	<Flex bgColor='input_bg' p={2} direction='column' gap={2}>
		<Flex alignItems='center' gap={1}>
			<Icon as={MdError} h={4} w={4} color='primary' />
			<Text fontSize='sm' as='strong'>
				{title}
			</Text>
		</Flex>
		{children && (
			<Text
				fontSize='sm'
				color='label_color'
				lineHeight={lineHeight}
				marginInlineStart={5}
				mb={2}
			>
				{children}
			</Text>
		)}
	</Flex>
)

export default InfoText
