import { useMemo } from 'react'
import type { RfSensor_SupportedDetections, RfSensorFilter } from '@Store/types'
import {
	DEFAULT_FREQUENCY_BAND,
	DEFAULT_VENDOR,
} from '@Constants/sensorFilters'

const useRfFilterFormOptions = ({
	supportedDetections,
	defaultValues,
	frequencyBand,
	vendor,
}: {
	supportedDetections: RfSensor_SupportedDetections | undefined
	defaultValues: RfSensorFilter
	frequencyBand: string
	vendor: string | undefined
}) => {
	const selectedFrequencyBand = frequencyBand
		? frequencyBand
		: DEFAULT_FREQUENCY_BAND

	const selectedVendor = vendor ? vendor : DEFAULT_VENDOR

	const enabledProtocols = useMemo(() => {
		if (supportedDetections) {
			return Object.values(supportedDetections)
				.find((detection) => detection.rfai_sub_band === selectedFrequencyBand)
				?.protocols.filter((protocol) => protocol.enabled)
		}
		return []
	}, [supportedDetections, selectedFrequencyBand])

	const bandOptions = useMemo(() => {
		if (supportedDetections) {
			return Object.values(supportedDetections)
				.map((detection) => detection?.rfai_sub_band)
				.filter((detection) => detection)
				.map((valueInMhz) => {
					const data = valueInMhz?.split('MHz')
					const value = Number(data[0]) / 1000
					const units = 'GHz'
					return {
						value: valueInMhz,
						label: `${value} ${units}`,
					}
				})
		} else {
			return []
		}
	}, [supportedDetections])

	const defaultBandOption = bandOptions.find(
		(option) => option.value === defaultValues.frequency_band
	)

	const vendorOptions = [
		...new Set(enabledProtocols?.map((protocol) => protocol.vendor)),
	].map((vendor) => {
		return {
			value: vendor,
			label: vendor,
		}
	})

	const defaultVendorOption = useMemo(() => {
		if (defaultValues.vendor) {
			return {
				value: defaultValues.vendor,
				label: defaultValues.vendor,
			}
		} else {
			return vendorOptions?.[0]
		}
	}, [defaultValues, vendorOptions])

	const protocolOptions = useMemo(() => {
		if (enabledProtocols) {
			return enabledProtocols
				.filter((protocol) => protocol.vendor === selectedVendor)
				.map((protocol) => {
					return {
						value: protocol.protocol,
						label: protocol.protocol,
					}
				})
		} else {
			return []
		}
	}, [enabledProtocols, selectedVendor])

	const defaultProtocolOption = useMemo(() => {
		const protocolsWithSelectedVendors = enabledProtocols?.filter(
			(protocol) => protocol.vendor === selectedVendor
		)
		const isDefaultProtocolOptionHasSelectedVendor =
			protocolsWithSelectedVendors?.some(
				(protocol) => protocol.protocol === defaultValues.protocol
			)
		if (isDefaultProtocolOptionHasSelectedVendor) {
			return {
				value: defaultValues.protocol,
				label: defaultValues.protocol,
			}
		} else if (protocolOptions.length) {
			return protocolOptions?.[0]
		} else if (defaultVendorOption?.value !== selectedVendor) {
			if (supportedDetections) {
				const defaultSelectedProtocols = Object.values(supportedDetections)
					.find(
						(detection) => detection.rfai_sub_band === selectedFrequencyBand
					)
					?.protocols.filter((protocol) => protocol.enabled)
					.filter((protocol) => protocol.vendor === defaultVendorOption?.value)
					.map((protocols) => {
						return {
							value: protocols.protocol,
							label: protocols.protocol,
						}
					})
				return defaultSelectedProtocols?.[0]
			}
		} else if (!defaultValues.protocol) {
			return {
				value: '',
				label: '',
			}
		}
	}, [
		defaultValues,
		protocolOptions,
		enabledProtocols,
		selectedVendor,
		defaultVendorOption,
		selectedFrequencyBand,
		supportedDetections,
	])

	return {
		bandOptions,
		vendorOptions,
		protocolOptions,
		defaultBandOption,
		defaultVendorOption,
		defaultProtocolOption,
	}
}

export default useRfFilterFormOptions
