import { memo } from 'react'
import Control from 'react-leaflet-custom-control'
import {
	CenterMapButton,
	CompassTool,
	MapLayerButton,
	ScreenshotTool,
	ZoomControl,
	CoordinatesControl,
} from '@Components/MapControls/index'
import type { LatLngExpression } from 'leaflet'
import MapLegendMeasurementWrapper from '@Components/MapControls/MapLegendMeasurementWrapper'
import { ScaleControl } from 'react-leaflet'

const MapControls = ({
	showCompassTool = true,
	showCenterMapButton = true,
	showZoomControl = true,
	showScreenshotTool = true,
	showMapLayerButton = true,
	showMeasurementTools = false,
	showMapLegend = false,
	showScaleControl = false,
	showCoordinatesControl = false,
	center,
	zoom,
}: {
	showCompassTool: boolean
	showCenterMapButton: boolean
	showZoomControl: boolean
	showScreenshotTool: boolean
	showMapLayerButton: boolean
	showMeasurementTools: boolean
	showMapLegend: boolean
	showScaleControl: boolean
	showCoordinatesControl: boolean
	center?: LatLngExpression
	zoom: number
}) => {
	return (
		<>
			{/* MapControls order: could be modified in theme/styles */}
			{/* Bottom Left */}
			{/* 1) Compass, 2) MapCenter, 3) Zoom, 4) Screenshot, 5) Measure, 6) MapLayer, 7) MapLegend*/}
			<Control prepend position='bottomleft'>
				{showCompassTool && <CompassTool />}
				{showCenterMapButton && <CenterMapButton center={center} zoom={zoom} />}
			</Control>
			{showZoomControl && <ZoomControl />}
			{showScreenshotTool && <ScreenshotTool />}
			{showMapLayerButton && <MapLayerButton />}
			<MapLegendMeasurementWrapper
				showMapLegend={showMapLegend}
				showMeasurementTools={showMeasurementTools}
			/>
			{/* Bottom Right */}
			{/* 1) Scale Control, 2) Coordinates control, 3) Map Attribution which invoked in Map.js*/}
			{showScaleControl && (
				<ScaleControl position='bottomright' imperial={false} />
			)}
			{showCoordinatesControl && (
				<Control position='bottomright'>
					<CoordinatesControl center={center} />
				</Control>
			)}
		</>
	)
}

export default memo(MapControls)
