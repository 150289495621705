import { defineStyleConfig } from '@chakra-ui/react'

const PopoverStyles = defineStyleConfig({
	baseStyle: {
		popper: {
			bg: 'none',
		},
		content: {
			borderRadius: 0,
			border: 'none',
			backgroundColor: 'panel_bg',
			pt: 4,
			pl: 4,
			color: 'label_color',
			w: '286px',
			maxHeight: '396px',
		},
		header: {
			fontSize: '16px',
			lineHeight: '16px',
			fontWeight: 600,
			textTransform: 'uppercase',
			p: 0,
			mb: 4,
			border: 'none',
			userSelect: 'none',
		},
		body: {
			overflowY: 'scroll',
			pt: 0,
			pb: 4,
			pr: 4,
			pl: 0,
		},
	},
})

export const Popover = {
	...PopoverStyles,
}
