import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { pdf } from '@react-pdf/renderer'
import { toPng } from 'html-to-image'

import { Box, Flex, Text } from '@chakra-ui/react'
import { MdDownload } from 'react-icons/md'

import Button from '@UI/Button/Button'
import Headings from '@UI/Headings/Headings'
import Modal from '@UI/Modals/Modal'

import { useLazyGetEventSummaryExportQuery } from '@Store/analytics/analyticsApi'

import EventReplay from './EventReplay'
import EventReport from '../EventReport'

import { formatEventId } from '@Utils/formatUtils'
import { downloadCsv, handleDownload } from '@Utils/download'
import type { EventSummary } from '@/store/types'

type EventDetailsModalProps = {
	isOpen: boolean
	onClose: () => void
	siteId: number
	isAlert?: boolean
	eventId: number
	eventSummary?: EventSummary
}

const EventDetailsModal = ({
	isOpen,
	onClose,
	siteId,
	isAlert,
	eventId,
	eventSummary,
}: EventDetailsModalProps) => {
	const { t } = useTranslation('pages', {
		keyPrefix: 'analytics.trackSummary.modal',
	})

	const [isPlaying, setIsPlaying] = useState<boolean>(false)
	const [currentTime, setCurrentTime] = useState<number>(0)
	const [isMapReady, setIsMapReady] = useState<boolean>(false)
	const [isFullScreen, setIsFullScreen] = useState(!!document.fullscreenElement)
	const fullScreenRef = useRef<HTMLDivElement | null>(null)

	const handleFullScreenChange = useCallback(
		() => setIsFullScreen(!!document.fullscreenElement),
		[]
	)

	useEffect(() => {
		document.addEventListener('fullscreenchange', handleFullScreenChange)

		return () => {
			document.removeEventListener('fullscreenchange', handleFullScreenChange)
		}
	}, [handleFullScreenChange])

	const handleOnClose = () => {
		setIsPlaying(false)
		setCurrentTime(0)
		onClose()
	}

	const [getEventSummaryExport] = useLazyGetEventSummaryExportQuery()

	const handleDownloadCsv = async () => {
		try {
			const { data } = await getEventSummaryExport({
				siteId,
				eventId: eventId,
			})
			downloadCsv(data, `event_${eventId}.csv`)
		} catch (error) {
			console.error('TODO: Unhandled events details error', error)
		}
	}

	const formattedEventId = formatEventId(eventSummary?.event.target_id ?? '')

	const captureMap = async () => {
		const mapElement = document.getElementById('event-replay-map')
		try {
			if (mapElement) {
				return await toPng(mapElement)
			}
		} catch (error) {
			console.error('TODO: Unhandled events details error', error)
		}
	}

	const downloadReport = async () => {
		if (eventSummary) {
			const { event, tracks } = eventSummary

			const mapImage = await captureMap()
			const blob = await pdf(
				<EventReport event={event} detections={tracks} mapImage={mapImage} />
			).toBlob()

			handleDownload(blob, `report_${event.id}.pdf`)
		}
	}

	return (
		<Modal
			isOpen={isOpen}
			onClose={handleOnClose}
			headerText={isAlert ? t('alertDetails') : t('eventDetails')}
			variant='analytics'
		>
			<Box ref={fullScreenRef}>
				<Flex justifyContent='space-between' h={12} p={3} alignItems='center'>
					<Flex gap={2}>
						<Headings.FieldHeading title={t('eventId')} />
						<Text fontSize='sm'>{formattedEventId}</Text>
					</Flex>
					<Flex alignItems='center' gap={1}>
						<Headings.FieldHeading title={t('export')} />
						<Button
							label='CSV'
							testId='csv'
							leftIcon={<MdDownload />}
							size='sm'
							onClick={() => handleDownloadCsv()}
						/>
						<Button
							label='PDF'
							testId='pdf'
							leftIcon={<MdDownload />}
							size='sm'
							isLoading={!isMapReady}
							onClick={downloadReport}
						/>
					</Flex>
				</Flex>
				<EventReplay
					fullScreenRef={fullScreenRef}
					isFullScreen={isFullScreen}
					eventId={eventId}
					siteId={siteId}
					isPlaying={isPlaying}
					setIsPlaying={setIsPlaying}
					currentTime={currentTime}
					setCurrentTime={setCurrentTime}
					setIsMapReady={setIsMapReady}
				/>
			</Box>
		</Modal>
	)
}

export default EventDetailsModal
