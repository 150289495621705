import { passwordSchema } from '@Forms/BaseForm/BaseForm.schema'
// Translations
import i18n from '@/i18n'
const t = (key: string) => i18n.t(`forms:userForm.errors.${key}`)

export const resetPasswordSchema = passwordSchema.refine(
	(values) => {
		return values.password === values.password_confirmation
	},
	{
		message: t('mustMatch'),
		path: ['password_confirmation'],
	}
)
