import {
	useGetSiteRadarAglMasksQuery,
	selectSiteRadarAglMaskBySensorId,
} from '@Store/radarAglMasks/radarAglMasksApi'
import type { Site, Radar } from '@Store/types'

import { Flex, Text, useDisclosure } from '@chakra-ui/react'
import EditButton from '../EditButton'
import Modal from '@Components/_UI/Modals/Modal'

import RadarAglMaskActiveSwitch from './AglMaskActiveSwitch'
import AglMaskPreview from './AglMaskPreview/AglMaskPreview'
import AglMaskLegend from './AglMaskPreview/AglMaskLegend'

import RadarAglMaskForm from '@Forms/RadarAglMaskForm/RadarAglMaskForm'
import { useTranslation } from 'react-i18next'

const AglMaskRange = {
	min: -3000,
	max: 3000,
}

type RadarAglMaskProps = {
	siteId: Site['id']
	sensorId: Radar['id']
}

const AglMask = ({ siteId, sensorId }: RadarAglMaskProps) => {
	const { onOpen, isOpen, onClose } = useDisclosure()
	const { t } = useTranslation('panels', { keyPrefix: 'aglMask' })
	const { radarAglMask, isSuccess } = useGetSiteRadarAglMasksQuery(
		{ siteId },
		{
			selectFromResult: ({ data, isSuccess }) => ({
				radarAglMask: selectSiteRadarAglMaskBySensorId(data, sensorId),
				isSuccess,
			}),
			skip: !siteId || !sensorId,
		}
	)

	return (
		isSuccess && (
			<>
				{radarAglMask && (
					<RadarAglMaskActiveSwitch
						siteId={siteId}
						radarAglMask={radarAglMask}
					/>
				)}

				<Flex justifyContent='flex-end'>
					{!radarAglMask && (
						<EditButton
							onClick={onOpen}
							type='add'
							label={t('createAglMask')}
							testId='create-agl-mask'
							disabled={radarAglMask ? true : false}
						/>
					)}
					{radarAglMask && (
						<EditButton
							onClick={onOpen}
							type='edit'
							testId='edit-agl-mask'
							disabled={!radarAglMask}
						/>
					)}
				</Flex>
				{radarAglMask?.enabled && (
					<>
						<AglMaskLegend />
						<AglMaskPreview
							min={AglMaskRange.min}
							max={AglMaskRange.max}
							value={[radarAglMask.min, radarAglMask.max]}
						/>
						<Flex direction='column' paddingBlockStart={2}>
							<Flex justifyContent={'center'} paddingBlock={2}>
								<Text fontSize='xs' color='label_color'>
									{t('detectionAreaLabel')}
								</Text>
							</Flex>
							<Flex justifyContent='center' alignItems='baseline' gap={2}>
								<Text fontSize='xs' color='label_color'>
									min
								</Text>
								<Text data-testid='agl-mask-min-preview'>
									{radarAglMask.min}
								</Text>
								<Text fontSize='xs' color='label_color'>
									max
								</Text>
								<Text data-testid='agl-mask-max-preview'>
									{radarAglMask.max}
								</Text>
							</Flex>
						</Flex>
					</>
				)}

				<Modal
					isOpen={isOpen}
					onClose={onClose}
					headerText={t('modalTitle')}
					variant='table'
				>
					<RadarAglMaskForm
						siteId={siteId}
						sensorId={sensorId}
						maskId={radarAglMask?.id}
						onClose={onClose}
					/>
				</Modal>
			</>
		)
	)
}

export default AglMask
