import { useEffect, useState } from 'react'
import type { StatusColor } from '@Store/types'
import { useTranslation } from 'react-i18next'

import { Flex, Icon, Text } from '@chakra-ui/react'
import { MdFiberManualRecord } from 'react-icons/md'

import { useGetSystemStatsQuery } from '@Store/system/systemApi'
import { useAppSelector, useAppDispatch } from '@Store/index'
import {
	selectNetworkAuthenticating,
	selectNetworkDisconnected,
	setNetworkSlowLinkEvent,
	clearNetworkSlowLinkEvent,
} from '@Store/system/systemSlice'
import { getStatusColor } from '@Utils/functions'

const SystemStatus = () => {
	const { t } = useTranslation('navigation', { keyPrefix: 'settingsMenu' })
	const dispatch = useAppDispatch()

	const {
		data: stats,
		isSuccess,
		isFetching,
		isLoading,
		isError,
	} = useGetSystemStatsQuery()

	const isRealtimeAuthenticating = useAppSelector(selectNetworkAuthenticating)
	const isRealtimeDisconnected = useAppSelector(selectNetworkDisconnected)
	const isRealtimeConnected =
		!isRealtimeAuthenticating && !isRealtimeDisconnected

	const [isConnected, setIsConnected] = useState<boolean>(false)

	// TODO: isSuccess is being set to true for a brief moment when isFetching goes true,
	// even though coreapi has been set to be completely non-responsive.
	// Needs more research. Meantime use a timer to control the isConnected state more carefully
	useEffect(() => {
		let errorTimeout: ReturnType<typeof setInterval> | null = null
		const handleConnected = () => {
			errorTimeout && clearTimeout(errorTimeout)
			setIsConnected(true)
			dispatch(clearNetworkSlowLinkEvent())
		}
		const handleDisconnected = () => setIsConnected(false)
		if (isSuccess && !isFetching) handleConnected()
		// Only mark disconnected if isError state persists for 1 second
		if (isError) {
			dispatch(setNetworkSlowLinkEvent())
			errorTimeout = setTimeout(handleDisconnected, 1000)
		}
	}, [dispatch, isSuccess, isFetching, isLoading, isError])

	const cpuUsage = Number(stats?.cpu_info.total_cpu_usage.toFixed(1))
	const memoryUsage = Number(stats?.memory_info.memory_used_percent.toFixed(1))
	const diskUsage = Number(stats?.disk_usage.used_percent.toFixed(1))

	return (
		<>
			<StatusItem
				color={isConnected ? 'green' : 'red'}
				label={t('systemStatus.apiStatus')}
				value={
					isConnected
						? t('systemStatus.connected')
						: t('systemStatus.disconnected')
				}
			/>
			<StatusItem
				color={isRealtimeConnected ? 'green' : 'red'}
				label={t('systemStatus.realtimeStatus')}
				value={
					isRealtimeConnected
						? t('systemStatus.connected')
						: t('systemStatus.disconnected')
				}
			/>
			<StatusItem
				color={isConnected ? getStatusColor(cpuUsage) : 'grey'}
				label={t('systemStatus.cpuUsage')}
				value={isConnected ? `${cpuUsage}%` : '-'}
			/>
			<StatusItem
				color={isConnected ? getStatusColor(memoryUsage) : 'grey'}
				label={t('systemStatus.memoryUsage')}
				value={isConnected ? `${memoryUsage}%` : '-'}
			/>
			<StatusItem
				color={isConnected ? getStatusColor(diskUsage) : 'grey'}
				label={t('systemStatus.diskUsage')}
				value={isConnected ? `${diskUsage}%` : '-'}
			/>
		</>
	)
}

type StatusItemProps = {
	color: StatusColor
	label?: string
	value?: string
}

const StatusItem = ({
	color = 'grey',
	label = 'N/A',
	value = 'N/A',
}: StatusItemProps) => (
	<Flex paddingInline={4} marginBlockEnd={2}>
		<Icon
			as={MdFiberManualRecord}
			color={`status.${color}`}
			boxSize={3}
			marginInlineStart={0.5}
			marginInlineEnd={2}
		/>
		<Flex direction='column'>
			<Text fontSize='xs' color='label_color' lineHeight={1}>
				{label}
			</Text>
			<Text fontSize='sm'>{value}</Text>
		</Flex>
	</Flex>
)

export default SystemStatus
