import { useTheme } from '@chakra-ui/react'
import type { Camera } from '@Store/types'
import Sector from '@Components/MapShapes/Sector/Sector'

// Utility
import { convertDegreeToRad, convertRadToDegree } from '@Utils/mathUtils'

type CameraSectorProps = {
	latitude: number
	longitude: number
	bearing: number
	isActive: boolean
} & Pick<Camera, 'max_fov_angle' | 'min_fov_angle' | 'pan' | 'zoom'>

// Functions taken from vue to calculate the camera sector angle
const calculateApparentFieldOfView = (
	zoom: number,
	AFOVmin: number,
	AFOVmax: number
) => {
	const b = Math.tan(convertDegreeToRad(AFOVmin / 2))
	const a = Math.tan(convertDegreeToRad(AFOVmax / 2))
	return convertRadToDegree(
		2 * Math.atan((a * b) / (zoom * zoom * a - zoom * b + b))
	)
}

// TODO: Add logic/functions/calculations for dragging, edit mode, active plan?
const CameraSector = ({
	latitude,
	longitude,
	bearing: sensorBearing = 0,
	max_fov_angle,
	min_fov_angle,
	pan = 0,
	zoom,
	isActive = false,
}: CameraSectorProps) => {
	const {
		semanticTokens: { colors },
	} = useTheme()

	const fillColor = colors.sectors.cameraFill
	const strokeColor = isActive
		? colors.sectors.highlight
		: colors.sectors.cameraStroke
	const strokeWeight = isActive ? 4 : 2

	// Magic numbers from vue :')
	// TODO: tilting up reduces the reach
	const reach = 700 + 500 * zoom
	const AFOV = calculateApparentFieldOfView(zoom, min_fov_angle, max_fov_angle)
	const bearing = (sensorBearing + pan * 180) % 360

	return (
		<Sector
			latitude={latitude}
			longitude={longitude}
			reach={reach}
			bearing={bearing}
			angle={AFOV}
			color={strokeColor}
			fillColor={fillColor}
			weight={strokeWeight}
		/>
	)
}

export default CameraSector
