import {
	Modal as ChakraModal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	type ModalProps as ChakraModalProps,
} from '@chakra-ui/react'

type ModalProps = {
	isOpen: boolean
	onClose: () => void
	headerText: string
	variant?: 'table' | 'analytics' | 'sm'
	children: React.ReactNode
	size?: string
	contentHeight?: string
	isScrollable?: boolean
} & ChakraModalProps

const Modal = ({
	isOpen,
	onClose,
	headerText,
	children,
	variant,
	size,
	contentHeight,
	isScrollable = false,
	...rest
}: ModalProps) => {
	return (
		<ChakraModal
			isOpen={isOpen}
			onClose={onClose}
			variant={variant}
			isCentered
			size={size}
			{...rest}
		>
			<ModalOverlay />
			<ModalContent maxH={contentHeight}>
				<ModalHeader>{headerText}</ModalHeader>
				<ModalCloseButton data-testid='close-modal' />
				<ModalBody p={0} overflowY={isScrollable ? 'auto' : undefined}>
					{children}
				</ModalBody>
			</ModalContent>
		</ChakraModal>
	)
}

export default Modal
