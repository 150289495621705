import {
	extendTheme,
	withDefaultColorScheme,
	type ThemeConfig,
} from '@chakra-ui/react'
// Foundation
import { colors } from './foundations/colors'
import { fonts } from './foundations/fonts'
import { shadows } from './foundations/shadows'
import semanticTokens from './foundations/semanticTokens'
// Components
import { Accordion } from './components/Accordion'
import { Alert } from './components/Alert'
import { Badge } from './components/Badge'
import { Button } from './components/Button'
import { Card } from './components/Card'
import { Form } from './components/Form'
import { FormError } from './components/FormError'
import { FormLabel } from './components/FormLabel'
import { Input } from './components/Input'
import { Menu } from './components/Menu'
import { NumberInput } from './components/NumberInput'
import { Select } from './components/Select'
import { Slider } from './components/Slider'
import { Tooltip } from './components/Tooltip'
import { Table } from './components/Table'
import { Textarea } from './components/Textarea'
import { Modal } from './components/Modal'
import { Tabs } from './components/Tabs'
import { Stepper } from './components/Stepper'
import { Switch } from './components/Switch'
import { Popover } from './components/Popover'

// Styles
import { styles } from './styles'

const config: ThemeConfig = {
	initialColorMode: 'dark',
	useSystemColorMode: false,
	cssVarPrefix: 'c2',
}

const overrides = {
	styles,
	colors,
	fonts,
	shadows,
	semanticTokens,
	components: {
		Accordion,
		Alert,
		Badge,
		Button,
		Card,
		Form,
		FormError,
		FormLabel,
		Input,
		Menu,
		NumberInput,
		Select,
		Slider,
		Tooltip,
		Table,
		Textarea,
		Modal,
		Tabs,
		Stepper,
		Switch,
		Popover,
	},
}

export default extendTheme(
	{
		config,
		...overrides,
	},
	withDefaultColorScheme({ colorScheme: 'primary' })
)
