import { defineStyleConfig } from '@chakra-ui/react'

const FormStyles = defineStyleConfig({
	baseStyle: {
		container: {
			marginTop: '1rem',
		},
	},
})

export const Form = {
	...FormStyles,
}
