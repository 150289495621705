// React
import { useTranslation } from 'react-i18next'
// Chakra
import { MdSend } from 'react-icons/md'
import { IconButton } from '@chakra-ui/react'
// Store
import { useAppDispatch } from '@/store'
import { addToast } from '@Store/ui/uiSlice'
import { useSendTestNotificationMutation } from '@Store/notifications/notificationRecipientsApi'
import type { FormError } from '@Forms/types'
import type { NotificationRecipient } from '@Store/types'

const AlertRecipientSend = ({
	siteId,
	recipient,
}: {
	siteId: number
	recipient: NotificationRecipient
}) => {
	const { t } = useTranslation('pages', {
		keyPrefix: 'alertSettings.alertRecipientsTable',
	})
	const dispatch = useAppDispatch()
	const [sendTestNotification] = useSendTestNotificationMutation()

	const handleSendTest = async () => {
		try {
			await sendTestNotification({
				siteId,
				id: recipient.id,
			})
				.unwrap()
				.then(() => {
					dispatch(
						addToast({
							status: 'success',
							title: t('status.success'),
							description: t('status.sendSuccess'),
							siteId,
						})
					)
				})
		} catch (e: FormError | unknown) {
			const error = (e as FormError)?.data?.error
			dispatch(
				addToast({
					type: 'error',
					title: t('status.error'),
					description: error,
					siteId,
					error: error,
				})
			)
			throw new Error(error)
		}
	}

	return (
		<IconButton
			onClick={handleSendTest}
			icon={<MdSend />}
			isDisabled={!recipient.enabled}
			aria-label={t('actions.sendTestAlert')}
			title={t('actions.sendTestAlert')}
			variant='actions'
		/>
	)
}

export default AlertRecipientSend
