import {
	useGetSiteLiveQuery,
	selectSiteInstallations,
} from '@Store/sites/sitesWsApi'
import { Card, CardHeader, CardBody, Flex, Text } from '@chakra-ui/react'
import EditButton from './EditButton'

import type { SiteInstallation } from '@Store/types'

import Installation from './Installation'
import UnregisteredSensors from './UnregisteredDevices'
import useAuth from '@Hooks/useAuth'
import { useTranslation } from 'react-i18next'

const Installations = ({ siteId }: { siteId: number }) => {
	const { installations } = useGetSiteLiveQuery(siteId, {
		selectFromResult: ({ data }) => ({
			installations: selectSiteInstallations(data),
		}),
	})
	const { isSiteManager } = useAuth()
	const { t } = useTranslation('panels', {
		keyPrefix: 'siteInstallations.headings',
	})
	return (
		<Card className='Installations' mb={0}>
			<CardHeader>
				<Flex justifyContent='space-between' alignItems='center'>
					<Text textTransform='uppercase'>{t('installations')}</Text>
					{isSiteManager && (
						<EditButton
							to={`/${siteId}/installation/add`}
							type='add'
							testId='add-installation'
						/>
					)}
				</Flex>
			</CardHeader>

			<UnregisteredSensors siteId={siteId} />

			<CardBody>
				{installations?.map((installation: SiteInstallation) => (
					<Installation key={installation.id} installation={installation} />
				))}
			</CardBody>
		</Card>
	)
}

export default Installations
