import { baseApi } from '@Store/baseApi'
import type { Site, Cot } from '@Store/types'

type CreateCotRequest = { siteId: Site['id'] } & Omit<Cot, 'id' | 'site_id'>
type GetCotRequest = { siteId: Site['id']; cotId: Cot['id'] }
type UpdateCotRequest = {
	siteId: Site['id']
	cotId: Cot['id']
	cot: Omit<Cot, 'id' | 'site_id'>
}
type DeleteCotRequest = { siteId: Site['id']; cotId: Cot['id'] }

export const cotsApi = baseApi
	.enhanceEndpoints({
		addTagTypes: ['Cots'],
	})
	.injectEndpoints({
		endpoints: (builder) => ({
			getCot: builder.query<Cot, GetCotRequest>({
				query: ({ siteId, cotId }) => ({
					url: `/api/sites/${siteId}/cursor_on_target_settings/${cotId}`,
				}),
				providesTags: ['Cots'],
				keepUnusedDataFor: 0,
				transformResponse: (response: { cot: Cot }) => response.cot,
			}),
			getCots: builder.query<Cot[], number>({
				query: (siteId) => ({
					url: `/api/sites/${siteId}/cursor_on_target_settings`,
				}),
				providesTags: ['Cots'],
				keepUnusedDataFor: 0,
				transformResponse: (response: { cots: Cot[] }) =>
					(response.cots ?? []).sort((a, b) => a.id - b.id),
			}),
			createCot: builder.mutation<Cot, CreateCotRequest>({
				query: ({ siteId, ...cot }) => ({
					url: `/api/sites/${siteId}/cursor_on_target_settings`,
					method: 'POST',
					body: {
						cot: {
							...cot,
							site_id: siteId,
						},
					},
				}),
				invalidatesTags: ['Cots'],
			}),
			updateCot: builder.mutation<Cot, UpdateCotRequest>({
				query: ({ siteId, cotId, cot }) => ({
					url: `/api/sites/${siteId}/cursor_on_target_settings/${cotId}`,
					method: 'PATCH',
					body: {
						cot,
					},
				}),
				invalidatesTags: ['Cots'],
			}),
			deleteCot: builder.mutation<{ message: string }, DeleteCotRequest>({
				query: ({ siteId, cotId }) => ({
					url: `/api/sites/${siteId}/cursor_on_target_settings/${cotId}`,
					method: 'DELETE',
				}),
				invalidatesTags: ['Cots'],
			}),
		}),
	})

export const {
	useGetCotQuery,
	useGetCotsQuery,
	useCreateCotMutation,
	useUpdateCotMutation,
	useDeleteCotMutation,
} = cotsApi
