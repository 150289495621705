import { memo } from 'react'
import MapMarker from '@Components/Markers/MapMarker/MapMarker'
import { useGetSiteLiveQuery, selectSiteMarkers } from '@Store/sites/sitesWsApi'
import { useAppSelector } from '@Store/index'
import { selectUserZoneSettings } from '@Store/user/userSlice'

type MapMarkersProps = {
	siteId: number
}

const MapMarkers = ({ siteId }: MapMarkersProps) => {
	const zoneSettings = useAppSelector(selectUserZoneSettings)
	const displayMarkers = zoneSettings?.showMarkers
	const { markers, isSuccess } = useGetSiteLiveQuery(siteId, {
		skip: !displayMarkers,
		selectFromResult: ({ data, isSuccess }) => ({
			markers: selectSiteMarkers(data),
			isSuccess,
		}),
	})
	return (
		displayMarkers &&
		isSuccess && (
			<>
				{markers?.map(({ id, lat, lng, nickname, number, colour }) => (
					<MapMarker
						key={id}
						latitude={Number(lat)}
						longitude={Number(lng)}
						number={+number}
						nickname={nickname}
						color={colour}
						interactive
					/>
				))}
			</>
		)
	)
}

export default memo(MapMarkers)
