import { type ReactNode, useMemo, useRef, useState, useEffect } from 'react'
import {
	Select as ChakraReactSelect,
	chakraComponents,
	// type ActionMeta,
	// type MultiValue,
	// type SingleValue,
	type ChakraStylesConfig,
	type GroupBase,
} from 'chakra-react-select'

import { MdArrowDropDown } from 'react-icons/md'
import { Box, Flex, Button, FormLabel, Text } from '@chakra-ui/react'
import {
	defaultSelectStyles,
	openedOnTopSelectStyles,
	siteModeSelectStyles,
	siteSelectStyles,
} from '@UI/Select/Select.styles'

import Tooltip from '../Tooltip/Tooltip'

export type Option<T> = {
	label: string
	value: T
	icon?: ReactNode
	isButton?: boolean
	onClick?: () => void
	additionalInfo?: string
	className?: string
}

type SelectProps<T> = {
	title?: string
	id?: string
	options: T[] | undefined
	onChange?: any
	defaultValue?: T | T[]
	value?: T
	isLoading?: boolean
	isDisabled?: boolean
	isSearchable?: boolean
	placeholder?: string
	variant?: 'default' | 'site_mode_select' | 'site_select' | 'opened_on_top'
	testId?: string
}

type SelectStylesType<T> = ChakraStylesConfig<T, boolean, GroupBase<T>>

// Custom component to swap out the default dropdown icon
// More info here: https://react-select.com/components
const DropdownIndicator = (props: any) => {
	return (
		<chakraComponents.DropdownIndicator style={{ margin: 0 }} {...props}>
			<MdArrowDropDown />
		</chakraComponents.DropdownIndicator>
	)
}

// Component to customise dropdown options. Here, we have the ability
// to add buttons as options or add icons/additional info to options
const CustomOption = ({ children, ...props }: any) => {
	const ref = useRef<HTMLParagraphElement>(null)
	const [isOverflown, setIsOverflown] = useState(false)

	useEffect(() => {
		const element = ref.current
		setIsOverflown(element ? element.offsetWidth < element.scrollWidth : false)
	}, [])

	if (props.data.isButton) {
		return (
			<chakraComponents.Option {...props} className={props.data.className}>
				<Button
					onClick={(e) => {
						e.stopPropagation()
						props.data.onClick()
					}}
					leftIcon={props.data.icon}
					variant='ghost'
					w='100%'
					h='100%'
				>
					{children}
				</Button>
			</chakraComponents.Option>
		)
	}

	return (
		<chakraComponents.Option {...props}>
			<Flex justify='space-between' width='100%'>
				<Flex gap={2} align='center'>
					{props.data.icon}
					<Tooltip label={isOverflown ? children : undefined} variant='dark'>
						<Text isTruncated ref={ref}>
							{children}
						</Text>
					</Tooltip>
				</Flex>
				{props.data.additionalInfo}
			</Flex>
		</chakraComponents.Option>
	)
}

// Component to customise the displayed selected option.
const SingleValue = (props: any) => (
	<chakraComponents.SingleValue {...props}>
		<Flex justify='space-between' gap={2}>
			<Flex gap={2} align='center'>
				{props.data.icon}
				{props.children}
			</Flex>
			{props.data.additionalInfo}
		</Flex>
	</chakraComponents.SingleValue>
)

function Select<T>({
	title = undefined,
	id,
	options,
	onChange,
	defaultValue,
	value,
	isDisabled = false,
	isSearchable = false,
	placeholder,
	variant = 'default',
	testId,
	...props
}: SelectProps<T>) {
	const selectStyles = useMemo(() => {
		switch (variant) {
			case 'site_mode_select':
				return siteModeSelectStyles as SelectStylesType<T>
			case 'site_select':
				return siteSelectStyles as SelectStylesType<T>
			case 'opened_on_top':
				return openedOnTopSelectStyles as SelectStylesType<T>
			default:
				return defaultSelectStyles as SelectStylesType<T>
		}
	}, [variant])

	const additionalComponents =
		variant === 'default'
			? undefined
			: { DropdownIndicator, Option: CustomOption, SingleValue }

	return (
		<Box data-testid={testId}>
			{title && (
				<FormLabel htmlFor={id} whiteSpace='pre'>
					{title}
				</FormLabel>
			)}

			<ChakraReactSelect
				id={id}
				useBasicStyles
				options={options}
				defaultValue={defaultValue}
				value={value}
				onChange={onChange}
				menuPortalTarget={document.body}
				focusBorderColor='transparent'
				isSearchable={isSearchable}
				isDisabled={isDisabled}
				placeholder={placeholder}
				components={additionalComponents}
				chakraStyles={selectStyles}
				styles={{
					menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
				}}
				{...props}
			/>
		</Box>
	)
}

export default Select
