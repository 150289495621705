import type { ReactNode } from 'react'
import { Flex } from '@chakra-ui/react'

type ActionSectionProps = {
	children: ReactNode
}
const ActionSection = ({ children }: ActionSectionProps) => {
	return (
		<Flex
			flexGrow={1}
			gap={2}
			justifyContent='flex-end'
			h={5}
			className='ActionSection'
		>
			{children}
		</Flex>
	)
}

export default ActionSection
