// Alias
import type { FormFieldsError, ServerError } from '@Store/utils/errorUtils'

// Types
import type { UseFormSetError } from 'react-hook-form'

export type setFormErrorProps = {
	error: ServerError | unknown
	setError: UseFormSetError<any>
}

export const setFormError = <T>({ error, setError }: setFormErrorProps) => {
	if (!setError) {
		throw new Error('setError is required')
	}

	if (!error) {
		return
	}

	if ((error as ServerError).status) {
		setError('root.serverError', {
			type: 'response.statusCode',
			message: String((error as ServerError).status),
		})
	} else {
		for (const field in error as FormFieldsError<T>) {
			setError(field, {
				type: 'custom',
				message: (error as FormFieldsError<T>)[field as keyof T],
			})
		}
	}
}
