import { ResponsiveLine, type Serie } from '@nivo/line'

import { useTheme } from '@chakra-ui/react'
import useChartTheme from './useChartTheme'

type LineChartProps = {
	data: Serie[]
}

const LineChart = ({ data }: LineChartProps) => {
	const {
		semanticTokens: { colors },
	} = useTheme()

	const theme = useChartTheme()

	const hasData = data.length > 0

	return (
		<ResponsiveLine
			data={data}
			theme={theme}
			margin={{ top: 15, right: 80, bottom: 55, left: 40 }}
			colors={[colors.charts.line]}
			xScale={{ type: 'point' }}
			yScale={{
				type: 'linear',
				min: 0,
				max: hasData ? 'auto' : 100,
			}}
			axisLeft={{
				format: (e) => (Math.floor(e) === e && e) || '',
			}}
			axisBottom={{ tickRotation: 15 }}
			pointSize={8}
			lineWidth={1}
			useMesh={hasData}
		/>
	)
}

export default LineChart
