import { defineStyleConfig } from '@chakra-ui/react'
import FIGMA from '../_FIGMA'

const SelectStyles = defineStyleConfig({
	baseStyle: {
		field: {
			_focusVisible: {
				border: '1px solid',
				borderColor: FIGMA.colors.primary,
			},
			_dark: {
				borderColor: 'transparent',
				background: FIGMA.colors.input_bg,
			},
		},
	},
})

export const Select = {
	defaultProps: {
		size: 'sm',
	},
	...SelectStyles,
}
