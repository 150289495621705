import { useEffect, useRef } from 'react'
import type { CSSProperties } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Center, Flex, Spinner } from '@chakra-ui/react'

// Redux
import { useAppSelector } from '@Store/index'
import { useAppDispatch } from '@Store/index'
import { setActiveCameraId } from '@Store/ui/uiSlice'
import {
	useGetSiteLiveQuery,
	selectCameraTrackedTargetId,
	selectCurrentCamera,
} from '@Store/sites/sitesWsApi'

// Janus
import { useJanus } from '@Components/Janus/JanusProvider'

// Camera Widgets
import CameraSwitcher from './Cameras/CameraSwitcher'
import WiperButton from './Cameras/WiperButton'
import GotoHomeButton from './Cameras/GotoHomeButton'
import RecordingButton from './Cameras/RecordingButton'
import FullScreenButton from './Cameras/FullScreenButton'
import JoystickControl from './Cameras/JoystickControl'
import ZoomControl from './Cameras/ZoomControl'
import CloseButton from './Cameras/CloseButton'
import RecordingStatus from './Cameras/RecordingStatus'
import ViewerCount from './Cameras/ViewerCount'
import InfraredButton from './Cameras/InfraredButton'
import DragButton from './Cameras/DragButton'

import DraggableWrapper from './Cameras/DraggableWrapper'
import useShowPreviewMode from '@Components/Site/MapLayers/PreviewModes/useShowPreviewMode'

const videoStyles = {
	width: '100%',
	height: 'auto',
	maxHeight: 'calc(100vh - 6rem)',
} as CSSProperties

const Cameras = () => {
	const { siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)

	const fullScreenRef = useRef<HTMLDivElement | null>(null)
	const dispatch = useAppDispatch()
	const cameraId = useAppSelector((state) => state.ui.activeCameraId)

	const { isReady, isStreaming, videoRef, setStreamId, viewerCount } =
		useJanus()

	const { trackedTargetId, trackedCameraId } = useGetSiteLiveQuery(siteId, {
		selectFromResult: ({ data }) => ({
			trackedTargetId: selectCameraTrackedTargetId(data),
			trackedCameraId: selectCurrentCamera(data)?.id,
		}),
	})

	useEffect(() => {
		if (isReady && cameraId) {
			setStreamId(cameraId)
		} else if (cameraId === null) {
			setStreamId(null)
		}
	}, [dispatch, isReady, setStreamId, cameraId])

	useEffect(() => {
		if (trackedTargetId) dispatch(setActiveCameraId(trackedCameraId))
	}, [dispatch, trackedTargetId, trackedCameraId])

	const { isPreviewRoute, showCameraCalibrationPreview } = useShowPreviewMode()
	const isFullscreen = document.fullscreenElement

	if (cameraId)
		return (
			<Flex
				ref={fullScreenRef}
				data-testid='camera-feed'
				direction='column'
				display={
					showCameraCalibrationPreview
						? 'flex'
						: isPreviewRoute
							? 'none'
							: undefined
				}
				w='calc(100% - 1rem)'
				maxW='36rem'
				h='auto'
				zIndex={2000}
				pointerEvents='none'
				marginInlineStart='auto'
				marginInlineEnd='0.5rem'
			>
				<DraggableWrapper>
					<Box position='relative' pointerEvents='all'>
						<Flex
							bgColor='camera.control_bg'
							height={10}
							cursor={isFullscreen ? 'default' : 'move'}
						>
							<CameraSwitcher />
							<Flex flexGrow={1} justifyContent='flex-end' alignItems='center'>
								<Flex
									flex={1}
									justifyContent='flex-end'
									className='CameraDragAction'
								>
									<DragButton />
								</Flex>
								<FullScreenButton cameraRef={fullScreenRef} />
								<CloseButton />
							</Flex>
						</Flex>
						<Box w='100%' bgColor='black'>
							<video
								ref={videoRef}
								style={isStreaming ? videoStyles : { display: 'none' }}
								autoPlay
								playsInline
								muted
								disablePictureInPicture
							/>
							{isStreaming ? (
								<>
									<Flex
										position='absolute'
										top={12}
										width='100%'
										justifyContent='space-between'
										gap={1}
										paddingInline={4}
									>
										<Box w={12} />
										<RecordingStatus cameraId={cameraId} />
										{viewerCount ? (
											<ViewerCount count={viewerCount} />
										) : (
											<Box w={12} />
										)}
									</Flex>
									<Flex position='absolute' bottom={16} right={4}>
										<Box marginInlineEnd={4}>
											<JoystickControl cameraId={cameraId} />
										</Box>
										<ZoomControl cameraId={cameraId} />
									</Flex>
								</>
							) : (
								<Center minH='288px'>
									<Spinner
										boxSize={100}
										thickness='4px'
										speed='0.6s'
										color='disabled'
									/>
								</Center>
							)}
						</Box>
						<Flex
							height={12}
							bgColor='camera.control_bg'
							alignItems='center'
							paddingInlineStart={4}
						>
							<RecordingButton cameraId={cameraId} isDisabled={false} />
							<WiperButton cameraId={cameraId} isDisabled={false} />
							<GotoHomeButton cameraId={cameraId} isDisabled={false} />
							<InfraredButton cameraId={cameraId} isDisabled={false} />
							<Flex
								flex={1}
								h='100%'
								marginInlineStart={4}
								justifyContent='flex-end'
								className='CameraDragAction'
								cursor={isFullscreen ? 'default' : 'move'}
							/>
						</Flex>
					</Box>
				</DraggableWrapper>
			</Flex>
		)
	else return null
}

export default Cameras
