// React
import { createRoot } from 'react-dom/client'

// Redux
import { Provider } from 'react-redux'
import { store } from '@Store/index'
import rehydrateUser from './store/user/rehydrateUser'

// App
import App from '@Components/App/App'

// Rehydrate user state
await rehydrateUser()

// Mount the App
createRoot(document.getElementById('root') as HTMLElement).render(
	<Provider store={store}>
		<App />
	</Provider>
)
