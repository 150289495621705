// React
import { useTranslation } from 'react-i18next'
// Form
import { useForm, FormProvider } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import type { TypeOf } from 'zod'
// Redux
import {
	useGetNotificationRecipientQuery,
	useUpdateNotificationRecipientMutation,
	useCreateNotificationRecipientMutation,
} from '@Store/notifications/notificationRecipientsApi'
import { selectAlertZones, useGetZonesQuery } from '@/store/zones/zonesApi'
import type {
	Site,
	NotificationRecipient as AlertRecipient,
} from '@Store/types'
// UI
import Field from '@Components/FormElements'
import { FormButtons } from '@Components/FormElements/FormButtons/FormButtons'
import FormWrapper from '@Components/FormElements/FormWrapper/FormWrapper'
import {
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react'
// Schema
import { alertRecipientFormSchema } from './AlertRecipientForm.schema'
import { addAlertRecipientDefaultValues } from './AlertRecipientForm.schema'
import { isValidPhoneNumber } from 'react-phone-number-input'
import isEmail from 'validator/lib/isEmail'
import { type FormSelectValueType } from '@Components/FormElements/Select/Select'
import { addToast } from '@Store/ui/uiSlice'
import { useAppDispatch } from '@/store'

// Alert Recipient Form Loader
type AlertRecipientFormProps = {
	siteId: number
	recipientId?: number
	onClose?: () => void
}

const AlertRecipientForm = ({
	siteId,
	recipientId,
	onClose,
}: AlertRecipientFormProps) => {
	const { t } = useTranslation('forms', { keyPrefix: 'alertRecipientForm' })
	const {
		isLoading,
		isError,
		isSuccess,
		isFetching,
		refetch,
		data: defaultValues,
	} = useGetNotificationRecipientQuery(
		{ siteId, id: recipientId ?? 0 },
		{
			skip: recipientId === undefined || !siteId,
			refetchOnMountOrArgChange: true,
			selectFromResult: ({
				isLoading,
				isError,
				isFetching,
				isSuccess,
				data,
			}) => ({
				isLoading,
				isError,
				isSuccess,
				isFetching,
				data,
			}),
		}
	)

	const isFormLoading = !isSuccess || (isFetching && isLoading)

	if (typeof recipientId === 'undefined') {
		return (
			<Form
				siteId={siteId}
				defaultValues={addAlertRecipientDefaultValues}
				onClose={onClose}
			/>
		)
	} else
		return (
			<FormWrapper
				entity={t('entity')}
				isEditForm={true}
				isLoading={isFormLoading}
				isError={isError}
				isSuccess={isSuccess}
				refetch={refetch}
				minH='432px'
			>
				<Form
					siteId={siteId}
					id={recipientId}
					defaultValues={defaultValues}
					onClose={onClose}
				/>
			</FormWrapper>
		)
}

// Site Alert Settings Form
const Form = ({
	siteId,
	id,
	defaultValues,
	onClose,
}: {
	siteId: Site['id']
	id?: AlertRecipient['id']
	defaultValues?: Partial<AlertRecipient>
	onClose?: () => void
}) => {
	type Schema = TypeOf<typeof alertRecipientFormSchema>

	// React Hook Form
	const methods = useForm<Schema>({
		resolver: zodResolver(alertRecipientFormSchema),
		defaultValues,
	})
	const {
		register,
		formState: { errors, isSubmitting, isDirty },
		handleSubmit,
		setError,
		setValue,
		watch,
	} = methods

	// Translations
	const { t } = useTranslation('forms', { keyPrefix: 'alertRecipientForm' })
	const { t: validationTrans } = useTranslation('forms', {
		keyPrefix: 'global.validation',
	})

	const { zones, isZonesReady } = useGetZonesQuery(
		{ siteId },
		{
			selectFromResult: ({ data, isSuccess }) => ({
				isZonesReady: isSuccess,
				zones: selectAlertZones(data).map((zone) => ({
					label: zone.name,
					value: zone.id,
				})),
			}),
		}
	)

	// RTK Query mutation(s)
	const [updateRecipient] = useUpdateNotificationRecipientMutation()
	const [createRecipient] = useCreateNotificationRecipientMutation()

	// email/sms field constants
	const [alertType, address] = watch(['address_type', 'address'])
	const isPhoneNumber = alertType === 'sms'

	const dispatch = useAppDispatch()

	const handleSave = async (payload: Schema) => {
		try {
			if (typeof id === 'number') {
				await updateRecipient({ ...payload, siteId, id }).unwrap()
				onClose && onClose()
			} else {
				await createRecipient({ ...payload, siteId }).unwrap()
				onClose && onClose()
			}
		} catch (errors) {
			// Surface server-side validation errors to react-hook-form

			for (const field in errors as { [name in keyof Schema]: string }) {
				const message = (errors as { [name in keyof Schema]: string })[
					field as keyof Schema
				] as string
				setError(field as keyof Schema, {
					type: 'custom',
					message,
				})
				// TODO: [C2-9219] Prevent closing window somehow in edit form and remove toast
				if (typeof id === 'number') {
					dispatch(
						addToast({
							type: 'error',
							title: `${t('toasts.errorUpdating')} ${field}`,
							description: message,
							siteId,
							error: field,
						})
					)
				}
			}
		}
	}

	const handleCancel = () => onClose && onClose()

	// Clear email or phone value when changing AlertType
	// and set an appropriate error message
	const handleAddressTypeChange = (option: FormSelectValueType) => {
		setValue('address_type', option.value as 'email' | 'sms')
		setValue('address', '')

		if (option.value === 'sms') {
			if (!isValidPhoneNumber(address)) {
				setError('address', {
					type: 'custom',
					message: validationTrans('phone'),
				})
			}
		} else {
			if (!isEmail(address)) {
				setError('address', {
					type: 'custom',
					message: validationTrans('email'),
				})
			}
		}
	}

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(handleSave)}>
				<Field.TextInput
					isRequired
					title={t('name')}
					register={register('name')}
					error={errors?.name?.message}
					testId='name'
				/>
				<Field.Select
					title={t('address_type')}
					register={register('address_type')}
					options={t('address_type_options', {
						returnObjects: true,
					})}
					error={errors?.address_type?.message}
					disabled={typeof id !== 'undefined'}
					testId='address-type'
					onCustomChange={handleAddressTypeChange}
				/>
				{isPhoneNumber ? (
					<Field.PhoneNumberInput
						title={t('address.sms')}
						register={register('address')}
						countryCodeField={'address_region'}
						error={errors?.address?.message}
						testId='phone'
						required
					/>
				) : (
					<Field.TextInput
						isRequired
						title={t('address.email')}
						register={register('address')}
						error={errors?.address?.message}
						testId='address'
						required
					/>
				)}
				<Field.Switch
					title={t('sensors_down')}
					register={register('sensors_down')}
					error={errors?.sensors_down?.message}
					testId='sensors-down-switch'
				/>
				<Field.Divider title={t('alertZonesHeading')} />
				{isZonesReady && (
					<Field.CheckboxGroup
						title={t('alertZonesDescription')}
						options={zones}
						register={register('zone_ids')}
						error={errors?.zone_ids?.message}
						testId='alert-zones'
					/>
				)}

				<FormButtons
					isSubmitting={isSubmitting}
					isDirty={isDirty}
					handleCancel={handleCancel}
				/>
			</form>
		</FormProvider>
	)
}

type AlertRecipientFormModalProps = {
	siteId: number
	recipientId?: number
	onClose: () => void
	isOpen: boolean
}

export const AlertRecipientFormModal = ({
	siteId,
	recipientId,
	onClose,
	isOpen,
}: AlertRecipientFormModalProps) => {
	const { t } = useTranslation('forms', { keyPrefix: 'alertRecipientForm' })
	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} isCentered>
				<ModalOverlay />
				<ModalContent minW={500} maxH='752px'>
					<ModalHeader data-testid='client-modal-header'>
						<Flex alignItems='center' gap='8px'>
							{recipientId
								? t('modal.editRecipient')
								: t('modal.createRecipient')}
						</Flex>
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody
						p='0 16px 16px 16px'
						overflowY='auto'
						data-testid='add-recipient-form'
					>
						<AlertRecipientForm
							siteId={siteId}
							recipientId={recipientId}
							onClose={onClose}
						/>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	)
}

export default AlertRecipientForm
