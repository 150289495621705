// React
import { memo, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useMap } from 'react-leaflet'
import { useAppDispatch, useAppSelector } from '@/store'
// Components
import SiteMarker from '@Components/Markers/SiteMarker/SiteMarker'
import useCenterMap from '@Components/Site/MapLayers/PreviewModes/useCenterMap'
import useValidCoordinates from '@Components/Site/MapLayers/PreviewModes/useValidCoordinates'
import Detections from '@Components/Site/MapLayers/Detections'
// Store
import {
	selectSiteLocationData,
	selectSiteZoom,
	setSiteLocationData,
} from '@Store/ui/uiSlice'
import type { LatLngExpression, LatLngLiteral } from 'leaflet'

const SitePreview = () => {
	const dispatch = useAppDispatch()
	const map = useMap()
	const siteLocationData = useAppSelector(selectSiteLocationData)
	const siteZoom = useAppSelector(selectSiteZoom)
	const { siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)

	const latitude = useMemo(() => siteLocationData?.latitude, [siteLocationData])
	const longitude = useMemo(
		() => siteLocationData?.longitude,
		[siteLocationData]
	)

	useEffect(() => {
		map.setZoom(siteZoom)
	}, [siteZoom, map])

	const coordinates = useValidCoordinates(latitude, longitude)

	useCenterMap({
		latitude: coordinates[0],
		longitude: coordinates[1],
		animation: false,
	})

	const handleDragEnd = (coords: LatLngLiteral) => {
		const { lat: latitude, lng: longitude } = coords
		dispatch(setSiteLocationData({ latitude, longitude }))
	}
	// Clean-up store after unmount
	useEffect(
		() => () => {
			dispatch(setSiteLocationData(null))
		},
		[dispatch]
	)

	if (!siteLocationData) return null
	const { accept_location_updates } = siteLocationData

	return (
		<>
			<Detections siteId={siteId} />
			<SiteMarker
				position={coordinates as LatLngExpression}
				onDragEnd={!accept_location_updates ? handleDragEnd : undefined}
				draggable={!accept_location_updates}
			/>
		</>
	)
}

export default memo(SitePreview)
