const Background = () => {
	return (
		<g className='agl-layer-background'>
			<rect
				width='100%'
				height='100%'
				fill='var(--c2-colors-primary)'
				fillOpacity={0.45}
			/>
		</g>
	)
}

export default Background
