import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setLeftPanelWidth } from '@Store/ui/uiSlice'
import { Outlet, useOutlet } from 'react-router-dom'

import { Box } from '@chakra-ui/react'

const LeftPanel = () => {
	const dispatch = useDispatch()
	const leftPanelWidth = 495

	const leftBarWidth = 60 + 8 // Left sidebar panel + indent
	const animationWidth = leftPanelWidth + leftBarWidth

	// Adjust the leaflet controls position when LeftPanel is open
	const leafletControlCss = leftPanelWidth
		? `.leaflet-left { left: calc(${leftPanelWidth}px + var(--c2-space-2)) }`
		: null

	const panelHasContent = useOutlet() ? true : false
	useEffect(() => {
		if (panelHasContent) dispatch(setLeftPanelWidth(leftPanelWidth))
		else dispatch(setLeftPanelWidth(0))
	}, [panelHasContent, dispatch])

	return (
		<>
			<Box
				className='LeftPanel'
				as='aside'
				flexShrink={0}
				pointerEvents='auto'
				overflowX='hidden'
				overflowY='scroll'
				bgColor='panel_bg'
				h='100%'
				width={leftPanelWidth}
				minWidth={leftPanelWidth}
				transition='all .3s'
				paddingInline={4}
				paddingBlockStart={3}
				paddingBlockEnd={4}
				_empty={{ transform: `translateX(-${animationWidth}px)` }}
				sx={{
					'& > div': {
						transition: 'width .3s',
					},
				}}
			>
				<Outlet />
			</Box>
			{panelHasContent && <style>{leafletControlCss}</style>}
		</>
	)
}

export default LeftPanel
