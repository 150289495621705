import { Icon } from '@chakra-ui/react'
import { MdRadioButtonChecked } from 'react-icons/md'

type StatusIconProps = {
	testId: string
	color?: string
}

const StatusIcon = ({ color, testId }: StatusIconProps) => {
	return (
		<Icon
			as={MdRadioButtonChecked}
			boxSize={6}
			color={color ?? 'magenta'}
			data-testid={testId}
		/>
	)
}

export default StatusIcon
