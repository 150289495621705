import { memo, type ReactNode, useRef } from 'react'
import Draggable, {
	type DraggableData,
	type DraggableEvent,
} from 'react-draggable'
import { Box } from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '@/store'
import {
	selectCameraPosition,
	setCameraPosition,
} from '@Store/cameras/camerasSlice'

const DraggableWrapper = ({ children }: { children: ReactNode }) => {
	const dispatch = useAppDispatch()
	const nodeRef = useRef<HTMLDivElement | null>(null)

	const isFullscreen = document.fullscreenElement ? true : false
	const cameraPosition = useAppSelector(selectCameraPosition)

	const handleDragEnd = (e: DraggableEvent, data: DraggableData) => {
		const { x, y } = data
		dispatch(setCameraPosition({ x, y }))
	}

	const handleDragStart = (e: DraggableEvent) => {
		if (isFullscreen) return false
	}

	return (
		<Draggable
			nodeRef={nodeRef}
			handle='.CameraDragAction'
			bounds='.Panels'
			position={isFullscreen ? { x: 0, y: 0 } : cameraPosition}
			onStart={handleDragStart}
			onStop={handleDragEnd}
			disabled={isFullscreen}
		>
			<Box ref={nodeRef}>{children}</Box>
		</Draggable>
	)
}

export default memo(DraggableWrapper)
