import { useMemo } from 'react'
import { Box, Flex } from '@chakra-ui/react'

const RfFiltersOverviewUnitsMarkers = ({
	min,
	max,
}: {
	min: number
	max: number
}) => {
	const stepSize = Math.abs(min - max) / 10
	const steps = useMemo(() => {
		const stepsArray = [min]
		for (let i = 1; i <= 10; i++) {
			stepsArray.push(min + stepSize * i)
		}
		return stepsArray
	}, [min, stepSize])

	const isInteger = (x: number): boolean => {
		return isFinite(x) && Math.floor(x) === x
	}

	const formatStep = (step: number) =>
		isInteger(step) ? step : step.toFixed(3)

	return (
		<Flex w='full' position='relative' userSelect='none' data-testid='steps'>
			{steps.map((step, index) => (
				<Box
					key={step}
					fontSize='12'
					flex={1}
					textAlign='center'
					position='relative'
					top='-6px'
				>
					<Box
						position='absolute'
						width='2px'
						bgColor='whiteAlpha.300'
						h={index === 0 || index === steps.length - 1 ? '14px' : '8px'}
						left='calc(50% - 1px)'
					/>
					<Box position='relative' top='16px'>
						{formatStep(step)}
					</Box>
				</Box>
			))}
		</Flex>
	)
}

export default RfFiltersOverviewUnitsMarkers
