import { defineStyleConfig } from '@chakra-ui/react'
import FIGMA from '../_FIGMA'

const FormLabelStyles = defineStyleConfig({
	baseStyle: {
		field: {
			_dark: {
				color: FIGMA.colors.label_color,
			},
		},
		fontSize: 'xs',
		fontWeight: 'normal',
		mb: '1',
		_dark: {
			color: FIGMA.colors.label_color,
			_after: {
				pl: 1,
				color: FIGMA.colors.error,
			},
		},
	},
})

export const FormLabel = {
	defaultProps: {
		size: 'xs',
	},
	...FormLabelStyles,
}
