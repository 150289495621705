import { useTranslation } from 'react-i18next'

// Form
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import type { TypeOf } from 'zod'

import { FormButtons } from '@Components/FormElements/FormButtons/FormButtons'

import {
	useGetGpsCompassQuery,
	useCreateGpsCompassMutation,
	useUpdateGpsCompassMutation,
} from '@Store/gpsCompasses/gpsCompassesApi'

import type { GpsCompass } from '@Store/types'
import { gpsCompassFormSchema } from './GpsCompassForm.schema'

import {
	useGetSiteInstallationsQuery,
	selectInstallationsOptions,
} from '@Store/sites/sitesApi'
import { skipToken } from '@reduxjs/toolkit/query'

import Field from '@Components/FormElements'
import FormHeader from '../FormHeader'
import GpsCompassWarning from './GpsCompassWarning'
import FormWrapper from '@Components/FormElements/FormWrapper/FormWrapper'
import ErrorBoundary from '@Components/App/ErrorHandling/ErrorBoundary'

type GpsCompassFormProps = {
	siteId: number
	installationId: number
	sensorId?: number
}

const GpsCompassForm = ({
	siteId,
	installationId,
	sensorId,
}: GpsCompassFormProps) => {
	const { t } = useTranslation('forms', { keyPrefix: 'gpsCompassForm' })
	const isEditForm = !!sensorId

	const {
		isLoading,
		isError,
		isSuccess,
		refetch,
		data: gpsCompass,
	} = useGetGpsCompassQuery(sensorId ? { siteId, sensorId } : skipToken, {
		refetchOnMountOrArgChange: true,
	})

	// Looks for props passed through from navigate() via Unregistered Sensors
	const { state } = useLocation()
	const addGpsCompassDefaultValues = {
		...state,
		sentry_id: installationId,
	} as GpsCompass

	const defaultValues = isEditForm ? gpsCompass : addGpsCompassDefaultValues
	return (
		<FormWrapper
			entity={t('entity')}
			isEditForm={isEditForm}
			isLoading={isLoading}
			isError={isError}
			isSuccess={isSuccess}
			refetch={refetch}
		>
			{defaultValues && (
				<Form
					key={sensorId}
					defaultValues={defaultValues}
					isEditForm={isEditForm}
				/>
			)}
		</FormWrapper>
	)
}

const Form = ({
	defaultValues,
	isEditForm,
}: {
	defaultValues: GpsCompass
	isEditForm: boolean
}) => {
	const { siteId, installationId, sensorId } = useParams()
	const navigate = useNavigate()

	const { t } = useTranslation('forms', { keyPrefix: 'gpsCompassForm' })

	type Schema = TypeOf<typeof gpsCompassFormSchema>

	const methods = useForm<Schema>({
		resolver: zodResolver(gpsCompassFormSchema),
		defaultValues,
	})
	const {
		register,
		formState: { errors, isSubmitting, isDirty },
		setError,
		handleSubmit,
	} = methods

	const [createGpsCompass] = useCreateGpsCompassMutation()
	const [updateGpsCompass] = useUpdateGpsCompassMutation()

	const gpsCompassesRoute = `/${siteId}/installations/${installationId}/gpscompass`

	const { installationsOptions, installationsOptionsReady } =
		useGetSiteInstallationsQuery(Number(siteId), {
			selectFromResult: ({ isSuccess, data }) => ({
				...selectInstallationsOptions(data),
				installationsOptionsReady: isSuccess,
			}),
		})

	const installationDefaultValue = installationsOptions.find(
		(option) =>
			option.value ===
			(isEditForm ? defaultValues.sentry_id : Number(installationId))
	)

	const handleSave = async (data: Schema) => {
		try {
			if (isEditForm) {
				await updateGpsCompass({
					id: Number(sensorId),
					siteId: Number(siteId),
					...data,
				}).unwrap()
			} else {
				await createGpsCompass({ siteId: Number(siteId), ...data }).unwrap()
			}
			navigate(gpsCompassesRoute)
		} catch (e: unknown) {
			const errors = e as { [name in keyof Schema]: string }
			for (const field in errors) {
				setError(field as keyof Schema, {
					type: 'custom',
					message: errors[field as keyof Schema] as string,
				})
			}
		}
	}

	const handleCancel = () => navigate(gpsCompassesRoute)

	return (
		<>
			<FormHeader
				title={
					isEditForm
						? t('headings.gpsCompassSettings')
						: t('headings.addGpsCompass')
				}
			/>
			<GpsCompassWarning />
			<Field.Divider title={t('headings.generalParameters')} />
			<ErrorBoundary>
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(handleSave)}>
						{installationsOptionsReady && (
							<Field.Select
								title={t('api.installation')}
								defaultValue={installationDefaultValue}
								register={register('sentry_id')}
								options={installationsOptions}
								error={errors?.sentry_id?.message}
							/>
						)}
						<Field.TextInput
							title={t('api.name')}
							register={register('name')}
							error={errors?.name?.message}
							testId='name'
						/>
						<Field.TextInput
							title={t('api.serialNumber')}
							register={register('serial_number')}
							disabled={isEditForm}
							tooltip={isEditForm ? t('tooltips.serial_number_disabled') : ''}
							error={errors?.serial_number?.message}
							testId='serial-number'
						/>
						<FormButtons
							isSubmitting={isSubmitting}
							isDirty={isDirty}
							handleCancel={handleCancel}
						/>
					</form>
				</FormProvider>
			</ErrorBoundary>
		</>
	)
}

export default GpsCompassForm
