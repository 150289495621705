// React
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
// Chakra
import { Box, Flex, Icon, Switch, Text } from '@chakra-ui/react'
import { MdError } from 'react-icons/md'
// Components
import MapLayersEditModal from '../Modals/EditMapLayerModal'
import MapLayersDeleteModal from '../Modals/DeleteMapLayerModal'
// Table
import TanStackTable from '@UI/TanStackTable/Table'
import Pagination from '@UI/TanStackTable/Pagination'
import {
	createColumnHelper,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	type PaginationState,
	useReactTable,
} from '@tanstack/react-table'
// Store
import type { MapLayer } from '@Store/types'
import { useUpdateMapLayerMutation } from '@Store/mapLayers/mapLayersApi'

const columnHelper = createColumnHelper<MapLayer>()

const MapLayerTableExpanded = ({ mapLayers }: { mapLayers?: MapLayer[] }) => {
	// Translations
	const { t } = useTranslation('forms', {
		keyPrefix: 'mapsZonesForm.mapLayersForm',
	})

	const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 25,
	})

	const pagination = useMemo(
		() => ({
			pageIndex,
			pageSize,
		}),
		[pageIndex, pageSize]
	)

	// Update map layer fallback
	const [isError, setIsError] = useState<boolean>(false)
	const isAnyFallback = mapLayers?.some((layer: MapLayer) => layer.fallback)
	const [updateMapLayer] = useUpdateMapLayerMutation()
	const handleFallbackChange = (layer: MapLayer) => {
		try {
			updateMapLayer({ ...layer, fallback: !layer.fallback })
				.unwrap()
				.then(() => setIsError(false))
		} catch (errors) {
			setIsError(true)
			console.error(errors)
		}
	}

	const columns = [
		columnHelper.accessor('fallback', {
			header: () => (
				<Text textAlign='center' w='full'>
					{t('fallbackTitle')}
				</Text>
			),
			cell: ({ getValue, row }) => {
				const fallback = getValue()
				const currentLayer = mapLayers?.find(
					(layer) => layer?.id === Number(row.original?.id)
				)
				if (!currentLayer) return null
				return (
					<Box w='full' textAlign='center'>
						<Switch
							isDisabled={isAnyFallback && !fallback}
							isChecked={fallback}
							onChange={() => handleFallbackChange(currentLayer)}
						/>
					</Box>
				)
			},
		}),
		columnHelper.accessor('name', {
			header: t('name'),
			cell: ({ getValue }) => (
				<Text
					maxW='280px'
					whiteSpace='nowrap'
					overflow='hidden'
					textOverflow='ellipsis'
				>
					{getValue()}
				</Text>
			),
		}),
		columnHelper.accessor('attribution', {
			header: t('attribution'),
			cell: ({ getValue }) => (
				<Text
					maxW='120px'
					whiteSpace='nowrap'
					overflow='hidden'
					textOverflow='ellipsis'
					textAlign='left'
				>
					{getValue()}
				</Text>
			),
		}),
		columnHelper.accessor('offline', {
			header: t('type'),
			cell: ({ getValue }) => <>{getValue() ? t('offline') : t('online')}</>,
		}),
		columnHelper.accessor('url', {
			header: t('url'),
			cell: ({ getValue }) => (
				<Text
					maxW='360px'
					whiteSpace='nowrap'
					overflow='hidden'
					textOverflow='ellipsis'
					textAlign='left'
				>
					{getValue() || '---'}
				</Text>
			),
		}),
		columnHelper.accessor('min_zoom', {
			header: () => (
				<Text textAlign='center' w='full'>
					{t('minZoom')}
				</Text>
			),
			cell: ({ getValue }) => (
				<Text textAlign='center' w='full'>
					{getValue()}
				</Text>
			),
		}),
		columnHelper.accessor('max_zoom', {
			header: () => (
				<Text textAlign='center' w='full'>
					{t('maxZoom')}
				</Text>
			),
			cell: ({ getValue }) => (
				<Text textAlign='center' w='full'>
					{getValue()}
				</Text>
			),
		}),
		columnHelper.display({
			header: t('actions'),
			cell: ({ row }) => {
				const currentLayer = mapLayers?.find(
					(layer) => layer?.id === Number(row.original.id)
				)
				if (!currentLayer) return null

				return (
					<Box w='full' textAlign='right'>
						<MapLayersEditModal layer={currentLayer} variant='icon' />
						<MapLayersDeleteModal layer={currentLayer} variant='icon' />
					</Box>
				)
			},
		}),
	]

	const table = useReactTable({
		data: mapLayers ?? [],
		columns,
		autoResetPageIndex: false,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		state: {
			pagination,
		},
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		onPaginationChange: setPagination,
	})

	if (!mapLayers?.length) return <>{t('noLayers')}</>

	return (
		<>
			{isError && (
				<Flex gap={2} alignItems='center' mb={2}>
					<Icon as={MdError} color='error' boxSize={5} />
					<Flex gap={1}>
						<Text fontSize='sm' fontWeight={500} alignSelf='center'>
							{t('fallbackError')}
						</Text>
					</Flex>
				</Flex>
			)}

			<TanStackTable table={table} />
			<Pagination table={table} totalCount={mapLayers.length ?? 0} />
		</>
	)
}

export default MapLayerTableExpanded
