import { z } from 'zod'

// Translations
import i18n from '@/i18n'
const t = (key: string, value: { [key: string]: string }) =>
	i18n.t(`forms:alertSettings.validation.${key}`, value) as string

const alertSettingsFormFields = z.object({
	zone_trigger_probability: z.number().min(0).max(0.8),
	minimum_display_probability: z.number().nonnegative().max(1).optional(),
	zone_trigger_detection_count: z.number().min(1).max(50),
	alert_suppression_time_window: z.number(),
	trigger_sensors_offline_alerts: z.boolean(),
})

export const alertSettingsFormSchema = alertSettingsFormFields.superRefine(
	(data, ctx) => {
		if (data.zone_trigger_probability && data.minimum_display_probability) {
			if (data.zone_trigger_probability < data.minimum_display_probability) {
				const probability = `${data.minimum_display_probability * 100}`
				ctx.addIssue({
					path: ['zone_trigger_probability'],
					message: t('zone_trigger_probability', { probability }),
					code: z.ZodIssueCode.custom,
				})
			}
		}
	}
)

export type AlertSettingsSchema = z.infer<typeof alertSettingsFormSchema>
