import { useTranslation } from 'react-i18next'

import { Box, Flex, Text, Icon } from '@chakra-ui/react'
import { MdError } from 'react-icons/md'

const GpsModuleError = () => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'devicesContent',
	})

	return (
		<Flex
			gap={1.5}
			marginBlockEnd={2}
			marginBlockStart={2}
			paddingInlineStart={2}
		>
			<Box w={6}>
				<Icon as={MdError} color='error' />
			</Box>
			<Box fontSize={14} paddingInlineEnd='1rem'>
				<Text as='span' fontWeight={500}>
					{t('rfContent.gpsModule')}:{' '}
				</Text>
				<Text as='span'>{t('rfContent.gpsModule400Error')}</Text>
			</Box>
		</Flex>
	)
}

export default GpsModuleError
