// Redux
import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { logout } from '../auth/authSlice'

// Types
import type { RootState } from '@Store/index'
import type {
	Camera,
	Detection,
	DetectionContribution,
	RfSensorPreview,
	CameraPreview,
	RadarPreview,
	Zone,
	RadarMaskPreview,
	DisruptorPreview,
	DsxPreview,
	RfFiltersPreview,
	SiteMarker,
	Site,
	SiteInstallation,
	UserSiteAssociation,
} from '../types'
import type { AlertProps, UseToastOptions } from '@chakra-ui/react'
import type { CoreApiError } from '@Store/utils/errorUtils'
import type { DetectionContributionSensor } from '../types'

export type Toast = AlertProps &
	UseToastOptions & {
		error: CoreApiError
		siteId: Site['id']
		id: string
		count: number
	}

// Initial State
type InitialState = {
	alertSoundEnabled: boolean
	activeCameraId: Camera['id'] | null
	leftPanelWidth: number
	activeTargetId: Detection['target_id'] | null
	sensorPreview:
		| RfSensorPreview
		| CameraPreview
		| DisruptorPreview
		| DsxPreview
		| RadarPreview
		| RadarMaskPreview
		| RfFiltersPreview
		| null
	zoneCoordinates: Zone['coordinates']
	siteMarkerData: {
		nickname: SiteMarker['nickname']
		number: SiteMarker['number']
		colour: SiteMarker['colour']
		lat: number
		lng: number
	} | null
	isZoneCoordinatesDirty: boolean
	detectionData: {
		seenContributors: {
			[targetId: Detection['target_id']]: DetectionContributionSensor[]
		} | null
		trackHistory: {
			[targetId: Detection['target_id']]: Array<{ lat: number; lng: number }>
		} | null
	}
	cameraCalibration: {
		activeStep: number
	}
	toasts: Record<string, Toast>
	selectedRfFiltersIds: number[]
	sentryData: Pick<
		SiteInstallation,
		| 'name'
		| 'latitude'
		| 'longitude'
		| 'accept_location_updates'
		| 'status_color'
		| 'sentry_type'
		| 'direction'
	> | null
	siteLocationData: Pick<
		Site,
		'latitude' | 'longitude' | 'accept_location_updates'
	> | null
	siteZoom: number
	userSiteAssociations: UserSiteAssociation[]
	showNetworkDisconnected: boolean
}

const initialState: InitialState = {
	alertSoundEnabled: true,
	activeCameraId: null,
	activeTargetId: null,
	cameraCalibration: {
		activeStep: 0,
	},
	detectionData: {
		seenContributors: null,
		trackHistory: null,
	},
	isZoneCoordinatesDirty: false,
	leftPanelWidth: 0,
	selectedRfFiltersIds: [],
	sensorPreview: null,
	sentryData: null,
	showNetworkDisconnected: false,
	siteLocationData: null,
	siteMarkerData: null,
	siteZoom: 12,
	toasts: {},
	userSiteAssociations: [],
	zoneCoordinates: [],
}

// UI slice
export const uiSlice = createSlice({
	name: 'ui',
	initialState: initialState,
	reducers: {
		setAlertSoundEnabled: (state, { payload: alertSoundEnabled }) => {
			state.alertSoundEnabled = alertSoundEnabled
		},
		setActiveCameraId: (state, { payload: cameraId }) => {
			state.activeCameraId = cameraId
		},
		setLeftPanelWidth: (state, { payload }) => {
			state.leftPanelWidth = payload
		},
		toggleActiveTargetId: (state, { payload: targetId }) => {
			state.activeTargetId = state.activeTargetId === targetId ? null : targetId
		},
		clearByTargetId: (state, { payload: targetId }) => {
			if (state.activeTargetId === targetId) state.activeTargetId = null
		},
		setActiveTargetId: (state, { payload: targetId }) => {
			state.activeTargetId = targetId
		},
		updateSensorPreview: (state, { payload }) => {
			state.sensorPreview = payload
		},
		setZoneCoordinates: (state, { payload: zoneCoordinates }) => {
			state.zoneCoordinates = zoneCoordinates
		},
		setSiteMarkerData: (state, { payload: siteMarker }) => {
			if (siteMarker === null) {
				state.siteMarkerData = null
			} else {
				state.siteMarkerData = {
					...state.siteMarkerData,
					...siteMarker,
				}
			}
		},
		setIsZoneCoordinatesDirty: (
			state,
			{ payload: isZoneCoordinatesDirty }: { payload: boolean }
		) => {
			state.isZoneCoordinatesDirty = isZoneCoordinatesDirty
		},
		updateSeenContributors: (
			state,
			{
				payload: { targetId, sensorType, sensorId },
			}: {
				payload: {
					targetId: Detection['target_id']
					sensorType: DetectionContribution['sensor_type']
					sensorId: DetectionContribution['sensor_id']
				}
			}
		) => {
			if (state.detectionData.seenContributors === null)
				state.detectionData.seenContributors = {
					[targetId]: [{ sensorType, sensorId }],
				}
			else if (!state.detectionData.seenContributors[targetId])
				state.detectionData.seenContributors[targetId] = [
					{ sensorType, sensorId },
				]
			else if (
				!state.detectionData.seenContributors[targetId].some(
					(seen) => seen.sensorId === sensorId && seen.sensorType === sensorType
				)
			)
				state.detectionData.seenContributors[targetId].push({
					sensorType,
					sensorId,
				})
		},
		addTrackHistory: (state, { payload: { targetId, lat, lng } }) => {
			//
			// TODO: limit the resolution and the length of this track history
			if (state.detectionData.trackHistory === null)
				state.detectionData.trackHistory = {
					[targetId]: [{ lat, lng }],
				}
			else if (!state.detectionData.trackHistory[targetId])
				state.detectionData.trackHistory[targetId] = [{ lat, lng }]
			else {
				const latest = state.detectionData.trackHistory[targetId].slice(-1)[0]
				if (latest.lat !== lat || latest.lng !== lng)
					state.detectionData.trackHistory[targetId].push({ lat, lng })
			}
		},
		deleteDetectionData: (state, { payload: targetId }) => {
			// C2-8548 - this data is needed for stale alerts
			// if (
			// 	state.detectionData.seenContributors !== null &&
			// 	state.detectionData.seenContributors[targetId]
			// )
			// 	delete state.detectionData.seenContributors[targetId]
			if (
				state.detectionData.trackHistory !== null &&
				state.detectionData.trackHistory[targetId]
			)
				delete state.detectionData.trackHistory[targetId]
			if (state.activeTargetId === targetId) state.activeTargetId = null
		},
		setCameraCalibrationStep: (state, { payload: step }) => {
			state.cameraCalibration.activeStep = step
		},
		addToast: (state, { payload: toast }) => {
			const { id } = toast
			if (state.toasts[id]) {
				state.toasts[id].count += 1
			} else {
				state.toasts[id] = { ...toast, count: 1 }
			}
		},
		deleteToast: (state, { payload: id }) => {
			delete state.toasts[id]
		},
		updateSelectedSensorFiltersIds: (state, { payload: filterId }) => {
			if (state.selectedRfFiltersIds.includes(filterId)) {
				state.selectedRfFiltersIds = state.selectedRfFiltersIds.filter(
					(id) => id !== filterId
				)
			} else {
				state.selectedRfFiltersIds = [...state.selectedRfFiltersIds, filterId]
			}
		},
		deleteSelectedSensorFiltersIds: (state, { payload: filterId }) => {
			state.selectedRfFiltersIds = []
		},
		setSentryData: (state, { payload: sentryData }) => {
			if (sentryData === null) {
				state.sentryData = null
			} else {
				state.sentryData = { ...state.sentryData, ...sentryData }
			}
		},
		setSiteLocationData: (state, { payload: siteLocationData }) => {
			if (siteLocationData === null) {
				state.siteLocationData = null
			} else {
				state.siteLocationData = {
					...state.siteLocationData,
					...siteLocationData,
				}
			}
		},
		setSiteZoom: (state, { payload: siteZoom }) => {
			state.siteZoom = siteZoom
		},
		updateUserSiteAssociations: (state, { payload: userSiteAssociations }) => {
			state.userSiteAssociations = userSiteAssociations
		},
		setShowNetworkDisconnected: (state, { payload }) => {
			state.showNetworkDisconnected = payload
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(isAnyOf(logout.fulfilled), () => {
			return initialState
		})
	},
})

// Selectors
export const selectActiveCameraId = (state: RootState) => {
	return state.ui.activeCameraId === null ? null : state.ui.activeCameraId
}

export const selectAlertSoundEnabled = (state: RootState) => {
	return state.ui.alertSoundEnabled
}

export const selectLeftPanelWidth = (state: RootState): number => {
	return state.ui.leftPanelWidth
}

export const selectActiveTargetId = (state: RootState) => {
	return state.ui.activeTargetId === null ? null : state.ui.activeTargetId
}

export const selectSensorPreview = (state: RootState) => {
	return state.ui.sensorPreview
}

export const selectZoneCoordinates = (state: RootState) => {
	return state.ui.zoneCoordinates
}

export const selectIsZoneCoordinatesDirty = (state: RootState) => {
	return state.ui.isZoneCoordinatesDirty
}

export const selectSiteMarkerData = (state: RootState) =>
	state.ui.siteMarkerData

export const selectSeenContributors =
	(targetId: Detection['target_id']) => (state: RootState) => {
		return (
			state.ui.detectionData.seenContributors &&
			state.ui.detectionData.seenContributors[targetId]
		)
	}

export const selectTrackHistory =
	(targetId: Detection['target_id']) => (state: RootState) => {
		return (
			state.ui.detectionData.trackHistory &&
			state.ui.detectionData.trackHistory[targetId]
		)
	}

export const selectCameraCalibrationStep = (state: RootState) => {
	return state.ui.cameraCalibration.activeStep
}

export const selectToasts = (state: RootState) => state.ui.toasts

export const selectSelectedRfFiltersIds = (state: RootState) => {
	return state.ui.selectedRfFiltersIds
}

export const selectSentryData = (state: RootState) => state.ui.sentryData
export const selectSiteLocationData = (state: RootState) =>
	state.ui.siteLocationData

export const selectSiteZoom = (state: RootState) => state.ui.siteZoom
export const selectUserSiteAssociations = (state: RootState) =>
	state.ui.userSiteAssociations

export const selectShowNetworkDisconnected = (state: RootState) =>
	state.ui.showNetworkDisconnected

// Reducer slice
export default uiSlice.reducer

// Actions
export const {
	setAlertSoundEnabled,
	setActiveCameraId,
	setLeftPanelWidth,
	toggleActiveTargetId,
	clearByTargetId,
	setActiveTargetId,
	updateSensorPreview,
	setZoneCoordinates,
	setSiteMarkerData,
	setIsZoneCoordinatesDirty,
	updateSeenContributors,
	addTrackHistory,
	deleteDetectionData,
	setCameraCalibrationStep,
	deleteToast,
	addToast,
	updateSelectedSensorFiltersIds,
	deleteSelectedSensorFiltersIds,
	setSentryData,
	setSiteLocationData,
	setSiteZoom,
	updateUserSiteAssociations,
	setShowNetworkDisconnected,
} = uiSlice.actions
