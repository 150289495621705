// React
import { useTranslation } from 'react-i18next'
// Chakra
import { Box, Flex } from '@chakra-ui/react'
// Components
import RfFiltersOverviewBearingRanges from './RfFiltersOverviewBearingRanges'
// Types
import type { RfSensorFilter } from '@Store/types'
export type BearingValuesType = {
	bearing: number
	bearing_tolerance: number
	name: string
	id: number
}

const RfFiltersOverviewBearings = ({
	filters,
	isDsx,
}: {
	filters: RfSensorFilter[]
	isDsx?: boolean
}) => {
	// Translations
	const { t } = useTranslation('panels', {
		keyPrefix: 'rfFilters.overview',
	})
	const bearings = filters.map(({ bearing, bearing_tolerance, name, id }) => ({
		bearing,
		bearing_tolerance,
		name,
		id,
	}))

	return (
		<Flex direction='column' gap={4} mt={6}>
			<Box>{t('bearings')}</Box>
			<RfFiltersOverviewBearingRanges ranges={bearings} isDsx={isDsx} />
		</Flex>
	)
}

export default RfFiltersOverviewBearings
