// React
import { useTranslation } from 'react-i18next'
// Chakra
import { Flex } from '@chakra-ui/react'
// Components
import MapLayersCreateModal from './Modals/CreateMapLayerModal'
// Store
import { useGetMapLayersQuery } from '@Store/mapLayers/mapLayersApi'
import FormWrapper from '@Components/FormElements/FormWrapper/FormWrapper'
import TableMapLayersModal from '@Components/Site/Panels/MapsAndZones/MapLayers/Modals/TableMapLayersModal'
import MapLayersTable from '@Components/Site/Panels/MapsAndZones/MapLayers/Tables/MapLayersTable'

const MapLayersEdit = () => {
	// Translations
	const { t } = useTranslation('forms', {
		keyPrefix: 'mapsZonesForm.mapLayersForm',
	})
	const {
		isLoading,
		isSuccess,
		isError,
		data: mapLayers,
		refetch,
	} = useGetMapLayersQuery()

	return (
		<FormWrapper
			isEditForm={true}
			isLoading={isLoading}
			isError={isError}
			isSuccess={isSuccess}
			entity={t('entity')}
			refetch={refetch}
		>
			<Flex alignItems='center' justifyContent='space-between' my={2}>
				<TableMapLayersModal mapLayers={mapLayers} />
				<MapLayersCreateModal variant='icon' />
			</Flex>
			<MapLayersTable mapLayers={mapLayers} variant='collapsed' />
		</FormWrapper>
	)
}

export default MapLayersEdit
