import { baseApi } from '@Store/baseApi'
import { type CoreApiError, formatApiErrors } from '@Store/utils/errorUtils'

import type { Disruptor } from '../types'

export type DisruptorRequest = {
	sentry_id: number
	name: string
	serial_number: string
	direction_offset: number
	reach: number
	shutoff_time: number
}

export type UpdateDisruptorBandsRequest = {
	band_24_58_trigger_command?: boolean
	band_433_trigger_command?: boolean
	band_915_trigger_command?: boolean
	gnss_trigger_command?: boolean
	power_trigger_command?: boolean
}

type UpdateAllDisruptorsRequest = UpdateDisruptorBandsRequest & {
	siteId: number
	disruptors: Disruptor[]
	power_trigger_command?: boolean
}

export const disruptorsApi = baseApi
	.enhanceEndpoints({
		addTagTypes: ['Disruptor'],
	})
	.injectEndpoints({
		endpoints: (builder) => ({
			getDisruptor: builder.query<
				Disruptor,
				{ siteId: number } & { sensorId: number }
			>({
				query: ({ siteId, sensorId }) => ({
					url: `/api/sites/${siteId}/cannons/${sensorId}`,
				}),
				providesTags: ['Disruptor'],
				transformResponse: (response: { cannon: Disruptor }) => response.cannon,
				keepUnusedDataFor: 0,
			}),
			getDisruptors: builder.query<
				Disruptor[],
				{ siteId: number; only_dsx?: boolean }
			>({
				query: ({ siteId, only_dsx }) => ({
					url: `/api/sites/${siteId}/cannons`,
					params: {
						only_dsx,
					},
				}),
				providesTags: ['Disruptor'],
				transformResponse: (response: { cannons: Disruptor[] }) =>
					response.cannons,
				keepUnusedDataFor: 0,
			}),
			createDisruptor: builder.mutation<
				Disruptor,
				DisruptorRequest & { siteId: number }
			>({
				query: ({ siteId, ...data }) => ({
					url: `/api/sites/${siteId}/cannons`,
					method: 'POST',
					body: {
						...data,
					},
				}),
				transformResponse: (response: { disruptor: Disruptor }) =>
					response.disruptor,
				transformErrorResponse: (response: CoreApiError) =>
					formatApiErrors<Disruptor>(response),
				invalidatesTags: ['Disruptor'],
			}),
			updateDisruptor: builder.mutation<
				Disruptor,
				{ siteId: number } & {
					id: number
				} & DisruptorRequest
			>({
				query: ({ siteId, id: sensorId, ...disruptor }) => ({
					url: `/api/sites/${siteId}/cannons/${sensorId}`,
					method: 'PUT',
					body: {
						...disruptor,
					},
				}),
				transformErrorResponse: (response: CoreApiError) =>
					formatApiErrors<Disruptor>(response),
				invalidatesTags: ['Disruptor'],
			}),
			updateDisruptorBands: builder.mutation<
				Disruptor,
				{ siteId: number } & {
					id: number
				} & UpdateDisruptorBandsRequest
			>({
				query: ({ siteId, id: sensorId, ...patch }) => ({
					url: `/api/sites/${siteId}/cannons/${sensorId}`,
					method: 'PATCH',
					body: {
						...patch,
					},
				}),
				invalidatesTags: ['Disruptor'],
			}),
			deleteDisruptor: builder.mutation<
				{ message: string },
				{ siteId: number } & { sensorId: number }
			>({
				query: ({ siteId, sensorId }) => ({
					url: `/api/sites/${siteId}/cannons/${sensorId}`,
					method: 'DELETE',
				}),
				invalidatesTags: ['Disruptor'],
			}),
			updateAllDisruptors: builder.mutation<
				Promise<void[]>,
				UpdateAllDisruptorsRequest
			>({
				async queryFn(
					{ siteId, disruptors, ...patch },
					_queryApi,
					_extraOptions,
					baseQuery
				) {
					const promises = disruptors.map(async (disruptor) => {
						await baseQuery({
							url: `/api/sites/${siteId}/cannons/${disruptor.id}`,
							method: 'PATCH',
							body: {
								...patch,
							},
						})
					})
					const result = Promise.all(promises)
					return { data: result }
				},
				invalidatesTags: ['Disruptor'],
			}),
		}),
	})

export const {
	useGetDisruptorQuery,
	useGetDisruptorsQuery,
	useCreateDisruptorMutation,
	useUpdateDisruptorMutation,
	useDeleteDisruptorMutation,
	useUpdateDisruptorBandsMutation,
	useUpdateAllDisruptorsMutation,
} = disruptorsApi
