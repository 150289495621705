import { Fragment } from 'react'

import {
	useGetSiteLiveQuery,
	selectCameraTrackedTargetId,
	selectSiteRadarDetections,
} from '@Store/sites/sitesWsApi'

import type { Detection } from '@Store/types'
import { selectActiveTargetId, toggleActiveTargetId } from '@Store/ui/uiSlice'
import { useAppDispatch, useAppSelector } from '@Store/index'

import DetectionMarker from '@Components/Markers/DetectionMarker/DetectionMarker'

import { getSpeedLeaderPosition } from '../../Detections'

const CameraCalibrationDetections = ({ siteId }: { siteId: number }) => {
	const { radarDetections, trackedTargetId } = useGetSiteLiveQuery(siteId, {
		selectFromResult: ({ data }) => ({
			trackedTargetId: selectCameraTrackedTargetId(data),
			radarDetections: selectSiteRadarDetections(data),
		}),
	})

	const selectedTargetId = useAppSelector(selectActiveTargetId)

	const dispatch = useAppDispatch()

	const handleClick = (targetId: Detection['target_id']) =>
		dispatch(toggleActiveTargetId(targetId))

	return (
		<>
			{radarDetections?.map((detection) => {
				return (
					<Fragment key={detection.target_id}>
						<DetectionMarker
							key={detection.target_id}
							targetId={detection.target_id}
							classification={detection.classification}
							latitude={detection.latitude}
							longitude={detection.longitude}
							speedLeaderPosition={getSpeedLeaderPosition(detection)}
							gcsPosition={[detection.gcs_latitude, detection.gcs_longitude]}
							homePosition={[detection.home_latitude, detection.home_longitude]}
							locationVariance={detection.location_variance}
							isWhitelisted={detection.state === 'whitelisted'}
							isSelected={detection.target_id === selectedTargetId}
							isTracking={detection.target_id === trackedTargetId}
							onMouseDown={() => handleClick(detection.target_id)}
						/>
					</Fragment>
				)
			})}
		</>
	)
}

export default CameraCalibrationDetections
