import { useTranslation } from 'react-i18next'
import { MdVisibility as ShowStreamIcon } from 'react-icons/md'

import { useAppDispatch, useAppSelector } from '@Store/index'

import {
	useGetSiteLiveQuery,
	selectCurrentCamera,
	selectIsCameraDisabled,
} from '@Store/sites/sitesWsApi'
import { setActiveCameraId } from '@Store/ui/uiSlice'

import QuickActionsButton from '../QuickActionsButton/QuickActionsButton'

const CameraStreamButton = ({ siteId }: { siteId: number }) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'quickActions.cameraActions',
	})

	const activeCameraId = useAppSelector((state) => state.ui.activeCameraId)
	const dispatch = useAppDispatch()

	const { currentCamera, isDisabled } = useGetSiteLiveQuery(siteId, {
		selectFromResult: ({ data }) => ({
			currentCamera: selectCurrentCamera(data),
			isDisabled: selectIsCameraDisabled(data),
		}),
	})

	const isStreaming = !!activeCameraId

	const toggleCameraStream = () => {
		dispatch(setActiveCameraId(isStreaming ? null : currentCamera.id))
	}

	return (
		<QuickActionsButton
			icon={ShowStreamIcon}
			label={t('cameraStream')}
			onClick={toggleCameraStream}
			isActive={isStreaming}
			isDisabled={isDisabled}
			testId='quick-action-camera-stream'
		/>
	)
}

export default CameraStreamButton
