import { useTheme } from '@chakra-ui/react'
import type { RadarMaskZone } from '@Store/types'

import Donut from '@Components/MapShapes/Donut/Donut'

type RadarMaskSectorProps = {
	latitude: number
	longitude: number
	bearing: number
	isActive?: boolean
} & Pick<
	RadarMaskZone,
	'azimuth_max' | 'azimuth_min' | 'range_max' | 'range_min'
>

const RadarMaskSector = ({
	latitude,
	longitude,
	bearing,
	azimuth_min,
	azimuth_max,
	range_min,
	range_max,
	isActive,
}: RadarMaskSectorProps) => {
	const {
		semanticTokens: { colors },
	} = useTheme()

	const fillColor = colors.radarMasks.fill
	const strokeColor = isActive
		? colors.radarMasks.active
		: colors.radarMasks.stroke
	const strokeWeight = isActive ? 4 : 2

	const startAngle = bearing + azimuth_min
	const stopAngle = bearing + azimuth_max

	return (
		<Donut
			latitude={latitude}
			longitude={longitude}
			startAngle={startAngle}
			stopAngle={stopAngle}
			innerRadius={range_min}
			outerRadius={range_max}
			fillColor={fillColor}
			strokeColor={strokeColor}
			strokeWeight={strokeWeight}
		/>
	)
}

export default RadarMaskSector
