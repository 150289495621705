// TODO: add fonts for the future translations, Ukrainian etc.

// UKRAINIAN
// import '@fontsource/roboto/cyrillic-400.css'
// import '@fontsource/roboto/cyrillic-500.css'
// import '@fontsource/roboto/cyrillic-700.css'
// import '@fontsource/roboto/cyrillic-900.css'
// import '@fontsource/roboto/cyrillic-ext-400.css'
// import '@fontsource/roboto/cyrillic-ext-500.css'
// import '@fontsource/roboto/cyrillic-ext-700.css'
// import '@fontsource/roboto/cyrillic-ext-900.css'

// ENGLISH
import '@fontsource/roboto/latin-400.css'
import '@fontsource/roboto/latin-500.css'
import '@fontsource/roboto/latin-700.css'
import '@fontsource/roboto/latin-900.css'
// POLISH Ą, Ć, Ę, Ł, Ń, Ó, Ś, Ź, Ż
import '@fontsource/roboto/latin-ext-400.css'
import '@fontsource/roboto/latin-ext-500.css'
import '@fontsource/roboto/latin-ext-700.css'
import '@fontsource/roboto/latin-ext-900.css'

export const fonts = {
	heading: 'Roboto',
	body: 'Roboto',
}
