import { useParams } from 'react-router-dom'

import { useAppSelector } from '@Store/index'
import { selectSensorPreview } from '@Store/ui/uiSlice'
import { useGetRadarQuery } from '@Store/radars/radarsApi'

import { RadarMaskSector } from '@Components/Sectors'
import RadarMasksPreview from './RadarMasksPreview'

import type { RadarMaskPreview as RadarMaskPreviewType } from '@Store/types'

const RadarMaskPreview = () => {
	const { siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)

	const sensorPreview = useAppSelector(
		selectSensorPreview
	) as RadarMaskPreviewType
	const sensorId = sensorPreview?.sensorId

	const { data: radar } = useGetRadarQuery(
		{
			siteId,
			sensorId,
		},
		{
			skip: !sensorPreview || !sensorId || !siteId,
		}
	)

	if (radar && sensorPreview) {
		return (
			<>
				<RadarMasksPreview isEdit />
				<RadarMaskSector
					latitude={radar.latitude}
					longitude={radar.longitude}
					bearing={radar.direction}
					azimuth_max={sensorPreview.azimuth_max}
					azimuth_min={sensorPreview.azimuth_min}
					range_max={sensorPreview.range_max}
					range_min={sensorPreview.range_min}
					isActive
				/>
			</>
		)
	} else return null
}

export default RadarMaskPreview
