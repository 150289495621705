import { baseApi } from '@Store/baseApi'

import type { Site } from '../types'

type UiLoggerRequest = {
	siteId: Site['id']
	level: 'info' | 'debug' | 'warn' | 'error'
	message: string
	trace?: string
}

export const uiLoggerApi = baseApi
	.enhanceEndpoints({ addTagTypes: ['Notes'] })
	.injectEndpoints({
		endpoints: (builder) => ({
			uiLogger: builder.mutation<{ message: string }, UiLoggerRequest>({
				query: ({ siteId, ...data }) => ({
					url: `/api/sites/${siteId}/uilogger`,
					method: 'POST',
					body: data,
				}),
			}),
		}),
	})

export const { useUiLoggerMutation } = uiLoggerApi
