// Base Redux Toolkit Query API
import { baseApi } from '../baseApi'

// Constants
import { jwtExpireAfterHours } from '@Constants/api'

// Types
import type { User } from '@Store/types'

// API Types
export type LoginRequest = { username: string; password: string }
export type LoginResponse = {
	status?: 'success'
	token: string
	token_expires_at: string
	user?: User
}

type RefreshRequest = undefined
type RefreshResponse = {
	status?: 'success' | 'error'
	token: string
	token_expires_at: string
}

// JWT Authentication API endpoints
export const authApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		login: builder.mutation<LoginResponse, LoginRequest>({
			query: (credentials) => ({
				url: '/api/tokens',
				method: 'POST',
				body: {
					user: {
						...credentials,
						valid_hours: jwtExpireAfterHours,
					},
				},
			}),
		}),
		getAuthUser: builder.query<User, number>({
			query: (userId) => ({
				url: `/api/users/${userId}`,
				method: 'GET',
			}),
			keepUnusedDataFor: 0,
			transformResponse: (response: { user: User }) => response.user,
		}),
		refresh: builder.mutation<RefreshResponse, RefreshRequest>({
			query: () => ({
				url: '/api/tokens/renew',
				method: 'POST',
				body: {
					valid_hours: jwtExpireAfterHours,
				},
			}),
		}),
	}),
})

// Exported mutation hooks
export const { useLoginMutation, useRefreshMutation } = authApi
