import type { SortingState } from '@tanstack/react-table'

export const getSorting = (sorting: SortingState) => {
	if (!sorting[0]) return null

	const sortingValue = {
		[`sort_by_${sorting[0].id}`]: sorting[0].desc ? 'DESC' : 'ASC',
	}
	return sortingValue
}
