import {
	createElementObject,
	createPathComponent,
	extendContext,
} from '@react-leaflet/core'

import { Polyline as LeafletPolyline } from 'leaflet'
import 'leaflet-textpath'

import type { LeafletContextInterface, PathProps } from '@react-leaflet/core'
import type {
	LatLngExpression,
	PolylineOptions,
	TextPathOptions,
} from 'leaflet'

type TextPathProps = {
	text: string
	positions: LatLngExpression[]
} & TextPathOptions &
	PathProps &
	PolylineOptions

export const TextPath = createPathComponent<LeafletPolyline, TextPathProps>(
	function createTextPath(
		{
			text,
			positions,
			repeat,
			center,
			below,
			offset,
			orientation,
			attributes,
			...options
		}: TextPathProps,
		ctx: LeafletContextInterface
	) {
		const polyline = new LeafletPolyline(positions, options)
		polyline.setText(text, {
			repeat,
			center,
			below,
			offset,
			orientation,
			attributes,
		})
		return createElementObject(
			polyline,
			extendContext(ctx, { overlayContainer: polyline })
		)
	},
	function updateTextPath(polyline, props, prevProps) {
		if (props.positions !== prevProps.positions) {
			polyline.setLatLngs(props.positions)
			polyline.setText(props.text, {
				repeat: props.repeat,
				center: props.center,
				below: props.below,
				offset: props.offset,
				orientation: props.orientation,
				attributes: props.attributes,
			})
		}
	}
)

export default TextPath
