import { baseApi } from '@Store/baseApi'
import { createSelector } from '@reduxjs/toolkit'

import type { Site, Radar, RadarAglMask } from '../types'

type GetSiteRadarAglMasks = { siteId: Site['id'] }
type GetRadarAglMask = { siteId: Site['id']; sensorId: Radar['id'] }
type CreateRadarAglMaskRequest = {
	siteId: Site['id']
	sensorId: Radar['id']
} & Pick<RadarAglMask, 'min' | 'max' | 'enabled'>
type UpdateRadarAglMaskRequest = {
	siteId: Site['id']
	sensorId: Radar['id']
	aglMaskId: RadarAglMask['id']
} & Partial<RadarAglMask>

export const radarAglMasksApi = baseApi
	.enhanceEndpoints({ addTagTypes: ['RadarAglMasks'] })
	.injectEndpoints({
		endpoints: (builder) => ({
			// Get all Radar AGL Masks for a site
			// https://au-dev.droneshield.xyz/api/docs#get-/api/sites/-site_id-/radar_agl_masks
			getSiteRadarAglMasks: builder.query<RadarAglMask[], GetSiteRadarAglMasks>(
				{
					query: ({ siteId }) => ({
						url: `/api/sites/${siteId}/radar_agl_masks`,
					}),
					providesTags: ['RadarAglMasks'],
					transformResponse: (response: { radar_agl_masks: RadarAglMask[] }) =>
						response.radar_agl_masks,
				}
			),
			// Get Radar AGL Mask for a radar
			// https://au-dev.droneshield.xyz/api/docs#get-/api/sites/-site_id-/radars/-radar_id-/radar_agl_masks
			getRadarAglMask: builder.query<RadarAglMask, GetRadarAglMask>({
				query: ({ siteId, sensorId }) => ({
					url: `/api/sites/${siteId}/radars/${sensorId}/radar_agl_masks`,
				}),
				providesTags: ['RadarAglMasks'],
				transformResponse: (response: { radar_agl_mask: RadarAglMask }) =>
					response.radar_agl_mask,
			}),
			// Create a Radar AGL Mask
			// https://au-dev.droneshield.xyz/api/docs#post-/api/sites/-site_id-/radars/-radar_id-/radar_agl_masks
			createRadarAglMask: builder.mutation<
				RadarAglMask,
				CreateRadarAglMaskRequest
			>({
				query: ({ siteId, sensorId, ...aglMask }) => ({
					url: `/api/sites/${siteId}/radars/${sensorId}/radar_agl_masks`,
					method: 'POST',
					body: {
						radar_agl_mask: {
							...aglMask,
						},
					},
				}),
				invalidatesTags: ['RadarAglMasks'],
				transformResponse: (response: { radar_agl_mask: RadarAglMask }) =>
					response.radar_agl_mask,
			}),
			// Update a Radar AGL Mask
			// https://au-dev.droneshield.xyz/api/docs#patch-/api/sites/-site_id-/radar_agl_masks/-id-
			updateRadarAglMask: builder.mutation<
				RadarAglMask,
				UpdateRadarAglMaskRequest
			>({
				query: ({ siteId, sensorId, aglMaskId, ...aglMask }) => ({
					url: `/api/sites/${siteId}/radars/${sensorId}/radar_agl_masks/${aglMaskId}`,
					method: 'PATCH',
					body: {
						radar_agl_mask: {
							...aglMask,
						},
					},
				}),
				invalidatesTags: ['RadarAglMasks'],
				transformResponse: (response: { radar_agl_mask: RadarAglMask }) =>
					response.radar_agl_mask,
			}),
		}),
	})

// Calling getRadarAglMask endpoint triggers 404 when no mask has been configured
// But calling getSiteRadarAglMasks endpoint is happy to return an empty array
// This selector fetches an agl mask object for a Radar ID, or returns null
export const selectSiteRadarAglMaskBySensorId = createSelector(
	[
		(data?: RadarAglMask[]) => data,
		(siteAglMasks, sensorId: number) => sensorId,
	],
	(siteAglMasks, sensorId) =>
		siteAglMasks?.find((aglMask) => aglMask.radar_id === sensorId) ?? null
)

export const {
	useGetSiteRadarAglMasksQuery,
	useGetRadarAglMaskQuery,
	useCreateRadarAglMaskMutation,
	useUpdateRadarAglMaskMutation,
} = radarAglMasksApi
