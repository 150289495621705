import { type CSSProperties, useMemo } from 'react'
import { Popup } from 'react-leaflet'
import type { LatLngExpression, LatLngLiteral } from 'leaflet'

import Marker from '../index'

const numberStyle = {
	position: 'absolute',
	left: '50%',
	top: 'calc(50% - 7px)', // Half size of font
	transform: 'translate(-50%, -50%)',
	fontSize: 14,
	fontWeight: 600,
	width: '24px',
	height: '24px',
	lineHeight: '24px',
	textAlign: 'center',
	borderRadius: '100%',
	backgroundColor: 'white',
	overflow: 'hidden',
	color: '#212121',
} as CSSProperties

type MapMarkerProps = {
	onDragEnd?: (position: LatLngLiteral) => void
	latitude: number
	longitude: number
	draggable?: boolean
	number: number
	nickname: string
	color: string
	interactive?: boolean
}

const MapMarker = ({
	latitude,
	longitude,
	onDragEnd,
	number,
	nickname,
	color,
	draggable,
	interactive = false,
}: MapMarkerProps) => {
	const memorizedPosition: LatLngExpression = useMemo(() => {
		return [latitude, longitude]
	}, [latitude, longitude])

	return (
		<Marker
			position={memorizedPosition}
			draggable={draggable}
			onDragEnd={onDragEnd}
			alt='Map Marker'
			icon={
				<>
					<svg
						width='40px'
						height='52px'
						fill={color}
						xmlns='http://www.w3.org/2000/svg'
						viewBox='0 0 25 33'
					>
						<path d='M12.5,0A13.07,13.07,0,0,0,3.66,3.38,11.16,11.16,0,0,0,0,11.55a12.59,12.59,0,0,0,2.34,7.26L12.5,33,22.66,18.81A12.5,12.5,0,0,0,25,11.55a10.75,10.75,0,0,0-.95-4.42,11.68,11.68,0,0,0-2.71-3.75A12.62,12.62,0,0,0,17.28.88,13.37,13.37,0,0,0,12.5,0Z' />
					</svg>
					<div style={numberStyle} data-testid='map-marker-icon'>
						{number}
					</div>
				</>
			}
			iconSize={[40, 52]}
			iconAnchor={[20, 52]}
			interactive={interactive}
		>
			<Popup>{nickname}</Popup>
		</Marker>
	)
}

export default MapMarker
