type HomeMarkerIconProps = {
	color: string
}

const HomeMarkerIcon = ({ color }: HomeMarkerIconProps) => {
	return (
		<svg
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g fill={color} data-testid={`home-icon-${color}`}>
				<polygon points='18.501426 5.428505 34.955926 14.734625 2.046946 14.734625' />
				<polygon points='34.977626 14.734725 2.034406 14.734725 2.034366 27.142825 16.304426 27.142825 16.304426 20.680225 21.474426 20.680225 21.474426 27.142825 34.977626 27.142825' />
				<polygon points='18.889226 36.190525 24.485926 29.404725 13.292426 29.404725' />
				<polygon points='18.586726 8.8817842e-16 0 10.414325 1.389536 13.001825 18.587826 3.217765 35.707426 12.794225 37.096926 10.206725 18.588326 0.000943 18.587526 0.013468' />
			</g>
		</svg>
	)
}

export default HomeMarkerIcon
