import { z } from 'zod'
import globalSchema from '@/components/Forms/BaseForm/BaseForm.schema'

const pickedFromGlobal = globalSchema.pick({
	name: true,
	email: true,
	phone: true,
})

const clientFormFields = z.object({
	address: z.string().optional(),
	address2: z.string().optional(),
	city: z.string().optional(),
	contact: z.string().optional(),
	fax: z.string().optional(),
	state: z.string().optional(),
	zip: z.string().optional(),
})

export const clientFormSchema = clientFormFields.merge(pickedFromGlobal)
export type ClientSchema = z.infer<typeof clientFormSchema>

const defaultValuesSchema = clientFormSchema.omit({
	address2: true,
})

type DefaultValues = z.infer<typeof defaultValuesSchema>

export const ADD_CLIENT_DEFAULT_VALUES: DefaultValues = {
	name: '',
	email: '',
}
