import useShowPreviewMode from './useShowPreviewMode'
import RfSensorPreview from './RfSensorPreview'
import CameraPreview from './CameraPreview'
import RadarPreview from './RadarPreview'
import DisruptorPreview from './DisruptorPreview'
import DsxPreview from './DsxPreview'
import ZonePreview from './ZonePreview'
import RadarMaskPreview from './RadarMaskPreview'
import RadarMasksPreview from './RadarMasksPreview'
import CameraCalibrationPreview from './CameraCalibrationPreview/CameraCalibrationPreview'
import RfFiltersPreview from './RfFiltersPreview'
import RfFiltersBearingPreview from './RfFiltersBearingPreview'
import SiteMarkersPreview from './SiteMarkersPreview'
import InstallationPreview from './InstallationPreview'
import SitePreview from './SitePreview'

const PreviewModes = () => {
	const {
		showRfSensorPreview,
		showRadarPreview,
		showCameraPreview,
		showDisruptorPreview,
		showDsxPreview,
		showRadarMaskPreview,
		showRadarMasksPreview,
		showRfFiltersPreview,
		showRfFiltersBearingPreview,
		showSiteMarkersPreview,
		showSitePreview,
		showInstallationPreview,
		showZonePreview,
		showCameraCalibrationPreview,
	} = useShowPreviewMode()

	if (showRfSensorPreview) return <RfSensorPreview />
	else if (showCameraPreview) return <CameraPreview />
	else if (showRadarPreview) return <RadarPreview />
	else if (showDisruptorPreview) return <DisruptorPreview />
	else if (showZonePreview) return <ZonePreview />
	else if (showDsxPreview) return <DsxPreview />
	else if (showRadarMaskPreview) return <RadarMaskPreview />
	else if (showRadarMasksPreview) return <RadarMasksPreview />
	else if (showRfFiltersPreview) return <RfFiltersPreview />
	else if (showRfFiltersBearingPreview) return <RfFiltersBearingPreview />
	else if (showSiteMarkersPreview) return <SiteMarkersPreview />
	else if (showCameraCalibrationPreview) return <CameraCalibrationPreview />
	else if (showInstallationPreview) return <InstallationPreview />
	else if (showSitePreview) return <SitePreview />
	else return null
}

export default PreviewModes
