import { defineStyleConfig } from '@chakra-ui/react'
import FIGMA from '../_FIGMA'

const InputStyles = defineStyleConfig({
	baseStyle: {
		field: {
			_focusVisible: {
				border: '1px solid',
				borderColor: FIGMA.colors.primary,
			},
			_dark: {
				color: FIGMA.colors.input_color,
				background: FIGMA.colors.input_bg,
				margin: '0 1px',
				border: '1px solid transparent',
			},
		},
	},
})

export const Input = {
	defaultProps: {
		size: 'sm',
		focusBorderColor: FIGMA.colors.primary,
		errorBorderColor: FIGMA.colors.error,
	},
	...InputStyles,
}
