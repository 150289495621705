// Chakra
import {
	FormControl,
	FormErrorMessage,
	Checkbox,
	Stack,
	Text,
	Box,
} from '@chakra-ui/react'

import { Controller, useFormContext } from 'react-hook-form'

// Components
import Tooltip from '@UI/Tooltip/Tooltip'

// Types
import type { RegisterType } from '@Components/FormElements/types/RegisterType'
import type { FormElementProps } from '@Components/FormElements/types/FormElementProps'

type CheckboxGroupProps = FormElementProps & {
	options: Array<{ label: string; value: number }>
} & RegisterType<string>

const CheckboxGroup = ({
	id,
	title,
	register,
	options,
	testId, // TODO
	tooltip,
	error,
}: CheckboxGroupProps) => {
	const htmlId = id ?? register?.name ?? 'checkbox-group'
	const { control } = useFormContext()
	return (
		<Tooltip label={tooltip} type='info'>
			<FormControl isInvalid={!!error}>
				<Controller
					name={htmlId}
					control={control}
					render={({
						field: { ref, value: checkedValues, onChange, ...rest },
					}) => {
						return (
							<Box data-testid={testId}>
								<Text fontSize='sm' color='label_color' marginBlockEnd={4}>
									{title}
								</Text>
								<Stack marginInlineStart={4} marginBlockEnd={4} spacing={4}>
									{options.map((option, index) => (
										<Checkbox
											data-testid={`${testId}-${option.label}`}
											key={index}
											value={option.value}
											isChecked={checkedValues?.includes(option.value)}
											onChange={(e) => {
												if (e.target.checked)
													onChange([
														...(checkedValues ?? []),
														Number(e.target.value),
													])
												else
													onChange(
														checkedValues.filter(
															(value: number) =>
																value !== Number(e.target.value)
														)
													)
											}}
										>
											<Text fontSize='sm'>{option.label}</Text>
										</Checkbox>
									))}
								</Stack>
							</Box>
						)
					}}
				/>
				<FormErrorMessage>{error}</FormErrorMessage>
			</FormControl>
		</Tooltip>
	)
}

export default CheckboxGroup
