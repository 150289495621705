import { Polyline } from 'react-leaflet'
import type { LatLngExpression } from 'leaflet'

type SentryTrackHistoryProps = {
	positions: LatLngExpression[]
	color: string
}

const SentryTrackHistory = ({ positions, color }: SentryTrackHistoryProps) => {
	return (
		positions &&
		positions.length > 0 && (
			<Polyline positions={positions} pathOptions={{ color, dashArray: '4' }} />
		)
	)
}

export default SentryTrackHistory
