import { useTranslation } from 'react-i18next'
import { Flex, Grid, GridItem, useToken } from '@chakra-ui/react'
import MapLegendSection from '@Components/MapControls/MapLegend/MapLegendSection'

const MapLegendSectionRfDetections = () => {
	const { t } = useTranslation('map', { keyPrefix: 'legend.rfDetections' })

	const [whitelistedColor, threatColor] = useToken('colors', [
		'detections.whitelisted',
		'detections.default',
	])

	return (
		<MapLegendSection title={t('title')}>
			<Grid templateColumns='repeat(3, 1fr)' rowGap={6} columnGap={2}>
				<GridItem></GridItem>
				<GridItem textAlign='center'>{t('lineOfBearing')}</GridItem>
				<Flex alignItems='center' justifyContent='center'>
					{t('whitelisted')}
				</Flex>
				<Flex alignItems='center' whiteSpace='pre-wrap'>
					{t('detection')}
				</Flex>
				<Flex alignItems='center' justifyContent='center'>
					<svg width='70' height='2' viewBox='0 0 70 2' fill='none'>
						<line
							x1='1'
							y1='1'
							x2='70'
							y2='1'
							stroke={threatColor}
							strokeWidth='2'
						/>
					</svg>
				</Flex>
				<Flex alignItems='center' justifyContent='center'>
					<svg width='70' height='2' viewBox='0 0 70 2' fill='none'>
						<line
							x1='1'
							y1='1'
							x2='70'
							y2='1'
							stroke={whitelistedColor}
							strokeWidth='2'
						/>
					</svg>
				</Flex>
				<Flex alignItems='center' whiteSpace='pre-wrap'>
					{t('controller')}
				</Flex>
				<Flex alignItems='center' justifyContent='center'>
					<svg
						width='70'
						height='3'
						viewBox='0 0 70 3'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<line
							x1='1'
							y1='1.5'
							x2='70'
							y2='1.5'
							stroke={threatColor}
							strokeWidth='3'
							strokeDasharray='6 4 18 4'
						/>
					</svg>
				</Flex>
				<Flex alignItems='center' justifyContent='center'>
					<svg
						width='70'
						height='3'
						viewBox='0 0 70 3'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<line
							x1='1'
							y1='1.5'
							x2='70'
							y2='1.5'
							stroke={whitelistedColor}
							strokeWidth='3'
							strokeDasharray='6 4 18 4'
						/>
					</svg>
				</Flex>
				<Flex alignItems='center' whiteSpace='pre-wrap' gridColumn='1 / 3'>
					{t('peripheral')}
				</Flex>
				<Flex alignItems='center' justifyContent='center'>
					<svg width='78' height='20' viewBox='0 0 78 20' fill='none'>
						<g filter='url(#gradient_filter)'>
							<rect
								x='4.24609'
								y='9'
								width='69'
								height='6'
								fill={threatColor}
								fillOpacity='0.15'
							/>
						</g>
						<g filter='url(#color_filter)'>
							<rect
								x='4.08203'
								y='4'
								width='69'
								height='20'
								fill={threatColor}
								fillOpacity='0.25'
							/>
						</g>
						<defs>
							<filter
								id='gradient_filter'
								x='0.246094'
								y='5'
								width='77'
								height='18'
								filterUnits='userSpaceOnUse'
								colorInterpolationFilters='sRGB'
							>
								<feFlood floodOpacity='0' result='BackgroundImageFix' />
								<feBlend
									mode='normal'
									in='SourceGraphic'
									in2='BackgroundImageFix'
									result='shape'
								/>
								<feGaussianBlur stdDeviation='2' result='foreground_blur' />
							</filter>
							<filter
								id='color_filter'
								x='0.0820312'
								y='0'
								width='77'
								height='20'
								filterUnits='userSpaceOnUse'
								colorInterpolationFilters='sRGB'
							>
								<feFlood floodOpacity='0' result='BackgroundImageFix' />
								<feBlend
									mode='normal'
									in='SourceGraphic'
									in2='BackgroundImageFix'
									result='shape'
								/>
								<feGaussianBlur stdDeviation='2' result='foreground_blur' />
							</filter>
						</defs>
					</svg>
				</Flex>
			</Grid>
		</MapLegendSection>
	)
}

export default MapLegendSectionRfDetections
