import { useTranslation } from 'react-i18next'

import { Box } from '@chakra-ui/react'

import FetchError from '@UI/FetchError/FetchError'
import Skeleton from '@UI/Skeleton/Skeleton'

import { useGetDisruptionsSummaryQuery } from '@Store/analytics/analyticsApi'

import NumberDisplay from '../NumberDisplay'

type DisruptionDetailsProps = {
	siteId: number
	startTime: string
	endTime: string
}

const DisruptionDetails = ({
	siteId,
	startTime,
	endTime,
}: DisruptionDetailsProps) => {
	const { t } = useTranslation('pages', {
		keyPrefix: 'analytics.disruptionsSummary',
	})

	const { isSuccess, isError, isLoading, isFetching, refetch, totalCount } =
		useGetDisruptionsSummaryQuery(
			{
				siteId: Number(siteId),
				format: 'table',
				startTime: startTime,
				endTime: endTime,
				page: 0,
				perPage: 10,
				sort_by_id: 'DESC',
			},
			{
				selectFromResult: ({
					isSuccess,
					isError,
					isLoading,
					isFetching,
					data,
				}) => ({
					isSuccess,
					isError,
					isLoading,
					isFetching,
					totalCount: data?.meta.total,
				}),
			}
		)

	return (
		<Skeleton isLoaded={!isLoading && !isFetching}>
			<Box w='250px'>
				{isSuccess && (
					<NumberDisplay
						title={t('numberOfDisruptions')}
						number={totalCount ?? 0}
					/>
				)}
				{isError && (
					<FetchError refetch={refetch} entity={t('entities.chart')} />
				)}
			</Box>
		</Skeleton>
	)
}

export default DisruptionDetails
