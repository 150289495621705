import { IconButton as ChakraIconButton } from '@chakra-ui/react'
import Tooltip from '../Tooltip/Tooltip'

import type {
	IconButtonProps as ChakraIconButtonProps,
	PlacementWithLogical,
} from '@chakra-ui/react'

type IconButtonProps = {
	tooltip?: string
	tooltipPlacement?: PlacementWithLogical
	testId: string
	isExpanded?: boolean
} & ChakraIconButtonProps

// TODO: Move To Tooltip folder and rename as TooltipIconButton
const IconButton = ({
	'aria-label': ariaLabel,
	tooltip,
	tooltipPlacement = 'bottom',
	variant = 'ghost',
	colorScheme = 'white',
	isDisabled,
	icon,
	onClick,
	testId,
	isExpanded = false,
	...props
}: IconButtonProps) => {
	const transform =
		variant === 'expand'
			? isExpanded
				? 'rotate(0deg)'
				: 'rotate(-180deg)'
			: undefined

	return (
		<Tooltip label={tooltip} placement={tooltipPlacement}>
			<ChakraIconButton
				aria-label={ariaLabel}
				variant={variant}
				colorScheme={colorScheme}
				data-testid={testId}
				isDisabled={isDisabled}
				icon={icon}
				onClick={onClick}
				transform={transform}
				{...props}
			/>
		</Tooltip>
	)
}

export default IconButton
