// Chakra
import {
	FormControl,
	FormErrorMessage,
	Checkbox as ChakraCheckbox,
	Text,
} from '@chakra-ui/react'
// Components
import Tooltip from '@UI/Tooltip/Tooltip'
// Types
import type { FC } from 'react'
import type { RegisterType } from '@Components/FormElements/types/RegisterType'
import type { FormElementProps } from '@Components/FormElements/types/FormElementProps'

type CheckboxProps = FormElementProps & {
	value?: string
	onChange?: <T>(value: T) => void
} & RegisterType<string>

const Checkbox: FC<CheckboxProps> = ({
	id,
	title,
	register,
	value,
	onChange,
	testId,
	tooltip,
	error,
}) => {
	const htmlId = id ?? register?.name ?? 'checkbox'
	return (
		<Tooltip label={tooltip} type='info'>
			<FormControl isInvalid={!!error}>
				<ChakraCheckbox
					id={htmlId}
					{...(register ?? {})}
					value={value}
					onChange={(e) => {
						if (value && onChange) onChange(e)
						else {
							register?.onChange(e)
						}
					}}
					data-testid={testId}
				>
					<label htmlFor={htmlId}>
						<Text fontSize='sm'>{title}</Text>
					</label>
				</ChakraCheckbox>
				<FormErrorMessage>{error}</FormErrorMessage>
			</FormControl>
		</Tooltip>
	)
}

export default Checkbox
