// React
import { useTranslation } from 'react-i18next'
// Chakra
import { Text } from '@chakra-ui/react'
// Components
import Skeleton from '@UI/Skeleton/Skeleton'
import SystemWarning from './SystemWarning'
// Store
import { useGetReadSiteWarningsQuery } from '@Store/siteWarnings/siteWarningsApi'
import SystemWarningsNoWarnings from '@Components/Navigation/SystemWarnings/SystemWarningsNoWarnings'

const SystemWarningsListHistory = ({
	siteId,
	siteTimeZone,
}: {
	siteId: number
	siteTimeZone: string
}) => {
	const { t } = useTranslation('navigation', {
		keyPrefix: 'systemWarnings',
	})

	const {
		data: historyWarnings,
		isSuccess: isHistorySuccess,
		isLoading: isHistoryLoading,
		isFetching: isHistoryFetching,
	} = useGetReadSiteWarningsQuery(
		{ siteId, timezone: siteTimeZone },
		{
			skip: !siteId || !siteTimeZone,
		}
	)

	return (
		<Skeleton isLoaded={!isHistoryLoading && !isHistoryFetching}>
			<Text fontSize='sm'>{t('past48hours')}</Text>
			{isHistorySuccess &&
				historyWarnings?.map((warning) => (
					<SystemWarning
						key={warning.id}
						systemWarning={warning}
						siteId={siteId}
						siteTimeZone={siteTimeZone}
					/>
				))}
			{isHistorySuccess && historyWarnings?.length < 1 && (
				<SystemWarningsNoWarnings />
			)}
		</Skeleton>
	)
}

export default SystemWarningsListHistory
