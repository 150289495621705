import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
	useGetSiteLiveQuery,
	selectSiteDisruptors,
} from '@Store/sites/sitesWsApi'
import { useUpdateDisruptorBandsMutation } from '@Store/disruptors/disruptorsApi'

import { useAppDispatch } from '@Store/index'
import { addToast } from '@Store/ui/uiSlice'
import type { CoreApiError } from '@Store/utils/errorUtils'

import DisruptorButton from './DisruptorButton/DisruptorButton'

import { isDisruptorDisabled } from '@Utils/installations'

type DisruptorsProps = {
	siteId: number
	isAllLoading: boolean
}

const Disruptors = ({ siteId, isAllLoading }: DisruptorsProps) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'quickActions.disruptorActions',
	})

	const [loadingDisruptor, setLoadingDisruptor] = useState<number | null>(null)
	const includeDsxDisruptors = false

	const dispatch = useAppDispatch()

	const { disruptors } = useGetSiteLiveQuery(siteId, {
		selectFromResult: ({ data }) => ({
			disruptors: selectSiteDisruptors(data, includeDsxDisruptors),
		}),
	})

	const [updateDisruptorBands, { isLoading }] =
		useUpdateDisruptorBandsMutation()

	const toggleDisruptor = async (
		sensorId: number,
		power_trigger_engaged: boolean
	) => {
		try {
			setLoadingDisruptor(sensorId)
			await updateDisruptorBands({
				siteId,
				id: sensorId,
				power_trigger_command: !power_trigger_engaged,
			}).unwrap()
			setLoadingDisruptor(null)
		} catch (error) {
			setLoadingDisruptor(null)
			dispatch(
				addToast({
					type: 'error',
					title: t('status.disruptError'),
					siteId,
					error,
					id: `disruptor-disrupt-${(error as CoreApiError).status}`,
				})
			)
			throw new Error(t('status.disruptError'))
		}
	}

	return (
		<>
			{disruptors.map((disruptor, index) => (
				<DisruptorButton
					key={disruptor.id}
					onClick={() =>
						toggleDisruptor(disruptor.id, disruptor.power_trigger_engaged)
					}
					name={disruptor.name}
					isActive={disruptor.power_trigger_engaged}
					isDisabled={isDisruptorDisabled(disruptor)}
					testId={`disruptor-${index}`}
					tooltip={disruptor.name}
					isLoading={
						(isLoading && loadingDisruptor === disruptor.id) || isAllLoading
					}
				/>
			))}
		</>
	)
}

export default Disruptors
