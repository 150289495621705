import { useTranslation } from 'react-i18next'
import { MdSkipNext as TrackNextIcon } from 'react-icons/md'

import { useAppDispatch } from '@Store/index'

import {
	useGetSiteLiveQuery,
	selectCurrentCamera,
	selectSiteDetections,
	selectSiteNextDetection,
	selectIsCameraDisabled,
} from '@Store/sites/sitesWsApi'
import { useTrackDetectionMutation } from '@Store/cameras/camerasApi'

import { setActiveCameraId, setActiveTargetId } from '@Store/ui/uiSlice'

import QuickActionsButton from '../QuickActionsButton/QuickActionsButton'

const TrackNextButton = ({ siteId }: { siteId: number }) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'quickActions.cameraActions',
	})

	const { currentCamera, detections, nextDetection, isDisabled } =
		useGetSiteLiveQuery(siteId, {
			selectFromResult: ({ data }) => ({
				currentCamera: selectCurrentCamera(data),
				detections: selectSiteDetections(data),
				nextDetection: selectSiteNextDetection(data),
				isDisabled: selectIsCameraDisabled(data),
			}),
		})

	const dispatch = useAppDispatch()
	const [trackNextDetection] = useTrackDetectionMutation()

	const handleTrackNextDetection = () => {
		trackNextDetection({
			siteId,
			cameraId: currentCamera.id,
			targetId: nextDetection,
		})
		dispatch(setActiveCameraId(currentCamera.id))
		dispatch(setActiveTargetId(nextDetection))
	}

	const hasDetections = detections.length > 0

	return (
		<QuickActionsButton
			icon={TrackNextIcon}
			label={t('trackNext')}
			onClick={handleTrackNextDetection}
			isDisabled={isDisabled || !hasDetections}
			testId='quick-action-track-next'
		/>
	)
}

export default TrackNextButton
