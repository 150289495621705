import type {
	Component,
	FunctionComponent,
	ReactNode,
	ReactElement,
} from 'react'
import { useParams } from 'react-router-dom'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import { useUiLoggerMutation } from '@Store/ui/uiLoggerApi'

import FallbackErrorMessage from './FallbackErrorMessage'

type ErrorBoundaryProps = {
	children: ReactNode
	message?: string
	quiet?: boolean
	showBackButton?: boolean
	showReloadButton?: boolean
	showLocationReloadButton?: boolean
	customComponent?: ReactElement<
		unknown,
		string | typeof Component | FunctionComponent
	>
}

/**
 * ErrorBoundary component
 * @param {boolean} quiet fail silently (optional)
 * @param {string} message show a custom error message (optional, shows `errorOccurred` translation by default)
 * @param {boolean} showBackButton offer a button to go back (optional, enabled by default)
 * @param {boolean} showReloadButton offer a button to reload the page (optional, disabled by default)
 * @param {boolean} showLocationReloadButton offer a button to hard reload the page (optional, disabled by default)
 * @param customComponent provide your own <CustomComponent /> to render
 * */
const ErrorBoundary = ({
	children,
	message,
	quiet = false,
	showBackButton = true,
	showReloadButton = false,
	showLocationReloadButton = false,
	customComponent,
}: ErrorBoundaryProps) => {
	const { siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)

	// Provide for logging ui events to core api
	const [uiLogger] = useUiLoggerMutation()
	const logUiMessage = (error: string, trace?: string) => {
		uiLogger({
			siteId: siteId,
			level: 'error',
			message: error.toString(),
			trace: trace ?? undefined,
		})
	}

	// Actual react-error-boundary component
	return (
		<ReactErrorBoundary
			fallback={
				customComponent ? (
					customComponent
				) : (
					<FallbackErrorMessage
						message={message}
						quiet={quiet}
						showBackButton={showBackButton}
						showReloadButton={showReloadButton}
						showLocationReloadButton={showLocationReloadButton}
					/>
				)
			}
			onError={(error, info) => {
				logUiMessage(error.toString(), info.componentStack ?? undefined)
			}}
		>
			{children}
		</ReactErrorBoundary>
	)
}

export default ErrorBoundary
