import { Flex, Icon, Text } from '@chakra-ui/react'
import { MdError, MdOutlineReplay } from 'react-icons/md'
import Button from '@UI/Button/Button'
import { useTranslation } from 'react-i18next'

const FetchError = ({
	refetch,
	errorMessage,
	entity,
	testId,
}: {
	refetch: any
	errorMessage?: string
	entity?: string
	testId?: string
}) => {
	const { t } = useTranslation('app', { keyPrefix: 'global' })
	return (
		<Flex
			mt={4}
			gap={2}
			pl={2}
			alignItems='center'
			justifyContent='space-between'
			bgColor='input_bg'
			data-testid={testId}
		>
			<Flex alignItems='center' gap={2}>
				<Icon as={MdError} color='error' />
				<Text fontWeight={500}>
					{errorMessage || t('messages.error', { entity })}
				</Text>
			</Flex>
			<Button
				testId='fetch-error-button'
				label={t('buttons.retry')}
				variant='ghost'
				onClick={refetch}
				leftIcon={<MdOutlineReplay />}
			/>
		</Flex>
	)
}

export default FetchError
