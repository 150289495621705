import { Box, Flex } from '@chakra-ui/react'

import AlertsDetails from './AlertsDetails'
import AlertsBarChart from './AlertsBarChart'
import AlertsDetailsTable from './AlertsDetailsTable/AlertsDetailsTable'

export const DEFAULT_PARAMS = {
	page: 0,
	perPage: 10,
	filter_by_content: '',
	sort_by_id: 'DESC' as const,
}

type AlertsSummaryProps = {
	siteId: number
	startTime: string
	endTime: string
	siteTimeZone: string
}

const AlertsSummary = ({
	siteId,
	startTime,
	endTime,
	siteTimeZone,
}: AlertsSummaryProps) => {
	return (
		<Box width='100%'>
			<Flex alignItems='center' gap='30px' marginBlockEnd={4} width='100%'>
				<AlertsDetails
					siteId={siteId}
					startTime={startTime}
					endTime={endTime}
				/>
				<AlertsBarChart
					siteId={siteId}
					startTime={startTime}
					endTime={endTime}
					siteTimeZone={siteTimeZone}
				/>
			</Flex>
			<AlertsDetailsTable
				siteId={siteId}
				startTime={startTime}
				endTime={endTime}
				siteTimeZone={siteTimeZone}
			/>
		</Box>
	)
}

export default AlertsSummary
