// React
import { useTransition } from 'react'
import { useParams } from 'react-router-dom'
// Components
import { Switch } from '@chakra-ui/react'
// Store
import type { RfSensorFilter } from '@Store/types'
import { useUpdateRfSensorFilterMutation } from '@Store/rfFilters/rfFiltersApi'

const RfFilterActiveToggleSwitch = ({
	rf_filter,
}: {
	rf_filter: RfSensorFilter
}) => {
	const { siteId } = useParams()
	const { id, active } = rf_filter
	const [isPending, startTransition] = useTransition()
	const [updateRfSensorFilter] = useUpdateRfSensorFilterMutation()

	const handleToggleActive = async () => {
		try {
			startTransition(() => {
				const updatedRfFilter: RfSensorFilter = {
					...rf_filter,
					active: !active,
				}
				updateRfSensorFilter({
					siteId: Number(siteId),
					rfFilterId: id,
					rf_filter: updatedRfFilter,
				}).unwrap()
			})
		} catch (error) {
			// TODO: handle server-side errors if necessary
			console.error('Unhandled update Rf Sensor Filters error', error)
		}
	}

	return (
		<Switch
			onChange={handleToggleActive}
			isChecked={active}
			isDisabled={isPending}
		/>
	)
}

export default RfFilterActiveToggleSwitch
