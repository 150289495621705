const measurementToolSettings = {
	position: 'bottomleft', // Position to show the control. Values: 'topright', 'topleft', 'bottomright', 'bottomleft'
	unit: 'kilometres', // Default unit the distances are displayed in. Values: 'kilometres', 'landmiles', 'nauticalmiles'
	useSubunits: true, // Use subunits (metres/feet) in tooltips if distances are less than 1 kilometre/landmile
	clearMeasurementsOnStop: true, // Clear all measurements when Measure Control is switched off
	showBearings: true, // Whether bearings are displayed within the tooltips
	bearingTextIn: 'In', // language dependend label for inbound bearings
	bearingTextOut: 'Out', // language dependend label for outbound bearings
	tooltipTextFinish: 'Click to <b>finish line</b><br>',
	tooltipTextDelete: 'Press SHIFT-key and click to <b>delete point</b>',
	tooltipTextMove: 'Click and drag to <b>move point</b><br>',
	tooltipTextResume: '<br>Press CTRL-key and click to <b>resume line</b>',
	tooltipTextAdd: 'Press CTRL-key and click to <b>add point</b>',
	// language dependend labels for point's tooltips
	measureControlTitleOn: 'Show measuring tool', // Title for the Measure Control going to be switched on
	measureControlTitleOff: 'Hide measuring tool', // Title for the Measure Control going to be switched off
	// measureControlLabel: '&#8614;', // Label of the Measure Control (Unicode symbols are possible)
	measureControlLabel: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21 6H3C1.9 6 1 6.9 1 8V16C1 17.1 1.9 18 3 18H21C22.1 18 23 17.1 23 16V8C23 6.9 22.1 6 21 6ZM21 16H3V8H5V12H7V8H9V12H11V8H13V12H15V8H17V12H19V8H21V16Z" fill="#E0E0E0"/>
</svg> `,
	measureControlClasses: ['c2-measure_button--create'], // Classes to apply to the Measure Control
	showClearControl: true, // Show a control to clear all the measurements
	clearControlTitle: 'Clear Measurements', // Title text to show on the Clear Control
	clearControlLabel: '&times', // Label of the Clear Control (Unicode symbols are possible)
	clearControlClasses: ['c2-measure_button--clear'], // Classes to apply to Clear Control
	showUnitControl: true, // Show a control to change the units of measurements
	unitControlUnits: ['kilometres', 'landmiles', 'nauticalmiles'],
	// measurement units being cycled through by using the Unit Control
	unitControlTitle: {
		// Title texts to show on the Unit Control
		text: 'Change Units',
		kilometres: 'kilometres',
		landmiles: 'land miles',
		nauticalmiles: 'nautical miles',
		metres: 'metres',
	},
	unitControlLabel: {
		// Unit symbols to show in the Unit Control and measurement labels
		metres: 'm',
		kilometres: 'km',
		feet: 'ft',
		landmiles: 'mi',
		nauticalmiles: 'nm',
	},
	unitControlClasses: ['c2-measure_button--units'], // Classes to apply to the Unit Control
	tempLine: {
		// Styling settings for the temporary dashed line
		color: '#ff8800', // Dashed line color
		weight: 2, // Dashed line weight
	},
	fixedLine: {
		// Styling for the solid line
		color: '#ff8800', // Solid line color
		weight: 2, // Solid line weight
	},
	arrow: {
		// Styling of the midway arrow
		color: '#000', // Color of the arrow
	},
	startCircle: {
		// Style settings for circle marker indicating the starting point of the polyline
		color: '#000', // Color of the border of the circle
		weight: 1, // Weight of the circle
		fillColor: '#ffa400', // Fill color of the circle
		fillOpacity: 1, // Fill opacity of the circle
		radius: 3, // Radius of the circle
	},
	intermedCircle: {
		// Style settings for all circle markers between startCircle and endCircle
		color: '#000', // Color of the border of the circle
		weight: 1, // Weight of the circle
		fillColor: '#ffffff', // Fill color of the circle
		fillOpacity: 1, // Fill opacity of the circle
		radius: 2, // Radius of the circle
	},
	currentCircle: {
		// Style settings for circle marker indicating the latest point of the polyline during drawing a line
		color: '#000', // Color of the border of the circle
		weight: 3, // Weight of the circle
		fillColor: '#ff8800', // Fill color of the circle
		fillOpacity: 1, // Fill opacity of the circle
		radius: 5, // Radius of the circle
	},
	endCircle: {
		// Style settings for circle marker indicating the last point of the polyline
		color: '#000', // Color of the border of the circle
		weight: 1, // Weight of the circle
		fillColor: '#ffa400', // Fill color of the circle
		fillOpacity: 1, // Fill opacity of the circle
		radius: 3, // Radius of the circle
	},
}

export default measurementToolSettings
