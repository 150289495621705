import { useParams } from 'react-router-dom'

// Redux
import { useGetSiteInstallationQuery } from '@Store/sites/sitesApi'
import { useAppSelector } from '@Store/index'
import { selectSensorPreview } from '@Store/ui/uiSlice'
import type { RfSensor_Models } from '@Store/types'
import type { RfSensorPreview as RfSensorPreviewType } from '@Store/types'

import SentryMarker from '@Components/Markers/SentryMarker/SentryMarker'
import { RfSensorSector } from '@Components/Sectors'
import CompassMarker from '@Components/Markers/CompassMarker/CompassMarker'
import Detections from '@Components/Site/MapLayers/Detections'

import useCenterMap from './useCenterMap'

const RfSensorPreview = () => {
	const { siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)

	// Sensor details
	const sensorPreview = useAppSelector(
		selectSensorPreview
	) as RfSensorPreviewType
	const installationId = sensorPreview?.sentryId

	// Installation details
	const { isSuccess, installation } = useGetSiteInstallationQuery(
		{ siteId, installationId: installationId as number },
		{
			skip: !sensorPreview || !installationId || !siteId,
			selectFromResult: ({ data, isSuccess }) => ({
				isSuccess,
				installation: data,
			}),
		}
	)

	// Temporarily center the map
	useCenterMap({
		latitude: installation?.latitude,
		longitude: installation?.longitude,
	})

	if (isSuccess && installation && sensorPreview) {
		const bearing =
			(installation.direction + sensorPreview.directionOffset) % 360
		return (
			<>
				<Detections siteId={siteId} />
				<CompassMarker
					position={[installation.latitude, installation.longitude]}
					bearing={bearing}
				/>
				<RfSensorSector
					model={sensorPreview.model as RfSensor_Models}
					latitude={installation.latitude}
					longitude={installation.longitude}
					bearing={bearing}
					reach={sensorPreview.reach}
					isActive={true}
				/>
				<SentryMarker
					name={installation.name}
					position={[installation.latitude, installation.longitude]}
					sentryType={installation.sentry_type}
					iconColor={installation.status_color}
				/>
			</>
		)
	} else return null
}

export default RfSensorPreview
