import { Box, Flex } from '@chakra-ui/react'
import EditButton from '@Components/Site/Panels/SiteInstallations/EditButton'
import { useTranslation } from 'react-i18next'
import { useGetSiteMarkersQuery } from '@Store/siteMarkers/siteMarkersApi'
import MapMarkersTable from '@Components/Site/Panels/MapsAndZones/MapMarkers/MapMarkersTable'
import Skeleton from '@UI/Skeleton/Skeleton'
import FetchError from '@UI/FetchError/FetchError'

type MapMarkersProps = {
	siteId: number
}

const MapMarkers = ({ siteId }: MapMarkersProps) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'mapsAndZones.mapMarkers',
	})

	const {
		isLoading,
		isError,
		refetch,
		data: markers,
	} = useGetSiteMarkersQuery({ siteId })

	return (
		<>
			<Box padding='0.5rem 0rem'>
				<Flex width='28rem' justifyContent='space-between' alignItems='end'>
					<Box width='23rem' />
					<EditButton
						testId='add-map-marker'
						label={t('add')}
						type='add'
						to={`/${siteId}/markers/add`}
					/>
				</Flex>
			</Box>
			<Skeleton isLoaded={!isLoading}>
				{isError && <FetchError refetch={refetch} entity={t('entity')} />}
			</Skeleton>
			{!!markers?.length && (
				<Box maxH='250px' overflow='auto'>
					<MapMarkersTable markers={markers} />
				</Box>
			)}
		</>
	)
}
export default MapMarkers
