// Leaflet
import L from 'leaflet'
// React-Leaflet
import { createControlComponent } from '@react-leaflet/core'
// Polyline measure, styles, settings, types
import 'leaflet.polylinemeasure'
import './MeasurementTool.css'
import measurementToolSettings from './MeasurementTool.settings'

const createPolylineMeasurer = () => {
	return L.control.polylineMeasure({
		...measurementToolSettings,
	})
}

const MeasurementToolControl = createControlComponent(createPolylineMeasurer)

const MeasurementTool = () => {
	return <MeasurementToolControl />
}

export default MeasurementTool
