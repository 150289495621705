import { useTranslation } from 'react-i18next'

import { Box, MenuItem, useDisclosure } from '@chakra-ui/react'
import { MdSettings } from 'react-icons/md'

import Modal from '@UI/Modals/Modal'

import SystemSettingsForm from '@Components/Forms/SystemSettingsForm/SystemSettingsForm'
import useAuth from '@Hooks/useAuth'

const SystemSettings = () => {
	const { t } = useTranslation('navigation', {
		keyPrefix: 'settingsMenu',
	})

	const { isOpen, onOpen, onClose } = useDisclosure()

	const { isManager } = useAuth()

	if (!isManager) return null

	return (
		<>
			<MenuItem
				icon={<MdSettings size='1.25rem' />}
				data-testid='system-settings'
				onClick={onOpen}
			>
				{t('systemSettings')}
			</MenuItem>
			<Modal isOpen={isOpen} onClose={onClose} headerText={t('systemSettings')}>
				<Box p={3}>
					<SystemSettingsForm handleCancel={onClose} />
				</Box>
			</Modal>
		</>
	)
}

export default SystemSettings
