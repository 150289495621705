import { Flex, Text, Icon } from '@chakra-ui/react'

import { MdRemoveRedEye } from 'react-icons/md'

const ViewerCount = ({ count }: { count: number | null }) => {
	return (
		count && (
			<Flex
				bgColor='rgba(255, 255, 255, 0.15)'
				paddingBlock={2}
				justifyContent='center'
				alignContent='center'
				alignItems='center'
				paddingInlineStart={2}
				paddingInlineEnd={2}
				borderRadius={2}
			>
				<Icon as={MdRemoveRedEye} />
				<Text as='span' color='white' paddingInlineStart={1} lineHeight={0}>
					{count}
				</Text>
			</Flex>
		)
	)
}

export default ViewerCount
