// React
import { type ChangeEvent, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
// Chakra
import {
	Box,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Grid,
	Input,
	useRangeSlider,
	type RangeSliderProps,
} from '@chakra-ui/react'
// Components
import RangeSliderThumb from './RangeSliderThumb'
import Tooltip from '@UI/Tooltip/Tooltip'
// Types
import type { Units } from '@Components/FormElements/types/Units'
import type { FormElementProps } from '@Components/FormElements/types/FormElementProps'
// Utils
import { formatDegToMils, formatMilsToDeg } from '@Utils/formatUtils'
import useUnits from '@Hooks/useUnits'

type FormRangeSliderProps = FormElementProps & {
	value?: number[]
	defaultValue: any
	min: number
	max: number
	step?: number
	stepByNumber?: number
	minStepsBetweenThumbs?: number
	inputWidth?: number
	onChangeEnd: RangeSliderProps['onChangeEnd']
	units?: Units
}

const RangeSlider = ({
	id,
	title,
	error,
	defaultValue,
	min,
	max,
	step = 1,
	minStepsBetweenThumbs = 1,
	stepByNumber = 1,
	disabled = false,
	onChangeEnd,
	inputWidth = undefined,
	testId,
	tooltip,
	units,
	...rest
}: FormRangeSliderProps) => {
	const htmlId = id ?? 'rangeSlider'
	const { control } = useFormContext()

	const {
		state,
		actions,
		getInnerTrackProps,
		getInputProps,
		// getMarkerProps,
		getRootProps,
		getThumbProps,
		getTrackProps,
	} = useRangeSlider({
		min,
		max,
		defaultValue,
		step,
		minStepsBetweenThumbs,
		onChangeEnd,
		onChange: onChangeEnd,
		...rest,
	})

	const {
		onKeyDown: onThumbKeyDownFirstIndex,
		'aria-valuenow': valueFirstIndex,
		...thumbPropsFirstIndex
	} = getThumbProps({
		index: 0,
	})

	const {
		onKeyDown: onThumbKeyDownSecondIndex,
		'aria-valuenow': valueSecondIndex,
		...thumbPropsSecondIndex
	} = getThumbProps({
		index: 1,
	})

	// TODO: Fix keyDown infinity loop issue
	const onKeyDownStepBy = (
		e: React.KeyboardEvent<HTMLDivElement>,
		thumbIndex: number
	) => {
		if (e.code === 'ArrowRight') actions.stepUp(thumbIndex, stepByNumber)
		else if (e.code === 'ArrowLeft') actions.stepDown(thumbIndex, stepByNumber)
		else if (['ArrowUp', 'ArrowDown'].includes(e.code)) {
			// do nothing since it will create infinity loop
		} else if (
			thumbIndex === 0 &&
			typeof onThumbKeyDownFirstIndex === 'function'
		)
			onThumbKeyDownFirstIndex(e)
		else if (
			thumbIndex === 1 &&
			typeof onThumbKeyDownSecondIndex === 'function'
		)
			onThumbKeyDownSecondIndex(e)
	}

	const calculatedInputWidth = useMemo(() => {
		if (inputWidth) return `${inputWidth}ch`
		else if (String(max).length >= 5) return `${String(max).length + 2}ch`
		else return '6ch'
	}, [inputWidth, max])

	const { unit, isMils } = useUnits(units)

	const milsValueFirstIndex =
		valueFirstIndex && formatDegToMils(valueFirstIndex)

	const milsValueSecondIndex =
		valueSecondIndex && formatDegToMils(valueSecondIndex)

	const handleInputChange = (
		e: ChangeEvent<HTMLInputElement>,
		index: number
	) => {
		const value = Number(e.target.value)
		if (isMils) {
			const regex = /^[0-9-]+$/ // Allow numbers and "-" sign
			if (regex.test(String(value))) {
				const degValue = Number(formatMilsToDeg(+e.target.value))
				if (
					Number(value) >=
						Number(formatDegToMils(state.getThumbMinValue(index))) &&
					Number(value) <=
						Number(formatDegToMils(state.getThumbMaxValue(index)))
				) {
					actions.setValueAtIndex(index, degValue)
				}
			} else return
		}
		value >= state.getThumbMinValue(index) &&
			value <= state.getThumbMaxValue(index) &&
			actions.setValueAtIndex(index, value)
	}

	return (
		<Tooltip label={tooltip} type='info'>
			<FormControl isInvalid={!!error} isDisabled={disabled}>
				<Controller
					control={control}
					name={htmlId}
					defaultValue={defaultValue}
					render={({ field: { value, onChange } }) => {
						return (
							<>
								<FormLabel>
									{title} {!!unit && `(${unit})`}
								</FormLabel>
								<Grid
									templateColumns={`${calculatedInputWidth} 1fr ${calculatedInputWidth}`}
								>
									<Flex justifyContent='flex-start'>
										<Input
											p={0}
											textAlign='center'
											w={calculatedInputWidth}
											{...(!isMils
												? getInputProps({ index: 0 })
												: { value: milsValueFirstIndex })}
											onChange={(e) => handleInputChange(e, 0)}
											{...(testId && { 'data-testid': `${testId}-min` })}
											type='number'
										/>
									</Flex>
									<Box p={2} pointerEvents={disabled ? 'none' : undefined}>
										<Box cursor='pointer' {...getRootProps()}>
											<Box
												w={'100%'}
												h={1}
												bgColor='whiteAlpha.200'
												borderRadius='full'
												{...getTrackProps()}
												opacity={disabled ? 0.2 : undefined}
											>
												<Box
													h={1}
													bgColor='primary'
													borderRadius='full'
													{...getInnerTrackProps()}
												/>
											</Box>
											<RangeSliderThumb
												value={state.value[0]}
												onKeyDownStepBy={onKeyDownStepBy}
												thumbProps={thumbPropsFirstIndex}
												thumbIndex={0}
												bgColor='primary'
												disabled={disabled}
											/>
											<RangeSliderThumb
												value={state.value[1]}
												onKeyDownStepBy={onKeyDownStepBy}
												thumbProps={thumbPropsSecondIndex}
												thumbIndex={1}
												bgColor='primary'
												disabled={disabled}
											/>
										</Box>
									</Box>
									<Flex justifyContent='flex-end'>
										<Input
											p={0}
											textAlign='center'
											w={calculatedInputWidth}
											{...(!isMils
												? getInputProps({ index: 1 })
												: { value: milsValueSecondIndex })}
											onChange={(e) => handleInputChange(e, 1)}
											{...(testId && { 'data-testid': `${testId}-max` })}
											type='number'
										/>
									</Flex>
								</Grid>
							</>
						)
					}}
				/>
				<FormErrorMessage>{error}</FormErrorMessage>
			</FormControl>
		</Tooltip>
	)
}

export default RangeSlider
