import { menuAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
	createMultiStyleConfigHelpers(menuAnatomy.keys)

// define the base component styles
const baseStyle = definePartsStyle({
	// define the part you're going to style
	button: {
		bg: 'transparent',
		borderRadius: '50%',
		width: '1.25rem',
		height: '1.25rem',
		display: 'inline-block',
		_active: {
			bg: 'menu.bg_active',
		},
		_disabled: {
			opacity: 0.5,
		},
	},
	list: {
		bg: 'menu.bg',
		border: 'none',
		borderRadius: 'unset',
		p: 0,
		boxShadow: 'unset',
		minWidth: 'auto',
		paddingInline: 0,
	},
	groupTitle: {
		color: 'label_color',
		marginInline: 2,
	},
	icon: {
		'&': {
			marginInlineEnd: 2,
		},
	},
	item: {
		minWidth: 'auto',
		color: 'white',
		bg: 'menu.bg',
		borderRadius: 'unset',
		paddingBlock: '0.25rem',
		paddingInline: '1.25rem',
		_focus: {
			bg: 'menu.bg_active',
			borderRadius: 'unset',
		},
		fontSize: 14,
		fontWeight: 400,
		button: {
			span: {
				fontSize: 14,
				textTransform: 'none',
			},
			marginInline: 0,
		},
	},
})

const variants = {
	userMenu: {
		button: {
			borderRadius: 0,
			lineHeight: 0,
			h: 'auto',
			w: 'auto',
			_active: {
				bg: 'transparent',
			},
		},
		groupTitle: {
			textTransform: 'uppercase',
			fontWeight: 500,
			fontSize: 'sm',
			paddingInline: 4,
			paddingBlock: 2,
			m: 0,
		},
		list: {
			width: '322px',
			zIndex: 9999,
		},
		item: {
			paddingInline: '16px',
			paddingBlock: '10px',
			icon: {
				size: '2rem',
			},
		},
		divider: {
			mt: 0,
			mb: 0,
			borderColor: 'border_color',
		},
		icon: {
			'&': {
				marginInlineStart: 0,
				marginInlineEnd: 2,
			},
		},
	},
}

export const Menu = defineMultiStyleConfig({ baseStyle, variants })
