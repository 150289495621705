// React
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
// Chakra
import { Flex, Icon } from '@chakra-ui/react'
import { MdCheckCircle } from 'react-icons/md'
// Components
import AlertRecipientActions from './AlertRecipientActions'
import AlertRecipientToggle from './AlertRecipientToggle'
import TanStackTable from '@UI/TanStackTable/Table'
import DebouncedInput from '@UI/DebouncedInput/DebouncedInput'
// Table
import {
	useReactTable,
	createColumnHelper,
	getCoreRowModel,
	getFilteredRowModel,
	type SortingState,
	getSortedRowModel,
} from '@tanstack/react-table'
import { rankItem } from '@tanstack/match-sorter-utils'
import type { FilterFn } from '@tanstack/react-table'
// Store
import { useGetNotificationRecipientsQuery } from '@Store/notifications/notificationRecipientsApi'
import { useGetZonesQuery, selectAlertZones } from '@Store/zones/zonesApi'
import type { Site, NotificationRecipient } from '@Store/types'

const columnHelper = createColumnHelper<NotificationRecipient>()

type AlertRecipientsTableProps = {
	siteId: Site['id']
	testId?: string
}

const sortableColumns = [
	'name',
	'address',
	'address_type',
	'zone_ids',
	'sensors_down',
]

const emptyArray = [] as NotificationRecipient[]

const AlertRecipientsTable = ({
	siteId,
	testId,
}: AlertRecipientsTableProps) => {
	const { t } = useTranslation('pages', {
		keyPrefix: 'alertSettings.alertRecipientsTable',
	})

	const [globalFilter, setGlobalFilter] = useState('')
	const [sorting, setSorting] = useState<SortingState>([
		{ id: 'name', desc: true },
	])

	const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
		// Rank the item
		const itemRank = rankItem(row.getValue(columnId), value)
		// Store the ranking info
		addMeta(itemRank)
		// Return if the item should be filtered in/out
		return itemRank.passed
	}

	const { data: recipients } = useGetNotificationRecipientsQuery(siteId, {
		skip: !siteId,
	})

	const { alertZones, isZonesSuccess } = useGetZonesQuery(
		{ siteId },
		{
			skip: !siteId,
			selectFromResult: ({ data, isSuccess }) => ({
				alertZones: selectAlertZones(data).map((zone) => ({
					id: zone.id,
					name: zone.name,
				})),
				isZonesSuccess: isSuccess,
			}),
		}
	)

	const getZoneNames = useCallback(
		(zoneIds: number[] | null) =>
			isZonesSuccess &&
			alertZones
				?.filter((zone) => zoneIds?.includes(zone.id))
				.map((zone) => zone.name)
				.join(', '),
		[isZonesSuccess, alertZones]
	)

	const columns = [
		columnHelper.accessor('enabled', {
			id: 'enabled',
			header: t('columns.enabled'),
			cell: ({ row }) => <AlertRecipientToggle recipient={row.original} />,
		}),
		columnHelper.accessor('name', {
			id: 'name',
			header: t('columns.name'),
			cell: ({ getValue }) => getValue(),
		}),
		columnHelper.accessor('address_type', {
			id: 'address_type',
			header: t('columns.type'),
			cell: ({ getValue }) => getValue(),
		}),
		columnHelper.accessor('address', {
			id: 'address',
			header: t('columns.contact'),
			cell: ({ getValue }) => getValue(),
		}),
		columnHelper.accessor('zone_ids', {
			id: 'zone_ids',
			header: t('columns.zones'),
			cell: ({ getValue }) => {
				const zoneIds = getValue()
				return getZoneNames(zoneIds)
			},
		}),
		columnHelper.accessor('sensors_down', {
			id: 'sensors_down',
			header: t('columns.sensors_down'),
			cell: ({ getValue }) => (
				<Icon
					as={MdCheckCircle}
					color={getValue() ? 'primary' : 'gray.500'}
					marginInlineStart={16}
					data-testid={`sensor-down-${getValue() ? 'true' : 'false'}`}
				/>
			),
		}),
		columnHelper.display({
			header: t('columns.actions'),
			cell: ({ row }) => <AlertRecipientActions recipient={row.original} />,
		}),
	]

	const table = useReactTable({
		columns,
		data: recipients ?? emptyArray,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		globalFilterFn: fuzzyFilter,
		onGlobalFilterChange: setGlobalFilter,
		onSortingChange: setSorting,
		state: {
			sorting,
			globalFilter,
		},
	})

	return (
		<>
			<Flex mb={3}>
				<DebouncedInput
					title={t('actions.search')}
					placeholder={t('actions.search')}
					value={globalFilter}
					onChange={(value) => {
						setGlobalFilter(String(value))
					}}
					data-testid='alert-recipient-search'
				/>
			</Flex>
			<TanStackTable
				table={table}
				sortableColumns={sortableColumns}
				testId={testId}
			/>
		</>
	)
}

export default AlertRecipientsTable
