// React
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
// Components
import { Page } from '@Components/Layouts'
import Skeleton from '@UI/Skeleton/Skeleton'
import Report from '@Pages/Analytics/Report/Report'
import ErrorBoundary from '@/components/App/ErrorHandling/ErrorBoundary'
// Store
import { selectSiteTimeZone, useGetSiteQuery } from '@Store/sites/sitesApi'

const Analytics = () => {
	const { t } = useTranslation('pages', { keyPrefix: 'analytics.tabs' })
	const { siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)

	const { siteTimeZone, isSuccess } = useGetSiteQuery(siteId, {
		selectFromResult: ({ data, isSuccess, isLoading }) => ({
			siteTimeZone: selectSiteTimeZone(data),
			isSuccess,
			isLoading,
		}),
	})

	if (!siteTimeZone) return null

	return (
		<Skeleton isLoaded={isSuccess}>
			<Page heading={t('analytics')}>
				<ErrorBoundary>
					<Report siteId={siteId} siteTimeZone={siteTimeZone} />
				</ErrorBoundary>
			</Page>
		</Skeleton>
	)
}

export default Analytics
