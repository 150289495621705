import { Flex } from '@chakra-ui/react'

import Notifications from './Notifications/Notifications'
import QuickActions from './QuickActions/QuickActions'

const RightPanel = () => {
	return (
		<Flex
			className='RightPanel'
			flexDir='column'
			overflow='hidden'
			w='316px'
			h='100%'
		>
			<QuickActions />
			<Notifications />
		</Flex>
	)
}

export default RightPanel
