import SystemWarningsAcknowledgeAll from '@Components/Navigation/SystemWarnings/SystemWarningsAcknowledgeAll'
import SystemWarning from '@Components/Navigation/SystemWarnings/SystemWarning'
import SystemWarningsNoWarnings from '@Components/Navigation/SystemWarnings/SystemWarningsNoWarnings'
import Skeleton from '@UI/Skeleton/Skeleton'

import { useGetSiteWarningsQuery } from '@Store/siteWarnings/siteWarningsApi'

const SystemWarningsList = ({
	siteId,
	siteTimeZone,
}: {
	siteId: number
	siteTimeZone: string
}) => {
	const {
		data: warnings,
		isSuccess,
		isLoading,
		isFetching,
	} = useGetSiteWarningsQuery(siteId, {
		skip: !siteId,
	})

	const isLoaded = !isFetching && !isLoading

	return (
		<Skeleton isLoaded={isLoaded}>
			{isSuccess && <SystemWarningsAcknowledgeAll siteId={siteId} />}
			{isSuccess &&
				warnings?.map((warning) => (
					<SystemWarning
						key={warning.id}
						siteId={siteId}
						siteTimeZone={siteTimeZone}
						systemWarning={warning}
					/>
				))}
			{isSuccess && warnings?.length < 1 && <SystemWarningsNoWarnings />}
		</Skeleton>
	)
}

export default SystemWarningsList
