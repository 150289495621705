import { z } from 'zod'
import globalSchema from '@/components/Forms/BaseForm/BaseForm.schema'
import i18n from '@/i18n'

const t = (key: string) => i18n.t(key, { ns: 'forms' })

const installationFormFields = z.object({
	name: globalSchema.shape.name,
	latitude: globalSchema.shape.latitude,
	longitude: globalSchema.shape.longitude,
	direction: globalSchema.shape.direction,
	// Installation schema
	accept_location_updates: z.boolean().optional(), // Enable location from device
	altitude: z.number(),
	anchored: z.boolean().optional(), // keep_installation_centered
	locatable_id: z.number().optional(),
	locatable_type: z.string().optional(),
	sentry_type: z.enum(['fixed', 'on_the_move', 'dismounted'], {
		errorMap: (issue, ctx) => ({
			message: t('installationForm.validation.sentry_type'),
		}),
	}),
	site_id: z.number().optional(),
	track_length: z
		.number()
		.min(0, t('installationForm.validation.track_length'))
		.optional(),
	tracked: z.boolean().optional(), // track_installation_location
	status_color: z.enum(['red', 'orange', 'green', 'grey']).optional(),
})

const installationFormSchema = installationFormFields.superRefine(
	(data, ctx) => {
		if (data.accept_location_updates) {
			if (!data.locatable_id) {
				ctx.addIssue({
					path: ['locatable_id'],
					message: t('installationForm.validation.locatable_id'),
					code: z.ZodIssueCode.custom,
				})
			}
		}
	}
)

const installationDefaultValuesSchema = installationFormFields.pick({
	altitude: true,
	sentry_type: true,
	site_id: true,
	status_color: true,
	name: true,
	latitude: true,
	longitude: true,
	direction: true,
})

type InstallationCreateValuesType = z.infer<
	typeof installationDefaultValuesSchema
>

const ADD_INSTALLATION_DEFAULT_VALUES: InstallationCreateValuesType = {
	altitude: 0,
	direction: 0,
	latitude: 0,
	longitude: 0,
	name: '',
	sentry_type: 'fixed',
	status_color: 'grey',
	site_id: 0,
}

export {
	installationFormSchema,
	ADD_INSTALLATION_DEFAULT_VALUES,
	installationDefaultValuesSchema,
}
