import { useEffect } from 'react'

import { useLeafletContext } from '@react-leaflet/core'
import L, { type LatLngExpression } from 'leaflet'

import 'leaflet.sector'

type DonutProps = {
	latitude: number
	longitude: number
	innerRadius: number
	outerRadius: number
	startAngle: number
	stopAngle: number
	fillColor?: string
	strokeColor?: string
	strokeWeight?: number
	dashArray?: string
	testId?: string
}

const Donut = ({
	latitude,
	longitude,
	innerRadius,
	outerRadius,
	startAngle,
	stopAngle,
	fillColor,
	strokeColor,
	strokeWeight = 2,
	dashArray,
	testId,
}: DonutProps) => {
	const context = useLeafletContext()

	const center: LatLngExpression = [latitude, longitude]
	useEffect(() => {
		const container = context.layerContainer || context.map
		const sector = new (L as any).sector({
			center,
			innerRadius,
			outerRadius,
			startBearing: startAngle,
			endBearing: stopAngle,
			numberOfPoints: 1250,
			weight: strokeWeight,
			fillColor,
			color: strokeColor,
			stroke: true,
			fillOpacity: 0.6,
			interactive: false,
			dashArray,
		})

		container.addLayer(sector)

		const donutSector = sector.getElement()

		if (donutSector && testId) {
			donutSector.dataset.testid = testId
		}

		return () => container.removeLayer(sector)
	})
	return null
}
export default Donut
