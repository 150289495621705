import { defineStyleConfig } from '@chakra-ui/react'

const TooltipStyles = defineStyleConfig({
	baseStyle: {
		fontSize: 'xs',
		fontWeight: 400,
		whiteSpace: 'pre-line',
		bg: 'card_bg',
		color: 'chakra-placeholder-color',
		zIndex: 99999,
	},
	variants: {
		dark: {
			bg: 'panel_bg',
		},
	},
})

export const Tooltip = {
	...TooltipStyles,
}
