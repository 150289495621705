import { Flex, Text } from '@chakra-ui/react'

import { StatusDot } from '.'

export type Band = {
	label: '2.4 5.8' | '915' | '433' | 'GNSS'
	value: '24_58' | '915' | '433' | 'gnss'
}

type Status = {
	'24_58': boolean
	gnss: boolean
	'915'?: boolean
	'433'?: boolean
}

type BandStatusProps = {
	bands: Band[]
	id: number
	bandStatus: Status
	width?: string
	gap?: string
	testId?: string
}

const BandStatus = ({
	bands,
	id,
	bandStatus,
	testId,
	width = '342px',
	gap = '24px',
}: BandStatusProps) => {
	return (
		<Flex w={width} gap={gap} p='12px 30px' data-testid={testId}>
			{bands.map(({ label, value }) => (
				<Flex key={`${id}-${label}`} gap='8px' alignItems='center' w='67.5px'>
					<StatusDot color={bandStatus[value] ? 'green' : 'red'} size='10px' />
					<Text fontSize='12px' color='label_color' w='34px'>
						{label}
					</Text>
				</Flex>
			))}
		</Flex>
	)
}

export default BandStatus
