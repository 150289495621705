// Note: reusable over track, disruption, & alert summary tabs

import { Flex, Text } from '@chakra-ui/react'
import Headings from '@UI/Headings/Headings'

type NumberDisplayProps = {
	title: string
	number: number | string
	testId?: string
}

const NumberDisplay = ({ title, number, testId }: NumberDisplayProps) => (
	<Flex p='16px 18px' direction='column' gap='16px' alignItems='center'>
		<Headings.FormHeading title={title} />
		<Text fontSize='3xl' data-testid={testId}>
			{number}
		</Text>
	</Flex>
)

export default NumberDisplay
