import type { AppStartListening } from '@Store/middleware'
import { setActiveCameraId } from '@Store/ui/uiSlice'
import { store } from '@Store/index'
import type { Site, Camera } from '@Store/types/index'

import { baseUrl } from '@Constants/api'

// get the siteId from /:siteId
const getSiteId = () => {
	const siteId = Number(window.location.pathname.replace(/^\/(\d+).*$/, '$1'))
	if (siteId > 0) return siteId
	else return null
}

const onloadSiteId = getSiteId()

// the session storage value is keyed by hostname and siteId
const hostname = baseUrl.replace(/^https*:\/\/(.+)\/*$/, '$1')

const persistState = (
	hostname: string,
	siteId: Site['id'],
	cameraId: Camera['id']
) => {
	if (cameraId) {
		sessionStorage.setItem(`${hostname}-${siteId}-camera`, String(cameraId))
	} else {
		sessionStorage.removeItem(`${hostname}-${siteId}-camera`)
	}
}

const fetchState = (hostname: string, siteId: Site['id']) => {
	const cameraId = sessionStorage.getItem(`${hostname}-${siteId}-camera`)
	if (Number(cameraId) > 0) return Number(cameraId)
	else return null
}

const addCameraPersistenceMiddleware = (startListening: AppStartListening) => {
	startListening({
		actionCreator: setActiveCameraId,
		effect: (action) => {
			const cameraId = action.payload
			const siteId = getSiteId()
			if (siteId) persistState(hostname, siteId, cameraId)
		},
	})
}

// On initial load get and set the cameraId from sessionStorage
if (onloadSiteId) {
	const cameraId = fetchState(hostname, onloadSiteId)
	if (cameraId) {
		// this file is a middleware import, so the the store has not been
		// instantiated yet.  queueMicrotask schedules this function to run
		// at the end of the current task, before control is returned to
		// event loop
		queueMicrotask(() => store.dispatch(setActiveCameraId(cameraId)))
	}
}

// Exports
export { addCameraPersistenceMiddleware }
