import { useTranslation } from 'react-i18next'
import { Box, Flex, Text } from '@chakra-ui/react'

import Headings from '@UI/Headings/Headings'
import VideoRecordingsTable from './VideoRecordingsTable'

import { useGetVideoRecordingsSummaryQuery } from '@Store/analytics/analyticsApi'

type VideoRecordingsProps = {
	siteId: number
	startTime: string
	endTime: string
	siteTimeZone: string
}

const VideoRecordings = ({
	siteId,
	startTime,
	endTime,
	siteTimeZone,
}: VideoRecordingsProps) => {
	const { t } = useTranslation('pages', {
		keyPrefix: 'analytics.videoRecordings',
	})

	const { totalCount } = useGetVideoRecordingsSummaryQuery(
		{
			siteId,
			format: 'table',
			startTime: startTime,
			endTime: endTime,
			page: 0,
			perPage: 10,
			sort_by_created_at: 'DESC',
		},
		{
			selectFromResult: ({ data }) => ({
				totalCount: data?.meta.total,
			}),
		}
	)

	return (
		<Box width='100%'>
			<Headings.ChartHeading title={t('videoRecordingsDetails')} />
			<Flex alignItems='center' gap='8px' my={4}>
				<Headings.FieldHeading title={t('total')} />
				<Text fontSize='xl' as='b'>
					{totalCount}
				</Text>
			</Flex>
			<VideoRecordingsTable
				siteId={siteId}
				startTime={startTime}
				endTime={endTime}
				siteTimeZone={siteTimeZone}
			/>
		</Box>
	)
}

export default VideoRecordings
