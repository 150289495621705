import {
	Skeleton as ChakraSkeleton,
	type SkeletonProps as ChakraSkeletonProps,
} from '@chakra-ui/react'

type SkeletonProps = ChakraSkeletonProps & { children: React.ReactNode }

const Skeleton = ({ children, isLoaded, ...props }: SkeletonProps) => (
	<ChakraSkeleton speed={1.2} isLoaded={isLoaded} {...props}>
		{children}
	</ChakraSkeleton>
)

export default Skeleton
