import { IconButton } from '@chakra-ui/react'
import { TbWiper } from 'react-icons/tb'
import { useParams } from 'react-router-dom'

import { useTriggerWiperMutation } from '@Store/cameras/camerasApi'

const CameraWiperButton = ({
	cameraId,
	isDisabled,
}: {
	cameraId: number
	isDisabled: boolean
}) => {
	const { siteId: siteIdParam } = useParams()
	const [triggerWiper] = useTriggerWiperMutation()
	const handleClick = () => {
		triggerWiper({ siteId: Number(siteIdParam), cameraId })
	}
	return (
		<IconButton
			icon={<TbWiper size={24} />}
			variant='unstyled'
			onClick={handleClick}
			aria-label='trigger camera wiper'
			sx={{
				svg: { transform: 'scale(1.2)', margin: '0 auto' },
			}}
			marginInline={2}
			isDisabled={isDisabled}
		/>
	)
}

export default CameraWiperButton
