// Base Redux Toolkit Query API
import { createSelector } from '@reduxjs/toolkit'
import { baseApi } from '../baseApi'
import { type CoreApiError, formatApiErrors } from '@Store/utils/errorUtils'

import type { Site, MapLayer, SiteMapLayer } from '@Store/types/index'

import { baseUrl, isProduction } from '@Constants/api'

type GetSiteMapLayersRequest = Site['id']
type CreateMapLayerRequest = Pick<
	MapLayer,
	'name' | 'attribution' | 'url' | 'min_zoom' | 'max_zoom' | 'fallback'
>
type UpdateMapLayerRequest = Pick<
	MapLayer,
	'name' | 'attribution' | 'url' | 'min_zoom' | 'max_zoom' | 'fallback' | 'id'
>
type DeleteMapLayerRequest = Pick<MapLayer, 'id'>
type CreateSiteMapLayerAssociationRequest = {
	mapLayerId: MapLayer['id']
	siteId: Site['id']
}
type DeleteSiteMapLayerAssociationRequest = {
	layerId: MapLayer['id']
	siteId: number
}
type BulkDeleteSiteMapLayerAssociations = {
	siteId: Site['id']
	layers: Array<MapLayer['id']>
}
type InitUploadOfflineMapLayerRequest = {
	maplayerName: string
	attribution: string
	fallback: boolean
	min_zoom: number
	max_zoom: number
	size: number
	filename: string
	quantity: number
}
type CancelUploadOfflineMapLayerRequest = { fileID: string }
type UploadOfflineMapLayerChunkRequest = unknown

export const mapLayersApi = baseApi
	.enhanceEndpoints({
		addTagTypes: ['MapLayers'],
	})
	.injectEndpoints({
		endpoints: (builder) => ({
			// https://au-dev.droneshield.xyz/api/docs#get-/api/map_layers
			getMapLayers: builder.query<MapLayer[], void>({
				query: () => ({
					url: '/api/map_layers',
				}),
				transformResponse: (response: { map_layers: MapLayer[] }) => {
					return response.map_layers
				},
				providesTags: ['MapLayers'],
			}),
			// https://au-dev.droneshield.xyz/api/docs#post-/api/map_layers
			createMapLayer: builder.mutation<MapLayer, CreateMapLayerRequest>({
				query: ({ ...post }) => ({
					url: '/api/map_layers',
					method: 'POST',
					body: {
						map_layer: {
							...post,
						},
					},
				}),
				transformResponse: (response: { map_layer: MapLayer }) => ({
					...response.map_layer,
					tileLayerUrl: buildTileLayerUrl(response.map_layer),
				}),
				transformErrorResponse: (response: CoreApiError) =>
					formatApiErrors<MapLayer>(response),
				invalidatesTags: ['MapLayers'],
			}),
			// https://au-dev.droneshield.xyz/api/docs#patch-/api/map_layers/-id-
			updateMapLayer: builder.mutation<MapLayer, UpdateMapLayerRequest>({
				query: ({ id: layerId, ...patch }) => ({
					url: `/api/map_layers/${layerId}`,
					method: 'PATCH',
					body: {
						map_layer: {
							...patch,
						},
					},
				}),
				invalidatesTags: ['MapLayers'],
				transformResponse: (response: { map_layer: MapLayer }) =>
					response.map_layer,
				transformErrorResponse: (response: CoreApiError) =>
					formatApiErrors<MapLayer>(response),
			}),
			// https://au-dev.droneshield.xyz/api/docs#delete-/api/map_layers/-id-
			deleteMapLayer: builder.mutation<
				{ message: string },
				DeleteMapLayerRequest
			>({
				query: ({ id: layerId }) => ({
					url: `/api/map_layers/${layerId}`,
					method: 'DELETE',
				}),
				invalidatesTags: ['MapLayers'],
			}),
			// https://au-dev.droneshield.xyz/api/docs#post-/api/maps/upload/init
			// TODO: typescript
			initUploadOfflineMapLayer: builder.mutation<
				{ fileID: string },
				InitUploadOfflineMapLayerRequest
			>({
				query: ({
					maplayerName,
					attribution,
					fallback,
					min_zoom,
					max_zoom,
					size,
					filename,
					quantity,
				}) => ({
					url: '/api/maps/upload/init',
					method: 'POST',
					headers: {
						'X-Content-Length': size as unknown as string,
						'X-Content-Name': filename,
						'X-Chunks-Quantity': quantity as unknown as string,
					},
					body: {
						maplayerName,
						attribution,
						fallback,
						min_zoom,
						max_zoom,
					},
				}),
			}),
			// https://au-dev.droneshield.xyz/api/docs#post-/api/maps/upload/cancel
			cancelUploadOfflineMapLayer: builder.mutation<
				{ fileID: string; message: string },
				CancelUploadOfflineMapLayerRequest
			>({
				query: ({ fileID }) => ({
					url: '/api/maps/upload/cancel',
					method: 'POST',
					headers: {
						'X-Content-Id': fileID,
					},
				}),
			}),
			// https://au-dev.droneshield.xyz/api/docs#post-/api/maps/upload
			uploadOfflineMapLayer: builder.mutation<
				{ size?: number; message?: string },
				UploadOfflineMapLayerChunkRequest
			>({
				query: ({ chunk, fileID, chunkID }) => ({
					url: '/api/maps/upload',
					method: 'POST',
					body: chunk,
					headers: {
						'Content-Type': 'application/octet-stream',
						'X-Content-Id': fileID,
						'X-Chunk-Id': chunkID,
					},
				}),
				// // NOTE: Once the alerts websocket is implemented, we can rely on that instead
				// // to populate the new map list as required.
				// async onQueryStarted(props, { dispatch, queryFulfilled }) {
				// 	const { data } = await queryFulfilled
				// 	if (data.message?.includes('Upload complete')) {
				// 		setTimeout(() => {
				// 			dispatch(mapLayersApi.util.invalidateTags(['MapLayers']))
				// 		}, 5000)
				// 	}
				// },
			}),
			// https://au-dev.droneshield.xyz/api/docs#get-/api/sites/-site_id-/map_layers
			getSiteMapLayers: builder.query<MapLayer[], GetSiteMapLayersRequest>({
				query: (siteId) => ({
					url: `/api/sites/${siteId}/map_layers`,
				}),
				providesTags: ['MapLayers'],
				transformResponse: (response: { site_map_layer: SiteMapLayer[] }) =>
					response.site_map_layer
						.filter((layer) => layer.map_layer) // C2-9612 map_layer undefined
						.map((layer) => layer.map_layer)
						.map((layer) => ({
							...layer,
							tileLayerUrl: buildTileLayerUrl(layer),
						})),
			}),
			// https://au-dev.droneshield.xyz/api/docs#post-/api/sites/-site_id-/map_layers
			createSiteMapLayerAssociation: builder.mutation<
				SiteMapLayer,
				CreateSiteMapLayerAssociationRequest
			>({
				query: ({ siteId, mapLayerId }) => ({
					url: `/api/sites/${siteId}/map_layers`,
					method: 'POST',
					body: {
						site_map_layer: { map_layer_id: mapLayerId },
					},
				}),
				transformResponse: (response: { site_map_layer: SiteMapLayer }) =>
					response.site_map_layer,
				invalidatesTags: ['MapLayers'],
			}),
			// https://au-dev.droneshield.xyz/api/docs#delete-/api/sites/-site_id-/map_layers/-id-
			deleteSiteMapLayerAssociation: builder.mutation<
				{ message: string },
				DeleteSiteMapLayerAssociationRequest
			>({
				query: ({ siteId, layerId }) => ({
					url: `/api/sites/${siteId}/map_layers/${layerId}`,
					method: 'DELETE',
				}),
				invalidatesTags: ['MapLayers'],
			}),
			deleteAllSiteMapLayersAssociation: builder.mutation<
				Promise<void[]>,
				BulkDeleteSiteMapLayerAssociations
			>({
				async queryFn({ siteId, layers }, _queryApi, _extraOptions, baseQuery) {
					const promises = layers.map(async (layerId: number) => {
						await baseQuery({
							url: `/api/sites/${siteId}/map_layers/${layerId}`,
							method: 'DELETE',
						})
					})
					const result = Promise.all(promises)
					return { data: result }
				},
				invalidatesTags: ['MapLayers'],
			}),
		}),
	})

const buildTileLayerUrl = (mapLayer: MapLayer) => {
	const { offline, path, path_template, format, url } = mapLayer
	if (isProduction && offline) {
		return `/${path}${path_template}.${format}`
	} else if (offline) {
		return `${baseUrl}/${path}${path_template}.${format}`
	} else return url
}

export const selectMapLayers = createSelector(
	(data?: MapLayer[]) => data,
	(layers) =>
		layers?.map((layer) => ({
			...layer,
			tileLayerUrl: buildTileLayerUrl(layer),
		}))
)

export const selectUserMapLayer = createSelector(
	[
		(data?: MapLayer[]) => data,
		(siteMapLayers, userMapLayerId?: number) => userMapLayerId,
	],
	(siteMapLayers, userMapLayerId) =>
		siteMapLayers?.find((layer) => layer.id === userMapLayerId) ??
		siteMapLayers?.[0]
)

export const selectFallbackMapLayer = createSelector(
	selectMapLayers,
	(mapLayers) => mapLayers?.find((layer) => layer.fallback)
)

export const selectSiteMapLayerAssociationOptions = createSelector(
	(data?: MapLayer[]) => data,
	(layers) => {
		return [
			{
				label: 'Offline',
				options: layers
					?.filter((layer) => layer.offline)
					.map((layer) => ({ value: layer.id, label: layer.name })),
			},
			{
				label: 'Online',
				options: layers
					?.filter((layer) => !layer.offline)
					.map((layer) => ({ value: layer.id, label: layer.name })),
			},
		]
	}
)

export const selectSiteMapLayerAssociationDefaultValues = createSelector(
	(data?: MapLayer[]) => data,
	(data) =>
		(data ?? []).map((layer) => ({ value: layer.id, label: layer.name }))
)

export const selectFirstValidMapLayer = createSelector(
	(data?: MapLayer[]) => data,
	(data) => data?.[0] ?? null
)

export const {
	useGetMapLayersQuery,
	useCreateMapLayerMutation,
	useUpdateMapLayerMutation,
	useDeleteMapLayerMutation,
	useInitUploadOfflineMapLayerMutation,
	useCancelUploadOfflineMapLayerMutation,
	useUploadOfflineMapLayerMutation,
	useGetSiteMapLayersQuery,
	useCreateSiteMapLayerAssociationMutation,
	useDeleteSiteMapLayerAssociationMutation,
	useDeleteAllSiteMapLayersAssociationMutation,
} = mapLayersApi
