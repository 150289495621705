import BandStatus from '../BandStatus'

import type { Disruptor } from '@Store/types'
import type { Band } from '../BandStatus'

type DisruptorBandStatusProps = {
	id: number
	disruptor: Disruptor
}

const bands: Band[] = [
	{ label: '2.4 5.8', value: '24_58' },
	{ label: '915', value: '915' },
	{ label: '433', value: '433' },
	{ label: 'GNSS', value: 'gnss' },
]

const getDisruptorBandsStatus = (disruptor: Disruptor) => ({
	gnss: disruptor.gnss_trigger_engaged,
	'433': disruptor.band_433_trigger_engaged,
	'915': disruptor.band_915_trigger_engaged,
	'24_58': disruptor.band_24_58_trigger_engaged,
})

const DisruptorBandStatus = ({ id, disruptor }: DisruptorBandStatusProps) => {
	const disruptorStatus = getDisruptorBandsStatus(disruptor)

	return (
		<BandStatus
			bands={bands}
			bandStatus={disruptorStatus}
			id={id}
			testId='disruptors-band-status'
		/>
	)
}

export default DisruptorBandStatus
