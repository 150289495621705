import { z } from 'zod'

const radiatingCircleSchema = z.object({
	coordinates: z.array(z.number()).nullable(),
	name: z.string(),
})

export const displaySettingsSchema = z.object({
	radiatingCircle: z.record(z.string(), radiatingCircleSchema),
	visibleZoneTypes: z.array(z.string()),
	showMarkers: z.boolean(),
	displayedSectors: z.any(),
})

type DefaultValues = z.infer<typeof displaySettingsSchema>

export const displaySettingsDefaultValues: DefaultValues = {
	radiatingCircle: {},
	visibleZoneTypes: [],
	showMarkers: false,
	displayedSectors: {},
}
