import { useMemo } from 'react'
import {
	DEFAULT_FREQUENCY_BAND,
	FREQUENCY_LIMITS,
	type FrequencyKeys,
} from '@Constants/sensorFilters'
import { convertHzToGHz } from '@Utils/mathUtils'

const UseMinMaxFrequencyRange = (frequencyBand: string) => {
	const frequencyValue = useMemo(() => {
		const defaultFrequencyBand = frequencyBand
			? frequencyBand
			: DEFAULT_FREQUENCY_BAND
		return defaultFrequencyBand?.replace('MHz', '') as FrequencyKeys
	}, [frequencyBand])

	const minFrequencyRange = useMemo(() => {
		return FREQUENCY_LIMITS[frequencyValue].min
	}, [frequencyValue])

	const maxFrequencyRange = useMemo(() => {
		return FREQUENCY_LIMITS[frequencyValue].max
	}, [frequencyValue])

	return {
		minFrequencyRange: convertHzToGHz(minFrequencyRange),
		maxFrequencyRange: convertHzToGHz(maxFrequencyRange),
	}
}

export default UseMinMaxFrequencyRange
