import { type GroupBase, type OptionsOrGroups } from 'chakra-react-select'
import { FormControl, FormErrorMessage } from '@chakra-ui/react'

import MultiSelectUI from '@Components/_UI/Select/MultiSelect'

// Types
import type { FC } from 'react'
import type { RegisterType } from '@Components/FormElements/types/RegisterType'
import { Controller, useFormContext } from 'react-hook-form'
import type {
	MultiSelectOptions,
	MultiSelectOption,
} from '@Components/_UI/Select/MultiSelect'
import type { FormSelectValueType } from './Select'

type MultiSelectProps = {
	title?: string
	id?: string
	options:
		| OptionsOrGroups<MultiSelectOptions, GroupBase<MultiSelectOptions>>
		| MultiSelectOption[]
	defaultValue?: MultiSelectOption[]
	isLoading?: boolean
	isDisabled?: boolean
	placeholder?: string
	error?: string
	styles?: React.CSSProperties
	testId?: string
} & RegisterType<string>

const MultiSelect: FC<MultiSelectProps> = ({
	title = undefined,
	id,
	options,
	defaultValue,
	isDisabled = false,
	placeholder,
	error,
	register,
	styles,
	testId,
}) => {
	const htmlId = id ?? register?.name ?? 'select'
	const { control } = useFormContext()

	return (
		<FormControl isInvalid={!!error} isDisabled={isDisabled} sx={styles}>
			<Controller
				control={control}
				name={htmlId}
				render={({ field: { value, onChange } }) => {
					const handleChange = (option: FormSelectValueType[]) => {
						const values = option.map((option) => option.value)
						onChange(values)
					}

					return (
						<>
							<MultiSelectUI
								title={title}
								id={htmlId}
								options={options}
								placeholder={placeholder}
								isDisabled={isDisabled}
								defaultValue={defaultValue}
								onChange={handleChange}
								testId={testId}
							/>
							<FormErrorMessage>{error}</FormErrorMessage>
						</>
					)
				}}
			/>
		</FormControl>
	)
}

export default MultiSelect
