import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useDisclosure } from '@chakra-ui/react'

// Components
import { FAULT_STATE, handleOpenDeviceManager } from '../index'
import DeleteModal from '@UI/Modals/DeleteModal'
import EditButton from '../../EditButton'

import EditPalette from '../../EditPalette'
import { useGetSiteLiveQuery, selectSmarthub } from '@Store/sites/sitesWsApi'

import {
	useDeleteRfSensorMutation,
	useToggleRfSensorMutation,
} from '@Store/rfSensors/rfSensorsApi'
import type { RfSensor } from '@Store/types'

import { DEFAULT_SMARTHUB_IP } from '@Constants/site'
import { useIpValidation } from '@Hooks/useTestIp'
import useAuth from '@Hooks/useAuth'

type RfMenuProps = {
	device: RfSensor
	siteId: number
	installationId: number
}

const RfMenu = ({ device, siteId, installationId }: RfMenuProps) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'devicesContent',
	})

	const { isAdmin } = useAuth()
	const baseRoute = `/${siteId}/installation/${installationId}`

	const [deleteRfSensor] = useDeleteRfSensorMutation()
	const [toggleRfSensor] = useToggleRfSensorMutation()

	const [deviceManagerIp, setDeviceManagerIp] = useState<string | undefined>()

	const { isOpen, onOpen, onClose } = useDisclosure()
	const { validateIps, isLoading } = useIpValidation()

	const { smarthub } = useGetSiteLiveQuery(siteId, {
		selectFromResult: ({ data }) => ({
			smarthub: selectSmarthub(data),
		}),
		skip: !isAdmin,
	})

	const handleDeleteRfSensor = async () => {
		try {
			await deleteRfSensor({ siteId, sensorId: device.id }).unwrap()
			onClose()
		} catch (error) {
			console.error('Delete RF sensor error', error)
		}
	}

	const handleToggleActive = async (sensorId: number, active: boolean) => {
		try {
			await toggleRfSensor({ siteId, sensorId, active: active }).unwrap()
		} catch (error) {
			console.error('Update RF sensor status error', error)
		}
	}

	const handleTestDeviceManagerIps = async () => {
		const ips = [
			...(device.ip ? [device.ip] : []),
			DEFAULT_SMARTHUB_IP,
			...(smarthub?.ip ? [smarthub.ip] : []),
		]
		const validIp = await validateIps(ips)
		if (validIp) {
			setDeviceManagerIp(validIp)
		}
	}

	return (
		<>
			<EditPalette
				testId={`device-menu-${device.name}`}
				onMenuButtonClick={isAdmin ? handleTestDeviceManagerIps : undefined}
			>
				<EditButton
					to={`${baseRoute}/rf_sensors/${device.id}/settings`}
					label={t('buttons.edit')}
					testId='edit-device'
				/>
				<EditButton
					to={`/${siteId}/rf_sensors/${device.id}/rf_filters`}
					type='filter'
					label={t('buttons.editFilters')}
					testId='edit-filters'
				/>
				<EditButton
					onClick={() =>
						handleToggleActive(device.id, device.active ? false : true)
					}
					type={device.active ? 'disable' : 'enable'}
					label={
						device.active
							? t('buttons.disableDetections')
							: t('buttons.enableDetections')
					}
					disabled={
						// RF sensor would be red when in fault but, we should still be able to
						// disable/enable it
						device.status_color === 'red' && device.state !== FAULT_STATE
					}
					testId={`disable-detections-${device.name}`}
				/>
				{isAdmin && (
					<EditButton
						onClick={() =>
							handleOpenDeviceManager(
								device.connection_type,
								device.serial_number,
								deviceManagerIp
							)
						}
						label={t('rfContent.deviceManager')}
						disabled={!deviceManagerIp}
						type='open'
						testId='open-device-manager'
						tooltip={!deviceManagerIp ? t('unableToRedirect') : undefined}
						isLoading={isLoading}
					/>
				)}
				<EditButton
					onClick={onOpen}
					label={t('buttons.delete')}
					type='delete'
					testId='delete-device'
				/>
			</EditPalette>

			<DeleteModal
				isOpen={isOpen}
				onClose={onClose}
				handleDelete={handleDeleteRfSensor}
				name={device.name}
			/>
		</>
	)
}

export default RfMenu
