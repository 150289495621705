import { useTranslation } from 'react-i18next'
import { useAppSelector } from '@/store'
import { selectMilsEnabled } from '@Store/user/userSlice'
import type { Units } from '@Components/FormElements/types/Units'
import { useMemo } from 'react'

const useUnits = (units: Units | undefined) => {
	const { t } = useTranslation('app', { keyPrefix: 'global.units' })
	const milsEnabled = useAppSelector(selectMilsEnabled)

	const isMils = useMemo(() => {
		return units === 'deg' && milsEnabled
	}, [units, milsEnabled])

	const unit = units ? (isMils ? t('mil') : t(units)) || null : null

	return { unit, isMils }
}

export default useUnits
