import { useEffect, useMemo, useState } from 'react'
import type { InputHTMLAttributes, ChangeEvent, ClipboardEvent } from 'react'
import lodashDebounce from 'lodash/debounce'
import { Flex, Input, FormLabel, type InputProps } from '@chakra-ui/react'

type DebouncedInputProps = {
	value: string | number
	onChange: (value: string | number) => void
	debounce?: number
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> &
	InputProps

const DebouncedInput = ({
	value: initialValue,
	onChange,
	debounce = 250,
	title,
	flexGrow = 0,
	...props
}: DebouncedInputProps) => {
	const [value, setValue] = useState(initialValue)

	const debouncedOnChange = useMemo(() => {
		const handler = (newValue: string | number) => {
			onChange(newValue)
		}
		return lodashDebounce(handler, debounce)
	}, [onChange, debounce])

	// Cancel any pending debounced calls
	useEffect(() => debouncedOnChange.cancel, [debouncedOnChange])

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const newValue = e.target.value
		setValue(newValue)
		debouncedOnChange(newValue)
	}

	const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
		e.preventDefault()
		const pastedValue = e.clipboardData.getData('text/plain')
		setValue(pastedValue)
		debouncedOnChange(pastedValue)
	}

	return (
		<Flex direction='column' flexGrow={flexGrow}>
			{title && <FormLabel>{title}</FormLabel>}
			<Input
				{...props}
				value={value}
				onChange={handleChange}
				onPaste={handlePaste}
			/>
		</Flex>
	)
}

export default DebouncedInput
