// Redux
import {
	createAsyncThunk,
	createSlice,
	isAnyOf,
	isPending,
} from '@reduxjs/toolkit'
import { authApi } from '@Store/auth/authApi'
import { baseApi } from '@Store/baseApi'
import { usersApi } from '@Store/user/usersApi'

// Utility
import { jwtTokenStillValid, jwtTokenUserId } from './jwtUtil'

// Types
import type { RootState } from '@Store/index'

export type Auth = {
	token?: string
}

// Initial State
const initialState: Auth = {}

// Augment initialState with JWT token from browser storage
const token = localStorage.getItem('token')
if (token !== null) {
	if (jwtTokenStillValid(token)) initialState.token = token
}

// Auth slice
export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(logout.fulfilled, (state, action) => {
			return initialState
		})

		builder.addMatcher(
			// User logged in
			// Store token in this slice
			isAnyOf(
				authApi.endpoints.login.matchFulfilled,
				authApi.endpoints.refresh.matchFulfilled
			),
			(state, { payload }) => {
				state.token = payload.token
				localStorage.setItem('token', payload.token)
			}
		)

		builder.addMatcher(isPending(logout), (state) => {
			// User logged out
			// Delete token in this slice
			delete state.token
			localStorage.removeItem('token')
		})

		builder.addMatcher(
			// User deactivated or deleted themself
			// Delete token in this slice
			isAnyOf(
				usersApi.endpoints.deactivateUser.matchFulfilled,
				usersApi.endpoints.deleteUser.matchFulfilled
			),
			(state, { payload, meta }) => {
				const userId = meta.arg.originalArgs.userId
				if (state?.token && userId === jwtTokenUserId(state.token)) {
					delete state.token
					localStorage.removeItem('token')
				}
			}
		)
	},
})

// Actions
export const logout = createAsyncThunk('auth/logout', (_, { dispatch }) => {
	dispatch(baseApi.util.resetApiState())
})

// Selectors
export const selectIsAuthenticated = (state: RootState) => {
	const token = (state.auth as Auth).token
	if (token !== undefined) return jwtTokenStillValid(token)
	return false
}

export const selectAuthenticatedUserId = (state: RootState) => {
	const token = (state.auth as Auth).token
	if (token !== undefined && jwtTokenStillValid(token)) {
		return jwtTokenUserId(token)
	}
	return null
}

export const selectJwtToken = (state: RootState) => {
	const token = (state.auth as Auth).token
	if (token !== undefined && jwtTokenStillValid(token)) {
		return token
	}
	return null
}

// Reducer slice
export default authSlice.reducer
