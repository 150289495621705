import { useTranslation } from 'react-i18next'
import formatInTimeZone from 'date-fns-tz/formatInTimeZone'

import { Flex } from '@chakra-ui/react'

import FetchError from '@UI/FetchError/FetchError'
import LineChart from '@UI/Charts/LineChart'
import Headings from '@UI/Headings/Headings'
import Skeleton from '@UI/Skeleton/Skeleton'

import { useGetDisruptionsSummaryQuery } from '@Store/analytics/analyticsApi'

import type { Serie } from '@nivo/line'

type DisruptionsLineChartProps = {
	siteId: number
	startTime: string
	endTime: string
	siteTimeZone: string
}

const getDateTz = (date: string, timeZone: string) => {
	const d = new Date(date)
	return formatInTimeZone(d, timeZone, 'yyyy-MM-dd')
}

const DisruptionsLineChart = ({
	siteId,
	startTime,
	endTime,
	siteTimeZone,
}: DisruptionsLineChartProps) => {
	const { t } = useTranslation('pages', {
		keyPrefix: 'analytics.disruptionsSummary',
	})

	const { isSuccess, isError, isLoading, isFetching, refetch, data } =
		useGetDisruptionsSummaryQuery({
			siteId: Number(siteId),
			format: 'graph',
			startTime: startTime,
			endTime: endTime,
		})

	const transformedData: { x: string; y: number }[] = Object.entries(
		data?.by_day ?? {}
	)
		.filter(([_, count]) => count !== 0)
		.map(([utcDate, count]) => ({
			x: getDateTz(utcDate, siteTimeZone),
			y: count,
		}))

	const chartData: Serie[] =
		transformedData.length > 0
			? [{ id: 'Disruptions', data: transformedData }]
			: []

	return (
		<Skeleton isLoaded={!isLoading && !isFetching} w='100%'>
			{isSuccess && (
				<Flex
					h='312px'
					minWidth={0}
					direction='column'
					gap='16px'
					marginBlockEnd={8}
					alignItems='center'
				>
					<Headings.ChartHeading title={t('disruptionsOverTime')} />
					<LineChart data={chartData} />
				</Flex>
			)}
			{isError && <FetchError refetch={refetch} entity={t('entities.chart')} />}
		</Skeleton>
	)
}

export default DisruptionsLineChart
