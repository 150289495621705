// React
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
// Chakra
import { Box, Button, Flex, FormControl, Icon } from '@chakra-ui/react'
import { MdAdd, MdInfo } from 'react-icons/md'
// Components
import Field from '@Components/FormElements'
import Tooltip from '@UI/Tooltip/Tooltip'
// State
import type { User, UserSiteAssociation } from '@Store/types'
import Select from '@UI/Select/Select'
// Type
import type {
	UserEditSchema,
	UserSchema,
} from '@Forms/UserForm/UserForm.schema'
import UserSiteListAssociation from '@Forms/UserForm/UserSiteListAssociation'
import useSiteListOptions from '@Forms/UserForm/useSiteListOptions'
import { useGetSitesListQuery } from '@Store/sites/sitesApi'
import { useGetClientQuery } from '@Store/clients/clientsApi'
import { useAppDispatch, useAppSelector } from '@/store'
import {
	selectUserSiteAssociations,
	updateUserSiteAssociations,
} from '@Store/ui/uiSlice'
type OptionType = { value: number | string; label: string | number }

const UserFormSitesAssociation = ({
	clientId,
	user,
	role,
}: {
	clientId: User['client_id']
	role: User['role']
	user: UserSchema | UserEditSchema
	handleAssociations?: (association: UserSiteAssociation[]) => void
}) => {
	const { t } = useTranslation('forms', { keyPrefix: 'userForm' })
	const siteAssociations = useAppSelector(selectUserSiteAssociations)
	const dispatch = useAppDispatch()

	useEffect(() => {
		if (clientId !== user.client_id) {
			dispatch(updateUserSiteAssociations([]))
		} else {
			if (user && user.site_associations && user.site_associations.length) {
				dispatch(updateUserSiteAssociations(user.site_associations))
			}
		}
	}, [user, dispatch, clientId])

	const [userRole, setUserRole] = useState<UserSiteAssociation['role'] | null>()
	const [userSiteId, setUserSiteId] = useState<number | null>()
	const [siteName, setSiteName] = useState<string | null>()

	// Options
	const siteListOptions = useSiteListOptions(clientId, siteAssociations)
	const userRoleOptions: OptionType[] = t('selects.siteUserRoleOptions', {
		returnObjects: true,
	})

	const isAddButtonDisabled = userRole && userSiteId
	const isAssociationError = !siteAssociations.length
	const handleAddSite = () => {
		const associatedSite = {
			site_id: userSiteId,
			role: userRole,
			site_name: siteName,
		} as UserSiteAssociation

		if (user && user?.id) {
			associatedSite.user_id = user.id
		}

		dispatch(
			updateUserSiteAssociations(
				siteAssociations.length
					? [...siteAssociations, associatedSite]
					: [associatedSite]
			)
		)
		setUserRole(null)
		setUserSiteId(null)
		setSiteName(null)
	}
	const handleDeleteSite = (id: number) => {
		const deleted = siteAssociations.filter((sites) => sites.site_id !== id)
		dispatch(updateUserSiteAssociations(deleted))
	}

	// Show alert when clients sites are empty.
	const { data: sites } = useGetSitesListQuery()
	const { data: client } = useGetClientQuery(clientId, {
		skip: !clientId,
	})
	const clientSitesList = sites?.filter((site) => site.client_id === clientId)
	if (role !== 'user') return null
	if (!clientId) return null

	if (!clientSitesList?.length)
		return (
			<>
				<Field.Divider title={t('headings.siteAssociation')} />
				<Flex gap={2} mt={3} p={2} bgColor='input_bg' alignItems='center'>
					<Icon as={MdInfo} color='primary' />
					<Flex direction='column'>
						<Box fontSize='sm' fontWeight={500}>
							{t('validation.clientSitesAssociation', {
								clientName: client?.name,
							})}
						</Box>
					</Flex>
				</Flex>
			</>
		)

	return (
		<Box data-testid='sites'>
			<Field.Divider title={t('headings.siteAssociation')} />
			{isAssociationError && (
				<Flex gap={2} mb={1} p={2} bgColor='input_bg' alignItems='center'>
					<Icon as={MdInfo} color='error' />
					<Flex direction='column'>
						<Box fontSize='sm' fontWeight={500}>
							{t('validation.siteAssociations', {
								clientName: client?.name,
							})}
						</Box>
					</Flex>
				</Flex>
			)}
			{clientId === 0 ? (
				<Flex gap={2} mt={3} p={2} bgColor='input_bg' alignItems='center'>
					<Icon as={MdInfo} color='primary' />
					<Flex direction='column'>
						<Box fontSize='sm' fontWeight={500}>
							{t('errors.selectClient')}
						</Box>
					</Flex>
				</Flex>
			) : (
				<Tooltip
					label={!siteListOptions?.length ? t('tooltips.newAssociation') : ''}
				>
					<Flex gap={2} alignItems='flex-end' position='relative' top='-0.5rem'>
						<FormControl
							flex={1}
							key={siteListOptions?.length}
							isInvalid={isAssociationError}
						>
							<Select
								variant='opened_on_top'
								title={t('headings.site')}
								options={siteListOptions}
								onChange={(option: OptionType) => {
									setUserSiteId(option?.value as number)
									setSiteName(option?.label as string)
								}}
								isDisabled={!siteListOptions?.length}
								testId='site'
							/>
						</FormControl>
						<Tooltip
							type='wrapper'
							label={
								!siteName && siteListOptions?.length ? t('tooltips.role') : ''
							}
							placement='bottom-start'
						>
							<FormControl
								flex={1}
								key={userSiteId}
								isInvalid={isAssociationError}
							>
								<Select
									variant='opened_on_top'
									title={t('headings.userRole')}
									options={userRoleOptions}
									onChange={(option: OptionType) =>
										setUserRole(option?.value as UserSiteAssociation['role'])
									}
									isDisabled={!siteName}
									testId='site-role'
								/>
							</FormControl>
						</Tooltip>
						<Button
							leftIcon={<MdAdd />}
							variant='action'
							onClick={handleAddSite}
							isDisabled={!isAddButtonDisabled}
							data-testid='add-site-association'
						>
							{t('buttons.add')}
						</Button>
					</Flex>
				</Tooltip>
			)}
			<>
				{!!siteAssociations?.length && (
					<UserSiteListAssociation
						userSiteList={siteAssociations}
						handleDelete={handleDeleteSite}
					/>
				)}
			</>
		</Box>
	)
}

export default UserFormSitesAssociation
