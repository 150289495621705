import { useMatch } from 'react-router-dom'

const useShowPreviewMode = () => {
	const previewResult = {
		isPreviewRoute: false,
		showRfSensorPreview: false,
		showRadarPreview: false,
		showCameraPreview: false,
		showDisruptorPreview: false,
		showDsxPreview: false,
		showRadarMaskPreview: false,
		showRadarMasksPreview: false,
		showRfFiltersPreview: false,
		showRfFiltersBearingPreview: false,
		showSiteMarkersPreview: false,
		showSitePreview: false,
		showInstallationPreview: false,
		showZonePreview: false,
		showCameraCalibrationPreview: false,
	}

	// Sensor CRUD routes
	const { sensorType } = [
		useMatch(
			'/:siteId/installation/:installationId/:sensorType/:sensorId/settings'
		),
		useMatch('/:siteId/installation/:installationId/:sensorType/add'),
		useMatch('/:siteId/:sensorType/add'),
		useMatch('/:siteId/:sensorType/:sensorId/settings'),
	].find((match) => match !== null)?.params ?? {
		sensorType: null,
	}

	if (sensorType) {
		switch (sensorType) {
			case 'rf_sensors':
				previewResult.showRfSensorPreview = true
				break
			case 'radar':
				previewResult.showRadarPreview = true
				break
			case 'camera':
				previewResult.showCameraPreview = true
				break
			case 'disruptor':
				previewResult.showDisruptorPreview = true
				break
			case 'rf_sensors_dsx':
				previewResult.showDsxPreview = true
				break
			case 'zone':
				previewResult.showZonePreview = true
				break
			default:
		}
	}

	const { maskType } = [
		useMatch('/:siteId/radar/:radarId/:maskType'),
		useMatch('/:siteId/radar/:radarId/:maskType/:maskId'),
		useMatch('/:siteId/radar/:radarId/:maskType/add'),
		useMatch('/:siteId/radar/:radarId/:maskType/:maskId/settings'),
	].find((match) => match !== null)?.params ?? {
		maskType: null,
	}

	if (maskType) {
		switch (maskType) {
			case 'mask':
				previewResult.showRadarMaskPreview = true
				break
			case 'masks':
				previewResult.showRadarMasksPreview = true
				break
			default:
		}
	}

	// RF and DSX filters
	const { rfFilters } = [
		useMatch('/:siteId/rf_sensors/:sensorId/:rfFilters/add'),
		useMatch('/:siteId/rf_sensors/:sensorId/:rfFilters/:rfFilterId/settings'),
		useMatch('/:siteId/rf_sensors_dsx/:sensorId/:rfFilters/add'),
		useMatch(
			'/:siteId/rf_sensors_dsx/:sensorId/:rfFilters/:rfFilterId/settings'
		),
	].find((match) => match !== null)?.params ?? {
		rfFilters: null,
	}
	if (rfFilters) {
		previewResult.showRfFiltersPreview = true
	}

	// Bearing
	const rfFilterBearing = [
		useMatch('/:siteId/rf_sensors/:sensorId/rf_filters'),
		useMatch('/:siteId/rf_sensors_dsx/:sensorId/rf_filters'),
	].filter((match) => match)
	if (rfFilterBearing.length) {
		previewResult.showRfFiltersBearingPreview = true
	}

	// Site Markers
	const { siteMarkers } = [
		useMatch('/:siteId/:siteMarkers/add'),
		useMatch('/:siteId/:siteMarkers/:markerId/settings'),
	].find((match) => match !== null)?.params ?? {
		siteMarkers: null,
	}
	if (siteMarkers === 'markers') {
		previewResult.showSiteMarkersPreview = true
	}
	// Installation
	const { installation } = [
		useMatch('/:siteId/:installation/:installationId/'),
		useMatch('/:siteId/:installation/:installationId/settings'),
	].find((match) => match !== null)?.params ?? {
		installation: null,
	}
	if (installation === 'installation') {
		previewResult.showInstallationPreview = true
	}

	// Add new site and site settings
	const site = [useMatch('/:siteId/settings'), useMatch('/site/add')].filter(
		(match) => match
	)
	if (site.length) {
		previewResult.showSitePreview = true
	}

	// Camera
	const isCameraCalibration = !!useMatch(
		'/:siteId/installation/:installationId/camera/:sensorId/calibrate'
	)

	if (isCameraCalibration) {
		previewResult.showCameraCalibrationPreview = true
	}

	// Set generic `isPreviewRoute` true if any of the above are true
	previewResult.isPreviewRoute = Object.keys(previewResult).some(
		(key) => previewResult[key as keyof typeof previewResult]
	)

	return previewResult
}

export default useShowPreviewMode
