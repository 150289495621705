import Button from '@UI/Button/Button'
import { Flex } from '@chakra-ui/react'
import {
	selectHasUnreadWarnings,
	selectUnreadWarningsCount,
	useGetSiteWarningsQuery,
	useUpdateAllSiteWarningsAsReadMutation,
} from '@Store/siteWarnings/siteWarningsApi'
import { useTranslation } from 'react-i18next'

const SystemWarningsAcknowledgeAll = ({ siteId }: { siteId: number }) => {
	const { t } = useTranslation('navigation', {
		keyPrefix: 'systemWarnings',
	})
	const { isUnread, count } = useGetSiteWarningsQuery(siteId, {
		skip: !siteId,
		selectFromResult: ({ data }) => ({
			isUnread: selectHasUnreadWarnings(data),
			count: selectUnreadWarningsCount(data),
		}),
	})

	const [updateAllWarningsAsRead] = useUpdateAllSiteWarningsAsReadMutation()

	const handleUpdateAllAsRead = async () => {
		try {
			await updateAllWarningsAsRead({
				siteId,
			})
		} catch (error) {
			// TODO: handle server-side errors if necessary
			console.error('Unhandled update Site Warnings error', error)
		}
	}

	return (
		<Flex justifyContent='flex-end'>
			{isUnread && (
				<Button
					testId='acknowledge-all'
					variant='link'
					size='sm'
					label={`${t('buttons.acknowledgeAll')} (${count})`}
					textTransform='none'
					fontWeight={500}
					onClick={handleUpdateAllAsRead}
				/>
			)}
		</Flex>
	)
}

export default SystemWarningsAcknowledgeAll
