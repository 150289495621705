import type { AppStartListening } from '@Store/middleware'
import { logout } from '@Store/auth/authSlice'

const addUnauthorizedRtkQueryMiddleware = (
	startListening: AppStartListening
) => {
	startListening({
		predicate: (action) =>
			action.type === 'api/executeQuery/rejected' &&
			(action.payload as { status?: number })?.status === 401,
		effect: (_, { dispatch }) => {
			dispatch(logout())
				.unwrap()
				.then(() => window.location.reload())
		},
	})
}

export { addUnauthorizedRtkQueryMiddleware }
