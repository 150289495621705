import TextPath from '@/components/MapShapes/TextPath/TextPath'
import type { LatLngTuple } from 'leaflet'

type DetectionSpeedLeaderProps = {
	detectionPosition: LatLngTuple
	speedLeaderPosition: LatLngTuple | [undefined, undefined]
	color: string
}

const DetectionSpeedLeader = ({
	detectionPosition,
	speedLeaderPosition,
	color = 'magenta',
}: DetectionSpeedLeaderProps) => {
	if (
		speedLeaderPosition[0] !== undefined &&
		speedLeaderPosition[1] !== undefined
	)
		return (
			<TextPath
				positions={[detectionPosition, speedLeaderPosition]}
				text='►'
				repeat={true}
				offset={3}
				attributes={{ fill: color }}
				color='transparent'
			/>
		)
}

export default DetectionSpeedLeader
