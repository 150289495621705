import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@chakra-ui/react'
import { formatDegToMils } from '@Utils/formatUtils'
import type { Units } from '@Components/FormElements/types/Units'
import useUnits from '@Hooks/useUnits'

const SliderCustomLabel = ({
	isAbsolute,
	customLabel,
	units,
}: {
	isAbsolute: boolean
	customLabel?: string | number
	units?: Units
}) => {
	const { t } = useTranslation('app', { keyPrefix: 'global' })
	const { unit, isMils } = useUnits(units)

	const customLabelText = useMemo(() => {
		if (isAbsolute) {
			if (units === 'deg') {
				const degValue = isMils
					? formatDegToMils(Number(customLabel))
					: customLabel
				return `${t('headings.absolute')} ${degValue} (${
					isMils ? t('units.mil') : t('units.deg')
				})`
			}
			return `${t('headings.absolute')} ${customLabel} (${units})`
		}
		if (customLabel && unit) return `${customLabel} (${unit})`
		return customLabel ? customLabel : null
	}, [customLabel, isMils, units, t, isAbsolute, unit])

	return (
		<Box position='absolute' right='0' fontSize='xs' color='label_color'>
			{customLabelText}
		</Box>
	)
}

export default SliderCustomLabel
