import { useTranslation } from 'react-i18next'

import {
	Flex,
	MenuItem,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
} from '@chakra-ui/react'
import { MdPerson } from 'react-icons/md'

import { useGetUserQuery } from '@Store/user/usersApi'
import ProfileForm from '@Forms/ProfileForm/ProfileForm'

const UserProfileMenuItem = ({ userId }: { userId: number }) => {
	const { t } = useTranslation('forms', { keyPrefix: 'profileForm' })

	const { isSuccess, data: user } = useGetUserQuery(userId, {
		skip: !userId,
	})

	const { isOpen, onOpen, onClose } = useDisclosure({ id: 'user-profile' })

	return (
		<>
			<MenuItem
				icon={<MdPerson size='1rem' />}
				data-testid='profile-settings'
				onClick={onOpen}
				disabled={!isSuccess}
			>
				{t('headings.profileSettings')}
			</MenuItem>
			{isOpen && user && (
				<Modal
					id='user-profile'
					isOpen={isOpen}
					onClose={onClose}
					isCentered
					closeOnOverlayClick={false}
				>
					<ModalOverlay />
					<ModalContent minW={500} h='665px'>
						<ModalHeader data-testid='client-modal-header'>
							<Flex alignItems='center' gap='8px'>
								{t('headings.profileSettings')}
							</Flex>
						</ModalHeader>
						<ModalCloseButton />
						<ModalBody p='0 16px 16px 16px' overflowY='auto'>
							<ProfileForm user={user} />
						</ModalBody>
					</ModalContent>
				</Modal>
			)}
		</>
	)
}

export default UserProfileMenuItem
