import { Link, useParams } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import { Box, useTheme } from '@chakra-ui/react'

import InfoText from '@UI/InfoText/InfoText'

const GpsCompassWarning = () => {
	const { siteId, installationId } = useParams()
	const installationUrl = `/${siteId}/installation/${installationId}/settings`

	const { t } = useTranslation('forms', { keyPrefix: 'gpsCompassForm' })

	const {
		semanticTokens: {
			colors: { primary: linkColor },
		},
	} = useTheme()

	return (
		<Box
			marginBlockStart={4}
			marginBlockEnd={7}
			data-testid='gps-compass-warnings'
		>
			<InfoText title={t('warning.title')} lineHeight={1}>
				<Trans
					ns='forms'
					i18nKey='gpsCompassForm.warning.description'
					components={[
						<Link
							key={1}
							to={installationUrl}
							style={{
								color: linkColor,
								fontWeight: 'bold',
								textDecoration: 'underline',
							}}
						/>,
					]}
				/>
			</InfoText>
		</Box>
	)
}

export default GpsCompassWarning
