// REACT
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
// STORE
import { useAppSelector } from '@/store'
import { selectUserSettings } from '@Store/user/userSlice'
import type { SiteInstallation } from '@Store/types'

export const useShowSectors = (installation?: SiteInstallation) => {
	const { siteId } = useParams()
	const userSettings = useAppSelector(selectUserSettings)

	// Return displayed sectors for particular site by default from User Settings
	const displayedSectorsValues = useMemo(() => {
		if (!userSettings) return
		if (!siteId) return

		return (
			userSettings?.zoneSettings?.displayedSectors?.[Number(siteId)]?.map(
				(sector) => sector.value
			) || []
		)
	}, [userSettings, siteId])

	const { installationId: installationIdParam, sensorType } = useParams()

	// Check if current installation is selected
	const activeInstallation = installation?.id === Number(installationIdParam)
	// Check if device group is selected in current installation
	const isCamerasSelected = sensorType === 'cameras' && activeInstallation
	const isDisruptorsSelected = sensorType === 'disruptors' && activeInstallation
	const isRadarsSelected = sensorType === 'radars' && activeInstallation
	const isRfSensorsSelected = sensorType === 'rf_sensors' && activeInstallation
	const isDsxSelected = sensorType === 'rf_sensors_dsx' && activeInstallation

	// Check 'Display Sensor Sectors' from Maps & Zones
	const displayCameraPref = displayedSectorsValues?.includes('cameras')
	const displayDisruptorPref = displayedSectorsValues?.includes('cannon')
	const displayRadarPref = displayedSectorsValues?.includes('radar')
	const displayRfSensorPref =
		displayedSectorsValues?.includes('rf_one') ||
		displayedSectorsValues?.includes('rf_patrol')
	const displayDsxPref = displayedSectorsValues?.includes('dronesentryx')
	const displayRfIntersectionPref =
		displayedSectorsValues?.includes('rf_intersection')

	return {
		showCameraSector: displayCameraPref || isCamerasSelected,
		showDisruptorSector: displayDisruptorPref || isDisruptorsSelected,
		showRadarSector: displayRadarPref || isRadarsSelected,
		showRfSensorSector: displayRfSensorPref || isRfSensorsSelected,
		showDsxSensorSector: displayDsxPref || isDsxSelected,
		showRfIntersection: displayRfIntersectionPref,
	}
}
