// React
import { useState } from 'react'

// Chakra
import { Flex, Text } from '@chakra-ui/react'

import Button from '@UI/Button/Button'
import LoginLayout from '@Components/Layouts/Login'

// Translations
import { useTranslation } from 'react-i18next'

import LoginForm from '@Forms/LoginForm/LoginForm'
import ForgotPasswordForm from '@Forms/ForgotPasswordForm/ForgotPasswordForm'

export type PageModes = 'login' | 'forgot' | 'reset'

// Login Page
export const Login = () => {
	const [pageMode, setPageMode] = useState<PageModes>('login')

	const { t } = useTranslation('forms', { keyPrefix: 'loginForm' })

	const handlePageMode = (mode: PageModes) => {
		setPageMode(mode)
	}

	return (
		<LoginLayout>
			{pageMode === 'login' && (
				<>
					<LoginForm handlePageMode={handlePageMode} />
				</>
			)}

			{pageMode === 'forgot' && (
				<>
					<ForgotPasswordForm handlePageMode={handlePageMode} />
				</>
			)}

			{pageMode === 'reset' && (
				<Flex direction='column' alignItems='center' gap='50px'>
					<Text fontSize='sm' color='label_color' textAlign='center'>
						{t('password.instructions')}
						<br />
						{t('email.check')}
					</Text>
					<Button
						variant='text'
						onClick={() => handlePageMode('login')}
						label={t('buttons.return')}
						testId='return-to-login'
						w='150px'
					/>
				</Flex>
			)}
		</LoginLayout>
	)
}

export default Login
