import { z } from 'zod'
import globalSchema from '@Components/Forms/BaseForm/BaseForm.schema'
// Translations
import { i18n } from '@Components/App/I18nProvider'
const t = (key: string) =>
	i18n.t(`forms:mapsZonesForm.mapLayersForm.validation.${key}`)

const pickedFromGlobal = globalSchema.pick({
	name: true,
})

const ACCEPTED_FILE_TYPES = [
	'application/zip',
	'application/x-zip-c mpressed',
	'multipart/x-zip',
]

const mapLayersFormFields = z.object({
	attribution: z.string().min(3, t('attribution')),
	url: z
		.string()
		.refine((value) => value.includes('.'), {
			message: t('url'),
		})
		.refine(
			(value) => {
				if (!value.includes('{q}')) return true
				const valid = ['{q}']
				const matches = value.match(/{[^}]+}/g)
				return valid.sort().toString() === matches?.sort().toString()
			},
			{
				message: t('quadKey'),
			}
		)
		.refine(
			(value) => {
				if (value.includes('{q}')) return true
				const valid = ['{x}', '{y}', '{z}']
				const matches = value.match(/{[^}]+}/g)
				return valid.sort().toString() === matches?.sort().toString()
			},
			{
				message: t('xyz'),
			}
		),

	fallback: z.boolean(),
	min_zoom: z.number().min(1).max(30),
	max_zoom: z.number().min(1).max(30),
})

const mapLayersFileUpload = z.object({
	file: z
		.custom()
		.refine((file: any) => file instanceof File, {
			message: t('file'),
		})
		.refine((file: any) => ACCEPTED_FILE_TYPES.includes(file?.type), {
			message: t('zip'),
		}),
})

const mapLayersFormSchema = mapLayersFormFields.merge(pickedFromGlobal)
const offlineMapLayersFormSchema = mapLayersFileUpload.merge(
	mapLayersFormSchema.omit({ url: true })
)

const defaultValues = {
	name: '',
	attribution: '',
	url: '',
	fallback: false,
	min_zoom: 5,
	max_zoom: 18,
	file: undefined,
}

export { mapLayersFormSchema, offlineMapLayersFormSchema, defaultValues }
