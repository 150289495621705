import { useTheme } from '@chakra-ui/react'
import type { LatLngExpression } from 'leaflet'
import type { Disruptor } from '@Store/types'

import Sector from '@Components/MapShapes/Sector/Sector'

type DisruptorSectorProps = {
	latitude: number
	longitude: number
	center?: LatLngExpression
	bearing: number
	isActive: boolean
} & Pick<Disruptor, 'power_trigger_engaged' | 'reach' | 'cannon_type'>

// Move to constants folder when needed
const DSX_TYPE = 'DSX'
const DSX_ANGLE = 360
const DISRUPTOR_ANGLE = 90

const DisruptorSector = ({
	latitude,
	longitude,
	cannon_type,
	bearing = 0,
	power_trigger_engaged,
	reach = 0,
	isActive = false,
}: DisruptorSectorProps) => {
	const {
		semanticTokens: { colors },
	} = useTheme()

	const fillColor = colors.sectors.disruptorFill
	const strokeColor = isActive
		? colors.sectors.highlight
		: colors.sectors.disruptorStroke
	const strokeWeight = isActive ? 4 : 2

	const isDSX = cannon_type.includes(DSX_TYPE)
	const angle = isDSX ? DSX_ANGLE : DISRUPTOR_ANGLE

	return (
		<Sector
			latitude={latitude}
			longitude={longitude}
			bearing={bearing}
			reach={reach}
			angle={angle}
			color={strokeColor}
			fillColor={fillColor}
			weight={strokeWeight}
			testId='disruptor-sector'
			className={power_trigger_engaged ? 'disruptor-sector-animation' : ''}
		/>
	)
}

export default DisruptorSector
