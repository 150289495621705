import { useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
	useReactTable,
	getCoreRowModel,
	type SortingState,
	type PaginationState,
} from '@tanstack/react-table'
import { Flex, useDisclosure } from '@chakra-ui/react'
import {
	Accordion,
	AccordionIcon,
	AccordionButton,
	AccordionItem,
	AccordionPanel,
} from '@chakra-ui/react'
import {
	useUpdateEventSummaryMutation,
	useGetTrackSummaryQuery,
	analyticsApi,
} from '@Store/analytics/analyticsApi'
import {
	selectSiteExportHasCamera,
	useGetSiteExportQuery,
} from '@Store/sites/sitesApi'

import { MdDownload } from 'react-icons/md'
import { downloadCsv, fetchFile } from '@Utils/download'

import Button from '@UI/Button/Button'
import FetchError from '@UI/FetchError/FetchError'
import Headings from '@UI/Headings/Headings'
import TanStackTable from '@UI/TanStackTable/Table'
import Pagination from '@UI/TanStackTable/Pagination'
import Skeleton from '@UI/Skeleton/Skeleton'

import EventFilter from './EventFilter'
import { useEventFilter } from './EventFilterContext'
import VideoRecordingModal from '@Pages/Analytics/VideoRecordings/VideoRecordingModal'
import { getColumns, SORTABLE_COLUMNS } from './columnDefinitions'

import { getSorting } from '@Utils/tables'
import type { Event } from '@Store/types'
import { useAppDispatch } from '@/store'
import { addToast } from '@Store/ui/uiSlice'

type EventDetailsTableProps = {
	startTime: string
	endTime: string
	siteId: number
	siteTimeZone: string
}

const EventDetailsTable = ({
	startTime,
	endTime,
	siteId,
	siteTimeZone,
}: EventDetailsTableProps) => {
	const { t } = useTranslation('pages', {
		keyPrefix: 'analytics.trackSummary',
	})

	const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 10,
	})
	const [sorting, setSorting] = useState<SortingState>([
		{ id: 'start_time', desc: true },
	])

	const navigate = useNavigate()
	const [videoRecordings, setVideoRecordings] = useState<number[]>()

	const pagination = useMemo(
		() => ({
			pageIndex,
			pageSize,
		}),
		[pageIndex, pageSize]
	)

	const {
		columnFilters,
		setColumnFilters,
		globalFilter,
		setGlobalFilter,
		filterParams,
	} = useEventFilter()

	const [updateEvent] = useUpdateEventSummaryMutation()
	const dispatch = useAppDispatch()

	const handleUpdateFalseAlarm = (eventId: number, value: boolean) => {
		updateEvent({ eventId, false_positive: value })
			.unwrap()
			.then(() => {
				dispatch(
					analyticsApi.util.updateQueryData(
						'getTrackSummary',
						{
							siteId: siteId,
							format: 'table',
							startTime: startTime,
							endTime: endTime,
							page: pageIndex,
							perPage: pageSize,
							...filterParams,
							...getSorting(sorting),
						},
						(draft) => {
							const index = draft.records.findIndex(
								(record) => record.id === eventId
							)

							if (draft?.records[index]) {
								draft.records[index].false_positive = value
							}
						}
					)
				)
			})
			.catch((error) => {
				dispatch(
					addToast({
						type: 'error',
						title: t('status.error'),
						description: t('status.updateError'),
						siteId,
						error: error,
						id: `site-${siteId}-error`,
					})
				)
			})
	}

	const {
		isOpen: isVideoRecordingModalOpen,
		onOpen: onVideoRecordingModalOpen,
		onClose: onVideoRecordingModalClose,
	} = useDisclosure()

	const handleOpenEventDetailsModal = (event: Event) => {
		navigate(`/${siteId}/analytics/track/${event.id}`, {
			state: { event },
		})
	}

	const handleOpenVideoRecordingModal = (recordings: number[]) => {
		setVideoRecordings(recordings)
		onVideoRecordingModalOpen()
	}

	const handleDownloadReport = async () => {
		try {
			const url = `/api/reports/${siteId}/track_summary/csv`
			const data = await fetchFile(url, {
				start_time: startTime,
				end_time: endTime,
				...filterParams,
			})
			downloadCsv(data, `track_summary_${startTime}_to_${endTime}.csv`)
		} catch (error) {
			console.error('TODO: unhandled download report error', error)
		}
	}

	const {
		isSuccess,
		isError,
		isLoading,
		isFetching,
		refetch,
		events,
		totalCount,
	} = useGetTrackSummaryQuery(
		{
			siteId: siteId,
			format: 'table',
			startTime: startTime,
			endTime: endTime,
			page: pageIndex,
			perPage: pageSize,
			...filterParams,
			...getSorting(sorting),
		},
		{
			selectFromResult: ({
				isSuccess,
				isError,
				isLoading,
				isFetching,
				data,
			}) => ({
				isSuccess,
				isError,
				isLoading,
				isFetching,
				events: data?.records,
				totalCount: data?.meta.total ?? 0,
			}),
		}
	)

	const { hasCamera, isError: hasCameraError } = useGetSiteExportQuery(siteId, {
		selectFromResult: ({ data, isError }) => ({
			hasCamera: selectSiteExportHasCamera(data),
			isError,
		}),
	})

	// Note/TODO: How do we want to handle somewhat insignificant errors?
	if (hasCameraError) {
		console.error('Failed to fetch whether site has cameras')
	}

	const pageCount = totalCount ? Math.ceil(totalCount / pageSize) : 0

	const columns = getColumns(
		siteTimeZone,
		hasCamera,
		handleUpdateFalseAlarm,
		handleOpenEventDetailsModal,
		handleOpenVideoRecordingModal
	)

	const table = useReactTable({
		columns,
		data: events ?? [],
		getCoreRowModel: getCoreRowModel(),
		pageCount: pageCount ?? -1,
		state: {
			pagination,
			globalFilter,
			columnFilters,
			sorting,
		},
		onPaginationChange: setPagination,
		onColumnFiltersChange: setColumnFilters,
		onGlobalFilterChange: setGlobalFilter,
		onSortingChange: setSorting,
		manualPagination: true,
		manualFiltering: true,
		manualSorting: true,
		enableSortingRemoval: false,
	})

	return (
		<Accordion defaultIndex={[0]} allowMultiple>
			<AccordionItem>
				<AccordionButton
					bgColor='gray.800'
					_hover={{ backgroundColor: 'gray.800' }}
				>
					<Flex justifyContent='space-between' w='100%' alignItems='center'>
						<Headings.ChartHeading title={t('detectionEventDetails')} />
						<AccordionIcon />
					</Flex>
				</AccordionButton>
				<AccordionPanel>
					<Flex justifyContent='flex-end' marginBlock={4}>
						<Flex alignItems='center'>
							<Headings.FieldHeading title={t('report')} />
							<Button
								label={t('download')}
								testId='download'
								size='sm'
								leftIcon={<MdDownload />}
								onClick={handleDownloadReport}
								isDisabled={totalCount === 0}
							/>
						</Flex>
					</Flex>
					<Skeleton isLoaded={!isLoading}>
						<EventFilter table={table} siteId={siteId} />
					</Skeleton>
					<Skeleton isLoaded={!isLoading && !isFetching}>
						{isSuccess && (
							<>
								<TanStackTable
									table={table}
									sortableColumns={SORTABLE_COLUMNS}
								/>
								<Pagination table={table} totalCount={totalCount ?? 0} />
								{videoRecordings && (
									<VideoRecordingModal
										isOpen={isVideoRecordingModalOpen}
										onClose={onVideoRecordingModalClose}
										recordingId={videoRecordings[0]}
									/>
								)}
							</>
						)}
						{isError && (
							<FetchError
								refetch={refetch}
								entity={t('entities.eventDetailsTable')}
							/>
						)}
					</Skeleton>
				</AccordionPanel>
			</AccordionItem>
		</Accordion>
	)
}

export default EventDetailsTable
