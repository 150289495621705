import { useTransition } from 'react'
import { useParams } from 'react-router-dom'

// Redux
import { useUpdateNotificationRecipientMutation } from '@Store/notifications/notificationRecipientsApi'
import type { NotificationRecipient } from '@Store/types'

// Chakra
import { Switch } from '@chakra-ui/react'
type RowActionsProps = {
	recipient: NotificationRecipient
}

const AlertRecipientsToggle = ({ recipient }: RowActionsProps) => {
	const { siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)

	const [updateRecipient] = useUpdateNotificationRecipientMutation()
	const [isPending, startTransition] = useTransition()

	const handleToggle = async () => {
		try {
			startTransition(() => {
				updateRecipient({
					siteId,
					id: recipient.id,
					enabled: !recipient.enabled,
				}).unwrap()
			})
		} catch (e) {
			console.error(e)
		}
	}

	return (
		<Switch
			isDisabled={isPending}
			isChecked={recipient.enabled}
			onChange={handleToggle}
		/>
	)
}

export default AlertRecipientsToggle
