import { COLORS } from '@Constants/colors'
import type { SiteInstallationType } from '@Store/types'

type SentryMarkerIconProps = {
	fill: string
	sentryType: SiteInstallationType
	isSelected?: boolean
}

const SentryMarkerIcon = ({
	fill,
	sentryType,
	isSelected,
}: SentryMarkerIconProps) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 70 70'>
			{isSelected && (
				<g id='Selected' data-testid='selected'>
					<rect
						x='1.5'
						y='1.5'
						width='67'
						height='67'
						style={{ opacity: 0.2 }}
					/>
					<polyline
						fill='none'
						stroke={COLORS.ORANGE}
						strokeWidth='3px'
						points='1.5 19.25 1.5 1.5 19.25 1.5'
					/>
					<polyline
						fill='none'
						stroke={COLORS.ORANGE}
						strokeWidth='3px'
						points='50.75 1.5 68.5 1.5 68.5 19.25'
					/>
					<polyline
						fill='none'
						stroke={COLORS.ORANGE}
						strokeWidth='3px'
						points='68.5 50.75 68.5 68.5 50.75 68.5'
					/>
					<polyline
						fill='none'
						stroke={COLORS.ORANGE}
						strokeWidth='3px'
						points='19.25 68.5 1.5 68.5 1.5 50.75'
					/>
				</g>
			)}
			<g id='Icon'>
				{sentryType === 'fixed' && (
					<path
						data-testid='fixed-sentry-marker'
						fill={COLORS.ORANGE}
						d='M34.35,28c-1.82,1-3.48,1.43-3.72,1s1.06-1.58,2.88-2.56,3.48-1.43,3.71-1S36.17,27,34.35,28m7.26,1.23c.36.66-1.8,2.51-4.82,4.14s-5.75,2.41-6.1,1.76,1.8-2.51,4.82-4.14,5.75-2.41,6.1-1.76M40,25.53A14.33,14.33,0,0,1,44,24V20.49l-9-1.65-9,1.65v12.9a18.16,18.16,0,0,1,3-2c2.42-1.31,4.61-1.95,4.89-1.43S32.4,32,30,33.3a16.09,16.09,0,0,1-3.69,1.51,6.78,6.78,0,0,0,1,1.78,16.64,16.64,0,0,0,3.28,3A32.65,32.65,0,0,0,35,42.25a33.29,33.29,0,0,0,4.39-2.63,16.64,16.64,0,0,0,3.28-3A5.34,5.34,0,0,0,44,33.4V25.46a16.82,16.82,0,0,1-3,2c-2.42,1.31-4.62,1.94-4.9,1.42s1.45-2,3.88-3.31'
					/>
				)}
				{sentryType === 'dismounted' && (
					<>
						<circle fill={COLORS.ORANGE} cx='35' cy='23.07' r='4.67' />
						<path
							data-testid='person-sentry-marker'
							fill={COLORS.ORANGE}
							d='M35.67,42.86l5-12a.72.72,0,0,0-.67-1H30a.72.72,0,0,0-.67,1l5,12A.73.73,0,0,0,35.67,42.86Z'
						/>
					</>
				)}
				{sentryType === 'on_the_move' && (
					<>
						<path
							data-testid='vehicle-sentry-marker'
							fill={COLORS.ORANGE}
							d='M35,18.26A12.28,12.28,0,1,1,22.72,30.54,12.28,12.28,0,0,1,35,18.26ZM26.28,30.48v.06l-1.68.78a10.45,10.45,0,0,0,8.27,9.44v0l1.29-1.47h.06l-.58-5.78A1.63,1.63,0,0,0,32.27,32Zm17.44-.15-6,1.62a1.71,1.71,0,0,0-1.25,1.54c-.1,1.2-.33,5.24-.36,5.78h.06l1.2,1.45a10.44,10.44,0,0,0,8.1-9.56l-1.69-.78ZM35,28.39a1.88,1.88,0,1,1-1.88,1.88A1.88,1.88,0,0,1,35,28.39Zm8.51.09v-.07l1.45-1a10.44,10.44,0,0,0-19.29-1.53A10.87,10.87,0,0,0,25,27.51l1.49,1.07v.06l4.8.39a1.79,1.79,0,0,0,1.66-.78A2.53,2.53,0,0,1,35,27.19a2.51,2.51,0,0,1,1.85.81,1.37,1.37,0,0,1,.22.2,2.22,2.22,0,0,0,1.76.83Z'
						/>
						<path
							fill='none'
							d='M35,18.26A12.28,12.28,0,1,1,22.72,30.54,12.28,12.28,0,0,1,35,18.26Zm0,.13A12.15,12.15,0,1,1,22.85,30.54,12.15,12.15,0,0,1,35,18.39ZM26.15,30.45a.22.22,0,0,1,.05-.08.15.15,0,0,1,.11,0l6,1.48a1.75,1.75,0,0,1,1.47,1.66l.58,5.76a.11.11,0,0,1,0,.1.12.12,0,0,1-.1,0h0L33,40.82h0a.13.13,0,0,1-.14.07,10.6,10.6,0,0,1-8.37-9.56.13.13,0,0,1,.07-.13Zm9.91,9h0a.18.18,0,0,1-.1,0,.17.17,0,0,1,0-.1c0-.54.26-4.58.36-5.78a1.84,1.84,0,0,1,1.35-1.66h0l6-1.63a.12.12,0,0,1,.1,0,.12.12,0,0,1,.05.07l1.64.76.07.12a10.59,10.59,0,0,1-8.2,9.68.13.13,0,0,1-.13-.05Zm-9.78-8.92v.06l-1.68.78a10.45,10.45,0,0,0,8.27,9.44v0l1.29-1.47h.06l-.58-5.78A1.63,1.63,0,0,0,32.27,32Zm17.44-.15-6,1.62a1.71,1.71,0,0,0-1.25,1.54c-.1,1.2-.33,5.24-.36,5.78h.06l1.2,1.45a10.44,10.44,0,0,0,8.1-9.56l-1.69-.78ZM35,28.26a2,2,0,1,0,2,2A2,2,0,0,0,35,28.26Zm0,.13a1.88,1.88,0,1,1-1.88,1.88A1.88,1.88,0,0,1,35,28.39Zm-8.66.25-1.42-1a.11.11,0,0,1-.05-.14,10.81,10.81,0,0,1,.67-1.69,10.58,10.58,0,0,1,19.53,1.55.13.13,0,0,1,0,.14l-1.39,1a.12.12,0,0,1-.11.14l-4.69.55A2.32,2.32,0,0,1,37,28.29a1.19,1.19,0,0,0-.2-.19h0a2.4,2.4,0,0,0-3.72.24h0a1.9,1.9,0,0,1-1.77.83l-4.79-.39a.12.12,0,0,1-.09-.05A.11.11,0,0,1,26.35,28.64Zm17.17-.16v-.07l1.45-1a10.44,10.44,0,0,0-19.29-1.53A10.87,10.87,0,0,0,25,27.51l1.49,1.07v.06l4.8.39a1.79,1.79,0,0,0,1.66-.78A2.53,2.53,0,0,1,35,27.19a2.51,2.51,0,0,1,1.85.81,1.37,1.37,0,0,1,.22.2,2.22,2.22,0,0,0,1.76.83Z'
						/>
					</>
				)}
				<path
					fill={fill}
					d='M35,6C15.79,5.9,4.87,25.59,12,39.1,17.55,49.69,28.56,59.54,33,63.29a3.05,3.05,0,0,0,3.92,0C41.44,59.54,52.45,49.69,58,39.1,65.13,25.59,54.21,5.9,35,6Zm0,42.23A17.73,17.73,0,1,1,52.73,30.5,17.73,17.73,0,0,1,35,48.23Z'
				/>
			</g>
		</svg>
	)
}

export default SentryMarkerIcon
