// React
import { useTranslation } from 'react-i18next'
// Chakra
import {
	Box,
	Flex,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react'
// Components
import EditPalette from '@Components/Site/Panels/SiteInstallations/EditPalette'
import MapLayersEditModal from '../Modals/EditMapLayerModal'
import MapLayersDeleteModal from '../Modals/DeleteMapLayerModal'
// Store
import type { MapLayer } from '@Store/types'

const FallbackCircle = () => (
	<Box
		borderRadius='full'
		bgColor='primary'
		w={2}
		h={2}
		position='absolute'
		left={-3}
		top={1.5}
	/>
)

const MapLayerTableCollapsed = ({ mapLayers }: { mapLayers?: MapLayer[] }) => {
	// Translations
	const { t } = useTranslation('forms', {
		keyPrefix: 'mapsZonesForm.mapLayersForm',
	})

	if (!mapLayers?.length) return <>{t('noLayers')}</>

	return (
		<TableContainer>
			<Table variant='simple' maxW='490px' mt={4}>
				<Thead>
					<Tr>
						<Th>{t('name')}</Th>
						<Th align='center'>{t('attribution')}</Th>
						<Th>{t('type')}</Th>
						<Th>{t('layerZoom')}</Th>
						<Th />
					</Tr>
				</Thead>
				<Tbody>
					{mapLayers?.map((layer) => {
						const {
							name,
							attribution,
							min_zoom,
							max_zoom,
							id,
							fallback,
							offline,
						} = layer
						return (
							<Tr key={id}>
								<Td width='86px'>
									<Flex position='relative'>
										{fallback && <FallbackCircle />}
										<Text
											maxW='84px'
											whiteSpace='nowrap'
											overflow='hidden'
											textOverflow='ellipsis'
										>
											{name}
										</Text>
									</Flex>
								</Td>
								<Td>
									<Text
										maxW='80px'
										whiteSpace='nowrap'
										overflow='hidden'
										textOverflow='ellipsis'
										textAlign='left'
									>
										{attribution}
									</Text>
								</Td>
								<Td>
									<Text
										maxW='80px'
										whiteSpace='nowrap'
										overflow='hidden'
										textOverflow='ellipsis'
										textAlign='center'
									>
										{offline ? t('offline') : t('online')}
									</Text>
								</Td>
								<Td isNumeric>
									<Text align='center'>
										{min_zoom}/{max_zoom}
									</Text>
								</Td>
								<Td>
									<EditPalette testId='map-layer-menu'>
										<MapLayersEditModal layer={layer} variant='button' />
										<MapLayersDeleteModal layer={layer} variant='button' />
									</EditPalette>
								</Td>
							</Tr>
						)
					})}
				</Tbody>
			</Table>
		</TableContainer>
	)
}

export default MapLayerTableCollapsed
