type MaskProps = {
	min: number
	max: number
	value: [number, number]
}

const maskFillColor = 'grey'
const maskLineColor = 'var(--c2-colors-primary)'

// tests, one day
// convert -3000 to 0
// convert -10 to 2900
// convert 0 to 3000
// convert 500 to 3500

const Mask = ({ min, max, value }: MaskProps) => {
	// establish the range
	// e.g -3000 to 3000 is a 6000 range
	const range = max - min

	// rejig the min/max so they be relative to range
	const minVal = range / 2 + value[0]
	const maxVal = range / 2 + value[1]
	// console.log({ range }, { min, max }, { minVal, maxVal })

	// turn it into useful percentages for the svg
	const topOffsetPercent = `${((range - maxVal) / 6000) * 100}%`
	const heightPercent = `${((maxVal - minVal) / 6000) * 100}%`
	const upperLineOffsetPercent = topOffsetPercent
	const lowerLineOffsetPercent = `${((range - minVal) / 6000) * 100}%`

	// console.log({ topOffsetPercent, heightPercent })

	return (
		<g id='agl-mask'>
			<rect
				x={0}
				y={topOffsetPercent}
				width='100%'
				height={heightPercent}
				fill={maskFillColor}
			/>
			<line
				x1={0}
				x2='100%'
				y1={upperLineOffsetPercent}
				y2={upperLineOffsetPercent}
				stroke={maskLineColor}
				strokeWidth={2}
			/>
			<line
				x1={0}
				x2='100%'
				y1={lowerLineOffsetPercent}
				y2={lowerLineOffsetPercent}
				stroke={maskLineColor}
				strokeWidth={2}
			/>
		</g>
	)
}

export default Mask
