import { ResponsivePie } from '@nivo/pie'

import { useTheme } from '@chakra-ui/react'
import useChartTheme from './useChartTheme'

type PieChartProps = {
	data: { id: string; label: string; value: number }[]
}

const PieChart = ({ data }: PieChartProps) => {
	const {
		semanticTokens: { colors },
	} = useTheme()

	const theme = useChartTheme()

	return (
		<ResponsivePie
			data={data}
			theme={theme}
			margin={{ top: 10, right: 60, bottom: 80, left: 60 }}
			innerRadius={0.75}
			padAngle={0.7}
			cornerRadius={3}
			activeOuterRadiusOffset={8}
			borderWidth={1}
			colors={colors.charts.pie}
			enableArcLabels={false}
			enableArcLinkLabels={false}
			legends={[
				{
					anchor: 'bottom',
					direction: 'column',
					translateX: 20,
					translateY: 55,
					itemsSpacing: 5,
					itemWidth: 100,
					itemHeight: 18,
					itemTextColor: colors.label_color,
					symbolSize: 7,
					symbolShape: 'circle',
				},
			]}
		/>
	)
}

export default PieChart
