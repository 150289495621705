import { useParams } from 'react-router-dom'
import Headings from '@UI/Headings/Headings'

import { useAppDispatch } from '@Store/index'
import { useCalibrateCameraMutation } from '@Store/cameras/camerasApi'
import { setCameraCalibrationStep } from '@Store/ui/uiSlice'

import type { CameraDetectionMap } from '../../types'
import { useInterval } from '@/hooks/useInterval'
import { useTranslation } from 'react-i18next'

type CameraReadingProps = {
	cameraDetections: CameraDetectionMap
}

const CAMERA_MIN_DETECTION_COUNT = 15
const CAMERA_INDEX_FOR_AVG = CAMERA_MIN_DETECTION_COUNT - 5
const CAMERA_POSITION_TOLERANCE = 10

const CameraReading = ({ cameraDetections }: CameraReadingProps) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'siteInstallations.cameraCalibration',
	})
	const { sensorId: sensorIdParam, siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)
	const sensorId = Number(sensorIdParam)

	const dispatch = useAppDispatch()
	const [calibrateCamera] = useCalibrateCameraMutation()

	useInterval(() => {
		const isDroneCentered = Object.values(cameraDetections).some(
			(detection) => {
				const { count, positionHistory } = detection
				// Minimum count (not a short-lived detection)
				if (count < CAMERA_MIN_DETECTION_COUNT) return false

				// Calculations for whether a drone is centered, taken from Vue
				return (
					Math.abs(positionHistory[0][0] - 50) < CAMERA_POSITION_TOLERANCE &&
					Math.abs(positionHistory[CAMERA_INDEX_FOR_AVG][0] - 50) <
						CAMERA_POSITION_TOLERANCE &&
					Math.abs(positionHistory[0][1] - 50) < CAMERA_POSITION_TOLERANCE &&
					Math.abs(positionHistory[CAMERA_INDEX_FOR_AVG][1] - 50) <
						CAMERA_POSITION_TOLERANCE
				)
			}
		)

		if (isDroneCentered) {
			calibrateCamera({ cameraId: sensorId, siteId, action: 'start-2' })
			dispatch(setCameraCalibrationStep(2))
		}
	}, 2000)

	return <Headings.FieldHeading title={t('headers.cameraReading')} />
}

export default CameraReading
