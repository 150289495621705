// Base Redux Toolkit Query API
import { baseApi } from '../baseApi'

export const mapLayersApi = baseApi
	.enhanceEndpoints({
		addTagTypes: ['ThirdParty'],
	})
	.injectEndpoints({
		endpoints: (builder) => ({
			getLocation: builder.query<any, void>({
				query: () => ({
					url: 'https://ipapi.co/json',
				}),
				providesTags: ['ThirdParty'],
			}),
		}),
	})
export const { useGetLocationQuery } = mapLayersApi
