import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Heading } from '@chakra-ui/react'
import { MdOpenInNew } from 'react-icons/md'

import Button from '@UI/Button/Button'
import StatusIcon from '@UI/StatusIcon/StatusIcon'
import { Subheading } from '@UI/Headings/Headings'

import { useGetSiteLiveQuery, selectSmarthubs } from '@Store/sites/sitesWsApi'

import { sensorStatusColorMap, type SensorStatusColor } from '@Store/types'

import { openInNewTab } from '@Utils/navigation'
import { DEFAULT_SMARTHUB_IP } from '@Constants/site'
import { useTestIp } from '@Hooks/useTestIp'

const Smarthubs = ({ siteId }: { siteId: number }) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'siteInstallations.smarthubs',
	})

	const { status, testIp } = useTestIp({
		defaultIp: false,
		smarthubIp: false,
	})

	const { smarthubs } = useGetSiteLiveQuery(siteId, {
		selectFromResult: ({ data }) => ({
			smarthubs: selectSmarthubs(data),
		}),
	})

	const hasSmarthubs = smarthubs && smarthubs.length > 0
	// Note: Handling one smarthub for now until we have the backend migrations
	const smarthubIp = smarthubs?.[0]?.ip

	useEffect(() => {
		if (smarthubs.length > 0) {
			testIp(DEFAULT_SMARTHUB_IP, 'defaultp')
		}
	}, [testIp, smarthubs.length])

	useEffect(() => {
		if (smarthubIp) {
			testIp(smarthubIp, 'smarthubIp')
		}
	}, [smarthubIp, testIp])

	const handleOpenSmarthub = (smarthubIp: string) => {
		if (status.defaultIp) {
			openInNewTab(`http://${DEFAULT_SMARTHUB_IP}`)
		} else if (status.smarthubIp) {
			openInNewTab(`http://${smarthubIp}`)
		}
	}

	const isDisabled = !status.defaultIp && !status.smarthubIp

	return (
		<>
			{hasSmarthubs &&
				smarthubs.map(({ serial, status, ip }) => (
					<Flex
						h='4.25rem'
						bgColor='body_bg'
						key={serial}
						justifyContent='space-between'
						alignItems='center'
						px={3}
					>
						<Flex gap={2}>
							<StatusIcon
								color={sensorStatusColorMap[status as SensorStatusColor]}
								testId={`${serial}-smarthub-status-color`}
							/>
							<Flex direction='column' gap={1}>
								<Heading size='sm'>{serial}</Heading>
								<Subheading>{t('smarthub')}</Subheading>
							</Flex>
						</Flex>
						<Button
							label={t('open')}
							leftIcon={<MdOpenInNew size='16px' />}
							testId={`${serial}-open-smarthub`}
							color='button_text'
							_hover={{ bg: 'none' }}
							size='xs'
							isDisabled={isDisabled}
							tooltip={isDisabled ? t('unableToRedirect') : undefined}
							onClick={() => handleOpenSmarthub(ip)}
						/>
					</Flex>
				))}
		</>
	)
}

export default Smarthubs
