import { createContext } from 'react'
import type { MutableRefObject, Dispatch, SetStateAction } from 'react'

export type ContextValue = {
	isReady: boolean
	isStreaming: boolean
	videoRef: MutableRefObject<HTMLVideoElement | null>
	streamId: number | null
	viewerCount: number | null
	setStreamId: Dispatch<SetStateAction<number | null>>
}

const JanusContext = createContext<ContextValue | undefined>(undefined)

export default JanusContext
