import { Flex, Grid, GridItem, useToken } from '@chakra-ui/react'
import MapLegendSection from '@Components/MapControls/MapLegend/MapLegendSection'
import { useTranslation } from 'react-i18next'

// Icons
import SiteMarkerIcon from '@Assets/icons/markers/site-center.svg'
import SentryMarkerIcon from '@Components/Markers/SentryMarker/SentryMarkerIcon'

const MapLegendSectionMap = () => {
	const { t } = useTranslation('map', { keyPrefix: 'legend.map' })

	const [
		connectionColor,
		noStatusColor,
		noConnectionColor,
		lostConnectionColor,
	] = useToken('colors', [
		'status.connection',
		'status.no_status',
		'status.no_connection',
		'status.lost_connection',
	])

	return (
		<MapLegendSection title={t('title')}>
			<Grid
				templateColumns='1fr 65px'
				justifyContent='center'
				alignItems='center'
				rowGap={4}
			>
				<GridItem>{t('siteCentre')}</GridItem>
				<Flex
					alignItems='center'
					justifyContent='center'
					sx={{ '& > svg': { width: '20px' } }}
				>
					<SiteMarkerIcon />
				</Flex>
				<GridItem>{t('installationOperational')}</GridItem>
				<Flex
					alignItems='center'
					justifyContent='center'
					sx={{ '& > svg': { width: '42px' } }}
				>
					<SentryMarkerIcon
						fill={connectionColor}
						sentryType='fixed'
						isSelected={false}
					/>
				</Flex>
				<GridItem>{t('installationDisabled')}</GridItem>
				<Flex
					alignItems='center'
					justifyContent='center'
					sx={{ '& > svg': { width: '42px' } }}
				>
					<SentryMarkerIcon
						fill={noStatusColor}
						sentryType='fixed'
						isSelected={false}
					/>
				</Flex>
				<GridItem>{t('installationWarning')}</GridItem>
				<Flex
					alignItems='center'
					justifyContent='center'
					sx={{ '& > svg': { width: '42px' } }}
				>
					<SentryMarkerIcon
						fill={lostConnectionColor}
						sentryType='fixed'
						isSelected={false}
					/>
				</Flex>
				<GridItem>{t('installationError')}</GridItem>
				<Flex
					alignItems='center'
					justifyContent='center'
					sx={{ '& > svg': { width: '42px' } }}
				>
					<SentryMarkerIcon
						fill={noConnectionColor}
						sentryType='fixed'
						isSelected={false}
					/>
				</Flex>
			</Grid>
		</MapLegendSection>
	)
}

export default MapLegendSectionMap
