import { useNavigate } from 'react-router-dom'
import { Button, Flex } from '@chakra-ui/react'
import Headings from '@UI/Headings/Headings'
import { MdArrowBackIosNew } from 'react-icons/md'
import { useTranslation } from 'react-i18next'

const FormHeader = ({
	title,
	backRoute,
}: {
	title: string
	backRoute?: string
}) => {
	const navigate = useNavigate()

	const { t } = useTranslation('forms', { keyPrefix: 'global' })
	const handleBackClick = () => {
		if (backRoute) {
			navigate(backRoute)
		} else navigate(-1)
	}

	return (
		<Flex justifyContent='space-between' alignItems='center'>
			<Headings.Section title={title} testId='headings' />
			<Button
				leftIcon={<MdArrowBackIosNew />}
				variant='ghost'
				onClick={handleBackClick}
				data-testid='back'
			>
				{t('buttons.back')}
			</Button>
		</Flex>
	)
}

export default FormHeader
