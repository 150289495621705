import { z } from 'zod'
import globalSchema from '@Components/Forms/BaseForm/BaseForm.schema'

const pickedFromGlobal = globalSchema.pick({
	name: true,
})

const zoneFormFields = z.object({
	zone_type: z.enum(['alert', 'disrupt', 'label', 'origin']),
	note: z.string(),
	coordinates: z.array(z.array(z.number())),
})

export const zoneFormSchema = zoneFormFields.merge(pickedFromGlobal)

export type ZoneSchema = z.infer<typeof zoneFormSchema>

export const ADD_ZONE_DEFAULT_VALUES: ZoneSchema = {
	name: '',
	zone_type: 'alert',
	note: '',
	coordinates: [],
}
