// React
import { useNavigate, useParams } from 'react-router-dom'
// Chakra
import { IconButton } from '@chakra-ui/react'
import { HiMiniEye, HiMiniEyeSlash } from 'react-icons/hi2'
// Components
import Tooltip from '@UI/Tooltip/Tooltip'
import { useTranslation } from 'react-i18next'
// Types
type DeviceContentWrapperProps = {
	deviceId: number
}

const ActiveSensorButton = ({ deviceId }: DeviceContentWrapperProps) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'siteInstallations.activeSensor',
	})
	const {
		siteId: siteIdParam,
		installationId: installationIdParam,
		sensorId: sensorIdParam,
		sensorType,
	} = useParams()
	const navigate = useNavigate()

	const siteId = Number(siteIdParam)
	const installationId = Number(installationIdParam)
	const sensorId = Number(sensorIdParam)

	const isActive = sensorId === deviceId
	const handleToggleSelectedDevice = () => {
		const pathWithId = `/${siteId}/installations/${installationId}/${sensorType}/${deviceId}`
		const toggledPathWithId = `/${siteId}/installations/${installationId}/${sensorType}/${
			!sensorId ? deviceId : ''
		}`

		navigate(isActive ? toggledPathWithId : pathWithId)
	}

	return (
		<Tooltip label={isActive ? t('deselect') : t('select')}>
			<IconButton
				icon={isActive ? <HiMiniEyeSlash /> : <HiMiniEye />}
				variant='icon'
				aria-label='open active sensor'
				isDisabled={false}
				onClick={handleToggleSelectedDevice}
			/>
		</Tooltip>
	)
}

export default ActiveSensorButton
