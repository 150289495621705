// React
import { useTranslation } from 'react-i18next'
import { nanoid } from '@reduxjs/toolkit'
// Chakra
import { Box, FormControl, Flex } from '@chakra-ui/react'
// Components
import useMinMaxFrequencyRange from '@Forms/RfFilterForm/_hooks/useMinMaxFrequencyRange'
import RfFiltersOverviewUnitsMarkers from './RfFiltersOverviewUnitsMarkers'
import RfFiltersOverviewSlider from './RfFiltersOverviewSlider'
// Helpers
import { convertHzToGHz } from '@Utils/mathUtils'
// Types
import type { FrequencyValuesType } from './RfFiltersOverviewFrequencies'
import {
	selectSelectedRfFiltersIds,
	updateSelectedSensorFiltersIds,
} from '@Store/ui/uiSlice'
import { useAppDispatch, useAppSelector } from '@/store'
type RfFiltersOverviewRangesProps = {
	frequencyBand: string
	ranges: FrequencyValuesType[]
}

const RfFiltersOverviewFrequenciesRanges = ({
	frequencyBand,
	ranges,
	...rest
}: RfFiltersOverviewRangesProps) => {
	// Translations
	const { t } = useTranslation('panels', { keyPrefix: 'rfFilters' })
	const { minFrequencyRange: min, maxFrequencyRange: max } =
		useMinMaxFrequencyRange(frequencyBand)

	const formatFrequency = (value: number) => Number(value.toFixed(3))

	const formatDefaultValue = (
		frequency: number,
		frequency_tolerance: number
	) => {
		const formattedFrequency = formatFrequency(convertHzToGHz(frequency))
		const formattedFrequencyTolerance = formatFrequency(
			convertHzToGHz(frequency_tolerance)
		)
		return [
			formattedFrequency - formattedFrequencyTolerance,
			formattedFrequency + formattedFrequencyTolerance * 2,
		]
	}

	const selectedFilters = useAppSelector(selectSelectedRfFiltersIds)
	const checkIsFilterSelected = (filterId: number) =>
		selectedFilters.some((id) => id === filterId)

	// Select/Deselect filters
	const dispatch = useAppDispatch()
	const handleClick = (id: number) =>
		dispatch(updateSelectedSensorFiltersIds(id))
	const populateTooltip = (isActive: boolean, filterName: string) => {
		return isActive
			? `${t('tooltips.unselect')} ${filterName}`
			: `${t('tooltips.select')} ${filterName}`
	}

	return (
		<FormControl>
			<Flex
				direction='column'
				alignItems='center'
				position='relative'
				pointerEvents='none'
			>
				<Box w='calc(100% - 40px)'>
					<Box
						width='100%'
						position='relative'
						h='2px'
						bgColor='whiteAlpha.300'
						top='-6px'
						left='0'
					/>
					<Box width='100%' position='relative'>
						{ranges?.map((range) => (
							<RfFiltersOverviewSlider
								key={nanoid()}
								min={min}
								max={max}
								range={range}
								isActive={checkIsFilterSelected(range?.id)}
								onClick={() => handleClick(range?.id)}
								tooltip={populateTooltip(
									checkIsFilterSelected(range?.id),
									range?.name
								)}
								defaultValue={formatDefaultValue(
									range.frequency,
									range.frequency_tolerance
								)}
								{...rest}
							/>
						))}
					</Box>
				</Box>
				<RfFiltersOverviewUnitsMarkers min={min} max={max} />
			</Flex>
		</FormControl>
	)
}

export default RfFiltersOverviewFrequenciesRanges
