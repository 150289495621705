import { useTranslation } from 'react-i18next'

// Form
import { useForm, FormProvider } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import type { TypeOf } from 'zod'

import {
	useGetRadarAglMaskQuery,
	useCreateRadarAglMaskMutation,
	useUpdateRadarAglMaskMutation,
} from '@Store/radarAglMasks/radarAglMasksApi'

import {
	radarAglMaskFormSchema,
	radarAglMaskFormDefaultValues,
	AglMaskRange,
	type DefaultValues,
} from './RadarAglMaskForm.schema'

import FormWrapper from '@Components/FormElements/FormWrapper/FormWrapper'
import AglMaskPreview from '@Components/Site/Panels/SiteInstallations/RadarMasks/AglMaskPreview/AglMaskPreview'
import AglMaskLegend from '@Components/Site/Panels/SiteInstallations/RadarMasks/AglMaskPreview/AglMaskLegend'
import AglRangeSlider from './AglRangeSlider/AglRangeSlider'

import { Box, Flex } from '@chakra-ui/react'
import { FormButtons } from '@Components/FormElements/FormButtons/FormButtons'

import type { RadarAglMask } from '@Store/types'

type RadarAglMaskFormProps = {
	siteId: number
	sensorId: number
	maskId?: number
	onClose: () => void
}

const RadarAglMaskForm = ({
	siteId,
	sensorId,
	maskId,
	onClose,
}: RadarAglMaskFormProps) => {
	const {
		isLoading,
		isError,
		isSuccess,
		refetch,
		data: radarAglMask,
	} = useGetRadarAglMaskQuery(
		{ siteId, sensorId },
		{ refetchOnMountOrArgChange: true, skip: !maskId }
	)
	const { t } = useTranslation('forms', { keyPrefix: 'radarAglMaskForm' })
	const defaultValues = radarAglMask
		? radarAglMask
		: radarAglMaskFormDefaultValues

	return (
		<FormWrapper
			entity={t('entity')}
			isEditForm={!!maskId}
			isLoading={isLoading}
			isError={isError}
			isSuccess={isSuccess}
			refetch={refetch}
		>
			<Form
				defaultValues={defaultValues}
				siteId={siteId}
				sensorId={sensorId}
				maskId={maskId}
				onClose={onClose}
			/>
		</FormWrapper>
	)
}

const Form = ({
	defaultValues,
	siteId,
	sensorId,
	maskId,
	onClose,
}: {
	defaultValues: RadarAglMask | DefaultValues
	siteId: number
	sensorId: number
	maskId?: number
	onClose: () => void
}) => {
	type Schema = TypeOf<typeof radarAglMaskFormSchema>

	const methods = useForm<Schema>({
		resolver: zodResolver(radarAglMaskFormSchema),
		// defaultValues,
	})
	const {
		// register,
		formState: { isSubmitting, isDirty },
		handleSubmit,
		setValue,
		watch,
	} = methods
	const [createAglMask] = useCreateRadarAglMaskMutation()
	const [updateAglMask] = useUpdateRadarAglMaskMutation()

	const handleSave = async (payload: any) => {
		const { min, max } = payload

		try {
			if (sensorId && maskId) {
				await updateAglMask({
					siteId,
					sensorId,
					aglMaskId: maskId,
					min,
					max,
				}).unwrap()
			} else {
				await createAglMask({
					siteId,
					sensorId,
					min,
					max,
					enabled: true,
				}).unwrap()
			}
			onClose()
		} catch (errors: any) {
			console.error('Error saving Radar AGL mask', errors)
		}
	}

	const handleCancel = () => {
		onClose()
	}

	const [min, max] = watch(['min', 'max'])

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(handleSave)}>
				<AglMaskLegend />
				<Flex direction='row'>
					<Box width='120px'>
						<AglRangeSlider
							min={AglMaskRange.min}
							max={AglMaskRange.max}
							defaultValue={[min ?? defaultValues.min, defaultValues.max]}
							onChange={(value) => {
								setValue('min', value[0], {
									shouldDirty: true,
									shouldTouch: true,
								})
								setValue('max', value[1], {
									shouldDirty: true,
									shouldTouch: true,
								})
							}}
						/>
					</Box>
					<Box
						width='calc(100% - 120px)'
						paddingBlock='4.25rem'
						paddingInlineEnd={4}
					>
						<AglMaskPreview
							min={AglMaskRange.min}
							max={AglMaskRange.max}
							value={[min ?? defaultValues.min, max ?? defaultValues.max]}
						/>
					</Box>
				</Flex>

				<Box marginBlockEnd={4} marginInlineStart={4}>
					<FormButtons
						isSubmitting={isSubmitting}
						isDirty={isDirty}
						handleCancel={handleCancel}
					/>
				</Box>
			</form>
		</FormProvider>
	)
}

export default RadarAglMaskForm
