// React
import { useEffect, useState, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'

// Form
import { useForm, FormProvider } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import type { TypeOf } from 'zod'
import Field from '@Components/FormElements'
import Accordion from '@Components/FormElements/Accordion/Accordion'

// Chakra and UI
import { FormControl } from '@chakra-ui/react'
// Components
import UiSelect from '@UI/Select/Select'
import Cots from '@Forms/CotForm/Cots'
// Schema
import siteFormSchema, {
	ADD_SITE_DEFAULT_VALUES,
	type SiteDefaultValues,
} from './SiteForm.schema'
import type { Site } from '@/store/types'

// State
import {
	useGetSiteQuery,
	useUpdateSiteMutation,
	useGetSiteInstallationsQuery,
	selectSiteInstallationLocatorOptions,
	useCreateSiteMutation,
	useDeleteSiteMutation,
} from '@Store/sites/sitesApi'
import {
	useGetTimezonesQuery,
	selectTimezoneOptions,
} from '@Store/system/systemApi'
import {
	useGetClientsListQuery,
	selectClientsOptions,
} from '@Store/clients/clientsApi'
import { nanoid } from '@reduxjs/toolkit'
import {
	FormButtons,
	FormDeleteButton,
} from '@Components/FormElements/FormButtons/FormButtons'
import useAuth from '@Hooks/useAuth'
import FormWrapper from '@Components/FormElements/FormWrapper/FormWrapper'
import { useAppDispatch, useAppSelector } from '@/store'
import { skipToken } from '@reduxjs/toolkit/query'
import {
	selectSiteLocationData,
	setSiteLocationData,
	setSiteZoom,
} from '@Store/ui/uiSlice'
import { useGetLocationQuery } from '@Store/thirdParty/thirdPartyApi'
import {
	selectFirstValidMapLayer,
	useCreateSiteMapLayerAssociationMutation,
	useGetMapLayersQuery,
} from '@Store/mapLayers/mapLayersApi'

import { useExpandFormAccordion } from '@Hooks/useExpandFormAccordion'

// Site Form Loader
const SiteForm = ({ siteId }: { siteId?: number }) => {
	const { t } = useTranslation('forms', { keyPrefix: 'siteForm' })
	const isEditForm = !!siteId
	const {
		isLoading,
		isError,
		isSuccess,
		refetch,
		data: siteData,
	} = useGetSiteQuery(siteId ? siteId : skipToken, {
		refetchOnMountOrArgChange: true,
	})

	const { isSuccess: isLocationSuccess, data: locationData } =
		useGetLocationQuery()

	const addSiteDefaultValues = {
		...ADD_SITE_DEFAULT_VALUES,
		latitude: isLocationSuccess
			? locationData.latitude
			: ADD_SITE_DEFAULT_VALUES.latitude,
		longitude: isLocationSuccess
			? locationData.longitude
			: ADD_SITE_DEFAULT_VALUES.longitude,
		timezone: isLocationSuccess
			? locationData.timezone
			: ADD_SITE_DEFAULT_VALUES.timezone,
	}

	const defaultValues = isEditForm ? siteData : addSiteDefaultValues

	return (
		<FormWrapper
			entity={t('entity')}
			isEditForm={isEditForm}
			isLoading={isLoading}
			isError={isError}
			isSuccess={isSuccess}
			refetch={refetch}
		>
			{defaultValues && (
				<Form
					key={nanoid()}
					isEditForm={isEditForm}
					defaultValues={defaultValues}
					siteId={siteId}
				/>
			)}
		</FormWrapper>
	)
}

// Site Form
const Form = ({
	defaultValues,
	isEditForm,
	siteId,
}: {
	defaultValues: Site | SiteDefaultValues
	isEditForm: boolean
	siteId?: number
}) => {
	const { state } = useLocation()
	const navigate = useNavigate()

	type Schema = TypeOf<typeof siteFormSchema>

	const ref = useRef(null)

	// React Hook Form
	const methods = useForm<Schema>({
		resolver: zodResolver(siteFormSchema),
		defaultValues,
	})
	const {
		register,
		formState: { errors, isSubmitting, dirtyFields },
		handleSubmit,
		watch,
		setValue,
		getValues,
		setError,
	} = methods
	const isDirty = Object.keys(dirtyFields).length > 0

	// Translations
	const { t } = useTranslation('forms', { keyPrefix: 'siteForm' })
	const { isAdmin, isManager } = useAuth()

	// RTK Query site form dependency data

	// TODO: find a better way, maybe as a `useGetSiteFormQuery`
	// that returns both defaultValues and dependentValues

	const { timezoneOptions, tzOptionsReady } = useGetTimezonesQuery(undefined, {
		selectFromResult: ({ isSuccess, data }) => ({
			...selectTimezoneOptions(data),
			tzOptionsReady: isSuccess,
		}),
	})
	const { locatorOptions, locatorOptionsReady, installations } =
		useGetSiteInstallationsQuery(siteId ? siteId : skipToken, {
			selectFromResult: ({ isSuccess, data }) => ({
				...selectSiteInstallationLocatorOptions(data),
				locatorOptionsReady: isSuccess,
				installations: data,
			}),
		})
	const { clientsOptions, clientsOptionsReady } = useGetClientsListQuery(
		undefined,
		{
			selectFromResult: ({ isSuccess, data }) => ({
				...selectClientsOptions(data),
				clientsOptionsReady: isSuccess,
			}),
			skip: !isAdmin,
		}
	)
	// Default layer
	const { isLayerSuccess, layer } = useGetMapLayersQuery(undefined, {
		selectFromResult: ({ isSuccess, isError, data }) => ({
			isLayerSuccess: isSuccess,
			isError,
			layer: selectFirstValidMapLayer(data),
		}),
		skip: isEditForm,
	})

	const { expandedIndex, setExpandedIndex, errorIndexes } =
		useExpandFormAccordion(ref, errors)

	// RTK Query mutation(s)
	const [updateSite] = useUpdateSiteMutation()
	const [createSite] = useCreateSiteMutation()
	const [deleteSite] = useDeleteSiteMutation()
	const [createMapLayerAssociation] = useCreateSiteMapLayerAssociationMutation()

	const handleSave = async (payload: Schema) => {
		console.log(siteId)
		try {
			if (isEditForm && siteId) {
				await updateSite({ ...payload, id: siteId }).unwrap()
				navigate(`/${siteId}`)
			} else {
				await createSite({ ...payload })
					.unwrap()
					.then((site) => {
						if (isLayerSuccess) {
							if (layer && Object.keys(layer).length > 0) {
								try {
									createMapLayerAssociation({
										siteId: site?.id,
										mapLayerId: layer?.id,
									}).unwrap()
								} catch (error) {
									console.error('Error update default map layer: ', error)
								}
							}
						}
						navigate(`/${site?.id}/installation/add`)
					})
			}
		} catch (errors: unknown) {
			// Surface server-side validation errors to react-hook-form
			for (const field in errors as { [name in keyof Schema]: string }) {
				setError(field as keyof Schema, {
					type: 'custom',
					message: (errors as { [name in keyof Schema]: string })[
						field as keyof Schema
					] as string,
				})
			}
		}
	}

	const handleCancel = () => navigate('/' + siteId + '/installations')
	const handleDelete = async () => {
		console.log(siteId)
		try {
			if (siteId) await deleteSite(siteId).unwrap()
			navigate('/')
		} catch (e) {
			console.error('Error deleting site', e)
		}
	}

	// Track live values
	const {
		accept_location_updates: acceptLocationUpdates,
		auto_jam: autoDisrupt,
		name,
		latitude,
		longitude,
		zoom_level,
		locator_id,
	} = watch()
	const [
		classifyByClassActive,
		classifyByProtectedZoneActive,
		classifyByCommunicationActive,
		classifyByGeoLocationActive,
		classifyByLegalLimitsActive,
		classifyByZoneOriginActive,
		classifyByTimeActive,
	] = watch([
		'threat_analysis_configuration.classify_by_class.active',
		'threat_analysis_configuration.classify_by_protected_zones.active',
		'threat_analysis_configuration.classify_by_communication.active',
		'threat_analysis_configuration.classify_by_geo_location.active',
		'threat_analysis_configuration.classify_by_legal_limits.active',
		'threat_analysis_configuration.classify_by_zone_origin.active',
		'threat_analysis_configuration.classify_by_time.active',
	])

	// Track a non-schema value
	type mapCenterType = { value: string; label: string }
	const mapCenterTranslations = t('mapCenterOptions', {
		returnObjects: true,
	}) as mapCenterType[]

	const [mapCenter, setMapCenter] = useState<mapCenterType>(
		defaultValues.map_center_latitude === null &&
			defaultValues.map_center_longitude === null
			? mapCenterTranslations[0]
			: mapCenterTranslations[1]
	)
	// Handle Drag/Drop site location
	const dispatch = useAppDispatch()

	useEffect(() => {
		if (acceptLocationUpdates) {
			const selectedInstallation = installations?.find(
				(installation) => installation.id === locator_id
			)
			if (selectedInstallation) {
				const { latitude, longitude } = selectedInstallation
				dispatch(setSiteLocationData({ latitude, longitude }))
			}
		}
		dispatch(
			setSiteLocationData({ accept_location_updates: acceptLocationUpdates })
		)
	}, [acceptLocationUpdates, installations, locator_id, dispatch])

	useEffect(() => {
		dispatch(
			setSiteLocationData({
				latitude: defaultValues?.latitude,
				longitude: defaultValues?.longitude,
			})
		)
	}, [dispatch, defaultValues])
	// Handle change lat/lng with drag-drop
	const siteLocationData = useAppSelector(selectSiteLocationData)
	const handleCoordinatesChange = (
		type: 'latitude' | 'longitude',
		value: string
	) => {
		dispatch(
			setSiteLocationData({
				[type]: value,
			})
		)
	}
	useEffect(() => {
		setValue('latitude', Number(siteLocationData?.latitude), {
			shouldDirty: true,
		})
		setValue('longitude', Number(siteLocationData?.longitude), {
			shouldDirty: true,
		})
	}, [siteLocationData, setValue, defaultValues])

	// Set Zoom level from map preferences slider
	useEffect(() => {
		dispatch(setSiteZoom(zoom_level))
	}, [zoom_level, dispatch])

	// TODO: Optimise logic for map center
	const handleMapCenterChange = (values: mapCenterType) => {
		const { value } = values
		const selectedValue = mapCenterTranslations.find(
			(option) => option.value === value
		) as mapCenterType
		setMapCenter(selectedValue)
		if (value === 'site') {
			setValue('map_center_latitude', null, {
				shouldDirty: true,
			})
			setValue('map_center_longitude', null, {
				shouldDirty: true,
			})
		} else {
			setValue('map_center_latitude', getValues()['latitude'], {
				shouldDirty: true,
			})
			setValue('map_center_longitude', getValues()['longitude'], {
				shouldDirty: true,
			})
		}
	}

	useEffect(() => {
		if (clientsOptionsReady && state?.clientId) {
			const option = clientsOptions?.find(
				(option) => option.value === state?.clientId
			)
			if (option !== undefined) setValue('client_id', option.value)
		}
	}, [clientsOptionsReady, clientsOptions, state?.clientId, setValue])

	const adminsClientOptions = [
		...clientsOptions,
		{ value: 0, label: t('clientIsEmpty') },
	]

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(handleSave)} ref={ref}>
				<Field.TextInput
					title={t('name')}
					register={register('name')}
					error={errors?.name?.message}
					testId='site-name'
				/>

				<Field.Select
					title={t('mode')}
					tooltip={<Trans>{t('tooltips.modeOptions')}</Trans>}
					options={t('modeOptions', { returnObjects: true })}
					register={register('mode')}
					error={errors?.mode?.message}
				/>

				<Accordion.Container
					setExpandedIndex={setExpandedIndex}
					index={expandedIndex}
				>
					<Accordion.Item
						title={t('locationPrefsDivider')}
						testId='site-location-accordion'
						isError={errorIndexes.has(0)}
					>
						{/*TODO: C2-8120 MGRS Convertions*/}
						{/*<Field.MgrsInput*/}
						{/*	title={t('siteLatitude')}*/}
						{/*	values={{*/}
						{/*		latitude: siteLocationData?.latitude,*/}
						{/*		longitude: siteLocationData?.longitude,*/}
						{/*	}}*/}
						{/*	errors={[errors?.latitude?.message, errors?.longitude?.message]}*/}
						{/*	disabled={acceptLocationUpdates}*/}
						{/*	testId='site-mgrs'*/}
						{/*/>*/}
						<Field.LatLongInput
							title={t('siteLatitude')}
							type='latitude'
							defaultValue={latitude}
							value={siteLocationData?.latitude}
							error={errors?.latitude?.message}
							onChange={(value) => handleCoordinatesChange('latitude', value)}
							disabled={acceptLocationUpdates}
							testId='site-latitude'
						/>
						<Field.LatLongInput
							title={t('siteLongitude')}
							type='longitude'
							defaultValue={longitude}
							value={siteLocationData?.longitude}
							error={errors?.longitude?.message}
							onChange={(value) => handleCoordinatesChange('longitude', value)}
							disabled={acceptLocationUpdates}
							testId='site-longitude'
						/>
						<Field.Switch
							title={t('locationUpdates')}
							tooltip={t('tooltips.locationUpdates')}
							register={register('accept_location_updates')}
							error={errors?.accept_location_updates?.message}
							disabled={Object.keys(locatorOptions).length === 0}
							testId='accept-location-updates'
						/>
						{acceptLocationUpdates && locatorOptionsReady && (
							<Field.Select
								title={t('installationLocation')}
								options={locatorOptions}
								register={register('locator_id', { valueAsNumber: true })}
								disabled={!acceptLocationUpdates}
								testId='locator'
							/>
						)}
					</Accordion.Item>

					<Accordion.Item
						title={t('dateTimePrefs')}
						testId='date-time-accordion'
						isError={errorIndexes.has(1)}
					>
						{tzOptionsReady && (
							<Field.Select
								title={t('timezone')}
								options={timezoneOptions}
								register={register('timezone')}
								error={errors?.timezone?.message}
								isSearchable
							/>
						)}
						{/*TODO: Add default site switch*/}
					</Accordion.Item>
					<Accordion.Item
						title={t('mapPrefsDivider')}
						testId='map-preferences-accordion'
						isError={errorIndexes.has(2)}
					>
						<FormControl>
							<UiSelect
								id='mapCenter'
								title={t('mapCenter')}
								options={t('mapCenterOptions', { returnObjects: true }) as any}
								value={mapCenter}
								onChange={(values: any) => handleMapCenterChange(values)}
							/>
						</FormControl>
						{mapCenter?.value === 'custom' && (
							<>
								<Field.LatLongInput
									type='latitude'
									title={t('customMapCenterLatitude')}
									register={register('map_center_latitude', {
										valueAsNumber: true,
									})}
									error={errors?.map_center_latitude?.message}
									testId='map-center-latitude'
								/>
								<Field.LatLongInput
									type='longitude'
									title={t('customMapCenterLongitude')}
									register={register('map_center_longitude', {
										valueAsNumber: true,
									})}
									error={errors?.map_center_longitude?.message}
									testId='map-center-longitude'
								/>
							</>
						)}

						<Field.Slider
							title={t('zoomLevel')}
							min={1} // TODO: get these values from Zod?
							max={18}
							register={register('zoom_level', { valueAsNumber: true })}
							error={errors?.zoom_level?.message}
							testId='zoom-level'
						/>
					</Accordion.Item>
					<Accordion.Item
						title={t('disruptionParamsDivider')}
						testId='disruption-parameters-accordion'
						isError={errorIndexes.has(3)}
					>
						<Field.Switch
							title={t('autoDisruptionSwitch')}
							tooltip={t('tooltips.autoDisruptionSwitch')}
							register={register('auto_jam')}
							error={errors?.auto_jam?.message}
							testId='auto-jam-switch'
						/>

						<Field.Select
							title={t('auto_disruption_probability')}
							tooltip={t('tooltips.auto_disruption_probability')}
							options={t('auto_disruption_probabilityOptions', {
								returnObjects: true,
							})}
							register={register('auto_disruption_probability', {
								valueAsNumber: true,
							})}
							error={errors?.auto_disruption_probability?.message}
							disabled={!autoDisrupt}
						/>

						<Field.NumberInput
							title={t('auto_disruption_detection_count')}
							tooltip={t('tooltips.auto_disruption_detection_count')}
							min={0}
							max={2_147_483_647}
							register={register('auto_disruption_detection_count', {
								valueAsNumber: true,
							})}
							error={errors?.auto_disruption_detection_count?.message}
							disabled={!autoDisrupt}
							testId='auto-disruption-detection-count'
						/>

						<Field.Slider
							title={t('auto_jam_interval')}
							tooltip={t('tooltips.auto_jam_interval')}
							min={-50} // TODO: get these values from Zod?
							max={300}
							register={register('auto_jam_interval', { valueAsNumber: true })}
							error={errors?.auto_jam_interval?.message}
							disabled={!autoDisrupt}
							testId='auto-jam-interval'
						/>
					</Accordion.Item>
					<Accordion.Item
						title={t('displayPrefsDivider')}
						testId='display-settings-accordion'
						isError={errorIndexes.has(4)}
					>
						<Field.Select
							title={t('detectionProbability')}
							tooltip={t('tooltips.detectionProbability')}
							options={t('detectionProbabilityOptions', {
								returnObjects: true,
							})}
							register={register('minimum_display_probability')}
							error={errors?.minimum_display_probability?.message}
						/>

						<Field.Slider
							title={t('drone_locator_detection_timeout')}
							defaultValue={defaultValues.drone_locator_detection_timeout}
							tooltip={t('tooltips.drone_locator_detection_timeout')}
							min={1}
							max={150}
							register={register('drone_locator_detection_timeout', {
								valueAsNumber: true,
							})}
							error={errors?.drone_locator_detection_timeout?.message}
							testId='drone-locator-detection-timeout'
						/>

						<Field.Checkbox
							title={t('show_whitelisted_detections')}
							tooltip={t('tooltips.show_whitelisted_detections')}
							register={register('show_whitelisted_detections')}
							error={errors?.show_whitelisted_detections?.message}
							testId='show-whitelisted-detections'
						/>

						<Field.Checkbox
							title={t('showRFSector')}
							tooltip={t('tooltips.showRFSector')}
							register={register('always_show_rf_beam')}
							testId='show-rf-sector'
						/>

						<Field.Checkbox
							title={t('showSystemWarnings')}
							tooltip={t('tooltips.showSystemWarnings')}
							register={register('system_warning_enabled')}
							testId='show-system-warnings'
						/>

						<Field.Slider
							title={t('speedleader_estimation_time')}
							tooltip={t('tooltips.speedleader_estimation_time')}
							min={0} // TODO: get these values from Zod?
							max={30}
							register={register('speedleader_estimation_time', {
								valueAsNumber: true,
							})}
							error={errors?.speedleader_estimation_time?.message}
							testId='speedleader-estimation-time'
						/>
					</Accordion.Item>
					{isAdmin && (
						<>
							<Accordion.Item
								title={t('sensorFusionParameters')}
								testId='sensor-fusion-accordion'
								isError={errorIndexes.has(5)}
							>
								<Field.Select
									title={t('sensitivity')}
									tooltip={<Trans>{t('tooltips.sensitivity')}</Trans>}
									options={t('sensitivityOptions', { returnObjects: true })}
									register={register('sensitivity')}
									error={errors?.sensitivity?.message}
								/>

								<Field.Slider
									title={t('max_location_variance')}
									tooltip={t('tooltips.max_location_variance')}
									min={1} // TODO: get these values from Zod?
									max={20000}
									register={register('max_location_variance', {
										valueAsNumber: true,
									})}
									error={errors?.max_location_variance?.message}
									testId='max-location-variance'
								/>

								<Field.Select
									title={t('round_frequency_band')}
									tooltip={t('tooltips.round_frequency_band')}
									options={t('round_frequency_bandOptions', {
										returnObjects: true,
									})}
									register={register('round_frequency_band')}
									error={errors?.sensitivity?.message}
								/>

								<Field.Slider
									title={t('prob_uav_filter')}
									tooltip={t('tooltips.prob_uav_filter')}
									min={0}
									max={100}
									step={1}
									defaultValue={defaultValues.prob_uav_filter}
									register={register('prob_uav_filter', {
										valueAsNumber: true,
									})}
									error={errors?.prob_uav_filter?.message}
									testId='prob-uav-filter'
								/>

								<Field.UnitsSlider
									units='m'
									title={t('prob_uav_filter_range')}
									tooltip={t('tooltips.prob_uav_filter_range')}
									min={20}
									max={10000}
									step={10}
									defaultValue={defaultValues.prob_uav_filter_range}
									register={register('prob_uav_filter_range', {
										valueAsNumber: true,
									})}
									error={errors?.prob_uav_filter_range?.message}
									testId='prob-uav-filter-range'
								/>
								<Field.Switch
									title={t('fuse_controller_detections')}
									tooltip={t('tooltips.fuse_controller_detections')}
									register={register('fuse_controller_detections')}
									error={errors?.fuse_controller_detections?.message}
									testId='fuse-controller-detections'
								/>
							</Accordion.Item>
							<Accordion.Item
								title={t('threatAnalysisConfig.title')}
								testId='threat-analysis-config-accordion'
								isError={errorIndexes.has(6)}
							>
								<Field.Switch
									title={t('threatAnalysisConfig.headings.classifyByClass')}
									register={register(
										'threat_analysis_configuration.classify_by_class.active'
									)}
									error={
										errors?.threat_analysis_configuration?.classify_by_class
											?.active?.message
									}
									testId='classify-by-size-active'
								/>
								<Field.Switch
									title={t('threatAnalysisConfig.size')}
									register={register(
										'threat_analysis_configuration.classify_by_class.size'
									)}
									disabled={!classifyByClassActive}
									error={
										errors?.threat_analysis_configuration?.classify_by_class
											?.size?.message
									}
									testId='classify-by-size-size'
								/>
								<Field.Switch
									title={t('threatAnalysisConfig.unknown')}
									register={register(
										'threat_analysis_configuration.classify_by_class.unknown'
									)}
									disabled={!classifyByClassActive}
									error={
										errors?.threat_analysis_configuration?.classify_by_class
											?.unknown?.message
									}
									testId='classify-by-size-unknown'
								/>
								<Field.Select
									title={t('threatAnalysisConfig.priority')}
									register={register(
										'threat_analysis_configuration.classify_by_class.priority'
									)}
									options={t('threatAnalysisConfig.priorityOptions', {
										returnObjects: true,
									})}
									disabled={!classifyByClassActive}
									error={
										errors?.threat_analysis_configuration?.classify_by_class
											?.priority?.message
									}
									testId='classify-by-size-priority'
								/>

								<Field.Switch
									title={t(
										'threatAnalysisConfig.headings.classifyByProtectedZones'
									)}
									register={register(
										'threat_analysis_configuration.classify_by_protected_zones.active'
									)}
									error={
										errors?.threat_analysis_configuration
											?.classify_by_protected_zones?.active?.message
									}
									testId='classify-by-protected-zones-active'
								/>
								<Field.Select
									title={t('threatAnalysisConfig.priority')}
									register={register(
										'threat_analysis_configuration.classify_by_protected_zones.priority'
									)}
									options={t('threatAnalysisConfig.priorityOptions', {
										returnObjects: true,
									})}
									disabled={!classifyByProtectedZoneActive}
									testId='classify-by-protected-zones-priority'
								/>
								<Field.Switch
									title={t(
										'threatAnalysisConfig.headings.classifyByCommunication'
									)}
									register={register(
										'threat_analysis_configuration.classify_by_communication.active'
									)}
									error={
										errors?.threat_analysis_configuration
											?.classify_by_communication?.active?.message
									}
									testId='classify-by-communication-active'
								/>
								<Field.Select
									title={t('threatAnalysisConfig.priority')}
									register={register(
										'threat_analysis_configuration.classify_by_communication.priority'
									)}
									options={t('threatAnalysisConfig.priorityOptions', {
										returnObjects: true,
									})}
									disabled={!classifyByCommunicationActive}
									testId='classify-by-communication-priority'
								/>
								<Field.Switch
									title={t(
										'threatAnalysisConfig.headings.classifyByGeoLocation'
									)}
									register={register(
										'threat_analysis_configuration.classify_by_geo_location.active'
									)}
									error={
										errors?.threat_analysis_configuration
											?.classify_by_geo_location?.active?.message
									}
									testId='classify-by-geo-location-active'
								/>
								<Field.Select
									title={t('threatAnalysisConfig.priority')}
									register={register(
										'threat_analysis_configuration.classify_by_geo_location.priority'
									)}
									options={t('threatAnalysisConfig.priorityOptions', {
										returnObjects: true,
									})}
									disabled={!classifyByGeoLocationActive}
									testId='classify-by-geo-location-priority'
								/>
								<Field.Switch
									title={t(
										'threatAnalysisConfig.headings.classifyByLegalLimits'
									)}
									register={register(
										'threat_analysis_configuration.classify_by_legal_limits.active'
									)}
									error={
										errors?.threat_analysis_configuration
											?.classify_by_legal_limits?.active?.message
									}
									testId='classify-by-legal-limits-active'
								/>
								<Field.RangeSlider
									title={t('threatAnalysisConfig.speed_limit')}
									defaultValue={[
										defaultValues.threat_analysis_configuration
											?.classify_by_legal_limits?.speed_min ?? 10,
										defaultValues.threat_analysis_configuration
											?.classify_by_legal_limits?.speed_max || 150,
									]}
									min={0}
									max={200}
									onChangeEnd={(value: [number, number]) => {
										setValue(
											'threat_analysis_configuration.classify_by_legal_limits.speed_min',
											value[0],
											{
												shouldDirty: true,
												shouldValidate: true,
												shouldTouch: true,
											}
										)
										setValue(
											'threat_analysis_configuration.classify_by_legal_limits.speed_max',
											value[1],
											{
												shouldDirty: true,
												shouldValidate: true,
												shouldTouch: true,
											}
										)
									}}
									disabled={!classifyByLegalLimitsActive}
									error={[
										errors?.threat_analysis_configuration
											?.classify_by_legal_limits?.speed_min?.message,
										errors?.threat_analysis_configuration
											?.classify_by_legal_limits?.speed_max?.message,
									]
										.filter((error) => !!error)
										.join(', ')}
									testId='classify-by-legal-limits-speed-range'
								/>
								<Field.RangeSlider
									title={t('threatAnalysisConfig.agl_limit')}
									defaultValue={[
										defaultValues.threat_analysis_configuration
											?.classify_by_legal_limits?.agl_min ?? 10,
										defaultValues.threat_analysis_configuration
											?.classify_by_legal_limits?.agl_max || 150,
									]}
									min={0}
									max={200}
									onChangeEnd={(value: [number, number]) => {
										setValue(
											'threat_analysis_configuration.classify_by_legal_limits.agl_min',
											value[0],
											{
												shouldDirty: true,
												shouldValidate: true,
												shouldTouch: true,
											}
										)
										setValue(
											'threat_analysis_configuration.classify_by_legal_limits.agl_max',
											value[1],
											{
												shouldDirty: true,
												shouldValidate: true,
												shouldTouch: true,
											}
										)
									}}
									disabled={!classifyByLegalLimitsActive}
									error={[
										errors?.threat_analysis_configuration
											?.classify_by_legal_limits?.agl_min?.message,
										errors?.threat_analysis_configuration
											?.classify_by_legal_limits?.agl_max?.message,
									]
										.filter((error) => !!error)
										.join(', ')}
									testId='classify-by-legal-limits-agl-range'
								/>
								<Field.Select
									title={t('threatAnalysisConfig.priority')}
									register={register(
										'threat_analysis_configuration.classify_by_legal_limits.priority'
									)}
									options={t('threatAnalysisConfig.priorityOptions', {
										returnObjects: true,
									})}
									disabled={!classifyByLegalLimitsActive}
									testId='classify-by-legal-limits-priority'
								/>
								<Field.Switch
									title={t(
										'threatAnalysisConfig.headings.classifyByZoneOrigin'
									)}
									register={register(
										'threat_analysis_configuration.classify_by_zone_origin.active'
									)}
									error={
										errors?.threat_analysis_configuration
											?.classify_by_zone_origin?.active?.message
									}
									testId='classify-by-zone-origin-active'
								/>
								<Field.Select
									title={t('threatAnalysisConfig.priority')}
									register={register(
										'threat_analysis_configuration.classify_by_zone_origin.priority'
									)}
									options={t('threatAnalysisConfig.priorityOptions', {
										returnObjects: true,
									})}
									disabled={!classifyByZoneOriginActive}
									testId='classify-by-zone-origin-priority'
								/>
								<Field.Switch
									title={t('threatAnalysisConfig.headings.classifyByTime')}
									register={register(
										'threat_analysis_configuration.classify_by_time.active'
									)}
									error={
										errors?.threat_analysis_configuration?.classify_by_time
											?.active?.message
									}
									testId='classify-by-time-active'
								/>
								<Field.Slider
									title={t('threatAnalysisConfig.start_time')}
									register={register(
										'threat_analysis_configuration.classify_by_time.start_time'
									)}
									min={0}
									max={24}
									disabled={!classifyByTimeActive}
									defaultValue={
										defaultValues.threat_analysis_configuration
											?.classify_by_time?.start_time ?? 6
									}
									testId='classify-by-time-start-time'
								/>
								<Field.Slider
									title={t('threatAnalysisConfig.duration')}
									register={register(
										'threat_analysis_configuration.classify_by_time.duration'
									)}
									min={0}
									max={1440}
									disabled={!classifyByTimeActive}
									defaultValue={
										defaultValues.threat_analysis_configuration
											?.classify_by_time?.duration || 60
									}
									testId='classify-by-time-duration'
								/>
								<Field.Select
									title={t('threatAnalysisConfig.priority')}
									register={register(
										'threat_analysis_configuration.classify_by_time.priority'
									)}
									options={t('threatAnalysisConfig.priorityOptions', {
										returnObjects: true,
									})}
									disabled={!classifyByTimeActive}
									testId='classify-by-time-priority'
								/>
							</Accordion.Item>
							{clientsOptionsReady && (
								<Accordion.Item
									title={t('clientManagementPrefs')}
									testId='client-management-accordion'
									isError={errorIndexes.has(7)}
								>
									<Field.Select
										title={t('client')}
										options={isAdmin ? adminsClientOptions : clientsOptions}
										register={register('client_id')}
										error={errors?.client_id?.message}
										placeholder={t('clientPlaceholder')}
									/>
								</Accordion.Item>
							)}
						</>
					)}
					{isEditForm && (
						<Accordion.Item
							title={t('outputPrefsDivider')}
							testId='output-options-accordion'
							isLazy
						>
							<Cots />
						</Accordion.Item>
					)}
				</Accordion.Container>
				{isEditForm && isManager && (
					<FormDeleteButton
						handleDelete={handleDelete}
						name={`Site ${name}`}
						headerText={t('deleteSite')}
						fromText='from instance'
						label={t('deleteSite')}
						testId='delete-site'
					/>
				)}
				{/* BUTTONS */}
				<FormButtons
					isSubmitting={isSubmitting}
					isDirty={isDirty}
					handleCancel={handleCancel}
				/>
			</form>
		</FormProvider>
	)
}

export default SiteForm
