// React
import { useTranslation } from 'react-i18next'
// Chakra
import {
	Button,
	Box,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	useDisclosure,
} from '@chakra-ui/react'
import { MdAdd } from 'react-icons/md'
// Components
import Modal from '@UI/Modals/Modal'
import Instructions from './Instructions'
import MapCreateOnlineLayerForm from '@Forms/MapsZonesForms/MapLayersForm/CreateOnlineMapLayerForm'
import MapCreateOfflineLayerForm from '@Forms/MapsZonesForms/MapLayersForm/CreateOfflineMapLayerForm'

const CreateMapLayerModal = ({ variant }: { variant: 'icon' | 'button' }) => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	// Translations
	const { t } = useTranslation('forms', {
		keyPrefix: 'mapsZonesForm.mapLayersForm',
	})
	return (
		<>
			{variant === 'icon' ? (
				<Button
					variant='action'
					leftIcon={<MdAdd />}
					onClick={onOpen}
					data-testid='add-map-layer'
				>
					{t('addLayer')}
				</Button>
			) : (
				<Button
					variant='ghost'
					leftIcon={<MdAdd />}
					onClick={onOpen}
					data-testid='add-map-layer'
				>
					{t('addLayer')}
				</Button>
			)}

			<Modal
				isOpen={isOpen}
				onClose={onClose}
				headerText={t('createMapLayer')}
				size='xl'
			>
				<Box p={4} maxH='620px' overflowY='auto'>
					<Tabs variant='modal'>
						<TabList>
							<Tab>{t('onlineMap')}</Tab>
							<Tab>{t('offlineMap')}</Tab>
						</TabList>
						<TabPanels>
							<TabPanel p={0}>
								<Instructions type='online' />
								<MapCreateOnlineLayerForm onClose={onClose} />
							</TabPanel>
							<TabPanel p={0}>
								<Instructions type='offline' />
								<MapCreateOfflineLayerForm onClose={onClose} />
							</TabPanel>
						</TabPanels>
					</Tabs>
				</Box>
			</Modal>
		</>
	)
}

export default CreateMapLayerModal
