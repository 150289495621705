import { useParams } from 'react-router-dom'

// Redux
import { useGetSiteInstallationQuery } from '@Store/sites/sitesApi'
import { useAppSelector } from '@Store/index'
import { selectSensorPreview } from '@Store/ui/uiSlice'

import type { RadarPreview as RadarPreviewType } from '@Store/types'

import SentryMarker from '@Components/Markers/SentryMarker/SentryMarker'
import { RadarSensorSector } from '@Components/Sectors'
import CompassMarker from '@Components/Markers/CompassMarker/CompassMarker'
import Detections from '@Components/Site/MapLayers/Detections'

import useCenterMap from './useCenterMap'

const RadarPreview = () => {
	const { siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)

	// Sensor details
	const sensorPreview = useAppSelector(selectSensorPreview) as RadarPreviewType
	const installationId = sensorPreview?.sentryId

	// Installation details
	const { isSuccess, installation } = useGetSiteInstallationQuery(
		{ siteId, installationId: installationId as number },
		{
			skip: !sensorPreview || !installationId || !siteId,
			selectFromResult: ({ data, isSuccess }) => ({
				isSuccess,
				installation: data,
			}),
		}
	)

	// Temporarily center the map
	useCenterMap({
		latitude: installation?.latitude,
		longitude: installation?.longitude,
	})

	if (isSuccess && installation && sensorPreview) {
		const bearing =
			(installation.direction + sensorPreview.directionOffset) % 360
		return (
			<>
				<Detections siteId={siteId} />
				<CompassMarker
					position={[installation.latitude, installation.longitude]}
					bearing={bearing}
				/>
				<RadarSensorSector
					latitude={installation.latitude}
					longitude={installation.longitude}
					bearing={bearing}
					reach_max={sensorPreview.reach_max}
					reach_min={sensorPreview.reach_min}
					radar_az_min_search={sensorPreview.radar_az_min_search}
					radar_az_max_search={sensorPreview.radar_az_max_search}
					radar_az_min_track={sensorPreview.radar_az_min_track}
					radar_az_max_track={sensorPreview.radar_az_max_track}
					isActive={true}
				/>
				<SentryMarker
					name={installation.name}
					position={[installation.latitude, installation.longitude]}
					sentryType={installation.sentry_type}
					iconColor={installation.status_color}
				/>
			</>
		)
	} else return null
}

export default RadarPreview
