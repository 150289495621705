import { Polygon as LeafletPolygon } from 'react-leaflet'

import type { PolygonProps as LeafletPolygonProps } from 'react-leaflet'
import { useEffect, useRef } from 'react'

type PolygonProps = LeafletPolygonProps & { testId?: string }

const Polygon = ({
	positions,
	fillColor,
	color,
	testId,
	dashArray = [1],
	weight = 3,
	...rest
}: PolygonProps) => {
	const options = {
		fillColor,
		color,
		dashArray,
		weight,
	}

	const polygonRef = useRef<L.Polygon | null>(null)

	useEffect(() => {
		if (polygonRef.current && testId) {
			const polygonElement = polygonRef.current.getElement()
			if (polygonElement) {
				polygonElement.setAttribute('data-testid', testId)
			}
		}
	}, [testId])

	return (
		<LeafletPolygon
			positions={positions}
			pathOptions={options}
			ref={polygonRef}
			{...rest}
		/>
	)
}

export default Polygon
