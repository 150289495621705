import { type FC, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import {
	Box,
	FormControl,
	FormErrorMessage,
	useRangeSlider,
	type RangeSliderProps,
	Flex,
} from '@chakra-ui/react'

import RangeFilterSliderThumb from './RangeFilterSliderThumb'

type FormRangeSliderProps = {
	id?: string
	error?: string
	defaultValue: any
	min: number
	max: number
	step?: number
	stepByNumber?: number
	minStepsBetweenThumbs?: number
	disabled?: boolean
	inputWidth?: number
	onChangeEnd: RangeSliderProps['onChangeEnd']
	inputStates?: number[]
}

const RangeFilterSlider: FC<FormRangeSliderProps> = ({
	id,
	error,
	defaultValue,
	min,
	max,
	step = 1,
	minStepsBetweenThumbs = 1,
	stepByNumber = 1,
	disabled = false,
	onChangeEnd,
	inputWidth = undefined,
	inputStates = undefined,
	...rest
}) => {
	const htmlId = id ?? 'rangeSlider'
	const { control } = useFormContext()

	const {
		state,
		actions,
		getInnerTrackProps,
		// getInputProps,
		// getMarkerProps,
		getRootProps,
		getThumbProps,
		getTrackProps,
	} = useRangeSlider({
		min,
		max,
		defaultValue,
		step,
		minStepsBetweenThumbs,
		onChangeEnd,
		onChange: onChangeEnd,
		...rest,
	})

	const { onKeyDown: onThumbKeyDownFirstIndex, ...thumbPropsFirstIndex } =
		getThumbProps({
			index: 0,
		})

	const { onKeyDown: onThumbKeyDownSecondIndex, ...thumbPropsSecondIndex } =
		getThumbProps({
			index: 1,
		})

	const onKeyDownStepBy = (
		e: React.KeyboardEvent<HTMLDivElement>,
		thumbIndex: number
	) => {
		if (e.code === 'ArrowRight') actions.stepUp(thumbIndex, stepByNumber)
		else if (e.code === 'ArrowLeft') actions.stepDown(thumbIndex, stepByNumber)
		else if (thumbIndex === 0 && typeof onThumbKeyDownFirstIndex === 'function')
			onThumbKeyDownFirstIndex(e)
		else if (
			thumbIndex === 1 &&
			typeof onThumbKeyDownSecondIndex === 'function'
		)
			onThumbKeyDownSecondIndex(e)
	}

	const CenterThumb = () => {
		const innerTrackProps = getInnerTrackProps()
		const left = innerTrackProps.style?.left
		const width = innerTrackProps.style?.width

		return (
			<Box className='RangeFilterCenterThumb' height='24px' width='100%'>
				<Box width={width} height='24px' left={left} position='relative'>
					<Box
						className='RangeFilterDivider'
						width='1px'
						bgColor='primary'
						position='absolute'
						left='50%'
						height='24px'
					>
						<Box
							className='RangeFilterTriangle'
							pointerEvents='none'
							position='relative'
							top='-6px'
							left='-6px'
							borderLeft='6px solid transparent'
							borderRight='6px solid transparent'
							borderTop='6px solid'
							borderTopColor='primary'
						/>
					</Box>
				</Box>
			</Box>
		)
	}

	const UnitsMarkers = () => {
		const stepSize = Math.abs(min - max) / 10
		const steps = useMemo(() => {
			const stepsArray = [min]
			for (let i = 1; i <= 10; i++) {
				stepsArray.push(min + stepSize * i)
			}
			return stepsArray
		}, [stepSize])

		const isInteger = (x: number): boolean => {
			return isFinite(x) && Math.floor(x) === x
		}

		const formatStep = (step: number) =>
			isInteger(step) ? step : step.toFixed(3)

		return (
			<Flex w='full' position='relative' top='6px' userSelect='none'>
				{steps.map((step) => (
					<Box
						key={step}
						fontSize='12'
						flex={1}
						textAlign='center'
						position='relative'
					>
						<Box
							position='absolute'
							width='2px'
							bgColor='whiteAlpha.300'
							h='24px'
							top='-24px'
							left='calc(50% - 1px)'
						/>
						{formatStep(step)}
					</Box>
				))}
			</Flex>
		)
	}
	// TODO: Update with Range Inputs
	// const RangeInputs = () => {
	// 	const inputOneProps = getInputProps({ index: 0 })
	// 	const inputTwoProps = getInputProps({ index: 1 })
	// 	const inputOneValue = inputOneProps?.value
	// 	const inputTwoValue = inputTwoProps?.value
	// 	return (
	// 		<Flex className='RangeFilterInputs' mb={6} gap={4} w='full'>
	// 			<Flex flex='1'>
	// 				<Input
	// 					{...getInputProps({ index: 0 })}
	// 					type='number'
	// 					onChange={(e) => {
	// 						const value = Number(e.target.value)
	// 						value >= state.getThumbMinValue(0) &&
	// 							value <= state.getThumbMaxValue(0) &&
	// 							actions.setValueAtIndex(0, value - 1)
	// 						setTimeout(() => e.target.focus(), 1)
	// 					}}
	// 					p={0}
	// 					textAlign='center'
	// 				/>
	// 			</Flex>
	// 			<Flex flex='1'>
	// 				<Input
	// 					{...getInputProps({ index: 1 })}
	// 					type='number'
	// 					onChange={(e) => {
	// 						const value = Number(e.target.value)
	// 						value >= state.getThumbMinValue(1) &&
	// 							value <= state.getThumbMaxValue(1) &&
	// 							actions.setValueAtIndex(1, value)
	//
	// 						// setTimeout(() => e.target.focus(), 1)
	// 					}}
	// 					p={0}
	// 					textAlign='center'
	// 				/>
	// 			</Flex>
	// 		</Flex>
	// 	)
	// }

	return (
		<FormControl isInvalid={!!error} isDisabled={disabled}>
			<Controller
				control={control}
				name={htmlId}
				defaultValue={defaultValue}
				render={({ field: { value, onChange } }) => {
					return (
						<Flex
							direction='column'
							alignItems='center'
							position='relative'
							py={3}
							{...(disabled && { opacity: 0.4, pointerEvents: 'none' })}
						>
							{/*<RangeInputs />*/}
							<Box w='calc(100% - 40px)'>
								<Box cursor='pointer' {...getRootProps()}>
									<Flex
										alignItems='center'
										justifyContent='center'
										w='full'
										h='24px'
										bgColor='whiteAlpha.200'
										border='1px solid'
										borderColor='whiteAlpha.300'
										{...getTrackProps()}
									>
										<Box
											h='100%'
											bgColor='rgba(255, 167, 38, 0.45)'
											{...getInnerTrackProps()}
										/>
										<CenterThumb />
									</Flex>
									<RangeFilterSliderThumb
										value={state.value[0]}
										onKeyDownStepBy={onKeyDownStepBy}
										thumbProps={thumbPropsFirstIndex}
										thumbIndex={0}
										bgColor='primary'
										testId='thumb-min'
									/>
									<RangeFilterSliderThumb
										value={state.value[1]}
										onKeyDownStepBy={onKeyDownStepBy}
										thumbProps={thumbPropsSecondIndex}
										thumbIndex={1}
										bgColor='primary'
										testId='thumb-max'
									/>
								</Box>
							</Box>
							<UnitsMarkers />
						</Flex>
					)
				}}
			></Controller>
			<FormErrorMessage>{error}</FormErrorMessage>
		</FormControl>
	)
}

export default RangeFilterSlider
