import { useTranslation } from 'react-i18next'

import type { Site } from '@Store/types'

import AlertRecipientsTable from '../AlertRecipientsTable/AlertRecipientsTable'
import { AlertRecipientFormModal } from '@Forms/AlertRecipientForm/AlertRecipientForm'
import { Button, Flex, useDisclosure } from '@chakra-ui/react'

type AlertRecipientsProps = {
	siteId: Site['id']
}
const AlertRecipients = ({ siteId }: AlertRecipientsProps) => {
	const { t } = useTranslation('pages', {
		keyPrefix: 'alertSettings.alertRecipients',
	})

	const { isOpen, onOpen, onClose } = useDisclosure()

	return (
		<>
			<Flex justifyContent='flex-end' marginBlock={4}>
				<Button border={0} onClick={onOpen} data-testid='add-recipient-button'>
					+ {t('actions.addRecipient')}
				</Button>
			</Flex>
			<AlertRecipientsTable siteId={siteId} testId='alert-recipients-table' />
			<AlertRecipientFormModal
				siteId={siteId}
				onClose={onClose}
				isOpen={isOpen}
			/>
		</>
	)
}

export default AlertRecipients
