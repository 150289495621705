import { useTranslation } from 'react-i18next'
import { Flex, Grid, GridItem, useToken } from '@chakra-ui/react'
import MapLegendSection from '@Components/MapControls/MapLegend/MapLegendSection'
// Icons
import ControllerMarkerIcon from '@Components/Markers/BeaconMarker/ControllerMarkerIcon'
import HomeMarkerIcon from '@Components/Markers/BeaconMarker/HomeMarkerIcon'

const MapLegendSectionDroneLocator = () => {
	const { t } = useTranslation('map', {
		keyPrefix: 'legend.droneLocator',
	})

	const [locatorColor] = useToken('colors', ['detections.default'])

	return (
		<MapLegendSection title={t('title')}>
			<Grid
				templateColumns='1fr 65px'
				justifyContent='center'
				alignItems='center'
				rowGap={4}
			>
				<GridItem>{t('operator')}</GridItem>
				<Flex alignItems='center' justifyContent='center'>
					<ControllerMarkerIcon color={locatorColor} />
				</Flex>
				<GridItem>{t('home')}</GridItem>
				<Flex alignItems='center' justifyContent='center'>
					<HomeMarkerIcon color={locatorColor} />
				</Flex>
			</Grid>
		</MapLegendSection>
	)
}

export default MapLegendSectionDroneLocator
