import { z } from 'zod'

export const AglMaskRange = {
	min: -3000,
	max: 3000,
}

export const radarAglMaskFormSchema = z.object({
	min: z.number().min(AglMaskRange.min).max(AglMaskRange.max),
	max: z.number().min(AglMaskRange.min).max(AglMaskRange.max),
})

export type RadarAglMaskSchema = z.infer<typeof radarAglMaskFormSchema>

export type DefaultValues = z.infer<typeof radarAglMaskFormSchema>

export const radarAglMaskFormDefaultValues: DefaultValues = {
	min: 30,
	max: 300,
}
