import { useMemo, memo } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Center, Tooltip, Icon } from '@chakra-ui/react'
import { MdMap } from 'react-icons/md'
import Control from 'react-leaflet-custom-control'

// Redux
import { skipToken } from '@reduxjs/toolkit/query/react'
import { createSelector } from '@reduxjs/toolkit'
import { useUpdateUserSettingsMutation } from '@Store/user/usersApi'
import {
	selectUserId,
	selectUserSettings,
	selectUserMapLayerId,
} from '@Store/user/userSlice'
import {
	useGetSiteMapLayersQuery,
	selectUserMapLayer,
} from '@Store/mapLayers/mapLayersApi'
import { useAppSelector } from '@Store/index'

import type { MapLayer } from '@Store/types/mapLayers'

const emptyMapLayers = [] as MapLayer[]

const MapLayerButton = () => {
	const { siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)
	const userId = useAppSelector(selectUserId)
	const userSettings = useAppSelector(selectUserSettings)
	const userMapLayerId = useAppSelector(selectUserMapLayerId)

	const [changeMapLayer] = useUpdateUserSettingsMutation()

	const selectSiteMapLayers = useMemo(() => {
		return createSelector(
			(data?: MapLayer[]) => data,
			(data) => data ?? emptyMapLayers
		)
	}, [])

	const { siteMapLayers, userMapLayer } = useGetSiteMapLayersQuery(
		siteId ? siteId : skipToken,
		{
			selectFromResult: ({ isError, isSuccess, data }) => ({
				isError,
				isSuccess,
				siteMapLayers: selectSiteMapLayers(data),
				userMapLayer: selectUserMapLayer(data, userMapLayerId),
			}),
		}
	)

	// if (!isSuccess) return <p>Loading</p>
	// else if (isError) return <p>Error</p>

	const userMapIndex = siteMapLayers.findIndex(
		(layer) => layer.id === userMapLayerId
	)

	const mapLayerId =
		userMapIndex + 1 > siteMapLayers.length - 1
			? siteMapLayers[0]?.id
			: siteMapLayers[userMapIndex + 1]?.id

	const handleClick = async () => {
		try {
			await changeMapLayer({
				userId,
				settings: {
					...userSettings,
					mapLayerId,
				},
			}).unwrap()
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<Control position='bottomleft'>
			<Tooltip
				label={userMapLayer?.name}
				placement='right'
				fontSize='xs'
				closeOnClick={false}
			>
				<Button onClick={handleClick} variant='mapControl'>
					<Center>
						<Icon aria-label='Change map layer' as={MdMap} h='24px' w='24px' />
					</Center>
				</Button>
			</Tooltip>
		</Control>
	)
}

export default memo(MapLayerButton)
