// Chakra
import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	Radio as ChakraRadio,
	RadioGroup,
	Stack,
	Text,
} from '@chakra-ui/react'
// Components
import Tooltip from '@UI/Tooltip/Tooltip'
// Types
import type { FC } from 'react'
import type { RegisterType } from '@Components/FormElements/types/RegisterType'
import type { FormElementProps } from '@Components/FormElements/types/FormElementProps'

type RadioOptionType = {
	value: string
	label: string
}

type RadioProps = FormElementProps & {
	value?: string
	defaultValue?: string
	options: RadioOptionType[]
	onChange?: <T>(value: T) => void
} & RegisterType<string>

const Radio: FC<RadioProps> = ({
	id,
	error,
	options,
	register,
	title,
	value,
	defaultValue,
	onChange,
	tooltip,
}) => {
	const htmlId = id ?? register?.name ?? 'radio'
	return (
		<Tooltip label={tooltip} type='info'>
			<FormControl isInvalid={!!error}>
				{title && <FormLabel htmlFor={htmlId}>{title}</FormLabel>}
				<RadioGroup
					id={htmlId}
					value={value}
					defaultValue={defaultValue}
					{...(register ?? {})}
					onChange={(e) => {
						if (value && onChange) onChange(e)
						else {
							register?.onChange({ target: { value: e } })
						}
					}}
				>
					<Stack direction='row'>
						{options.map(({ value, label }) => (
							<ChakraRadio
								key={value}
								value={value}
								data-testid={label.toLowerCase()}
								{...(register ?? {})}
							>
								<Text fontSize='sm'>{label}</Text>
							</ChakraRadio>
						))}
					</Stack>
				</RadioGroup>
				<FormErrorMessage>{error}</FormErrorMessage>
			</FormControl>
		</Tooltip>
	)
}

export default Radio
