import { defineStyleConfig } from '@chakra-ui/react'

const TabsStyles = defineStyleConfig({
	baseStyle: {
		tablist: {
			// Due to issue with the setting up fontSize for the tabs
			'& > button': {
				fontSize: 'sm',
			},
			paddingBottom: '2px',
			overflowX: 'auto',
			scrollbarWidth: 'none',
			'::-webkit-scrollbar': { display: 'none' },
		},
		tab: {
			textTransform: 'uppercase',
			fontWeight: 500,
			_selected: {
				borderBottomColor: 'var(--c2-colors-primary) !important',
			},
			_hover: {
				color: 'primary',
			},
			_active: {
				background: 'unset',
			},
		},
	},
	variants: {
		modal: {
			tablist: {
				border: 'none',
			},
			tab: {
				borderBottomWidth: '2px',
				borderBottomColor: 'transparent',
				_selected: {
					borderBottomColor: 'primary',
				},
			},
		},
		quick_actions: {
			tab: {
				_disabled: {
					opacity: 1,
					backgroundColor: 'card_bg__disabled',
					color: 'disabled',
					_selected: {
						color: 'disabled',
					},
					_hover: {
						color: 'disabled',
					},
				},
			},
		},
	},
})

export const Tabs = {
	...TabsStyles,
}
