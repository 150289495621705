import { defineStyleConfig } from '@chakra-ui/react'

const SwitchStyles = defineStyleConfig({
	baseStyle: {
		container: {
			position: 'relative',
		},
		thumb: {
			boxSize: '20px',
			position: 'relative',
			top: '-6px',
			left: '-4px',
			_checked: {
				bg: 'primary',
			},
		},
		track: {
			'--switch-track-width': '26px',
			height: '8px',
			_checked: {
				bg: 'filter_bg',
			},
		},
	},
})

export const Switch = {
	...SwitchStyles,
}
