import { useTheme } from '@chakra-ui/react'
import Sector, { type SectorProps } from '@Components/MapShapes/Sector/Sector'

export type RfFilterSectorProps = {
	isActive?: boolean
	isDisabled?: boolean
} & SectorProps

const RfFilterSector = ({
	latitude,
	longitude,
	bearing,
	angle,
	reach = 0,
	isActive = false,
	isDisabled = false,
}: RfFilterSectorProps) => {
	const {
		semanticTokens: { colors },
	} = useTheme()
	const fillColor = colors.primary
	const strokeColor = colors.sectors.highlight
	const disabledColor = colors.filter_bg__disabled

	const color = isDisabled ? disabledColor : isActive ? strokeColor : fillColor
	const fill = isDisabled ? disabledColor : fillColor

	return (
		<Sector
			latitude={latitude}
			longitude={longitude}
			reach={reach}
			bearing={bearing}
			angle={angle}
			testId='rf-filter-sector'
			className='RfFilterSector'
			weight={isDisabled ? 0 : 4}
			color={color}
			fillColor={fill}
		/>
	)
}

export default RfFilterSector
