// React
import { useParams } from 'react-router-dom'
// Chakra
import { Icon, Menu, MenuButton, MenuList } from '@chakra-ui/react'
import { MdWarning } from 'react-icons/md'
// Components
import SystemWarningsTabs from '@Components/Navigation/SystemWarnings/SystemWarningsTabs'
import SystemWarningsUnreadButton from '@Components/Navigation/SystemWarnings/SystemWarningsUnreadButton'

const SystemWarningsButton = () => {
	const { siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)

	return (
		<Menu
			placement='bottom-start'
			strategy='fixed'
			gutter={1}
			autoSelect={false}
			variant='userMenu'
		>
			<MenuButton position='relative' disabled={!siteId}>
				<Icon as={MdWarning} boxSize={6} />
				<SystemWarningsUnreadButton siteId={siteId} />
			</MenuButton>
			<MenuList w='440px'>
				<SystemWarningsTabs siteId={siteId} />
			</MenuList>
		</Menu>
	)
}

export default SystemWarningsButton
