import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import FormHeader from '@Components/Forms/FormHeader'

import DeviceConfiguration from './DeviceConfiguration'
import DroneCalibration from './DroneCalibration/DroneCalibration'
import BracketCalibration from './BracketCalibration/BracketCalibration'

import { useAppDispatch, useAppSelector } from '@Store/index'
import {
	selectActiveCameraId,
	selectCameraCalibrationStep,
	setActiveCameraId,
	setActiveTargetId,
	setCameraCalibrationStep,
} from '@Store/ui/uiSlice'
import { useTranslation } from 'react-i18next'

const CameraCalibration = () => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'siteInstallations.cameraCalibration',
	})
	const {
		sensorId: sensorIdParam,
		installationId: installationIdParam,
		siteId: siteIdParam,
	} = useParams()
	const siteId = Number(siteIdParam)
	const installationId = Number(installationIdParam)
	const cameraId = Number(sensorIdParam)

	const dispatch = useAppDispatch()
	const activeStep = useAppSelector(selectCameraCalibrationStep)

	// restore or hide camera when exit camera calibration
	const activeCameraId = useAppSelector(selectActiveCameraId)
	const [cameraWasActive] = useState<boolean>(activeCameraId !== null)

	const [bracketInstalled, setBracketInstalled] = useState<boolean>(false)

	useEffect(() => {
		dispatch(setActiveTargetId(null))
		return () => {
			dispatch(setActiveTargetId(null))
			dispatch(setCameraCalibrationStep(0))
		}
	}, [dispatch])

	useEffect(() => {
		dispatch(setActiveCameraId(cameraId))
		return () => {
			if (!cameraWasActive) dispatch(setActiveCameraId(null))
		}
	}, [cameraId, dispatch, cameraWasActive])

	const setActiveStep = (step: number) => {
		dispatch(setCameraCalibrationStep(step))
	}

	return (
		<>
			<FormHeader
				title={t('entity')}
				backRoute={`/${siteId}/installations/${installationId}/cameras`}
			/>
			{activeStep === 0 && (
				<DeviceConfiguration
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					bracketInstalled={bracketInstalled}
					setBracketInstalled={setBracketInstalled}
					siteId={siteId}
					installationId={installationId}
				/>
			)}
			{activeStep !== 0 && !bracketInstalled && (
				<DroneCalibration
					activeStep={activeStep}
					siteId={siteId}
					installationId={installationId}
					sensorId={cameraId}
				/>
			)}
			{activeStep !== 0 && bracketInstalled && (
				<BracketCalibration
					activeStep={activeStep}
					siteId={siteId}
					installationId={installationId}
					sensorId={cameraId}
				/>
			)}
		</>
	)
}

export default CameraCalibration
