import { memo, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTheme } from '@chakra-ui/react'
import { nanoid } from '@reduxjs/toolkit'
import {
	useGetSiteQuery,
	useGetSiteInstallationsQuery,
} from '@Store/sites/sitesApi'
import { useGetZonesQuery } from '@Store/zones/zonesApi'

import { selectUserZoneSettings } from '@Store/user/userSlice'
import {
	setZoneCoordinates,
	setIsZoneCoordinatesDirty,
} from '@Store/ui/uiSlice'
import { useAppSelector, useAppDispatch } from '@Store/index'

import EditControl from '@Components/MapControls/EditControl/EditControl'
import Polygon from '@Components/MapShapes/Polygon/PolygonNew'
import SiteMarker from '@Components/Markers/SiteMarker/SiteMarker'
import SentryMarker from '@Components/Markers/SentryMarker/SentryMarker'

import useCenterMap from './useCenterMap'
import { createNewDefaultZone } from '@Utils/zones'

const ZonePreview = () => {
	const dispatch = useAppDispatch()

	const {
		semanticTokens: { colors },
	} = useTheme()

	const { siteId: siteIdParam, zoneId: zoneIdParam } = useParams()
	const siteId = Number(siteIdParam)
	const zoneId = Number(zoneIdParam)

	const { siteLatitude, siteLongitude } = useGetSiteQuery(siteId, {
		selectFromResult: ({ data }) => ({
			siteLatitude: data?.latitude ?? 0,
			siteLongitude: data?.longitude ?? 0,
		}),
		skip: !siteId,
	})

	const { data: zones } = useGetZonesQuery(
		{ siteId },
		{
			skip: !siteId,
		}
	)

	const { data: installations } = useGetSiteInstallationsQuery(siteId, {
		skip: !siteId,
	})

	const zoneSettings = useAppSelector(selectUserZoneSettings)

	useCenterMap({
		latitude: siteLatitude,
		longitude: siteLongitude,
	})

	const isEditZone = !!zoneId
	const editedZone = (zones ?? []).find((zone) => zone.id === zoneId)

	const defaultCoordinates = isEditZone
		? editedZone?.coordinates
		: createNewDefaultZone(siteLatitude, siteLongitude)

	useEffect(() => {
		dispatch(setZoneCoordinates(defaultCoordinates))
		return () => {
			dispatch(setZoneCoordinates([]))
			dispatch(setIsZoneCoordinatesDirty(false))
		}
	}, [dispatch, defaultCoordinates])

	const displayedZones = (zones ?? []).filter((zone) =>
		zoneSettings?.visibleZoneTypes.includes(zone.zone_type)
	)
	// Statically display zones that are not being added or edited
	const staticZones = isEditZone
		? displayedZones.filter((zone) => zone.id !== zoneId)
		: displayedZones

	// Using 'any' here as type definitions for 'react-leaflet-draw' don't exist
	const onEditVertex = (e: any) => {
		const newCoordinates = e.poly._latlngs[0].map((point: L.LatLng) => [
			point.lat,
			point.lng,
		])
		dispatch(setZoneCoordinates(newCoordinates))
		dispatch(setIsZoneCoordinatesDirty(true))
	}

	return (
		<>
			<SiteMarker position={[siteLatitude, siteLongitude]} />
			{installations?.map(
				({ id, name, latitude, longitude, sentry_type, status_color }) => (
					<SentryMarker
						key={id}
						name={name}
						position={[latitude, longitude]}
						sentryType={sentry_type}
						iconColor={status_color}
					/>
				)
			)}
			{staticZones.map((zone) => (
				<Polygon
					key={zone.id}
					positions={zone.coordinates}
					fillColor='transparent'
					color={colors.zones[zone.zone_type]}
					dashArray={[14]}
					weight={2}
					testId={`zone-${zone.zone_type}`}
				/>
			))}
			<EditControl onEditVertex={onEditVertex}>
				<Polygon
					key={nanoid()}
					positions={defaultCoordinates || []}
					testId='zone-preview'
				/>
			</EditControl>
		</>
	)
}

export default memo(ZonePreview)
