// A read-only text input component, simply for showing values as per design
import { Box, Input as ChakraInput, FormLabel } from '@chakra-ui/react'

const Input = ({ label, value }: { label: string; value: string | number }) => (
	<Box>
		<FormLabel>{label}</FormLabel>
		<ChakraInput value={value} isReadOnly />
	</Box>
)

export default Input
