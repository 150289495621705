import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import Alert from '@UI/Alert/Alert'
import Button from '@UI/Button/Button'

import { useAppDispatch } from '@Store/index'
import { setCameraCalibrationStep } from '@Store/ui/uiSlice'
import { useCalibrateCameraMutation } from '@Store/cameras/camerasApi'

const LostDetectionAlert = () => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'siteInstallations.cameraCalibration',
	})

	const { sensorId: sensorIdParam, siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)
	const sensorId = Number(sensorIdParam)

	const dispatch = useAppDispatch()
	const [calibrateCamera] = useCalibrateCameraMutation()

	const handleConfirm = () => {
		calibrateCamera({ cameraId: sensorId, siteId, action: 'stop' })
		dispatch(setCameraCalibrationStep(1))
	}

	return (
		<Alert description={t('detectionLost')} status='error' variant='outline'>
			<Button
				label={t('buttons.confirm')}
				testId='confirm'
				width='95px'
				size='xs'
				onClick={handleConfirm}
				sx={{ color: 'error' }}
			/>
		</Alert>
	)
}

export default LostDetectionAlert
