// Base Redux Toolkit Query API
import { createSelector } from '@reduxjs/toolkit'
import { baseApi } from '../baseApi'
import { type CoreApiError, formatApiErrors } from '@Store/utils/errorUtils'

// Types
import type { Client } from '../types'
import type { ClientSchema } from '@Forms/ClientForm/ClientForm.schema'

// API Types
type ClientRequest = number | undefined
type ClientResponse = Client
type UpdateClientRequest = ClientSchema & { clientId: Client['id'] }
type DeleteClientRequest = { clientId: Client['id'] }

// Clients API endpoints
// Refer https://au-release.droneshield.xyz/api/docs#get-/api/clients

export const clientsApi = baseApi
	.enhanceEndpoints({
		addTagTypes: ['Client'],
	})
	.injectEndpoints({
		endpoints: (builder) => ({
			getClientsList: builder.query<Array<Client>, ClientRequest>({
				query: (clientId) => ({
					url: clientId ? `/api/clients/${clientId}` : '/api/clients',
				}),
				providesTags: ['Client'],
				keepUnusedDataFor: 0,
				transformResponse: (response: { clients: Client[] | null }) =>
					(response.clients ?? []).sort((a, b) => a.id - b.id),
			}),
			getClient: builder.query<ClientResponse, ClientRequest>({
				query: (clientId) => ({
					url: `/api/clients/${clientId}`,
					method: 'GET',
				}),
				providesTags: ['Client'],
				keepUnusedDataFor: 0,
				transformResponse: (response: { client: Client }) => response.client,
			}),
			createClient: builder.mutation<ClientResponse, Partial<Client>>({
				query: (client) => ({
					url: '/api/clients',
					method: 'POST',
					body: {
						client: { ...client },
					},
				}),
				transformResponse: (response: { client: Client }) => response.client,
				transformErrorResponse: (response: CoreApiError) =>
					formatApiErrors<Client>(response),
				invalidatesTags: ['Client'],
			}),
			updateClient: builder.mutation<Client, UpdateClientRequest>({
				query: ({ clientId, ...client }) => ({
					url: `/api/clients/${clientId}`,
					method: 'PATCH',
					body: {
						client,
					},
				}),
				transformResponse: (response: { client: Client }) => response.client,
				transformErrorResponse: (response: CoreApiError) =>
					formatApiErrors<Client>(response),
				invalidatesTags: ['Client'],
			}),
			deleteClient: builder.mutation<{ message: string }, DeleteClientRequest>({
				query: ({ clientId }) => ({
					url: `/api/clients/${clientId}`,
					method: 'DELETE',
				}),
				invalidatesTags: ['Client'],
			}),
		}),
	})

export const selectClientsOptions = createSelector(
	(data?: Client[]) => data,
	(data) => ({
		clientsOptions: (data ?? []).map((client) => ({
			value: client.id,
			label: client.name,
		})),
	})
)

export const selectClientsName = createSelector(
	(data?: Client) => data,
	(data) => (data ? data?.name : null)
)

export const {
	useGetClientsListQuery,
	useGetClientQuery,
	useCreateClientMutation,
	useUpdateClientMutation,
	useDeleteClientMutation,
} = clientsApi
