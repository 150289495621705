import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// Chakra
import {
	Card,
	CardHeader,
	CardBody,
	Spacer,
	useDisclosure,
} from '@chakra-ui/react'

import EditButton from '../../EditButton'
import DsxContent from './DsxContent'
import UpdateModal from '@UI/Modals/UpdateModal'

import { useRestartSiteRfSensorsMutation } from '@Store/rfSensors/rfSensorsApi'

import { type RfSensor, type SiteInstallation } from '@Store/types'

import useAuth from '@Hooks/useAuth'
import {
	selectIsTactical,
	useGetSystemInfoQuery,
} from '@Store/system/systemApi'

type DsxContentProps = {
	devices: RfSensor[]
	acceptLocationUpdates: SiteInstallation['accept_location_updates']
}

const DsxDevices = ({
	devices,
	acceptLocationUpdates = false,
}: DsxContentProps) => {
	const { isSiteManager } = useAuth()

	const { siteId: siteIdParam, installationId: installationIdParam } =
		useParams()
	const siteId = Number(siteIdParam)
	const installationId = Number(installationIdParam)

	const { t } = useTranslation('panels', {
		keyPrefix: 'devicesContent',
	})

	const baseRoute = `/${siteId}/installation/${installationId}`

	const { isOpen, onOpen, onClose } = useDisclosure()

	const [restartRfSensors] = useRestartSiteRfSensorsMutation()

	const handleRestart = async () => {
		try {
			await restartRfSensors({ siteId }).unwrap()
		} catch (error) {
			console.error('Restart DSX sensor status error', error)
		}
	}

	// C2T
	const { isTactical, isSuccess: isTacticalSuccess } = useGetSystemInfoQuery(
		undefined,
		{
			selectFromResult: ({ data, isSuccess }) => ({
				isTactical: selectIsTactical(data),
				isSuccess,
			}),
		}
	)

	return (
		<Card variant='devices'>
			{isSiteManager && (
				<CardHeader textAlign='right'>
					{isTacticalSuccess && !isTactical && (
						<EditButton
							onClick={onOpen}
							type='restart'
							label={t('buttons.restart')}
							testId='restart-all'
							disabled={devices.length < 1}
						/>
					)}
					<Spacer />
					<EditButton
						to={`${baseRoute}/rf_sensors_dsx/add`}
						type='add'
						label={t('buttons.add')}
						testId='add-dsx'
					/>
				</CardHeader>
			)}
			<CardBody>
				{devices.map((device) => (
					<DsxContent
						key={device.id}
						device={device}
						acceptLocationUpdates={acceptLocationUpdates}
						siteId={siteId}
						installationId={installationId}
					/>
				))}
			</CardBody>

			<UpdateModal
				isOpen={isOpen}
				onClose={onClose}
				handleDelete={handleRestart}
				name=''
				headerText={t('modals.restartDSXSensors')}
				modalBodyText={t('modals.cannotUndone')}
			/>
		</Card>
	)
}

export default DsxDevices
