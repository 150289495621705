import {
	ResponsiveScatterPlot,
	type ScatterPlotRawSerie,
	type ScatterPlotDatum,
	type ScatterPlotSvgProps,
} from '@nivo/scatterplot'

import { useTheme } from '@chakra-ui/react'
import useChartTheme from './useChartTheme'

type ScatterPlotProps = {
	data: ScatterPlotRawSerie<ScatterPlotDatum>[]
	yLabel?: string
	xLabel?: string
	layers?: any
	margin: ScatterPlotSvgProps<ScatterPlotDatum>['margin']
	xScale: ScatterPlotSvgProps<ScatterPlotDatum>['xScale']
	yScale: ScatterPlotSvgProps<ScatterPlotDatum>['yScale']
	xTickValues?: number[]
	yTickValues?: number[]
	enableGridY?: boolean
	nodeSize?: number
}

const ScatterPlot = ({
	data,
	xLabel = '',
	yLabel = '',
	layers,
	margin,
	xScale,
	yScale,
	xTickValues,
	yTickValues,
	enableGridY = true,
	nodeSize = 3,
}: ScatterPlotProps) => {
	const {
		semanticTokens: { colors },
	} = useTheme()

	const theme = useChartTheme()

	return (
		<ResponsiveScatterPlot
			data={data}
			theme={theme}
			layers={layers}
			colors={[colors.charts.line]}
			margin={margin}
			xScale={xScale}
			yScale={yScale}
			nodeSize={nodeSize}
			enableGridX={false}
			enableGridY={enableGridY}
			axisTop={null}
			axisRight={null}
			axisBottom={{
				tickSize: 5,
				tickPadding: 5,
				tickValues: xTickValues,
				tickRotation: 0,
				legend: `${xLabel}`,
				legendPosition: 'middle',
				legendOffset: 40,
			}}
			axisLeft={{
				tickSize: 5,
				tickPadding: 5,
				tickValues: yTickValues,
				tickRotation: 0,
				legend: `${yLabel}`,
				legendPosition: 'middle',
				legendOffset: -40,
			}}
			tooltip={() => <></>}
			animate={false}
		/>
	)
}

export default ScatterPlot
