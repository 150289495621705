import type { ReactElement } from 'react'
import { Button as ChakraButton, IconButton } from '@chakra-ui/react'
import Tooltip from '../Tooltip/Tooltip'
// TODO: Move To Tooltip folder and rename as TooltipIconButton
import type {
	ButtonProps as ChakraButtonProps,
	PlacementWithLogical,
} from '@chakra-ui/react'

type ButtonProps = {
	tooltip?: string
	tooltipPlacement?: PlacementWithLogical
	testId?: string
	label: string
	shape?: 'button' | 'icon'
	icon?: ReactElement
} & ChakraButtonProps

const Button = ({
	shape = 'button',
	tooltip,
	tooltipPlacement = 'bottom',
	variant = 'ghost',
	isDisabled,
	onClick,
	testId,
	leftIcon,
	size,
	label,
	icon,
	children,
	...rest
}: ButtonProps) => (
	<Tooltip label={tooltip} placement={tooltipPlacement}>
		{shape === 'button' ? (
			<ChakraButton
				variant={variant}
				data-testid={testId}
				isDisabled={isDisabled}
				onClick={onClick}
				leftIcon={leftIcon}
				size={size}
				{...rest}
			>
				{label}
				{children}
			</ChakraButton>
		) : (
			<IconButton
				aria-label={label}
				variant={variant}
				data-testid={testId}
				isDisabled={isDisabled}
				onClick={onClick}
				size={size}
				icon={icon}
				{...rest}
			/>
		)}
	</Tooltip>
)

export default Button
