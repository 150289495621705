import { useTranslation } from 'react-i18next'

import { Box, Flex, Text } from '@chakra-ui/react'

import { Chunk, FAULT_STATE, StatusDot } from '..'
import ActionSection from '../../ActionSection'
import ActiveSensorButton from '../../ActiveSensorButton'
import DsxBandStatus from './DsxBandStatus'
import DisruptionPanel from './DisruptionPanel'
import DsxMenu from './DsxMenu'
import GpsModuleError from '../GpsModuleError'
import { formatDirection } from '@Utils/formatUtils'

import { type RfSensor, type SiteInstallation } from '@Store/types'

import useAuth from '@Hooks/useAuth'

type DsxContentProps = {
	device: RfSensor
	acceptLocationUpdates: SiteInstallation['accept_location_updates']
	siteId: number
	installationId: number
}

const DsxContent = ({
	device,
	acceptLocationUpdates = false,
	siteId,
	installationId,
}: DsxContentProps) => {
	const { isSiteManager } = useAuth()

	const { t } = useTranslation('panels', {
		keyPrefix: 'devicesContent',
	})

	// Show GPS Error module when sensor direction receives 400
	const isGpsModuleError = (device: RfSensor) => {
		return acceptLocationUpdates ? device.direction === 400 : false
	}

	return (
		<>
			<Box key={device.id} paddingBlockEnd={4}>
				<Flex paddingInlineStart={2} marginBlockEnd={1}>
					<Box w={6}>
						<StatusDot color={device.status_color} type='dsx' id={device.id} />
					</Box>
					<Box>
						<Text fontWeight={500} data-testid={'device-name'}>
							{device.name}
						</Text>
						{!device.active && device.state !== FAULT_STATE && (
							<Text
								fontWeight={400}
								color='label_color'
								data-testid='detections-disabled'
							>
								{t('detectionDisabled')}
							</Text>
						)}
						{device.state === FAULT_STATE && (
							<Text
								fontWeight={400}
								color='label_color'
								data-testid='rf-sensor-faulty'
							>
								{t('rfSensorFaulty')}
							</Text>
						)}
					</Box>
					<ActionSection>
						<ActiveSensorButton deviceId={device.id} />
						{isSiteManager && (
							<DsxMenu
								device={device}
								siteId={siteId}
								installationId={installationId}
							/>
						)}
					</ActionSection>
				</Flex>
				{/* GPS module 400 error */}
				{isGpsModuleError(device) && <GpsModuleError />}
				<Flex flexWrap='wrap' paddingInlineStart={8} paddingBlockEnd={4}>
					<Chunk
						units='deg'
						label={t('content.direction')}
						value={formatDirection(device.direction) ?? '-'}
						testId='direction'
					/>
					<Chunk
						label={t('content.activeFilters')}
						value={
							(device.RfFilter ?? []).filter(
								(filter) => filter.active === true && filter.whitelist === false
							).length
						}
						testId='active-filters'
					/>
					<Chunk
						label={t('content.serial')}
						value={device.serial_number}
						testId='serial-number'
					/>
					{device.software_version && (
						<Chunk
							label={t('content.version')}
							value={device.software_version ?? '-'}
							testId='software-version'
						/>
					)}
					{device.connection_type !== 'v1' && (
						<Chunk
							label={t('content.detection_mode')}
							value={device.detection_mode ?? '-'}
							testId='detection-mode'
						/>
					)}
				</Flex>
				{device.model === 'dsx_direct' && (
					<DsxBandStatus
						id={device.id}
						dsx={device}
						testId='dsx-mk1-band-status'
					/>
				)}
				{device.model === 'dsx_mk2' && (
					<DisruptionPanel
						siteId={siteId}
						sensorId={device.id}
						testId='dsx-mk2-band-status'
					/>
				)}
			</Box>
		</>
	)
}

export default DsxContent
