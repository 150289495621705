import { useTranslation } from 'react-i18next'

import { useForm, FormProvider } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import type { TypeOf } from 'zod'

import Field from '@Components/FormElements'
import { FormButtons } from '@Components/FormElements/FormButtons/FormButtons'

import FormWrapper from '@Components/FormElements/FormWrapper/FormWrapper'

import { systemSettingsFormSchema } from './SystemSettingsForm.schema'

import {
	useGetSystemSettingsQuery,
	useUpdateSystemSettingsMutation,
} from '@Store/system/systemApi'

import useAuth from '@Hooks/useAuth'

import type { SystemSettings } from '@Store/types'

type SystemSettingsFormProps = {
	handleCancel: () => void
}

const SystemSettingsForm = ({ handleCancel }: SystemSettingsFormProps) => {
	const { t } = useTranslation('forms', { keyPrefix: 'systemSettingsForm' })

	const {
		isLoading,
		isError,
		isSuccess,
		refetch,
		data: systemSettings,
	} = useGetSystemSettingsQuery()

	return (
		<FormWrapper
			entity={t('entity')}
			isEditForm
			isLoading={isLoading}
			isError={isError}
			isSuccess={isSuccess}
			refetch={refetch}
		>
			{systemSettings && (
				<Form defaultValues={systemSettings} handleCancel={handleCancel} />
			)}
		</FormWrapper>
	)
}

const Form = ({
	defaultValues,
	handleCancel,
}: {
	defaultValues: SystemSettings
	handleCancel: () => void
}) => {
	const { t } = useTranslation('forms', { keyPrefix: 'systemSettingsForm' })

	const { isAdmin } = useAuth()

	const [updateSystemSettings] = useUpdateSystemSettingsMutation()

	type Schema = TypeOf<typeof systemSettingsFormSchema>

	const methods = useForm<Schema>({
		resolver: zodResolver(systemSettingsFormSchema),
		defaultValues,
	})

	const {
		register,
		formState: { errors, isSubmitting, isDirty },
		handleSubmit,
		setError,
	} = methods

	const handleSave = async (data: Schema) => {
		try {
			await updateSystemSettings({
				...defaultValues,
				...data,
				updated_at: new Date().toISOString(),
			}).unwrap()
			handleCancel()
		} catch (e: unknown) {
			const errors = e as { [name in keyof Schema]: string }
			for (const field in errors) {
				setError(field as keyof Schema, {
					type: 'custom',
					message: errors[field as keyof Schema] as string,
				})
			}
		}
	}

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(handleSave)}>
				<Field.Divider title={t('headings.networkSettings')} />
				<Field.TextInput
					title={t('staticIp')}
					register={register('static_ip')}
					error={errors?.static_ip?.message}
					testId='static-ip'
				/>
				<Field.TextInput
					title={t('subnetMask')}
					register={register('static_subnet_mask')}
					error={errors?.static_subnet_mask?.message}
					testId='static-subnet-mask'
				/>
				<Field.TextInput
					title={t('ntpUrl')}
					register={register('ntp_url')}
					error={errors?.ntp_url?.message}
					testId='ntp-url'
				/>
				{isAdmin && (
					<>
						<Field.Divider title={t('headings.display')} />
						<Field.Select
							title={t('logo')}
							register={register('logo')}
							options={t('logoOptions', { returnObjects: true })}
							error={errors?.logo?.message}
							testId='logo'
						/>
					</>
				)}
				<FormButtons
					isSubmitting={isSubmitting}
					isDirty={isDirty}
					handleCancel={handleCancel}
				/>
			</form>
		</FormProvider>
	)
}

export default SystemSettingsForm
