import { IconButton } from '@chakra-ui/react'
import { RiDragMove2Line } from 'react-icons/ri'

const DragButton = () => {
	const isFullscreen = document.fullscreenElement ? true : false

	return (
		<>
			{!isFullscreen && (
				<IconButton
					icon={<RiDragMove2Line size={20} />}
					variant='unstyled'
					aria-label='drag camera panel'
					color='camera.control_color'
					cursor='move'
				/>
			)}
		</>
	)
}

export default DragButton
