// React
import { useParams } from 'react-router-dom'
// Components
import { DsxSector, RfSensorSector } from '@Components/Sectors'
import SentryMarker from '@Components/Markers/SentryMarker/SentryMarker'
import CompassMarker from '@Components/Markers/CompassMarker/CompassMarker'
import RfFilterSector from '@Components/Sectors/RfFilterSector/RfFilterSector'
import DsxFilterSector from '@Components/Sectors/DsxFilterSector/DsxFilterSector'
import Detections from '@Components/Site/MapLayers/Detections'
// Redux
import { useGetSiteInstallationQuery } from '@Store/sites/sitesApi'
import { useGetRfSensorFiltersQuery } from '@Store/rfFilters/rfFiltersApi'
import { useAppSelector } from '@/store'
import { selectSensorPreview } from '@Store/ui/uiSlice'
// Types
import type {
	RfSensor_Models,
	RfFiltersPreview as RfFiltersPreviewType,
} from '@Store/types'
// Hooks
import useCenterMap from './useCenterMap'

const RfFilterPreview = () => {
	const {
		siteId: siteIdParam,
		sensorId: sensorIdParam,
		filterId: filterIdParam,
	} = useParams()
	const siteId = Number(siteIdParam)
	const sensorId = Number(sensorIdParam)
	const filterId = Number(filterIdParam)

	// Sensor details
	const sensorPreview = useAppSelector(
		selectSensorPreview
	) as RfFiltersPreviewType
	const installationId = sensorPreview?.sentryId

	// Installation details
	const { isSuccess, installation } = useGetSiteInstallationQuery(
		{ siteId, installationId: installationId as number },
		{
			skip: !sensorPreview || !installationId || !siteId,
			selectFromResult: ({ data, isSuccess }) => ({
				isSuccess,
				installation: data,
			}),
		}
	)
	// All filters
	const { data: rfFilters, isSuccess: isFilterSuccess } =
		useGetRfSensorFiltersQuery({
			siteId,
			sensorId,
		})
	const remainingFilters = rfFilters?.filter((filter) => filter.id !== filterId)
	const isDsx = sensorPreview?.model?.includes('dsx')

	// Temporarily center the map
	useCenterMap({
		latitude: installation?.latitude,
		longitude: installation?.longitude,
	})

	if (isSuccess && installation && sensorPreview) {
		const sensorBearing =
			(installation.direction + sensorPreview.directionOffset) % 360
		const filterBearing = sensorBearing + (sensorPreview?.angle ?? 0)
		const filterAngle = 2 * (sensorPreview?.angle_tolerance ?? 0)

		return (
			<>
				<Detections siteId={siteId} />
				<CompassMarker
					position={[installation.latitude, installation.longitude]}
					bearing={sensorBearing}
				/>
				<SentryMarker
					name={installation.name}
					position={[installation.latitude, installation.longitude]}
					sentryType={installation.sentry_type}
					iconColor={installation.status_color}
				/>

				{isDsx && (
					<>
						<DsxSector
							latitude={installation.latitude}
							longitude={installation.longitude}
							reach={sensorPreview.reach}
							isActive={false}
						/>
						<DsxFilterSector
							latitude={installation.latitude}
							longitude={installation.longitude}
							reach={sensorPreview.reach}
							bearing={sensorBearing}
							angle={sensorPreview?.angle}
							tolerance={sensorPreview?.angle_tolerance}
							isActive
						/>
						{/* Display the rest of the DSX filters */}
						{isFilterSuccess &&
							remainingFilters?.length &&
							remainingFilters?.map((filter) => {
								return (
									<DsxFilterSector
										key={filter.id}
										latitude={installation.latitude}
										longitude={installation.longitude}
										reach={sensorPreview.reach}
										tolerance={filter.bearing_tolerance}
										bearing={sensorBearing}
										angle={filter.bearing}
										isActive={false}
										isDisabled
									/>
								)
							})}
					</>
				)}
				{!isDsx && (
					<>
						<RfSensorSector
							model={sensorPreview.model as RfSensor_Models}
							latitude={installation.latitude}
							longitude={installation.longitude}
							bearing={sensorBearing}
							reach={sensorPreview.reach}
							isActive={false}
						/>
						{filterBearing !== 0 && filterAngle !== 0 && (
							<RfFilterSector
								latitude={installation.latitude}
								longitude={installation.longitude}
								reach={sensorPreview.reach}
								bearing={filterBearing}
								angle={filterAngle}
								isActive
							/>
						)}
					</>
				)}
			</>
		)
	} else return null
}

export default RfFilterPreview
