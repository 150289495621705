import { FREQUENCY_LIMITS } from '@Constants/sensorFilters'

export const findFrequencyBand = (
	number: number | undefined
): string | null => {
	if (number === undefined) return 'MHz'

	for (const band in FREQUENCY_LIMITS) {
		if (Object.prototype.hasOwnProperty.call(FREQUENCY_LIMITS, band)) {
			const { min, max } = FREQUENCY_LIMITS[band]
			if (number >= min && number <= max) {
				return `${band}MHz`
			}
		}
	}

	return 'MHz'
}
