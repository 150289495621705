import { Box, Flex, Text } from '@chakra-ui/react'
import Map from '@Components/Map/Map'
import { useTranslation } from 'react-i18next'

const NoSite = () => {
	const { t } = useTranslation('app', { keyPrefix: 'global.messages.noSite' })
	return (
		<Flex w='100%' h='100%' position='relative'>
			<Box
				position='absolute'
				top={2}
				left={2}
				bottom={2}
				width='495px'
				padding={4}
				zIndex={450}
				bgColor='panel_bg'
			>
				<Text
					color='label_color'
					fontWeight='bold'
					textTransform='uppercase'
					marginBlockEnd={6}
				>
					{t('heading')}
				</Text>
				<Text color='label_color' fontSize='sm'>
					{t('description')}
				</Text>
			</Box>
			<Map
				center={[0, 0]}
				scrollWheelZoom={false}
				showZoomControl={false}
				showCenterMapButton={false}
				showScreenshotTool={false}
			/>
		</Flex>
	)
}

export default NoSite
