import { isAnyOf } from '@reduxjs/toolkit'
import type { AppStartListening } from '@Store/middleware'

import { authApi } from '@Store/auth/authApi'
import { usersApi } from '@Store/user/usersApi'
import { systemApi } from '@Store/system/systemApi'
import { logout } from '@Store/auth/authSlice'

import { systemStatsPollingIntervalSecs } from '@Constants/api'

let systemStatsPollingTimer: ReturnType<typeof setInterval> | null = null

const addSystemStatsMiddleware = (startListening: AppStartListening) => {
	// Upon successful login subscribe to the System Stats endpoint
	startListening({
		matcher: isAnyOf(
			authApi.endpoints.login.matchFulfilled, // login event
			usersApi.endpoints.getUser.matchFulfilled // browser reload event
		),
		effect: async (_, { dispatch }) => {
			if (systemStatsPollingTimer) clearInterval(systemStatsPollingTimer)
			systemStatsPollingTimer = setInterval(() => {
				dispatch(
					systemApi.endpoints.getSystemStats.initiate(undefined, {
						forceRefetch: true,
					})
				)
			}, systemStatsPollingIntervalSecs * 1000)
		},
	})
	// On logout, cancel it
	startListening({
		matcher: isAnyOf(logout.fulfilled),
		effect: () => {
			if (systemStatsPollingTimer) clearInterval(systemStatsPollingTimer)
		},
	})
}

// Exports
export { addSystemStatsMiddleware }
