import { useTranslation } from 'react-i18next'
import { Box } from '@chakra-ui/react'

import {
	useGetSiteQuery,
	selectMapCenter,
	useGetSiteInstallationsQuery,
} from '@Store/sites/sitesApi'

import Map from '@Components/Map/Map'
import FetchError from '@UI/FetchError/FetchError'
import Skeleton from '@UI/Skeleton/Skeleton'
import { type LatLngExpression } from 'leaflet'

import { useGetTrackSummaryQuery } from '@Store/analytics/analyticsApi'

import SentryMarker from '@Components/Markers/SentryMarker/SentryMarker'
import { useEventFilter } from './EventDetailsTable/EventFilterContext'

import { DEFAULT_PARAMS } from './EventDetails'

import { HeatmapLayerFactory } from '@vgrid/react-leaflet-heatmap-layer'
const HeatmapLayer = HeatmapLayerFactory<number[]>()

type HeatMapProps = {
	startTime: string
	endTime: string
	siteId: number
}

const HeatMap = ({ siteId, startTime, endTime }: HeatMapProps) => {
	const { t } = useTranslation('pages', {
		keyPrefix: 'analytics.trackSummary',
	})

	const { data: installations } = useGetSiteInstallationsQuery(siteId)

	const { mapCenter } = useGetSiteQuery(siteId, {
		selectFromResult: ({ data }) => ({
			mapCenter: selectMapCenter(data),
		}),
	})

	const { filterParams } = useEventFilter()

	const { isSuccess, isLoading, isError, refetch, isFetching, events } =
		useGetTrackSummaryQuery(
			{
				siteId,
				format: 'locations',
				startTime: startTime,
				endTime: endTime,
				...DEFAULT_PARAMS,
				...filterParams,
			},
			{
				selectFromResult: ({
					isSuccess,
					isLoading,
					isError,
					isFetching,
					data,
				}) => ({
					isSuccess,
					isLoading,
					isError,
					isFetching,
					events: data?.locations ?? [],
				}),
			}
		)

	return (
		<Skeleton isLoaded={!isLoading && !isFetching}>
			<Box h='558px'>
				{isSuccess && mapCenter && (
					<Map center={mapCenter as LatLngExpression}>
						{installations?.map(
							({ id, name, latitude, longitude, sentry_type }) => (
								<SentryMarker
									key={id}
									name={name}
									position={[latitude, longitude]}
									sentryType={sentry_type}
									iconColor='green'
								/>
							)
						)}
						<HeatmapLayer
							fitBoundsOnLoad
							points={events}
							longitudeExtractor={(m) => m[1]}
							latitudeExtractor={(m) => m[0]}
							intensityExtractor={(m) => m[2]}
							max={1}
							radius={6}
							blur={8}
						/>
					</Map>
				)}
				{isError && (
					<FetchError refetch={refetch} entity={t('entities.heatMap')} />
				)}
			</Box>
		</Skeleton>
	)
}

export default HeatMap
