// React
import { useTranslation } from 'react-i18next'
import useAuth from '@/hooks/useAuth'
// Chakra
import {
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
} from '@chakra-ui/react'
import { MdAdd, MdEdit } from 'react-icons/md'

// Schema
import { ADD_USER_DEFAULT_VALUES, type UserSchema } from './UserForm.schema'
// Redux
import { useGetUserQuery } from '@Store/user/usersApi'
// Components
import Button from '@UI/Button/Button'
import UserFormAdd from '@Forms/UserForm/UserFormAdd'
import UserFormEdit from '@Forms/UserForm/UserFormEdit'
import FetchError from '@UI/FetchError/FetchError'
import {
	selectClientsOptions,
	useGetClientsListQuery,
} from '@Store/clients/clientsApi'
import type { FormSelectValueType } from '@Components/FormElements/Select/Select'
import { useAppSelector } from '@/store'
import { selectUserClientId } from '@Store/user/userSlice'

const UserForm = ({
	userId,
	onClose,
}: {
	userId: number
	onClose: () => void
}) => {
	const isEditForm = !!userId
	const { t } = useTranslation('forms', { keyPrefix: 'userForm' })

	const {
		data: user,
		isError,
		refetch,
	} = useGetUserQuery(userId, {
		refetchOnMountOrArgChange: true,
		skip: !userId,
	})

	// Default values for Admin and Manager
	const client_id = useAppSelector(selectUserClientId)
	const addUserDefaultValues = {
		...ADD_USER_DEFAULT_VALUES,
		client_id,
	} as UserSchema

	const { isAdmin } = useAuth()
	// Clients options for Admin role
	const { clientsOptions, clientsOptionsReady } = useGetClientsListQuery(
		undefined,
		{
			skip: !isAdmin && !client_id,
			selectFromResult: ({ isSuccess, data }) => ({
				...selectClientsOptions(data),
				clientsOptionsReady: isSuccess,
			}),
		}
	)
	// Roles options for Admin role

	// User select options
	const roleOptions = isAdmin ? t('api.role', { returnObjects: true }) : []

	const defaultValues = isEditForm ? user : addUserDefaultValues

	return (
		<>
			{isError && (
				<FetchError refetch={refetch} errorMessage={t('status.error')} />
			)}
			{defaultValues && (
				<>
					{isEditForm ? (
						<UserFormEdit
							defaultValues={defaultValues}
							onClose={onClose}
							userId={userId}
							refetch={refetch}
							clientsOptions={clientsOptions}
							clientsOptionsReady={clientsOptionsReady}
							roleOptions={roleOptions as FormSelectValueType[]}
						/>
					) : (
						<UserFormAdd
							defaultValues={defaultValues}
							onClose={onClose}
							clientsOptions={clientsOptions}
							clientsOptionsReady={clientsOptionsReady}
							roleOptions={roleOptions as FormSelectValueType[]}
						/>
					)}
				</>
			)}
		</>
	)
}

const UserFormModal = ({
	userId,
	userName,
	isActive = true,
}: {
	userId: number
	userName?: string
	isActive?: boolean
}) => {
	const { t } = useTranslation('forms', { keyPrefix: 'userForm' })
	const { isOpen, onOpen, onClose } = useDisclosure()
	const isEditModal = !!userId

	return (
		<>
			{isEditModal ? (
				<Button
					onClick={onOpen}
					testId={`edit-user-${userName}`}
					label={t('buttons.editUser')}
					tooltip={t('buttons.editUser')}
					icon={<MdEdit />}
					shape='icon'
					variant='actions'
					isDisabled={!isActive}
				/>
			) : (
				<Button
					onClick={onOpen}
					testId={'add-user'}
					label={t('buttons.addUser')}
					leftIcon={<MdAdd />}
					variant='ghost'
				/>
			)}
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				isCentered
				closeOnOverlayClick={false}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						<Flex alignItems='center' gap='8px'>
							{t(isEditModal ? 'headings.editUser' : 'headings.createUser')}
						</Flex>
					</ModalHeader>
					<ModalCloseButton data-testid='close' />
					<ModalBody mb={4}>
						<UserForm userId={userId} onClose={onClose} />
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	)
}

export { UserForm, UserFormModal }
