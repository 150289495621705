// React
import { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { nanoid } from '@reduxjs/toolkit'
// Chakra
import {
	Box,
	Flex,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Tab,
} from '@chakra-ui/react'
// Components
import FetchError from '@UI/FetchError/FetchError'
import Headings from '@UI/Headings/Headings'
import { StatusDot } from '@Components/Site/Panels/SiteInstallations/DevicesContent'
import EditButton from '@Components/Site/Panels/SiteInstallations/EditButton'
import RfFiltersOverview from '@Components/Site/Panels/SiteInstallations/RfFilters/RfFiltersOverview/RfFiltersOverview'
// Forms
import FormHeader from '@Forms/FormHeader'
import FormWrapper from '@Components/FormElements/FormWrapper/FormWrapper'
import RfFiltersTable from './RfFiltersTable'
import RfFiltersTableModal from './Modals/RfFiltersTableModal'
// Store
import { useAppDispatch } from '@/store'
import { useGetRfSensorQuery } from '@Store/rfSensors/rfSensorsApi'
import { useGetRfSensorFiltersQuery } from '@Store/rfFilters/rfFiltersApi'
import { updateSensorPreview } from '@Store/ui/uiSlice'
import {
	useGetSiteLiveQuery,
	selectIsSiteCalibrationMode,
} from '@Store/sites/sitesWsApi'

const RfFilters = () => {
	const { sensorId: sensorIdParam, siteId: siteIdParam } = useParams()
	const location = useLocation()
	const navigate = useNavigate()
	const sensorId = Number(sensorIdParam)
	const siteId = Number(siteIdParam)
	// Translations
	const { t } = useTranslation('panels', { keyPrefix: 'rfFilters' })
	const { t: tSensor } = useTranslation('forms', { keyPrefix: 'rfSensorForm' })
	// Store
	const {
		data: sensor,
		isError: isSensorError,
		isLoading: isSensorLoading,
		isSuccess: isSensorSuccess,
		refetch: sensorRefetch,
	} = useGetRfSensorQuery({ siteId, sensorId }, { skip: !siteId || !sensorId })
	const {
		isLoading,
		isError,
		isSuccess,
		refetch,
		data: rf_filters,
	} = useGetRfSensorFiltersQuery({
		siteId,
		sensorId,
	})

	const { isCalibrationMode } = useGetSiteLiveQuery(siteId, {
		selectFromResult: ({ data }) => ({
			isCalibrationMode: selectIsSiteCalibrationMode(data),
		}),
	})

	const isDsx = sensor?.model.includes('dsx')

	const isNoSupportedDetections = !sensor?.supported_detections

	const whiteListedRfFilters =
		rf_filters?.filter((rf_filter) => rf_filter.whitelist) || []

	const notWhiteListedRfFilters =
		rf_filters?.filter((rf_filter) => !rf_filter.whitelist) || []

	const activeWhiteListedRfFilters = whiteListedRfFilters.filter(
		(filter) => filter.active
	)

	const activeNotWhiteListedRfFilters = notWhiteListedRfFilters.filter(
		(filter) => filter.active
	)

	const addFilterUrl = `/${siteId}/${
		isDsx ? 'rf_sensors_dsx' : 'rf_sensors'
	}/${sensorId}/rf_filters/add`
	const backRoute = `/${siteId}/installations`

	const handleAddFilter = (isWhiteListed = false) => {
		navigate(`${addFilterUrl}${isWhiteListed ? '?whitelist' : ''}`)
	}

	const getTooltip = () => {
		if (isNoSupportedDetections) return t('noSupportedDetections')
		else if (!isCalibrationMode) return t('table.calibration')
	}

	const dispatch = useAppDispatch()
	useEffect(() => {
		dispatch(
			updateSensorPreview({
				sentryId: sensor?.sentry_id,
				sensorType: 'rfFilters',
				reach: sensor?.reach,
				directionOffset: sensor?.direction_offset,
				sensorId: sensor?.id,
				model: sensor?.model,
			})
		)
		return () => {
			dispatch(updateSensorPreview(null))
		}
	}, [dispatch, sensor])

	return (
		<>
			{isSensorError && (
				<FetchError
					errorMessage={tSensor('fetchStatus.error')}
					refetch={sensorRefetch}
				/>
			)}

			<FormWrapper
				entity={t('entity')}
				isEditForm
				isSuccess={isSuccess && isSensorSuccess}
				isLoading={isLoading || isSensorLoading}
				isError={isError}
				errorMessage={t('fetchStatus.error')}
				refetch={refetch}
			>
				<FormHeader title={t('headers.rfFilters')} backRoute={backRoute} />
				{sensor?.name && (
					<Flex gap={2} mb={4}>
						<Flex mt={5}>
							<StatusDot color={sensor.status_color} />
						</Flex>
						<Headings.SectionSubheading title={sensor?.name} />
					</Flex>
				)}
				<Box className={`RfFilters-${sensorId}`}>
					<Tabs defaultIndex={location.hash === '#whitelist_tab' ? 1 : 0}>
						<TabList>
							<Tab onClick={() => navigate('#filter_tab')}>
								{t('headers.rfFilters')}
							</Tab>
							<Tab onClick={() => navigate('#whitelist_tab')}>
								{t('headers.whitelists')}
							</Tab>
						</TabList>

						<TabPanels>
							<TabPanel px={0} key={nanoid()}>
								{!!notWhiteListedRfFilters.length && (
									<RfFiltersOverview
										isWhiteListed={false}
										filters={activeNotWhiteListedRfFilters}
									/>
								)}
								<Flex justifyContent='space-between'>
									{/* TODO: Replace with UI_modals */}
									<RfFiltersTableModal rf_filters={notWhiteListedRfFilters} />
									<EditButton
										label={t('addNewFilter')}
										type='add'
										onClick={() => handleAddFilter()}
										disabled={isNoSupportedDetections || !isCalibrationMode}
										tooltip={getTooltip()}
										testId='add-filter'
									/>
								</Flex>
								{notWhiteListedRfFilters.length ? (
									<RfFiltersTable
										rf_filters={notWhiteListedRfFilters}
										variant='collapsed'
										isDsx={isDsx}
									/>
								) : (
									<Box>{t('noFilters')}</Box>
								)}
							</TabPanel>

							<TabPanel px={0} key={nanoid()}>
								{!!whiteListedRfFilters.length && (
									<RfFiltersOverview
										isWhiteListed={true}
										filters={activeWhiteListedRfFilters}
									/>
								)}

								<Flex justifyContent='space-between'>
									<RfFiltersTableModal
										rf_filters={whiteListedRfFilters}
										isWhiteListed
									/>
									<EditButton
										label={t('addNewWhitelist')}
										type='add'
										onClick={() => handleAddFilter(true)}
										disabled={isNoSupportedDetections || !isCalibrationMode}
										tooltip={getTooltip()}
										testId='add-whitelist'
									/>
								</Flex>
								{whiteListedRfFilters.length ? (
									<RfFiltersTable
										rf_filters={whiteListedRfFilters}
										variant='collapsed'
										isWhiteListed
									/>
								) : (
									<Box>{t('noFilters')}</Box>
								)}
							</TabPanel>
						</TabPanels>
					</Tabs>
				</Box>
			</FormWrapper>
		</>
	)
}

export default RfFilters
