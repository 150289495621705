import { Box, Text } from '@chakra-ui/react'

const MapLegendSection = ({
	children,
	title,
}: {
	children: React.ReactNode
	title: string
}) => (
	<Box fontSize='sm' p={2}>
		<Text as='h2' pb={4} pt={4}>
			{title}
		</Text>
		<Box color='white'>{children}</Box>
	</Box>
)

export default MapLegendSection
