// Base Redux Toolkit Query API
import { createSelector } from '@reduxjs/toolkit'
import { baseApi } from '../baseApi'
import { sitesWsApi } from './sitesWsApi'
import { type CoreApiError, formatApiErrors } from '@Store/utils/errorUtils'

// Types
import type {
	Site,
	SiteMode,
	SiteAlertSettings,
	SiteExport,
	SiteInstallation,
	SiteInstallationExport,
	SiteInstallationLocator,
} from '../types'

type GetSitesListRequest = void
type GetSiteRequest = Site['id']
type CreateSiteRequest = Partial<Site>
type DeleteSiteRequest = Site['id']
type UpdateSiteRequest = Pick<Site, 'id'> & Partial<Site>
type UpdateSiteAlertSettingsRequest = Pick<Site, 'id'> & SiteAlertSettings
type SiteModeRequest = { siteId: number; mode: SiteMode }
type SiteAutoJamRequest = { siteId: number; autoJam: boolean }
// TODO: Installations request/response types?

const transformSiteResponse = (response: { site: Site }) => ({
	...response.site,
	prob_uav_filter: response.site.prob_uav_filter * 100,
})

// Sites API endpoints
// Refer https://au-release.droneshield.xyz/api/docs#get-/api/sites
export const sitesApi = baseApi
	.enhanceEndpoints({ addTagTypes: ['Sites'] })
	.injectEndpoints({
		endpoints: (builder) => ({
			getSitesList: builder.query<
				Array<Pick<Site, 'id' | 'name' | 'client_id'>>,
				GetSitesListRequest
			>({
				query: () => ({
					url: '/api/sites',
				}),
				providesTags: ['Sites'],
				transformResponse: (response: { sites: Site[] }) =>
					response.sites
						.map((site: Site) => ({
							id: site.id,
							name: site.name,
							client_id: site.client_id,
						}))
						.sort((a, b) => a.id - b.id),
			}),
			getSite: builder.query<Site, GetSiteRequest>({
				query: (siteId) => ({
					url: `/api/sites/${siteId}`,
				}),
				providesTags: ['Sites'],
				transformResponse: transformSiteResponse,
				keepUnusedDataFor: 0,
			}),
			// Includes all installations, sensors, etc.
			getSiteExport: builder.query<SiteExport, SiteExport['id']>({
				query: (siteId) => ({
					url: `/api/sites/${siteId}/export`,
				}),
				keepUnusedDataFor: 0,
			}),
			createSite: builder.mutation<Site, CreateSiteRequest>({
				query: (site) => ({
					url: '/api/sites',
					method: 'POST',
					body: {
						site: {
							...site,
							prob_uav_filter: (site.prob_uav_filter ?? 0) / 100,
						},
					},
				}),
				transformResponse: transformSiteResponse,
				transformErrorResponse: (response: CoreApiError) =>
					formatApiErrors<Site>(response),
				invalidatesTags: ['Sites'],
			}),
			deleteSite: builder.mutation<{ message: string }, DeleteSiteRequest>({
				query: (siteId) => ({
					url: `/api/sites/${siteId}`,
					method: 'DELETE',
				}),
				invalidatesTags: ['Sites'],
				async onQueryStarted(siteId, { dispatch, queryFulfilled }) {
					// Optimistic update
					// https://redux-toolkit.js.org/rtk-query/usage/manual-cache-updates#optimistic-updates
					const result = dispatch(
						sitesApi.util.updateQueryData(
							'getSitesList',
							undefined,
							(draft) => {
								return draft.filter((site) => site.id !== siteId)
							}
						)
					)
					try {
						await queryFulfilled
					} catch {
						result.undo()
					}
				},
			}),
			updateSite: builder.mutation<Site, UpdateSiteRequest>({
				query: ({ id: siteId, ...put }) => ({
					url: `/api/sites/${siteId}`,
					method: 'PUT',
					body: {
						site: { ...put, prob_uav_filter: (put.prob_uav_filter ?? 0) / 100 },
					},
				}),
				transformResponse: transformSiteResponse,
				transformErrorResponse: (response: CoreApiError) =>
					formatApiErrors<Site>(response),
				invalidatesTags: ['Sites'],
				async onQueryStarted(
					{ id: siteId, ...patch },
					{ dispatch, queryFulfilled }
				) {
					// Pessimistic update
					// https://redux-toolkit.js.org/rtk-query/usage/manual-cache-updates#pessimistic-updates
					try {
						const { data } = await queryFulfilled
						dispatch(
							sitesApi.util.updateQueryData(
								'getSite',
								siteId,
								(draft: Site) => {
									Object.assign(draft, data)
								}
							)
						)
						dispatch(
							sitesApi.util.updateQueryData(
								'getSitesList',
								undefined,
								(draft) => {
									const index = draft.findIndex((site) => site.id === siteId)
									if (index !== -1 && patch.name) draft[index].name = patch.name
									if (index !== -1 && patch.client_id)
										draft[index].client_id = patch.client_id
								}
							)
						)
					} catch {
						// given this is a pessimistic update, error handling is superfluous
					}
				},
			}),
			updateSiteAlertSettings: builder.mutation<
				Site,
				UpdateSiteAlertSettingsRequest
			>({
				query: ({ id: siteId, ...patch }) => ({
					url: `/api/sites/${siteId}/alert_settings`,
					method: 'PATCH',
					body: {
						site_alert_settings: patch,
					},
				}),
				invalidatesTags: ['Sites'],
				transformResponse: transformSiteResponse,
				transformErrorResponse: (response: CoreApiError) =>
					formatApiErrors<Site>(response),
			}),
			updateSiteInstallation: builder.mutation<
				SiteInstallation,
				Partial<SiteInstallation> &
					Pick<SiteInstallation, 'id'> & { siteId: number }
			>({
				query: ({ id: installationId, siteId, ...patch }) => ({
					url: `/api/sites/${siteId}/sentries/${installationId}`,
					method: 'PATCH',
					body: {
						sentry: { ...patch, site_id: siteId },
					},
				}),
				transformResponse: (response: { sentry: SiteInstallation }) =>
					response.sentry,
				transformErrorResponse: (response: CoreApiError) =>
					formatApiErrors<SiteInstallation>(response),
			}),
			updateSiteMode: builder.mutation<Site, SiteModeRequest>({
				query: ({ siteId, mode }) => ({
					url: `/api/sites/${siteId}`,
					method: 'PATCH',
					body: {
						mode,
					},
				}),
				async onQueryStarted({ siteId, mode }, { dispatch, queryFulfilled }) {
					// Optimistic update
					// https://redux-toolkit.js.org/rtk-query/usage/manual-cache-updates#optimistic-updates
					const result = dispatch(
						sitesWsApi.util.updateQueryData('getSiteLive', siteId, (draft) => {
							if (draft) draft.mode = mode
						})
					)
					try {
						await queryFulfilled
					} catch {
						result.undo()
					}
				},
			}),
			// Note: consider using same mutation for site mode & autojam?
			updateSiteAutoJam: builder.mutation<Site, SiteAutoJamRequest>({
				query: ({ siteId, autoJam }) => ({
					url: `/api/sites/${siteId}`,
					method: 'PATCH',
					body: {
						auto_jam: autoJam,
					},
				}),
				async onQueryStarted(
					{ siteId, autoJam },
					{ dispatch, queryFulfilled }
				) {
					const result = dispatch(
						sitesWsApi.util.updateQueryData('getSiteLive', siteId, (draft) => {
							if (draft) draft.auto_jam = autoJam
						})
					)
					try {
						await queryFulfilled
					} catch {
						result.undo()
					}
				},
			}),
			createSiteInstallation: builder.mutation<
				SiteInstallation,
				Partial<SiteInstallation> &
					Pick<SiteInstallation, 'id'> & { siteId: number }
			>({
				query: ({ siteId, ...post }) => ({
					url: `/api/sites/${siteId}/sentries`,
					method: 'POST',
					body: {
						sentry: { ...post, site_id: siteId },
					},
				}),
				transformResponse: (response: { sentry: SiteInstallation }) =>
					response.sentry,
				transformErrorResponse: (response: CoreApiError) =>
					formatApiErrors<SiteInstallation>(response),
			}),
			getSiteInstallations: builder.query<Array<SiteInstallation>, number>({
				query: (siteId) => ({
					url: `/api/sites/${siteId}/sentries`,
				}),
				transformResponse: (response: { sentries: SiteInstallation[] }) =>
					response.sentries,
			}),
			deleteSiteInstallation: builder.mutation<
				{ message: string },
				Partial<SiteInstallation> &
					Pick<SiteInstallation, 'id'> & { siteId: number }
			>({
				query: ({ siteId, id }) => ({
					url: `/api/sites/${siteId}/sentries/${id}`,
					method: 'DELETE',
				}),
			}),
			getSiteInstallation: builder.query<
				SiteInstallation,
				{
					siteId: number
					installationId: number
				}
			>({
				query: ({ siteId, installationId }) => ({
					url: `/api/sites/${siteId}/sentries/${installationId}`,
				}),
				transformResponse: (response: { sentry: SiteInstallation }) =>
					response.sentry,
				keepUnusedDataFor: 0,
			}),
			getSiteInstallationLocators: builder.query<
				Array<SiteInstallationLocator>,
				{
					siteId: number
					installationId: number
				}
			>({
				query: ({ siteId, installationId }) => ({
					url: `/api/sites/${siteId}/sentries/${installationId}/locators`,
				}),
				transformResponse: (response: {
					locators: SiteInstallationLocator[]
				}) => response.locators,
			}),
			startSimulation: builder.mutation<
				{ message: string },
				{ siteId: number }
			>({
				query: ({ siteId }) => ({
					url: `/api/sites/${siteId}/simulation`,
					method: 'POST',
				}),
			}),
			// Using this endpoint for polling
			updateHeartbeat: builder.query<{ message: string }, { siteId: number }>({
				query: ({ siteId }) => ({
					url: `/api/sites/${siteId}/heartbeats`,
					method: 'PATCH',
				}),
			}),
		}),
	})

export const selectFirstSiteId = createSelector(
	(data?: Array<Pick<Site, 'id' | 'name'>>) => data,
	(sites) => sites?.[0]?.id ?? null
)

export const selectSiteAlertSettings = createSelector(
	(data?: Site) => data,
	(data) =>
		({
			zone_trigger_probability: data?.zone_trigger_probability,
			zone_trigger_detection_count: data?.zone_trigger_detection_count,
			alert_suppression_time_window: data?.alert_suppression_time_window,
			trigger_sensors_offline_alerts: data?.trigger_sensors_offline_alerts,
			minimum_display_probability: data?.minimum_display_probability,
		}) as SiteAlertSettings
)

export const selectSiteInstallationLocatorOptions = createSelector(
	(data?: SiteInstallation[]) => data,
	(data) => ({
		locatorOptions: (data ?? []).map((installation) => ({
			value: installation.id,
			label: installation.name,
		})),
	})
)

export const selectSiteInstallationLocatorSensorOptions = createSelector(
	(data?: SiteInstallationLocator[]) => data,
	(data) => ({
		locatorOptions: (data ?? []).map((sensor) => ({
			label: sensor.name,
			value: sensor,
		})),
	})
)

export const selectInstallationsOptions = createSelector(
	(data?: SiteInstallation[]) => data,
	(data) => ({
		installationsOptions: (data ?? []).map((installation) => ({
			value: installation.id,
			label: installation.name,
		})),
	})
)

const emptyInstallationsResult: SiteInstallationExport[] = []

export const selectSiteExport = createSelector(
	(data?: SiteExport) => data,
	(data) => data
)

export const selectSiteExportInstallations = createSelector(
	(data?: SiteExport) => data,
	(data) =>
		(data?.sentries ?? emptyInstallationsResult).map((installation) => ({
			...installation,
		}))
)

export const selectSiteExportRadars = createSelector(
	[selectSiteExportInstallations],
	(data) =>
		(data ?? emptyInstallationsResult).flatMap(
			(installation: SiteInstallationExport) => installation.radars || []
		)
)

export const selectSiteExportHasRadar = createSelector(
	selectSiteExportRadars,
	(radars) => radars.length > 0
)

export const selectSiteExportCameras = createSelector(
	[selectSiteExportInstallations],
	(data) =>
		(data ?? emptyInstallationsResult).flatMap(
			(installation: SiteInstallationExport) => installation.cameras || []
		)
)

export const selectSiteExportHasCamera = createSelector(
	selectSiteExportCameras,
	(cameras) => cameras.length > 0
)

export const selectSiteExportDisruptors = createSelector(
	(data?: SiteExport) => data,
	(data) =>
		(data?.sentries ?? emptyInstallationsResult).flatMap(
			(installation: SiteInstallationExport) =>
				installation?.disruptors?.filter(
					(disruptor) => !disruptor.cannon_type.includes('DSX')
				) || []
		)
)

export const selectSiteExportRfSensors = createSelector(
	(data?: SiteExport) => data,
	(data) =>
		(data?.sentries ?? emptyInstallationsResult).flatMap(
			(installation: SiteInstallationExport) => installation.rf_sensors || []
		)
)

export const selectSiteExportHasRfSensor = createSelector(
	selectSiteExportRfSensors,
	(rfSensors) => rfSensors.length > 0
)

export const selectSiteExportDsx = createSelector(
	selectSiteExportRfSensors,
	(rfSensors) =>
		rfSensors.filter((sensor) => sensor.model.includes('dsx')) || []
)

export const selectSiteExportJammingDsx = createSelector(
	selectSiteExportRfSensors,
	(rfSensors) =>
		rfSensors.filter((sensor) =>
			['dsx_direct', 'dsx_mk2'].includes(sensor.model)
		) || []
)

export const selectSiteExportAllDisruptors = createSelector(
	[selectSiteExportJammingDsx, selectSiteExportDisruptors],
	(rfSensorsJammingDsx, disruptors) => [...rfSensorsJammingDsx, ...disruptors]
)

type AvailableSector = {
	name: string
	value: string
}

export const selectMapCenter = createSelector(
	(data?: Site) => data,
	(site) => {
		if (site) {
			const mapCenter = [site?.latitude, site?.longitude]

			if (
				site.map_center_latitude !== undefined &&
				site.map_center_longitude !== undefined &&
				site.map_center_latitude !== null &&
				site.map_center_longitude !== null
			) {
				mapCenter[0] = site.map_center_latitude
				mapCenter[1] = site.map_center_longitude
			}

			return mapCenter
		}
	}
)

// Logic taken directly from vue
export const selectAvailableSectors = createSelector(
	[
		selectSiteExportRfSensors,
		selectSiteExportCameras,
		selectSiteExportDisruptors,
		selectSiteExportRadars,
		selectSiteExportDsx,
	],
	(rfSensors, cameras, disruptors, radars) => {
		const sectors: AvailableSector[] = []
		if (rfSensors.length > 0) {
			if (rfSensors.some((rf) => rf.model === 'rf_one')) {
				sectors.push({ name: 'RFOne', value: 'rf_one' })
			}
			if (rfSensors.some((rf) => rf.model === 'rf_patrol')) {
				sectors.push({ name: 'RfPatrol', value: 'rf_patrol' })
			}
			const intersectionRf = rfSensors.filter(
				(rf) => rf.model === 'rf_one' || rf.model.includes('dsx')
			)
			if (intersectionRf.length > 1)
				sectors.push({ name: 'RF Intersection', value: 'rf_intersection' })
		}

		if (cameras.length > 0) {
			sectors.push({ name: 'DroneOpt', value: 'cameras' })
		}
		if (radars.length > 0) {
			sectors.push({ name: 'Radar', value: 'radar' })
		}
		if (disruptors.length > 0) {
			sectors.push({ name: 'DroneCannon', value: 'cannon' })
		}
		if (rfSensors.filter((s) => s.model.includes('dsx')).length > 0) {
			sectors.push({ name: 'DroneSentry-X', value: 'dronesentryx' })
		}
		return sectors
	}
)

export const selectConcentricMarkerOptions = createSelector(
	[selectSiteExport, selectSiteExportInstallations],
	(site, installations) => {
		if (!site) return []
		return [
			{
				label: 'None',
				value: {
					name: '',
					coordinates: null,
				},
			},
			{
				label: `Site (${site.name})`,
				value: {
					name: `Site (${site.name})`,
					coordinates: [site.latitude, site.longitude],
				},
			},
			...installations
				.filter(
					(installation) =>
						installation.latitude !== site.latitude &&
						installation.longitude !== site.longitude
				)
				.map((installation) => ({
					label: `Installation (${installation.name})`,
					value: {
						name: `Installation (${installation.name})`,
						coordinates: [installation.latitude, installation.longitude],
					},
				})),
		]
	}
)

export const selectSiteMapCenter = createSelector(
	(data?: Site) => data,
	(data) => ({
		lat: data?.latitude,
		lng: data?.longitude,
	})
)

export const selectSiteTimeZone = createSelector(
	(data?: Site) => data,
	(data) => data?.timezone
)

export const {
	useGetSitesListQuery,
	useCreateSiteMutation,
	useDeleteSiteMutation,
	useGetSiteQuery,
	useGetSiteExportQuery,
	useGetSiteInstallationQuery,
	useGetSiteInstallationsQuery,
	useGetSiteInstallationLocatorsQuery,
	useUpdateSiteMutation,
	useUpdateSiteAlertSettingsMutation,
	useUpdateSiteModeMutation,
	useUpdateSiteAutoJamMutation,
	useDeleteSiteInstallationMutation,
	useCreateSiteInstallationMutation,
	useUpdateSiteInstallationMutation,
	useStartSimulationMutation,
	useUpdateHeartbeatQuery,
} = sitesApi
