import { useTheme } from '@chakra-ui/react'
import type { BarDatum, BarLegendProps, BarSvgProps } from '@nivo/bar'
import type { AxisProps } from '@nivo/axes'
import { ResponsiveBar } from '@nivo/bar'

import useChartTheme from './useChartTheme'

type BarChartProps = {
	data: BarDatum[]
	indexBy: string
	keys: string[]
	includeLegend?: boolean
	margin: BarSvgProps<BarDatum>['margin']
	isLabelTilted?: boolean
	legendX?: string
	legendY?: string
}

const BarChart = ({
	data,
	keys,
	indexBy,
	margin,
	includeLegend,
	isLabelTilted,
	legendX,
	legendY,
}: BarChartProps) => {
	const {
		semanticTokens: { colors },
	} = useTheme()

	const theme = useChartTheme()

	const hasData = data.length > 0

	const axisBottom: AxisProps = legendX
		? {
				legend: legendX,
				legendOffset: 48,
				legendPosition: 'middle',
			}
		: {}

	const axisLeft: AxisProps = legendY
		? {
				legend: legendY,
				legendOffset: -42,
				legendPosition: 'middle',
			}
		: {}

	const legends: BarLegendProps[] = [
		{
			dataFrom: 'keys',
			anchor: 'top',
			direction: 'row',
			translateX: 20,
			translateY: -30,
			itemsSpacing: 10,
			itemWidth: 120,
			itemHeight: 18,
			symbolSize: 10,
			symbolShape: 'circle',
		},
	]

	return (
		<ResponsiveBar
			data={data}
			theme={theme}
			keys={keys}
			indexBy={indexBy}
			enableLabel={false}
			margin={margin}
			padding={0.3}
			valueScale={{ type: 'linear', min: 0, max: hasData ? 'auto' : 25 }}
			indexScale={{ type: 'band', round: true }}
			colors={colors.charts.bar}
			labelSkipWidth={12}
			labelSkipHeight={12}
			legends={includeLegend ? legends : undefined}
			axisBottom={{
				...axisBottom,
				tickRotation: isLabelTilted ? 15 : undefined,
			}}
			axisLeft={{
				...axisLeft,
			}}
		/>
	)
}

export default BarChart
