import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { formatInTimeZone } from 'date-fns-tz'
// When using date-fns, time zone names work best when a
// locale is provided - unsure how this will scale...
import enAU from 'date-fns/locale/en-AU'
import { Flex, Text } from '@chakra-ui/react'
import {
	useGetSiteLiveQuery,
	selectSiteTimezone,
} from '@Store/sites/sitesWsApi'
import { skipToken } from '@reduxjs/toolkit/query'

const Clock = () => {
	const { siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)
	const [date, setDate] = useState(new Date())

	useEffect(() => {
		const timerId = setInterval(() => setDate(new Date()), 1000)
		return () => clearInterval(timerId)
	}, [])

	const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

	// Site Time Zone
	const { isSuccess: isSiteSuccess, siteTimeZone } = useGetSiteLiveQuery(
		siteId > 0 ? siteId : skipToken,
		{
			selectFromResult: ({ isSuccess, data }) => ({
				isSuccess,
				siteTimeZone: selectSiteTimezone(data),
			}),
		}
	)

	const timeZone = isSiteSuccess && siteTimeZone ? siteTimeZone : userTimeZone
	const formatDate = (formatString: string) =>
		formatInTimeZone(date, timeZone, formatString, {
			locale: enAU,
		})

	return (
		<Flex
			direction='column'
			fontSize='14px'
			textAlign='right'
			data-testid='clock'
		>
			<Flex gap={1} lineHeight='16px'>
				<Text>{formatDate('HH:mm:ss')}</Text>
				<Text>{formatDate('zzz')}</Text>
			</Flex>
			<Text lineHeight='16px'>{formatDate('yyyy-MM-dd')}</Text>
		</Flex>
	)
}

export default Clock
