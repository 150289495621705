import FIGMA from '../_FIGMA'

// 200 is default in Dark mode, 500 is default in Light modes
export const colors = {
	...FIGMA.colorSchemas,
	primary: {
		200: FIGMA.colors.primary,
		500: FIGMA.colors.primary,
	},
	error: {
		200: FIGMA.colors.error,
		300: FIGMA.colors.error,
		500: FIGMA.colors.error,
	},
	success: {
		200: FIGMA.colors.success,
		500: FIGMA.colors.success,
	},
	warning: {
		200: FIGMA.colors.warning,
		500: FIGMA.colors.warning,
	},
}
