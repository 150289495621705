// React
import { useMemo } from 'react'
// Chakra
import { Box, Icon, Text } from '@chakra-ui/react'
import { MdFiberManualRecord } from 'react-icons/md'
// Components
import type { DevicesList } from '../Installation'
import CameraContent from './CameraContent'
import DisruptorContent from './DisruptorContent/DisruptorContent'
import DsxDevices from './DsxDevices/DsxDevices'
import RadarContent from './RadarContent'
import RfDevices from './RfDevices/RfDevices'
import GpsCompassContent from './GpsCompassContent'
// Store
import { sensorStatusColorMap } from '@Store/types'
import type { SiteInstallation, StatusColor } from '@Store/types'
// Utils
import { openInNewTab } from '@Utils/navigation'
import { formatDegToMils } from '@Utils/formatUtils'
// Hooks
import useUnits from '@Hooks/useUnits'
import type { Units } from '@Components/FormElements/types/Units'
import ErrorBoundary from '@Components/App/ErrorHandling/ErrorBoundary'

type DevicesContentProps = {
	installationDirection: SiteInstallation['direction']
	sensorType: keyof DevicesList
	acceptLocationUpdates: SiteInstallation['accept_location_updates']
	devices:
		| SiteInstallation['rf_sensors']
		| SiteInstallation['radars']
		| SiteInstallation['cameras']
		| SiteInstallation['disruptors']
		| SiteInstallation['gps_compasses']
}

export const FAULT_STATE = 'FAULT'

const DevicesContent = ({
	sensorType,
	devices,
	acceptLocationUpdates = false,
}: DevicesContentProps) => {
	switch (sensorType) {
		case 'cameras':
			return (
				<ErrorBoundary>
					<CameraContent devices={devices as SiteInstallation['cameras']} />
				</ErrorBoundary>
			)
		case 'disruptors':
			return (
				<ErrorBoundary>
					<DisruptorContent
						devices={devices as SiteInstallation['disruptors']}
					/>
				</ErrorBoundary>
			)
		case 'radars':
			return (
				<ErrorBoundary>
					<RadarContent devices={devices as SiteInstallation['radars']} />
				</ErrorBoundary>
			)
		case 'rf_sensors':
			return (
				<ErrorBoundary>
					<RfDevices
						devices={devices as SiteInstallation['rf_sensors']}
						acceptLocationUpdates={acceptLocationUpdates}
					/>
				</ErrorBoundary>
			)
		case 'rf_sensors_dsx':
			return (
				<ErrorBoundary>
					<DsxDevices
						devices={devices as SiteInstallation['rf_sensors']}
						acceptLocationUpdates={acceptLocationUpdates}
					/>
				</ErrorBoundary>
			)
		case 'gps_compasses':
			return (
				<ErrorBoundary>
					<GpsCompassContent
						devices={devices as SiteInstallation['gps_compasses']}
					/>
				</ErrorBoundary>
			)
		default:
			return <p>Unknown error</p>
	}
}

export const handleOpenDeviceManager = (
	connectionType: string,
	serialNumber: string,
	deviceManagerIp?: string
) => {
	if (connectionType === 'v2_direct') {
		openInNewTab(`http://${deviceManagerIp}`)
	} else {
		openInNewTab(`http://${deviceManagerIp}/sensor/${serialNumber}`)
	}
}

export const Chunk = ({
	label,
	value = '-',
	testId,
	units,
}: {
	label: string
	value: string | number
	testId?: string
	units?: Units
}) => {
	const { unit, isMils } = useUnits(units)

	const displayedUnit = useMemo(() => {
		if (units && unit) {
			if (value === '-' || value === '') return null
			if (value === 0) return unit
			return unit
		}
		return null
	}, [unit, value, units])

	const displayedValue = useMemo(() => {
		if (value === '' || value === '-') return '-'
		if (isMils) {
			if (value === 0) return value ? formatDegToMils(+value) : value
			return formatDegToMils(+value)
		}
		return value
	}, [value, isMils])

	let displayedLabel = label
	if (displayedUnit) {
		displayedLabel += ` (${displayedUnit})`
	}

	return (
		<Box width='50%' paddingBlockEnd={2}>
			<Text fontSize='sm' color='label_color'>
				{displayedLabel}
			</Text>
			<Text fontSize='md' color='white' data-testid={testId}>
				{displayedValue}
			</Text>
		</Box>
	)
}

export const StatusDot = ({
	color = 'red',
	size = '16px',
	type,
	id,
}: {
	color: StatusColor
	size?: string
	type?: string
	id?: number
}) => {
	return (
		<Icon
			as={MdFiberManualRecord}
			color={sensorStatusColorMap[color]}
			boxSize={size}
			data-testid={`${type}-${id}-status-color-${color}`}
		/>
	)
}

export default DevicesContent
