import { useRef, useEffect } from 'react'
import { EditControl as LeafletEditControl } from 'react-leaflet-draw'

import { FeatureGroup } from 'react-leaflet'

import type { EditControlProps as LeafletEditControlProps } from 'react-leaflet-draw'
import 'leaflet-draw-drag'
import 'leaflet-draw/dist/leaflet.draw.css'

import './EditControl.css'

type EditControlProps = Pick<LeafletEditControlProps, 'onEditVertex'> & {
	children: React.ReactNode
}

const selectedPathOptions = {
	edit: {
		selectedPathOptions: {
			fillColor: 'var(--c2-colors-zones-editMode)',
			fillOpacity: 0.5,
			stroke: false,
		},
	},
}

const drawOptions = {
	marker: false,
	circle: false,
	circlemarker: false,
	rectangle: false,
	polygon: false,
	polyline: false,
}

// NOTE: Using 'any' here as type definitions for 'react-leaflet-draw' don't exist
const EditControl = ({ onEditVertex, children }: EditControlProps) => {
	const editControlRef = useRef<any>(null)

	// Sets the edit state on mount. This ensures the user does not need to
	// click the edit button on the leaflet-draw toolbar to add/edit zones on the map
	useEffect(() => {
		if (!editControlRef.current) return
		editControlRef.current._toolbars.edit._modes.edit.handler.enable()
	}, [editControlRef])

	const onMounted = (event: any) => {
		editControlRef.current = event
	}

	return (
		<FeatureGroup>
			<LeafletEditControl
				position='topleft'
				edit={selectedPathOptions}
				draw={drawOptions}
				onMounted={onMounted}
				onEditVertex={onEditVertex}
			/>
			{children}
		</FeatureGroup>
	)
}

export default EditControl
