import { useTranslation } from 'react-i18next'
import DeleteModal from '@UI/Modals/DeleteModal'
import { MdDelete } from 'react-icons/md'
import { IconButton, useDisclosure } from '@chakra-ui/react'
import { useDeleteNotificationRecipientMutation } from '@Store/notifications/notificationRecipientsApi'
// Store
import { addToast } from '@Store/ui/uiSlice'
import { useAppDispatch } from '@/store'
import type { CoreApiError } from '@Store/utils/errorUtils'

const AlertRecipientDelete = ({
	siteId,
	recipientId,
	name,
	testId,
}: {
	siteId: number
	recipientId: number
	name: string
	testId?: string
}) => {
	const { t } = useTranslation('pages', {
		keyPrefix: 'alertSettings.alertRecipientsTable',
	})
	const dispatch = useAppDispatch()

	const { isOpen, onOpen, onClose } = useDisclosure()

	const [deleteRecipient] = useDeleteNotificationRecipientMutation()

	const handleDeleteRecipient = async () => {
		try {
			await deleteRecipient({
				siteId,
				id: recipientId,
			}).unwrap()
			onClose()
		} catch (error: unknown) {
			dispatch(
				addToast({
					type: 'error',
					title: t('status.error'),
					description: t('status.errorDelete'),
					siteId,
					error,
					id: `recipient-${(error as CoreApiError).status}`,
				})
			)
			throw new Error(t('status.errorDelete'))
		}
	}

	return (
		<>
			<IconButton
				onClick={onOpen}
				icon={<MdDelete />}
				aria-label={t('actions.deleteRecipient')}
				title={t('actions.deleteRecipient')}
				variant='actions'
				data-testid={testId}
			/>
			<DeleteModal
				isOpen={isOpen}
				onClose={onClose}
				handleDelete={handleDeleteRecipient}
				name={name}
				headerText={t('actions.deleteRecipient')}
				fromText={t('modals.deleteFrom')}
			/>
		</>
	)
}

export default AlertRecipientDelete
