// Redux
import { createSlice } from '@reduxjs/toolkit'

// Types
import type { RootState } from '@Store/index'

// Initial State
// TODO: Move cameras store from UI slice https://droneshield.atlassian.net/browse/C2-9352
type InitialState = {
	cameraPosition: { x: number; y: number }
}

const initialState: InitialState = {
	cameraPosition: { x: 0, y: 0 },
}

// UI slice
export const camerasSlice = createSlice({
	name: 'cameras',
	initialState: initialState,
	reducers: {
		setCameraPosition: (state, { payload }) => {
			state.cameraPosition = payload
		},
	},
})

// Selectors
export const selectCameraPosition = (state: RootState) => {
	return state.cameras.cameraPosition
}

// Reducer slice
export default camerasSlice.reducer

// Actions
export const { setCameraPosition } = camerasSlice.actions
