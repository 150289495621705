import { useEffect, memo } from 'react'
import { useLeafletContext } from '@react-leaflet/core'
import type { FC, SVGProps } from 'react'
import type { CircleProps, PathProps } from '@react-leaflet/core'
import L, { type LatLngExpression } from 'leaflet'
// SEMICIRCLE DOCS https://github.com/jieter/Leaflet-semicircle
import 'leaflet-semicircle'

// TODO: extend with Provided methods if necessary
// https://github.com/jieter/Leaflet-semicircle/blob/master/README.md
type SemiCircleProps = {
	startAngle: number
	stopAngle: number
	latitude: number
	longitude: number
	className?: string
	radius?: number
	id?: string
	onClick?: () => void
	onMouseDown?: () => void
	testId?: string
} & Omit<CircleProps, 'center'> &
	PathProps &
	SVGProps<any>

const SemiCircle: FC<SemiCircleProps> = ({
	latitude,
	longitude,
	id,
	className = undefined,
	testId,
	onClick,
	onMouseDown,
	...options
}) => {
	const context = useLeafletContext()

	// Prevent leaflect-semicircle rendering undefined as 'undefined' string
	if (options.fillColor === undefined) delete options['fillColor']
	if (options.fillOpacity === undefined) delete options['fillOpacity']
	options.interactive =
		typeof onClick === 'function' || typeof onMouseDown === 'function'

	const center: LatLngExpression = [latitude, longitude]

	useEffect(() => {
		const semiCircle = new (L as any).semiCircle(center, options)
		const container = context.layerContainer || context.map
		if (typeof onClick === 'function') semiCircle.on('click', onClick)
		if (typeof onMouseDown === 'function')
			semiCircle.on('mousedown', onMouseDown)
		container.addLayer(semiCircle)
		// Add attributes to the semicircle element
		const semiCircleElement = semiCircle.getElement()
		if (semiCircleElement) {
			if (testId) semiCircleElement.dataset.testid = testId
			if (className) semiCircleElement.classList.add(className)
			if (id) semiCircleElement.id = id
		}
		return () => {
			container.removeLayer(semiCircle)
		}
	})
	return null
}

export default memo(SemiCircle)
