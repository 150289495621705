export type FrequencyKeys =
	| '433'
	| '868'
	| '915'
	| '1200'
	| '2400'
	| '5200'
	| '5800'

export const DEFAULT_FREQUENCY_BAND = '2400MHz'
export const DEFAULT_VENDOR = 'DJI SKY XIA'

// TODO: Clarify default values of FREQUENCY for 445, 868, 915, 1200, 5200
export const DEFAULT_FREQUENCY: { [key: string]: number } = {
	'433': 437480000,
	'868': 862985000,
	'915': 919480000,
	'1200': 1078255000,
	'2400': 2439975000,
	'5200': 5224485000,
	'5800': 5645000000,
}

// TODO: Clarify default values of FREQUENCY_TOLERANCE for 445, 868, 915, 1200, 5200
export const DEFAULT_FREQUENCY_TOLERANCE = {
	'433': 4080000,
	'868': 2682000,
	'915': 7680000,
	'1200': 29994000,
	'2400': 30678000,
	'5200': 52284000,
	'5800': 57295000,
}

export const FREQUENCY_LIMITS: { [key: string]: { min: number; max: number } } =
	{
		433: {
			min: 400000000,
			max: 470000000,
		},
		868: { min: 860000000, max: 930000000 },
		1200: { min: 1003270000, max: 1153240000 },
		2400: {
			min: 2370000000,
			max: 2510000000,
		},
		5200: { min: 5100000000, max: 5644900000 },
		5800: { min: 5645000000, max: 5945000000 },
	}
