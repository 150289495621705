import type { ReactNode } from 'react'
import { Flex, Button, Icon, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

import {
	MdWifiTethering as RadarIcon,
	MdCamera as CameraIcon,
	MdBolt as DisruptorIcon,
	MdCompassCalibration as DsxIcon,
	MdOutlineGpsNotFixed as GpsCompassIcon,
} from 'react-icons/md'
import { VscRadioTower as RfIcon } from 'react-icons/vsc'

import type { SensorStatusColorFigma, SensorType } from '@Store/types'

const icons = {
	rf_sensors: {
		icon: RfIcon,
		sx: { transform: 'translateX(-0.5px)' },
	},
	rf_sensors_dsx: {
		icon: DsxIcon,
		sx: { transform: 'translateX(-0.5px)' },
	},
	radars: {
		icon: RadarIcon,
		sx: { transform: 'translateY(-1px)' },
	},
	cameras: {
		icon: CameraIcon,
		sx: undefined,
	},
	disruptors: {
		icon: DisruptorIcon,
		sx: undefined,
	},
	gps_compasses: {
		icon: GpsCompassIcon,
		sx: undefined,
	},
}

// TODO: locales
const labels = {
	rf_sensors: 'RF',
	rf_sensors_dsx: 'DroneSentry-X',
	radars: 'Radar',
	cameras: 'Optical',
	disruptors: 'Effector',
	gps_compasses: 'Gps Compass',
}

const iconSize = '24px'

type DeviceButtonProps = {
	active: boolean
	count: number
	to: string
	type: SensorType
	color: SensorStatusColorFigma | 'magenta'
}

const DeviceButton = ({
	active,
	color = 'magenta',
	count,
	to,
	type,
}: DeviceButtonProps) => {
	const navigate = useNavigate()
	const handleClick = () => {
		navigate(to, { replace: true })
	}
	const icon = icons[type as keyof typeof icons]
	const label = labels[type as keyof typeof labels]

	return (
		<Button
			w='calc(50% - 0.5px)'
			h='72px'
			variant='sensor'
			isActive={active}
			onClick={handleClick}
			data-testid={`device-button-${type}`}
		>
			<StatusRing type={type} color={count > 0 ? color : 'transparent'}>
				<Icon as={icon.icon} boxSize={iconSize} color='white' sx={icon.sx} />
			</StatusRing>
			<Text color='button_text'>
				{label} ({count})
			</Text>
		</Button>
	)
}

const StatusRing = ({
	color,
	type,
	children,
}: {
	color: string
	type: string
	children: ReactNode
}) => (
	<Flex
		w='40px'
		h='40px'
		borderWidth={3}
		borderStyle='solid'
		borderColor={color}
		borderRadius='50%'
		backgroundColor='transparent'
		alignItems='center'
		justifyContent='center'
		marginInlineEnd={2}
		data-testid={`${type}-group-status-color-${color}`}
	>
		{children}
	</Flex>
)

export default DeviceButton
