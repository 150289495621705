import { Flex, Box } from '@chakra-ui/react'
import type { Site, SiteMode } from '@/store/types'

import LeftPanel from './LeftPanel'
import RightPanel from './RightPanel'
import ModeAlert from './ModeAlert/ModeAlert'
import Cameras from './Cameras/Cameras'

const OPERATIONAL_SITE_MODE = 'operational'

type PanelsProps = {
	siteId: Site['id']
	siteMode?: SiteMode
}

const Panels = ({ siteId, siteMode }: PanelsProps) => {
	const showAlert = siteMode !== undefined && siteMode !== OPERATIONAL_SITE_MODE
	return (
		<>
			<Flex
				className='Panels'
				top='0.5rem'
				left='0.5rem'
				bottom='0.5rem'
				right='0.5rem'
				position='absolute'
				zIndex={1000}
				pointerEvents='none'
			>
				<LeftPanel />
				<Flex direction='column' flexGrow={1}>
					{showAlert && (
						<Box className='AlertsPanel' px={2} marginBlockEnd='0.5rem'>
							<ModeAlert siteId={siteId} mode={siteMode} />
						</Box>
					)}
					<Cameras />
				</Flex>
				<RightPanel />
			</Flex>
		</>
	)
}

export default Panels
