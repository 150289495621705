import BandStatus from '../BandStatus'

import type { RfSensor } from '@Store/types'
import type { Band } from '../BandStatus'

type DsxBandStatusProps = {
	id: number
	dsx: RfSensor
	testId?: string
}

const bands: Band[] = [
	{ label: '2.4 5.8', value: '24_58' },
	{ label: 'GNSS', value: 'gnss' },
]

const getDsxBandsStatus = (dsx: RfSensor) => ({
	gnss: dsx.gnss_trigger_engaged,
	'24_58': dsx.band_24_58_trigger_engaged,
})

const DsxBandStatus = ({ id, dsx, testId }: DsxBandStatusProps) => {
	const dsxStatus = getDsxBandsStatus(dsx)

	return (
		<BandStatus
			bands={bands}
			bandStatus={dsxStatus}
			id={id}
			gap='138px'
			testId={testId}
		/>
	)
}

export default DsxBandStatus
