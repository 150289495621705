import { memo, useEffect, useState } from 'react'
import { FeatureGroup, useMap } from 'react-leaflet'
import MapLegend from './MapLegend/MapLegend'
import MeasurementTool from './MeasurementTool/MeasurementTool'
import useMeasurementToolActive from './MeasurementTool/useMeasurementToolActive'

const MapLegendMeasurementWrapper = ({
	showMeasurementTools,
	showMapLegend,
}: {
	showMeasurementTools: boolean
	showMapLegend: boolean
}) => {
	const isMeasureActive = useMeasurementToolActive()
	const [isMapLegendActive, setIsMapLegendActive] = useState(false)

	const map = useMap()
	const mapContainer = map.getContainer()
	const measurementTool = mapContainer.querySelector(
		'.c2-measure_button--create'
	) as HTMLElement

	const onMapLegendClick = () => {
		// Toggle map legend
		setIsMapLegendActive(!isMapLegendActive)
		// Execute click to close measurementTool and clean its statement
		// unfortunately there is no click event on measure tool
		if (isMeasureActive) {
			measurementTool.click()
		}
	}

	useEffect(() => {
		if (isMeasureActive) {
			// Clicking on measurement tool, closing MapLegend
			setIsMapLegendActive(false)
		}
	}, [isMeasureActive])

	return (
		<FeatureGroup>
			{showMeasurementTools && <MeasurementTool />}
			{showMapLegend && (
				<MapLegend onClick={onMapLegendClick} isActive={isMapLegendActive} />
			)}
		</FeatureGroup>
	)
}

export default memo(MapLegendMeasurementWrapper)
