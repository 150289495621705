import { Box } from '@chakra-ui/react'

type Props = {
	value: number
	thumbIndex: number
	thumbProps: any
	onKeyDownStepBy: (
		e: React.KeyboardEvent<HTMLDivElement>,
		thumbIndex: number
	) => void
	bgColor: string
	testId: string
}

const FilterThumb = ({
	bgColor,
	thumbIndex,
	onKeyDownStepBy,
	testId,
	thumbProps,
}: Props) => {
	return (
		<Box
			key={thumbIndex}
			onKeyDown={(e) => onKeyDownStepBy(e, thumbIndex)}
			top='3%'
			w='6px'
			h='12px'
			bgColor={bgColor}
			_focusVisible={{
				outline: 'none',
			}}
			{...thumbProps}
			data-testid={testId}
		/>
	)
}
export default FilterThumb
