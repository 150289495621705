// React
import { useTranslation } from 'react-i18next'
// Form
import { useForm, FormProvider } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import type { TypeOf } from 'zod'

// Chakra
import {
	Button,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
} from '@chakra-ui/react'
import { MdAdd, MdEdit } from 'react-icons/md'

// Schema
import {
	clientFormSchema,
	ADD_CLIENT_DEFAULT_VALUES,
} from './ClientForm.schema'
import {
	useCreateClientMutation,
	useGetClientQuery,
	useUpdateClientMutation,
} from '@Store/clients/clientsApi'
// Redux
import type { Client } from '@Store/types'

// Components
// import ClientFormSites from '@Forms/ClientForm/ClientFormSites'
import Field from '@Components/FormElements'
import { FormButtons } from '@Components/FormElements/FormButtons/FormButtons'
import IconButton from '@UI/IconButton/IconButton'
import FormWrapper from '@Components/FormElements/FormWrapper/FormWrapper'

const ClientForm = ({
	clientId,
	onClose,
}: {
	clientId: number
	onClose: () => void
}) => {
	const { t } = useTranslation('forms', { keyPrefix: 'clientForm' })
	const isEditForm = !!clientId
	const {
		isLoading,
		isError,
		isSuccess,
		refetch,
		data: client,
	} = useGetClientQuery(clientId, {
		refetchOnMountOrArgChange: true,
		skip: !clientId,
	})

	const addClientDefaultValues = {
		...ADD_CLIENT_DEFAULT_VALUES,
	} as Client

	const defaultValues = isEditForm ? client : addClientDefaultValues

	return (
		<FormWrapper
			entity={t('entity')}
			isEditForm={isEditForm}
			isLoading={isLoading}
			isError={isError}
			isSuccess={isSuccess}
			refetch={refetch}
		>
			{defaultValues && (
				<Form
					defaultValues={defaultValues}
					isEditForm={isEditForm}
					onClose={onClose}
					clientId={clientId}
				/>
			)}
		</FormWrapper>
	)
}

const Form = ({
	defaultValues,
	isEditForm,
	onClose,
	clientId,
}: {
	defaultValues: Client
	isEditForm: boolean
	onClose: () => void
	clientId: number
}) => {
	type Schema = TypeOf<typeof clientFormSchema>
	const { t } = useTranslation('forms', { keyPrefix: 'clientForm' })
	const methods = useForm<Schema>({
		resolver: zodResolver(clientFormSchema),
		defaultValues,
	})
	const {
		register,
		formState: { errors, isSubmitting, isDirty },
		handleSubmit,
		setError,
	} = methods

	const [createClient] = useCreateClientMutation()
	const [updateClient] = useUpdateClientMutation()

	const handleSave = async (payload: Schema) => {
		try {
			if (isEditForm) {
				await updateClient({ ...payload, clientId: Number(clientId) }).unwrap()
			} else {
				await createClient({ ...payload }).unwrap()
			}
			onClose()
		} catch (errors: unknown) {
			// Surface server-side validation errors to react-hook-form
			for (const field in errors as { [name in keyof Schema]: string }) {
				setError(field as keyof Schema, {
					type: 'custom',
					message: (errors as { [name in keyof Schema]: string })[
						field as keyof Schema
					] as string,
				})
			}
		}
	}

	const handleCancel = () => onClose()

	return (
		<>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(handleSave)}>
					<Field.TextInput
						isRequired
						title={t('api.name')}
						register={register('name')}
						error={errors?.name?.message}
						testId='name'
					/>

					<Field.TextInput
						title={t('api.contact')}
						register={register('contact')}
						error={errors?.contact?.message}
						testId='contact'
					/>

					<Field.TextInput
						isRequired
						title={t('api.email')}
						register={register('email')}
						error={errors?.email?.message}
						testId='email'
					/>

					<Field.TextInput
						title={t('api.address')}
						register={register('address')}
						error={errors?.address?.message}
						testId='address'
					/>

					<Field.TextInput
						title={t('api.city')}
						register={register('city')}
						error={errors?.city?.message}
						testId='city'
					/>

					<Field.TextInput
						title={t('api.state')}
						register={register('state')}
						error={errors?.state?.message}
						testId='state'
					/>

					{/*{isEditForm && <ClientFormSites clientId={clientId} />}*/}

					<FormButtons
						isSubmitting={isSubmitting}
						isDirty={isDirty}
						handleCancel={handleCancel}
					/>
				</form>
			</FormProvider>
		</>
	)
}

const ClientFormModal = ({ clientId }: { clientId: number }) => {
	const { t } = useTranslation('forms', { keyPrefix: 'clientForm' })
	const { isOpen, onOpen, onClose } = useDisclosure()
	const isEditModal = !!clientId

	return (
		<>
			{isEditModal ? (
				<IconButton
					testId='edit-client'
					aria-label='edit-client'
					icon={<MdEdit />}
					onClick={onOpen}
				/>
			) : (
				<Button
					onClick={onOpen}
					data-testid={'add-client'}
					leftIcon={<MdAdd />}
					variant='ghost'
				>
					{t('buttons.addClient')}
				</Button>
			)}
			<Modal isOpen={isOpen} onClose={onClose} isCentered>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader data-testid='client-modal-header'>
						<Flex alignItems='center' gap='8px'>
							{t(isEditModal ? 'headings.editClient' : 'headings.createClient')}
						</Flex>
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody mb={4}>
						<ClientForm clientId={clientId} onClose={onClose} />
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	)
}

export { ClientForm, ClientFormModal }
