import { useEffect, useState } from 'react'
import { useMap } from 'react-leaflet'

const useMeasurementToolActive = () => {
	const map = useMap()
	const [active, setActive] = useState<boolean>(false)
	useEffect(() => {
		// polylinemeasure events
		// https://github.com/ppete2/Leaflet.PolylineMeasure/blob/master/README.md#events
		map.on('polylinemeasure:toggle', (e) => {
			const { sttus: toggleStatus } = e as unknown as { sttus: boolean }
			setActive(toggleStatus)
		})
		return () => {
			map.off('polylinemeasure:toggle')
		}
	}, [map])
	return active
}

export default useMeasurementToolActive
