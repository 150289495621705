import { Flex, useTheme } from '@chakra-ui/react'

const Footer = ({ children }: { children: React.ReactNode }) => {
	const {
		semanticTokens: { colors },
	} = useTheme()

	return (
		<Flex
			position='absolute'
			padding='16px'
			height='64px'
			bgColor={colors.input_bg}
			alignItems='center'
			gap='6px'
			bottom={0}
			left={0}
			w={495}
		>
			{children}
		</Flex>
	)
}

export default Footer
