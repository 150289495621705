import type { ReactNode } from 'react'

// Leaflet
import 'leaflet/dist/leaflet.css'
import type { LatLngBoundsExpression, LatLngExpression } from 'leaflet'
import { MapContainer } from 'react-leaflet'

// Components
import MapTileLayer from './MapTileLayer/MapTileLayer'
import MapEventsHandler from './MapEventsHandler'
import MapControls from '@Components/MapControls/MapControls'

type MapProps = {
	center?: LatLngExpression
	zoom?: number
	scrollWheelZoom?: boolean
	attributionControl?: boolean
	showMeasurementTools?: boolean
	showScreenshotTool?: boolean
	showMapLayerButton?: boolean
	showZoomControl?: boolean
	showCompassTool?: boolean
	showCenterMapButton?: boolean
	showMapLegend?: boolean
	showScaleControl?: boolean
	showCoordinatesControl?: boolean
	children?: ReactNode
	whenReady?: () => void
	id?: string
}

export const mapBounds = [
	[-90, -360],
	[80, 360],
] as LatLngBoundsExpression

// Leaflet Map Container
const Map = ({
	center,
	zoom = 13,
	scrollWheelZoom = false,
	attributionControl = true,
	showCompassTool = true,
	showCenterMapButton = true,
	showZoomControl = true,
	showMeasurementTools = false,
	showScreenshotTool = true,
	showMapLayerButton = true,
	showMapLegend = false,
	showScaleControl = true,
	showCoordinatesControl = true,
	whenReady,
	children,
	id,
}: MapProps) => {
	return (
		<MapContainer
			className='MapContainer'
			center={center}
			zoom={zoom}
			scrollWheelZoom={scrollWheelZoom}
			zoomControl={false}
			attributionControl={attributionControl}
			id={id}
			whenReady={whenReady}
			maxBounds={mapBounds}
			maxBoundsViscosity={0}
			worldCopyJump={true}
		>
			<MapTileLayer />
			<MapControls
				zoom={zoom}
				center={center}
				showCompassTool={showCompassTool}
				showCenterMapButton={showCenterMapButton}
				showZoomControl={showZoomControl}
				showScreenshotTool={showScreenshotTool}
				showMapLayerButton={showMapLayerButton}
				showMapLegend={showMapLegend}
				showMeasurementTools={showMeasurementTools}
				showScaleControl={showScaleControl}
				showCoordinatesControl={showCoordinatesControl}
			/>
			<MapEventsHandler />
			{children}
		</MapContainer>
	)
}

// Exports
export default Map
