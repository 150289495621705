// Chakra
import { Box, Tooltip, useRangeSlider } from '@chakra-ui/react'
// Components
import type { FrequencyValuesType } from './RfFiltersOverviewFrequencies'
import type { BearingValuesType } from './RfFiltersOverviewBearings'

type FormRangeSliderProps = {
	min: number
	max: number
	tooltip: string
	range: FrequencyValuesType | BearingValuesType
	defaultValue: number[]
	isActive?: boolean
	onClick?: () => void
}

const RfFiltersOverviewSlider = ({
	min,
	max,
	range,
	tooltip,
	defaultValue,
	isActive,
	onClick,
	...rest
}: FormRangeSliderProps) => {
	const { getInnerTrackProps } = useRangeSlider({
		min,
		max,
		defaultValue,
		...rest,
	})

	const CenterThumb = () => {
		return (
			<Box {...getInnerTrackProps()} onClick={onClick}>
				<Tooltip label={tooltip}>
					<Box
						width='16px'
						cursor='pointer'
						position='relative'
						pointerEvents='auto'
						top='-12px'
						left='calc(50% - 8px)'
						borderLeft='8px solid transparent'
						borderRight='8px solid transparent'
						borderTop='8px solid'
						borderTopColor='primary'
						data-testid='range-filter-triangle'
					/>
				</Tooltip>
			</Box>
		)
	}

	return (
		<Box position='absolute' width='100%'>
			<Box
				h='16px'
				bgColor='filter_bg'
				{...getInnerTrackProps()}
				border={isActive ? '2px solid white' : 'none'}
			/>
			<CenterThumb />
		</Box>
	)
}

export default RfFiltersOverviewSlider
