import { useTranslation } from 'react-i18next'
// Chakra
import { Tabs, TabList, TabPanels, TabPanel, Tab } from '@chakra-ui/react'

// Components
import { Page } from '@Components/Layouts'
import UserManagementClientsTable from './Tables/UserManagementClientsTable'
import UserManagementUsersTable from './Tables/UserManagementUsersTable'
import useAuth from '@Hooks/useAuth'

const UserManagement = () => {
	const { t } = useTranslation('pages', {
		keyPrefix: 'userManagement',
	})
	const { isAdmin, isManager } = useAuth()

	return (
		<Page heading={t('userManagement')}>
			<Tabs mt={6} isLazy>
				<TabList>
					{isManager && <Tab data-testid='users-tab'>{t('users')}</Tab>}
					{isAdmin && <Tab data-testid='clients-tab'>{t('clients')}</Tab>}
				</TabList>
				<TabPanels>
					{isManager && (
						<TabPanel>
							<UserManagementUsersTable />
						</TabPanel>
					)}
					{isAdmin && (
						<TabPanel>
							<UserManagementClientsTable />
						</TabPanel>
					)}
				</TabPanels>
			</Tabs>
		</Page>
	)
}

export default UserManagement
