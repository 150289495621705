import { useTranslation } from 'react-i18next'
import { Flex, Grid, GridItem, useToken } from '@chakra-ui/react'
import MapLegendSection from './MapLegendSection'
// Icons
import FusionDetectionMarkerIcon from '@Components/Markers/FusionDetectionMarker/FusionDetectionMarkerIcon'
import DetectionMarkerIcon from '@Components/Markers/DetectionMarker/DetectionMarkerIcon'

const MapLegendSectionSensorFusion = () => {
	const { t } = useTranslation('map', { keyPrefix: 'legend.sensorFusion' })

	const [whitelistedColor, threatColor] = useToken('colors', [
		'detections.whitelisted',
		'detections.default',
	])

	return (
		<MapLegendSection title={t('title')}>
			<Grid templateColumns='repeat(3, 1fr)' rowGap={3}>
				<GridItem>{t('confidence')}</GridItem>
				<GridItem textAlign='center'>{t('threat')}</GridItem>
				<GridItem textAlign='center'>{t('whitelisted')}</GridItem>
				<Flex alignItems='center' whiteSpace='pre-wrap'>
					{t('low')}
				</Flex>
				<Flex alignItems='center' justifyContent='center'>
					<FusionDetectionMarkerIcon
						probability={0}
						isThreat
						isSelected={false}
					/>
				</Flex>
				<Flex alignItems='center' justifyContent='center'>
					<FusionDetectionMarkerIcon
						probability={0}
						isThreat={false}
						isSelected={false}
					/>
				</Flex>
				<Flex alignItems='center' whiteSpace='pre-wrap'>
					{t('high')}
				</Flex>
				<Flex
					alignItems='center'
					justifyContent='center'
					sx={{ '& > svg': { width: '32px' } }}
				>
					<DetectionMarkerIcon iconType='drone' color={threatColor} />
				</Flex>
				<Flex
					alignItems='center'
					justifyContent='center'
					sx={{ '& > svg': { width: '32px' } }}
				>
					<DetectionMarkerIcon iconType='drone' color={whitelistedColor} />
				</Flex>
			</Grid>
		</MapLegendSection>
	)
}

export default MapLegendSectionSensorFusion
