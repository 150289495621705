// Packages
import { skipToken } from '@reduxjs/toolkit/query'

// Alias
import StatusIcon from '@/components/_UI/StatusIcon/StatusIcon'
import {
	selectSiteStatusColor,
	useGetSiteLiveQuery,
} from '@/store/sites/sitesWsApi'

// Types
import { sensorStatusColorMap, type SensorStatusColor } from '@/store/types'

export type SiteStatusProps = {
	siteId: number
}

const SiteStatus = ({ siteId }: SiteStatusProps) => {
	const { status_color, isSiteStatusSuccess } = useGetSiteLiveQuery(
		siteId > 0 ? siteId : skipToken,
		{
			selectFromResult: ({ data, isSuccess }) => ({
				status_color: selectSiteStatusColor(data),
				isSiteStatusSuccess: isSuccess,
			}),
		}
	)

	if (!siteId || !isSiteStatusSuccess) {
		return undefined
	}

	return (
		<StatusIcon
			color={sensorStatusColorMap[status_color as SensorStatusColor]}
			testId={`site-status-color-${status_color}`}
		/>
	)
}

export default SiteStatus
