// React
import { useTransition } from 'react'
// Components
import { Switch } from '@chakra-ui/react'
// Store
import type { Cot } from '@Store/types/cot'
import { useUpdateCotMutation } from '@Store/cots/cotsApi'

const CotActiveToggleSwitch = ({ cot }: { cot: Cot }) => {
	const { id: cotId, site_id: siteId, enabled } = cot
	const [isPending, startTransition] = useTransition()
	const [updateCot] = useUpdateCotMutation()

	const handleToggleActive = async () => {
		try {
			startTransition(() => {
				updateCot({
					siteId,
					cotId,
					cot: { ...cot, enabled: !enabled },
				}).unwrap()
			})
		} catch (error) {
			// TODO: handle server-side errors if necessary
			console.error('Unhandled update COT error', error)
		}
	}

	return (
		<Switch
			onChange={handleToggleActive}
			isChecked={enabled}
			isDisabled={isPending}
		/>
	)
}

export default CotActiveToggleSwitch
