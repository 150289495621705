import { Children, useState } from 'react'
import type { ReactNode } from 'react'
import {
	Box,
	Icon,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
} from '@chakra-ui/react'
import { MdMoreVert } from 'react-icons/md'
import ErrorBoundary from '@Components/App/ErrorHandling/ErrorBoundary'
import Tooltip from '@UI/Tooltip/Tooltip'
import { useTranslation } from 'react-i18next'

type EditPaletteProps = {
	disabled?: boolean
	children?: ReactNode | ReactNode[] | null
	testId?: string
	onMenuButtonClick?: () => void
}

const EditPalette = ({
	disabled = false,
	children = null,
	testId = 'edit-palette',
	onMenuButtonClick,
}: EditPaletteProps) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'siteInstallations.tooltips',
	})
	const [isTooltipOpened, setIsTooltipOpen] = useState(false)
	const handleClick = () => {
		if (typeof onMenuButtonClick === 'function') onMenuButtonClick()
		setIsTooltipOpen(false)
	}

	const openTooltip = () => setIsTooltipOpen(true)
	const closeTooltip = () => setIsTooltipOpen(false)

	return (
		<ErrorBoundary quiet>
			<Box textAlign='right' marginInlineEnd={2}>
				<Menu
					placement='bottom-start'
					strategy='fixed'
					gutter={1}
					autoSelect={false}
				>
					<Tooltip isOpen={isTooltipOpened} label={t('showMore')}>
						<MenuButton
							disabled={disabled || children === null}
							data-testid={testId}
							onClick={handleClick}
							onMouseEnter={openTooltip}
							onMouseLeave={closeTooltip}
							onKeyDown={closeTooltip}
							onKeyUp={openTooltip}
						>
							<Icon as={MdMoreVert} boxSize={5} />
						</MenuButton>
					</Tooltip>
					<MenuList>
						{Children.map(children, (child, index) => (
							<ErrorBoundary quiet>
								<MenuItem key={index} as='div'>
									{child}
								</MenuItem>
							</ErrorBoundary>
						))}
					</MenuList>
				</Menu>
			</Box>
		</ErrorBoundary>
	)
}

export default EditPalette
