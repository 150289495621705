import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useGetZonesQuery, selectHasAlertZones } from '@Store/zones/zonesApi'

import Alert from '@UI/Alert/Alert'
import AlertButton from '@UI/Alert/AlertButton'

const AlertZonesWarning = ({ siteId }: { siteId: number }) => {
	const navigate = useNavigate()
	const { t } = useTranslation('pages', {
		keyPrefix: 'alertSettings.alertSettings',
	})

	const { isSuccess, hasAlertZones } = useGetZonesQuery(
		{ siteId },
		{
			skip: !siteId,
			selectFromResult: ({ isSuccess, data }) => ({
				isSuccess,
				hasAlertZones: data && selectHasAlertZones(data),
			}),
		}
	)

	const handleClick = () => {
		navigate(`/${siteId}/zones`)
	}

	if (isSuccess && !hasAlertZones)
		return (
			<Alert
				status='warning'
				title={t('noAlertZonesWarningLabel')}
				description={t('noAlertZonesWarningText')}
			>
				<AlertButton
					onClick={handleClick}
					label={t('buttons.goToMapsAndZones')}
				/>
			</Alert>
		)
	else return null
}

export default AlertZonesWarning
