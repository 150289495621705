import { useTranslation } from 'react-i18next'

import { Box } from '@chakra-ui/react'
import { MdOutlineSlowMotionVideo } from 'react-icons/md'

import Button from '@UI/Button/Button'
import SpinnerText from '@UI/SpinnerText/SpinnerText'

import {
	useGetSystemInfoQuery,
	selectIsSimulationEnabled,
} from '@Store/system/systemApi'
import {
	useGetSiteQuery,
	useStartSimulationMutation,
	useUpdateHeartbeatQuery,
} from '@Store/sites/sitesApi'
import {
	TEST_DETECTIONS_ENABLED_SITES,
	TEST_DETECTIONS_HEARTBEAT_INTERVAL,
} from '@Constants/site'

const TestDetections = ({ siteId }: { siteId: number }) => {
	const { t } = useTranslation('navigation', {
		keyPrefix: 'testDetections',
	})

	const { data } = useGetSiteQuery(siteId, {
		skip: !siteId,
	})

	const { isSimulationEnabled } = useGetSystemInfoQuery(undefined, {
		selectFromResult: ({ data }) => ({
			isSimulationEnabled: selectIsSimulationEnabled(data),
		}),
	})

	const showTestDetections =
		isSimulationEnabled &&
		TEST_DETECTIONS_ENABLED_SITES.includes(data?.name || '')

	const [startSimulation] = useStartSimulationMutation()

	const { data: heartbeat } = useUpdateHeartbeatQuery(
		{ siteId },
		{
			pollingInterval: TEST_DETECTIONS_HEARTBEAT_INTERVAL,
			skip: !showTestDetections || !siteId,
		}
	)

	if (!showTestDetections) return null

	const handleRunTestDetections = () => {
		startSimulation({ siteId })
	}

	const isTestDetectionsRunning =
		heartbeat?.message !== 'Skip - Simulation not running'

	return (
		<Box mr={3}>
			{isTestDetectionsRunning ? (
				<SpinnerText status='active' text={t('runningTestDetections')} />
			) : (
				<Button
					testId='test-detections'
					label={t('runTestDetections')}
					leftIcon={<MdOutlineSlowMotionVideo fontSize='16px' />}
					size='xs'
					onClick={handleRunTestDetections}
				/>
			)}
		</Box>
	)
}

export default TestDetections
