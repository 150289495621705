import { useTranslation } from 'react-i18next'
import {
	Button,
	Flex,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react'
import { MdInfo } from 'react-icons/md'

type CancelModalProps = {
	isOpen: boolean
	onClose: () => void
	handleCancel: () => void
	headerText: string
	fromText?: string
	testId?: string
	modalBodyText?: string
}

const CancelModal = ({
	// testId,
	isOpen,
	onClose,
	handleCancel,
	headerText,
	modalBodyText = '',
}: CancelModalProps) => {
	const { t } = useTranslation('app', { keyPrefix: 'global.buttons' })
	const onConfirm = async () => {
		handleCancel()
		onClose()
	}

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			variant='deleteConfirmation'
			isCentered
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					<Flex alignItems='center' gap='8px'>
						<Icon as={MdInfo} h={6} w={6} color='primary' />
						{headerText}
					</Flex>
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>{modalBodyText}</ModalBody>
				<ModalFooter>
					<Button onClick={onConfirm} data-testid='confirm'>
						{t('confirm')}
					</Button>
					<Button mr={3} onClick={onClose} variant='ghost' data-testid='cancel'>
						{t('cancel')}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default CancelModal
