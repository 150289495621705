// REACT
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { type TypeOf } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

// Chakra
import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import { MdInfo } from 'react-icons/md'

// Components
import { FormButtons } from '@Components/FormElements/FormButtons/FormButtons'
import Field from '@Components/FormElements'

import {
	defaultValues,
	offlineMapLayersFormSchema,
} from '@Forms/MapsZonesForms/MapLayersForm/MapLayersForm.schema'
import {
	useInitUploadOfflineMapLayerMutation,
	useUploadOfflineMapLayerMutation,
	useCancelUploadOfflineMapLayerMutation,
} from '@Store/mapLayers/mapLayersApi'

import { OFFLINE_MAX_LAYER_SIZE } from '@Constants/mapLayers'
import { chunk } from '@Utils/functions'

import type { FormError } from '../../types'
import { addToast } from '@Store/ui/uiSlice'
import type { CoreApiError } from '@Store/utils/errorUtils'
import { useAppDispatch } from '@/store'

// Logic taken from Vue
const getFileChunks = async (file: File) => {
	const size = OFFLINE_MAX_LAYER_SIZE
	const chunkQuantity = Math.ceil(file?.size / size)
	const chunks = []

	for (let i = 0; i < chunkQuantity; i++) {
		const start = i * size
		const end = start + size
		const chunk = file.slice(start, end)
		chunks.push(chunk)
	}

	return chunks
}

// TODO: Handle errors after implementing the alerts socket - right now there
// may be silent failures
const MapCreateOfflineLayerForm = ({ onClose }: { onClose: () => void }) => {
	const { siteId } = useParams<string>()
	// Translations
	const { t } = useTranslation('forms', { keyPrefix: 'mapsZonesForm' })

	type Schema = TypeOf<typeof offlineMapLayersFormSchema>

	const methods = useForm<Schema>({
		resolver: zodResolver(offlineMapLayersFormSchema),
		mode: 'onBlur',
		defaultValues,
	})
	const {
		register,
		formState: { errors, isSubmitting, isDirty },
		handleSubmit,
		setValue,
		setError,
	} = methods

	const [initUploadOfflineMapLayer] = useInitUploadOfflineMapLayerMutation()
	const [uploadOfflineMapLayer] = useUploadOfflineMapLayerMutation()
	const [cancelUploadOfflineMapLayerMutation] =
		useCancelUploadOfflineMapLayerMutation()

	const [uploadingFileId, setUploadingFileId] = useState<string | null>(null)
	const dispatch = useAppDispatch()

	const uploadFileChunks = async (chunks: Blob[], fileID: string) => {
		const orderedChunks = chunks.map((blob, index) => ({
			id: index,
			data: blob,
		}))

		const chunkBatches = chunk(orderedChunks, 3)

		for (const batch of chunkBatches) {
			try {
				await Promise.all(
					batch.map(({ id, data }) => {
						return uploadOfflineMapLayer({
							fileID,
							chunkID: id,
							chunk: data,
						})
							.unwrap()
							.catch((error) => {
								setUploadingFileId(null)
								cancelUploadOfflineMapLayerMutation({ fileID })
								throw t('mapLayersForm.status.errorOfflineUpload')
							})
					})
				)
			} catch (error) {
				console.error(`Error uploading map layer: ${error}`)
				dispatch(
					addToast({
						type: 'error',
						title: t('mapLayersForm.status.error'),
						description: t('mapLayersForm.status.errorOfflineUpload'),
						siteId,
						error,
						id: `chunk-${(error as CoreApiError).status}`,
					})
				)
				break
			}
		}
	}

	const handleSave = async (data: Schema) => {
		const file = data.file as File
		try {
			const fileChunks = await getFileChunks(file)

			const { fileID } = await initUploadOfflineMapLayer({
				maplayerName: data.name,
				attribution: data.attribution,
				min_zoom: data.min_zoom,
				max_zoom: data.max_zoom,
				fallback: data.fallback,
				size: file.size,
				filename: file.name,
				quantity: fileChunks.length,
			}).unwrap()

			setUploadingFileId(fileID)
			await uploadFileChunks(fileChunks, fileID)

			onClose()
			setUploadingFileId(null)
		} catch (error) {
			setError('name', {
				type: 'custom',
				message: (error as FormError).data.error,
			})
			setUploadingFileId(null)
		}
	}

	const handleCancel = () => onClose()

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(handleSave)}>
				<Field.TextInput
					title={t('mapLayersForm.name')}
					register={register('name')}
					error={errors?.name?.message}
					isRequired
				/>
				<Field.TextInput
					title={t('mapLayersForm.attribution')}
					register={register('attribution')}
					error={errors?.attribution?.message}
					isRequired
				/>
				<Field.FileInput
					title={t('mapLayersForm.file')}
					accept='.zip'
					uploadButtonTitle={t('mapLayersForm.buttons.upload')}
					register={register('file')}
					error={errors?.file?.message}
					fieldName='file'
					setValue={setValue}
					isRequired
				/>
				<Field.RangeSlider
					title={t('mapLayersForm.minMaxZoom')}
					defaultValue={[defaultValues.min_zoom, defaultValues.max_zoom]}
					step={1}
					min={1}
					max={30}
					onChangeEnd={(value: [number, number]) => {
						setValue('min_zoom', value[0], {
							shouldDirty: true,
							shouldValidate: true,
							shouldTouch: true,
						})
						setValue('max_zoom', value[1], {
							shouldDirty: true,
							shouldValidate: true,
							shouldTouch: true,
						})
					}}
					testId='zoom'
				/>
				{/* 32px is switch width + length of ch of translated title */}
				<Box width={`calc(32px + ${t('mapLayersForm.fallback').length}ch)`}>
					<Field.Switch
						title={t('mapLayersForm.fallback')}
						tooltip={t('mapLayersForm.fallbackMessage')}
						register={register('fallback')}
						error={errors?.fallback?.message}
					/>
				</Box>
				<FormButtons
					isSubmitting={isSubmitting}
					isDirty={isDirty}
					handleCancel={handleCancel}
				/>
				{uploadingFileId && (
					<Flex gap={3} mt={3} p={2} bgColor='input_bg' alignItems='center'>
						<Icon as={MdInfo} color='primary' />
						<Text fontSize='sm' fontWeight={500}>
							{t('mapLayersForm.backgroundUpload')}
						</Text>
					</Flex>
				)}
			</form>
		</FormProvider>
	)
}
export default MapCreateOfflineLayerForm
