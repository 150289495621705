import { useState } from 'react'

import AutoDisruptButton from './AutoDisruptButton'
import DisruptAllButton from './DisruptAllButton'
import Disruptors from './Disruptors'
import DsxDisruptors from './DsxDisruptors'

const DisruptorActions = ({ siteId }: { siteId: number }) => {
	// Whether all disruptors are loading (after clicking the disrupt all button)
	const [isAllLoading, setIsAllLoading] = useState(false)

	return (
		<>
			<AutoDisruptButton siteId={siteId} />
			<DisruptAllButton siteId={siteId} setIsAllLoading={setIsAllLoading} />
			{/* Disruptors and DsxDisruptors both implement the same button, but
			have been split into different components to handle the different types + logic
			between DSX (Rf Sensors) and normal disruptors
			*/}
			<Disruptors siteId={siteId} isAllLoading={isAllLoading} />
			<DsxDisruptors siteId={siteId} isAllLoading={isAllLoading} />
		</>
	)
}

export default DisruptorActions
