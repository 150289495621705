// Packages
import { Box } from '@chakra-ui/react'

// Alias
import ErrorBoundary from '@/components/App/ErrorHandling/ErrorBoundary'

// Relatives
import DetectionHeader from '../DetectionDetail/DetectionHeader'
import DetectionDetail from '../DetectionDetail/DetectionDetail'

// Types
import type { Detection } from '@/store/types'

export type NotificationDetectionProps = {
	detection: Detection
	activeTargetId?: string | null
}

const NotificationDetection = ({
	detection,
	activeTargetId,
}: NotificationDetectionProps) => (
	<Box
		key={detection.target_id}
		_notLast={{ marginBlockEnd: '1px' }}
		data-testid='detection-notification-box'
		data-uuid={detection.target_id}
		pointerEvents='all'
	>
		<ErrorBoundary quiet>
			<DetectionHeader key={detection.target_id} detection={detection} />
		</ErrorBoundary>
		{activeTargetId === detection.target_id && (
			<ErrorBoundary quiet>
				<DetectionDetail detection={detection} />
			</ErrorBoundary>
		)}
	</Box>
)

export default NotificationDetection
