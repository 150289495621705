// Not used here, but re-exported for the zod form schemas
import { i18n } from '@Components/App/I18nProvider'

type Node = { [key: string]: string | Node }
type Locale = {
	[lang: string]: {
		[namespace: string]: {
			[keyPrefix: string]: Node
		}
	}
}

// Pass to i18n
const resources: Locale = {}

// Import all locale JSON files within the root directory
const localeFiles = import.meta.glob('./**/*.locales/*.json', {
	eager: true,
	import: 'default',
})

// Merge into a 'messages' object for i18n
Object.values(localeFiles).forEach((locale) => {
	const lang = Object.keys(locale as Locale)[0]
	const namespace = Object.keys((locale as Locale)[lang])[0]
	const keyPrefix = Object.keys((locale as Locale)[lang][namespace])[0]
	if (!resources[lang]) resources[lang] = {}
	if (!resources[lang][namespace]) resources[lang][namespace] = {}
	if (!resources[lang][namespace][keyPrefix])
		resources[lang][namespace][keyPrefix] = {}
	const entries = (locale as Locale)[lang][namespace][keyPrefix]
	Object.assign(resources[lang][namespace][keyPrefix], entries)
})

export default i18n

export { resources as i18nresources }
