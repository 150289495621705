import { useTheme } from '@chakra-ui/react'
import { useMapEvent } from 'react-leaflet'

import type { Detection, DetectionContribution, RfSensor } from '@Store/types'
import RfDetectionLine from '../RfDetectionLine/RfDetectionLine'
import Sector from '@Components/MapShapes/Sector/Sector'

// Utility
import { convertRadToDegree } from '@Utils/mathUtils'

type RfDetectionSectorProps = {
	latitude: number
	longitude: number
	sensorBearing: number
	aoa: DetectionContribution['aoa']
	aoaError: DetectionContribution['aoa_error']
	droneLocatorConfirmed?: Detection['drone_locator_confirmed']
	classification?: Detection['classification']
	showSector?: RfSensor['show_sectors']
	showLine?: RfSensor['show_sector_as_line']
	showOutside?: RfSensor['show_outside_detections']
	showNoAoa?: RfSensor['show_720_detection']
	isWhitelisted?: boolean
	isSelected?: boolean
	onClick?: () => void
	onMouseDown?: () => void
	testId?: string
} & Pick<RfSensor, 'model' | 'reach'>

const RfDetectionSector = ({
	latitude,
	longitude,
	sensorBearing,
	aoa,
	aoaError,
	droneLocatorConfirmed = false,
	reach = 0,
	model,
	classification,
	isSelected,
	isWhitelisted,
	onClick,
	onMouseDown,
	showSector,
	showLine,
	showOutside,
	showNoAoa,
	testId = 'rf-detection-sector',
}: RfDetectionSectorProps) => {
	const {
		semanticTokens: { colors },
	} = useTheme()
	const color = isWhitelisted
		? colors.detections.whitelisted
		: colors.detections.default
	let fillColor = undefined
	let fillOpacity = 0.2
	if (isSelected) {
		fillColor = colors.detections.selected.fillColor
		fillOpacity = 0.4
	}

	useMapEvent('click', () => {
		isSelected && typeof onMouseDown === 'function' && onMouseDown()
	})

	// Is this a 'no AoA' detection?
	let detectionHasNoAoa = false
	if (!['rf_patrol'].includes(model)) {
		detectionHasNoAoa = aoa > 12
		if (detectionHasNoAoa && !showNoAoa) return null
		reach =
			detectionHasNoAoa && classification === 'controller'
				? (reach * 2) / 3
				: reach
	}

	// discard drone locator contribution
	if (droneLocatorConfirmed && aoa === 0 && aoaError === 0) {
		return
	}

	// Rf Patrol shows a single 360 degrees sector
	if (['rf_patrol'].includes(model)) {
		return (
			<Sector
				latitude={latitude}
				longitude={longitude}
				reach={reach}
				color={color}
				fillColor={fillColor}
				fillOpacity={fillOpacity}
				onClick={onClick}
				onMouseDown={onMouseDown}
				testId={testId}
			/>
		)
	}
	// DSX
	else if (model.includes('dsx')) {
		const detectionBearing = (sensorBearing + convertRadToDegree(aoa)) % 360
		const detectionAngle = detectionHasNoAoa
			? 360
			: convertRadToDegree(aoaError) * 2
		const sectorStrokeWeight = isSelected ? 2 : 0
		const lineStrokeWeight = isSelected ? 4 : 1

		return (
			<>
				{showSector && (
					<Sector
						latitude={latitude}
						longitude={longitude}
						bearing={detectionBearing}
						angle={detectionAngle}
						reach={reach}
						color={color}
						fillColor={fillColor}
						fillOpacity={fillOpacity}
						weight={sectorStrokeWeight}
						onClick={onClick}
						onMouseDown={onMouseDown}
						testId={testId}
					/>
				)}
				{showLine && !detectionHasNoAoa && (
					<RfDetectionLine
						latitude={latitude}
						longitude={longitude}
						bearing={detectionBearing}
						weight={lineStrokeWeight}
						color={color}
						reach={reach}
						classification={classification}
						onClick={onClick}
						onMouseDown={onMouseDown}
					/>
				)}
			</>
		)
	}
	// RFOne
	else {
		const sensorAngle = 90
		const minAngle = sensorBearing - sensorAngle / 2
		const maxAngle = sensorBearing + sensorAngle / 2
		const detectionBearing = (sensorBearing + convertRadToDegree(aoa)) % 360
		const detectionAngle = detectionHasNoAoa
			? 90
			: convertRadToDegree(aoaError) * 2

		const sectorStrokeWeight = isSelected ? 2 : 0
		const lineStrokeWeight = isSelected ? 5 : 2

		return (
			<>
				{detectionBearing >= minAngle && detectionBearing <= maxAngle && (
					<>
						{showSector && (
							<Sector
								latitude={latitude}
								longitude={longitude}
								bearing={detectionBearing}
								angle={detectionAngle}
								reach={reach}
								color={color}
								fillColor={fillColor}
								fillOpacity={fillOpacity}
								weight={sectorStrokeWeight}
								onClick={onClick}
								onMouseDown={onMouseDown}
								testId={testId}
							/>
						)}
						{showLine && !detectionHasNoAoa && (
							<RfDetectionLine
								latitude={latitude}
								longitude={longitude}
								bearing={detectionBearing % 360}
								weight={lineStrokeWeight}
								color={color}
								reach={reach}
								classification={classification}
								onClick={onClick}
								onMouseDown={onMouseDown}
								pane='markerPane' // hack to render the line above the sector
							/>
						)}
					</>
				)}
				{showOutside && detectionBearing < minAngle && (
					<Sector
						latitude={latitude}
						longitude={longitude}
						bearing={(minAngle - detectionAngle / 2) % 360}
						reach={reach + 1}
						angle={detectionAngle}
						color={color}
						weight={0}
						onClick={onClick}
						onMouseDown={onMouseDown}
						{...(testId && { testId: `${testId}-outside-left` })}
						className='rf-detection-sector-show-outside'
					/>
				)}
				{showOutside && detectionBearing > maxAngle && (
					<Sector
						latitude={latitude}
						longitude={longitude}
						bearing={maxAngle + detectionAngle / 2}
						reach={reach}
						angle={detectionAngle}
						color={color}
						onClick={onClick}
						onMouseDown={onMouseDown}
						{...(testId && { testId: `${testId}-outside-right` })}
						className='rf-detection-sector-show-outside'
					/>
				)}
			</>
		)
	}
}

export default RfDetectionSector
