// accept a javascript error and destructure it for typescript
// more notes:
// https://kentcdodds.com/blog/get-a-catch-block-error-message-with-typescript
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error
export const getErrorMessage = (error: unknown) => {
	if (error instanceof Error) {
		return { message: error.message, stack: error.stack }
	} else {
		return {
			message: String(error),
		}
	}
}

export type CoreApiError = {
	data: {
		error: string
		status: string
	}
	status: number
} & Error

export type ServerError = {
	status: number
}

export type FormFieldsError<T> = { [name in keyof T]: string }

export const formatApiErrors = <T>(
	response: CoreApiError
): FormFieldsError<T> | ServerError => {
	switch (true) {
		case [400, 401, 403, 404, 405, 500].includes(response.status):
			return { status: response.status }
		case response.status === 422:
		default:
			return response?.data?.error
				.split(/;\s*/)
				.map((error) => error.split(/:\s*/))
				.reduce(
					(obj, error) => ({
						...obj,
						[error[0]]: error[1].trim(),
					}),
					{} as FormFieldsError<T>
				)
	}
}
