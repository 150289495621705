import type { ReactNode } from 'react'

import {
	Alert as BaseAlert,
	AlertIcon,
	AlertDescription,
	AlertTitle,
	Flex,
	Stack,
} from '@chakra-ui/react'

type AlertProps = {
	description: string
	title?: string
	status: 'error' | 'success' | 'warning' | 'info'
	testId?: string
	children?: ReactNode
	variant?: string
}

const Alert = ({
	description,
	status,
	title,
	children,
	testId,
	variant = 'left-accent',
}: AlertProps) => {
	return (
		<BaseAlert variant={variant} status={status}>
			<Flex
				justify='space-between'
				width='100%'
				pointerEvents='auto'
				alignItems='center'
			>
				<Stack direction='row' alignItems='center' data-testid={testId}>
					<AlertIcon />
					{title && (
						<AlertTitle fontSize='sm' fontWeight={500} marginInlineEnd={0}>
							{title}
						</AlertTitle>
					)}
					<AlertDescription fontSize='sm' fontWeight={400}>
						{description}
					</AlertDescription>
				</Stack>
				{children}
			</Flex>
		</BaseAlert>
	)
}

export default Alert
