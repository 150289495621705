import { useTranslation } from 'react-i18next'
import { z, type TypeOf } from 'zod'
import { useForm, FormProvider } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { Flex, Text } from '@chakra-ui/react'

import Button from '@UI/Button/Button'
import Field from '@Components/FormElements'

import { useGenerateForgotPasswordLinkMutation } from '@Store/user/usersApi'

import type { PageModes } from '@Pages/Login/Login'
import type { FormError } from '@Forms/types'

type ForgotPasswordFormProps = {
	handlePageMode: (mode: PageModes) => void
}

const forgotPasswordFormSchema = z.object({
	email: z.string().email(),
})

const ForgotPasswordForm = ({ handlePageMode }: ForgotPasswordFormProps) => {
	const { t } = useTranslation('forms', { keyPrefix: 'loginForm' })

	type Schema = TypeOf<typeof forgotPasswordFormSchema>

	const methods = useForm<Schema>({
		resolver: zodResolver(forgotPasswordFormSchema),
		defaultValues: { email: '' },
	})
	const {
		register,
		formState: { errors, isDirty },
		handleSubmit,
		setError,
	} = methods

	const [generateForgotPasswordLink, { isLoading }] =
		useGenerateForgotPasswordLinkMutation()

	const handleForgotPassword = async (data: Schema): Promise<void> => {
		try {
			await generateForgotPasswordLink(data).unwrap()
			handlePageMode('reset')
		} catch (error: unknown) {
			setError('email', {
				type: 'custom',
				message: (error as FormError).data.error,
			})
		}
	}

	return (
		<Flex direction='column' gap='28px' h='100%'>
			<Text
				fontSize='sm'
				color='label_color'
				alignSelf='center'
				textAlign='center'
			>
				{t('password.enterEmail')}
			</Text>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(handleForgotPassword)}>
					<Field.TextInput
						title={t('email.address')}
						register={register('email')}
						error={errors?.email?.message}
						testId='email'
						isRequired
					/>
					<Flex direction='column' gap='20px' alignItems='center' mt='50px'>
						<Button
							variant='outline'
							type='submit'
							testId='forgot-reset'
							label={isLoading ? '' : t('password.reset')}
							isLoading={isLoading}
							size='sm'
							w='150px'
							isDisabled={!isDirty}
						/>
						<Button
							variant='text'
							onClick={() => handlePageMode('login')}
							label={t('buttons.return')}
							testId='return-to-login'
							w='150px'
						/>
					</Flex>
				</form>
			</FormProvider>
		</Flex>
	)
}

export default ForgotPasswordForm
