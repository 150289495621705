import { z } from 'zod'
// Translations
import i18n from '@/i18n'
const t = (key: string) => i18n.t(`forms:cotForm.validation.${key}`)

const cotFormFields = z.object({
	id: z.number().optional(),
	site_id: z.number().optional(),
	enabled: z.boolean(),
	minimum_probability: z.number().min(0).max(0.8),
	rate_limit: z.number().min(0).max(100),
	event_version: z.number(),
	field_of_view: z.number().min(0).max(360),
	symbol_type: z.string().refine(
		(value) => {
			const regex = /^(\w+(-\w+)*(;[^;]*)?)$/
			return regex.test(value)
		},
		{ message: 'Invalid entry' }
	),
	how: z.string().refine(
		(value) => {
			const regex = /^\w-\w$/
			return regex.test(value)
		},
		{ message: 'Invalid entry' }
	),
	ip: z.string().ip({ version: 'v4', message: t('ip') }),
	port: z.number().min(1).max(65535),
	protocol: z.enum(['tcp', 'udp']),
})

export const cotFormSchema = cotFormFields
export type CotSchema = z.infer<typeof cotFormSchema>

const defaultValuesSchema = cotFormSchema.omit({ site_id: true, id: true })

type DefaultValues = z.infer<typeof defaultValuesSchema>

export const ADD_COT_DEFAULT_VALUES: DefaultValues = {
	enabled: true,
	event_version: 3,
	field_of_view: 11,
	how: 'm-g',
	ip: '127.0.0.1',
	minimum_probability: 0.6,
	port: 1,
	protocol: 'tcp',
	rate_limit: 3,
	symbol_type: 'a-s-A-M-H-Q',
}
