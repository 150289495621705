// Chakra
import {
	Box,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input as ChakraInput,
	// NumberInput as ChakraNumberInput,
} from '@chakra-ui/react'
// React Hook Form
import { useFormContext } from 'react-hook-form'
// Components
import Tooltip from '@UI/Tooltip/Tooltip'
// Types
import type { FC, KeyboardEvent } from 'react'
import type { RegisterType } from '@Components/FormElements/types/RegisterType'
import type { InputProps } from '@chakra-ui/input/dist/input'
import type { FormElementProps } from '@Components/FormElements/types/FormElementProps'

type NumberInputProps = FormElementProps & {
	min?: number
	max?: number
	variant?: 'approximated' | 'default'
} & RegisterType<string> &
	InputProps

const MyNumberInput: FC<NumberInputProps> = ({
	id,
	title,
	register,
	error,
	min = undefined,
	max = undefined,
	disabled = undefined,
	variant = 'default',
	testId,
	tooltip,
	...rest
}) => {
	const isApproximate = variant === 'approximated'
	const htmlId = id ?? register?.name ?? 'numberInput'
	const { setError } = useFormContext()
	const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.ctrlKey || e.metaKey || e.altKey) return
		if (e.key.match(/^Backspace|Delete|Arrow(Right|Left|Down|Up)$/)) return
		else if (isNaN(Number(e.key))) e.preventDefault()
		else if (min && Number(e.currentTarget.value) < min) {
			setError(htmlId, { type: 'min', message: 'Number is too small' })
		} else if (max && Number(e.currentTarget.value) > max) {
			setError(htmlId, { type: 'max', message: 'Number is too big' })
		} else {
			setError(htmlId, {})
		}
	}
	return (
		<Tooltip label={tooltip} type='info'>
			<FormControl isInvalid={!!error} isDisabled={disabled}>
				<FormLabel htmlFor={htmlId}>{title}</FormLabel>
				<Box>
					{isApproximate && (
						<Box
							position='absolute'
							height={8}
							width={6}
							display='flex'
							justifyContent='center'
							alignItems='center'
							zIndex={10}
							userSelect='none'
							color='disabled'
						>
							±
						</Box>
					)}
					<ChakraInput
						id={htmlId}
						placeholder={title}
						{...(register ?? {})}
						onKeyDown={handleKeyPress}
						onKeyUp={handleKeyPress}
						data-testid={testId}
						paddingInlineStart={isApproximate ? 5 : 3}
						{...rest}
					/>
				</Box>
				<FormErrorMessage>{error}</FormErrorMessage>
			</FormControl>
		</Tooltip>
	)
}

export default MyNumberInput
