import { useTheme } from '@chakra-ui/react'

import Donut from '@Components/MapShapes/Donut/Donut'
import type { Radar } from '@Store/types'

type RadarSensorSectorProps = {
	latitude: number
	longitude: number
	bearing: number
	isActive: boolean
	isDashed?: boolean
} & Pick<
	Radar,
	| 'radar_az_max_search'
	| 'radar_az_max_track'
	| 'radar_az_min_search'
	| 'radar_az_min_track'
	| 'reach_max'
	| 'reach_min'
>

const RadarSensorSector = ({
	latitude,
	longitude,
	bearing,
	reach_max,
	reach_min,
	radar_az_min_track = -45,
	radar_az_max_track = 45,
	radar_az_min_search = -45,
	radar_az_max_search = 45,
	isActive = false,
	isDashed = false,
}: RadarSensorSectorProps) => {
	const {
		semanticTokens: { colors },
	} = useTheme()
	const fillColor = colors.sectors.radarFill
	const strokeColor = isActive
		? colors.sectors.highlight
		: colors.sectors.radarStroke
	const strokeWeight = isActive ? 4 : 2

	const trackStartAngle = bearing + radar_az_min_track
	const trackStopAngle = bearing + radar_az_max_track

	const searchStartAngle = bearing + radar_az_min_search
	const searchStopAngle = bearing + radar_az_max_search

	return (
		<>
			<Donut
				latitude={latitude}
				longitude={longitude}
				startAngle={trackStartAngle}
				stopAngle={trackStopAngle}
				innerRadius={reach_min}
				outerRadius={reach_max}
				strokeColor={strokeColor}
				fillColor={fillColor}
				strokeWeight={strokeWeight}
				dashArray={isDashed ? '24 24' : undefined}
				testId='sensor_sector_track'
			/>

			<Donut
				latitude={latitude}
				longitude={longitude}
				startAngle={searchStartAngle}
				stopAngle={searchStopAngle}
				innerRadius={reach_min}
				outerRadius={reach_max}
				strokeColor={strokeColor}
				fillColor='transparent'
				strokeWeight={strokeWeight}
				dashArray={isDashed ? '24 24' : undefined}
				testId='sensor_sector_search'
			/>
		</>
	)
}

export default RadarSensorSector
