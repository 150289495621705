import { useTheme } from '@chakra-ui/react'
import { useMapEvent } from 'react-leaflet'
import type { Detection } from '@Store/types'
import type { BaseMarkerProps } from '../index'
import BaseMarker from '../index'
import DetectionMarkerIcon from './DetectionMarkerIcon'
import HighlightMarkerIcon from './DetectionMarkerIcon/HighlightMarkerIcon'
import BeaconMarker from '@Components/Markers/BeaconMarker/BeaconMarker'
import Circle from '@Components/MapShapes/Circle/Circle'
import TrackHistory from './DetectionTrackHistory'
import DetectionSpeedLeader from './DetectionSpeedLeader'
import type { LatLngTuple } from 'leaflet'
import ErrorBoundary from '@Components/App/ErrorHandling/ErrorBoundary'
import useDistanceBetweenPoints from '@Hooks/useDistanceBetweenPoints'

export type DetectionMarkerProps = {
	targetId: Detection['target_id']
	latitude: number
	longitude: number
	speedLeaderPosition?: LatLngTuple
	gcsPosition?: BaseMarkerProps['position']
	homePosition?: BaseMarkerProps['position']
	onMouseDown?: BaseMarkerProps['onMouseDown'] // rapid marker re-rendering breaks onClick
	classification: Detection['classification']
	locationVariance?: Detection['location_variance']
	isWhitelisted?: boolean
	isSelected?: boolean
	isTracking?: boolean
	isTrackingLocked?: boolean
}

const DetectionMarker = ({
	targetId,
	latitude,
	longitude,
	speedLeaderPosition,
	gcsPosition = [0, 0],
	homePosition = [0, 0],
	onMouseDown,
	classification = 'unknown',
	locationVariance = 0,
	isWhitelisted,
	isSelected,
	isTracking,
	isTrackingLocked,
}: DetectionMarkerProps) => {
	const {
		semanticTokens: { colors },
	} = useTheme()
	const markerColor = isWhitelisted
		? colors.detections.whitelisted
		: colors.detections.default

	useMapEvent('click', () => {
		isSelected && typeof onMouseDown === 'function' && onMouseDown()
	})

	const validDroneLocation = latitude !== -9999 && longitude !== -9999

	const { isDistanceLessThenPixelsBetween, coordinatesBetween } =
		useDistanceBetweenPoints(gcsPosition, homePosition, 45, 45)

	return (
		<>
			{isSelected && (
				<>
					{isDistanceLessThenPixelsBetween && coordinatesBetween ? (
						<ErrorBoundary quiet>
							<BeaconMarker
								type='cluster'
								position={coordinatesBetween}
								color={markerColor}
							/>
						</ErrorBoundary>
					) : (
						<>
							<ErrorBoundary quiet>
								<BeaconMarker
									type='controller'
									position={gcsPosition}
									color={markerColor}
								/>
							</ErrorBoundary>
							<ErrorBoundary quiet>
								<BeaconMarker
									type='home'
									position={homePosition}
									color={markerColor}
								/>
							</ErrorBoundary>
						</>
					)}
					<ErrorBoundary quiet>
						<TrackHistory targetId={targetId} color={markerColor} />
					</ErrorBoundary>

					{speedLeaderPosition && (
						<ErrorBoundary quiet>
							<DetectionSpeedLeader
								detectionPosition={[latitude, longitude]}
								speedLeaderPosition={speedLeaderPosition}
								color={markerColor}
							/>
						</ErrorBoundary>
					)}
				</>
			)}
			{validDroneLocation && (
				<>
					<ErrorBoundary quiet>
						<BaseMarker
							position={[latitude, longitude]}
							icon={
								<DetectionMarkerIcon
									iconType={classification}
									color={markerColor}
								/>
							}
							iconSize={[40, 40]}
							iconAnchor={[20, 20]}
							onMouseDown={onMouseDown}
						>
							{locationVariance > 0 ? (
								<Circle
									latitude={latitude}
									longitude={longitude}
									radius={locationVariance}
									color={markerColor}
									weight={0}
									testId='location-variance'
								/>
							) : null}
						</BaseMarker>
					</ErrorBoundary>
					<ErrorBoundary quiet>
						<BaseMarker
							position={[latitude, longitude]}
							icon={
								<HighlightMarkerIcon
									isSelected={isSelected}
									isTracking={isTracking}
									isTrackingLocked={isTrackingLocked}
									color={colors.detections.selected.markerColor}
								/>
							}
							iconSize={[100, 100]}
							iconAnchor={[50, 50]}
						/>
					</ErrorBoundary>
				</>
			)}
		</>
	)
}

export default DetectionMarker
