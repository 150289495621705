// UI for Tanstack table pagination
import { Flex } from '@chakra-ui/react'

import { MdArrowBackIos, MdArrowForwardIos } from 'react-icons/md'

import IconButton from '@UI/IconButton/IconButton'
import Headings from '@UI/Headings/Headings'
import Select from '@UI/Select/Select'

import { type Table } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

type PaginationProps<TData> = {
	table: Table<TData>
	totalCount: number
}

const PAGINATION_OPTIONS = [
	{
		label: '5',
		value: 5,
	},
	{
		label: '10',
		value: 10,
	},
	{
		label: '25',
		value: 25,
	},
	{
		label: '50',
		value: 50,
	},
	{
		label: '100',
		value: 100,
	},
]

const Pagination = <TData = unknown,>({
	table,
	totalCount,
}: PaginationProps<TData>) => {
	const handleNumberOfRowsChange = ({ value }: { value: number }) => {
		table.setPageSize(value)
	}

	const { pageSize, pageIndex } = table.getState().pagination

	const hasRows = table.getRowModel().rows.length > 0

	const firstRow = pageIndex * pageSize + 1
	const lastRow = Math.min(firstRow + pageSize - 1, totalCount)

	const value = PAGINATION_OPTIONS.find((option) => option.value === pageSize)

	const { t } = useTranslation('app', { keyPrefix: 'global.tables' })

	return (
		<Flex justifyContent='end' alignItems='center' gap='8px'>
			<Headings.FieldHeading title={t('rowsPerPage')} />
			<Select
				options={PAGINATION_OPTIONS}
				value={value}
				onChange={handleNumberOfRowsChange}
				testId='pagination-pages'
			/>
			<Headings.FieldHeading
				title={
					hasRows ? `${firstRow}-${lastRow} ${t('of')} ${totalCount}` : '-'
				}
				testId='pagination-count'
			/>
			<Flex>
				<IconButton
					icon={<MdArrowBackIos />}
					testId='back-pagination'
					aria-label='back-page'
					onClick={() => table.previousPage()}
					isDisabled={!table.getCanPreviousPage()}
				/>
				<IconButton
					icon={<MdArrowForwardIos />}
					testId='forward-pagination'
					aria-label='forward-page'
					onClick={() => table.nextPage()}
					isDisabled={!table.getCanNextPage()}
				/>
			</Flex>
		</Flex>
	)
}

export default Pagination
