import { defineStyleConfig } from '@chakra-ui/react'
import FIGMA from '../_FIGMA'

const ButtonStyles = defineStyleConfig({
	baseStyle: {
		borderRadius: 2,
		textTransform: 'upperCase',
		fontWeight: 500,
		_disabled: {
			color: FIGMA.colors.disabled,
		},
	},
	variants: {
		plainlink: {
			fontWeight: 400,
			textDecoration: 'underline',
			textTransform: 'none',
			padding: 'unset',
		},
		link: {
			fontWeight: 400,
		},
		ghost: {
			border: '1px solid transparent',
		},
		sensor: {
			border: 0,
			borderRadius: '2px',
			bgColor: 'card_bg',
			justifyContent: 'left',
			_active: {
				bgColor: 'input_bg',
			},
		},
		quickActions: {
			border: 0,
			padding: '8px',
			borderRadius: '2px',
			bgColor: 'card_bg',
			color: 'body_text',
			_active: {
				bgColor: 'input_bg',
			},
			_hover: {
				_disabled: {
					bgColor: 'card_bg',
					opacity: 0.4,
				},
			},
		},
		icon: {
			bgColor: 'transparent',
			fontSize: 'xl',
			minWidth: 5,
			height: 5,
			width: 5,
			border: 'none',
		},
		navigation_icon: {
			bgColor: 'transparent',
			border: 'none',
			'& > svg': {
				minWidth: 6,
				height: 6,
				width: 6,
			},
			_active: {
				color: 'primary',
			},
		},
		action: {
			border: 'none',
			display: 'flex',
			color: FIGMA.colors.action_button_color,
			fontSize: 'xs',
			minW: '78px',
		},
		actions: {
			colorScheme: 'white',
			_hover: {
				bgColor: FIGMA.colors.card_background__disabled,
			},
		},
		delete: {
			color: 'error',
			border: `1px solid ${FIGMA.colors.error}`,
		},
		text: {
			textTransform: 'none',
			textDecoration: 'underline',
			color: 'primary',
			fontSize: 'sm',
			padding: '0px 2px',
		},
		mapControl: {
			color: FIGMA.colors.leaflet_control_color,
			bgColor: FIGMA.colors.leaflet_control_bg,
			padding: 0,
		},
		expand: {
			color: FIGMA.colors.action_button_color,
			transition: 'transform 0.2s ease-in-out',
			background: 'none',
		},
	},
})

export const Button = {
	defaultProps: {
		variant: 'outline',
	},
	sizes: {
		md: {
			h: '8',
			minW: '8',
		},
	},
	...ButtonStyles,
}
