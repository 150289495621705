import LoginLayout from '@Components/Layouts/Login'
import ResetPasswordForm from '@Forms/ResetPasswordForm/ResetPasswordForm'

const ResetPassword = () => {
	return (
		<LoginLayout>
			<ResetPasswordForm />
		</LoginLayout>
	)
}

export default ResetPassword
