// Packages
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Howl } from 'howler'

export type useAlertSound = {
	src?: string
	loop?: boolean
	autoplay?: boolean
	enabled?: boolean
}

const useAlertSound = (
	src: string = '',
	{ loop = false, autoplay = false, enabled }: useAlertSound = {}
) => {
	const [isPlaying, setIsPlaying] = useState(false)
	const sound = useMemo(() => {
		if (!src) {
			return undefined
		}

		return new Howl({ src, loop, autoplay })
	}, [src, loop, autoplay])

	const handlePlay = useCallback(() => {
		if (!sound?.playing()) {
			sound?.play()
		}
	}, [sound])

	const handleStop = useCallback(() => {
		sound?.stop()
	}, [sound])

	const handleOnPlay = useCallback(() => setIsPlaying(true), [])

	const handleOnPause = useCallback(() => setIsPlaying(false), [])

	const handleOnStop = useCallback(() => setIsPlaying(false), [])

	useEffect(() => {
		if (!sound) {
			return
		}

		sound.on('play', handleOnPlay)
		sound.on('pause', handleOnPause)
		sound.on('stop', handleOnStop)
		sound.on('end', handleOnStop)
		return () => {
			if (sound.playing()) {
				sound.stop()
			}

			sound.unload()
			sound.off('play', handleOnPlay)
			sound.off('pause', handleOnPause)
			sound.off('stop', handleOnStop)
			sound.off('end', handleOnStop)
		}
	}, [sound, handleOnPlay, handleOnPause, handleOnStop])

	useEffect(() => {
		if (enabled === undefined) {
			// Self control disabled
			return
		}

		if (!enabled && isPlaying) {
			handleStop()
		} else if (enabled && !isPlaying) {
			handlePlay()
		}

		return () => {
			handleStop()
		}
	}, [enabled])

	return { sound, play: handlePlay, stop: handleStop, isPlaying }
}

export default useAlertSound
