// Translations
import i18n from '@/i18n'

import { createColumnHelper } from '@tanstack/react-table'

import { Box, Checkbox } from '@chakra-ui/react'
import { MdPlayCircleOutline, MdSearch } from 'react-icons/md'

import IconButton from '@UI/IconButton/IconButton'

import { formatSecondsDuration } from '@/utils/formatUtils'
import { formatDateTz } from '@Utils/dates'
import { capitalize } from '@Utils/stringUtils'

import type { Event } from '@Store/types'

export type EventColumnType =
	| 'target_id'
	| 'start_time'
	| 'duration'
	| 'vendor'
	| 'protocol'
	| 'num_detections'
	| 'classification'
	| 'probability'
	| 'state'
	| 'false_positive'
	| 'track'
	| 'video_recordings'
	| 'confirmed_by'
	| 'drone_serial_number'

type ColumnInfo = {
	header: string
	type: EventColumnType
	dataTransform?: (value: string & number) => void
}

export const getConfirmedBy = (event: Partial<Event>) => {
	const confirmedBy: string[] = []
	if (event.camera_confirmed) confirmedBy.push('Camera')
	if (event.rf_confirmed) confirmedBy.push('RF')
	if (event.radar_confirmed) confirmedBy.push('Radar')
	if (event.drone_locator_confirmed) confirmedBy.push('DroneLocator')
	return confirmedBy.join(', ')
}

const columnHelper = createColumnHelper<Event>()

export const getColumns = (
	siteTimeZone: string,
	hasCamera: boolean,
	handleUpdateFalseAlarm: (id: number, value: boolean) => void,
	handleEventDetailsModalOpen: (event: Event) => void,
	handleVideoRecordingModalOpen: (recordings: number[]) => void
) => {
	const t = (key: string) => i18n.t(`pages:analytics.table.${key}`)
	const columns = [
		generateColumn({
			header: t('eventId'),
			type: 'target_id',
			dataTransform: (targetId: string) => targetId.split('-')[0],
		}),
		generateColumn({
			header: t('startTime'),
			type: 'start_time',
			dataTransform: (startTime: string) =>
				formatDateTz(startTime, siteTimeZone),
		}),
		generateColumn({
			header: t('duration'),
			type: 'duration',
			dataTransform: (duration: number) => formatSecondsDuration(duration),
		}),
		generateColumn({
			header: t('vendor'),
			type: 'vendor',
		}),
		generateColumn({
			header: t('protocol'),
			type: 'protocol',
		}),
		generateColumn({
			header: t('droneSerialNumber'),
			type: 'drone_serial_number',
		}),
		generateColumn({
			header: t('detectionsCount'),
			type: 'num_detections',
		}),
		generateColumn({
			header: t('classification'),
			type: 'classification',
			dataTransform: (classification: string) =>
				classification ? capitalize(classification) : t('unknown'),
		}),
		generateColumn({
			header: t('probability'),
			type: 'probability',
			dataTransform: (probability: number) =>
				probability ? `${(probability * 100).toFixed(2)}%` : '0%',
		}),
		columnHelper.accessor((row) => getConfirmedBy(row), {
			header: t('confirmedBy'),
			id: 'confirmed_by',
			cell: (info) => <Box>{info.getValue()}</Box>,
		}),
		generateColumn({
			header: t('state'),
			type: 'state',
			dataTransform: (state: string) => capitalize(state),
		}),
		columnHelper.accessor('false_positive', {
			header: t('falseAlarm'),
			cell: ({ row }) => (
				<Box paddingInlineStart={10}>
					<Checkbox
						isChecked={row.original.false_positive}
						onChange={(e) =>
							handleUpdateFalseAlarm(row.original.id, e.target.checked)
						}
					/>
				</Box>
			),
		}),
		columnHelper.display({
			header: t('track'),
			id: 'track',
			cell: ({ row }) => (
				<Box paddingInlineStart={2}>
					<IconButton
						icon={<MdSearch />}
						testId='track-icon'
						aria-label='track-icon'
						cursor='pointer'
						onClick={() => handleEventDetailsModalOpen(row.original)}
					/>
				</Box>
			),
		}),
	]

	if (hasCamera) {
		columns.push(
			columnHelper.display({
				header: t('videos'),
				id: 'video_recordings',
				cell: ({ row }) => (
					<Box paddingInlineStart={2}>
						<IconButton
							icon={<MdPlayCircleOutline />}
							testId='video-icon'
							aria-label='video-icon'
							isDisabled={!row.original.video_recordings}
							onClick={() =>
								handleVideoRecordingModalOpen(
									row.original.video_recordings as number[]
								)
							}
						/>
					</Box>
				),
			})
		)
	}

	return columns
}

const generateColumn = (column: ColumnInfo) => {
	return columnHelper.accessor(column.type as keyof Event, {
		header: column.header,
		cell: (info) =>
			column.dataTransform
				? column.dataTransform(info.getValue() as string & number & boolean)
				: info.getValue(),
	})
}

export const SORTABLE_COLUMNS: EventColumnType[] = [
	'target_id',
	'start_time',
	'duration',
	'vendor',
	'protocol',
	'state',
	'probability',
	'num_detections',
]
