import { Flex, Grid, GridItem, useToken } from '@chakra-ui/react'
import MapLegendSection from '@Components/MapControls/MapLegend/MapLegendSection'
import { useTranslation } from 'react-i18next'

// Icons
import HighlightMarkerIcon from '@Components/Markers/DetectionMarker/DetectionMarkerIcon/HighlightMarkerIcon'

const MapLegendSectionDetectionSelectors = () => {
	const { t } = useTranslation('map', {
		keyPrefix: 'legend.detectionSelectors',
	})

	const [selectedColor] = useToken('colors', [
		'detections.selected.markerColor',
	])

	return (
		<MapLegendSection title={t('title')}>
			<Grid
				templateColumns='1fr 65px'
				justifyContent='center'
				alignItems='center'
				rowGap={4}
			>
				<GridItem>{t('selector')}</GridItem>
				<Flex
					alignItems='center'
					justifyContent='center'
					sx={{ '& > svg': { width: '50px', height: '50px' } }}
				>
					<HighlightMarkerIcon
						isSelected={true}
						isTracking={false}
						color={selectedColor}
					/>
				</Flex>
				<GridItem>{t('cameraTracking')}</GridItem>
				<Flex
					alignItems='center'
					justifyContent='center'
					sx={{ '& > svg': { width: '42px', height: '42px' } }}
				>
					<HighlightMarkerIcon
						isSelected={false}
						isTracking={true}
						color={selectedColor}
					/>
				</Flex>
				<GridItem>{t('cameraTrackingSelector')}</GridItem>
				<Flex
					alignItems='center'
					justifyContent='center'
					sx={{ '& > svg': { width: '42px', height: '42px' } }}
				>
					<HighlightMarkerIcon
						isSelected={true}
						isTracking={true}
						color={selectedColor}
					/>
				</Flex>
				<GridItem>{t('cameraTrackingLocked')}</GridItem>
				<Flex
					alignItems='center'
					justifyContent='center'
					sx={{ '& > svg': { width: '42px', height: '42px' } }}
				>
					<HighlightMarkerIcon
						isSelected={false}
						isTracking={true}
						isTrackingLocked={true}
						color={selectedColor}
					/>
				</Flex>
			</Grid>
		</MapLegendSection>
	)
}

export default MapLegendSectionDetectionSelectors
