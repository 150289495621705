import { Fragment } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'

import { useAppSelector } from '@Store/index'
import { selectUserConcentricMarkerCoordinatesBySiteId } from '@Store/user/userSlice'
import type { Site } from '@Store/types'

import { DivIcon } from 'leaflet'
import { FeatureGroup, Marker } from 'react-leaflet'
import LatLon from 'geodesy/latlon-ellipsoidal-vincenty'
import Circle from '@Components/MapShapes/Circle/Circle'

import { useTheme } from '@chakra-ui/react'

type ConcentricMarkerProps = {
	siteId: Site['id']
}

const radii = [100, 250, 500, 1000, 2500, 5000, 10000]

const ConcentricMarker = ({ siteId }: ConcentricMarkerProps) => {
	const {
		semanticTokens: { colors },
	} = useTheme()

	const centerCoordinates = useAppSelector((state) =>
		selectUserConcentricMarkerCoordinatesBySiteId(state, siteId)
	)

	const labelCoordinates = (coordinates: [number, number], radius: number) => {
		const startPoint = new LatLon(coordinates[0], coordinates[1])
		return startPoint.destinationPoint(radius, 0)
	}

	const iconLabel = (label: string) =>
		new DivIcon({
			html: renderToStaticMarkup(
				<div
					style={{
						marginBlockStart: '12.5%',
						height: '100%',
						width: '100%',
						textAlign: 'center',
					}}
				>
					<div
						style={{
							color: colors.primary,
							backgroundColor: colors.panel_bg,
							paddingBlock: '0.125rem',
							paddingInline: '0.25rem',
							borderRadius: '0.25rem',
							fontWeight: 'bold',
							fontSize: '10px',
							width: 'auto',
							whiteSpace: 'nowrap',
							display: 'inline-block',
						}}
					>
						{label}
					</div>
				</div>
			),
			iconSize: [60, 16],
			iconAnchor: [30, 16],
		})

	return (
		centerCoordinates && (
			<FeatureGroup>
				{radii.map((radius, index) => (
					<Fragment key={index}>
						<Circle
							latitude={centerCoordinates[0]}
							longitude={centerCoordinates[1]}
							radius={radius}
							color={colors.primary}
							weight={2}
							fillColor='transparent'
							dashArray='8 8'
							interactive={false}
							testId={`concentric-marker-${radius}`}
						/>
						<Marker
							position={labelCoordinates(centerCoordinates, radius)}
							icon={iconLabel(`${radius} m`)}
						/>
					</Fragment>
				))}
			</FeatureGroup>
		)
	)
}

export default ConcentricMarker
