import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Icon } from '@chakra-ui/react'
import { MdNotifications, MdNotificationsOff } from 'react-icons/md'
import {
	selectAlertSoundEnabled,
	setAlertSoundEnabled,
} from '@Store/ui/uiSlice'
import { selectUserAlertSoundEnabled } from '@/store/user/userSlice'
import { useAppSelector } from '@Store/index'

const AlertSoundButton = () => {
	const userAlertSoundEnabled = useAppSelector(selectUserAlertSoundEnabled)
	const uiAlertSoundEnabled = useAppSelector(selectAlertSoundEnabled)

	const dispatch = useDispatch()
	const handleClickMuteSound = useCallback(
		() =>
			userAlertSoundEnabled &&
			dispatch(setAlertSoundEnabled(!uiAlertSoundEnabled)),
		[uiAlertSoundEnabled, userAlertSoundEnabled, dispatch]
	)
	return (
		<Icon
			cursor={userAlertSoundEnabled ? 'pointer' : 'inherit'}
			marginRight={1}
			as={
				uiAlertSoundEnabled && userAlertSoundEnabled
					? MdNotifications
					: MdNotificationsOff
			}
			color={!userAlertSoundEnabled ? 'gray.600' : undefined}
			boxSize={5}
			onClick={handleClickMuteSound}
		/>
	)
}

export default AlertSoundButton
