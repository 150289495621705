// React
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
// Chakra
import { Box, Center, Flex, Text } from '@chakra-ui/react'
// Components
import Field from '@Components/FormElements'
import Button from '@UI/Button/Button'
import { resetPasswordSchema } from '@Forms/ResetPasswordForm/ResetPasswordSchema'
// Forms
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
// Store
import { useResetPasswordMutation } from '@Store/user/usersApi'
import type { TypeOf } from 'zod'

const ResetPasswordForm = () => {
	const { t } = useTranslation('forms', {
		keyPrefix: 'profileForm',
	})
	const { t: validation } = useTranslation('forms', {
		keyPrefix: 'global.validation',
	})

	const { token } = useParams()
	const navigate = useNavigate()

	const [resetPassword] = useResetPasswordMutation()

	type Schema = TypeOf<typeof resetPasswordSchema>

	const methods = useForm<Schema>({
		resolver: zodResolver(resetPasswordSchema),
		defaultValues: { password: '', password_confirmation: '' },
	})

	const {
		register,
		formState: { errors, isDirty },
		handleSubmit,
	} = methods

	const handleResetPassword = async (data: Schema): Promise<void> => {
		try {
			await resetPassword({
				password: data.password,
				password_confirmation: data.password_confirmation,
				reset_password_token: token || '',
			}).unwrap()
			navigate('/')
		} catch (error) {
			console.error('unhandled password reset error')
		}
	}
	return (
		<FormProvider {...methods}>
			<Text
				fontSize='sm'
				color='label_color'
				alignSelf='center'
				textAlign='center'
			>
				{t('headings.newPassword')}
			</Text>
			<form onSubmit={handleSubmit(handleResetPassword)}>
				<Field.PasswordInput
					title={t('api.password')}
					register={register('password')}
					error={errors?.password?.message}
					testId='password'
					tooltip={
						<Flex direction='column'>
							<Box>{validation('min8char')}</Box>
							<Box>{validation('min1lowercase')}</Box>
							<Box>{validation('min1uppercase')}</Box>
							<Box>{validation('min1numeric')}</Box>
							<Box>{validation('min1special')}</Box>
							<Box>{validation('max128char')}</Box>
						</Flex>
					}
					isRequired
				/>
				<Field.PasswordInput
					title={t('api.password_confirmation')}
					register={register('password_confirmation')}
					error={errors?.password_confirmation?.message}
					testId='password-confirmation'
					isRequired
				/>
				<Center mt={50}>
					<Button
						variant='outline'
						type='submit'
						testId='forgot-reset'
						label={t('buttons.passwordReset')}
						size='sm'
						w='150px'
						isDisabled={!isDirty}
					/>
				</Center>
			</form>
		</FormProvider>
	)
}

export default ResetPasswordForm
