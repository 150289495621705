import {
	ADD_RADAR_MASK_DEFAULT_VALUES,
	type DefaultValues,
} from '@Components/Forms/RadarMaskForm/RadarMaskForm.schema'
import LatLonSpherical from 'geodesy/latlon-spherical'

import type { Radar } from '@Store/types'
import type { DetectionDetails } from '@Components/Site/Panels/QuickActions/MaskActions/RadarMaskButton'

const ELEVATION_TOLERANCE = 2
const RANGE_TOLERANCE_METERS = 30
const ALG_TOLERANCE_METERS = 30
const MIN_AZIMUTH = -60
const MIN_ELEVATION = -40
const MAX_ELEVATION = 40

// Logic taken from vue
export const getQuickActionsRadarMaskDefaultValues = (
	radar: Radar,
	detectionDetails: DetectionDetails
): DefaultValues => {
	const detectionPosition = new LatLonSpherical(
		detectionDetails.latitude,
		detectionDetails.longitude
	)
	const radarPosition = new LatLonSpherical(radar.latitude, radar.longitude)

	const distance = Math.round(radarPosition.distanceTo(detectionPosition))

	// Get minimum/maximum range
	let minRange = distance - RANGE_TOLERANCE_METERS
	if (minRange < radar.reach_min) {
		minRange = radar.reach_min
	}
	let maxRange = distance + RANGE_TOLERANCE_METERS
	if (maxRange > radar.reach_max) {
		maxRange = radar.reach_max
	}

	// Get minimum/maximum azimuth
	let azimuth = radarPosition.finalBearingTo(detectionPosition)
	azimuth -= radar.direction
	azimuth = ((azimuth + 180) % 360) - 180

	const azimuthTolerance =
		(Math.atan(RANGE_TOLERANCE_METERS / distance) * 180) / Math.PI

	let minAzimuth = azimuth - azimuthTolerance
	if (minAzimuth < MIN_AZIMUTH) {
		minAzimuth = MIN_AZIMUTH
	}

	let maxAzimuth = azimuth + azimuthTolerance
	if (maxAzimuth < MIN_AZIMUTH) {
		maxAzimuth = MIN_AZIMUTH
	}

	// Get minimum/maximum elevation
	let minElevation = detectionDetails.elevation - ELEVATION_TOLERANCE
	if (minElevation < MIN_ELEVATION) {
		minElevation = MIN_ELEVATION
	}
	let maxElevation = detectionDetails.elevation + ELEVATION_TOLERANCE
	if (maxElevation > MAX_ELEVATION) {
		maxElevation = MAX_ELEVATION
	}
	// Get RCS min value
	const minRcs = Math.floor(detectionDetails.rcs) - 10
	const maxRcs = Math.floor(detectionDetails.rcs) + 10

	return {
		...ADD_RADAR_MASK_DEFAULT_VALUES,
		range_min: minRange,
		range_max: maxRange,
		azimuth_min: Math.floor(minAzimuth),
		azimuth_max: Math.ceil(maxAzimuth),
		elevation_min: Math.floor(minElevation),
		elevation_max: Math.ceil(maxElevation),
		rcs_min: minRcs,
		rcs_max: maxRcs,
		max_agl: Math.round(detectionDetails.altitude + ALG_TOLERANCE_METERS),
	}
}
