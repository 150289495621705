type HomeControllerClusterIconProps = {
	color?: string
}

const HomeControllerClusterIcon = ({
	color,
}: HomeControllerClusterIconProps) => {
	return (
		<svg
			width='90'
			height='50'
			viewBox='0 0 90 50'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			style={{ transform: 'translate(-27px, -14px)' }}
		>
			<g>
				<rect
					width='85.097'
					height='37'
					transform='translate(3)'
					fill='#FFA726'
					fillOpacity='0.25'
				/>
				<path d='M3 0L3 37' stroke='#FFA726' strokeWidth='5' />
				<g data-testid={`cluster-icon-${color}`} fill={color}>
					<path d='M29.5015 9.42847L45.956 18.7346H29.5015H13.047L29.5015 9.42847Z' />
					<path d='M45.9777 18.7346H29.5061H13.0345L13.0344 31.1428H27.3045V24.6802H32.4745V31.1428H45.9777V18.7346Z' />
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M29.5867 4L11 14.4143L12.3895 17.0018L29.5878 7.21777L46.7074 16.7942L48.097 14.2067L29.5884 4.00094L29.5875 4.01347L29.5867 4Z'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M56.0969 20C56.0969 18.8954 56.9924 18 58.0969 18H82.0969C83.2015 18 84.0969 18.8954 84.0969 20V31C84.0969 32.1046 83.2015 33 82.0969 33H58.0969C56.9924 33 56.0969 32.1046 56.0969 31V20ZM62.0969 30C64.3061 30 66.0969 28.2091 66.0969 26C66.0969 23.7909 64.3061 22 62.0969 22C59.8878 22 58.0969 23.7909 58.0969 26C58.0969 28.2091 59.8878 30 62.0969 30ZM78.0969 30C80.3061 30 82.0969 28.2091 82.0969 26C82.0969 23.7909 80.3061 22 78.0969 22C75.8878 22 74.0969 23.7909 74.0969 26C74.0969 28.2091 75.8878 30 78.0969 30ZM74.0969 29H66.0969V31H74.0969V29Z'
					/>
					<rect x='61.0969' y='4' width='2' height='13' rx='1' />
					<rect x='77.0969' y='4' width='2' height='13' rx='1' />
					<path d='M45.5485 50L52.9716 41H38.1254L45.5485 50Z' />
				</g>
			</g>
		</svg>
	)
}

export default HomeControllerClusterIcon
