import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Button, Flex, Icon, Text, useTheme } from '@chakra-ui/react'
import { MdErrorOutline, MdClose } from 'react-icons/md'

import {
	useGetSiteLiveQuery,
	selectSiteAlertByTargetId,
	selectSiteName,
	clearSiteAlert,
} from '@Store/sites/sitesWsApi'
import { selectActiveTargetId, toggleActiveTargetId } from '@Store/ui/uiSlice'
import { useAppDispatch, useAppSelector } from '@Store/index'

import type { Detection } from '@Store/types'
import DetectionIcon from './DetectionIcon'
import { formatEventId, formatDistance } from '@Utils/formatUtils'

type DetectionHeaderProps = {
	detection: Detection
	stale?: true
}

const DetectionHeader = ({ detection, stale }: DetectionHeaderProps) => {
	const { siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)
	const targetId = detection.target_id
	const { t } = useTranslation('panels', {
		keyPrefix: 'detections.detectionHeader',
	})
	const dispatch = useAppDispatch()
	const { alert, siteName } = useGetSiteLiveQuery(siteId, {
		selectFromResult: ({ data }) => ({
			siteName: selectSiteName(data),
			alert: selectSiteAlertByTargetId(data, targetId),
		}),
	})
	const activeTargetId = useAppSelector(selectActiveTargetId)

	const {
		semanticTokens: { colors },
	} = useTheme()
	const markerColor =
		detection.state === 'whitelisted'
			? colors.detections.whitelisted
			: colors.detections.default

	const alertBgColor = alert && !stale ? colors.notifications.alert : undefined
	const alertTextColor =
		alert && !stale ? undefined : colors.notifications.stale

	const handleClickDetection = (targetId: Detection['target_id']) => {
		dispatch(toggleActiveTargetId(targetId))
	}

	const handleClearAlert = (targetId: Detection['target_id']) => {
		dispatch(clearSiteAlert(siteId, targetId))
	}

	// Show line of bearing if only one detection sensor, and no lat/long
	const showLobIcon = useMemo(
		() =>
			detection?.detection_contributions?.length === 1 &&
			['rfSensor', 'dsx'].includes(
				detection.detection_contributions[0].sensor_type
			) &&
			[0, null].includes(detection?.latitude) &&
			[0, null].includes(detection?.longitude),
		[detection]
	)

	return (
		<Flex
			bgColor={
				activeTargetId === detection.target_id
					? 'notifications.active_bg'
					: 'notifications.bg'
			}
			h='5rem'
			onClick={() => handleClickDetection(detection.target_id)}
		>
			<Flex justifyContent='center' alignItems='center' w='5rem'>
				{alert && (
					<Icon
						as={MdErrorOutline}
						color={`notifications.${stale ? 'stale' : 'warning'}`}
						// color={stale ? 'grey' : '#FB8C00'}
						boxSize='2rem'
					/>
				)}
				{!alert && (
					<DetectionIcon
						classification={detection.classification}
						showLob={showLobIcon}
						color={markerColor}
					/>
				)}
			</Flex>
			<Flex
				flexGrow={1}
				w='100%'
				justifyContent='center'
				flexDirection='column'
			>
				{detection.drone_locator_confirmed && (
					<Text color={stale ? 'grey' : 'error'} fontSize='0.75rem'>
						DroneLocator
					</Text>
				)}
				<Text
					fontSize='0.875rem'
					fontWeight={600}
					color={stale ? 'grey' : 'white'}
					sx={{
						':first-letter': {
							textTransform: 'uppercase',
						},
					}}
				>
					{detection.classification}
				</Text>
				<Text fontSize='0.75rem' fontWeight={400} color='headings'>
					{alert && alert.zone.name}
					{!alert && siteName}
				</Text>
			</Flex>
			<Flex
				bgColor={alertBgColor}
				flexDirection='column'
				w='16ch'
				textAlign='center'
				justifyContent='center'
				position='relative'
			>
				{alert && (
					<>
						<Text
							fontSize='0.75rem'
							fontWeight={600}
							textTransform='uppercase'
							color={alertTextColor}
						>
							{t('alertHeading')}
						</Text>
						<Box position='absolute' top={0.5} right={0.5}>
							<Button
								variant='unstyled'
								minW={0}
								h='auto'
								onClick={(e) => {
									handleClearAlert(detection.target_id)
									e.stopPropagation()
								}}
							>
								<Icon as={MdClose} boxSize='1rem' />
							</Button>
						</Box>
					</>
				)}
				<Text fontSize='1rem' fontWeight={600} color={alertTextColor}>
					{formatDistance(detection.site_distance)}
				</Text>
				<Text fontSize='xs' color='label_color'>
					{formatEventId(detection.target_id)}
				</Text>
			</Flex>
		</Flex>
	)
}

export default DetectionHeader
