import { defineStyleConfig } from '@chakra-ui/react'

const StepperStyles = defineStyleConfig({
	variants: {
		instructions: {
			indicator: {
				w: '20px',
				h: '20px',
				color: 'body_text__dark',
				border: 'none',
				bgColor: 'primary',
			},
			number: {
				fontSize: '12px',
			},
			separator: {
				'&[data-orientation=vertical]': {
					left: '10px',
					top: '30px',
					width: '1px',
				},
			},
			title: {
				fontSize: 'sm',
				fontWeight: 'normal',
			},
			description: {
				color: 'body_text',
				minH: '42px',
			},
		},
		calibration: {
			indicator: {
				w: '20px',
				h: '20px',
				color: 'primary',
			},
			number: {
				fontSize: '12px',
				'&[data-status=active]': {
					color: 'primary',
				},
				'&[data-status=complete]': {
					color: 'body_text__dark',
				},
				'&[data-status=incomplete]': {
					color: 'label_color',
				},
			},
			separator: {
				'&[data-orientation=vertical]': {
					left: '10px',
					top: '30px',
					width: '1px',
					bgColor: 'disabled',
				},
			},
			title: {
				fontSize: 'm',
				fontWeight: 500,
				'&[data-status=active]': {
					color: 'primary',
				},
				'&[data-status=complete]': {
					color: 'body_text',
				},
				'&[data-status=incomplete]': {
					color: 'label_color',
				},
			},
			description: {
				color: 'label_color',
				minH: '42px',
			},
		},
	},
})

export const Stepper = {
	...StepperStyles,
}
