// Redux
import { configureStore, addListener } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import type { ThunkAction, Action } from '@reduxjs/toolkit'
import type { TypedUseSelectorHook } from 'react-redux'

// Redux Reducers
import authReducer from './auth/authSlice'
import userReducer from './user/userSlice'
import uiReducer from './ui/uiSlice'
import camerasReducer from './cameras/camerasSlice'
import systemReducer from './system/systemSlice'

// Redux Toolkit Query
import { baseApi } from './baseApi'

// Redux Middleware
import listenerMiddleware from './middleware/index'

// Redux Store
export const store = configureStore({
	reducer: {
		[baseApi.reducerPath]: baseApi.reducer,
		auth: authReducer,
		user: userReducer,
		ui: uiReducer,
		cameras: camerasReducer,
		system: systemReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware()
			.prepend(listenerMiddleware)
			.concat([baseApi.middleware]),
})

// Redux Types
export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>

// Typed Redux Hooks
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const addAppListener = addListener.withTypes<RootState, AppDispatch>()
