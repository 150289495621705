import { useEffect, useState } from 'react'

const useValidCoordinates = (
	latitude: number | undefined,
	longitude: number | undefined
) => {
	const [prevLat, setPrevLat] = useState<number | undefined>(latitude)
	const [prevLng, setPrevLng] = useState<number | undefined>(longitude)
	useEffect(() => {
		const reg = /^-?\d+(\.\d*)?$/
		if (reg.test(String(latitude))) setPrevLat(Number(latitude))
		if (reg.test(String(longitude))) setPrevLng(Number(longitude))
	}, [latitude, longitude])

	if (latitude && longitude) {
		if (isNaN(latitude) || isNaN(longitude)) {
			if (prevLat && prevLng) {
				return [prevLat, prevLng]
			} else {
				return [0, 0]
			}
		}
	}

	return [latitude, longitude]
}

export default useValidCoordinates
