// React
// import { useTranslation } from 'react-i18next'
// Chakra
import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
} from '@chakra-ui/react'
// Components
import Tooltip from '@UI/Tooltip/Tooltip'
// Types
import type { FC } from 'react'
import type { RegisterType } from '@/components/FormElements/types/RegisterType'
import type { FormElementProps } from '@Components/FormElements/types/FormElementProps'
import type { LatLngExpression } from 'leaflet'
import { forward } from 'mgrs'
// import TextInput from '@Components/FormElements/TextInput/TextInput'

type MgrsInputProps = FormElementProps & {
	defaultValue?: LatLngExpression
	errors: [string?, string?]
	values: {
		latitude?: number
		longitude?: number
	}
	onChange?: (value: string) => void
} & RegisterType<string>

// TODO: needs onBlur for isDirty support
const MgrsInput: FC<MgrsInputProps> = ({
	// id = undefined,
	title,
	// type,
	// defaultValue = 0,
	errors,
	// register,
	disabled = undefined,
	// testId,
	tooltip,
	values = undefined,
	// onChange = undefined,
}) => {
	// const htmlId = id ?? register?.name ?? type

	// const { t } = useTranslation('forms', {
	// 	keyPrefix: 'global.validation',
	// })

	// const min = type === 'latitude' ? -90 : -180
	// const max = type === 'latitude' ? 90 : 180
	//
	// const validValue = validateLatLon(String(value), type)
	//
	// const isInvalid = !!error || (!!value && !validValue)
	// const errorMessage = !!value && !validValue ? t('range', { min, max }) : error

	const isInvalid = false
	const htmlId = 'MGRS_TEMPORARY_ID'
	if (!values?.latitude || !values?.longitude) return null

	const mgrs = forward([values.longitude, values.latitude])
	// console.log('MGRS VALUE: ', mgrs)

	return (
		<Tooltip label={tooltip} type='info'>
			<FormControl isInvalid={isInvalid} isDisabled={disabled}>
				<FormLabel htmlFor={htmlId} aria-labelledby={title}>
					{title}
				</FormLabel>
				{mgrs}
				<Input
					id={htmlId}
					// defaultValue={defaultValue}
					pattern='-*[0-9]*(.[0-9]+)?'
					// onChange={onChange}
					value={mgrs}
				/>
				{/*	<NumberInputField {...(register || {})} data-testid={testId} />*/}
				{/*</NumberInput>*/}
				<FormErrorMessage>
					{errors?.map((errorMessage) => errorMessage)}
				</FormErrorMessage>
			</FormControl>
		</Tooltip>
	)
}

export default MgrsInput
