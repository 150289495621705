// React
import { useMemo, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
// Store
import { useAppDispatch, useAppSelector } from '@Store/index'
import { selectUserRole, selectUserSites } from '@Store/user/userSlice'
import { selectIsAuthenticated } from '@Store/auth/authSlice'
import { logout } from '@Store/auth/authSlice'

// Types
import type { UserRole, UserSiteAssociationRole } from '@Store/types'
// Constants
const PERMISSIONS = {
	admin: 0,
	manager: 1,
	site_manager: 2,
	operator: 3,
	guest: 4,
}

type AuthUserRole = UserRole | UserSiteAssociationRole

type UseAuth = {
	role: AuthUserRole
	isAuthenticated: boolean
	isAdmin: boolean
	isManager: boolean
	isSiteManager: boolean
	isOperator: boolean
	isGuest: boolean
}

// HOW IT WORKS? Hook returns nested roles from top to bottom.
// F.E. if role is Admin, then all roles above will be true
// if role is guest, then just isGuest will be true
// if role is manager, then isManager, and roles below "isSiteManager", "isOperator", "isGuest" will be true

const useAuth = (): UseAuth => {
	const authState: UseAuth = useMemo(
		() => ({
			role: 'guest',
			isAuthenticated: false,
			isAdmin: false,
			isManager: false,
			isSiteManager: false,
			isOperator: false,
			isGuest: false,
		}),
		[]
	)

	const isAuthenticated = useAppSelector(selectIsAuthenticated)

	// Dynamically handle the JWT token expiry time ticking over
	const dispatch = useAppDispatch()
	const wasAuthenticated = useRef<boolean>(false)
	useEffect(() => {
		if (wasAuthenticated.current && isAuthenticated === false) {
			dispatch(logout())
		} else if (isAuthenticated) wasAuthenticated.current = true
	}, [isAuthenticated, dispatch])

	const role = useAppSelector(selectUserRole)

	const userSites = useAppSelector(selectUserSites)
	const { siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)

	// Check user role roles:
	return useMemo(() => {
		if (!isAuthenticated) return authState
		if (!role) return authState

		const getRoleWeight = (role: AuthUserRole) => {
			return (PERMISSIONS as Record<string, number>)[role]
		}

		const populatePermissionsTree = (role: AuthUserRole) => {
			return {
				isAdmin: getRoleWeight(role) <= getRoleWeight('admin'),
				isManager: getRoleWeight(role) <= getRoleWeight('manager'),
				isSiteManager: getRoleWeight(role) <= getRoleWeight('site_manager'),
				isOperator: getRoleWeight(role) <= getRoleWeight('operator'),
				isGuest: getRoleWeight(role) <= getRoleWeight('guest'),
			}
		}

		// TODO: replace this xmas tree with selector, e.g.
		// const role = useAppSelector((state) => selectUserRoleBySiteId(state, siteId)

		if (role === 'user') {
			if (userSites && userSites.length) {
				const currentUserSiteRole = userSites?.find(
					(site) => site?.site_id === siteId
				)?.role
				if (currentUserSiteRole) {
					return {
						...authState,
						...populatePermissionsTree(currentUserSiteRole),
						isAuthenticated,
						role: currentUserSiteRole,
					}
				} else {
					return {
						...authState,
						isAuthenticated,
						role: userSites?.[0]?.role || role,
						...populatePermissionsTree(userSites?.[0]?.role),
					}
				}
			} else {
				return {
					...authState,
					isAuthenticated,
				}
			}
		} else {
			return {
				...authState,
				...populatePermissionsTree(role),
				isAuthenticated,
				role,
			}
		}
	}, [isAuthenticated, role, userSites, authState, siteId])
}

export default useAuth
