import { disconnectionTimeoutMs } from '@Constants/site'
import type { AppStartListening } from '@Store/middleware'

import { setShowNetworkDisconnected } from '../ui/uiSlice'
import {
	setNetworkConnected,
	setNetworkDisconnected,
} from '../system/systemSlice'

const addNetworkStatusMiddleware = (startListening: AppStartListening) => {
	let disconnectionTimeout: ReturnType<typeof setTimeout> | null = null

	startListening({
		actionCreator: setNetworkConnected,
		effect: (action, { dispatch }) => {
			disconnectionTimeout && clearTimeout(disconnectionTimeout)
			dispatch(setShowNetworkDisconnected(false))
		},
	})
	startListening({
		actionCreator: setNetworkDisconnected,
		effect: (action, { dispatch, getState }) => {
			disconnectionTimeout = setTimeout(() => {
				if (getState().system.networkStatus.disconnected)
					dispatch(setShowNetworkDisconnected(true))
			}, disconnectionTimeoutMs)
		},
	})
}

// Exports
export { addNetworkStatusMiddleware }
