import { memo, useEffect, useMemo, useState } from 'react'
import { useMap } from 'react-leaflet'
import type { LatLngExpression, LatLngTuple, LeafletMouseEvent } from 'leaflet'
import { Text, Flex } from '@chakra-ui/react'

const CoordinatesControl = ({ center }: { center?: LatLngExpression }) => {
	const memoizedCenter = useMemo(() => {
		return center as LatLngTuple
	}, [center])

	if (!memoizedCenter) return null

	return (
		<Flex
			className='c2-coordinates-control'
			data-testid='coordinates-control'
			bgColor='leaflet.control_transparent_bg'
			color='leaflet.control_text'
			gap={1}
			paddingInline={1}
		>
			<Coordinates center={memoizedCenter} />
		</Flex>
	)
}

const Coordinates = ({ center }: { center: LatLngTuple }) => {
	const map = useMap()
	const [coords, setCoords] = useState<LatLngTuple>(center)

	useEffect(() => {
		if (!map) return
		const updateCoordinates = (e: LeafletMouseEvent) => {
			const { lat, lng } = e.latlng
			setCoords([lat, lng % 180])
		}
		map.addEventListener('mousemove', updateCoordinates)
		return () => {
			map.removeEventListener('mousemove', updateCoordinates)
		}
	}, [map])

	const lat = coords[0].toFixed(8)
	const lng = coords[1].toFixed(8)

	return (
		<>
			<Text>{lat},</Text>
			<Text>{lng}</Text>
		</>
	)
}

export default memo(CoordinatesControl)
