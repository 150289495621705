import { useState } from 'react'
import { Divider, Flex, Text } from '@chakra-ui/react'
import { formatInTimeZone } from 'date-fns-tz'
import enAU from 'date-fns/locale/en-AU'
import type { Note } from '@/store/types'
import IconButton from '@UI/IconButton/IconButton'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'

const NOTE_PREVIEW_LENGTH = 60

const Note = ({ note, timeZone }: { note: Note; timeZone: string }) => {
	const { body, name, created_at } = note

	const formattedDate = formatInTimeZone(
		created_at,
		timeZone,
		'yyyy-MM-dd HH:mm:ss zzz',
		{
			locale: enAU,
		}
	)

	const upperCasedLetter = {
		':first-letter': {
			textTransform: 'uppercase',
		},
	}

	const [isTruncated, setTruncated] = useState<boolean>(
		body.length >= NOTE_PREVIEW_LENGTH
	)
	const [isArrowVisible] = useState<boolean>(body.length >= NOTE_PREVIEW_LENGTH)

	return (
		<Flex direction='column' gap={1}>
			<Text color='disabled' fontSize='xs' sx={upperCasedLetter}>
				{name}
			</Text>
			<Flex
				justifyContent='space-between'
				paddingInlineEnd={4}
				marginBlockEnd={1}
			>
				<Flex w='100%'>
					<Text
						fontSize='sm'
						lineHeight='1.2'
						isTruncated={isTruncated}
						sx={upperCasedLetter}
						w='423px'
					>
						{body}
					</Text>
				</Flex>
				{isArrowVisible && (
					<Flex justifyContent='center'>
						<IconButton
							w='32px'
							height='18px'
							aria-label='expand'
							testId='expand-note'
							icon={<MdOutlineKeyboardArrowDown />}
							onClick={() => setTruncated(!isTruncated)}
							variant='expand'
							isExpanded={isTruncated}
						/>
					</Flex>
				)}
			</Flex>

			<Text color='label_color' fontSize='sm'>
				{formattedDate}
			</Text>
			<Divider color='label_color' marginBlockEnd={2} />
		</Flex>
	)
}

export default Note
