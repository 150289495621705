import { useTranslation } from 'react-i18next'

import { Center, Flex } from '@chakra-ui/react'
import FetchError from '@UI/FetchError/FetchError'
import Headings from '@UI/Headings/Headings'
import RadarChart from '@UI/Charts/RadarChart'
import Skeleton from '@UI/Skeleton/Skeleton'

import { useGetTrackSummaryQuery } from '@Store/analytics/analyticsApi'
import {
	useGetSiteRfSensorsQuery,
	selectAllProtocols,
	selectAllVendors,
} from '@Store/rfSensors/rfSensorsApi'

import { DURATION_LABELS, FREQUENCY_LABELS } from '@Constants/analytics'

import { useEventFilter } from './EventDetailsTable/EventFilterContext'

type TrackSummaryChartsProps = {
	startTime: string
	endTime: string
	siteId: number
}

const ChartWrapper = ({ children }: { children: React.ReactNode }) => (
	<Flex
		position='relative'
		width='25%'
		minW='270px'
		height='332px'
		direction='column'
		alignItems='center'
		gap='8px'
	>
		{children}
	</Flex>
)

const formatData = (data: Record<string, number>, chartLabels: string[]) =>
	chartLabels.map((label) => ({
		label,
		value: data[label] ? data[label] : 0,
	}))

const TrackSummaryCharts = ({
	startTime,
	endTime,
	siteId,
}: TrackSummaryChartsProps) => {
	const { t } = useTranslation('pages', {
		keyPrefix: 'analytics.trackSummary',
	})

	const { filterParams } = useEventFilter()

	const { isSuccess, isLoading, isError, refetch, isFetching, data } =
		useGetTrackSummaryQuery({
			siteId: Number(siteId),
			format: 'graph',
			startTime: startTime,
			endTime: endTime,
			...filterParams,
		})

	const { protocolsLabels, vendorsLabels } = useGetSiteRfSensorsQuery(
		{ siteId },
		{
			selectFromResult: ({ data }) => ({
				protocolsLabels: selectAllProtocols(data),
				vendorsLabels: selectAllVendors(data),
			}),
		}
	)

	const vendorsData = formatData(data?.by_vendor ?? {}, vendorsLabels)
	const frequenciesData = formatData(data?.by_frequency ?? {}, FREQUENCY_LABELS)
	const durationsData = formatData(data?.by_duration ?? {}, DURATION_LABELS)
	const protocolsData = formatData(data?.by_protocol ?? {}, protocolsLabels)

	return (
		<>
			<Skeleton isLoaded={!isLoading && !isFetching} w='100%' minH='332px'>
				{isSuccess && (
					<Flex
						position='relative'
						justifyContent='space-between'
						flexWrap='wrap'
					>
						<ChartWrapper>
							<Headings.ChartHeading title={t('vendors')} />
							<RadarChart data={vendorsData} />
						</ChartWrapper>

						<ChartWrapper>
							<Headings.ChartHeading title={t('durations')} />
							<RadarChart data={durationsData} />
						</ChartWrapper>

						<ChartWrapper>
							<Headings.ChartHeading title={t('frequencies')} />
							<RadarChart data={frequenciesData} />
						</ChartWrapper>

						<ChartWrapper>
							<Headings.ChartHeading title={t('protocols')} />
							<RadarChart data={protocolsData} />
						</ChartWrapper>
					</Flex>
				)}
				{isError && (
					<Center minH='332px'>
						<FetchError
							refetch={refetch}
							entity={t('entities.trackSummaryCharts')}
						/>
					</Center>
				)}
			</Skeleton>
		</>
	)
}

export default TrackSummaryCharts
