import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Badge,
	Box,
	CloseButton,
	Flex,
	type AlertProps,
	type UseToastOptions,
} from '@chakra-ui/react'

type ToastProps = {
	onClose: () => void
	errorCount?: number
} & AlertProps &
	UseToastOptions

const Toast = ({
	status = 'error',
	title,
	description = '',
	onClose,
	isClosable = true,
	variant = 'left-accent',
	errorCount = 0,
}: ToastProps) => (
	<Alert
		status={status}
		variant={variant}
		alignItems='flex-start'
		justifyContent='space-between'
	>
		<AlertIcon />
		<Box flex={1}>
			<Flex w='100%' justifyContent='space-between' alignItems='flex-start'>
				<Flex direction='column' flex={1}>
					{title && <AlertTitle>{title}</AlertTitle>}
					{description && <AlertDescription>{description}</AlertDescription>}
				</Flex>
				<Flex>
					{errorCount > 1 && (
						<Badge variant='solid' colorScheme='error' fontWeight={600}>
							{errorCount}
						</Badge>
					)}
				</Flex>
			</Flex>
		</Box>
		{isClosable && (
			<CloseButton
				alignSelf='flex-start'
				position='relative'
				right={-1}
				top={-1}
				onClick={onClose}
			/>
		)}
	</Alert>
)

export default Toast
