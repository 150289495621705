// Base Redux Toolkit Query API
import type { RootState } from '@Store/index'
import { baseApi } from '@Store/baseApi'
import {
	createSelector,
	createDraftSafeSelector,
	current,
} from '@reduxjs/toolkit'
import { selectJwtToken } from '../auth/authSlice'
import { logout } from '../auth/authSlice'
import { siteWarningsApi } from '../siteWarnings/siteWarningsApi'
import { cotsApi } from '../cots/cotsApi'
import { uiLoggerApi } from '../ui/uiLoggerApi'

// Reconnecting websocket-ts library
import { WebsocketBuilder, ExponentialBackoff } from 'websocket-ts'
import type { Websocket } from 'websocket-ts'

// Constants
import {
	wsUrl,
	wsReconnectInitialDelay,
	wsReconnectMultiplyTimes,
} from '@Constants/api'
import {
	detectionTimeoutMs,
	droneLocatorDetectionTimeoutMs as droneLocatorDetectionTimeoutMsDefault,
	detectionGarbageCollectInterval,
} from '@Constants/detections'
import { smarthubTimeoutMs } from '@Constants/site'

// Types
import type {
	Site,
	SiteExport,
	SiteInstallation,
	SiteInstallationExport,
	SiteLive,
	Detection,
	DetectionContribution,
	Alert,
	Camera,
	Radar,
	RfSensor,
	Disruptor,
	UnregisteredSensor,
} from '@Store/types'

import {
	isDsxDisabled,
	isDisruptorDisabled,
	isEveryDisruptorDisabled,
	isEveryDsxDisabled,
} from '@Utils/installations'

// Actions
import {
	updateSeenContributors,
	addTrackHistory,
	deleteDetectionData,
} from '../ui/uiSlice'
import {
	setNetworkAuthenticating,
	setNetworkSubscribing,
	setNetworkConnected,
	setNetworkDisconnected,
	setNetworkReconnecting,
} from '../system/systemSlice'
import { sitesApi } from './sitesApi'

// Websocket
let ws: Websocket | undefined = undefined

// Detections garbage collector
let gc: ReturnType<typeof setInterval> | null = null
let droneLocatorDetectionTimeoutMs = droneLocatorDetectionTimeoutMsDefault

let smarthubUpdateCheck: ReturnType<typeof setInterval> | null = null

export const sitesWsApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		getSiteLive: builder.query({
			async queryFn(siteId: Site['id'], _queryApi, _extraOptions, fetchWithBQ) {
				const siteResult = await fetchWithBQ(`/api/sites/${siteId}/export`)

				if (siteResult.error) return { error: siteResult.error }

				const site = siteResult.data as SiteExport

				const installations: SiteInstallationExport[] = (
					(site.sentries ?? []) as SiteInstallation[]
				)
					// Sort Installations by ID
					.sort((a, b) => a.id - b.id)
					.map(
						(installation: SiteInstallationExport) =>
							({
								...installation,
								// Sort Devices by Direction
								cameras: ((installation.cameras || []) as Camera[]).sort(
									(a, b) => a.direction - b.direction
								),
								disruptors: (
									(installation.disruptors || []) as Disruptor[]
								).sort((a, b) => a.direction - b.direction),
								radars: ((installation.radars || []) as Radar[]).sort(
									(a, b) => a.direction - b.direction
								),
								rf_sensors: (
									(installation.rf_sensors || []) as RfSensor[]
								).sort((a, b) => a.direction - b.direction),
								gps_compasses: installation.gps_compasses || [],
							}) as SiteInstallation
					)

				const initialSiteData = {
					...site,
					installations,
					site_markers: site.site_markers ?? [],
					zones: site.zones ?? [],
				}

				delete initialSiteData.sentries

				// Fetch the drone_locator_detection_timeout
				if (site.drone_locator_detection_timeout === 0)
					droneLocatorDetectionTimeoutMs = 1000
				else
					droneLocatorDetectionTimeoutMs =
						site.drone_locator_detection_timeout * 1000

				return {
					data: { ...initialSiteData } as SiteLive,
				}
			},

			// remove the site from rtk query cache as soon as the useGetSiteLiveQuery is unmounted
			keepUnusedDataFor: 0,

			async onCacheEntryAdded(
				siteId,
				{
					updateCachedData,
					cacheDataLoaded,
					cacheEntryRemoved,
					getState,
					dispatch,
				}
			) {
				try {
					// wait for the initial queries to resolve before attempting websocket connection
					await cacheDataLoaded

					// once the above resolves, set up the websocket
					if (ws === undefined) {
						const wsListener = (i: Websocket, e: MessageEvent) => {
							const data = JSON.parse(e.data)
							//
							// handle invalid token
							if (
								data.type &&
								data.type === 'disconnect' &&
								data.reason === 'unauthorized'
							) {
								console.log('ws: unauthorized token')
								dispatch(logout())
								i.close() // stop reconnection attempts
							}
							//
							// handle invalid token signature
							else if (data.error && data.error === 'signature is invalid') {
								console.log('ws: invalid token signature')
								dispatch(logout())
								i.close() // stop reconnection attempts
							}
							//
							// handle expired token by re-authenticating
							else if (data.Type && data.Type === 'unauthenticated') {
								console.log('ws token re-auth')
								dispatch(setNetworkDisconnected())
								dispatch(setNetworkSubscribing(false))
								dispatch(setNetworkAuthenticating(true))
								const token = selectJwtToken(getState() as RootState)
								i.send(
									JSON.stringify({
										command: 'authenticate',
										options: {
											auth_options: {
												token,
											},
										},
									})
								)
							}
							//
							// handle core-api has confirmed the connection
							else if (data.Type && data.Type === 'confirm_connection') {
								console.log('ws connection confirmed')
								dispatch(setNetworkConnected())
								dispatch(setNetworkReconnecting(false))
							}
							//
							// handle core-api has confirmed the authentication
							else if (data.Type && data.Type === 'confirm_authentication') {
								console.log('ws authentication confirmed')
								dispatch(setNetworkConnected())
								dispatch(setNetworkAuthenticating(false))
								dispatch(setNetworkSubscribing(true))
								i.send(
									JSON.stringify({
										command: 'subscribe',
										identifier: {
											channel: 'SitesChannel',
										},
										options: {
											site_options: {
												all_sites: false,
												site_id: siteId,
											},
										},
									})
								)
							} else if (data.identifier) {
								const { site_id } = data.identifier
								if (Number(site_id) === Number(siteId)) {
									//
									// handle Site subscription + populate initial site data
									if (data.type && data.type === 'confirm_subscribe_site') {
										console.log('ws subscription confirmed')
										dispatch(setNetworkSubscribing(false))

										const sitePayload: SiteExport = data.message

										// doesn't belong here
										delete sitePayload.sentries
										delete (sitePayload as SiteExport & { client: unknown })
											.client

										updateCachedData((draft) => {
											return {
												...draft,
												...sitePayload,
												alerts: draft?.alerts ?? [],
												detections: draft?.detections ?? [],
												smarthubs: draft?.smarthubs ?? [],
												discovery: draft?.discovery ?? [],
											}
										})
									}
									//
									// handle core-api making arbitrary decision to close the socket
									else if (
										data.type &&
										data.type === 'confirm_unsubscribe_site'
									) {
										// Log this back to core ui logs
										const message =
											'core-api triggered unsubscribe and disconnect'
										dispatch(
											uiLoggerApi.endpoints.uiLogger.initiate({
												level: 'error',
												message,
												siteId,
											})
										)
										// Rely on socket disconnect to trigger reconnect
										console.log(message)
									}
									//
									// handle individual 'space' updates
									else {
										const { space, action, data: payload } = data.message
										//
										// update Site data
										if (space === 'sites') {
											// TODO: core send a simple object
											const sitePayload =
												action === 'partial_update' ? payload : payload[0]

											// these don't belong here
											delete sitePayload.sentries
											delete sitePayload.site_markers

											updateCachedData((draft) => ({
												...draft,
												...sitePayload,
											}))

											// ignore partial updates triggered by PATCH request
											if (action !== 'partial_update')
												dispatch(
													sitesApi.util.updateQueryData(
														'getSitesList',
														undefined,
														(draft) => {
															const sitePosition = draft.findIndex(
																(site) => site.id === sitePayload.id
															)
															if (
																sitePosition &&
																draft &&
																draft[sitePosition] &&
																sitePayload
															) {
																const { id, name, client_id } = sitePayload
																draft[sitePosition].id = id
																draft[sitePosition].name = name
																draft[sitePosition].client_id = client_id
															}
														}
													)
												)
										}
										//
										// update Installation data
										else if (space === 'sentries') {
											dispatch(
												sitesWsApi.util.updateQueryData(
													'getSiteLive',
													siteId,
													(draft) => {
														// TODO: core send a simple object
														const installationPayload = payload[0]

														if (draft?.installations) {
															if (action === 'create') {
																const installation = {
																	cameras: [],
																	disruptors: [],
																	radars: [],
																	rf_sensors: [],
																	gps_compasses: [],
																	...installationPayload,
																} as SiteInstallation
																draft.installations.push(installation)
															} else if (action === 'update') {
																const installationIndex =
																	draft.installations.findIndex(
																		(installation) =>
																			installation.id === installationPayload.id
																	)
																if (installationIndex > -1) {
																	draft.installations[installationIndex] = {
																		...draft.installations[installationIndex],
																		...installationPayload,
																	}
																}
															} else if (action === 'delete') {
																draft.installations =
																	draft?.installations.filter(
																		(installation) =>
																			installation.id !== installationPayload.id
																	)
															}
														}
													}
												)
											)
										}
										//
										// update RfSensors data
										else if (
											space === 'rf_sensors' ||
											space === 'dsx_sensors'
										) {
											dispatch(
												sitesWsApi.util.updateQueryData(
													'getSiteLive',
													siteId,
													(draft) => {
														// TODO: core send a simple object
														const sensorPayload = payload[0]

														// shouldn't be here
														delete sensorPayload.Sentry

														if (draft?.installations?.length) {
															const installationIndex =
																draft.installations.findIndex(
																	(installation) =>
																		installation.id === sensorPayload.sentry_id
																)
															if (installationIndex > -1) {
																const rfSensors =
																	draft.installations[installationIndex]
																		?.rf_sensors

																if (action === 'create') {
																	rfSensors.push(sensorPayload)
																} else if (action === 'update') {
																	const rfSensorIndex = rfSensors?.findIndex(
																		(rfSensor) =>
																			rfSensor.id === sensorPayload.id
																	)
																	if (rfSensorIndex > -1)
																		rfSensors[rfSensorIndex] = {
																			...sensorPayload,
																			RfFilter:
																				rfSensors[rfSensorIndex].RfFilter ?? [],
																		}
																} else if (action === 'delete') {
																	draft.installations[
																		installationIndex
																	].rf_sensors = rfSensors?.filter(
																		(sensor) => sensor.id !== sensorPayload.id
																	)
																}
															}
														}
													}
												)
											)
										}
										//
										// update Disruptors data
										else if (space === 'cannons') {
											dispatch(
												sitesWsApi.util.updateQueryData(
													'getSiteLive',
													siteId,
													(draft) => {
														// TODO: core send a simple object
														const sensorPayload = payload[0]

														// shouldn't be here
														delete sensorPayload.Sentry

														if (draft?.installations) {
															const installationIndex =
																draft.installations.findIndex(
																	(installation) =>
																		installation.id === sensorPayload.sentry_id
																)
															if (installationIndex > -1) {
																const disruptors =
																	draft.installations[installationIndex]
																		?.disruptors
																if (action === 'create') {
																	disruptors.push(sensorPayload)
																}
																if (action === 'update') {
																	const disruptorIndex = disruptors?.findIndex(
																		(disruptor) =>
																			disruptor.id === sensorPayload.id
																	)
																	if (disruptorIndex > -1)
																		disruptors[disruptorIndex] = sensorPayload
																}
																if (action === 'delete') {
																	draft.installations[
																		installationIndex
																	].disruptors = disruptors?.filter(
																		(sensor) => sensor.id !== sensorPayload.id
																	)
																}
															}
														}
													}
												)
											)
										}
										//
										// Update disruptors for DSX MK2 (Profiles)
										else if (space === 'dsx_disruptors') {
											dispatch(
												sitesWsApi.util.updateQueryData(
													'getSiteLive',
													siteId,
													(draft) => {
														const profilePayload = payload
														if (draft?.installations) {
															const installationIndex =
																draft.installations.findIndex(
																	(installation) =>
																		installation.id === profilePayload.sentry_id
																)
															const rfSensors =
																draft.installations[installationIndex]
																	?.rf_sensors

															const dsxIndex = rfSensors.findIndex(
																(sensor) =>
																	sensor.id === profilePayload.sensor_id
															)

															delete profilePayload.sentry_id
															delete profilePayload.sensor_id

															const dsx = rfSensors[dsxIndex]

															if (dsxIndex > -1 && dsx.Cannon) {
																dsx.Cannon.v2_bands_statuses = {
																	...dsx.Cannon.v2_bands_statuses,
																	...profilePayload,
																}
															}
														}
													}
												)
											)
										}
										//
										// update Radars data
										else if (space === 'radars') {
											dispatch(
												sitesWsApi.util.updateQueryData(
													'getSiteLive',
													siteId,
													(draft) => {
														// TODO: core send a simple object
														const sensorPayload = payload[0]

														// shouldn't be here
														delete sensorPayload.Sentry

														if (draft?.installations) {
															const installationIndex =
																draft.installations.findIndex(
																	(installation) =>
																		installation.id === sensorPayload.sentry_id
																)
															if (installationIndex > -1) {
																const radars =
																	draft.installations[installationIndex]?.radars
																if (action === 'create') {
																	radars.push(sensorPayload)
																}
																if (action === 'update') {
																	const radarIndex = radars?.findIndex(
																		(radar) => radar.id === sensorPayload.id
																	)
																	if (radarIndex > -1) {
																		radars[radarIndex] = {
																			...radars[radarIndex],
																			...sensorPayload,
																		}
																	}
																}
																if (action === 'delete') {
																	draft.installations[
																		installationIndex
																	].radars = radars?.filter(
																		(sensor) => sensor.id !== sensorPayload.id
																	)
																}
															}
														}
													}
												)
											)
										}
										//
										// update Cameras data
										else if (space === 'cameras') {
											dispatch(
												sitesWsApi.util.updateQueryData(
													'getSiteLive',
													siteId,
													(draft) => {
														// TODO: core to send simple object
														const sensorPayload = payload[0]

														// doesn't belong here:
														delete sensorPayload.Sentry

														if (draft?.installations) {
															const installationIndex =
																draft.installations.findIndex(
																	(installation) =>
																		installation.id === sensorPayload.sentry_id
																)
															if (installationIndex > -1) {
																const cameras =
																	draft.installations[installationIndex]
																		?.cameras
																if (action === 'create') {
																	cameras.push(sensorPayload)
																}
																if (action === 'update') {
																	const cameraIndex = cameras.findIndex(
																		(camera) => camera.id === sensorPayload.id
																	)
																	if (cameraIndex > -1)
																		cameras[cameraIndex] = sensorPayload
																}
																if (action === 'delete') {
																	draft.installations[
																		installationIndex
																	].cameras = cameras?.filter(
																		(sensor) => sensor.id !== sensorPayload.id
																	)
																}
															}
														}
													}
												)
											)
										}
										//
										// Update Zones data
										else if (space === 'zones') {
											dispatch(
												sitesWsApi.util.updateQueryData(
													'getSiteLive',
													siteId,
													(draft) => {
														// TODO: core send a simple object
														const zonePayload = payload[0]

														if (draft?.zones) {
															if (action === 'create') {
																draft.zones.push(zonePayload)
															}
															if (action === 'update') {
																const zoneIndex = draft.zones.findIndex(
																	(zone) => zone.id === zonePayload.id
																)
																if (zoneIndex > -1)
																	draft.zones[zoneIndex] = zonePayload
															}
															if (action === 'delete') {
																draft.zones = draft.zones.filter(
																	(zone) => zone.id !== zonePayload.id
																)
															}
														}
													}
												)
											)
										}
										//
										// Update Map Markers data
										else if (space === 'site_markers') {
											dispatch(
												sitesWsApi.util.updateQueryData(
													'getSiteLive',
													siteId,
													(draft) => {
														// TODO: core send a simple object
														const markerPayload = payload[0]

														if (draft?.site_markers) {
															if (action === 'create') {
																draft.site_markers.push(markerPayload)
															}
															if (action === 'update') {
																const zoneIndex = draft.site_markers.findIndex(
																	(marker) => marker.id === markerPayload.id
																)
																if (zoneIndex > -1)
																	draft.site_markers[zoneIndex] = markerPayload
															}
															if (action === 'delete') {
																draft.site_markers = draft.site_markers.filter(
																	(marker) => marker.id !== markerPayload.id
																)
															}
														}
													}
												)
											)
										}
										//
										// Update Radar Masks data
										else if (space === 'radar_mask_zones') {
											// TODO: core doesn't need to send an array
											const maskPayload = payload[0]

											dispatch(
												sitesWsApi.util.updateQueryData(
													'getSiteLive',
													siteId,
													(draft) => {
														if (draft?.installations) {
															// Find the installation which the updated radar mask belongs to
															const installationIndex =
																draft.installations.findIndex((installation) =>
																	installation.radars.find(
																		(radar) => radar.id === maskPayload.radar_id
																	)
																)
															// Find the radar which the updated radar mask belongs to
															const radarIndex = draft.installations[
																installationIndex
															].radars.findIndex(
																(radar) => radar.id === maskPayload.radar_id
															)
															// Existing radar masks
															const radarMasks =
																draft.installations[installationIndex]?.radars[
																	radarIndex
																]?.radar_mask_zones ?? []
															// Apply update
															if (installationIndex > -1 && radarIndex > -1) {
																if (action === 'create') {
																	radarMasks.push(maskPayload)
																	draft.installations[installationIndex].radars[
																		radarIndex
																	].radar_mask_zones = radarMasks
																} else if (action === 'update') {
																	draft.installations[installationIndex].radars[
																		radarIndex
																	].radar_mask_zones = radarMasks.map((mask) =>
																		mask.id === maskPayload.id
																			? maskPayload
																			: mask
																	)
																} else if (action === 'delete') {
																	draft.installations[installationIndex].radars[
																		radarIndex
																	].radar_mask_zones = radarMasks.filter(
																		(mask) => mask.id !== maskPayload.id
																	)
																}
															}
														}
													}
												)
											)
										}
										//
										// Update Radar AGL Mask data
										else if (space === 'radar_agl_masks') {
											// TODO: core doesn't need to send an array
											const maskPayload = payload[0]

											dispatch(
												sitesWsApi.util.updateQueryData(
													'getSiteLive',
													siteId,
													(draft) => {
														if (draft?.installations) {
															// Find the installation which the updated radar mask belongs to
															const installationIndex =
																draft.installations.findIndex((installation) =>
																	installation.radars.find(
																		(radar) => radar.id === maskPayload.radar_id
																	)
																)
															// Find the radar which the updated radar mask belongs to
															const radarIndex = draft.installations[
																installationIndex
															].radars.findIndex(
																(radar) => radar.id === maskPayload.radar_id
															)
															// Apply update
															if (installationIndex > -1 && radarIndex > -1) {
																if (action === 'delete') {
																	draft.installations[installationIndex].radars[
																		radarIndex
																	].radar_agl_mask = undefined
																} else {
																	draft.installations[installationIndex].radars[
																		radarIndex
																	].radar_agl_mask = [maskPayload]
																}
															}
														}
													}
												)
											)
										}
										// update GPS Compass data
										else if (space === 'gps_compass') {
											dispatch(
												sitesWsApi.util.updateQueryData(
													'getSiteLive',
													siteId,
													(draft) => {
														// TODO: core to send simple object
														const sensorPayload = payload[0]

														if (draft?.installations) {
															const installationIndex =
																draft.installations.findIndex(
																	(installation) =>
																		installation.id === sensorPayload.sentry_id
																)
															if (installationIndex > -1) {
																const gpsCompasses =
																	draft.installations[installationIndex]
																		?.gps_compasses

																if (action === 'create') {
																	gpsCompasses.push(sensorPayload)
																}
																if (action === 'update') {
																	const cameraIndex = gpsCompasses.findIndex(
																		(gpsCompass) =>
																			gpsCompass.id === sensorPayload.id
																	)
																	if (cameraIndex > -1)
																		gpsCompasses[cameraIndex] = sensorPayload
																}
																if (action === 'delete') {
																	draft.installations[
																		installationIndex
																	].gps_compasses = gpsCompasses?.filter(
																		(sensor) => sensor.id !== sensorPayload.id
																	)
																}
															}
														}
													}
												)
											)
										}
										//
										// Update RF Filters data
										else if (space === 'rf_filters') {
											dispatch(
												sitesWsApi.util.updateQueryData(
													'getSiteLive',
													siteId,
													(draft) => {
														if (draft?.installations) {
															// delete payload has different shape
															const sensorId =
																payload[0].rf_sensor_ids?.[0] ??
																payload[0].rf_sensors[0].id

															// cleanup the filter data
															const filterPayload = payload[0]
															delete filterPayload.rf_sensor_ids
															delete filterPayload.rf_sensors
															delete filterPayload.created_at
															delete filterPayload.deleted_at

															const installationIndex =
																draft.installations.findIndex((installation) =>
																	installation.rf_sensors.some(
																		(rfSensor) => rfSensor.id === sensorId
																	)
																)

															if (installationIndex > -1) {
																const rfSensorIndex = draft.installations[
																	installationIndex
																].rf_sensors.findIndex(
																	(rfSensor) => rfSensor.id === sensorId
																)

																// filters before update
																const rfFilters =
																	draft.installations[installationIndex]
																		.rf_sensors[rfSensorIndex].RfFilter ?? []

																if (action === 'create') {
																	rfFilters.push(filterPayload)
																	draft.installations[
																		installationIndex
																	].rf_sensors[rfSensorIndex].RfFilter =
																		rfFilters
																} else if (action === 'update') {
																	draft.installations[
																		installationIndex
																	].rf_sensors[rfSensorIndex].RfFilter =
																		rfFilters.map((rfFilter) =>
																			rfFilter.id === filterPayload.id
																				? filterPayload
																				: rfFilter
																		)
																} else if (action === 'delete') {
																	draft.installations[
																		installationIndex
																	].rf_sensors[rfSensorIndex].RfFilter =
																		rfFilters.filter(
																			(rfFilter) =>
																				!rfFilter.id === filterPayload.id
																		)
																}
															}
														}
													}
												)
											)
										}
										//
										// Recordings
										else if (space === 'recordings') {
											// TODO
										}
										//
										// Selected Detection event (generated by UI)
										else if (space === 'selected_detection') {
											// TODO
										}
										//
										// Cursor on Target
										else if (space === 'cursor_on_target_settings') {
											dispatch(
												cotsApi.util.updateQueryData(
													'getCots',
													siteId,
													(draft) => {
														if (draft) {
															const cotsPayload = payload[0]
															if (action === 'create') {
																draft.push(cotsPayload)
															} else if (action === 'update') {
																const cotIndex = draft.findIndex(
																	(cot) => cot.id === cotsPayload?.id
																)
																draft[cotIndex] = cotsPayload
															} else if (action === 'delete') {
																draft.filter(
																	(cot) => cot.id !== cotsPayload?.id
																)
															}
														}
													}
												)
											)
										}
										//
										// update Detections data
										else if (space === 'sensor_fusion_detection') {
											// New ability to receive a batch of updates
											const detectionsBatch: Detection[] = []
											if (!Array.isArray(payload)) {
												// single update
												detectionsBatch.push(payload)
											} else {
												// batched update
												detectionsBatch.push(...payload)
											}

											updateCachedData((draft) => {
												if (draft?.detections) {
													for (const detectionPayload of detectionsBatch) {
														// add a default timestamp for garbage collection
														if (detectionPayload.raw_drone_locator_detection)
															detectionPayload._ui_expire_at =
																Date.now() + droneLocatorDetectionTimeoutMs
														else
															detectionPayload._ui_expire_at =
																Date.now() + detectionTimeoutMs

														// find existing detection with payload's target_id
														const indexByTargetId = draft.detections.findIndex(
															(detection) =>
																detection.target_id ===
																detectionPayload.target_id
														)
														// find existing detection with payload's serial no
														const indexBySerial = draft.detections.findIndex(
															(detection) =>
																detection.raw_drone_locator_detection &&
																detection.drone_serial_number ===
																	detectionPayload.drone_serial_number
														)
														// update existing detection by target_id
														if (indexByTargetId > -1) {
															draft.detections[indexByTargetId] =
																detectionPayload
														}
														// update existing raw detection by serial number
														// i.e. effectively an upgrade to a fused detection
														else if (indexBySerial > -1) {
															draft.detections[indexBySerial] = {
																...detectionPayload,
																_ui_expire_at: Date.now() + detectionTimeoutMs,
															}
														}
														// insert new detection
														else {
															draft.detections.push(detectionPayload)
														}
													}
												}

												if (draft?.alerts) {
													for (const detectionPayload of detectionsBatch) {
														// if this targetId has an alert, and had previously been
														// marked expired, bring it back to life..
														const indexByTargetId = draft.alerts.findIndex(
															(alert) =>
																alert.track.id === detectionPayload.target_id
														)
														if (indexByTargetId > -1)
															delete draft.alerts[indexByTargetId].detection
													}
												}
											})

											queueMicrotask(() => {
												for (const detectionPayload of detectionsBatch) {
													const targetId = detectionPayload.target_id

													// Detection contribution sensor history
													const contributors =
														detectionPayload.detection_contributions ??
														[].map((contribution: DetectionContribution) => ({
															sensor_type: contribution.sensor_type,
															sensor_id: contribution.sensor_id,
														}))
													contributors.forEach((contributor) => {
														dispatch(
															updateSeenContributors({
																targetId,
																sensorType: contributor.sensor_type,
																sensorId: contributor.sensor_id,
															})
														)
													})

													// Detection track history
													if (
														![0, -9999, null, undefined].includes(
															detectionPayload.latitude
														) &&
														![0, -9999, null, undefined].includes(
															detectionPayload.longitude
														)
													) {
														dispatch(
															addTrackHistory({
																targetId,
																lat: detectionPayload.latitude,
																lng: detectionPayload.longitude,
															})
														)
													}
												}
											})

											//
											// initialise detections garbage collector
											if (!gc) {
												gc = setInterval(() => {
													let expiredTargetIds: Array<Detection['target_id']>
													dispatch(
														sitesWsApi.util.updateQueryData(
															'getSiteLive',
															siteId,
															(draft) => {
																if (
																	draft?.detections &&
																	draft.detections.length
																) {
																	// TODO: use createDraftSafeSelector instead of current
																	expiredTargetIds = current(draft?.detections)
																		.filter(
																			(detection) =>
																				Date.now() >= detection._ui_expire_at
																		)
																		.map((d: Detection) => d.target_id)

																	// move expired detection data to the alert
																	// this marks the alert as 'stale'
																	draft.alerts = draft.alerts?.map((alert) => {
																		if (
																			expiredTargetIds.includes(alert.track.id)
																		)
																			return {
																				...alert,
																				detection: draft.detections?.find(
																					(detection) =>
																						detection.target_id ===
																						alert.track.id
																				),
																			}
																		else return alert
																	})

																	draft.detections = draft.detections.filter(
																		(detection) =>
																			Date.now() <= detection._ui_expire_at
																	)
																	expiredTargetIds.forEach((targetId) => {
																		dispatch(deleteDetectionData(targetId))
																	})
																}
															}
														)
													)
												}, detectionGarbageCollectInterval)
											}
										}
										//
										// update RF Zone Event intrusions
										else if (space === 'zone_rf_event_intrusions') {
											// this is fully deprecated, should never be seen on console
											console.error(
												'deprecated websocket space: zone_rf_event_intrusions'
											)
											console.log({ space, action, payload, raw: data })
										}
										//
										// update Zone Event intrusions
										else if (space === 'zone_event_intrusions') {
											dispatch(
												sitesWsApi.util.updateQueryData(
													'getSiteLive',
													siteId,
													(draft) => {
														if (draft?.alerts) {
															payload.forEach((alertPayload: Alert) => {
																if (draft?.alerts) {
																	const alertIndex = draft.alerts.findIndex(
																		(alert) =>
																			alert.track.id === alertPayload.track.id
																	)
																	if (alertIndex > -1)
																		draft.alerts[alertIndex] = alertPayload
																	else draft.alerts.push(alertPayload)
																}
															})
														}
													}
												)
											)
										}
										//
										// update user notes
										else if (space === 'notes') {
											//
											// TODO
										}
										//
										// update Smarthubs data
										else if (space === 'smarthub') {
											dispatch(
												sitesWsApi.util.updateQueryData(
													'getSiteLive',
													siteId,
													(draft) => {
														if (draft?.smarthubs) {
															const { data: smarthubData } = data.message
															const payload = {
																...smarthubData,
																status: 'green',
																_ui_expire_at: Date.now() + smarthubTimeoutMs,
															}
															const smarthubIndex = draft.smarthubs.findIndex(
																(smarthub) =>
																	smarthub.serial === smarthubData.serial
															)
															if (smarthubIndex > -1)
																draft.smarthubs[smarthubIndex] = payload
															else draft.smarthubs.push(payload)
														}
													}
												)
											)
										}
										//
										// Update Site Warnings data (in getSiteWarnings API)
										else if (space === 'site_warnings') {
											dispatch(
												siteWarningsApi.util.updateQueryData(
													'getSiteWarnings',
													siteId,
													(draft) => {
														if (draft) {
															if (action === 'create') {
																draft.push(payload)
															} else if (action === 'update') {
																const warningIndex = draft.findIndex(
																	(warning) => warning.id === payload.id
																)
																draft[warningIndex] = payload
															}
														}
													}
												)
											)
										}
										//
										// Sensor Discovery
										else if (space === 'discovery') {
											dispatch(
												sitesWsApi.util.updateQueryData(
													'getSiteLive',
													siteId,
													(draft) => {
														const sensorPayload = payload

														// 1. confirm this discovered sensor matches current siteId
														if (sensorPayload.site_id === siteId) {
															// 2. check device with this serial not already extant
															const knownSerials =
																(draft &&
																	draftSelectSiteKnownSerialNumbers(draft)) ??
																[]
															if (draft?.discovery) {
																const sensorIndex = draft.discovery.findIndex(
																	(sensor) =>
																		sensor.serial === sensorPayload.serial
																)
																const includedInTheKnownSensors =
																	knownSerials.includes(sensorPayload.serial)
																const toBeAddedSinceNotThere =
																	!includedInTheKnownSensors &&
																	sensorIndex === -1
																const toBeAddedSinceDifferentIP =
																	!includedInTheKnownSensors &&
																	sensorPayload.ip !== '' &&
																	sensorPayload.ip !==
																		draft.discovery[sensorIndex]?.ip

																if (
																	includedInTheKnownSensors &&
																	sensorIndex !== -1
																) {
																	draft.discovery.splice(sensorIndex, 1)
																} else if (
																	toBeAddedSinceNotThere ||
																	toBeAddedSinceDifferentIP
																) {
																	draft.discovery.push(sensorPayload)
																}
															}
														}
													}
												)
											)
										}
										//
										// Discovair (legacy device)
										else if (space === 'discovair_sensors') {
											// do nothing
										}
										//
										// Drone MCU (legacy device)
										else if (space === 'drone_mcu_unit') {
											// do nothing
										}
										//
										// unimplemented Smart Hub commands
										else if (space === 'commands') {
											// do nothing
											// console.error('unhandled smarthub websocket payload')
											// console.log({ space, action, payload, raw: data })
										}
										//
										// if this message appears in the console logs, this payload
										// needs to investigated and handled here
										else {
											console.log('unhandled websocket payload')
											console.log({ space, action, payload, raw: data })
										}
									}
								}
							} else {
								console.log('unhandled websocket message')
								console.log({ raw: data })
							}
						}

						//  Checks if the smarthub is active every 11 seconds.
						if (!smarthubUpdateCheck) {
							smarthubUpdateCheck = setInterval(() => {
								dispatch(
									sitesWsApi.util.updateQueryData(
										'getSiteLive',
										siteId,
										(draft) => {
											if (draft?.smarthubs?.length) {
												draft?.smarthubs.forEach((smarthub) => {
													if (Date.now() > smarthub._ui_expire_at) {
														smarthub.status = 'red'
													}
												})
											}
										}
									)
								)
							}, smarthubTimeoutMs)
						}

						// Docs: https://github.com/jjxxs/websocket-ts
						const token = selectJwtToken(getState() as RootState)
						new WebsocketBuilder(wsUrl)
							.withBackoff(
								new ExponentialBackoff(
									wsReconnectInitialDelay,
									wsReconnectMultiplyTimes
								)
							)
							.onOpen((i, e) => {
								console.log('ws open, sending authentication')
								dispatch(setNetworkDisconnected())
								dispatch(setNetworkAuthenticating(true))
								ws = i
								i.send(
									JSON.stringify({
										command: 'authenticate',
										options: {
											auth_options: {
												token,
											},
										},
									})
								)
							})
							.onClose((i, e) => {
								console.log('close websocket (close)')
								dispatch(setNetworkDisconnected())
							})
							.onError((i, e) => {
								console.log('close websocket (error)')
								dispatch(setNetworkDisconnected())
							})
							.onMessage((i, e) => wsListener(i, e))
							.onRetry((i, e) => {
								console.log('retry websocket')
								dispatch(setNetworkReconnecting(true))
							})
							.onReconnect((i, e) => {
								console.log('ws reconnected')
								// base connected on `confirm_connection` event
								// dispatch(setNetworkConnected())
								dispatch(setNetworkReconnecting(false))
							})
							.build()
					}
				} catch (e) {
					// no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
					// in which case `cacheDataLoaded` will throw
					// otherwise the websocket connection failed for some reason:
					console.error(e)
				}

				// cacheEntryRemoved will resolve when the cache subscription is no longer active
				await cacheEntryRemoved

				// perform cleanup steps once the above `cacheEntryRemoved` promise resolves
				console.log('close websocket (formal cleanup)')
				if (ws) {
					ws.close()
					ws = undefined
				}
				console.log('cancel detections garbage collector')
				if (gc) {
					clearInterval(gc)
					gc = null
				}
				console.log('cancel smarthub update check')
				if (smarthubUpdateCheck) {
					clearInterval(smarthubUpdateCheck)
					smarthubUpdateCheck = null
				}
			},
		}),
	}),
})

export const selectSiteName = createSelector(
	(data?: SiteLive) => data,
	(data) => data?.name
)

export const selectSiteMode = createSelector(
	(data?: SiteLive) => data,
	(data) => data?.mode
)

export const selectSiteData = createSelector(
	(data?: SiteLive) => data,
	(data) =>
		data && {
			name: data.name,
			latitude: data.latitude,
			longitude: data.longitude,
			map_center_latitude: data.map_center_latitude,
			map_center_longitude: data.map_center_longitude,
			mode: data.mode,
			zoom_level: data.zoom_level,
		}
)

export const selectSiteMapCenter = createSelector(
	(data?: SiteLive) => data,
	(site) => {
		if (site) {
			const mapCenter = [site?.latitude, site?.longitude]

			if (
				site.map_center_latitude !== undefined &&
				site.map_center_longitude !== undefined &&
				site.map_center_latitude !== null &&
				site.map_center_longitude !== null
			) {
				mapCenter[0] = site.map_center_latitude
				mapCenter[1] = site.map_center_longitude
			}

			return mapCenter
		}
	}
)

export const selectSiteStatusColor = createSelector(
	(data?: SiteLive) => data,
	(data) => data?.status_color
)

export const selectIsSiteCalibrationMode = createSelector(
	(data?: SiteLive) => data,
	(data) => data?.mode === 'calibration'
)

export const selectSiteAlwaysShowRf = createSelector(
	(data?: SiteLive) => data,
	(data) => (data?.always_show_rf_beam === true ? true : null)
)

const emptyInstallationsResult: SiteInstallation[] = []

export const selectSiteInstallations = createSelector(
	(data?: SiteLive) => data,
	(site) => site?.installations ?? emptyInstallationsResult
)

export const selectSiteInstallationsFirstId = createSelector(
	selectSiteInstallations,
	(installations) => installations[0]?.id ?? null
)

const selectSiteInstallationById = createSelector(
	[
		selectSiteInstallations,
		(installations, installationId: number) => installationId,
	],
	(installations, installationId) =>
		installations.find((installation) => installation.id === installationId)
)

export const selectSiteInstallationAltitude = createSelector(
	selectSiteInstallationById,
	(installation) => installation?.altitude || 0
)

export const selectSiteRadars = createSelector(
	selectSiteInstallations,
	(installations) =>
		installations.flatMap((installation) => installation.radars)
)

export const selectSiteHasRadar = createSelector(
	selectSiteRadars,
	(radars) => radars.length > 0
)

export const selectSiteHasActiveRadar = createSelector(
	selectSiteRadars,
	(radars) => radars.some((radar) => radar.status_color === 'green')
)

export const selectSiteRadarById = createSelector(
	[selectSiteRadars, (radars, sensorId: number) => sensorId],
	(radars, sensorId) => radars.find((sensor) => sensor.id === sensorId)
)

export const selectSiteRfSensors = createSelector(
	selectSiteInstallations,
	(installations) =>
		installations.flatMap((installation) => installation.rf_sensors)
)

export const selectSiteHasRfSensor = createSelector(
	selectSiteRfSensors,
	(rfSensors) => rfSensors.length > 0
)

export const selectSiteRfSensorById = createSelector(
	[selectSiteRfSensors, (rfSensors, sensorId: number) => sensorId],
	(rfSensors, sensorId) => rfSensors.find((sensor) => sensor.id === sensorId)
)

export const selectSiteRfSensorsDsx = createSelector(
	selectSiteRfSensors,
	(rfSensors) => rfSensors?.filter((sensor) => sensor.model.includes('dsx'))
)

export const selectSiteRfSensorsNoDsx = createSelector(
	selectSiteRfSensors,
	(rfSensors) => rfSensors?.filter((sensor) => !sensor.model.includes('dsx'))
)

export const selectSiteDsxMk2 = createSelector(
	selectSiteRfSensors,
	(rfSensors) => rfSensors?.filter((sensor) => sensor.model.includes('dsx_mk2'))
)

export const selectSiteDsxProfile = createSelector(
	[selectSiteRfSensorsDsx, (dsxSensors, sensorId: number) => sensorId],
	(dsxSensors, sensorId) => {
		const dsxSensor = dsxSensors.find((sensor) => sensor.id === sensorId)
		return dsxSensor?.Cannon?.v2_bands_statuses
	}
)

export const selectSiteDisruptors = createSelector(
	[(data?: SiteLive) => data, (data, includeDsx) => includeDsx],
	(data, includeDsx) => {
		const disruptors = (
			data?.installations ?? emptyInstallationsResult
		).flatMap((installation) => installation.disruptors)

		const disruptorsExcludingDsx = disruptors?.filter(
			(disruptor) => !disruptor.cannon_type.includes('DSX')
		)

		return includeDsx ? disruptors : disruptorsExcludingDsx
	}
)

const selectIsDsxDisabled = createSelector(
	selectSiteRfSensorsDsx,
	(rfSensorsDsx) => rfSensorsDsx.some((sensor) => isDsxDisabled(sensor))
)

const selectIsDisruptorDisabled = createSelector(
	selectSiteDisruptors,
	(disruptors) => disruptors.some((sensor) => isDisruptorDisabled(sensor))
)

// Note on combining 'input selectors': https://redux.js.org/usage/deriving-data-selectors#createselector-behavior
export const selectIsAnyDisruptorDisabled = createSelector(
	[selectIsDisruptorDisabled, selectIsDsxDisabled],
	(isDisruptorDisabled, isDsxDisabled) => isDisruptorDisabled || isDsxDisabled
)

export const selectAllDisruptors = createSelector(
	[selectSiteRfSensorsDsx, selectSiteDisruptors],
	(rfSensorsDsx, disruptors) => [
		...rfSensorsDsx.filter((rfSensor) => !rfSensor.model.includes('no_jam')),
		...disruptors,
	]
)

export const selectSiteHasDisruptor = createSelector(
	selectAllDisruptors,
	(disruptors) => disruptors.length > 0
)

export const selectIsEveryDisruptorDisabled = createSelector(
	[selectSiteRfSensorsDsx, selectSiteDisruptors],
	(rfSensorsDsx, disruptors) => {
		const isDisruptorDisabled = isEveryDisruptorDisabled(disruptors)
		const isDsxDisabled = isEveryDsxDisabled(rfSensorsDsx)
		return isDisruptorDisabled && isDsxDisabled
	}
)

export const selectIsEveryDisruptorOffline = createSelector(
	selectAllDisruptors,
	(disruptors) =>
		disruptors.every((disruptor) => disruptor.status_color !== 'green')
)

export const selectSiteCameras = createSelector(
	selectSiteInstallations,
	(installations) =>
		installations.flatMap((installation) => installation.cameras)
)

export const selectSiteHasCamera = createSelector(
	selectSiteCameras,
	(cameras) => cameras.length > 0
)

export const selectSiteCameraById = createSelector(
	[selectSiteCameras, (cameras, cameraId: number | null) => cameraId],
	(cameras, cameraId) => cameras.find((camera) => camera.id === cameraId)
)

export const selectSiteCameraIsThermal = createSelector(
	selectSiteCameraById,
	(camera) => camera?.is_thermal ?? false
)

export const selectSiteCameraPosition = createSelector(
	selectSiteCameraById,
	(camera) => ({
		pan: camera?.pan || 0,
		tilt: camera?.tilt || 0,
		zoom: camera?.zoom || 0,
	})
)

export const selectSiteCameraAltitude = createSelector(
	selectSiteCameraById,
	(camera) => camera?.altitude || 0
)

export const selectCurrentCamera = createSelector(
	[selectSiteCameras],
	(cameras) =>
		cameras.find((camera) => camera.status_color === 'green') || cameras[0]
)

export const selectCameraTrackedTargetId = createSelector(
	[selectCurrentCamera],
	(camera) =>
		camera &&
		camera.tracked_target_id !== '00000000-0000-0000-0000-000000000000'
			? camera.tracked_target_id
			: null
)

export const selectIsCameraDisabled = createSelector(
	[selectSiteCameras, selectCurrentCamera],
	(cameras, currentCamera) =>
		cameras.length < 1 || currentCamera.status_color !== 'green'
)

const emptySensorsResult: UnregisteredSensor[] = []

export const selectSiteUnregisteredSensors = createSelector(
	(data?: SiteLive) => data,
	(data) => data?.discovery ?? emptySensorsResult
)

const emptyDetectionsResult: Detection[] = []

export const selectSiteDetections = createSelector(
	(data?: SiteLive) => data,
	(data) => data?.detections ?? emptyDetectionsResult
)

// TODO: Remove map with random id here. It's only necessary when using wssim
// as all detection ids are the same.
export const selectSiteRadarDetections = createSelector(
	[selectSiteDetections],
	(detections) =>
		(
			detections.filter(({ radar_confirmed }) => radar_confirmed) ??
			emptyDetectionsResult
		).map((detection) => ({
			...detection,
			id: Math.random(),
		}))
)

export const selectSiteCameraDetections = createSelector(
	[selectSiteDetections],
	(detections) =>
		(detections.filter(({ camera_confirmed }) => camera_confirmed) ?? []).map(
			(detection) => ({
				...detection,
				id: Math.random(),
			})
		) ?? emptyDetectionsResult
)

export const selectSiteFusedDetections = createSelector(
	[selectSiteDetections],
	(detections) =>
		detections.filter(({ raw_rf_detection }) => !raw_rf_detection) ??
		emptyDetectionsResult
)

export const selectSiteRawRfDetections = createSelector(
	[selectSiteDetections],
	(detections) =>
		detections.filter(({ raw_rf_detection }) => raw_rf_detection) ??
		emptyDetectionsResult
)

export const selectHasSiteDetections = createSelector(
	selectSiteDetections,
	(detections) => detections.length > 0
)

export const selectSiteDetection = createSelector(
	[
		selectSiteDetections,
		(data: SiteLive | undefined, targetId: string | null) => targetId,
	],
	(detections, targetId) =>
		detections.find((detection) => detection.target_id === targetId)
)

export const selectDetectionHasRadarContributions = createSelector(
	selectSiteDetection,
	(detection) => {
		const contributions = detection?.detection_contributions
		return contributions?.some(
			(contribution) => contribution.sensor_type === 'radar'
		)
	}
)

export const selectDetectionHasRfContributions = createSelector(
	selectSiteDetection,
	(detection) => {
		const contributions = detection?.detection_contributions
		return contributions?.some(
			(contribution) =>
				contribution.sensor_type === 'rfSensor' ||
				contribution.sensor_type === 'dsx'
		)
	}
)

export const selectSiteNextDetection = createSelector(
	[selectSiteDetections, selectCameraTrackedTargetId],
	(detections, targetId) => {
		let trackedIndex = -1
		if (targetId && detections.length > 0) {
			trackedIndex = detections.findIndex((d) => d.target_id === targetId)
		}
		if (detections[trackedIndex + 1]) {
			return detections[trackedIndex + 1].target_id
		} else if (detections.length > 0) {
			return detections[0].target_id
		}
		return null
	}
)

export const selectSiteZones = createSelector(
	(data?: SiteLive) => data,
	(data) => data?.zones ?? []
)

export const selectSiteMarkers = createSelector(
	(data?: SiteLive) => data,
	(data) => data?.site_markers ?? []
)

const emptyAlertsResult: Alert[] = []

export const selectSiteAlerts = createSelector(
	(data?: SiteLive) => data,
	(data) => data?.alerts ?? emptyAlertsResult
)

export const selectSiteActiveAlerts = createSelector(
	[selectSiteAlerts, selectSiteDetections],
	(alerts, detections) =>
		alerts.filter(
			(alert) =>
				!alert.detection &&
				detections.map((d) => d.target_id).includes(alert.track.id)
		) ?? emptyAlertsResult
)

export const selectSiteStaleAlerts = createSelector(
	selectSiteAlerts,
	(alerts) => alerts.filter((alert) => alert.detection) ?? emptyAlertsResult
)

export const selectSiteAlertTargetIds = createSelector(
	selectSiteAlerts,
	(alerts) => alerts?.map((alert) => alert.track.id) ?? emptyAlertsResult
)

export const selectSiteAlertByTargetId = createSelector(
	[selectSiteAlerts, (alerts, targetId: string | null) => targetId],
	(alerts, targetId) => alerts.find((alert) => alert.track.id === targetId)
)

export const selectSmarthubs = createSelector(
	(data?: SiteLive) => data,
	(data) => data?.smarthubs ?? []
)

export const selectSmarthub = createSelector(selectSmarthubs, (smarthubs) =>
	smarthubs.length > 0 ? smarthubs[0] : undefined
)

export const clearSiteAlert = (
	siteId: Site['id'],
	targetId: Detection['target_id']
) =>
	sitesWsApi.util.updateQueryData('getSiteLive', siteId, (draft) => {
		if (draft) {
			draft.alerts = draft.alerts?.filter(
				(alert) => alert.track.id !== targetId
			)
		}
	})

export const clearAllSiteAlerts = (siteId: Site['id']) =>
	sitesWsApi.util.updateQueryData('getSiteLive', siteId, (draft) => {
		if (draft) {
			draft.alerts = []
		}
	})

export const clearStaleSiteAlerts = (siteId: Site['id']) =>
	sitesWsApi.util.updateQueryData('getSiteLive', siteId, (draft) => {
		if (draft) {
			draft.alerts = draft.alerts?.filter((alert) => !alert.detection)
		}
	})

export const selectSiteTimezone = createSelector(
	(data?: SiteLive) => data,
	(data) => data?.timezone
)

// Special "Draft Safe" selector for Discovery messages
const draftSelectSiteKnownSerialNumbers = createDraftSafeSelector(
	(draft: SiteLive) => draft,
	(data) =>
		[
			...(data.installations?.flatMap(
				(installation: SiteInstallation) => installation.radars
			) ?? []),
			...(data.installations?.flatMap(
				(installation: SiteInstallation) => installation.rf_sensors
			) ?? []),
			...(data.installations?.flatMap(
				(installation: SiteInstallation) => installation.gps_compasses
			) ?? []),
			...(data.installations?.flatMap(
				(installation: SiteInstallation) => installation.cameras
			) ?? []),
			...(data.installations?.flatMap(
				(installation: SiteInstallation) => installation.disruptors
			) ?? []),
		].map((sensor) => sensor.serial_number) as Array<string>
)

export const { useGetSiteLiveQuery } = sitesWsApi
