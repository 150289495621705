import { Fragment } from 'react'
import {
	selectSiteInstallations,
	useGetSiteLiveQuery,
} from '@Store/sites/sitesWsApi'

// Leaflet
import SentryMarker from '@Components/Markers/SentryMarker/SentryMarker'

import DeviceSectors from '@Components/Site/MapLayers/DeviceSectors'
import SentryTrackHistory from '@/components/Markers/SentryMarker/SentryTrackHistory'
import { COLORS } from '@Constants/colors'

// Installations HOC
const Installations = ({ siteId }: { siteId: number }) => {
	const { installations } = useGetSiteLiveQuery(siteId, {
		selectFromResult: ({ data }) => ({
			installations: selectSiteInstallations(data),
		}),
	})
	return (
		<>
			{installations?.map((sentry) => (
				<Fragment key={sentry.id}>
					<DeviceSectors installation={sentry} />
					{sentry.location_history && sentry.location_history.length > 0 && (
						<SentryTrackHistory
							positions={sentry.location_history.map((location) => ({
								lat: location.latitude,
								lng: location.longitude,
							}))}
							color={COLORS.GREEN}
						/>
					)}
					<SentryMarker
						name={sentry.name}
						position={[sentry.latitude, sentry.longitude]}
						sentryType={sentry.sentry_type}
						iconColor={sentry.status_color}
					/>
				</Fragment>
			))}
		</>
	)
}
export default Installations
