import { useState } from 'react'
import { useTranslation } from 'react-i18next'

// Components
import Switch from '@Components/_UI/Switch/Switch'

// Redux
import type { Site, RadarAglMask } from '@Store/types'
import { useUpdateRadarAglMaskMutation } from '@/store/radarAglMasks/radarAglMasksApi'

type RadarAglMaskActiveSwitchProps = {
	siteId: Site['id']
	radarAglMask: RadarAglMask
}

const RadarAglMaskActiveSwitch = ({
	siteId,
	radarAglMask,
}: RadarAglMaskActiveSwitchProps) => {
	const { id, enabled } = radarAglMask
	const { t } = useTranslation('panels', { keyPrefix: 'aglMask' })

	const [isPending, setIsPending] = useState<boolean>(false)
	const [updateRadarAglMask] = useUpdateRadarAglMaskMutation()

	const handleToggleActive = async () => {
		try {
			setIsPending(true)
			await updateRadarAglMask({
				siteId: siteId,
				sensorId: radarAglMask.radar_id,
				aglMaskId: id,
				enabled: !enabled,
			})
				.unwrap()
				.then(() => setIsPending(false))
		} catch (error) {
			// TODO: handle server-side errors if necessary
			console.error('Unhandled update radar mask active error', error)
		}
	}

	return (
		<Switch
			onChange={handleToggleActive}
			label={t('switchLabel')}
			isChecked={enabled}
			isDisabled={isPending}
			testId='radar-agl-active-switch'
		/>
	)
}

export default RadarAglMaskActiveSwitch
