import type { DetectionMarkerIconProps } from '.'

type PlaneMarkerIconProps = {
	color: DetectionMarkerIconProps['color']
}

const PlaneMarkerIcon = ({ color }: PlaneMarkerIconProps) => {
	return (
		<svg
			width='40px'
			height='40px'
			viewBox='0 0 40 40'
			version='1.1'
			xmlns='http://www.w3.org/2000/svg'
			xmlnsXlink='http://www.w3.org/1999/xlink'
		>
			<g>
				<path
					data-testid={`plane-icon-${color}`}
					d='M19.5523,1.08297053 C18.7038,1.83397053 16.9704,4.19897053 16.825,7.65297053 C16.825,7.65297053 16.8142,10.8109705 16.82,14.6949705 C16.4824,14.8779705 15.6922,15.3069705 14.6368,15.8839705 L14.6368,15.6769705 C14.6368,14.8739705 13.9856,14.2229705 13.1822,14.2229705 C12.3789,14.2229705 11.7277,14.8739705 11.7277,15.6769705 L11.7277,17.2729705 C11.7277,17.3419705 11.7325,17.4099705 11.7419,17.4769705 C7.18219,19.9999705 1.29308,23.3359705 1.18187,23.7959705 C1,24.5459705 1,26.6109705 1,26.6109705 L16.856,22.1469705 C16.8836,25.0399705 16.93,27.4969705 17.0068,28.4879705 C17.1174,29.9149705 17.354,32.0399705 17.7168,34.0139705 C16.1046,35.1519705 12.934,37.4179705 12.8847,37.6799705 C12.8186,38.0319705 12.8186,38.9999705 12.8186,38.9999705 L18.4206,36.9669705 C18.7448,37.9789705 19.122,38.7329705 19.5523,38.9999705 L20.4546,38.9999705 L20.4546,1.08297053 C20.1637,0.932970532 19.7318,1.01997053 19.5523,1.08297053 Z M20.4547,1.08297053 C21.3032,1.83397053 23.0366,4.19897053 23.182,7.65297053 C23.182,7.65297053 23.1928,10.8129705 23.187,14.6989705 C23.5479,14.8949705 24.4064,15.3599705 25.5455,15.9839705 L25.5455,15.6769705 C25.5455,14.8739705 26.1968,14.2229705 27.0001,14.2229705 C27.8034,14.2229705 28.4546,14.8739705 28.4546,15.6769705 L28.4546,17.2729705 C28.4546,17.3739705 28.4442,17.4729705 28.4245,17.5689705 C32.9597,20.0809705 38.7083,23.3419705 38.8181,23.7959705 C39,24.5459705 39,26.6109705 39,26.6109705 L23.1509,22.1489705 C23.1234,25.0409705 23.077,27.4969705 23.0002,28.4879705 C22.8874,29.9439705 22.6435,32.1249705 22.2684,34.1319705 C23.9223,35.3009705 26.886,37.4269705 26.9336,37.6799705 C26.9998,38.0319705 26.9998,38.9999705 26.9998,38.9999705 L21.5666,37.0279705 C21.2469,38.0089705 20.8763,38.7389705 20.4547,38.9999705 L20.4547,1.08297053 Z'
					fill={color}
				/>
			</g>
		</svg>
	)
}

export default PlaneMarkerIcon
