import { ResponsiveRadar } from '@nivo/radar'

import { useTheme } from '@chakra-ui/react'
import useChartTheme from './useChartTheme'

type RadarChartProps = {
	data: { label: string; value: number }[]
}

const RadarChart = ({ data }: RadarChartProps) => {
	const {
		semanticTokens: { colors },
	} = useTheme()

	const theme = useChartTheme()

	const dataMaxValue = Math.max(...data.map((obj) => obj.value))
	const adjustedMaxValue = dataMaxValue + 0.1

	return (
		<ResponsiveRadar
			theme={theme}
			data={data}
			keys={['value']}
			indexBy='label'
			margin={{ top: 45, right: 75, bottom: 55, left: 75 }}
			gridLabelOffset={12}
			dotSize={6}
			dotColor='transparent'
			dotBorderColor={colors.body_text}
			dotBorderWidth={1}
			colors={[colors.primary]}
			gridLevels={0}
			maxValue={adjustedMaxValue}
			animate={false}
		/>
	)
}

export default RadarChart
