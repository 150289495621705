import { useTranslation } from 'react-i18next'
import { Box, Flex, Text, Input, useRangeSlider } from '@chakra-ui/react'
import { type RangeSliderProps } from '@chakra-ui/react'

// Components
import Thumb from './Thumb'

type AglRangeSliderProps = {
	value?: number[]
	defaultValue: any
	min: number
	max: number
	step?: number
	stepByNumber?: number
	minStepsBetweenThumbs?: number
	inputWidth?: number
	// 'aria-label': [string, string]
	onChangeEnd?: RangeSliderProps['onChangeEnd']
	onChange: RangeSliderProps['onChange']
}

const AglRangeSlider = ({
	min,
	max,
	stepByNumber,
	defaultValue,
	...rest
}: AglRangeSliderProps) => {
	const {
		state,
		actions,
		getInnerTrackProps,
		getInputProps,
		// getMarkerProps,
		getRootProps,
		getThumbProps,
		getTrackProps,
	} = useRangeSlider({
		min,
		max,
		defaultValue,
		orientation: 'vertical',
		...rest,
	})

	const { t } = useTranslation('forms', { keyPrefix: 'radarAglMaskForm' })

	const { onKeyDown: onThumbKeyDownFirstIndex, ...thumbPropsFirstIndex } =
		getThumbProps({
			index: 0,
		})

	const { onKeyDown: onThumbKeyDownSecondIndex, ...thumbPropsSecondIndex } =
		getThumbProps({
			index: 1,
		})

	const onKeyDownStepBy = (
		e: React.KeyboardEvent<HTMLDivElement>,
		thumbIndex: number
	) => {
		if (e.code === 'ArrowRight') actions.stepUp(thumbIndex, stepByNumber)
		else if (e.code === 'ArrowLeft') actions.stepDown(thumbIndex, stepByNumber)
		else if (thumbIndex === 0 && typeof onThumbKeyDownFirstIndex === 'function')
			onThumbKeyDownFirstIndex(e)
		else if (
			thumbIndex === 1 &&
			typeof onThumbKeyDownSecondIndex === 'function'
		)
			onThumbKeyDownSecondIndex(e)
	}
	return (
		<Flex h='100%' direction='column' marginInlineStart={3}>
			<Text fontSize='xs' color='label_color'>
				{t('maxHeight')}
			</Text>
			<Input
				w='100%'
				paddingBlock={4}
				paddingInline={2}
				h={4}
				{...getInputProps({ index: 1 })}
				type='number'
				data-testid='input-agl-max'
				onChange={(e) => {
					const value = Number(e.target.value)
					value >= state.getThumbMinValue(1) &&
						value <= state.getThumbMaxValue(1) &&
						actions.setValueAtIndex(1, value)
				}}
			/>

			<Flex h='100%' marginBlock={4}>
				<Flex
					direction='column'
					justifyContent='space-between'
					marginBlockStart={-1}
					marginBlockEnd={-1}
					paddingInlineEnd={4}
				>
					{[3000, 2000, 1000, 0, -1000, -2000, -3000].map((value, index) => {
						return (
							<Text key={index} fontSize='sm' textAlign='center'>
								{value}
							</Text>
						)
					})}
				</Flex>
				<Box cursor='pointer' h='100%' {...getRootProps()}>
					<Box w='24px' bgColor='primary' opacity='50%' {...getTrackProps()}>
						<Box w='24px' bgColor='gray.900' {...getInnerTrackProps()} />
					</Box>
					<Thumb
						value={state.value[0]}
						thumbIndex={0}
						thumbProps={thumbPropsFirstIndex}
						onKeyDownStepBy={onKeyDownStepBy}
						bgColor='primary'
						testId='slider-agl-min'
					/>
					<Thumb
						value={state.value[1]}
						thumbIndex={1}
						thumbProps={thumbPropsSecondIndex}
						onKeyDownStepBy={onKeyDownStepBy}
						bgColor='primary'
						testId='slider-agl-max'
					/>
				</Box>
			</Flex>
			<Text fontSize='xs' color='label_color'>
				{t('minHeight')}
			</Text>
			<Input
				w='100%'
				paddingBlock={4}
				paddingInline={2}
				h={4}
				{...getInputProps({ index: 0 })}
				type='number'
				data-testid='input-agl-min'
				onChange={(e) => {
					const value = Number(e.target.value)
					value >= state.getThumbMinValue(0) &&
						value <= state.getThumbMaxValue(0) &&
						actions.setValueAtIndex(0, value)
				}}
			/>
		</Flex>
	)
}

export default AglRangeSlider
