import { useRef, type ReactNode } from 'react'
import {
	Accordion as ChakraAccordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem as ChakraAccordionItem,
	AccordionPanel,
	Box,
	Flex,
	Icon,
} from '@chakra-ui/react'
import { MdError } from 'react-icons/md'

import type {
	AccordionProps,
	AccordionItemProps as ChakraAccordionItemProps,
	AccordionButtonProps,
} from '@chakra-ui/react'

const AccordionContainer = ({
	index,
	children,
	defaultIndex,
	setExpandedIndex,
}: AccordionProps & { setExpandedIndex?: (index: number) => void }) => (
	<ChakraAccordion
		index={index}
		allowMultiple
		allowToggle
		defaultIndex={defaultIndex}
		onChange={setExpandedIndex && ((index: number) => setExpandedIndex(index))}
	>
		{children}
	</ChakraAccordion>
)

type AccordionItemProps = ChakraAccordionItemProps & {
	title?: AccordionButtonProps['title']
	children: ReactNode
	isLazy?: boolean
	testId?: string
	isError?: boolean
}

const AccordionItem = ({
	id = undefined,
	title,
	children,
	testId,
	isLazy,
	isError,
}: AccordionItemProps) => {
	const ref = useRef<HTMLDivElement | null>(null)

	return (
		<ChakraAccordionItem id={id}>
			{({ isExpanded }) => (
				<>
					<AccordionButton data-testid={testId}>
						<Flex justifyContent='space-between' w='100%' alignItems='center'>
							{title}
							<Flex alignItems='center' gap={2}>
								{isError && <Icon as={MdError} color='error' />}
								<AccordionIcon />
							</Flex>
						</Flex>
					</AccordionButton>
					<AccordionPanel ref={ref}>
						{isLazy && !isExpanded ? (
							<Box h={ref.current?.clientHeight} />
						) : (
							children
						)}
					</AccordionPanel>
				</>
			)}
		</ChakraAccordionItem>
	)
}

const Accordion = {
	Container: AccordionContainer,
	Item: AccordionItem,
}

export default Accordion
