// React
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
// Redux
import { useGetSitesListQuery } from '@Store/sites/sitesApi'
import { useAppSelector } from '@Store/index'
import { selectShowNetworkDisconnected } from '@Store/ui/uiSlice'

// Chakra
import { Flex, Icon, Text } from '@chakra-ui/react'
import { MdAdd, MdWarning } from 'react-icons/md'
import Select from '@UI/Select/Select'

import type { Option } from '@UI/Select/Select'

import useAuth from '@Hooks/useAuth'

const formatId = (id: number) => (id.toString().length > 1 ? id : `0${id}`)

const SiteSelect = () => {
	const { t } = useTranslation('navigation', { keyPrefix: 'siteSelect' })
	const navigate = useNavigate()
	const { siteId } = useParams()
	const { data: sites, isSuccess } = useGetSitesListQuery()

	const isNetworkDisconnected = useAppSelector(selectShowNetworkDisconnected)

	const handleChange = (option: Option<string>) => {
		navigate('/' + option.value)
	}

	const handleAddSite = () => navigate('/site/add')

	const ADD_SITE_OPTION = {
		label: t('addNewSite'),
		value: '',
		icon: <Icon as={MdAdd} h={4} w={4} />,
		onClick: handleAddSite,
		isButton: true,
		className: 'AddSiteButton',
	}

	const options = sites?.map(
		({ name, id }) =>
			({
				label: name,
				value: id,
				additionalInfo: formatId(id),
			}) as Option<any>
	)

	// Permissions
	const { isManager } = useAuth()
	if (isManager) options?.push(ADD_SITE_OPTION)

	if (!isSuccess) return null
	else if (sites && sites.length > 0 && siteId) {
		const defaultValue = options?.find(
			(option) => option.value === Number(siteId)
		)

		return (
			<Select
				value={defaultValue}
				onChange={handleChange}
				options={options}
				variant='site_select'
				id='site-select'
				testId='site-select'
				isDisabled={isNetworkDisconnected === true}
			/>
		)
	} else if (sites && sites.length === 0) {
		return (
			<Flex marginInline={8} gap={2} alignContent='center' alignItems='center'>
				<Icon as={MdWarning} />
				<Text fontSize='sm'>{t('noSites')}</Text>
			</Flex>
		)
	} else return null
}

export default SiteSelect
