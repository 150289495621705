import { baseApi } from '@Store/baseApi'

import type {
	Event,
	EventSummary,
	VideoRecording,
	SiteRfDetails,
} from '../types'

import type {
	GetTrackSummaryResponse,
	GetTrackSummaryRequest,
	GetDisruptionsSummaryRequest,
	GetDisruptionsSummaryResponse,
	GetAlertSummaryRequest,
	GetAlertSummaryResponse,
	GetVideoRecordingsResponse,
	GetVideoRecordingsRequest,
	GetSiteLogsRequest,
	GetSiteLogsResponse,
} from './types'

type UpdateEventSummaryRequest = {
	eventId: number
	false_positive: boolean
}

export const analyticsApi = baseApi
	.enhanceEndpoints({
		addTagTypes: [
			'Analytics',
			'Tracks',
			'Disruptions',
			'Alerts',
			'Recordings',
			'SiteLogs',
		],
	})
	.injectEndpoints({
		endpoints: (builder) => ({
			getTrackSummary: builder.query<
				GetTrackSummaryResponse,
				GetTrackSummaryRequest
			>({
				query: ({
					siteId,
					format,
					startTime,
					endTime,
					page,
					perPage,
					...filterParams
				}) => ({
					url: `api/reports/${siteId}/track_summary/${format}`,
					params: {
						start_time: startTime,
						end_time: endTime,
						page,
						per_page: perPage,
						...filterParams,
					},
				}),
				providesTags: ['Tracks'],
			}),
			getDisruptionsSummary: builder.query<
				GetDisruptionsSummaryResponse,
				GetDisruptionsSummaryRequest
			>({
				query: ({
					siteId,
					format,
					startTime,
					endTime,
					page,
					perPage,
					...sortingParams
				}) => ({
					url: `api/reports/${siteId}/cannon_disruptions/${format}`,
					params: {
						start_time: startTime,
						end_time: endTime,
						page,
						per_page: perPage,
						...sortingParams,
					},
				}),
				providesTags: ['Disruptions'],
			}),
			getAlertsSummary: builder.query<
				GetAlertSummaryResponse,
				GetAlertSummaryRequest
			>({
				query: ({
					siteId,
					format,
					startTime,
					endTime,
					page,
					perPage,
					...filterParams
				}) => ({
					url: `api/reports/${siteId}/alerts/${format}`,
					params: {
						start_time: startTime,
						end_time: endTime,
						page,
						per_page: perPage,
						...filterParams,
					},
				}),
				providesTags: ['Alerts'],
			}),
			getVideoRecordingsSummary: builder.query<
				GetVideoRecordingsResponse,
				GetVideoRecordingsRequest
			>({
				query: ({
					siteId,
					startTime,
					endTime,
					page,
					perPage,
					...sortingParams
				}) => ({
					url: `api/reports/${siteId}/camera_recordings/table`,
					params: {
						start_time: startTime,
						end_time: endTime,
						page,
						per_page: perPage,
						...sortingParams,
					},
				}),
				providesTags: ['Recordings'],
			}),
			getSiteLogs: builder.query<GetSiteLogsResponse, GetSiteLogsRequest>({
				query: ({ siteId, startTime, endTime, page, perPage }) => ({
					url: `api/reports/${siteId}/site_logs/table`,
					params: {
						start_time: startTime,
						end_time: endTime,
						page,
						per_page: perPage,
					},
				}),
				providesTags: ['SiteLogs'],
			}),
			getEventSummary: builder.query<EventSummary, { eventId: number }>({
				query: ({ eventId }) => ({
					url: `/api/event_summaries/${eventId}`,
				}),
			}),
			getEventSummaryExport: builder.query<
				string,
				{ siteId: number; eventId: number }
			>({
				query: ({ siteId, eventId }) => ({
					url: `/api/sites/${siteId}/event_summaries/${eventId}/export`,
					responseHandler: 'text',
				}),
				keepUnusedDataFor: 0,
			}),
			updateEventSummary: builder.mutation<Event, UpdateEventSummaryRequest>({
				query: ({ eventId, false_positive }) => ({
					url: `/api/event_summaries/${eventId}`,
					method: 'PATCH',
					body: {
						event: {
							false_positive,
						},
					},
				}),
				invalidatesTags: ['Analytics'],
			}),
			getDetectedRfDetails: builder.query<SiteRfDetails, { siteId: number }>({
				query: ({ siteId }) => ({
					url: `/api/sites/${siteId}/detected_rf_details`,
				}),
				providesTags: ['Analytics'],
			}),
			getVideoRecording: builder.query<VideoRecording, { recordingId: number }>(
				{
					query: ({ recordingId }) => ({
						url: `/api/recordings/${recordingId}`,
					}),
				}
			),
			getVideoRecordings: builder.query({
				async queryFn({ recordingIds }, _queryApi, _extraOptions, fetchWithBQ) {
					try {
						const fetchPromises = recordingIds.map((recordingId: number) =>
							fetchWithBQ({ url: `/api/recordings/${recordingId}` })
						)
						const responses = await Promise.all(fetchPromises)

						const errorResponse = responses.find((response) => !response.data)
						if (errorResponse) {
							return { error: errorResponse.error }
						}
						const data = responses.map(
							(response) => response.data as VideoRecording
						)
						return { data }
					} catch (error) {
						return { error: error }
					}
				},
			}),
			deleteVideoRecording: builder.mutation<
				{ message: string },
				{ recordingId: number }
			>({
				query: ({ recordingId }) => ({
					url: `/api/recordings/${recordingId}`,
					method: 'DELETE',
				}),
				invalidatesTags: ['Analytics'],
			}),
		}),
	})

export const {
	useGetTrackSummaryQuery,
	useGetDisruptionsSummaryQuery,
	useGetAlertsSummaryQuery,
	useGetVideoRecordingsSummaryQuery,
	useGetSiteLogsQuery,
	useGetEventSummaryQuery,
	useLazyGetEventSummaryExportQuery,
	useUpdateEventSummaryMutation,
	useGetDetectedRfDetailsQuery,
	useGetVideoRecordingQuery,
	useGetVideoRecordingsQuery,
	useLazyGetVideoRecordingQuery,
	useDeleteVideoRecordingMutation,
} = analyticsApi
