import type { ReactNode } from 'react'
import { useForm, FormProvider } from 'react-hook-form'

import Accordion from './Accordion/Accordion'
import Checkbox from './Checkbox/Checkbox'
import CheckboxGroup from './CheckboxGroup/CheckboxGroup'
import Divider from './Divider/Divider'
import LatLongInput from './LatLongInput/LatLongInput'
import NumberInput from './NumberInput/NumberInput'
import MultiSelect from './Select/MultiSelect'
import Radio from './Radio/Radio'
import RangeSlider from './RangeSlider/RangeSlider'
import RangeFilterSlider from './RangeSlider/RangeFilterSlider'
import Select from './Select/Select'
import Slider from './Slider/Slider'
import UnitsSlider from './Slider/UnitsSlider'
import Switch from './Switch/Switch'
import TextInput from './TextInput/TextInput'
import FileInput from './FileInput/FileInput'
import TextArea from './TextArea/TextArea'
import PasswordInput from './PasswordInput/PasswordInput'
import ColorInput from './ColorInput/ColorInput'
import PhoneNumberInput from './PhoneNumberInput/PhoneNumberInput'
import MgrsInput from './MgrsInput/MgrsInput'

const FormElements = {
	Accordion,
	Checkbox,
	CheckboxGroup,
	Divider,
	LatLongInput,
	MultiSelect,
	NumberInput,
	PasswordInput,
	Radio,
	RangeSlider,
	RangeFilterSlider,
	Select,
	Slider,
	UnitsSlider,
	Switch,
	TextInput,
	FileInput,
	TextArea,
	ColorInput,
	PhoneNumberInput,
	MgrsInput,
}

export const ReactHookFormDecorator = ({
	children,
}: {
	children: ReactNode
}) => {
	const methods = useForm()
	return <FormProvider {...methods}>{children}</FormProvider>
}

export default FormElements
