import { ZoomControl as ReactLeafletZoomControl } from 'react-leaflet'
import './ZoomControl.css'

const ZoomControl = () => (
	<ReactLeafletZoomControl
		position='bottomleft'
		zoomInText='<span aria-hidden="true">&#xFF0B;</span>'
		zoomOutText='<span aria-hidden="true">&#xFF0D;</span>'
	/>
)

export default ZoomControl
