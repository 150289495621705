import { intervalToDuration, formatDuration, format } from 'date-fns'
import { convertDegreesToCardinal } from '@Utils/mathUtils'
import convertUnits from '@Utils/convertUnits'
import { ECHOSHIELD_RADAR_FREQUENCY_CHANNEL } from '@Constants/radars'

export const formatEventId = (targetId: string | null) => {
	return targetId === null ? null : targetId.split('-')[0]
}

export const formatProbability = (probability: number) => {
	if (probability >= 0) return (probability * 100).toFixed(2) + '%'
	else return null
}

export const formatThreatLevel = (threatLevel: number) => {
	if (threatLevel >= 0) return (threatLevel * 100).toFixed(2) + '%'
	else return null
}

export const formatSpeed = (metersPerSecond: number) => {
	return metersPerSecond > 0 ? metersPerSecond.toFixed(2) + ' m/s' : null
}

export const formatRadarRCS = (rcs: number) => {
	return isNaN(rcs) || rcs === 0 ? null : rcs.toFixed(2)
}

export const formatFrequency = (frequencyHz: number) => {
	if (frequencyHz > 0) {
		const conversion = convertUnits(frequencyHz).from('Hz').toBest()
		return conversion ? conversion.val.toFixed(4) + conversion.unit : null
	} else return null
}

export const formatAltitude = (height: number) => {
	const conversion = convertUnits(height)
		.from('m')
		.toBest({ exclude: ['nm', 'μm', 'mm', 'cm'] })
	return conversion !== null
		? conversion.val.toFixed(2) + conversion.unit
		: null
}

export const formatDistance = (distance: number) => {
	if (distance > 0 && distance < 10000) {
		return `${Math.round(distance)}m`
	}
	if (distance > 0 && distance >= 10000) {
		return `${(distance / 1000).toFixed(2)}km`
	}
	return null
}

export const formatBeaconString = (beaconString: string) => {
	if (!beaconString || beaconString === 'Empty_Field') return null
	else return beaconString
}

export const formatHeading = (heading: number) => {
	return heading === null ? null : convertDegreesToCardinal(heading)
}

export const formatDirection = (direction: number) => {
	return direction === null ? null : direction?.toFixed(2)
}

export const formatLatLng = (latOrLng: number) => {
	if (isNaN(latOrLng)) return null
	else if (latOrLng === 0) return null
	else if (latOrLng === -9999) return null
	return latOrLng.toFixed(5)
}

export const formatSecondsToMinutes = (seconds: number) => {
	const date = new Date(0)
	date.setSeconds(seconds)
	return format(date, 'mm:ss')
}

//
// Time on Target using date-fns
const formatTimeOnTargetLocale = {
	xMinutes: '{{count}}m',
	xSeconds: '{{count}}s',
}
const shortLocale = {
	formatDistance: (
		token: keyof typeof formatTimeOnTargetLocale,
		count: string
	) => formatTimeOnTargetLocale[token].replace('{{count}}', count),
}
export const formatTimeOnTarget = (seconds: number) => {
	if (isNaN(seconds)) return null
	const duration = intervalToDuration({ start: 0, end: seconds * 1000 })
	// prettier-ignore
	return seconds > 0
		? formatDuration(duration, {
			format: ['minutes', 'seconds'],
			locale: shortLocale,
		})
		: null
}

// Formats milliseconds to a duration like: 3h 1m 12s
export const formatMillisecondsDuration = (ms: number) => {
	if (!ms) return '-'

	const seconds = Math.floor((ms / 1000) % 60)
	const minutes = Math.floor((ms / 1000 / 60) % 60)
	const hours = Math.floor(ms / 1000 / 60 / 60)

	return hours > 0
		? `${hours}h ${minutes}m ${seconds}s`
		: `${minutes}m ${seconds}s`
}

export const formatSecondsDuration = (totalSeconds: number): string => {
	if (!totalSeconds && totalSeconds !== 0) return ''

	const hours = Math.floor(totalSeconds / 3600)
	const remainingMinutes = Math.floor((totalSeconds % 3600) / 60)
	const seconds = Math.floor(totalSeconds % 60)

	return hours > 0
		? `${hours}h ${remainingMinutes}m ${seconds}s`
		: `${remainingMinutes}m ${seconds}s`
}

export const formatBytes = (bytes: number) => {
	if (bytes < 1024) {
		// Less than 1 KB, display in bytes
		return `${bytes} B`
	} else if (bytes < 1024 * 1024) {
		// Between 1 KB and 1 MB, convert to KB
		return `${(bytes / 1024).toFixed(2)} KB`
	} else if (bytes < 1024 * 1024 * 1024) {
		// Between 1 MB and 1 GB, convert to MB
		return `${(bytes / 1024 / 1024).toFixed(2)} MB`
	} else {
		// 1 GB and above, convert to GB
		return `${(bytes / 1024 / 1024 / 1024).toFixed(2)} GB`
	}
}

export const formatDegToMils = (deg: number) => {
	return (deg * 17.77777777777778).toFixed(0)
}

export const formatMilsToDeg = (mils: number) => {
	return (mils * 0.05625).toFixed(0)
}

export const formatRadarFrequencyChannel = (
	radarType: string,
	channel: number
) => {
	// radar_zero we return just the number but since it starts from zero we add 1
	if (radarType === 'radar_zero') return channel + 1

	return (
		ECHOSHIELD_RADAR_FREQUENCY_CHANNEL.find(({ value }) => value === channel)
			?.label ?? '-'
	)
}
