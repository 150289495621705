import {
	type AlertProps,
	createMultiStyleConfigHelpers,
} from '@chakra-ui/react'
import { alertAnatomy } from '@chakra-ui/anatomy'
import FIGMA from '../_FIGMA'

const { definePartsStyle, defineMultiStyleConfig } =
	createMultiStyleConfigHelpers(alertAnatomy.keys)

const baseStyle = definePartsStyle((props: AlertProps) => {
	const { status } = props

	const successBase = status === 'success' && {
		container: {
			_dark: {
				background: 'body_bg',
				borderInlineStartColor: FIGMA.colors.success,
				borderRadius: 0,
			},
		},
		icon: {
			color: 'success',
		},
	}

	const warningBase = status === 'warning' && {
		container: {
			_dark: {
				background: 'body_bg',
				borderInlineStartColor: FIGMA.colors.warning,
				borderRadius: 0,
			},
		},
		icon: {
			color: 'warning',
		},
	}

	const errorBase = status === 'error' && {
		container: {
			_dark: {
				background: 'body_bg',
				borderInlineStartColor: FIGMA.colors.error,
				borderRadius: 0,
			},
		},
		icon: {
			color: 'error',
		},
	}

	// TODO: info alert
	const infoBase = status === 'info' && {
		icon: {
			color: 'warning',
		},
	}

	return {
		...successBase,
		...warningBase,
		...errorBase,
		...infoBase,
	}
})

const outlineVariant = definePartsStyle((props: AlertProps) => {
	const { status } = props
	return {
		container: {
			_dark: {
				background: 'body_bg',
				border: `1px solid ${
					status === 'info' ? FIGMA.colors.primary : FIGMA.colors.error
				}`,
				padding: '8px',
				alignItems: 'center',
			},
		},
	}
})

export const Alert = defineMultiStyleConfig({
	baseStyle,
	variants: { outline: outlineVariant },
})
