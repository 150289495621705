export const ECHOSHIELD_RADAR_FREQUENCY_CHANNEL = [
	{
		label: 'L1',
		value: 0,
	},
	{
		label: 'L2',
		value: 1,
	},
	{
		label: 'L3',
		value: 2,
	},
	{
		label: 'L4',
		value: 3,
	},
	{
		label: 'L5',
		value: 4,
	},
	{
		label: 'U1',
		value: 5,
	},
	{
		label: 'U2',
		value: 6,
	},
	{
		label: 'U3',
		value: 7,
	},
	{
		label: 'U4',
		value: 8,
	},
	{
		label: 'U5',
		value: 9,
	},
	{
		label: 'U6',
		value: 10,
	},
	{
		label: 'U7',
		value: 11,
	},
	{
		label: 'U8',
		value: 12,
	},
	{
		label: 'U9',
		value: 13,
	},
	{
		label: 'U10',
		value: 14,
	},
	{
		label: 'U11',
		value: 15,
	},
	{
		label: 'U12',
		value: 16,
	},
	{
		label: 'U13',
		value: 17,
	},
	{
		label: 'U14',
		value: 18,
	},
	{
		label: 'U15',
		value: 19,
	},
	{
		label: 'U16',
		value: 20,
	},
	{
		label: 'U17',
		value: 21,
	},
]
