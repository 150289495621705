import { useTheme } from '@chakra-ui/react'

import { selectSiteZones, useGetSiteLiveQuery } from '@Store/sites/sitesWsApi'
import { selectUserZoneSettings } from '@Store/user/userSlice'
import { useAppSelector } from '@Store/index'
import useAuth from '@Hooks/useAuth'

import Polygon from '@Components/MapShapes/Polygon/Polygon'

import type { SimplifiedZone, Zone } from '@Store/types'

type ZonesProps = {
	siteId: number
	// These props are used for the event replay in analytics.
	// In this case, we provide zones instead of fetching them
	// from the socket
	isEventReplay?: boolean
	eventReplayZones?: Zone[] | SimplifiedZone[]
}

const Zones = ({ siteId, eventReplayZones, isEventReplay }: ZonesProps) => {
	const { liveZones, isError } = useGetSiteLiveQuery(siteId, {
		skip: isEventReplay,
		selectFromResult: ({ data, isError }) => ({
			isError,
			liveZones: selectSiteZones(data),
		}),
	})

	const zoneSettings = useAppSelector(selectUserZoneSettings)
	const { isAdmin } = useAuth()

	const {
		semanticTokens: { colors },
	} = useTheme()

	const zones = isEventReplay ? eventReplayZones : liveZones

	const displayedZones = (zones ?? [])
		.filter((zone) => zoneSettings?.visibleZoneTypes?.includes(zone.zone_type))
		.filter((zone) => zone.zone_type !== 'origin' || isAdmin)

	if (isError) {
		console.error('TODO: handle failed to get zones from getSiteLive')
	}

	return (
		<>
			{displayedZones.map((zone) => (
				<Polygon
					key={zone.id}
					positions={zone.coordinates}
					fillColor='transparent'
					color={colors.zones[zone.zone_type]}
					dashArray={[14]}
					weight={2}
					interactive={false}
					testId={`zone-${zone.zone_type}`}
				/>
			))}
		</>
	)
}

export default Zones
