// Redux
import { createSlice, createSelector, isAnyOf } from '@reduxjs/toolkit'
import { authApi } from '@Store/auth/authApi'
import { usersApi } from './usersApi'
import { logout } from '../auth/authSlice'

// Types
import type { Draft } from '@reduxjs/toolkit'
import type { RootState } from '@Store/index'
import type { User, ZoneSettings } from '@Store/types'

// Initial State
type InitialState = User | null

// User slice
export const userSlice = createSlice({
	name: 'user',
	initialState: null as InitialState,
	reducers: {
		updateUserSettings: (state, { payload: settings }) => {
			if (state) state.settings = settings
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			// User logged in
			// Store user in this slice
			authApi.endpoints.login.matchFulfilled,
			(state: Draft<InitialState>, { payload }) => payload.user as User
		)

		// User logged out
		// Clear the current user and force new login
		builder.addMatcher(isAnyOf(logout.fulfilled), (state) => {
			return null
		})

		builder.addMatcher(
			// A user was edited in User Management
			// If user edited themself, update user info in this slice
			usersApi.endpoints.updateUser.matchFulfilled,
			(state: Draft<InitialState>, { payload }) => {
				if (payload.id === state?.id) {
					const {
						name,
						email,
						username,
						company,
						phone,
						role,
						updated_at,
						deleted_at,
					} = payload
					return {
						...state,
						name,
						email,
						username,
						company,
						phone,
						role,
						updated_at,
						deleted_at,
					} as User
				}
			}
		)

		builder.addMatcher(
			// A user saved their own profile
			// Store relevant updated user info in this slice
			usersApi.endpoints.updateUserProfile.matchFulfilled,
			(state: Draft<InitialState>, { payload }) => {
				const {
					language,
					play_alarm_sounds,
					default_site_id,
					settings,
					name,
					username,
					email,
					company,
					phone,
					updated_at,
				} = payload
				return {
					...state,
					language,
					play_alarm_sounds,
					default_site_id,
					settings,
					name,
					username,
					email,
					company,
					phone,
					updated_at,
				} as User
			}
		)

		// User updated their own user settings

		// given that a user can click the Map Layers button many times,
		// if many Update User Settings requests are fired, the requests
		// can resolve delayed and out of order, and the settings object
		// can be thrown out of sync with what the user expects it to be

		// track the latest requestId
		let latestUpdateUserSettingsRequestId: string | null

		builder.addMatcher(
			usersApi.endpoints.updateUserSettings.matchPending,
			(state: Draft<InitialState>, { meta }) => {
				// keep a record of the latest requestId as updateUserSettings enters pending state
				latestUpdateUserSettingsRequestId = meta.requestId
			}
		)

		// Store updated user settings in this slice
		builder.addMatcher(
			usersApi.endpoints.updateUserSettings.matchFulfilled,
			(state: Draft<InitialState>, { payload, meta }) => {
				const settings = payload.settings
				// when updateUserSettings fulfilled and it's the latest one, update the settings
				if (meta.requestId === latestUpdateUserSettingsRequestId)
					return {
						...state,
						settings,
					} as User
			}
		)

		// User reloaded the browser window, triggering auth revalidation
		// Store the re-authed user in this slice
		builder.addMatcher(
			authApi.endpoints.getAuthUser.matchFulfilled,
			(state: Draft<InitialState>, { payload }) => payload as User
		)

		// User deactivated or deleted themself
		// Clear the current user
		builder.addMatcher(
			isAnyOf(
				usersApi.endpoints.deactivateUser.matchFulfilled,
				usersApi.endpoints.deleteUser.matchFulfilled
			),
			(state: Draft<InitialState>, { payload, meta }) => {
				const userId = meta.arg.originalArgs.userId
				if (userId === state?.id) {
					return null
				}
			}
		)
	},
})

// Selectors
export const selectUserDefaultSiteId = (state: RootState): number | null => {
	return state.user === null ? null : state.user.default_site_id
}

export const selectUser = (state: RootState): User | null => state.user

export const selectUserId = createSelector(selectUser, (user) => user?.id)
export const selectUserLanguage = createSelector(
	selectUser,
	(user) => user?.language
)
export const selectUserAlertSoundEnabled = createSelector(
	selectUser,
	(user) => user?.play_alarm_sounds
)

export const selectUserRole = createSelector(selectUser, (user) => user?.role)

export const selectUserRoleBySiteId = createSelector(
	[selectUser, (user, siteId: number | undefined) => siteId],
	(user, siteId) => {
		if (user?.role) {
			if (user.role !== 'user') return user.role
			else
				return (
					siteId &&
					user.site_associations?.find(
						(association) => association.site_id === siteId
					)?.role
				)
		} else return null
	}
)

export const selectUserSites = createSelector(
	selectUser,
	(state) => state?.site_associations
)
export const selectUserClientId = createSelector(
	selectUser,
	(user) => user?.client_id
)

export const selectUserDetails = createSelector(selectUser, (user) => ({
	name: user?.name,
	email: user?.email,
	role: user?.role,
}))

export const selectUserSettings = createSelector(
	selectUser,
	(user) => user?.settings
)

export const selectMilsEnabled = createSelector(
	selectUser,
	(user) => user?.settings?.milsEnabled
)

export const selectUserMapLayerId = createSelector(
	selectUserSettings,
	(settings) => settings?.mapLayerId
)

export const selectUserZoneSettings = createSelector(
	selectUserSettings,
	(settings) =>
		({
			...settings?.zoneSettings,
			displayedSectors: settings?.zoneSettings.displayedSectors ?? {},
			visibleZoneTypes: settings?.zoneSettings.visibleZoneTypes ?? [],
		}) as ZoneSettings
)

export const selectUserConcentricMarkerCoordinatesBySiteId = createSelector(
	[selectUserSettings, (settings, siteId: number) => siteId],
	(settings, siteId) =>
		(settings?.zoneSettings.radiatingCircle &&
			(settings?.zoneSettings.radiatingCircle[String(siteId)]?.coordinates as [
				number,
				number,
			])) ??
		null
)

// Reducer slice
export default userSlice.reducer

export const { updateUserSettings } = userSlice.actions
