import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

const SystemWarningsNoWarnings = () => {
	const { t } = useTranslation('navigation', {
		keyPrefix: 'systemWarnings',
	})

	return (
		<Box textAlign='center' pt={2} fontSize='xs' color='disabled'>
			{t('noSystemWarnings')}
		</Box>
	)
}

export default SystemWarningsNoWarnings
