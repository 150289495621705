const BtLogo = () => {
	return (
		<svg
			width='111'
			height='111'
			viewBox='0 0 111 111'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			data-testid='navigation-customer-bt-logo'
		>
			<path
				d='M55.5 -0.00444031C24.8444 -0.00444031 0 24.84 0 55.4956C0 86.1511 24.8444 110.996 55.5 110.996C86.1556 110.996 111 86.14 111 55.4956C111 24.8511 86.1444 -0.00444031 55.5 -0.00444031ZM55.4778 6.84C82.3889 6.84 104.2 28.6511 104.2 55.5622C104.2 82.4733 82.3889 104.284 55.4778 104.284C28.5667 104.284 6.75556 82.4622 6.75556 55.5622C6.75556 28.6622 28.5667 6.84 55.4778 6.84Z'
				fill='white'
			/>
			<path
				d='M27.834 36.5289V74.3178H43.2118C50.2007 74.3178 55.5673 69.9511 55.5673 63.04C55.5673 56.9511 50.8118 54.6178 50.8118 54.6178C50.8118 54.6178 54.4673 52.2845 54.4673 47.1067C54.4673 41.0845 49.7118 36.5289 42.7007 36.5289H27.834ZM36.3451 43.9067H40.8895C44.1229 43.9067 45.5229 45.5622 45.5229 47.6178C45.5229 49.84 43.9229 51.2511 41.1007 51.2511H36.3451V43.9067ZM36.3451 58.8178H40.8895C44.1229 58.8178 45.5229 60.4733 45.5229 62.5289C45.5229 64.7511 43.9229 66.1622 41.1007 66.1622H36.3451V58.8178Z'
				fill='white'
			/>
			<path
				d='M66.9785 74.1955V45.1955H58.4785V36.4622H84.923V45.1955H76.0563V74.1955H66.9785Z'
				fill='white'
			/>
		</svg>
	)
}

export default BtLogo
