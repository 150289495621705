// React
import { useTranslation } from 'react-i18next'
// Chakra
import {
	Button,
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	useDisclosure,
} from '@chakra-ui/react'
import { MdOpenInNew } from 'react-icons/md'
// Store
import type { RfSensorFilter } from '@Store/types'
import { RfFiltersTable } from '@Components/Site/Panels/SiteInstallations/RfFilters/RfFiltersTable'

const RfFiltersTableModal = ({
	rf_filters,
	isWhiteListed = false,
}: {
	rf_filters: RfSensorFilter[]
	isWhiteListed?: boolean
}) => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	// Translations
	const { t } = useTranslation('panels', { keyPrefix: 'rfFilters' })

	return (
		<>
			<Flex mb={2}>
				{!!rf_filters.length && (
					<Button variant='action' leftIcon={<MdOpenInNew />} onClick={onOpen}>
						{t('tableView')}
					</Button>
				)}
			</Flex>

			<Modal isOpen={isOpen} onClose={onClose} isCentered>
				<ModalOverlay />
				<ModalContent minW='calc(100% - 400px)'>
					<ModalHeader>{t('headers.rfFilters')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody mb={6}>
						<RfFiltersTable
							rf_filters={rf_filters}
							variant='expanded'
							isWhiteListed={isWhiteListed}
						/>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	)
}

export default RfFiltersTableModal
