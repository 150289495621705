// React
import { useTranslation } from 'react-i18next'
// Chakra
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
// Components
import SystemWarningsList from './SystemWarningsList'
import SystemWarningsListHistory from './SystemWarningsListHistory'
// Store
import { selectSiteTimeZone, useGetSiteQuery } from '@Store/sites/sitesApi'

const SystemWarningsTabs = ({ siteId }: { siteId: number }) => {
	const { t } = useTranslation('navigation', {
		keyPrefix: 'systemWarnings',
	})

	const { siteTimeZone } = useGetSiteQuery(siteId, {
		skip: !siteId,
		selectFromResult: ({ data }) => ({
			siteTimeZone: selectSiteTimeZone(data),
		}),
	})

	if (!siteTimeZone) return null

	return (
		<Box p={2}>
			<Tabs isLazy>
				<TabList>
					<Tab flex='1' data-testid='warnings-tab'>
						{t('tabs.systemWarnings')}
					</Tab>
					<Tab flex='1' data-testid='history-tab'>
						{t('tabs.history')}
					</Tab>
				</TabList>
				<TabPanels>
					<TabPanel maxH='90vh' overflowY='scroll'>
						<SystemWarningsList siteId={siteId} siteTimeZone={siteTimeZone} />
					</TabPanel>
					<TabPanel maxH='90vh' overflowY='scroll'>
						<SystemWarningsListHistory
							siteId={siteId}
							siteTimeZone={siteTimeZone}
						/>
					</TabPanel>
				</TabPanels>
			</Tabs>
		</Box>
	)
}

export default SystemWarningsTabs
