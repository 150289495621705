import { useParams } from 'react-router-dom'
import { Box, Text } from '@chakra-ui/react'

import {
	useGetSiteLiveQuery,
	selectSiteCameraById,
} from '@Store/sites/sitesWsApi'

const RecordingStatus = ({ cameraId }: { cameraId: number }) => {
	const { siteId: siteIdParam } = useParams()

	const { camera } = useGetSiteLiveQuery(Number(siteIdParam), {
		selectFromResult: ({ data }) => ({
			camera: selectSiteCameraById(data, cameraId),
		}),
	})

	return (
		camera?.recording && (
			<Box
				bgColor='camera.recording_red_bg'
				paddingBlock={1}
				paddingInlineStart={2}
				paddingInlineEnd={3}
				borderRadius={2}
			>
				<Text
					as='span'
					color='camera.recording_red'
					paddingInlineEnd={2}
					fontSize='x-large'
					lineHeight={0}
				>
					●
				</Text>
				<Text as='span'>Recording</Text>
			</Box>
		)
	)
}

export default RecordingStatus
