import i18n from '@/i18n'
import { memo } from 'react'
import { Flex, Icon, Text } from '@chakra-ui/react'
import { MdNavigation } from 'react-icons/md'
import Tooltip from '@UI/Tooltip/Tooltip'

const CompassTool = () => {
	const t = (key: string) => i18n.t(`map:mapControls.tooltips.${key}`)
	return (
		<Tooltip label={t('compassTool')} placement='right-start'>
			<Flex
				alignItems='center'
				flexDirection='column'
				bgColor='leaflet.control_bg'
				paddingBlockStart={1}
				marginBlockEnd={2}
				borderRadius={2}
				className={'c2-map-compass-control'}
				w='32px'
			>
				<Text fontSize='20px' lineHeight='24px' fontWeight='600'>
					N
				</Text>
				<Icon
					as={MdNavigation}
					w='24px'
					h='24px'
					pb='4px'
					color='status.no_connection'
				/>
			</Flex>
		</Tooltip>
	)
}

export default memo(CompassTool)
