// React
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
// Chakra
import { useDisclosure, Text, Flex, Input } from '@chakra-ui/react'
import { MdDelete, MdLockReset } from 'react-icons/md'
import { ImUserCheck, ImUserMinus } from 'react-icons/im'
// Components
import Button from '@UI/Button/Button'
import DeleteModal from '@UI/Modals/DeleteModal'
// Store
import {
	useActivateUserMutation,
	useDeactivateUserMutation,
	useDeleteUserMutation,
	useResetUserPasswordMutation,
} from '@Store/user/usersApi'
import type { User } from '@Store/types'
import UpdateModal from '@UI/Modals/UpdateModal'
import Modal from '@UI/Modals/Modal'
import CopyButton from '@UI/CopyButton/CopyButton'
import {
	selectIsOnPremOrStaging,
	useGetSystemInfoQuery,
} from '@Store/system/systemApi'
import { addToast } from '@Store/ui/uiSlice'
import { useAppDispatch } from '@/store'
import type { FormError } from '@Forms/types'
// Types
type UserActionType = {
	id: number
	name: string
	resetPasswordLink?: string
	action:
		| 'delete'
		| 'activate'
		| 'deactivate'
		| 'reset-password'
		| 'password-confirmation'
} | null

const UserManagementActionButtons = ({ user }: { user: User }) => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const { t } = useTranslation('pages', {
		keyPrefix: 'userManagement',
	})
	// CONST
	const { deleted_at, name, id } = user
	const isActive = !deleted_at

	// DELETE USER
	const [userToAction, setUserToAction] = useState<UserActionType>({
		id: 0,
		name: '',
		action: 'delete',
	})

	// DELETE/ACTIVATE/DEACTIVATE USER
	const [deleteUser] = useDeleteUserMutation()
	const [activateUser] = useActivateUserMutation()
	const [deactivateUser] = useDeactivateUserMutation()
	const [resetUserPassword] = useResetUserPasswordMutation()

	const dispatch = useAppDispatch()

	const handleOpenModal = (userAction: UserActionType) => {
		setUserToAction((prev) => (prev ? { ...prev, ...userAction } : userAction))
		onOpen()
	}

	const handleDeleteUser = async () => {
		try {
			if (userToAction) {
				await deleteUser({ userId: Number(userToAction.id) }).unwrap()
				onClose()
			}
		} catch (error) {
			console.error(t('status.deleteUserError'), error)
			dispatch(
				addToast({
					type: 'error',
					title: t('status.deleteUserError'),
					description: (error as FormError)?.data?.error,
				})
			)
		}
	}

	const handleActivateUser = async () => {
		try {
			if (userToAction) {
				await activateUser({
					...{ ...user },
					userId: Number(userToAction.id),
				}).unwrap()
				onClose()
			}
		} catch (error) {
			console.error(t('status.activateUserError'), error)
			dispatch(
				addToast({
					type: 'error',
					title: t('status.activateUserError'),
					description: (error as FormError)?.data?.error,
				})
			)
		}
	}

	const handleResetUserPassword = async () => {
		try {
			if (userToAction) {
				await resetUserPassword(Number(userToAction.id))
					.unwrap()
					.then((resetPasswordLink) => {
						handleOpenModal({
							...userToAction,
							resetPasswordLink,
							action: 'password-confirmation',
						})
					})
			}
		} catch (error) {
			console.error(t('status.resetPasswordError'), error)
			dispatch(
				addToast({
					type: 'error',
					title: t('status.resetPasswordError'),
					description: (error as FormError)?.data?.error,
				})
			)
		}
	}

	const handleDeactivateUser = async () => {
		try {
			if (userToAction) {
				await deactivateUser({ userId: Number(userToAction.id) }).unwrap()
				onClose()
			}
		} catch (error) {
			console.error(t('status.deactivateUserError'), error)
			dispatch(
				addToast({
					type: 'error',
					title: t('status.deactivateUserError'),
					description: (error as FormError)?.data?.error,
				})
			)
		}
	}

	const handleCloseModal = () => {
		onClose()
		setUserToAction(null)
	}

	const { isOnPremOrStaging } = useGetSystemInfoQuery(undefined, {
		selectFromResult: ({ data }) => ({
			isOnPremOrStaging: selectIsOnPremOrStaging(data),
		}),
	})

	return (
		<>
			{isActive ? (
				<Button
					testId={`deactivate-user-${user.username}`}
					label='deactivate-user'
					shape='icon'
					variant='actions'
					tooltip={t('buttons.deactivateUser')}
					icon={<ImUserMinus />}
					onClick={() => handleOpenModal({ id, name, action: 'deactivate' })}
				/>
			) : (
				<Button
					testId={`activate-user-${user.username}`}
					label='activate-user'
					icon={<ImUserCheck />}
					shape='icon'
					variant='actions'
					tooltip={t('buttons.activateUser')}
					onClick={() => handleOpenModal({ id, name, action: 'activate' })}
				/>
			)}
			{isOnPremOrStaging && (
				<Button
					label='reset-user-password'
					testId={`reset-user-password-${user.username}`}
					isDisabled={!isActive}
					shape='icon'
					variant='actions'
					tooltip={t('buttons.resetPassword')}
					icon={<MdLockReset />}
					onClick={() =>
						handleOpenModal({ id, name, action: 'reset-password' })
					}
				/>
			)}
			<Button
				label='delete-user'
				testId={`delete-user-${user.username}`}
				isDisabled={isActive}
				shape='icon'
				variant='actions'
				tooltip={t('buttons.deleteUser')}
				icon={<MdDelete />}
				onClick={() => handleOpenModal({ id, name, action: 'delete' })}
			/>
			{/*DELETE*/}
			{userToAction?.action === 'delete' && (
				<DeleteModal
					isOpen={isOpen}
					onClose={handleCloseModal}
					handleDelete={handleDeleteUser}
					name={userToAction?.name || ''}
					headerText={t('headings.deleteUser')}
					fromText={t('headings.fromInstance')}
				/>
			)}
			{/*DEACTIVATE*/}
			{userToAction?.action === 'deactivate' && (
				<UpdateModal
					isOpen={isOpen}
					onClose={handleCloseModal}
					handleDelete={handleDeactivateUser}
					name={userToAction?.name || ''}
					headerText={t('headings.deactivateUser')}
					modalBodyText={t('modals.deactivatingUser')}
				/>
			)}
			{/*ACTIVATE*/}
			{userToAction?.action === 'activate' && (
				<UpdateModal
					isOpen={isOpen}
					onClose={handleCloseModal}
					handleDelete={handleActivateUser}
					name={userToAction?.name || ''}
					headerText={t('headings.activateUser')}
					modalBodyText={t('modals.activatingUser')}
				/>
			)}
			{/*RESET PASSWORD*/}
			{userToAction?.action === 'reset-password' && (
				<UpdateModal
					isOpen={isOpen}
					onClose={handleCloseModal}
					handleDelete={handleResetUserPassword}
					name={userToAction?.name || ''}
					headerText={t('headings.resetPassword')}
					modalBodyText={t('modals.resetPassword')}
				/>
			)}
			{/*PASSWORD CONFIRMATION*/}
			{userToAction?.action === 'password-confirmation' && (
				<Modal
					isOpen={isOpen}
					onClose={handleCloseModal}
					headerText={t('headings.passwordResetLink')}
				>
					<Flex direction='column' gap={3} p='8px'>
						{!userToAction.resetPasswordLink && <Text>{t('error')}</Text>}
						{userToAction.resetPasswordLink && (
							<>
								<Text>{t('modals.passwordResetLink')}</Text>
								<Flex gap={2} alignItems='center'>
									<Input
										type='text'
										value={userToAction.resetPasswordLink}
										disabled
									/>
									<CopyButton
										content={userToAction.resetPasswordLink ?? ''}
										successTitle={t('buttons.copyButton.titles.success')}
										errorTitle={t('buttons.copyButton.titles.error')}
										successDescription={t(
											'buttons.copyButton.descriptions.success'
										)}
										errorDescription={t(
											'buttons.copyButton.descriptions.error'
										)}
									/>
								</Flex>
							</>
						)}
					</Flex>
				</Modal>
			)}
		</>
	)
}

export default UserManagementActionButtons
