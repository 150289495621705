import { useTranslation } from 'react-i18next'
import { Flex, useTheme } from '@chakra-ui/react'
import { MdFilterAlt } from 'react-icons/md'

import {
	useGetSiteExportQuery,
	selectSiteExportHasCamera,
	selectSiteExportHasRadar,
	selectSiteExportHasRfSensor,
} from '@Store/sites/sitesApi'
import { useGetDetectedRfDetailsQuery } from '@Store/analytics/analyticsApi'

import Button from '@UI/Button/Button'
import DebouncedInput from '@UI/DebouncedInput/DebouncedInput'
import MultiSelect from '@UI/Select/MultiSelect'
import type { MultiSelectOption } from '@UI/Select/MultiSelect'

import { type Table } from '@tanstack/react-table'
import type { EventColumnType } from './columnDefinitions'

import type { Event } from '@Store/types'

export const SelectWrapper = ({ children }: { children: React.ReactNode }) => (
	<Flex direction='column' flexGrow={1} maxW='250px'>
		{children}
	</Flex>
)

type FilterableColumn = {
	id: EventColumnType
	title: string
	options: MultiSelectOption[]
	defaultValue?: MultiSelectOption[]
	placeholder?: string
}

type EventFilterProps<TData> = {
	table: Table<TData>
	siteId: number
}

const EventFilter = <TData = Event[],>({
	table,
	siteId,
}: EventFilterProps<TData>) => {
	const {
		semanticTokens: { colors },
	} = useTheme()

	const { t } = useTranslation('pages', {
		keyPrefix: 'analytics.trackSummary',
	})

	const { setGlobalFilter } = table
	const globalFilter = table.getState().globalFilter

	const { protocols, vendors } = useGetDetectedRfDetailsQuery(
		{ siteId },
		{
			selectFromResult: ({ data }) => ({
				protocols: data?.protocols,
				vendors: data?.vendors,
			}),
		}
	)

	const { hasRfSensor, hasRadar, hasCamera } = useGetSiteExportQuery(siteId, {
		selectFromResult: ({ data }) => ({
			hasRfSensor: selectSiteExportHasRfSensor(data),
			hasRadar: selectSiteExportHasRadar(data),
			hasCamera: selectSiteExportHasCamera(data),
		}),
	})

	const protocolFilterOptions = (protocols ?? []).map((protocol) => ({
		label: protocol,
		value: protocol,
	}))

	const vendorFilterOptions = (vendors ?? []).map((vendor) => ({
		label: vendor,
		value: vendor,
	}))

	const detectionTypeOptions: MultiSelectOption[] = t('detectionTypeOptions', {
		returnObjects: true,
	})

	const detectionStateOptions: MultiSelectOption[] = t(
		'detectionStateOptions',
		{
			returnObjects: true,
		}
	)

	const probabilityOptions: MultiSelectOption[] = t('probabilityOptions', {
		returnObjects: true,
	})

	const getConfirmedByOptions = () => {
		const options = [
			{
				label: 'DroneLocator',
				value: 'droneLocator',
			},
		]

		if (hasCamera) {
			options.push({
				label: 'Camera',
				value: 'camera',
			})
		}
		if (hasRadar) {
			options.push({
				label: 'Radar',
				value: 'radar',
			})
		}
		if (hasRfSensor) {
			options.push({
				label: 'RF',
				value: 'rf',
			})
		}
		return options
	}

	const filterableColumns: FilterableColumn[] = [
		{
			id: 'confirmed_by',
			title: t('table.confirmedBy'),
			options: getConfirmedByOptions(),
			placeholder: t('placeholders.selectOption'),
		},
		{
			id: 'false_positive',
			title: t('table.detectionsCount'),
			options: detectionTypeOptions,
			placeholder: t('placeholders.selectOption'),
		},
		{
			id: 'vendor',
			title: t('table.vendor'),
			options: vendorFilterOptions,
			placeholder: t('placeholders.selectOption'),
		},
		{
			id: 'protocol',
			title: t('table.protocol'),
			options: protocolFilterOptions,
			placeholder: t('placeholders.all'),
		},
		{
			id: 'state',
			title: t('table.state'),
			options: detectionStateOptions,
			placeholder: t('placeholders.selectOption'),
		},
		{
			id: 'probability',
			title: t('table.probability'),
			options: probabilityOptions,
			placeholder: t('placeholders.selectOption'),
		},
	]

	const filterValue = (columnId: string) => {
		const filters = table.getColumn(columnId)?.getFilterValue() as string

		if (!filters) return undefined

		const column = filterableColumns.find((column) => column.id === columnId)

		return column?.options.filter((option) =>
			filters.split(',').includes(option.value as string)
		)
	}

	const handleFilterChange = (
		filterValues: MultiSelectOption[],
		type: EventColumnType
	) => {
		const filters = filterValues.map((filter) => filter.value).join(',')
		table.getColumn(type)?.setFilterValue(filters)
		table.setPageIndex(0)
	}

	return (
		<Flex alignItems='flex-end' gap='10px' mb={2} flexWrap='wrap'>
			<DebouncedInput
				title={t('searchGlobal.title')}
				value={globalFilter ?? ''}
				onChange={(value) => setGlobalFilter(String(value))}
				placeholder={t('searchGlobal.placeholder')}
				data-testid='global-search'
				flexGrow={1}
				debounce={500}
				minW='300px'
			/>
			<Button
				size='xs'
				label='Filters'
				leftIcon={<MdFilterAlt />}
				testId='filters'
				pointerEvents='none'
				color={colors.label_color}
			/>
			{filterableColumns.map((column) => (
				<SelectWrapper key={column.id}>
					<MultiSelect
						title={column.title}
						placeholder={column.placeholder}
						options={column.options}
						defaultValue={column.defaultValue}
						value={filterValue(column.id) ?? null}
						onChange={(value: MultiSelectOption[]) =>
							handleFilterChange(value, column.id)
						}
					/>
				</SelectWrapper>
			))}
			<Button
				size='xs'
				label={t('buttons.resetFilters')}
				testId='reset-filters'
				variant='link'
				onClick={() => table.resetColumnFilters()}
			/>
		</Flex>
	)
}

export default EventFilter
