// React
import { useEffect, useMemo } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// Form
import { useForm, FormProvider } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import type { TypeOf } from 'zod'
import {
	installationFormSchema,
	ADD_INSTALLATION_DEFAULT_VALUES,
} from './InstallationFormSchemas'

// State, RTK
import type { SiteInstallation, SiteInstallationLocator } from '@Store/types'
import {
	selectSiteInstallationLocatorSensorOptions,
	useGetSiteInstallationQuery,
	useGetSiteInstallationLocatorsQuery,
	useUpdateSiteInstallationMutation,
	useCreateSiteInstallationMutation,
	useDeleteSiteInstallationMutation,
	useGetSiteQuery,
} from '@Store/sites/sitesApi'
import { skipToken } from '@reduxjs/toolkit/query'

// Chakra
import { type SingleValue } from 'chakra-react-select'
import {
	Flex,
	FormControl,
	FormErrorMessage,
	Grid,
	GridItem,
} from '@chakra-ui/react'
// Components
import Field from '@Components/FormElements'
import UiSelect from '@UI/Select/Select'
import {
	FormButtons,
	FormDeleteButton,
} from '@Components/FormElements/FormButtons/FormButtons'
import FormWrapper from '@Components/FormElements/FormWrapper/FormWrapper'
import { useAppDispatch, useAppSelector } from '@/store'
import { selectSentryData, setSentryData } from '@Store/ui/uiSlice'

const InstallationForm = ({
	siteId,
	installationId,
}: {
	siteId: number
	installationId?: number
}) => {
	const { t } = useTranslation('forms', { keyPrefix: 'installationForm' })
	const isEditForm = !!installationId
	const {
		isLoading,
		isError,
		isSuccess,
		refetch,
		data: installation,
	} = useGetSiteInstallationQuery(
		siteId && installationId ? { siteId, installationId } : skipToken,
		{ refetchOnMountOrArgChange: true }
	)

	const {
		data: site,
		isSuccess: isSiteSuccess,
		isLoading: isSiteLoading,
	} = useGetSiteQuery(siteId ? siteId : skipToken, {
		skip: !siteId,
	})

	if (!isSiteSuccess) return null

	const addInstallationDefaultValues = {
		...ADD_INSTALLATION_DEFAULT_VALUES,
		name: t('entity'),
		latitude: isSiteSuccess ? site?.latitude : 0,
		longitude: isSiteSuccess ? site?.longitude : 0,
	} as SiteInstallation

	const defaultValues = isEditForm ? installation : addInstallationDefaultValues

	return (
		<FormWrapper
			entity={t('entity')}
			isEditForm={isEditForm}
			isLoading={isLoading || isSiteLoading}
			isError={isError}
			isSuccess={isSuccess && isSiteSuccess}
			refetch={refetch}
		>
			{defaultValues && (
				<Form defaultValues={defaultValues} isEditForm={isEditForm} />
			)}
		</FormWrapper>
	)
}

const Form = ({
	defaultValues,
	isEditForm,
}: {
	defaultValues: SiteInstallation
	isEditForm: boolean
}) => {
	const { siteId, installationId } = useParams()
	const navigate = useNavigate()

	const [updateInstallation] = useUpdateSiteInstallationMutation()
	const [createInstallation] = useCreateSiteInstallationMutation()
	const [deleteInstallation] = useDeleteSiteInstallationMutation()

	const dispatch = useAppDispatch()

	const installationsRoute = `/${siteId}/installations`

	// Translations
	const { t } = useTranslation('forms', { keyPrefix: 'installationForm' })

	type Schema = TypeOf<typeof installationFormSchema>
	const methods = useForm<Schema>({
		resolver: zodResolver(installationFormSchema),
		defaultValues,
	})

	const {
		register,
		formState: { errors, isSubmitting, isDirty },
		handleSubmit,
		setValue,
		setError,
		watch,
	} = methods

	// Track live values
	const {
		accept_location_updates: acceptLocationUpdates,
		latitude,
		longitude,
		tracked,
		locatable_id,
		locatable_type,
		name,
		sentry_type,
		status_color,
		direction,
	} = watch()

	// Handle unchecking installation switchers
	useEffect(() => {
		if (!acceptLocationUpdates) {
			setValue('tracked', false)
			setValue('anchored', false)
		} else {
			dispatch(
				setSentryData({
					latitude: defaultValues?.latitude,
					longitude: defaultValues?.longitude,
				})
			)
		}
		dispatch(setSentryData({ accept_location_updates: acceptLocationUpdates }))
	}, [acceptLocationUpdates, setValue, dispatch, defaultValues])

	// Drag/Drop installation marker
	useEffect(() => {
		dispatch(
			setSentryData({
				latitude: defaultValues?.latitude,
				longitude: defaultValues?.longitude,
			})
		)
	}, [dispatch, defaultValues])

	useEffect(() => {
		dispatch(
			setSentryData({
				name,
				sentry_type,
				status_color,
				direction,
			})
		)
	}, [dispatch, name, sentry_type, status_color, direction])

	// Handle change lat/lng with drag-drop
	const sentryData = useAppSelector(selectSentryData)
	const handleCoordinatesChange = (
		type: 'latitude' | 'longitude',
		value: string
	) => {
		dispatch(
			setSentryData({
				[type]: value,
			})
		)
	}

	useEffect(() => {
		if (sentryData) {
			setValue('latitude', Number(sentryData.latitude), { shouldDirty: true })
			setValue('longitude', Number(sentryData.longitude), { shouldDirty: true })
		}
	}, [sentryData, setValue, defaultValues])

	// RTK
	const { locatorOptions, locatorOptionsReady } =
		useGetSiteInstallationLocatorsQuery(
			{
				siteId: Number(siteId),
				installationId: Number(installationId),
			},
			{
				skip: !installationId || !siteId,
				selectFromResult: ({ isSuccess, data }) => ({
					...selectSiteInstallationLocatorSensorOptions(data),
					locatorOptionsReady: isSuccess,
				}),
			}
		)

	const locatorSelectedValue = useMemo(() => {
		return locatorOptions.find(
			(locator) =>
				locator?.value?.id === locatable_id &&
				locator?.value?.type === locatable_type
		)
	}, [locatable_id, locatable_type, locatorOptions])

	// Handle disabling statements
	const isTrackLengthDisabled = !tracked

	// Handlers and listeners
	const handleCancel = () => navigate(installationsRoute)

	const handleLocatorChange = (
		option: SingleValue<{ label: string; value: SiteInstallationLocator }>
	) => {
		if (option) {
			setValue('locatable_type', option?.value?.type, { shouldDirty: true })
			setValue('locatable_id', option?.value?.id, { shouldDirty: true })
		}
	}
	const handleSave = async (payload: Schema | any) => {
		try {
			if (isEditForm) {
				await updateInstallation({
					...payload,
					id: Number(installationId),
					siteId: Number(siteId),
				}).unwrap()
			} else {
				await createInstallation({
					...payload,
					siteId: Number(siteId),
				}).unwrap()
			}
			navigate(installationsRoute)
		} catch (errors: unknown) {
			// Surface server-side validation errors to react-hook-form
			for (const field in errors as { [name in keyof Schema]: string }) {
				setError(field as keyof Schema, {
					type: 'custom',
					message: (errors as { [name in keyof Schema]: string })[
						field as keyof Schema
					] as string,
				})
			}
		}
	}

	const handleDelete = async () => {
		try {
			await deleteInstallation({
				siteId: Number(siteId),
				id: Number(installationId),
			}).unwrap()
			navigate(installationsRoute)
		} catch (e) {
			console.error('Error delete installation', e)
		}
	}

	return (
		<FormProvider {...methods}>
			<form
				onSubmit={handleSubmit(handleSave)}
				style={{ display: 'flex', flexDirection: 'column' }}
				key={installationId}
			>
				<Field.TextInput
					title={t('api.name')}
					register={register('name')}
					error={errors?.name?.message}
					testId='installation-name'
				/>
				<Field.Select
					title={t('installationType')}
					register={register('sentry_type')}
					defaultValue={defaultValues?.sentry_type}
					options={t('api.sentry_type', { returnObjects: true })}
					error={errors?.sentry_type?.message}
				/>
				<Field.Divider title={t('headers.locationParameters')} />
				<Grid
					gridTemplateColumns={'repeat(3, 1fr)'}
					gridTemplateRows={'auto auto'}
					columnGap={4}
				>
					<GridItem>
						<Field.LatLongInput
							title={t('api.latitude')}
							type='latitude'
							defaultValue={latitude}
							value={sentryData?.latitude}
							error={errors?.latitude?.message}
							testId='installation-latitude'
							onChange={(value) => handleCoordinatesChange('latitude', value)}
							disabled={acceptLocationUpdates}
						/>
					</GridItem>
					<GridItem>
						<Field.LatLongInput
							title={t('api.longitude')}
							type='longitude'
							defaultValue={longitude}
							value={sentryData?.longitude}
							error={errors?.longitude?.message}
							testId='installation-longitude'
							onChange={(value) => handleCoordinatesChange('longitude', value)}
							disabled={acceptLocationUpdates}
						/>
					</GridItem>
					<GridItem>
						<Field.NumberInput
							title={t('api.altitude')}
							register={register('altitude', {
								valueAsNumber: true,
							})}
							error={errors?.altitude?.message}
							disabled={acceptLocationUpdates}
							tooltip={!acceptLocationUpdates ? t('tooltips.altitude') : ''}
							testId='installation-altitude'
						/>
					</GridItem>
					<GridItem gridColumn={'1 / -1'}>
						<Field.UnitsSlider
							units='deg'
							title={t('api.direction')}
							min={0}
							max={359.99}
							step={0.1}
							register={register('direction', { valueAsNumber: true })}
							error={errors?.direction?.message}
							disabled={acceptLocationUpdates}
							tooltip={!acceptLocationUpdates ? t('tooltips.direction') : ''}
							testId='installation-direction'
						/>
					</GridItem>
				</Grid>
				{isEditForm && (
					<>
						<Flex mb={2}>
							<Field.Switch
								testId='accept-location-updates'
								title={t('api.accept_location_updates')}
								tooltip={t('tooltips.accept_location_updates')}
								register={register('accept_location_updates')}
								error={errors?.accept_location_updates?.message}
								disabled={!(locatorOptionsReady && locatorOptions.length > 0)}
							/>
						</Flex>
						{locatorOptionsReady && locatorOptions.length > 0 && (
							<>
								<UiSelect
									id='installation_device_locator'
									placeholder={t('locator')}
									options={locatorOptions}
									onChange={handleLocatorChange}
									value={locatorSelectedValue}
									isDisabled={!acceptLocationUpdates}
								/>
								<FormControl mt={0} isInvalid={!!errors?.locatable_id?.message}>
									<FormErrorMessage>
										{errors?.locatable_id?.message}
									</FormErrorMessage>
								</FormControl>
							</>
						)}
						<Field.Divider title={t('headers.mapParameters')} />
						<Field.Switch
							title={t('api.tracked')}
							register={register('tracked')}
							error={errors?.tracked?.message}
							disabled={!acceptLocationUpdates}
							tooltip={acceptLocationUpdates ? t('tooltips.tracked') : ''}
						/>
						<Field.NumberInput
							title={t('api.track_length')}
							tooltip={!isTrackLengthDisabled ? t('tooltips.track_length') : ''}
							min={0}
							register={register('track_length', {
								valueAsNumber: true,
							})}
							error={errors?.track_length?.message}
							disabled={isTrackLengthDisabled}
						/>
						<Field.Switch
							title={t('api.anchored')}
							tooltip={acceptLocationUpdates ? t('tooltips.anchored') : ''}
							register={register('anchored')}
							error={errors?.anchored?.message}
							disabled={!acceptLocationUpdates}
						/>
						{/* BUTTONS */}
						<FormDeleteButton
							handleDelete={handleDelete}
							name={`${t('modal.installation')} ${name}`}
							headerText={t('buttons.delete')}
							fromText={t('modal.deleteFrom')}
							label={t('buttons.delete')}
							testId='delete-installation'
						/>
					</>
				)}
				<FormButtons
					isSubmitting={isSubmitting}
					isDirty={isDirty}
					handleCancel={handleCancel}
				/>
			</form>
		</FormProvider>
	)
}

export default InstallationForm
