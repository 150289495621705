import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Box, Tabs, TabList, TabPanels, TabPanel, Tab } from '@chakra-ui/react'
import { Page } from '@Components/Layouts'

import AlertSettingsForm from '@Forms/AlertSettingsForm/AlertSettingsForm'
import AlertZonesWarning from './AlertZonesWarning'
import AlertRecipients from './AlertRecipients/AlertRecipients'
import {
	selectNotificationsEnabled,
	useGetSystemInfoQuery,
} from '@Store/system/systemApi'

const AlertSettings = () => {
	const { siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)
	// Translations
	const { t } = useTranslation('pages', {
		keyPrefix: 'alertSettings.alertSettings',
	})

	const { notificationsEnabled, notificationsEnabledLoaded } =
		useGetSystemInfoQuery(undefined, {
			selectFromResult: ({ data, isSuccess }) => ({
				notificationsEnabledLoaded: isSuccess,
				notificationsEnabled: selectNotificationsEnabled(data),
			}),
		})

	return (
		<Page heading={t('alertSettingsTitle')}>
			<Tabs mt={6} isLazy>
				<TabList>
					<Tab>{t('tabs.alertSettings')}</Tab>
					{notificationsEnabled && notificationsEnabledLoaded && (
						<Tab>{t('tabs.alertRecipients')}</Tab>
					)}
				</TabList>
				<TabPanels>
					<TabPanel>
						<Box w='705px'>
							<AlertZonesWarning siteId={siteId} />
							<AlertSettingsForm siteId={siteId} />
						</Box>
					</TabPanel>
					{notificationsEnabled && notificationsEnabledLoaded && (
						<TabPanel>
							<AlertRecipients siteId={siteId} />
						</TabPanel>
					)}
				</TabPanels>
			</Tabs>
		</Page>
	)
}

export default AlertSettings
