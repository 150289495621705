import { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
	useReactTable,
	getCoreRowModel,
	type PaginationState,
	createColumnHelper,
} from '@tanstack/react-table'
import { Flex, Box, useDisclosure } from '@chakra-ui/react'
import { MdSearch } from 'react-icons/md'

import { useGetSiteLogsQuery } from '@Store/analytics/analyticsApi'

import IconButton from '@UI/IconButton/IconButton'
import FetchError from '@UI/FetchError/FetchError'
import TanStackTable from '@UI/TanStackTable/Table'
import Pagination from '@UI/TanStackTable/Pagination'
import Skeleton from '@UI/Skeleton/Skeleton'

import type { SiteLog } from '@Store/types'

import ChangesModal from './ChangesModal'

import { formatDateTz } from '@Utils/dates'
import { capitalize } from '@Utils/stringUtils'

type SiteLogsTableProps = {
	startTime: string
	endTime: string
	siteTimeZone: string
	siteId: number
	testId?: string
}

const columnHelper = createColumnHelper<SiteLog>()

const SiteLogsTable = ({
	startTime,
	endTime,
	siteTimeZone,
	siteId,
	testId,
}: SiteLogsTableProps) => {
	const { t } = useTranslation('pages', {
		keyPrefix: 'analytics.siteLogs',
	})

	const [siteLog, setSiteLog] = useState<SiteLog>()

	const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 10,
	})

	const pagination = useMemo(
		() => ({
			pageIndex,
			pageSize,
		}),
		[pageIndex, pageSize]
	)

	const {
		isSuccess,
		isError,
		isFetching,
		isLoading,
		refetch,
		siteLogs,
		totalCount,
	} = useGetSiteLogsQuery(
		{
			siteId,
			format: 'table',
			startTime: startTime,
			endTime: endTime,
			page: pageIndex,
			perPage: pageSize,
		},
		{
			selectFromResult: ({
				isSuccess,
				isError,
				isFetching,
				isLoading,
				data,
			}) => ({
				isSuccess,
				isError,
				isFetching,
				isLoading,
				totalCount: data?.meta.total,
				siteLogs: data?.records,
			}),
		}
	)

	const { isOpen, onOpen, onClose } = useDisclosure()

	const handleOpenModal = (siteLog: SiteLog) => {
		setSiteLog(siteLog)
		onOpen()
	}

	const columns = [
		columnHelper.accessor('id', {
			id: 'id',
			header: t('table.id'),
		}),
		columnHelper.accessor('created_at', {
			id: 'created_at',
			header: t('table.timestamp'),
			cell: (info) => formatDateTz(info.getValue(), siteTimeZone),
		}),
		columnHelper.accessor('whodunnit', {
			id: 'whodunnit',
			header: t('table.user'),
			cell: (info) => capitalize(info.getValue()),
		}),
		columnHelper.accessor('event', {
			id: 'event',
			header: t('table.action'),
			cell: (info) => capitalize(info.getValue()),
		}),
		columnHelper.accessor('object', {
			id: 'object',
			header: t('table.serialNumber'),
			cell: (info) => info.getValue().serial_number,
		}),
		columnHelper.accessor('installation', {
			id: 'installation',
			header: t('table.installation'),
		}),
		columnHelper.accessor('description', {
			id: 'description',
			header: t('table.description'),
		}),
		columnHelper.display({
			id: 'details',
			header: t('table.details'),
			cell: ({ row }) => (
				<Box paddingInlineStart={2}>
					<IconButton
						icon={<MdSearch />}
						testId='details-icon'
						aria-label='details-icon'
						onClick={() => handleOpenModal(row.original)}
						isDisabled={row.original.event === 'delete'}
					/>
				</Box>
			),
		}),
	]

	const pageCount = totalCount ? Math.ceil(totalCount / pageSize) : 0

	const table = useReactTable({
		columns,
		data: siteLogs as SiteLog[],
		getCoreRowModel: getCoreRowModel(),
		pageCount: pageCount ?? -1,
		state: {
			pagination,
		},
		onPaginationChange: setPagination,
		manualPagination: true,
	})

	return (
		<Skeleton isLoaded={!isLoading && !isFetching}>
			{isSuccess && (
				<>
					<Flex direction='column' gap='8px' data-testid={testId}>
						<TanStackTable table={table} />
						<Pagination table={table} totalCount={totalCount ?? 0} />
					</Flex>
					{siteLog && (
						<ChangesModal
							isOpen={isOpen}
							onClose={onClose}
							objectChanges={siteLog.object_changes}
						/>
					)}
				</>
			)}
			{isError && (
				<FetchError refetch={refetch} errorMessage={t('fetchError')} />
			)}
		</Skeleton>
	)
}

export default SiteLogsTable
