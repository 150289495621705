import { cardAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
	createMultiStyleConfigHelpers(cardAnatomy.keys)

const baseStyle = definePartsStyle({
	container: {
		backgroundColor: 'unset',
		borderRadius: '0',
		mb: 6,
		boxShadow: 'unset',
		w: '100%',
	},
	header: {
		padding: 'unset',
		color: '#c0c0c0',
		fontWeight: 600,
		pb: 2,
		button: {
			marginInline: 4,
		},
	},
	body: {
		padding: 'unset',
	},
	footer: {
		paddingTop: '2px',
	},
})

const variants = {
	site: definePartsStyle({
		container: {},
		header: {
			textTransform: 'uppercase',
		},
	}),
	installation: definePartsStyle({
		container: {},
		header: {
			bgColor: '#303030',
			paddingInlineStart: 2,
			paddingBlockStart: 4,
		},
		body: {
			bgColor: '#303030',
			button: {
				marginInline: 0,
			},
		},
		footer: {
			padding: 'unset',
			paddingBlockStart: 4,
		},
	}),
	devices: definePartsStyle({
		container: {
			//
		},
		header: {
			display: 'flex',
			justifyContent: 'space-between',
		},
		body: {
			paddingInlineStart: 5,
		},
	}),
}

export const Card = defineMultiStyleConfig({ baseStyle, variants })
