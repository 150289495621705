import { createSelector } from '@reduxjs/toolkit'

import type {
	DetectionContribution,
	DetectionContributionSensor,
	EventSummary,
} from '../types'

import type {
	SimplifiedSite,
	SimplifiedSiteInstallation,
	SimplifiedRfSensor,
	SimplifiedRadar,
	SimplifiedCamera,
} from '@Store/types/simplifiedSite'

// TODO
// Strip out SimplifiedStuff and
//  just use Partial<Site> Partial<Radar> etc ?
const emptySiteResult: SimplifiedSite[] = []
const emptyInstallationsResult: SimplifiedSiteInstallation[] = []
const emptyRfSensorsResult: SimplifiedRfSensor[] = []
const emptyRadarsResult: SimplifiedRadar[] = []
const emptyCamerasResult: SimplifiedCamera[] = []
const emptyDetectionsResult: EventSummary['tracks'] = []

const selectEventSite = createSelector(
	(data?: EventSummary) => data,
	(data) => (data?.site as SimplifiedSite) ?? emptySiteResult
)

const selectEventSiteInstallations = createSelector(
	selectEventSite,
	(data) => data?.sentries ?? emptyInstallationsResult
)

export const selectEventSiteAlwaysShowRf = createSelector(
	selectEventSite,
	(site) => (site?.always_show_rf_beam === true ? true : null)
)

export const selectEventSiteTimeZone = createSelector(
	selectEventSite,
	(site) => site?.timezone
)

export const selectEventRfSensors = createSelector(
	selectEventSiteInstallations,
	(installations) =>
		installations.flatMap(
			(installation) => installation.rf_sensors || emptyRfSensorsResult
		)
)

export const selectEventRadarSensors = createSelector(
	selectEventSiteInstallations,
	(installations) =>
		installations.flatMap(
			(installation) => installation.radars || emptyRadarsResult
		)
)

const selectEventCameraSensors = createSelector(
	selectEventSiteInstallations,
	(installations) =>
		installations.flatMap(
			(installation) => installation.cameras || emptyCamerasResult
		)
)

const selectEventDetections = createSelector(
	(data?: EventSummary) => data,
	(data) => data?.tracks ?? emptyDetectionsResult
)

const selectEventDetectionContributions = createSelector(
	selectEventDetections,
	(detections) =>
		detections.flatMap((detection) => detection.detection_contributions)
)

const selectEventUniqueDetectionContributions = createSelector(
	selectEventDetectionContributions,
	(detectionContributions) =>
		detectionContributions.reduce(
			(uniqueContributions, contribution) => {
				if (
					!uniqueContributions.some(
						(c) =>
							c.sensor_id === contribution.sensor_id &&
							c.sensor_type === contribution.sensor_type
					)
				)
					uniqueContributions.push(contribution)
				return uniqueContributions
			},
			[] as Array<Partial<DetectionContribution>>
		)
)

// Gets an array of sensors that contributed to an event/set of detections
export const selectEventContributionSensors = createSelector(
	[
		selectEventUniqueDetectionContributions,
		selectEventRfSensors,
		selectEventRadarSensors,
		selectEventCameraSensors,
	],
	(contributions, allRfSensors, allRadars, allCameras) =>
		contributions.map((contribution) => {
			let sensorName: DetectionContributionSensor['sensorName'] | undefined =
				undefined
			switch (contribution.sensor_type) {
				case 'rfSensor':
				case 'dsx':
					sensorName = allRfSensors.find(
						(s) => s.id === contribution.sensor_id
					)?.name
					break
				case 'radar':
					sensorName = allRadars.find(
						(s) => s.id === contribution.sensor_id
					)?.name
					break
				case 'camera':
					sensorName = allCameras.find(
						(s) => s.id === contribution.sensor_id
					)?.name
					break
			}
			return {
				sensorId: contribution.sensor_id,
				sensorType: contribution.sensor_type,
				sensorName: sensorName,
			}
		}) as DetectionContributionSensor[]
)
