import type { LatLngExpression } from 'leaflet'

import CompassRose from './CompassRose'
import DirectionPointer from './DirectionPointer'

type SiteMarkerProps = {
	position: LatLngExpression
	bearing?: number
}

const CompassMarker = ({ position, bearing }: SiteMarkerProps) => {
	return (
		<>
			{/* milliradian compass to be provided later */}
			<CompassRose position={position} />
			<DirectionPointer position={position} bearing={bearing} />
		</>
	)
}

export default CompassMarker
