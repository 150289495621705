// Alias
import { Flex, Text } from '@chakra-ui/react'
import Modal from '@UI/Modals/Modal'
import { useMemo } from 'react'

type AlertContentModalProps = {
	isOpen: boolean
	onClose: () => void
	content: string
}

const AlertContentModal = ({
	isOpen,
	onClose,
	content,
}: AlertContentModalProps) => {
	const contentParsed = useMemo<string[]>(
		() => content?.replaceAll(/[\n]+/gim, '\n').split('\n'),
		[content]
	)

	return (
		<Modal
			headerText='Alert Content'
			isOpen={isOpen}
			onClose={onClose}
			size='3xl'
		>
			<Flex padding='16px' flexDirection='column' whiteSpace='pre-line'>
				{contentParsed.map((content, i) => {
					const firstLine = contentParsed.length > 1 && i === 0

					return (
						<Text
							key={i}
							color={firstLine ? 'white' : undefined}
							marginBottom={firstLine ? '16px' : '0px'}
							fontWeight={firstLine ? 'bold' : 'normal'}
						>
							{content}
						</Text>
					)
				})}
			</Flex>
		</Modal>
	)
}

export default AlertContentModal
