import type { ChangeEvent } from 'react'
import { useGetCamerasListQuery } from '@Store/cameras/camerasApi'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@Store/index'
import { setActiveCameraId } from '@Store/ui/uiSlice'

const CameraSwitcher = () => {
	const { siteId: siteIdParam } = useParams()
	const activeCameraId = useAppSelector((state) => state.ui.activeCameraId)
	const dispatch = useAppDispatch()
	const {
		// isFetching,
		// isLoading,
		// isError,
		isSuccess,
		// refetch,
		data: camerasList,
	} = useGetCamerasListQuery(Number(siteIdParam), {
		refetchOnMountOrArgChange: true,
	})
	const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
		const cameraId = Number(e.target.value)
		dispatch(setActiveCameraId(cameraId))
	}
	return (
		isSuccess &&
		camerasList.length > 1 && (
			<select
				onChange={handleChange}
				value={activeCameraId?.toString()}
				style={{ marginInlineStart: 4 }}
			>
				{camerasList.map((camera, index) => (
					<option key={index} value={camera.id}>
						{camera.name}
					</option>
				))}
			</select>
		)
	)
}

export default CameraSwitcher
