import { z } from 'zod'
import globalSchema from '@/components/Forms/BaseForm/BaseForm.schema'
// Translations
import i18n from '@/i18n'
const t = (key: string, value: { [key: string]: string }) =>
	i18n.t(`forms:siteForm.validation.${key}`, value) as string

const siteFormFields = z.object({
	// Copied from global schema
	name: globalSchema.shape.name,
	timezone: globalSchema.shape.timezone,
	zoom_level: globalSchema.shape.zoom_level,
	latitude: globalSchema.shape.latitude,
	longitude: globalSchema.shape.longitude,
	map_center_latitude: globalSchema.shape.latitude.nullable(),
	map_center_longitude: globalSchema.shape.longitude.nullable(),
	// Site schema
	mode: z.enum(['operational', 'monitor', 'calibration'], {
		errorMap: (issue, ctx) => ({ message: 'Please select site' }),
	}),
	auto_jam: z.boolean().optional(),
	accept_location_updates: z.boolean().optional(),
	locator_id: z.number().optional(),
	minimum_display_probability: z.number().nonnegative().max(1).optional(),
	zone_trigger_probability: z.number().nonnegative().max(0.8).optional(),
	show_whitelisted_detections: z.boolean().optional(),
	always_show_rf_beam: z.boolean().optional(),
	system_warning_enabled: z.boolean().optional(),
	speedleader_estimation_time: z
		.number()
		.nonnegative()
		.max(30, 'Speedleader must be 30s or less'),
	auto_disruption_probability: z.number().nonnegative().max(100),
	auto_disruption_detection_count: z
		.number()
		.nonnegative()
		.min(1)
		.max(2_147_483_647),
	auto_jam_interval: z.number().min(1).max(300),
	sensitivity: z.enum(['normal', 'high']),
	max_location_variance: z.number().min(1).max(20000),
	round_frequency_band: z.enum(['none', 'controller', 'all']),
	prob_uav_filter: z.number().nonnegative().max(100).optional(),
	prob_uav_filter_range: z.number().min(20).max(10000),
	fuse_controller_detections: z.boolean().optional(),
	client_id: z.number().optional(),
	drone_locator_detection_timeout: z.number().min(1).max(150),
	threat_analysis_configuration: z
		.object({
			classify_by_class: z.object({
				active: z.boolean().optional(),
				size: z.boolean().optional(),
				unknown: z.boolean().optional(),
				priority: z.string().default('low'),
			}),
			classify_by_protected_zones: z.object({
				active: z.boolean().optional(),
				priority: z.string().default('low'),
			}),
			classify_by_communication: z.object({
				active: z.boolean().optional(),
				priority: z.string().default('low'),
			}),
			classify_by_geo_location: z.object({
				active: z.boolean().optional(),
				priority: z.string().default('low'),
			}),
			classify_by_legal_limits: z.object({
				active: z.boolean().optional(),
				priority: z.string().default('low'),
				speed_min: z.number().min(0).max(200).optional(),
				speed_max: z.number().min(0).max(200).optional(),
				agl_min: z.number().min(0).max(200).optional(),
				agl_max: z.number().min(0).max(200).optional(),
			}),
			classify_by_zone_origin: z.object({
				active: z.boolean().optional(),
				priority: z.string().default('low'),
			}),
			classify_by_time: z.object({
				active: z.boolean().optional(),
				priority: z.string().default('low'),
				start_time: z.number().min(0).max(24).optional(),
				duration: z.number().min(0).max(1440).optional(),
			}),
		})
		.optional(),
	//
	// TODO: we move this to user preferences?
	// defaultSite: z.boolean().optional(),
	//
	// TODO: these actually belong to Alerts?
	// alert_suppression_time_window: 600,
	// trigger_sensors_offline_alerts: boolean,
	// zone_trigger_probability: 0.6,
	// zone_trigger_detection_count: 5,
})

const siteFormSchema = siteFormFields.superRefine((data, ctx) => {
	if (data.zone_trigger_probability && data.minimum_display_probability) {
		if (data.zone_trigger_probability < data.minimum_display_probability) {
			const probability = `${data.zone_trigger_probability * 100}`
			ctx.addIssue({
				path: ['minimum_display_probability'],
				message: t('minimum_display_probability', { probability }),
				code: z.ZodIssueCode.custom,
			})
		}
	}
})

const defaultValuesSchema = siteFormSchema
export type SiteDefaultValues = z.infer<typeof defaultValuesSchema>

export const ADD_SITE_DEFAULT_VALUES: SiteDefaultValues = {
	accept_location_updates: false,
	always_show_rf_beam: false,
	auto_disruption_detection_count: 5,
	auto_disruption_probability: 0,
	auto_jam: false,
	auto_jam_interval: 60,
	fuse_controller_detections: false,
	latitude: -33.8715,
	locator_id: 0,
	longitude: 151.2006,
	map_center_latitude: null,
	map_center_longitude: null,
	max_location_variance: 300,
	minimum_display_probability: 0,
	zone_trigger_probability: 0.6,
	mode: 'operational',
	name: '',
	prob_uav_filter: 20,
	prob_uav_filter_range: 350,
	// prob_uav_threshold: 0,
	round_frequency_band: 'controller',
	sensitivity: 'normal',
	show_whitelisted_detections: false,
	speedleader_estimation_time: 10,
	system_warning_enabled: true,
	timezone: 'Australia/Sydney',
	zoom_level: 13,
	drone_locator_detection_timeout: 70,
	threat_analysis_configuration: {
		classify_by_class: { active: true, priority: 'high' },
		classify_by_communication: { active: false, priority: 'low' },
		classify_by_geo_location: { active: false, priority: 'low' },
		classify_by_legal_limits: { active: false, priority: 'low' },
		classify_by_protected_zones: { active: true, priority: 'high' },
		classify_by_time: { active: false, priority: 'low' },
		classify_by_zone_origin: { active: false, priority: 'low' },
	},
}

export default siteFormSchema
