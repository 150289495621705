import { useMap } from 'react-leaflet'
import { useMemo } from 'react'
import type { LatLngExpression, LatLngTuple } from 'leaflet'
import LatLonSpherical from 'geodesy/latlon-spherical'

const useDistanceBetweenPoints = (
	position1: LatLngExpression,
	position2: LatLngExpression,
	distanceInPixelsX: number, // desired distance in pixels X axis between two coordinates
	distanceInPixelsY: number // desired distance in pixels Y axis between two coordinates
) => {
	const map = useMap()
	const zoom = map.getZoom()

	// Coordinates positions
	const position1InPixels = map.latLngToContainerPoint(position1)
	const position2InPixels = map.latLngToContainerPoint(position2)

	const isDistanceInSquare =
		Math.abs(position1InPixels.x - position2InPixels.x) < distanceInPixelsX &&
		Math.abs(position1InPixels.y - position2InPixels.y) < distanceInPixelsY

	const isDistanceLessThenPixelsBetween = zoom ? isDistanceInSquare : false

	const coordinatesBetween = useMemo(() => {
		const point1 = new LatLonSpherical(
			(position1 as LatLngTuple)[0] as number,
			(position1 as LatLngTuple)[1] as number
		)
		const point2 = new LatLonSpherical(
			(position2 as LatLngTuple)[0] as number,
			(position2 as LatLngTuple)[1] as number
		)
		const midpoint = point1.midpointTo(point2)
		if (zoom) return [midpoint.latitude, midpoint.longitude] as LatLngTuple
	}, [zoom, position1, position2])

	return { isDistanceLessThenPixelsBetween, coordinatesBetween }
}

export default useDistanceBetweenPoints
