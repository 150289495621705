import { z } from 'zod'
// Translations
import i18n from '@/i18n'
const t = (key: string) => i18n.t(`forms:systemSettingsForm.validation.${key}`)

const systemSettingsFormFields = z.object({
	static_ip: z.string().ip({ version: 'v4', message: t('ip') }),
	static_subnet_mask: z.string().ip({ version: 'v4', message: t('ip') }),
	ntp_url: z.string().optional(),
	logo: z.string(),
})

export const systemSettingsFormSchema = systemSettingsFormFields
export type SystemSettingsSchema = z.infer<typeof systemSettingsFormSchema>
