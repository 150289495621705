// React
import { useTranslation } from 'react-i18next'
import { nanoid } from '@reduxjs/toolkit'
// Chakra
import { Box, FormControl, Flex } from '@chakra-ui/react'
// Components
import RfFiltersOverviewUnitsMarkers from './RfFiltersOverviewUnitsMarkers'
import RfFiltersOverviewSlider from './RfFiltersOverviewSlider'
// Types
import type { BearingValuesType } from './RfFiltersOverviewBearings'
import { useAppDispatch, useAppSelector } from '@/store'
import {
	selectSelectedRfFiltersIds,
	updateSelectedSensorFiltersIds,
} from '@Store/ui/uiSlice'

const RfFiltersOverviewBearingRanges = ({
	ranges,
	isDsx,
	...rest
}: {
	ranges: BearingValuesType[]
	isDsx?: boolean
}) => {
	// Translations
	const { t } = useTranslation('panels', { keyPrefix: 'rfFilters' })

	const min = isDsx ? 0 : -70
	const max = isDsx ? 360 : 70

	const formatBearing = (value: number) =>
		value ? Number(value.toFixed(2)) : 0

	const formatDefaultValue = (bearing: number, bearingTolerance: number) => {
		const formattedBearing = formatBearing(bearing)
		const formattedBearingTolerance = formatBearing(bearingTolerance)

		return [
			formattedBearing - formattedBearingTolerance,
			formattedBearing + formattedBearingTolerance,
		]
	}

	const selectedFilters = useAppSelector(selectSelectedRfFiltersIds)
	const checkIsFilterSelected = (filterId: number) =>
		selectedFilters.some((id) => id === filterId)

	// Select/Unselect filters
	const dispatch = useAppDispatch()
	const handleClick = (id: number) =>
		dispatch(updateSelectedSensorFiltersIds(id))

	const populateTooltip = (isActive: boolean, filterName: string) => {
		return isActive
			? `${t('tooltips.unselect')} ${filterName}`
			: `${t('tooltips.select')} ${filterName}`
	}

	return (
		<FormControl>
			<Flex
				direction='column'
				alignItems='center'
				position='relative'
				pointerEvents='none'
			>
				<Box w='calc(100% - 40px)'>
					<Box
						width='100%'
						position='relative'
						h='2px'
						bgColor='whiteAlpha.300'
						top='-6px'
						left='0'
					/>
					<Box width='100%' position='relative'>
						{ranges?.map((range) => (
							<RfFiltersOverviewSlider
								key={nanoid()}
								min={min}
								max={max}
								range={range}
								tooltip={populateTooltip(
									checkIsFilterSelected(range?.id),
									range?.name
								)}
								isActive={checkIsFilterSelected(range?.id)}
								onClick={() => handleClick(range?.id)}
								defaultValue={formatDefaultValue(
									range.bearing,
									range.bearing_tolerance
								)}
								{...rest}
							/>
						))}
					</Box>
				</Box>
				<RfFiltersOverviewUnitsMarkers min={min} max={max} />
			</Flex>
		</FormControl>
	)
}

export default RfFiltersOverviewBearingRanges
