import type { LatLngExpression, LatLngLiteral } from 'leaflet'

import Marker from '../index'
import SiteMarkerIcon from '@Assets/icons/markers/site-center.svg'

type SiteMarkerProps = {
	position: LatLngExpression
	onDragEnd?: (position: LatLngLiteral) => void
	draggable?: boolean
}

const ICON_SIZE = [30, 30]
const ICON_ANCHOR = [15, 15]

const SiteMarker = ({ position, onDragEnd, draggable }: SiteMarkerProps) => {
	return (
		<Marker
			position={position}
			onDragEnd={onDragEnd}
			icon={<SiteMarkerIcon />}
			iconSize={ICON_SIZE}
			iconAnchor={ICON_ANCHOR}
			draggable={draggable}
			alt='Site Marker'
		/>
	)
}

export default SiteMarker
