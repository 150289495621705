import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
	Box,
	Flex,
	Icon,
	Spinner,
	Table,
	Tbody,
	Tr,
	Td,
	Text,
} from '@chakra-ui/react'
import { MdHelpOutline, MdCheckCircle } from 'react-icons/md'

import CustomSvgLayer from '@UI/Charts/CustomSvgLayer'
import ScatterPlot from '@UI/Charts/ScatterPlot'
import Tooltip from '@UI/Tooltip/Tooltip'

import { useAppDispatch } from '@Store/index'
import { setActiveTargetId } from '@Store/ui/uiSlice'

import type { RadarDetectionMap } from '../../types'
import type { Feature, Point, Properties } from '@turf/turf'

import { getDistanceDifference, getHasNotMoved } from '@Utils/cameraCalibration'
import { useInterval } from '@Hooks/useInterval'

const BottomCustomLayer = CustomSvgLayer({
	startX: 100,
	endX: 150,
	startY: 2,
	endY: 0,
	color: 'var(--c2-colors-charts-customAmber)',
})

const TopCustomLayer = CustomSvgLayer({
	startX: 100,
	endX: 150,
	startY: 25,
	endY: 23,
	color: 'var(--c2-colors-charts-customGreen)',
})

const SideCustomLayer = CustomSvgLayer({
	startX: 146,
	endX: 150,
	startY: 25,
	endY: 0,
	color: 'var(--c2-colors-charts-customGreen)',
})

const RADAR_MIN_DETECTION_COUNT = 30
const AVG_COUNT = 30
const TARGET_ALTITUDE = 25
const DISTANCE_TOLERANCE = 12

type RadarReadingProps = {
	radarDetections: RadarDetectionMap
	sensorCoordinates: Feature<Point, Properties>
}

const RadarReading = ({
	radarDetections,
	sensorCoordinates,
}: RadarReadingProps) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'siteInstallations.cameraCalibration',
	})

	const [isCorrectAltitude, setIsCorrectAltitude] = useState(false)
	const [isCorrectDistance, setIsCorrectDistance] = useState(false)

	const dispatch = useAppDispatch()

	useInterval(() => {
		// Determine if and which detection is within the parameters (150m distance, 25m height)
		const { target_id } =
			Object.values(radarDetections).find((detection) => {
				const { count, coordinateHistory, altitudeHistory } = detection
				// Minimum count (not a short-lived detection)
				if (count < RADAR_MIN_DETECTION_COUNT) return false
				// Maximum location variance (detection does not move)
				if (coordinateHistory[AVG_COUNT] === undefined) return false

				const hasNotMoved = getHasNotMoved(coordinateHistory, altitudeHistory)

				setIsCorrectAltitude(
					Math.abs(altitudeHistory[0] - TARGET_ALTITUDE) < DISTANCE_TOLERANCE
				)
				setIsCorrectDistance(
					getDistanceDifference(sensorCoordinates, coordinateHistory[0]) <
						DISTANCE_TOLERANCE
				)

				const rightSpot = isCorrectAltitude && isCorrectDistance

				return hasNotMoved && rightSpot
			}) || {}

		dispatch(setActiveTargetId(target_id))
	}, 2000)

	return (
		<Flex direction='column'>
			<Table>
				<Tbody>
					<Tr h='48px'>
						<Td w='142px' color='label_color' fontWeight={500}>
							{t('distance')}
						</Td>
						<Td>150</Td>
						<Td isNumeric>
							{isCorrectDistance ? (
								<Icon as={MdCheckCircle} color='status.green' w={5} h={5} />
							) : (
								<Spinner size='sm' color='disabled' />
							)}
						</Td>
					</Tr>
					<Tr h='48px'>
						<Td color='label_color' fontWeight={500}>
							{t('height')}
						</Td>
						<Td>25</Td>
						<Td isNumeric>
							{isCorrectAltitude ? (
								<Icon as={MdCheckCircle} color='status.green' w={5} h={5} />
							) : (
								<Spinner size='sm' color='disabled' />
							)}
						</Td>
					</Tr>
				</Tbody>
			</Table>

			<Tooltip label={t('tooltips.fly')} placement='bottom-end'>
				<Flex gap='4px' alignItems='center' justifyContent='flex-end'>
					<Icon as={MdHelpOutline} color='primary' />
					<Text as='u' fontSize='sm' color='primary'>
						{t('unableToFly')}
					</Text>
				</Flex>
			</Tooltip>

			<Box h='310px' w='400px'>
				<ScatterPlot
					data={[]}
					yLabel={t('altitude')}
					margin={{ top: 20, right: 40, bottom: 55, left: 50 }}
					layers={[
						'grid',
						'axes',
						'mesh',
						BottomCustomLayer,
						TopCustomLayer,
						SideCustomLayer,
						'nodes',
					]}
					xScale={{ type: 'linear', min: 100, max: 150 }}
					yScale={{ type: 'linear', min: 0, max: 25 }}
					xTickValues={[100, 110, 120, 130, 140, 150]}
				/>
			</Box>
		</Flex>
	)
}

export default RadarReading
