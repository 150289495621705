// Base Redux Toolkit Query API
import { baseApi } from '../baseApi'
import { type CoreApiError, formatApiErrors } from '@Store/utils/errorUtils'

// Types
import type { SiteUsers, User } from '@Store/types'
import { updateUserSettings } from './userSlice'
import type { UserEditSchema } from '@Forms/UserForm/UserForm.schema'
import { createSelector } from '@reduxjs/toolkit'

// API types
type GetUserRequest = User['id']
type UpdateUserSettingsRequest = any // TODO
type UpdateUserProfileRequest = any // TODO
type GetUsersListWithDeletedUsersRequest = { user: User | undefined }
type UpdateUserRequest = UserEditSchema & { userId: User['id'] }
type DeleteUserRequest = { userId: User['id'] }

export type GenerateForgotPasswordLinkRequest = {
	email: string
}

export type ResetPasswordRequest = {
	password: string
	password_confirmation: string
	reset_password_token: string
}

export type ResetPasswordViaTokenResponse = {
	message: string
}

export type ResetPasswordResponse = {
	message: string
	password_reset_link: string
}

// Users API endpoints
export const usersApi = baseApi
	.enhanceEndpoints({
		addTagTypes: ['User'],
	})
	.injectEndpoints({
		endpoints: (builder) => ({
			getUsersList: builder.query<Array<User>, void>({
				query: () => ({
					url: '/api/users',
				}),
				providesTags: ['User'],
				keepUnusedDataFor: 0,
				transformResponse: (response: { users: User[] | null }) =>
					(response.users ?? []).sort((a, b) => a.id - b.id),
			}),
			getUsersListWithDeletedUsers: builder.query<
				Array<User>,
				GetUsersListWithDeletedUsersRequest
			>({
				query: ({ user }) => ({
					url:
						user?.role !== 'admin' && user?.client_id
							? `/api/users?client_id=${user?.client_id}&with_deleted=true`
							: '/api/users?with_deleted=true',
				}),
				providesTags: ['User'],
				keepUnusedDataFor: 0,
				transformResponse: (response: { users: User[] | null }) =>
					(response.users ?? []).sort((a, b) => a.id - b.id),
			}),
			getUser: builder.query<User, GetUserRequest>({
				query: (userId) => ({
					url: `/api/users/${userId}`,
					method: 'GET',
				}),
				providesTags: ['User'],
				keepUnusedDataFor: 0,
				transformResponse: (response: { user: User }) => response.user,
			}),
			getUserListSiteAssociation: builder.query<SiteUsers[], GetUserRequest>({
				query: (userId) => ({
					url: `/api/users/${userId}/sites`,
					method: 'GET',
				}),
				providesTags: ['User'],
				keepUnusedDataFor: 0,
				transformResponse: (response: { site_users: SiteUsers[] | null }) =>
					response.site_users ?? [],
			}),
			createUser: builder.mutation<User, Partial<User>>({
				query: (user) => ({
					url: '/api/users',
					method: 'POST',
					body: {
						user: { ...user },
					},
				}),
				transformResponse: (response: { user: User }) => response.user,
				transformErrorResponse: (response: CoreApiError) =>
					formatApiErrors(response),
				invalidatesTags: ['User'],
			}),
			updateUser: builder.mutation<User, UpdateUserRequest>({
				query: ({ userId, ...user }) => ({
					url: `/api/users/${userId}`,
					method: 'PUT',
					body: {
						user,
					},
				}),
				transformResponse: (response: { user: User }) => response.user,
				transformErrorResponse: (response: CoreApiError) =>
					formatApiErrors(response),
				invalidatesTags: ['User'],
			}),
			activateUser: builder.mutation<User, UpdateUserRequest>({
				query: ({ userId, ...user }) => ({
					url: `/api/users/${userId}/restore`,
					method: 'PATCH',
					body: {
						user,
					},
				}),
				transformResponse: (response: { user: User }) => response.user,
				transformErrorResponse: (response: CoreApiError) =>
					formatApiErrors<User>(response),
				invalidatesTags: ['User'],
			}),
			deactivateUser: builder.mutation<{ message: string }, DeleteUserRequest>({
				query: ({ userId }) => ({
					url: `/api/users/${userId}`,
					method: 'DELETE',
				}),
				invalidatesTags: ['User'],
			}),
			deleteUser: builder.mutation<{ message: string }, DeleteUserRequest>({
				query: ({ userId }) => ({
					url: `/api/users/${userId}?force_delete=true`,
					method: 'DELETE',
				}),
				invalidatesTags: ['User'],
			}),
			generateForgotPasswordLink: builder.mutation<
				ResetPasswordViaTokenResponse,
				GenerateForgotPasswordLinkRequest
			>({
				query: (email) => ({
					url: '/api/users/generate_forgot_password_link',
					method: 'POST',
					body: {
						user: {
							...email,
						},
					},
				}),
			}),
			resetPassword: builder.mutation<
				ResetPasswordViaTokenResponse,
				ResetPasswordRequest
			>({
				query: (passwordReset) => ({
					url: '/api/users/password_reset',
					method: 'POST',
					body: {
						user: {
							...passwordReset,
						},
					},
				}),
			}),
			updateUserSettings: builder.mutation<User, UpdateUserSettingsRequest>({
				query: ({ userId, settings }) => ({
					url: `/api/users/${userId}/settings`,
					method: 'PATCH',
					body: { user: { settings } },
				}),
				// https://redux-toolkit.js.org/rtk-query/usage/manual-cache-updates#optimistic-updates
				async onQueryStarted({ settings }, { dispatch, queryFulfilled }) {
					dispatch(updateUserSettings(settings))
				},
				transformResponse: (response: { user: User }) => response.user,
			}),
			updateUserProfile: builder.mutation<User, UpdateUserProfileRequest>({
				query: ({ userId, ...profile }) => ({
					url: `/api/users/${userId}/profile`,
					method: 'PATCH',
					body: { user: { ...profile } },
				}),
				transformResponse: (response: { user: User }) => response.user,
				transformErrorResponse: (response: CoreApiError) => {
					// TODO @#$*&^@#% core api
					if (!response.data.error.match(/[:;]/)) {
						return { current_password: response.data.error }
					}
					return formatApiErrors<User>(response)
				},
				invalidatesTags: ['User'],
			}),
			// TODO: types
			resetUserPassword: builder.mutation<string, number>({
				query: (userId) => ({
					url: `/api/users/${userId}/generate_forgot_password_link`,
					method: 'POST',
				}),
				transformResponse: (response: { password_reset_link: string }) =>
					response.password_reset_link,
			}),
		}),
	})

export const selectUserClientId = createSelector(
	(user?: User) => user,
	(user) => user?.client_id
)

// Exported mutation hooks
export const {
	useGetUsersListQuery,
	useGetUsersListWithDeletedUsersQuery,
	useGetUserQuery,
	useGetUserListSiteAssociationQuery,
	useCreateUserMutation,
	useUpdateUserMutation,
	useActivateUserMutation,
	useDeactivateUserMutation,
	useDeleteUserMutation,
	useGenerateForgotPasswordLinkMutation,
	useResetPasswordMutation,
	useUpdateUserSettingsMutation,
	useUpdateUserProfileMutation,
	useResetUserPasswordMutation,
} = usersApi
