import { useEffect } from 'react'

import { useToast, type ToastPosition } from '@chakra-ui/react'

import { useAppSelector, useAppDispatch } from '@Store/index'
import { selectToasts, deleteToast } from '@Store/ui/uiSlice'

import Toast from './Toast'

const ToastProvider = () => {
	const toast = useToast()

	const dispatch = useAppDispatch()
	const siteToasts = useAppSelector(selectToasts)

	useEffect(() => {
		Object.entries(siteToasts).forEach(([id, siteToast]) => {
			const {
				status,
				variant = 'left-accent',
				duration = null,
				position = 'top-right' as ToastPosition,
				isClosable = true,
				title,
				description,
				error,
			} = siteToast

			const errorTitle =
				(title || error?.data?.error || error?.name) ?? 'An Error Occurred'
			const errorDescription = (description || error?.message) ?? undefined

			const toastOptions = {
				id,
				position,
				variant,
				duration,
				status,
				render: ({ onClose }: { onClose: () => void }) => {
					const handleClose = () => {
						onClose()
						dispatch(deleteToast(id))
					}
					return (
						<Toast
							status={status}
							title={errorTitle}
							description={errorDescription}
							onClose={handleClose}
							variant={variant}
							isClosable={isClosable}
							errorCount={siteToast.count}
						/>
					)
				},
			}

			if (!toast.isActive(id)) {
				toast(toastOptions)
			} else {
				toast.update(id, toastOptions)
			}
		})
	}, [siteToasts, toast, dispatch])

	return <></>
}

export default ToastProvider
