import { useTranslation } from 'react-i18next'

import { useGetSystemInfoQuery, selectVersions } from '@Store/system/systemApi'

import { Text } from '@chakra-ui/react'

const SoftwareVersions = () => {
	const { versions, isSuccess } = useGetSystemInfoQuery(undefined, {
		selectFromResult: ({ data, isSuccess }) => ({
			versions: selectVersions(data),
			isSuccess,
		}),
	})
	const { t } = useTranslation('navigation', { keyPrefix: 'settingsMenu' })
	const showCommitIds = isSuccess && !versions?.app.match(/^\d+\.\d+(\.\d+)*/)

	if (isSuccess) {
		return (
			<>
				<Text fontSize='xs' color='label_color'>
					{t('version')}
				</Text>
				<Text fontSize='sm'>{versions?.app}</Text>
				{showCommitIds && (
					<Text
						fontFamily='monospace'
						fontSize='10px'
						lineHeight={1.5}
						marginBlockStart={2}
					>
						core-api: {versions?.core_api}
						<br />
						sentinel-api: {versions?.sentinel_api}
						<br />
						sentry-janus: {versions?.sentry_janus}
						<br />
						sentry-ui-react: {versions?.sentry_ui_react}
					</Text>
				)}
			</>
		)
	}
}

export default SoftwareVersions
