import Alert from '@UI/Alert/Alert'
import AlertButton from '@UI/Alert/AlertButton'

import type { SiteMode } from '@Store/types'
import { useUpdateSiteModeMutation } from '@Store/sites/sitesApi'

import { SITE_MODES } from '@Constants/site'

import { useTranslation } from 'react-i18next'

type ModeAlertProps = {
	mode: SiteMode
	siteId: number
}

const ModeAlert = ({ mode, siteId }: ModeAlertProps) => {
	const { t } = useTranslation('panels', { keyPrefix: 'modeAlert' })
	const description = `${t(`siteMode.${mode}`)} - ${t('notSaved')}`
	const [changeMode] = useUpdateSiteModeMutation()

	const onClick = () => {
		changeMode({ siteId, mode: SITE_MODES.OPERATIONAL })
	}

	return (
		<Alert
			title={t('title')}
			description={description}
			status='warning'
			testId='mode-alert'
		>
			<AlertButton
				onClick={onClick}
				label={t('alertButton')}
				testId='mode-alert-button'
			/>
		</Alert>
	)
}

export default ModeAlert
