import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// Chakra
import { Box, Flex, Text } from '@chakra-ui/react'
import { MdArrowUpward } from 'react-icons/md'
import CopyButton from '@UI/CopyButton/CopyButton'

// Redux
import {
	useGetSiteLiveQuery,
	selectSiteHasRadar,
	selectSiteHasRfSensor,
	selectSiteRadars,
	selectSiteRfSensors,
	selectSiteCameras,
	selectDetectionHasRadarContributions,
	selectSiteRadarById,
} from '@Store/sites/sitesWsApi'
import { useAppSelector } from '@Store/index'
import { selectSeenContributors } from '@Store/ui/uiSlice'
import type {
	Detection as DetectionType,
	DetectionContributionSensor,
} from '@Store/types'

// Utility
import {
	formatAltitude,
	formatLatLng,
	formatHeading,
	formatBeaconString,
	formatSpeed,
	formatFrequency,
	formatProbability,
	formatThreatLevel,
	formatTimeOnTarget,
	formatRadarRCS,
	formatEventId,
	formatDistance,
} from '@Utils/formatUtils'

import useShowPreviewMode from '../../MapLayers/PreviewModes/useShowPreviewMode'

type DetectionProps = {
	detection: DetectionType
}

export const SensorFusionDetail = ({ detection }: DetectionProps) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'detections.detectionDetail.headings',
	})
	return (
		<Flex w='100%' flexWrap='wrap' marginBlockEnd={2}>
			<Box w='50%' marginBlockEnd={2}>
				<Text fontSize='xs' color='headings'>
					{t('confidence')}
				</Text>
				<Text fontSize='sm'>
					{formatProbability(detection.probability) || '-'}
				</Text>
			</Box>
			<Box w='50%' marginBlockEnd={2}>
				<Text fontSize='xs' color='headings'>
					{t('threatLevel')}
				</Text>
				<Text fontSize='sm'>
					{formatThreatLevel(detection.threat_level) || '-'}
				</Text>
			</Box>
		</Flex>
	)
}

export const RFDetail = ({ detection }: DetectionProps) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'detections.detectionDetail.headings',
	})

	return (
		<Flex w='100%' flexWrap='wrap' marginBlockEnd={2}>
			<Box w='50%' marginBlockEnd={2}>
				<Text fontSize='xs' color='headings'>
					{t('protocol')}
				</Text>
				<Text fontSize='sm'>{detection.protocol || '-'}</Text>
			</Box>
			<Box w='50%' marginBlockEnd={2}>
				<Text fontSize='xs' color='headings'>
					{t('frequency')}
				</Text>
				<Text fontSize='sm'>{formatFrequency(detection.frequency) || '-'}</Text>
			</Box>
			<Box w='50%' marginBlockEnd={2}>
				<Text fontSize='xs' color='headings'>
					{t('vendor')}
				</Text>
				<Text fontSize='sm'>{detection.vendor || '-'}</Text>
			</Box>
			<Box w='50%' marginBlockEnd={2}>
				<Text fontSize='xs' color='headings'>
					{t('ssid')}
				</Text>
				<Text fontSize='sm'>{formatBeaconString(detection.ssid) ?? '-'}</Text>
			</Box>
		</Flex>
	)
}

export const LocationDetail = ({
	detection,
	isEventReplay,
}: DetectionProps & { isEventReplay?: boolean }) => {
	const {
		latitude,
		longitude,
		altitude,
		speed,
		time_on_target,
		heading,
		site_distance,
	} = detection
	const { t } = useTranslation('panels', {
		keyPrefix: 'detections.detectionDetail.headings',
	})

	return (
		<Flex w='100%' flexWrap='wrap' marginBlockEnd={2}>
			<Box w='50%' marginBlockEnd={2}>
				<Text fontSize='xs' color='headings'>
					{t('latitude')}
				</Text>
				<Text fontSize='sm'>{formatLatLng(latitude) ?? '-'}</Text>
			</Box>
			<Box w='50%' marginBlockEnd={2}>
				<Text fontSize='xs' color='headings'>
					{t('longitude')}
				</Text>
				<Text fontSize='sm'>{formatLatLng(longitude) ?? '-'}</Text>
			</Box>
			<Box w='50%' marginBlockEnd={2}>
				<Text fontSize='xs' color='headings'>
					{t('height')}
				</Text>
				<Text fontSize='sm'>{formatAltitude(altitude) ?? '-'}</Text>
			</Box>
			<Box w='50%' marginBlockEnd={2}>
				<Text fontSize='xs' color='headings'>
					{t('speed')}
				</Text>
				<Text fontSize='sm'>{formatSpeed(speed) ?? '-'}</Text>
			</Box>
			<Box w='50%' marginBlockEnd={2}>
				<Text fontSize='xs' color='headings'>
					{t('direction')}
				</Text>
				<Box fontSize='sm' position='relative'>
					{heading && !Number.isNaN(heading) && (
						<>
							<Text
								position='absolute'
								top='2px'
								transform={`rotate(${heading}deg)`}
								as='span'
							>
								<MdArrowUpward />
							</Text>
							<Text paddingInlineStart={4}>{formatHeading(heading)}</Text>
						</>
					)}
					{(!heading || Number.isNaN(heading)) && '-'}
				</Box>
			</Box>
			<Box w='50%' marginBlockEnd={2}>
				<Text fontSize='xs' color='headings'>
					{t('timeOnTarget')}
				</Text>
				<Text fontSize='sm'>{formatTimeOnTarget(time_on_target) ?? '-'}</Text>
			</Box>
			{isEventReplay && (
				<Box w='50%' marginBlockEnd={2}>
					<Text fontSize='xs' color='headings'>
						{t('distance')}
					</Text>
					<Text fontSize='sm'>{formatDistance(site_distance) ?? '-'}</Text>
				</Box>
			)}
		</Flex>
	)
}

export const RcsDetail = ({ detection }: DetectionProps) => {
	const { rcs } = detection
	const { t } = useTranslation('panels', {
		keyPrefix: 'detections.detectionDetail.headings',
	})
	return (
		<Flex w='100%' flexWrap='wrap' marginBlockEnd={2}>
			<Box w='50%' marginBlockEnd={2}>
				<Text fontSize='xs' color='headings'>
					{t('rcs')}
				</Text>
				<Text fontSize='sm'>{formatRadarRCS(rcs) ?? '-'}</Text>
			</Box>
		</Flex>
	)
}

export const BeaconDetail = ({ detection }: DetectionProps) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'detections.detectionDetail.headings',
	})
	const {
		gcs_latitude,
		gcs_longitude,
		home_latitude,
		home_longitude,
		drone_serial_number,
	} = detection

	return (
		<Flex w='100%' flexWrap='wrap' marginBlockEnd={2}>
			{gcs_latitude !== 0 && (
				<Box w='50%' marginBlockEnd={2}>
					<Text fontSize='xs' color='headings'>
						{t('gcsLatitude')}
					</Text>
					<Text fontSize='sm'>{formatLatLng(gcs_latitude) ?? '-'}</Text>
				</Box>
			)}
			{gcs_longitude !== 0 && (
				<Box w='50%' marginBlockEnd={2}>
					<Text fontSize='xs' color='headings'>
						{t('gcsLongitude')}
					</Text>
					<Text fontSize='sm'>{formatLatLng(gcs_longitude) ?? '-'}</Text>
				</Box>
			)}
			{home_latitude !== 0 && (
				<Box w='50%' marginBlockEnd={2}>
					<Text fontSize='xs' color='headings'>
						{t('homeLatitude')}
					</Text>
					<Text fontSize='sm'>{formatLatLng(home_latitude) ?? '-'}</Text>
				</Box>
			)}
			{home_longitude !== 0 && (
				<Box w='50%' marginBlockEnd={2}>
					<Text fontSize='xs' color='headings'>
						{t('homeLongitude')}
					</Text>
					<Text fontSize='sm'>{formatLatLng(home_longitude) ?? '-'}</Text>
				</Box>
			)}
			{drone_serial_number && (
				<Box w='100%' marginBlockEnd={2}>
					<Text fontSize='xs' color='headings'>
						{t('droneSerialNumber')}
					</Text>
					<Text fontSize='sm'>
						{formatBeaconString(drone_serial_number) ?? '-'}
					</Text>
				</Box>
			)}
		</Flex>
	)
}

export const EventDetail = ({ detection }: DetectionProps) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'detections',
	})
	return (
		<Box w='100%' marginBlockEnd={2}>
			<Text fontSize='xs' color='headings'>
				{t('detectionDetail.headings.eventId')}
			</Text>
			<Flex alignItems='center' fontSize='sm' gap={1}>
				<Text>{formatEventId(detection.target_id)}</Text>
				<CopyButton
					content={formatEventId(detection.target_id) ?? ''}
					successTitle={t('buttons.copyButton.titles.success')}
					errorTitle={t('buttons.copyButton.titles.error')}
					successDescription={t('buttons.copyButton.descriptions.success')}
					errorDescription={t('buttons.copyButton.descriptions.error')}
				/>
			</Flex>
		</Box>
	)
}

export const SensorDetail = ({
	detection,
	isEventReplay,
	eventReplayContributors,
}: DetectionProps & {
	isEventReplay?: boolean
	eventReplayContributors?: DetectionContributionSensor[]
}) => {
	const seenContributors = useAppSelector(
		selectSeenContributors(detection.target_id)
	)

	const contributions = detection.detection_contributions
	const { t } = useTranslation('panels', {
		keyPrefix: 'detections.detectionDetail.headings',
	})

	const { siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)
	const { radars, rfSensors, cameras } = useGetSiteLiveQuery(siteId, {
		selectFromResult: ({ data }) => ({
			radars: selectSiteRadars(data),
			rfSensors: selectSiteRfSensors(data),
			cameras: selectSiteCameras(data),
		}),
		skip: isEventReplay,
	})

	const getSensorName = (sensorType: string, sensorId: number) => {
		switch (sensorType) {
			case 'rfSensor':
			case 'dsx':
				return rfSensors.find((sensor) => sensor.id === sensorId)?.name
			case 'radar':
				return radars.find((sensor) => sensor.id === sensorId)?.name
			case 'camera':
				return cameras.find((sensor) => sensor.id === sensorId)?.name
			default:
				return null
		}
	}

	const contributors = isEventReplay
		? eventReplayContributors
		: seenContributors

	return (
		<Box w='100%' flexWrap='wrap' marginBlockEnd={2}>
			<Text fontSize='xs' color='headings'>
				{t('detectionSensors')}
			</Text>
			{detection.drone_locator_confirmed && (
				<Text fontSize='sm'>DroneLocator</Text>
			)}
			{contributors?.map(({ sensorType, sensorId, sensorName }) => (
				<Text
					key={`${sensorType}-${sensorId}`}
					fontSize='sm'
					opacity={
						contributions.some(
							(c) => c.sensor_id === sensorId && c.sensor_type === sensorType
						)
							? undefined
							: 0.4
					}
				>
					{isEventReplay ? sensorName : getSensorName(sensorType, sensorId)}
				</Text>
			))}
		</Box>
	)
}

const RadarMaskDetail = ({ detection }: DetectionProps) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'detections.detectionDetail.headings',
	})

	const contribution = detection.detection_contributions[0]
	const elevation = contribution.elevation
	const altitude = detection.altitude

	const { isEchoShield } = useGetSiteLiveQuery(detection.site_id, {
		selectFromResult: ({ data }) => ({
			isEchoShield:
				selectSiteRadarById(data, contribution.sensor_id)?.radar_type ===
				'echo_shield',
		}),
	})

	return (
		<Flex w='100%' flexWrap='wrap' marginBlockEnd={2}>
			<Box w='50%' marginBlockEnd={2}>
				{isEchoShield ? (
					<>
						<Text fontSize='xs' color='headings'>
							{t('height')}
						</Text>
						<Text fontSize='sm'>{formatAltitude(altitude) ?? '-'}</Text>
					</>
				) : (
					<>
						<Text fontSize='xs' color='headings'>
							{t('elevation')}
						</Text>
						<Text fontSize='sm'>{formatAltitude(elevation) ?? '-'}</Text>
					</>
				)}
			</Box>
			<Box w='50%' marginBlockEnd={2}>
				<Text fontSize='xs' color='headings'>
					{t('rcs')}
				</Text>
				<Text fontSize='sm'>{formatRadarRCS(detection.rcs) ?? '-'}</Text>
			</Box>
			<Box>
				<Text fontSize='xs' color='headings'>
					{t('eventId')}
				</Text>
				<Text fontSize='sm'>{formatEventId(detection.target_id) ?? '-'}</Text>
			</Box>
		</Flex>
	)
}

const DetectionDetail = ({ detection }: DetectionProps) => {
	const { siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)
	const { hasRfSensor, hasRadar, hasRadarContributions } = useGetSiteLiveQuery(
		siteId,
		{
			selectFromResult: ({ data }) => ({
				hasRfSensor: selectSiteHasRfSensor(data),
				hasRadar: selectSiteHasRadar(data),
				hasRadarContributions: selectDetectionHasRadarContributions(
					data,
					detection.target_id
				),
			}),
		}
	)
	const { showRadarMaskPreview } = useShowPreviewMode()
	const showRadarMaskDetail = showRadarMaskPreview && hasRadarContributions

	return (
		<Flex
			bgColor='panel_bg'
			paddingInlineStart={12}
			paddingBlock={4}
			flexWrap='wrap'
		>
			{showRadarMaskDetail ? (
				<RadarMaskDetail detection={detection} />
			) : (
				<>
					<SensorFusionDetail detection={detection} />
					{hasRfSensor && <RFDetail detection={detection} />}
					<LocationDetail detection={detection} />
					{hasRadar && <RcsDetail detection={detection} />}
					{detection.drone_locator_confirmed && (
						<BeaconDetail detection={detection} />
					)}
					<EventDetail detection={detection} />
					<SensorDetail detection={detection} />
				</>
			)}
		</Flex>
	)
}

export default DetectionDetail
