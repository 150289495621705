import { useTranslation } from 'react-i18next'
import { formatInTimeZone } from 'date-fns-tz'

import { Flex } from '@chakra-ui/react'

import BarChart from '@UI/Charts/BarChart'
import FetchError from '@UI/FetchError/FetchError'
import Headings from '@UI/Headings/Headings'
import Skeleton from '@UI/Skeleton/Skeleton'

import { useGetTrackSummaryQuery } from '@Store/analytics/analyticsApi'

import { useEventFilter } from './EventDetailsTable/EventFilterContext'

const getHourTz = (date: string, timeZone: string) => {
	const d = new Date(date)
	return formatInTimeZone(d, timeZone, 'HH')
}

type FlightsBarChartProps = {
	siteId: number
	startTime: string
	endTime: string
	siteTimeZone: string
}

const FlightsBarChart = ({
	siteId,
	startTime,
	endTime,
	siteTimeZone,
}: FlightsBarChartProps) => {
	const { t } = useTranslation('pages', {
		keyPrefix: 'analytics.trackSummary',
	})

	const { filterParams } = useEventFilter()

	const { isSuccess, isLoading, isError, refetch, isFetching, flightsByHour } =
		useGetTrackSummaryQuery(
			{
				siteId,
				format: 'graph',
				startTime,
				endTime,
				...filterParams,
			},
			{
				selectFromResult: ({
					isSuccess,
					isLoading,
					isError,
					isFetching,
					data,
				}) => ({
					isSuccess,
					isLoading,
					isError,
					isFetching,
					flightsByHour: data?.by_hour ?? {},
				}),
			}
		)

	const chartData = Object.entries(flightsByHour)
		.map(([utcHour, count]) => {
			// The API provides only the UTC hour
			// To adjust for timezone offset a full date is required
			const utcDate = `${startTime.split(' ')[0]}T${utcHour}:00:00Z`
			const convertedHour = getHourTz(utcDate, siteTimeZone)
			return {
				sortBy: Number(convertedHour),
				label: convertedHour + ':00',
				value: count,
			}
		})
		.sort((a, b) => a.sortBy - b.sortBy)

	return (
		<Skeleton isLoaded={!isLoading && !isFetching}>
			{isSuccess && (
				<Flex
					h='360px'
					w='100%'
					direction='column'
					gap='16px'
					marginBlockEnd={8}
					alignItems='center'
				>
					<Headings.ChartHeading title={t('numberOfFlightsBarChart.title')} />
					<BarChart
						data={chartData}
						indexBy='label'
						keys={['value']}
						margin={{ top: 15, right: 30, bottom: 68, left: 60 }}
						legendY={t('numberOfFlightsBarChart.yAxisLabel')}
						legendX={t('numberOfFlightsBarChart.xAxisLabel')}
					/>
				</Flex>
			)}
			{isError && (
				<FetchError
					refetch={refetch}
					entity={t('entities.trackSummaryCharts')}
				/>
			)}
		</Skeleton>
	)
}

export default FlightsBarChart
