// import { useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Joystick } from 'react-joystick-component'
import type { IJoystickUpdateEvent } from 'react-joystick-component/build/lib/Joystick'
import {
	useMoveCameraMutation,
	useMoveCameraStopMutation,
} from '@Store/cameras/camerasApi'

const JoystickControl = ({ cameraId }: { cameraId: number }) => {
	// const ref = useRef<HTMLDivElement | null>(null)
	const { siteId: siteIdParam } = useParams()
	const [moveCamera] = useMoveCameraMutation()
	const [moveCameraStop] = useMoveCameraStopMutation()

	const handleMove = (e: IJoystickUpdateEvent) => {
		if (e.type === 'move' && e.x && e.y) {
			moveCamera({
				siteId: Number(siteIdParam),
				cameraId: cameraId,
				x: e.x,
				y: e.y,
			}).catch((reason) => {
				console.error('moveCamera failed: ', reason)
			})
		}
	}

	const handleStop = (e: IJoystickUpdateEvent) => {
		if (e.type === 'stop') {
			moveCameraStop({
				siteId: Number(siteIdParam),
				cameraId: cameraId,
			}).catch((reason) => {
				console.error('moveCameraStop failed: ', reason)
			})
		}
	}

	return (
		<Joystick
			baseColor='#e0e0e080'
			stickColor='#fff'
			size={64}
			stickSize={32}
			move={handleMove}
			stop={handleStop}
			throttle={150}
		/>
	)
}

export default JoystickControl
