import { useTranslation } from 'react-i18next'
import {
	MdPlayArrow as DisruptAllIcon,
	MdStop as StopDisruptionIcon,
} from 'react-icons/md'

import { useUpdateAllDisruptorsMutation } from '@Store/disruptors/disruptorsApi'
import { useUpdateAllRfSensorsMutation } from '@Store/rfSensors/rfSensorsApi'

import {
	useGetSiteLiveQuery,
	selectSiteDisruptors,
	selectSiteRfSensorsDsx,
	selectIsEveryDisruptorDisabled,
	selectIsEveryDisruptorOffline,
} from '@Store/sites/sitesWsApi'

import QuickActionsButton from '../QuickActionsButton/QuickActionsButton'

import { isDisruptorDisabled, isDsxDisabled } from '@Utils/installations'

type DisruptAllButtonProps = {
	siteId: number
	setIsAllLoading: (isLoading: boolean) => void
}

const DisruptAllButton = ({
	siteId,
	setIsAllLoading,
}: DisruptAllButtonProps) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'quickActions.disruptorActions',
	})
	const includeDsxDisruptors = false

	const { disruptors, rfSensorsDsx, isDisabled, isOffline } =
		useGetSiteLiveQuery(siteId, {
			selectFromResult: ({ data }) => ({
				disruptors: selectSiteDisruptors(data, includeDsxDisruptors),
				rfSensorsDsx: selectSiteRfSensorsDsx(data),
				isDisabled: selectIsEveryDisruptorDisabled(data, includeDsxDisruptors),
				isOffline: selectIsEveryDisruptorOffline(data, includeDsxDisruptors),
			}),
		})

	const [updateAllDisruptors] = useUpdateAllDisruptorsMutation()
	const [updateAllRfSensors, { isLoading }] = useUpdateAllRfSensorsMutation()

	const activeDisruptors = disruptors.filter(
		(disruptor) => !isDisruptorDisabled(disruptor)
	)
	const activeDsx = rfSensorsDsx
		.filter((dsx) => !isDsxDisabled(dsx))
		.map((activeDsx) => {
			if (activeDsx.model.includes('dsx_mk2')) {
				return {
					...activeDsx,
					active_profile_id: activeDsx.Cannon?.v2_bands_statuses?.id,
				}
			}
			return activeDsx
		})

	const isAnyDisruptorActivated = disruptors.some(
		(disruptor) => disruptor.power_trigger_engaged
	)
	const isAnyDsxActivated = rfSensorsDsx.some((dsx) => dsx.jamming)

	const isActivated = isAnyDisruptorActivated || isAnyDsxActivated

	const toggleAllDisruptors = async () => {
		setIsAllLoading(true)
		try {
			await updateAllDisruptors({
				siteId,
				disruptors: activeDisruptors,
				power_trigger_command: isActivated ? false : true,
			}).unwrap()
			await updateAllRfSensors({
				siteId,
				rfSensors: activeDsx,
				power_trigger_command: isActivated ? false : true,
			}).unwrap()
			setIsAllLoading(false)
		} catch (error) {
			console.error('Toggle all disruptors error', error)
			setIsAllLoading(false)
		}
	}

	return (
		<QuickActionsButton
			icon={isActivated ? StopDisruptionIcon : DisruptAllIcon}
			label={isActivated ? t('stopDisruption') : t('disruptAll')}
			onClick={toggleAllDisruptors}
			isDisabled={isDisabled || isOffline}
			isActive={isActivated}
			isLoading={isLoading}
			testId='disrupt-all'
		/>
	)
}

export default DisruptAllButton
