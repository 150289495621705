import { Center, Flex } from '@chakra-ui/react'

import Logos from '@UI/Logos/Logos'

const LoginPage = ({ children }: { children: React.ReactNode }) => {
	return (
		<Center h='100vh' bgColor='body_bg'>
			<Flex
				direction='column'
				h='581px'
				w='458px'
				p={8}
				bgColor='login_panel_bg'
				gap='36px'
				boxShadow='lg'
			>
				<Center flexDirection='column'>
					<Logos.Login />
				</Center>
				{children}
			</Flex>
		</Center>
	)
}

export default LoginPage
