import { Flex, Spinner, Text, Icon, useTheme } from '@chakra-ui/react'

import { MdCheckCircle, MdRadioButtonUnchecked } from 'react-icons/md'

const iconSize = { w: 5, h: 5 }

type SpinnerTextProps = {
	text: string
	status?: 'active' | 'inactive' | 'complete'
	hint?: string
}

const renderIcon = (status: SpinnerTextProps['status']) => {
	switch (status) {
		case 'active':
			return <Spinner size='sm' color='primary' />
		case 'inactive':
			return <Icon as={MdRadioButtonUnchecked} color='disabled' {...iconSize} />
		case 'complete':
			return <Icon as={MdCheckCircle} color='primary' {...iconSize} />
		default:
			return null
	}
}

const SpinnerText = ({ text, status, hint }: SpinnerTextProps) => {
	const {
		semanticTokens: { colors },
	} = useTheme()

	return (
		<Flex direction='column' gap='10px'>
			<Flex alignItems='center' gap='12px'>
				{renderIcon(status)}
				<Text
					fontSize='sm'
					color={status === 'inactive' ? colors.disabled : colors.input_color}
				>
					{text}
				</Text>
			</Flex>
			{hint && status === 'active' && (
				<Flex alignItems='center' gap='12px' marginInlineStart={6}>
					<Spinner size='sm' color='primary' />
					<Text fontSize='sm'>{hint}</Text>
				</Flex>
			)}
		</Flex>
	)
}

export default SpinnerText
