// Components
import MapLayerTableCollapsed from './MapLayerTableCollapsed'
import MapLayerTableExpanded from './MapLayerTableExpanded'
// Store
import type { MapLayer } from '@Store/types'

const MapLayersTable = ({
	mapLayers,
	variant,
}: {
	mapLayers?: MapLayer[]
	variant: 'collapsed' | 'expanded'
}) => {
	const isExpanded = variant === 'expanded'
	const sortedMapLayers = [...(mapLayers ?? [])].sort((a, b) => a.id - b.id)

	return (
		<>
			{isExpanded ? (
				<MapLayerTableExpanded mapLayers={sortedMapLayers} />
			) : (
				<MapLayerTableCollapsed mapLayers={sortedMapLayers} />
			)}
		</>
	)
}

export default MapLayersTable
