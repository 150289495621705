/**
 * Splits an array into groups the length of size. If array can't be split evenly, the final chunk will be the remaining elements.
 * @param array - The array to process.
 * @param size - The length of each chunk
 * @returns - Returns a new array of chunks.
 */
export const chunk = <T>(array: T[], size: number): T[][] => {
	const chunkedArray = []
	for (let i = 0; i < array.length; i += size) {
		chunkedArray.push(array.slice(i, i + size))
	}
	return chunkedArray
}

export const debounce = <F extends (...args: any[]) => void>(
	func: F,
	delay: number
): ((...args: Parameters<F>) => void) => {
	let timer: NodeJS.Timeout | null = null

	return (...args: Parameters<F>): void => {
		if (timer) clearTimeout(timer)

		timer = setTimeout(() => {
			func(...args)
		}, delay)
	}
}

export const getStatusColor = (percentage: number) => {
	if (percentage >= 80) return 'red'
	if (percentage >= 50) return 'orange'
	return 'green'
}
