import SemiCircle from '@Components/MapShapes/SemiCircle/SemiCircle'
import type { SVGProps } from 'react'
import type { CircleProps, PathProps } from '@react-leaflet/core'

export type SectorProps = {
	latitude: number
	longitude: number
	bearing?: number
	angle?: number
	reach: number
	onClick?: () => void
	onMouseDown?: () => void
	testId?: string
	className?: string
} & Omit<CircleProps, 'center' | 'radius'> &
	PathProps &
	Omit<SVGProps<any>, 'radius'>

const Sector = ({
	latitude,
	longitude,
	bearing,
	angle,
	reach,
	onClick,
	onMouseDown,
	testId,
	className,
	...options
}: SectorProps) => {
	if (bearing === undefined || angle === undefined || angle === 360)
		return (
			// render 360
			<SemiCircle
				latitude={latitude}
				longitude={longitude}
				startAngle={0}
				stopAngle={360}
				radius={reach}
				onClick={onClick}
				onMouseDown={onMouseDown}
				testId={testId}
				className={className}
				{...options}
			/>
		)
	else {
		const startAngle = bearing - angle / 2
		const stopAngle = bearing + angle / 2
		return (
			<SemiCircle
				latitude={latitude}
				longitude={longitude}
				startAngle={startAngle}
				stopAngle={stopAngle}
				radius={reach}
				onClick={onClick}
				onMouseDown={onMouseDown}
				testId={testId}
				className={className}
				{...options}
			/>
		)
	}
}

export default Sector
