// Rehydrate user state
import { store } from '@Store/index'
import { authApi } from '@Store/auth/authApi'
import { selectAuthenticatedUserId } from '@Store/auth/authSlice'
import { logout } from '@Store/auth/authSlice'

const rehydrateUser = async () => {
	const userId = selectAuthenticatedUserId(store.getState())
	if (userId) {
		const promise = store.dispatch(
			authApi.endpoints.getAuthUser.initiate(userId)
		)
		const { isError } = await promise
		if (isError) store.dispatch(logout())
	}
}

export default rehydrateUser
