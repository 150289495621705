import { useEffect, useState, type RefObject } from 'react'
import type { DeepRequired, FieldErrorsImpl } from 'react-hook-form'

export const useExpandFormAccordion = (
	ref: RefObject<HTMLDivElement>,
	errors: Partial<FieldErrorsImpl<DeepRequired<any>>> & {
		root?: Record<string, any> & any
	}
) => {
	const [expandedIndex, setExpandedIndex] = useState<number | undefined>(
		undefined
	)
	const [errorIndexes, setErrorIndexes] = useState<Set<number>>(new Set())

	useEffect(() => {
		if (ref.current) {
			const accordionItems = Array.from(
				ref.current.querySelectorAll('.chakra-accordion__item')
			)

			const invalidElementsIndexes: number[] = accordionItems.reduce(
				(acc: number[], el: Element, index: number) => {
					if (el.querySelector('input[aria-invalid="true"]')) {
						acc.push(index)
					}
					return acc
				},
				[]
			)

			if (invalidElementsIndexes.length > 0) {
				setExpandedIndex(invalidElementsIndexes[0])
				setErrorIndexes(new Set(invalidElementsIndexes))
			} else {
				setExpandedIndex(undefined)
				setErrorIndexes(new Set())
			}
		}
	}, [errors, ref])

	return { expandedIndex, setExpandedIndex, errorIndexes }
}
