import { z } from 'zod'
import globalSchema from '@Components/Forms/BaseForm/BaseForm.schema'
import { isValidPhoneNumber } from 'react-phone-number-input'
import i18n from 'i18next'
import isEmail from 'validator/lib/isEmail'

const t = (key: string) => i18n.t(`forms:global.validation.${key}`)

const alertRecipientFormFields = z.object({
	name: globalSchema.shape.name,
	address_type: z.enum(['sms', 'email']),
	address: z.string(),
	address_region: globalSchema.shape.countryCode,
	zone_ids: z.null().or(z.array(z.number())),
	sensors_down: z.boolean(),
	enabled: z.boolean().optional(),
})

export const alertRecipientFormSchema = alertRecipientFormFields.superRefine(
	(data, ctx) => {
		if (data.address_type.includes('sms')) {
			if (!isValidPhoneNumber(data.address)) {
				ctx.addIssue({
					path: ['address'],
					message: t('phone'),
					code: z.ZodIssueCode.custom,
				})
			}
		}
		if (data.address_type.includes('email')) {
			if (!isEmail(data.address)) {
				ctx.addIssue({
					path: ['address'],
					message: t('email'),
					code: z.ZodIssueCode.custom,
				})
			}
		}
	}
)

export const addAlertRecipientDefaultValues = {
	address: '',
	address_type: 'email' as 'email' | 'sms',
	enabled: true,
	name: '',
	sensors_down: false,
	zone_ids: [],
}
