// NOTE: All of the commented out code here is related to updating DSX Mk2 Profiles
// Uncomment them when the BE is ready!

import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useForm, FormProvider } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import type { TypeOf } from 'zod'

import { Box, Flex } from '@chakra-ui/react'
import { MdOpenInNew } from 'react-icons/md'

import Button from '@UI/Button/Button'
import InfoText from '@UI/InfoText/InfoText'
import Headings from '@UI/Headings/Headings'
import SpinnerText from '@UI/SpinnerText/SpinnerText'

import FormHeader from '../FormHeader'
import Field from '@Components/FormElements'
import { FormButtons } from '@Components/FormElements/FormButtons/FormButtons'
import FormWrapper from '@Components/FormElements/FormWrapper/FormWrapper'
import { BandStatus } from '@Components/Site/Panels/SiteInstallations/DevicesContent/DsxDevices/DisruptionPanel'

import { useAppDispatch } from '@Store/index'
import { updateSensorPreview } from '@Store/ui/uiSlice'
import {
	useGetRfSensorQuery as useGetDsxQuery,
	useCreateRfSensorMutation as useCreateDsxMutation,
	useUpdateRfSensorMutation as useUpdateDsxMutation,
	useGetDsxProfilesQuery,
} from '@Store/rfSensors/rfSensorsApi'
import {
	useGetSiteInstallationsQuery,
	selectInstallationsOptions,
} from '@Store/sites/sitesApi'
import { skipToken } from '@reduxjs/toolkit/query'

import type {
	RfSensor,
	DsxSensor_Models,
	RfSensor_ConnectionTypes,
	DsxProfile,
} from '@Store/types'
import type { DefaultValues } from './DsxForm.schema'
import { dsxFormSchema, ADD_DSX_DEFAULT_VALUES } from './DsxForm.schema'

import { openInNewTab } from '@Utils/navigation'
import {
	selectIsDefeatEnabled,
	useGetSystemInfoQuery,
} from '@Store/system/systemApi'
import ErrorBoundary from '@Components/App/ErrorHandling/ErrorBoundary'

type DsxFormProps = {
	siteId: number
	installationId: number
	sensorId?: number
}

type Dsx = (RfSensor & Omit<RfSensor, 'model'>) & {
	model: DsxSensor_Models
	connection_type: RfSensor_ConnectionTypes
	active_profile: DsxProfile
}

type DetectionVisualisationValue = 'sector' | 'line' | 'sector_and_line'

const DsxForm = ({ siteId, installationId, sensorId }: DsxFormProps) => {
	const isEditForm = !!sensorId
	const { t } = useTranslation('forms', { keyPrefix: 'dsxForm' })
	const {
		isLoading,
		isError,
		isSuccess,
		refetch,
		data: dsx,
	} = useGetDsxQuery(sensorId ? { siteId, sensorId } : skipToken, {
		// Ensures we get the correct active profile after editing, as the subsequent GET from
		// tag invalidation still returns the old result if the profiles are slow to switch
		refetchOnMountOrArgChange: true,
	})

	// Looks for props passed through from navigate() via Unregistered Sensors
	const { state } = useLocation()
	const addDsxDefaultValues = {
		...ADD_DSX_DEFAULT_VALUES,
		...state,
		sentry_id: installationId,
	}

	const defaultValues = isEditForm ? (dsx as Dsx) : addDsxDefaultValues

	return (
		<FormWrapper
			entity={t('entity')}
			isEditForm={isEditForm}
			isLoading={isLoading}
			isError={isError}
			isSuccess={isSuccess}
			refetch={refetch}
		>
			{defaultValues && (
				<Form
					isEditForm={isEditForm}
					defaultValues={defaultValues}
					siteId={siteId}
					installationId={installationId}
					sensorId={sensorId}
				/>
			)}
		</FormWrapper>
	)
}

const Form = ({
	defaultValues,
	isEditForm,
	siteId,
	installationId,
	sensorId,
}: {
	defaultValues: Dsx | DefaultValues
	isEditForm: boolean
	siteId: number
	installationId: number
	sensorId?: number
}) => {
	const navigate = useNavigate()
	const { t } = useTranslation('forms', { keyPrefix: 'dsxForm' })

	type Schema = TypeOf<typeof dsxFormSchema>

	const methods = useForm<Schema>({
		resolver: zodResolver(dsxFormSchema),
		defaultValues,
	})

	const {
		register,
		formState: { errors, isSubmitting, isDirty, dirtyFields },
		handleSubmit,
		setValue,
		setError,
		watch,
		resetField,
		unregister,
	} = methods

	const [
		model,
		directionOffset,
		reach,
		reachJamming,
		sentryId,
		connectionType,
		activeProfileId,
	] = watch([
		'model',
		'direction_offset',
		'reach',
		'reach_jamming',
		'sentry_id',
		'connection_type',
		'active_profile_id',
	])

	const isDsxMk2 = model.includes('dsx_mk2')
	const dsxConnectedViaSh = connectionType?.includes('sh')
	const isDetectOnlyModel = model.includes('no_jam')

	useEffect(() => {
		isDsxMk2 ? register('ip') : unregister('ip')
		isDsxMk2 && !dsxConnectedViaSh ? register('port') : unregister('port')
	}, [isDsxMk2, dsxConnectedViaSh, unregister, register])

	const dispatch = useAppDispatch()

	useEffect(() => {
		dispatch(
			updateSensorPreview({
				sentryId,
				sensorType: 'dronesentryx',
				model,
				directionOffset,
				reach,
				reachJamming,
			})
		)
		return () => {
			dispatch(updateSensorPreview(null))
		}
	}, [
		installationId,
		model,
		directionOffset,
		reach,
		reachJamming,
		sentryId,
		dispatch,
	])

	const [createDsx] = useCreateDsxMutation()
	const [updateDsx] = useUpdateDsxMutation()

	const { installationsOptions, installationsOptionsReady } =
		useGetSiteInstallationsQuery(Number(siteId), {
			selectFromResult: ({ isSuccess, data }) => ({
				...selectInstallationsOptions(data),
				installationsOptionsReady: isSuccess,
			}),
		})

	// if disabled or in faulty we should still be able to change bands or shutoff time
	const isDsxOnline =
		(defaultValues as Dsx).status_color !== 'red' ||
		(defaultValues as Dsx).state === 'fault'

	const {
		data: profiles,
		isSuccess: isGetProfileSuccess,
		isLoading: isLoadingProfiles,
	} = useGetDsxProfilesQuery(sensorId ? { siteId, sensorId } : skipToken, {
		skip: !isEditForm || !isDsxOnline || !isDsxMk2,
		refetchOnMountOrArgChange: true,
	})

	const profileOptions = (profiles ?? []).map((profile) => ({
		label: profile.name,
		value: profile.id,
	}))

	const profileDefaultValue = profileOptions.find(
		(option) => option.value === (defaultValues as Dsx).active_profile?.id
	)

	const selectedProfile = profiles?.find(
		(profile) => profile.id === (activeProfileId ?? profileDefaultValue?.value)
	)

	useEffect(() => {
		setValue('active_profile_id', profileDefaultValue?.value)
	}, [profileDefaultValue?.value, setValue])

	const getDefaultDetectionVisualisation = () => {
		const { show_sectors, show_sector_as_line } = defaultValues
		if (show_sectors && show_sector_as_line) return 'sector_and_line'
		else if (show_sectors) return 'sector'
		else if (show_sector_as_line) return 'line'
		// https://github.com/chakra-ui/chakra-ui/issues/2451
		// This case should ideally never be reached, as one of the conditions
		// above should always be met. Just in case, we return an 'invalid' string as
		// opposed to undefined as chakra doesn't deal with components going from
		// uncontrolled to controlled.
		else return 'unknown'
	}

	const [detectionVisualisation, setDetectionVisualisation] = useState(
		getDefaultDetectionVisualisation()
	)

	const setRadioValue = (
		name: 'show_sectors' | 'show_sector_as_line',
		value: boolean
	) => setValue(name, value, { shouldDirty: true })

	const installationDefaultValue = installationsOptions.find(
		(option) =>
			option.value ===
			(isEditForm ? (defaultValues as RfSensor).sentry_id : installationId)
	)

	const dsxRoute = `/${siteId}/installations/${installationId}/rf_sensors_dsx`
	const connectionTypeOptions = t('connectionTypeOptions', {
		returnObjects: true,
	}) as Array<{ label: string; value: string }>

	const connectionTypeDefaultValue = connectionTypeOptions.find(
		(option) =>
			option.value ===
			(isEditForm ? defaultValues.connection_type ?? 'v1' : 'v2_direct')
	)

	const handleSave = async (
		data: Schema & {
			band_24_58_trigger_command?: boolean
			gnss_trigger_command?: boolean
		}
	) => {
		try {
			if (data.model.includes('no_jam')) {
				delete data.reach_jamming
			}

			if (isEditForm && sensorId) {
				if (!isDsxOnline) {
					delete data.shutoff_time
				}

				const payload = {
					siteId,
					sensorId,
					...data,
				}

				if (dirtyFields['band_24_58_trigger_engaged']) {
					payload.band_24_58_trigger_command = data.band_24_58_trigger_engaged
				}

				if (dirtyFields['gnss_trigger_engaged']) {
					payload.gnss_trigger_command = data.gnss_trigger_engaged
				}

				await updateDsx(payload).unwrap()
			} else {
				await createDsx({ siteId, ...data }).unwrap()
			}
			navigate(dsxRoute)
		} catch (e: unknown) {
			const errors = e as { [name in keyof Schema]: string }
			for (const field in errors) {
				setError(field as keyof Schema, {
					type: 'custom',
					message: errors[field as keyof Schema] as string,
				})
			}
		}
	}

	const handleCancel = () => navigate(dsxRoute)

	const { isDefeatEnabled } = useGetSystemInfoQuery(undefined, {
		selectFromResult: ({ data }) => ({
			isDefeatEnabled: selectIsDefeatEnabled(data),
		}),
	})
	// hide jamming model when defeat is disabled
	const dsxTypeOptions = (
		t('modelOptions', {
			returnObjects: true,
		}) as Array<{ label: string; value: string }>
	).filter(
		(option) =>
			option.value.includes('no_jam') ||
			(!option.value.includes('no_jam') && isDefeatEnabled)
	)

	return (
		<>
			<FormHeader
				title={
					isEditForm ? t('headings.sensorSettings') : t('headings.addSensor')
				}
				backRoute={dsxRoute}
			/>
			<ErrorBoundary>
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(handleSave)}>
						{isEditForm && model === 'dsx_direct' && (
							<>
								<Field.Divider title={t('headings.disruptionBands')} />
								<Flex w='180px' paddingLeft='4px'>
									<Field.Switch
										title={'2.4\n5.8'}
										register={register('band_24_58_trigger_engaged')}
										testId='24-58-switch'
										disabled={!isDsxOnline}
									/>
									<Field.Switch
										title='GNSS'
										register={register('gnss_trigger_engaged')}
										testId='gnss-switch'
										disabled={!isDsxOnline}
									/>
								</Flex>
							</>
						)}
						{isEditForm && isDsxMk2 && !isDetectOnlyModel && (
							<>
								<Field.Divider title={t('headings.disruptionProfiles')} />
								<Flex justifyContent='flex-end'>
									<Button
										label={t('editProfiles')}
										leftIcon={<MdOpenInNew />}
										size='xs'
										color='label_color'
										testId='edit-profiles'
										isDisabled={!isDsxOnline}
										onClick={() =>
											openInNewTab(`http://${defaultValues.ip}/detections`)
										}
									/>
								</Flex>
								{!isDsxOnline && (
									<Box my={3}>
										<InfoText title={t('onlineDevice')}>
											{t('connectDevice')}
										</InfoText>
									</Box>
								)}
								{isLoadingProfiles && (
									<SpinnerText text={t('loadingProfiles')} status='active' />
								)}
								{isGetProfileSuccess && (
									<>
										<Field.Select
											title={t('selectProfile')}
											defaultValue={profileDefaultValue}
											register={register('active_profile_id')}
											options={profileOptions}
											error={errors?.active_profile_id?.message}
											disabled={!isDsxOnline}
										/>
										{selectedProfile && (
											<Box ml={1}>
												<BandStatus profile={selectedProfile} />
											</Box>
										)}
									</>
								)}
							</>
						)}
						<Field.Divider title={t('headings.generalParameters')} />
						{installationsOptionsReady && (
							<Field.Select
								title={t('installation')}
								defaultValue={installationDefaultValue}
								register={register('sentry_id')}
								options={installationsOptions}
								error={errors?.sentry_id?.message}
							/>
						)}
						<Field.TextInput
							title={t('name')}
							register={register('name')}
							error={errors?.name?.message}
							testId='name'
						/>
						<Field.Select
							title={t('model')}
							register={register('model', {
								onChange: () => {
									resetField('ip')
								},
							})}
							disabled={isEditForm}
							tooltip={isEditForm ? t('tooltips.model_disabled') : ''}
							options={dsxTypeOptions}
							error={errors?.model?.message}
						/>
						<Field.TextInput
							title={t('serialNumber')}
							register={register('serial_number')}
							disabled={isEditForm}
							tooltip={isEditForm ? t('tooltips.serial_number_disabled') : ''}
							error={errors?.serial_number?.message}
							testId='serial-number'
						/>
						{isDsxMk2 && (
							<>
								<Field.Select
									title={t('connectionType')}
									register={register('connection_type')}
									options={connectionTypeOptions}
									defaultValue={connectionTypeDefaultValue}
									error={errors?.connection_type?.message}
									placeholder={t('connectionTypePlaceholder')}
									disabled={isEditForm}
									tooltip={
										isEditForm ? t('tooltips.connection_type_disabled') : ''
									}
									testId='connection-type'
								/>
								<Field.TextInput
									title={dsxConnectedViaSh ? t('smartHubIp') : t('ip')}
									disabled={isEditForm}
									tooltip={
										isEditForm
											? t('tooltips.ip_disabled')
											: dsxConnectedViaSh
												? t('tooltips.smartHubIp')
												: t('tooltips.ip')
									}
									register={register('ip')}
									error={errors?.ip?.message}
									testId='ip'
								/>
								{!dsxConnectedViaSh && (
									<Field.TextInput
										title={t('port')}
										register={register('port')}
										error={errors?.port?.message}
										disabled={isEditForm}
										tooltip={isEditForm ? t('tooltips.port_disabled') : ''}
										testId='port'
									/>
								)}
							</>
						)}
						<Field.UnitsSlider
							units='deg'
							title={t('directionOffset')}
							min={0}
							max={359.99}
							step={0.1}
							register={register('direction_offset', { valueAsNumber: true })}
							error={errors?.direction_offset?.message}
							testId='direction-offset'
						/>
						<Field.Slider
							units='m'
							title={t('displayRange')}
							min={1000}
							max={8000}
							register={register('reach', { valueAsNumber: true })}
							error={errors?.reach?.message}
							testId='display-range'
						/>
						{['dsx_direct', 'dsx_mk2'].includes(model) && (
							<>
								<Field.Slider
									units='m'
									title={t('disruptionReach')}
									min={250}
									max={750}
									register={register('reach_jamming', { valueAsNumber: true })}
									error={errors?.reach_jamming?.message}
									testId='disruption-reach'
								/>
								{isEditForm && (
									<Field.Slider
										title={t('shutoffTime')}
										tooltip={t('tooltips.shutoffTime')}
										min={10}
										max={600}
										disabled={!isDsxOnline}
										register={register('shutoff_time', { valueAsNumber: true })}
										error={errors?.shutoff_time?.message}
										testId='shutoff-time'
									/>
								)}
							</>
						)}

						<Field.Divider title={t('headings.displayParameters')} />

						<Field.Switch
							title={t('show720Detection')}
							register={register('show_720_detection')}
							error={errors?.show_720_detection?.message}
							testId='show-detections-no-aoa'
						/>

						<Headings.SectionSubheading
							title={t('headings.detectionVisualisation')}
						/>
						<Field.Radio
							id='detectionVisualisation'
							options={t('detectionVisualisationOptions', {
								returnObjects: true,
							})}
							error={errors?.show_sectors?.message}
							value={detectionVisualisation}
							onChange={(value) => {
								setDetectionVisualisation(value as DetectionVisualisationValue)
								if (value === 'sector') {
									setRadioValue('show_sectors', true)
									setRadioValue('show_sector_as_line', false)
								} else if (value === 'line') {
									setRadioValue('show_sectors', false)
									setRadioValue('show_sector_as_line', true)
								} else if (value === 'sector_and_line') {
									setRadioValue('show_sectors', true)
									setRadioValue('show_sector_as_line', true)
								}
							}}
						/>

						<FormButtons
							isSubmitting={isSubmitting}
							isDirty={isDirty}
							handleCancel={handleCancel}
						/>
					</form>
				</FormProvider>
			</ErrorBoundary>
		</>
	)
}

export default DsxForm
