import { z } from 'zod'
import globalSchema from '@/components/Forms/BaseForm/BaseForm.schema'

const pickedFromGlobal = globalSchema.pick({
	name: true,
})
const gpsCompassFormFields = z.object({
	name: z.string(),
	sentry_id: z.number(),
	serial_number: z.string().min(1),
})

export const gpsCompassFormSchema = gpsCompassFormFields.merge(pickedFromGlobal)
export type GpsCompassSchema = z.infer<typeof gpsCompassFormSchema>
