export const styles = {
	global: {
		'html, body': {},
		body: {
			letterSpacing: '0.025rem',
		},
		'@supports selector(::-webkit-scrollbar)': {
			'::-webkit-scrollbar': {
				w: '0.25rem',
				h: '0.25rem',
			},
			'::-webkit-scrollbar-corner': {
				bgColor: 'primary',
			},
			'::-webkit-scrollbar-thumb': {
				bgColor: 'primary',
			},
		},
		'@supports selector(scrollbar-width)': {
			'*': {
				scrollbarColor: 'var(--c2-colors-primary) var(--c2-colors-panel_bg)',
				scrollbarWidth: 'thin',
				scrollbarHeight: 'thin',
			},
		},
		// Fix screenshot tool displaying extra borders around markers
		'.leaflet-marker-icon': {
			border: '1px solid transparent',
			boxSizing: 'content-box',
		},
		'.leaflet-container': {
			'.leaflet-tile-pane': {
				filter: 'grayscale(0.8)',
			},
			'.leaflet-control-attribution': {
				marginRight: 'var(--c2-space-2)',
				a: { display: 'none' },
				span: { display: 'none' },
				// attribution control is stubbornly too tall
				lineHeight: '18px',
				height: '18px',
			},
		},
		// Map
		'.MapContainer': {
			boxSize: '100%',
		},
		// MapControls order:
		// 1) Compass, 2) MapCenter, 3) Zoom, 4) Screenshot, 5) Measure, 6) MapLayer, 7) MapLegend
		'.leaflet-control:has(.c2-map-compass-control)': {
			order: -1000,
		},
		'.leaflet-control:has(.MapLegendFullScreen)': {
			order: 1000,
		},
		// Controls
		'.leaflet-control': {
			fontFamily: 'Roboto',
		},

		'.leaflet-control-scale-line': {
			textShadow: 'none',
			lineHeight: 'unset',
			padding: 'unset',
			paddingInline: '4px',
			border: '1px solid #777',
			borderTop: 0,
		},
		'.leaflet-control-container .leaflet-bottom.leaflet-right': {
			display: 'flex',
			zIndex: 400,
		},
		'.leaflet-control-attribution': {
			order: 100,
			color: 'var(--c2-colors-leaflet.control_text)',
		},
		'.leaflet-control-container .leaflet-left': {
			transition: 'left .30s',
			display: 'flex',
			flexDirection: 'column',
		},
		'.leaflet-control-container .leaflet-control': {
			border: 'none',
			marginBottom: 'var(--c2-space-2)',
			a: {
				width: '32px',
				height: '32px',
				color: 'var(--c2-colors-leaflet-control_color)',
				backgroundColor: 'var(--c2-colors-leaflet-control_bg)',
			},
		},
		'.leaflet-bar a': {
			borderBottom: '1px solid var(--c2-colors-chakra-border-color)',
		},
		// Fix map controls overlays device action menu buttons
		'.leaflet-bottom': {
			zIndex: 500,
		},
		'.leaflet-div-icon:not(.leaflet-marker-draggable)': {
			backgroundColor: 'transparent',
			border: 0,
		},
		'.rf-detection-sector-show-outside': {
			filter: 'blur(8px)',
		},
		'.disruptor-sector-animation': {
			animation: 'sector-pulse 2s infinite',
			fill: 'red',
			fillOpacity: 0.4,
			stroke: 'red',
			strokeWidth: 3,
		},
		'@keyframes sector-pulse': {
			'0%': {
				opacity: 0.3,
			},
			'50%': {
				opacity: 0.8,
			},
			'100%': {
				opacity: 0.3,
			},
		},
		// Move top-right toast position next to QuickActions and SiteMode alert
		'.chakra-toast__inner': {
			maxWidth: '350px',
			width: '350px',
			margin: '0 !important',
		},
		'div#chakra-toast-manager-top-right': {
			top: '50px !important',
			right: '0',
			gap: '10px',
			padding: '10px',
		},
		'body:has(div.RightPanel) :is(div#chakra-toast-manager-top-right)': {
			right: '324px !important',
		},
		'body:has(div.AlertsPanel):has(div.RightPanel) :is(div#chakra-toast-manager-top-right)':
			{
				top: '114px !important',
			},
	},
}
