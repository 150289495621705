import { useTranslation } from 'react-i18next'

import FetchError from '@UI/FetchError/FetchError'
import Modal from '@UI/Modals/Modal'
import VideoPlayer from '@UI/VideoPlayer/VideoPlayer'

import { useGetVideoRecordingQuery } from '@Store/analytics/analyticsApi'

type VideoRecordingModalProps = {
	isOpen: boolean
	onClose: () => void
	recordingId: number
}

const VideoRecordingModal = ({
	isOpen,
	onClose,
	recordingId,
}: VideoRecordingModalProps) => {
	const { t } = useTranslation('pages', {
		keyPrefix: 'analytics.videoRecordings',
	})

	const {
		isSuccess,
		isError,
		refetch,
		data: videoRecording,
	} = useGetVideoRecordingQuery({ recordingId })

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			headerText={`${t('recording')} ${videoRecording?.filename}`}
			size='3xl'
		>
			{isSuccess && <VideoPlayer url={videoRecording.url} controls />}
			{isError && (
				<FetchError refetch={refetch} errorMessage={t('fetchRecordingError')} />
			)}
		</Modal>
	)
}

export default VideoRecordingModal
