import type { LatLngExpression, LatLngTuple } from 'leaflet'
import Marker from '../index'
import ControllerMarkerIcon from './ControllerMarkerIcon'
import HomeMarkerIcon from './HomeMarkerIcon'
import HomeControllerClusterIcon from './HomeControllerClusterIcon'

type BeaconMarkerProps = {
	position: LatLngExpression
	color: string
	type: 'home' | 'controller' | 'cluster'
}

const BeaconMarker = ({ position, color, type }: BeaconMarkerProps) => {
	const Icon = () => {
		switch (type) {
			case 'home':
				return <HomeMarkerIcon color={color} />
			case 'controller':
				return <ControllerMarkerIcon color={color} />
			case 'cluster':
				return <HomeControllerClusterIcon color={color} />
			default:
				return null
		}
	}

	if (
		(position as LatLngTuple)[0] !== 0 &&
		(position as LatLngTuple)[1] !== 0 &&
		(position as LatLngTuple)[0] !== undefined &&
		(position as LatLngTuple)[1] !== undefined &&
		(position as LatLngTuple)[0] !== -9999 &&
		(position as LatLngTuple)[1] !== -9999
	)
		return (
			<Marker
				position={position}
				icon={<Icon />}
				iconSize={[40, 40]}
				iconAnchor={[19, 38]}
			/>
		)
}

export default BeaconMarker
