/* eslint-disable react/display-name */

type CustomSvgProps = {
	startX?: number
	endX?: number
	startY?: number
	endY?: number
	color?: string
	strokeWidth?: number
	type?: 'rect' | 'line'
}

type ScaleFunction = (value: number | Date) => number

type Scale = {
	xScale: ScaleFunction
	yScale: ScaleFunction
	innerHeight: number
}

const CustomSvgLayer = ({
	startX = 0,
	endX = 0,
	startY = 0,
	endY = 0,
	color = 'primary',
	type = 'rect',
	strokeWidth = 5,
}: CustomSvgProps) => {
	if (type === 'rect') {
		return ({ xScale, yScale }: Scale) => {
			// Convert scale values to SVG coordinates
			const svgStartX = xScale(startX)
			const svgEndX = xScale(endX)
			const svgStartY = yScale(startY)
			const svgEndY = yScale(endY)

			// Calculate the width and height of the rectangle
			const width = svgEndX - svgStartX
			const height = Math.abs(svgEndY - svgStartY)

			// Use the smaller y-value as the starting point
			const yStart = Math.min(svgStartY, svgEndY)

			return (
				<rect
					x={svgStartX}
					y={yStart}
					width={width}
					height={height}
					fill={color}
				/>
			)
		}
	} else {
		return ({ xScale, yScale, innerHeight }: Scale) => {
			const svgStartX = xScale(startX)
			const svgStartY = yScale(1)
			const svgEndX = xScale(endX)
			const svgEndY = yScale(-1)

			return (
				<line
					x1={svgStartX}
					y1={innerHeight - svgStartY}
					x2={svgEndX}
					y2={innerHeight - svgEndY}
					stroke={color}
					strokeWidth={strokeWidth}
				/>
			)
		}
	}
}

export default CustomSvgLayer
