// React
import { useParams } from 'react-router-dom'
// Components
import { DsxSector, RfSensorSector } from '@Components/Sectors'
import SentryMarker from '@Components/Markers/SentryMarker/SentryMarker'
import RfFilterSector from '@Components/Sectors/RfFilterSector/RfFilterSector'
import DsxFilterSector from '@Components/Sectors/DsxFilterSector/DsxFilterSector'
import Detections from '@Components/Site/MapLayers/Detections'
// Redux
import { useGetSiteInstallationQuery } from '@Store/sites/sitesApi'
import { useAppSelector } from '@/store'
import {
	selectSelectedRfFiltersIds,
	selectSensorPreview,
} from '@Store/ui/uiSlice'
// Types
import type {
	RfSensor_Models,
	RfFiltersPreview as RfFiltersPreviewType,
} from '@Store/types'
// Hooks
import useCenterMap from './useCenterMap'
import { useGetRfSensorFiltersQuery } from '@Store/rfFilters/rfFiltersApi'

const RfFilterBearingPreview = () => {
	const { siteId: siteIdParam, sensorId: sensorIdParam } = useParams()
	const siteId = Number(siteIdParam)
	const sensorId = Number(sensorIdParam)

	// Sensor details
	const sensorPreview = useAppSelector(
		selectSensorPreview
	) as RfFiltersPreviewType
	const installationId = sensorPreview?.sentryId

	// Installation details
	const { isSuccess, data: installation } = useGetSiteInstallationQuery(
		{ siteId, installationId },
		{
			skip: !sensorPreview || !installationId || !siteId,
		}
	)

	// Filters
	const { data: rfFilters, isSuccess: isFilterSuccess } =
		useGetRfSensorFiltersQuery({
			siteId,
			sensorId,
		})
	const selectedFilters = useAppSelector(selectSelectedRfFiltersIds)

	// Temporarily center the map
	useCenterMap({
		latitude: installation?.latitude,
		longitude: installation?.longitude,
	})

	const isDsx = sensorPreview?.model?.includes('dsx')

	if (isSuccess && installation && sensorPreview) {
		const sensorBearing =
			(installation?.direction + sensorPreview.directionOffset) % 360

		const rfSectorsWithBearings = rfFilters
			?.filter(
				(sector) =>
					sector.active &&
					sector.bearing !== undefined &&
					sector.bearing_tolerance !== undefined
			)
			.map((sector) => ({
				...sector,
				bearing: Number(sector?.bearing) + sensorBearing,
				angle: Number(sector?.bearing_tolerance) * 2,
			}))

		const dsxSectorsWithBearing = rfFilters?.filter(
			(sector) =>
				sector.active &&
				sector.bearing !== undefined &&
				sector.bearing_tolerance !== undefined
		)

		return (
			<>
				<Detections siteId={siteId} />
				<SentryMarker
					name={installation.name}
					position={[installation.latitude, installation.longitude]}
					sentryType={installation.sentry_type}
					iconColor={installation.status_color}
				/>
				{!isDsx && (
					<>
						<RfSensorSector
							model={sensorPreview.model as RfSensor_Models}
							latitude={installation.latitude}
							longitude={installation.longitude}
							bearing={sensorBearing}
							reach={sensorPreview.reach}
							isActive={false}
						/>
						{isFilterSuccess &&
							rfSectorsWithBearings?.map((sector) => (
								<RfFilterSector
									key={sector.id}
									latitude={installation.latitude}
									longitude={installation.longitude}
									reach={sensorPreview.reach}
									bearing={sector.bearing}
									angle={sector.bearing_tolerance}
									isActive={selectedFilters.some((id) => id === sector?.id)}
								/>
							))}
					</>
				)}
				{isDsx && (
					<>
						<DsxSector
							latitude={installation.latitude}
							longitude={installation.longitude}
							reach={sensorPreview.reach}
							isActive={false}
						/>
						{isFilterSuccess &&
							dsxSectorsWithBearing?.map((sector) => (
								<DsxFilterSector
									key={sector.id}
									latitude={installation.latitude}
									longitude={installation.longitude}
									reach={sensorPreview.reach}
									tolerance={sector.bearing_tolerance}
									bearing={sensorBearing}
									angle={sector.bearing}
									isActive={selectedFilters.some((id) => id === sector?.id)}
								/>
							))}
					</>
				)}
			</>
		)
	} else return null
}

export default RfFilterBearingPreview
