// React
import { useTranslation } from 'react-i18next'
// Chakra
import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	NumberInput,
	NumberInputField,
} from '@chakra-ui/react'
// Components
import Tooltip from '@UI/Tooltip/Tooltip'
// Types
import type { FC } from 'react'
import type { RegisterType } from '@/components/FormElements/types/RegisterType'
import type { FormElementProps } from '@Components/FormElements/types/FormElementProps'

type LatLongInputProps = FormElementProps & {
	defaultValue?: number
	type: 'latitude' | 'longitude'
	value?: number
	onChange?: (value: string) => void
} & RegisterType<string>

// TODO: needs onBlur for isDirty support
const LatLongInput: FC<LatLongInputProps> = ({
	id = undefined,
	title,
	type,
	defaultValue = 0,
	error,
	register,
	disabled = undefined,
	testId,
	tooltip,
	value = undefined,
	onChange = undefined,
}) => {
	const htmlId = id ?? register?.name ?? type

	const { t } = useTranslation('forms', {
		keyPrefix: 'global.validation',
	})

	const validateLatLon = (value: string, type: 'latitude' | 'longitude') => {
		const isLat = type === 'latitude'
		const reg = /^-?\d+(\.\d*)?$/
		if (isLat) {
			if (Number(value) < -90 || Number(value) > 90) return false
		}
		if (!isLat) {
			if (Number(value) < -180 || Number(value) > 180) return false
		}
		return reg.test(value)
	}

	const min = type === 'latitude' ? -90 : -180
	const max = type === 'latitude' ? 90 : 180

	const validValue = validateLatLon(String(value), type)

	const isInvalid = !!error || (!!value && !validValue)
	const errorMessage = !!value && !validValue ? t('range', { min, max }) : error

	return (
		<Tooltip label={tooltip} type='info'>
			<FormControl isInvalid={isInvalid} isDisabled={disabled}>
				<FormLabel htmlFor={htmlId} aria-labelledby={title}>
					{title}
				</FormLabel>
				<NumberInput
					id={htmlId}
					defaultValue={defaultValue}
					min={min}
					max={max}
					precision={8}
					pattern='-*[0-9]*(.[0-9]+)?'
					onChange={onChange}
					value={value}
				>
					<NumberInputField {...(register || {})} data-testid={testId} />
				</NumberInput>
				<FormErrorMessage>{errorMessage}</FormErrorMessage>
			</FormControl>
		</Tooltip>
	)
}

export default LatLongInput
