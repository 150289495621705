import { createListenerMiddleware } from '@reduxjs/toolkit'
import type { RootState, AppDispatch } from '../store'

// Middlewares
import { addJwtRefreshMiddleware } from './jwtRefresh'
import { addSystemStatsMiddleware } from './systemStats'
import { addNetworkStatusMiddleware } from './networkStatus'
import { addCameraPersistenceMiddleware } from './cameraPersistence'
import { addUnauthorizedRtkQueryMiddleware } from './unauthorizedRtkQuery'
import { addSimulatorDemoMiddleware } from './simulatorDemo'
// TODO C2-8327
// import {	addAlertSocketMiddleware } from './alertsSocket'

// Middleware setup
const listenerMiddleware = createListenerMiddleware()
const appStartListening = listenerMiddleware.startListening.withTypes<
	RootState,
	AppDispatch
>()

// Initialise Middlewares
addJwtRefreshMiddleware(appStartListening)
addSystemStatsMiddleware(appStartListening)
addNetworkStatusMiddleware(appStartListening)
addCameraPersistenceMiddleware(appStartListening)
addUnauthorizedRtkQueryMiddleware(appStartListening)
addSimulatorDemoMiddleware(appStartListening)
// TODO C2-8327
// addAlertSocketMiddleware(appStartListening)

export type AppStartListening = typeof appStartListening

// Exports
export default listenerMiddleware.middleware
