import { z } from 'zod'

const notesFormFields = z.object({
	body: z.string().max(5000),
	name: z.string(),
})

export const notesFormSchema = notesFormFields

export type NotesSchema = z.infer<typeof notesFormSchema>

export const ADD_NOTE_DEFAULT_VALUES: NotesSchema = {
	body: '',
	name: '',
}
