import type { DetectionMarkerIconProps } from '.'

type BirdMarkerIconProps = {
	color: DetectionMarkerIconProps['color']
}

const BirdMarkerIcon = ({ color }: BirdMarkerIconProps) => {
	return (
		<svg
			width='40px'
			height='40px'
			viewBox='0 0 40 40'
			version='1.1'
			xmlns='http://www.w3.org/2000/svg'
			xmlnsXlink='http://www.w3.org/1999/xlink'
		>
			<g
				transform='translate(1.000000, 12.250000)'
				data-testid={`bird-icon-${color}`}
				fill={color}
			>
				<path d='M20.1262016,2.15005 C20.1262016,2.99005 19.5501016,5.53145 19.0941016,5.53145 C18.6381016,5.53145 18.0620016,2.99005 18.0620016,2.15005 C18.0620016,1.31005 18.6381016,0.62905 19.0941016,0.62905 C19.5501016,0.62905 20.1262016,1.31005 20.1262016,2.15005 Z' />
				<polygon points='19.0932016 0.11295 19.5401016 0.88705 18.6463016 0.88705' />
				<path d='M11.0486016,2.60455 C14.9705016,4.04945 17.8034016,3.38125 18.5774016,2.95125 C20.1255016,4.84335 21.6789016,9.10665 16.7249016,10.34515 C10.5325016,11.89335 10.0165016,9.05505 4.34007156,7.76495 C-0.201076442,6.73295 -0.304284442,5.61485 0.211751558,5.18475 C2.18991156,3.72265 7.12669156,1.15965 11.0486016,2.60455 Z' />
				<path d='M26.9514016,2.60455 C23.0295016,4.04945 20.3836016,3.38125 19.6095016,2.95125 C18.0614016,4.84335 16.3211016,9.10665 21.2751016,10.34515 C27.4675016,11.89335 27.9835016,9.05505 33.6599016,7.76495 C38.2011016,6.73295 38.3043016,5.61485 37.7882016,5.18475 C35.8101016,3.72265 30.8733016,1.15965 26.9514016,2.60455 Z' />
				<path d='M17.5456016,8.88555 L20.6419016,8.88555 L22.9641016,14.04595 C22.9641016,14.04595 20.8999016,15.59405 19.0938016,15.59405 C17.2876016,15.59405 15.4815016,13.78795 15.4815016,13.78795 L17.5456016,8.88555 Z' />
			</g>
		</svg>
	)
}

export default BirdMarkerIcon
