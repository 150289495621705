import { z, type TypeOf } from 'zod'
import { useForm, FormProvider } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

// Chakra
import { Center, Icon, Flex, Text } from '@chakra-ui/react'
import { MdLockOpen, MdError } from 'react-icons/md'

import Button from '@UI/Button/Button'
import Field from '@Components/FormElements'
import type { FormError } from '@Forms/types'

// RTK Query
import { useLoginMutation } from '@Store/auth/authApi'
import { useGetSystemInfoQuery, selectIsOnPrem } from '@Store/system/systemApi'

// Translations
import { useTranslation } from 'react-i18next'

import type { PageModes } from '@Pages/Login/Login'

type LoginFormProps = {
	handlePageMode: (mode: PageModes) => void
}

const loginFormSchema = z.object({
	username: z.string(),
	password: z.string(),
})

export const LoginForm = ({ handlePageMode }: LoginFormProps) => {
	const { t } = useTranslation('forms', { keyPrefix: 'loginForm' })

	type Schema = TypeOf<typeof loginFormSchema>

	const methods = useForm<Schema>({
		resolver: zodResolver(loginFormSchema),
		defaultValues: { username: '', password: '' },
	})
	const {
		register,
		formState: { errors },
		handleSubmit,
		setError,
	} = methods

	const [userLogin] = useLoginMutation()

	const handleLogin = async (data: Schema): Promise<void> => {
		try {
			await userLogin(data).unwrap()
		} catch (error: unknown) {
			setError('password', {
				type: 'custom',
				message: (error as FormError).data.error,
			})
		}
	}

	const {
		isOnPrem,
		isSuccess: isGetInfoSuccess,
		isError: isGetInfoError,
		refetch,
	} = useGetSystemInfoQuery(undefined, {
		selectFromResult: ({ data, isSuccess, isError }) => ({
			isSuccess,
			isError,
			isOnPrem: selectIsOnPrem(data),
		}),
	})

	return (
		<>
			<Flex direction='column' gap='28px' h='100%'>
				<Text
					fontSize='xl'
					color='label_color'
					fontWeight={500}
					alignSelf='center'
				>
					DroneSentry-C2
				</Text>
				{isGetInfoError && (
					<Flex gap={2} alignItems='center'>
						<Icon as={MdError} color='error' boxSize={5} />
						<Flex gap={1}>
							<Text fontSize='sm' fontWeight={500} alignSelf='center'>
								{t('siteInfoError')}
							</Text>
							<Button
								label={t('refresh')}
								testId='refresh'
								variant='text'
								onClick={refetch}
								color='primary'
							/>
						</Flex>
					</Flex>
				)}
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(handleLogin)}>
						<Field.TextInput
							title={t('username')}
							register={register('username')}
							error={errors?.username?.message}
							testId='username'
							disabled={!isGetInfoSuccess}
						/>
						<Field.PasswordInput
							title={t('password.title')}
							register={register('password')}
							error={errors?.password?.message}
							testId='password'
							disabled={!isGetInfoSuccess}
						/>
						{isGetInfoSuccess && !isOnPrem && (
							<Button
								variant='text'
								testId='forgot-password'
								onClick={() => handlePageMode('forgot')}
								mt={1}
								label={`${t('password.forgot')}?`}
							/>
						)}
						<Center mt='50px'>
							<Button
								leftIcon={<MdLockOpen />}
								testId='login'
								type='submit'
								isDisabled={!isGetInfoSuccess}
								label={t('login')}
								variant='outline'
							/>
						</Center>
					</form>
				</FormProvider>
			</Flex>
		</>
	)
}

export default LoginForm
