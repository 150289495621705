import { useTheme } from '@chakra-ui/react'
import type { RfSensor } from '@/store/types'

import Sector from '@Components/MapShapes/Sector/Sector'

type RfSensorSectorProps = {
	latitude: number
	longitude: number
	bearing?: number
	isActive: boolean
} & Pick<RfSensor, 'model' | 'reach'>

const RfSensorSector = ({
	model,
	latitude,
	longitude,
	bearing,
	reach = 0,
	isActive = false,
}: RfSensorSectorProps) => {
	const {
		semanticTokens: { colors },
	} = useTheme()
	const fillColor = colors.sectors.rfSensorFill
	const strokeColor = isActive
		? colors.sectors.highlight
		: colors.sectors.rfSensorStroke
	const strokeWeight = isActive ? 4 : 2

	// Rf Patrol
	// Single 360 degrees sector
	if (model === 'rf_patrol') {
		return (
			<Sector
				latitude={latitude}
				longitude={longitude}
				reach={reach}
				color={strokeColor}
				fillColor={fillColor}
				weight={strokeWeight}
				testId='rf-sector'
			/>
		)
	}

	// RFTwo
	// 45 degrees either side of sectorDirection for total 90 degrees
	else {
		return (
			<Sector
				latitude={latitude}
				longitude={longitude}
				angle={90}
				bearing={bearing}
				reach={reach}
				color={strokeColor}
				fillColor={fillColor}
				weight={strokeWeight}
				testId='rf-sector'
			/>
		)
	}
}

export default RfSensorSector
