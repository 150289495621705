import { useTranslation } from 'react-i18next'
import {
	Icon,
	Menu,
	MenuButton,
	MenuOptionGroup,
	MenuItemOption,
	MenuList,
} from '@chakra-ui/react'
import { MdFilterAlt } from 'react-icons/md'

type ThreatLevelSelectorProps = {
	disabled?: boolean
	value: number
	onChange: (value: number) => void
}

const ThreatLevelSelector = ({
	disabled = false,
	value = 0,
	onChange,
}: ThreatLevelSelectorProps) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'notifications.threatLevel',
	})
	return (
		<Menu
			placement='bottom-start'
			strategy='fixed'
			gutter={1}
			autoSelect={false}
		>
			<MenuButton disabled={disabled}>
				<Icon as={MdFilterAlt} boxSize={5} />
			</MenuButton>
			<MenuList>
				<MenuOptionGroup
					defaultValue={value.toString()}
					title={t('title')}
					type='radio'
				>
					<MenuItemOption
						key={0}
						value='0'
						onClick={() => onChange(0)}
						padding={2}
					>
						{t('options.all')} (0%)
					</MenuItemOption>
					<MenuItemOption
						key={1}
						value='0.2'
						onClick={() => onChange(0.2)}
						padding={2}
					>
						{t('options.low')} (20%)
					</MenuItemOption>
					<MenuItemOption
						key={2}
						value='0.4'
						onClick={() => onChange(0.4)}
						padding={2}
					>
						{t('options.lowMedium')} (40%)
					</MenuItemOption>
					<MenuItemOption
						key={3}
						value='0.6'
						onClick={() => onChange(0.6)}
						padding={2}
					>
						{t('options.medium')} (60%)
					</MenuItemOption>
					<MenuItemOption
						key={4}
						value='0.8'
						onClick={() => onChange(0.8)}
						padding={2}
					>
						{t('options.high')} (80%)
					</MenuItemOption>
				</MenuOptionGroup>
			</MenuList>
		</Menu>
	)
}

export default ThreatLevelSelector
