import { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
	useReactTable,
	getCoreRowModel,
	type SortingState,
	type PaginationState,
} from '@tanstack/react-table'
import { Flex } from '@chakra-ui/react'

import { useGetDisruptionsSummaryQuery } from '@Store/analytics/analyticsApi'
import {
	useGetSiteExportQuery,
	selectSiteExportAllDisruptors,
} from '@Store/sites/sitesApi'
import { useGetUsersListQuery } from '@Store/user/usersApi'

import FetchError from '@UI/FetchError/FetchError'
import Headings from '@UI/Headings/Headings'
import Button from '@UI/Button/Button'
import { MdDownload } from 'react-icons/md'

import TanStackTable from '@UI/TanStackTable/Table'
import Pagination from '@UI/TanStackTable/Pagination'
import Skeleton from '@UI/Skeleton/Skeleton'

import { getDuration, formatDateTz } from '@Utils/dates'
import { getSorting } from '@Utils/tables'

import { type DisruptionRow, SORTABLE_COLUMNS } from './columnDefinitions'

import { downloadCsv, fetchFile } from '@Utils/download'
import useDisruptionColumns from '@Pages/Analytics/DisruptionsSummary/DisruptionDetailsTable/useDisruptionColumns'

type DisruptionDetailsTableProps = {
	siteId: number
	startTime: string
	endTime: string
	siteTimeZone: string
}

const emptyResult = [] as DisruptionRow[]

const DisruptionDetailsTable = ({
	startTime,
	endTime,
	siteId,
	siteTimeZone,
}: DisruptionDetailsTableProps) => {
	const { t } = useTranslation('pages', {
		keyPrefix: 'analytics.disruptionsSummary',
	})

	const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 10,
	})
	const [sorting, setSorting] = useState<SortingState>([
		{ id: 'id', desc: true },
	])

	const pagination = useMemo(
		() => ({
			pageIndex,
			pageSize,
		}),
		[pageIndex, pageSize]
	)

	const {
		isSuccess,
		isError,
		isLoading,
		isFetching,
		refetch,
		disruptions,
		totalCount,
	} = useGetDisruptionsSummaryQuery(
		{
			siteId: Number(siteId),
			format: 'table',
			startTime: startTime,
			endTime: endTime,
			page: pageIndex,
			perPage: pageSize,
			...getSorting(sorting),
		},
		{
			selectFromResult: ({
				isSuccess,
				isError,
				isLoading,
				isFetching,
				data,
			}) => ({
				isSuccess,
				isError,
				isLoading,
				isFetching,
				disruptions: data?.records,
				totalCount: data?.meta.total,
			}),
		}
	)

	const { disruptors } = useGetSiteExportQuery(siteId, {
		selectFromResult: ({ data, isSuccess }) => ({
			disruptors: selectSiteExportAllDisruptors(data),
			isSuccess,
		}),
	})

	const { data: users } = useGetUsersListQuery()

	const getUser = (userId: number, isManual: boolean) => {
		if (!isManual) {
			return t('automatic')
		}
		const user = users?.find((user) => user.id === userId)

		return user ? user.name : t('manual')
	}

	const tableData: DisruptionRow[] = (disruptions ?? []).map((disruption) => {
		const disruptor = disruptors?.find((disruptor) => {
			if ('Cannon' in disruptor) {
				return disruptor.Cannon?.id === disruption.cannon_id
			}
			return disruptor.id === disruption.cannon_id
		})

		const {
			id,
			activating_user_id,
			activated_manually,
			activated_at,
			deactivated_manually,
			deactivated_at,
		} = disruption

		return {
			id,
			activated_at: formatDateTz(activated_at, siteTimeZone),
			deactivated_at: formatDateTz(deactivated_at, siteTimeZone),
			disruptor: disruptor?.name ?? '',
			serial_number: disruptor?.serial_number ?? '',
			activated_by: getUser(activating_user_id, activated_manually),
			deactivated_by: getUser(activating_user_id, deactivated_manually),
			duration: getDuration(activated_at, deactivated_at),
		}
	})

	const pageCount = totalCount ? Math.ceil(totalCount / pageSize) : 0
	const columns = useDisruptionColumns()

	const table = useReactTable({
		columns,
		data: tableData ?? emptyResult,
		getCoreRowModel: getCoreRowModel(),
		pageCount: pageCount ?? -1,
		state: {
			pagination,
			sorting,
		},
		onPaginationChange: setPagination,
		onSortingChange: setSorting,
		manualPagination: true,
		manualSorting: true,
		enableSortingRemoval: false,
	})

	const handleDownloadReport = async () => {
		try {
			const url = `/api/reports/${siteId}/cannon_disruptions/csv`
			const data = await fetchFile(url, {
				start_time: startTime,
				end_time: endTime,
			})
			downloadCsv(data, `disruptions_summary_${startTime}_to_${endTime}.csv`)
		} catch (error) {
			console.error('TODO: unhandled download disruptions report error', error)
		}
	}

	return (
		<Skeleton isLoaded={!isLoading && !isFetching && isSuccess}>
			<>
				<Flex justifyContent='space-between' alignItems='center'>
					<Headings.ChartHeading title={t('disruptionDetails')} />
					<Flex alignItems='center'>
						<Headings.FieldHeading title={t('report')} />
						<Button
							label={t('download')}
							testId='download'
							size='sm'
							leftIcon={<MdDownload />}
							onClick={handleDownloadReport}
						/>
					</Flex>
				</Flex>
				<TanStackTable table={table} sortableColumns={SORTABLE_COLUMNS} />
				<Pagination table={table} totalCount={totalCount ?? 0} />
			</>

			{isError && <FetchError refetch={refetch} entity={t('entities.table')} />}
		</Skeleton>
	)
}

export default DisruptionDetailsTable
