// TODO: Get data to implement the video recording within the event replay
import { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Center, Spinner } from '@chakra-ui/react'

import Map from '@Components/Map/Map'
import SentryMarker from '@Components/Markers/SentryMarker/SentryMarker'
import SiteMarker from '@Components/Markers/SiteMarker/SiteMarker'
import Zones from '@Components/Site/MapLayers/Zones'

import VideoControls from '@UI/VideoPlayer/VideoControls'
import VideoPlayer from '@UI/VideoPlayer/VideoPlayer'

import EventReplayDetections from './EventReplayDetection'
import EventReplayPanel from './EventReplayPanel'

import {
	useGetEventSummaryQuery,
	useGetVideoRecordingsQuery,
} from '@Store/analytics/analyticsApi'

import { getInstallationLocation } from '@Utils/analytics'
import FetchError from '@UI/FetchError/FetchError'

import type { Detection, VideoRecording } from '@Store/types'
import type { Dispatch, Ref, SetStateAction } from 'react'
import { getDurationSeconds } from '@Utils/dates'

type EventReplayProps = {
	eventId: number
	siteId: number
	currentTime: number
	setCurrentTime: (time: number) => void
	isPlaying: boolean
	setIsPlaying: Dispatch<SetStateAction<boolean>>
	setIsMapReady: (isReady: boolean) => void
	fullScreenRef: Ref<HTMLDivElement>
	isFullScreen: boolean
}

const EventReplay = ({
	eventId,
	siteId,
	currentTime,
	setCurrentTime,
	isPlaying,
	fullScreenRef,
	isFullScreen,
	setIsPlaying,
	setIsMapReady,
}: EventReplayProps) => {
	const { t } = useTranslation('pages', {
		keyPrefix: 'analytics.trackSummary',
	})

	// const fullScreenRef = useRef<HTMLDivElement | null>(null)
	const [currentDetectionIndex, setCurrentDetectionIndex] = useState<number>(0)
	const [seekTime, setSeekTime] = useState<number>(0)

	const {
		data: event,
		isSuccess,
		isError,
		isLoading,
		refetch,
	} = useGetEventSummaryQuery({
		eventId,
	})

	const duration = event?.event.duration ?? 0
	const zones = event?.site.zones ?? []
	const eventCreatedAt = event?.event.created_at ?? ''
	// this is a hack to make the current events working without messing up with fetching the data
	// we need to fix this once we make the EventReplay to support moving sentries (which we don't at the moment)
	// here the ticket for reference: https://droneshield.atlassian.net/browse/C2-9697
	const installationsWithLocations = (event?.site.sentries ?? [])
		.map((sentry) => {
			const [firstLocation] = sentry.sentry_locations ?? []
			const { latitude = 0, longitude = 0 } = firstLocation ?? {}

			return { ...sentry, latitude, longitude }
		})
		.filter(({ latitude, longitude }) => latitude !== 0 && longitude !== 0)

	const recordingIds = (
		(event?.event.video_recordings as VideoRecording[]) ?? []
	).map((recording) => recording.id)

	const { data: recordings } = useGetVideoRecordingsQuery(
		{ recordingIds },
		{
			skip: recordingIds.length < 1,
		}
	)

	const videoRecordings = (recordings ?? []).map((recording) => ({
		url: recording.url,
		startTime: getDurationSeconds(eventCreatedAt, recording.created_at),
		duration: recording.duration / 1000,
	}))

	const activeVideo = videoRecordings.find(
		({ startTime, duration }) =>
			currentTime > startTime && currentTime < startTime + duration
	)

	const setModifiedSeekTime = (value: number) => {
		if (activeVideo) {
			const videoSeekTime = value - activeVideo.startTime
			setSeekTime(videoSeekTime > 0 ? videoSeekTime : 0)
		}
	}

	const detections = useMemo(
		() =>
			event?.tracks.map((track) => ({
				...track,
				installationLocation: getInstallationLocation(
					track,
					event?.site.sentries ?? []
				),
			})),
		[event?.site.sentries, event?.tracks]
	) as Detection[]

	// Handles the incrementation of detections
	useEffect(() => {
		const numberOfDetections = detections?.length
		const timePerDetection = duration / numberOfDetections
		const newIndex = Math.floor(currentTime / timePerDetection)

		if (newIndex < numberOfDetections && newIndex !== currentDetectionIndex) {
			setCurrentDetectionIndex(newIndex)
		}
	}, [currentTime, detections?.length, duration, currentDetectionIndex])

	const detection = detections?.[currentDetectionIndex]

	return (
		<Box
			h={isFullScreen ? 'calc(100vh - 50px)' : 'calc(100vh - 200px)'}
			bgColor={isLoading ? 'input_bg' : undefined}
			position='relative'
		>
			{isError && (
				<FetchError refetch={refetch} errorMessage={t('fetchError')} />
			)}
			{isLoading && (
				<Center h='90%'>
					<Spinner
						boxSize={100}
						thickness='5px'
						speed='0.6s'
						color='gray.600'
					/>
				</Center>
			)}
			{isSuccess && (
				<>
					<Box h='calc(100% - 55px)' position='relative'>
						<Map
							center={[event.site.latitude, event.site.longitude]}
							whenReady={() => setIsMapReady(true)}
							id='event-replay-map'
							attributionControl={true}
							showMeasurementTools
						>
							<SiteMarker
								position={[event.site.latitude, event.site.longitude]}
							/>
							<EventReplayDetections
								event={event.event}
								detection={detection}
								detections={detections}
							/>
							{(installationsWithLocations ?? []).map((sentry) => (
								<SentryMarker
									key={sentry.id}
									name={sentry.name}
									position={[sentry.latitude, sentry.longitude]}
									sentryType={sentry.sentry_type}
									iconColor='green' // force green for now and fix it with: https://droneshield.atlassian.net/browse/C2-9699
								/>
							))}
							<Zones isEventReplay eventReplayZones={zones} siteId={siteId} />
						</Map>
						{activeVideo && (
							<Box position='absolute' bottom={0} left={7} zIndex={9999} my={3}>
								<VideoPlayer
									url={activeVideo.url}
									width='400px'
									height='200px'
									playing={isPlaying}
									seekTime={seekTime}
								/>
							</Box>
						)}
					</Box>
					<EventReplayPanel
						detection={detection}
						detections={detections}
						eventId={eventId}
					/>
					<VideoControls
						isPlaying={isPlaying}
						setIsPlaying={setIsPlaying}
						duration={duration}
						currentTime={currentTime}
						setCurrentTime={setCurrentTime}
						setSeekTime={setModifiedSeekTime}
						isDisabled={!isSuccess}
						ref={fullScreenRef}
					/>
				</>
			)}
		</Box>
	)
}

export default EventReplay
