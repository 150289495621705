// React
import { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDisclosure } from '@chakra-ui/react'
// Components
import EventDetailsModal from './EventDetailsModal/EventDetailsModal'
import ErrorBoundary from '@Components/App/ErrorHandling/ErrorBoundary'
// Store
import { useAppDispatch } from '@/store'
import { useGetEventSummaryQuery } from '@Store/analytics/analyticsApi'
import { addToast } from '@Store/ui/uiSlice'
import type { CoreApiError } from '@Store/utils/errorUtils'

const Track = () => {
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const { siteId: siteIdParam, eventId: eventIdParam } = useParams()
	const { isOpen, onOpen, onClose } = useDisclosure()

	const siteId = Number(siteIdParam)
	const eventId = Number(eventIdParam)

	const {
		data: eventSummary,
		isError: isTrackError,
		error: trackError,
	} = useGetEventSummaryQuery({ eventId }, { skip: !eventId || !siteId })

	const navigateToParent = useCallback(() => {
		navigate(`/${siteId}/analytics`, {
			state: null,
		})
	}, [navigate, siteId])

	const handleClose = async () => {
		onClose()
		navigateToParent()
	}

	useEffect(() => {
		onOpen()
	}, [onOpen])

	useEffect(() => {
		if (isTrackError) {
			dispatch(
				addToast({
					type: 'error',
					siteId,
					error: trackError,
					id: `track-object-${(trackError as CoreApiError).status}`,
				})
			)
			navigateToParent()
		}
	}, [trackError, dispatch, isTrackError, siteId, navigateToParent])

	return (
		<ErrorBoundary>
			<EventDetailsModal
				isOpen={isOpen}
				onClose={handleClose}
				siteId={siteId}
				eventId={eventId}
				eventSummary={eventSummary}
			/>
		</ErrorBoundary>
	)
}

export default Track
