export type DisruptionColumnType =
	| 'id'
	| 'disruptor'
	| 'serial_number'
	| 'activated_by'
	| 'deactivated_by'
	| 'zone'
	| 'activated_at'
	| 'deactivated_at'
	| 'duration'

export type DisruptionRow = {
	id: number
	activated_at: string
	deactivated_at: string
	disruptor: string
	serial_number: string
	activated_by: string
	deactivated_by: string
	duration: string
}

export const SORTABLE_COLUMNS: DisruptionColumnType[] = [
	'id',
	'activated_at',
	'deactivated_at',
]
