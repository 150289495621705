export const DEFAULT_VENDOR_LABELS = [
	'No Vendor',
	'FPV',
	'DJI',
	'DJI AUT XIA',
	'Skydio Inc',
	'Yuneec',
	'Parrot',
	'Xiaomi',
]
export const DURATION_LABELS = [
	'3 mins',
	'2 mins',
	'1 min',
	'> 4 mins',
	'< 1 min',
]
export const FREQUENCY_LABELS = [
	'2.4 GHz',
	'2.5 GHz',
	'5.8 GHz',
	'433 MHz',
	'915 MHz',
]

export const DEFAULT_PROTOCOL_LABELS = [
	'No Protocol',
	'OS OFDM',
	'WBFM',
	'WiFi',
	'LB OFDM',
	'ACCESS ACCST',
	'DJI Ocusync',
	'LightBridge FHSS',
	'LightBridge OFDM',
]
