import { Polyline } from 'react-leaflet'

import { useAppSelector } from '@Store/index'
import { selectTrackHistory } from '@Store/ui/uiSlice'
import type { Detection } from '@Store/types'

type DetectionTrackHistoryProps = {
	targetId: Detection['target_id']
	color: string
}

const DetectionTrackHistory = ({
	targetId,
	color,
}: DetectionTrackHistoryProps) => {
	const positions = useAppSelector(selectTrackHistory(targetId))
	return (
		positions &&
		positions.length > 0 && (
			<Polyline positions={positions} pathOptions={{ color, dashArray: '4' }} />
		)
	)
}

export default DetectionTrackHistory
