import { useParams } from 'react-router-dom'

// Redux
import { useGetSiteInstallationQuery } from '@Store/sites/sitesApi'
import { useGetRadarQuery } from '@Store/radars/radarsApi'
import { useGetRadarMasksQuery } from '@Store/radarMasks/radarMasksApi'

import Detections from '../Detections'
import SentryMarker from '@Components/Markers/SentryMarker/SentryMarker'
import { RadarSensorSector, RadarMaskSector } from '@Components/Sectors'

import useCenterMap from './useCenterMap'

type RadarMasksPreviewProps = {
	// Determines whether a mask is currently being added or edited
	isEdit?: boolean
}

const RadarMasksPreview = ({ isEdit }: RadarMasksPreviewProps) => {
	const {
		siteId: siteIdParam,
		sensorId: sensorIdParam,
		maskId: maskIdParam,
	} = useParams()
	const siteId = Number(siteIdParam)
	const sensorId = Number(sensorIdParam)
	const maskId = Number(maskIdParam)

	const { isSuccess: isRadarSuccess, data: radar } = useGetRadarQuery(
		{
			siteId,
			sensorId,
		},
		{
			skip: !sensorId || !siteId,
		}
	)

	const { isSuccess: isInstallationSuccess, data: installation } =
		useGetSiteInstallationQuery(
			{ siteId, installationId: radar?.sentry_id as number },
			{
				skip: !radar?.sentry_id || !siteId,
			}
		)

	const { data: radarMasks } = useGetRadarMasksQuery(
		{ siteId, sensorId: radar?.id as number },
		{
			skip: !radar?.id || !siteId,
		}
	)

	const activeMasks = radarMasks?.filter((mask) => mask.active)

	const staticMasks = isEdit
		? activeMasks?.filter((mask) => mask.id !== maskId)
		: activeMasks

	useCenterMap({
		latitude: installation?.latitude,
		longitude: installation?.longitude,
	})

	const isSuccess = isRadarSuccess && isInstallationSuccess

	if (isSuccess && radar && installation) {
		return (
			<>
				<Detections siteId={siteId} />
				<RadarSensorSector
					latitude={radar.latitude}
					longitude={radar.longitude}
					bearing={radar.direction}
					reach_max={radar.reach_max}
					reach_min={radar.reach_min}
					radar_az_min_search={radar.radar_az_min_search}
					radar_az_max_search={radar.radar_az_max_search}
					radar_az_min_track={radar.radar_az_min_track}
					radar_az_max_track={radar.radar_az_max_track}
					isActive={false}
				/>
				{staticMasks?.map((mask) => (
					<RadarMaskSector
						key={mask.id}
						latitude={radar.latitude}
						longitude={radar.longitude}
						bearing={radar.direction}
						azimuth_max={mask.azimuth_max}
						azimuth_min={mask.azimuth_min}
						range_max={mask.range_max}
						range_min={mask.range_min}
						isActive={mask.id === maskId}
					/>
				))}
				<SentryMarker
					name={installation.name}
					position={[installation.latitude, installation.longitude]}
					sentryType={installation.sentry_type}
					iconColor={installation.status_color}
				/>
			</>
		)
	} else return null
}

export default RadarMasksPreview
