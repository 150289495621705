import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Flex, Button } from '@chakra-ui/react'

type FallbackErrorMessageProps = {
	quiet?: boolean
	message?: string
	showBackButton?: boolean
	showReloadButton?: boolean
	showLocationReloadButton?: boolean
}

const FallbackErrorMessage = ({
	quiet = false,
	message,
	showBackButton = true,
	showReloadButton = false,
	showLocationReloadButton = false,
}: FallbackErrorMessageProps) => {
	const { t } = useTranslation('app', { keyPrefix: 'global' })
	const navigate = useNavigate()
	const location = useLocation()

	const handleGoBack = () => {
		// https://stackoverflow.com/a/66084680
		if (location.key !== 'default') navigate(-1)
		else navigate('/')
	}

	const handleReload = () => {
		navigate('/')
	}

	const handleLocationReload = () => {
		navigate(0)
	}

	return (
		!quiet && (
			<Flex mt={4} gap={2} pl={2} alignItems='center' bgColor='input_bg'>
				{message ? message : t('messages.errorOccurred')}{' '}
				{showBackButton && (
					<Button variant='plainlink' onClick={handleGoBack}>
						{t('buttons.back')}
					</Button>
				)}
				{showReloadButton && (
					<Button variant='plainlink' onClick={handleReload}>
						{t('buttons.reload')}
					</Button>
				)}
				{showLocationReloadButton && (
					<Button variant='plainlink' onClick={handleLocationReload}>
						{t('buttons.reload')}
					</Button>
				)}
			</Flex>
		)
	)
}

export default FallbackErrorMessage
