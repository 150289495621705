import FetchError from '@UI/FetchError/FetchError'
import Skeleton from '@UI/Skeleton/Skeleton'
import type { SkeletonProps } from '@chakra-ui/react'

type FormWrapperProps = {
	isEditForm: boolean
	isLoading: boolean
	isError: boolean
	isSuccess: boolean
	entity: string
	errorMessage?: string
	refetch: any
	children: React.ReactNode
} & SkeletonProps

const FormWrapper = ({
	isEditForm,
	isLoading,
	isError,
	isSuccess,
	entity,
	children,
	refetch,
	errorMessage,
	...props
}: FormWrapperProps) => {
	const isLoaded = !isEditForm ? true : !isLoading
	const showChildren = !isEditForm ? true : isSuccess

	return (
		<Skeleton isLoaded={isLoaded} {...props}>
			{isError && (
				<FetchError
					refetch={refetch}
					entity={entity}
					errorMessage={errorMessage}
				/>
			)}
			{showChildren && children}
		</Skeleton>
	)
}

export default FormWrapper
