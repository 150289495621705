import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
	Document,
	Page,
	Text,
	View,
	Image,
	StyleSheet,
} from '@react-pdf/renderer'

import { store } from '@Store/index'
import { analyticsApi } from '@Store/analytics/analyticsApi'
import { selectEventContributionSensors } from '@Store/analytics/analyticsSelectors'

import {
	formatEventId,
	formatFrequency,
	formatLatLng,
	formatDistance,
} from '@Utils/formatUtils'
import { formatDateWithMonth, getDate, getTime } from '@Utils/dates'

import type { Event } from '@Store/types/analytics'
import type { Detection, DetectionContributionSensor } from '@Store/types'

type ReportDetails = {
	heading: string
	value: string | number
}

const formatLocation = (latitude: number, longitude: number) => {
	if (formatLatLng(latitude) || formatLatLng(longitude)) {
		return `${formatLatLng(latitude) ?? '-'} ${formatLatLng(longitude) ?? '-'}`
	} else return '-'
}

const ReportTable = ({
	header,
	details,
}: {
	header: string
	details: ReportDetails[]
}) => (
	<View style={styles.table}>
		<View style={styles.tableHeader}>
			<Text style={styles.tableCell}>{header}</Text>
		</View>
		{details.map((row, index) => (
			<View key={index} style={styles.tableRow}>
				<View style={styles.tableColHeader}>
					<Text style={styles.tableCell}>{row.heading}</Text>
				</View>
				<View style={styles.tableCol}>
					<Text style={styles.tableCell}>{row.value}</Text>
				</View>
			</View>
		))}
	</View>
)

const EventReport = ({
	event,
	mapImage,
	detections,
}: {
	event: Event
	detections: Detection[]
	mapImage: string | undefined
}) => {
	const { t } = useTranslation('pages', {
		keyPrefix: 'analytics.trackSummary.reportPdf',
	})

	const [sensors, setSensors] = useState<DetectionContributionSensor[]>([])

	// TODO
	// Make this an ordinary useGetEventSummary hook with selectFromResult clause!??
	const fetchEventSummary = async (eventId: number) => {
		const result = await store
			.dispatch(
				analyticsApi.endpoints.getEventSummary.initiate({
					eventId,
				})
			)
			.unwrap()
			.then((data) => selectEventContributionSensors(data))
		return result
	}

	fetchEventSummary(event.id).then((contributionSensors) =>
		setSensors(contributionSensors)
	)

	const siteName = event.site.name
	const date = formatDateWithMonth(event.created_at)
	const sensorsList = sensors.map((sensor) => sensor.sensorName).join(', ')
	const minDistance = detections.reduce((min, detection) => {
		return Math.min(min, detection.site_distance)
	}, Infinity)

	const eventDetails = [
		{ heading: t('siteLocation'), value: event.site.name },
		{ heading: t('eventId'), value: formatEventId(event.target_id) || '-' },
		{
			heading: t('firstDetection'),
			value: `${getDate(event.start_time)} ${getTime(event.start_time)}`,
		},
		{
			heading: t('lastDetection'),
			value: `${getDate(event.end_time)} ${getTime(event.end_time)}`,
		},
		{ heading: t('numDetections'), value: event.num_detections ?? '-' },
		{
			heading: t('startLocation'),
			value: formatLocation(event.start_latitude, event.start_longitude),
		},
		{
			heading: t('endLocation'),
			value: formatLocation(event.end_latitude, event.end_longitude),
		},
		{
			heading: t('nearestDistance'),
			value: formatDistance(minDistance) || '-',
		},
		{ heading: t('sensors'), value: sensorsList || '-' },
	]

	const droneDetails = [
		{ heading: t('vendor'), value: event.vendor || '-' },
		{ heading: t('protocol'), value: event.protocol || '-' },
		{
			heading: t('frequency'),
			value: formatFrequency(Math.round(event.frequency_min / 1e5) / 10) || '-',
		},
		{ heading: t('macAddress'), value: event.mac_address || '-' },
	]

	const droneLocatorDetails = [
		{ heading: t('ssid'), value: '-' },
		{ heading: t('serialNumber'), value: event.drone_serial_number || '-' },
		{
			heading: t('homeLocation'),
			value:
				event.home_latitude && event.home_longitude
					? formatLocation(event.home_latitude, event.home_longitude)
					: '-',
		},
		{
			heading: t('gcsLocation'),
			value:
				event.gcs_latitude && event.gcs_longitude
					? formatLocation(event.gcs_latitude, event.gcs_longitude)
					: '-',
		},
	]

	return (
		<Document>
			<Page size='A4' style={styles.page}>
				<View style={{ gap: '8px' }}>
					<Text style={styles.heading}>{t('report')}</Text>
					<View style={styles.subheading}>
						<Text>{siteName}</Text>
						<View style={styles.divider} />
						<Text>{date}</Text>
					</View>
					<Text style={styles.text}>
						{t('description', { date, siteName })}
					</Text>
				</View>

				<View style={{ gap: '10px' }}>
					<ReportTable header={t('eventDetails')} details={eventDetails} />
					<ReportTable header={t('droneDetails')} details={droneDetails} />
					{event.drone_locator_confirmed && (
						<ReportTable
							header={t('droneLocatorDetails')}
							details={droneLocatorDetails}
						/>
					)}
					<Image src={mapImage} style={styles.image} />
				</View>
			</Page>
		</Document>
	)
}

export default EventReport

// Custom event report colors
const styles = StyleSheet.create({
	page: {
		padding: '24px',
		display: 'flex',
		flexDirection: 'column',
	},
	heading: { color: '#F57B15', fontSize: '16px', fontWeight: 600 },
	divider: { backgroundColor: '#F57B15', width: '3px', height: '14px' },
	subheading: {
		color: '#101820',
		fontSize: '12px',
		display: 'flex',
		flexDirection: 'row',
		gap: '4px',
	},
	text: { color: '#101820', fontSize: '10px', marginBottom: '16px' },
	table: {
		border: '1px solid #9E9FA2',
		borderBottomWidth: 0,
	},
	tableHeader: {
		backgroundColor: '#9E9FA2',
		color: 'white',
	},
	tableColHeader: {
		width: '25%',
		fontWeight: 500,
	},
	tableCol: {
		width: '75%',
		borderLeft: '1px solid #9E9FA2',
	},
	tableRow: {
		margin: 'auto',
		flexDirection: 'row',
		borderBottomWidth: 1,
		borderColor: '#9E9FA2',
	},
	tableCell: {
		height: '20px',
		padding: '4px',
		fontSize: '10px',
	},
	image: { width: '547px', height: '255px' },
})
