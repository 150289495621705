import { useParams } from 'react-router-dom'
import { Icon, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { MdMoreVert, MdDeleteSweep } from 'react-icons/md'
import { useTranslation } from 'react-i18next'

import {
	clearAllSiteAlerts,
	clearStaleSiteAlerts,
} from '@Store/sites/sitesWsApi'
import { useAppDispatch } from '@Store/index'

type ClearAlertsButtonProps = {
	disabled?: boolean
}

const ClearAlertsButton = ({ disabled = false }: ClearAlertsButtonProps) => {
	const { siteId: siteIdParam } = useParams()
	const siteId = Number(siteIdParam)
	const { t } = useTranslation('panels', { keyPrefix: 'notifications' })

	const dispatch = useAppDispatch()

	const handleClearAlerts = () => {
		dispatch(clearAllSiteAlerts(siteId))
	}

	const handleClearStaleAlerts = () => {
		dispatch(clearStaleSiteAlerts(siteId))
	}

	return (
		<Menu
			placement='bottom-start'
			strategy='fixed'
			gutter={1}
			autoSelect={false}
		>
			<MenuButton disabled={disabled}>
				<Icon as={MdMoreVert} boxSize={5} />
			</MenuButton>
			<MenuList>
				<MenuItem
					icon={<MdDeleteSweep size={16} />}
					onClick={handleClearAlerts}
					paddingBlock={2}
				>
					{t('clearAllAlertsButton')}
				</MenuItem>
				<MenuItem
					icon={<MdDeleteSweep size={16} />}
					onClick={handleClearStaleAlerts}
					paddingBlock={2}
				>
					{t('clearStaleAlertsButton')}
				</MenuItem>
			</MenuList>
		</Menu>
	)
}

export default ClearAlertsButton
