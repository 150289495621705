import { useTranslation } from 'react-i18next'
import {
	Box,
	Button,
	Flex,
	Icon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react'
import { MdInfo } from 'react-icons/md'

type UpdateModalProps = {
	isOpen: boolean
	onClose: () => void
	handleDelete: () => void
	name: string
	headerText?: string
	fromText?: string
	testId?: string
	modalBodyText?: string
}

const UpdateModal = ({
	// testId,
	isOpen,
	onClose,
	handleDelete,
	name,
	headerText,
	modalBodyText = '',
}: UpdateModalProps) => {
	const onConfirm = async () => {
		handleDelete()
		onClose()
	}

	const { t } = useTranslation('app', { keyPrefix: 'global' })
	const headerTitle = headerText || t('modals.updateDevice')

	return (
		<>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				variant='deleteConfirmation'
				isCentered
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						<Flex alignItems='center' gap='8px' data-testid='modal-title'>
							<Icon as={MdInfo} h={6} w={6} color='primary' />
							{headerTitle}
						</Flex>
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Box data-testid='modal-body'>
							{modalBodyText}
							{name && (
								<Text fontWeight='500' as='span' px={1}>
									{name}. {t('modals.proceed')}
								</Text>
							)}
						</Box>
					</ModalBody>
					<ModalFooter>
						<Button onClick={onConfirm} data-testid='confirm'>
							{t('buttons.confirm')}
						</Button>
						<Button
							mr={3}
							onClick={onClose}
							variant='ghost'
							data-testid='cancel'
						>
							{t('buttons.cancel')}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}

export default UpdateModal
