import { useEffect, useState, memo } from 'react'

import { Box } from '@chakra-ui/react'

import CustomSvgLayer from '@UI/Charts/CustomSvgLayer'
import ScatterPlot from '@UI/Charts/ScatterPlot'

import type { ScatterPlotRawSerie, ScatterPlotDatum } from '@nivo/scatterplot'
import type { CalibrationTilt, TiltResult } from '../../types'
import { useTranslation } from 'react-i18next'

const DiagonalLineLayer = (min: number, max: number) =>
	CustomSvgLayer({
		type: 'line',
		color: 'var(--c2-colors-charts-customGreen)',
		strokeWidth: 10,
		startX: min,
		endX: max,
	})

type CameraTiltChartProps = {
	tiltResult?: TiltResult
	calibrationTilt: CalibrationTilt
}

const CameraTiltChart = ({
	tiltResult,
	calibrationTilt,
}: CameraTiltChartProps) => {
	const { t } = useTranslation('panels', {
		keyPrefix: 'siteInstallations.cameraCalibration',
	})
	const [tiltData, setTiltData] = useState<
		ScatterPlotRawSerie<ScatterPlotDatum>[]
	>([{ id: 'tilt', data: [] }])

	useEffect(() => {
		if (calibrationTilt.x.length > 0 || calibrationTilt.y.length > 0) {
			const newCoordinate = {
				x: calibrationTilt.x[calibrationTilt.x.length - 1],
				y: calibrationTilt.y[calibrationTilt.y.length - 1],
			}

			setTiltData((prevCoordinates) => [
				{ id: 'tilt', data: [...prevCoordinates[0].data, newCoordinate] },
			])
		}
	}, [calibrationTilt.x, calibrationTilt.y])

	const calibrationMin = tiltResult?.min || -1
	const calibrationMax = tiltResult?.max || 1

	return (
		<Box h='310px' w='200px'>
			<ScatterPlot
				data={tiltData}
				yLabel={t('cameraTilt')}
				layers={[
					'grid',
					'axes',
					'mesh',
					DiagonalLineLayer(calibrationMin, calibrationMax),
					'nodes',
				]}
				xScale={{
					type: 'linear',
					min: calibrationMin,
					max: calibrationMax,
				}}
				xTickValues={[calibrationMin, 0, calibrationMax]}
				yScale={{ type: 'linear', min: -1, max: 1 }}
				margin={{ top: 10, right: 10, bottom: 25, left: 45 }}
			/>
		</Box>
	)
}

export default memo(CameraTiltChart)
