// React
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
// Dates
import { parseISO } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import enAU from 'date-fns/locale/en-AU'
// Chakra
import { Flex, Icon, Text } from '@chakra-ui/react'
import { MdWarning } from 'react-icons/md'
// Components
import Button from '@UI/Button/Button'
// Types
import type { SiteWarning } from '@Store/types/siteWarning'
import { useUpdateSiteWarningAsReadMutation } from '@Store/siteWarnings/siteWarningsApi'
import { titleCase } from '@Utils/stringUtils'

const SystemWarning = ({
	systemWarning,
	siteId,
	siteTimeZone,
}: {
	systemWarning: SiteWarning
	siteId: number
	siteTimeZone: string
}) => {
	const { t } = useTranslation('navigation', {
		keyPrefix: 'systemWarnings',
	})
	const { id, read, title, content, created_at } = systemWarning

	const date = useMemo(() => {
		if (created_at) {
			const parsedDate = parseISO(created_at)
			return formatInTimeZone(
				parsedDate,
				siteTimeZone,
				'yyyy-MM-dd HH:mm:ss zzz',
				{
					locale: enAU,
				}
			)
		}
	}, [created_at, siteTimeZone])

	const [updateWarningAsRead] = useUpdateSiteWarningAsReadMutation()

	const handleUpdateAsRead = async () => {
		try {
			await updateWarningAsRead({
				siteId,
				siteWarningId: id,
			}).unwrap()
		} catch (error) {
			// TODO: handle server-side errors if necessary
			console.error('Unhandled update Site Warning error', error)
		}
	}

	return (
		<Flex gap={2} p='16px 0' color={read ? 'headings' : 'white'}>
			<Flex w='24px' minW='24px'>
				<Icon as={MdWarning} boxSize={6} />
			</Flex>
			<Flex fontSize='sm' direction='column'>
				<Text fontWeight={500}>{titleCase(title)}</Text>
				<Text>{content}</Text>
				<Text fontSize='xs'>{date}</Text>
			</Flex>
			<Flex
				w='120px'
				minW='120px'
				justifyContent='flex-end'
				alignSelf='flex-start'
				ml='auto'
			>
				{!read && (
					<Button
						testId='acknowledge'
						label={t('buttons.acknowledge')}
						variant='outline'
						size='sm'
						onClick={handleUpdateAsRead}
					/>
				)}
			</Flex>
		</Flex>
	)
}

export default SystemWarning
