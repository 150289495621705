import { useCallback, useEffect, useState, useMemo } from 'react'
import type { LatLngExpression } from 'leaflet'
import { Polyline } from 'react-leaflet'

import { useTheme } from '@chakra-ui/react'
import { RxCross2 as CrossIcon } from 'react-icons/rx'
import { PiCircleBold as CircleIcon } from 'react-icons/pi'

import BaseMarker from '@Components/Markers'
import DetectionMarker from '@Components/Markers/DetectionMarker/DetectionMarker'
import RfDetectionSector from '@Components/Sectors/RfDetectionSector/RfDetectionSector'

import { getSpeedLeaderPosition } from '@Components/Site/MapLayers/Detections'

import type {
	Detection,
	Event,
	SimplifiedRfSensor,
	SimplifiedSiteInstallation,
} from '@Store/types'

import useMapBounds from '@Hooks/useMapBounds'
import {
	selectEventSiteAlwaysShowRf,
	selectEventRfSensors,
} from '@Store/analytics/analyticsSelectors'
import { useGetEventSummaryQuery } from '@Store/analytics/analyticsApi'

type DetectionPosition = {
	id: number
	lat: number
	lng: number
}

const getTrackBounds = (coordinates: DetectionPosition[]) =>
	coordinates.reduce(
		(acc, coord) => {
			if (coord.lat < acc[0].lat) acc[0] = coord
			if (coord.lng < acc[1].lng) acc[1] = coord
			if (coord.lat > acc[2].lat) acc[2] = coord
			if (coord.lng > acc[3].lng) acc[3] = coord
			return acc
		},
		[coordinates[0], coordinates[0], coordinates[0], coordinates[0]]
	)

const EventReplayDetections = ({
	event,
	detection,
	detections,
}: {
	event: Event
	detection: Detection
	detections: Detection[]
}) => {
	const {
		semanticTokens: { colors },
	} = useTheme()

	const eventId = event.id
	const [detectionTrackPositions, setDetectionTrackPositions] = useState<
		LatLngExpression[]
	>([])

	const { rfSensors, alwaysShowRf, site } = useGetEventSummaryQuery(
		{ eventId },
		{
			selectFromResult: ({ data }) => ({
				rfSensors: selectEventRfSensors(data),
				alwaysShowRf: selectEventSiteAlwaysShowRf(data),
				site: data?.site,
			}),
		}
	)

	const getRfSensor = useCallback(
		(rfSensorId: SimplifiedRfSensor['id']) =>
			rfSensors.find((sensor: SimplifiedRfSensor) => sensor.id === rfSensorId),
		[rfSensors]
	)

	const getInstallation = useCallback(
		(installationId: SimplifiedSiteInstallation['id'] | undefined) =>
			site?.sentries?.find(
				(installation) => installation.id === installationId
			),
		[site]
	)

	const getRfSensorAndItsLocation = useCallback(
		(rfSensorId: SimplifiedRfSensor['id']) => {
			const rfSensor = getRfSensor(rfSensorId)
			if (!rfSensor) {
				return false
			}

			const installation = getInstallation(rfSensor?.sentry_id)
			const installationLocations = installation?.sentry_locations || []
			const firstInstallationLocation = installationLocations[0]
			if (!firstInstallationLocation) {
				return false
			}

			// set as if the RfSensor is the locator of the installation
			let direction = firstInstallationLocation.direction
			if (
				installation &&
				(installation.locatable_id !== rfSensor.id ||
					installation.locatable_type !== 'RfSensor')
			) {
				// consider the direction offset instead
				direction =
					(firstInstallationLocation.direction + rfSensor.direction_offset) %
					360
			}

			return {
				sensor: rfSensor,
				latitude: firstInstallationLocation.latitude,
				longitude: firstInstallationLocation.longitude,
				direction,
			}
		},
		[getInstallation, getRfSensor]
	)

	const firstDetection = detections[0]
	const lastDetection = detections[detections.length - 1]

	const noPosition = [
		event.start_latitude,
		event.start_longitude,
		event.end_latitude,
		event.end_longitude,
	].every((p) => p === 0)
	const allDetectionPositions = useMemo(
		() =>
			detections.map((detection) => ({
				id: detection.id,
				lat: detection.latitude,
				lng: detection.longitude,
			})),
		[detections]
	)

	const trackBounds = useMemo(() => {
		const sitePosition = {
			lat: site?.latitude || 0,
			lng: site?.longitude || 0,
			id: site?.id || 0,
		}

		// center to site location if noPosition
		if (site && noPosition) {
			return [sitePosition]
		}

		const bounds = getTrackBounds(allDetectionPositions)
		if (site) {
			bounds.push(sitePosition)
		}
		return bounds
	}, [allDetectionPositions, site, noPosition])

	// Center the map to the bounds of the detections
	useMapBounds({
		coordinates: trackBounds,
	})

	useEffect(() => {
		const upToCurrentPosition = allDetectionPositions.filter(
			(position) => position.id <= detection.id
		)
		setDetectionTrackPositions(upToCurrentPosition)
	}, [detection.id, allDetectionPositions])

	return (
		<>
			{!noPosition && (
				<>
					<BaseMarker
						position={[firstDetection.latitude, firstDetection.longitude]}
						icon={
							<CircleIcon color={colors.detections.default} fontSize='24px' />
						}
						iconAnchor={[13, 13]}
					/>
					<BaseMarker
						position={[lastDetection.latitude, lastDetection.longitude]}
						icon={
							<CrossIcon color={colors.detections.default} fontSize='30px' />
						}
						iconAnchor={[15, 15]}
					/>
					<Polyline
						positions={detectionTrackPositions}
						pathOptions={{ color: colors.detections.default }}
					/>
					<Polyline
						positions={allDetectionPositions}
						pathOptions={{ color: colors.detections.default, dashArray: '6' }}
					/>
					<DetectionMarker
						key={detection.id}
						targetId={detection.target_id}
						classification={detection.classification}
						latitude={detection.latitude}
						longitude={detection.longitude}
						speedLeaderPosition={getSpeedLeaderPosition(detection)}
						gcsPosition={[detection.gcs_latitude, detection.gcs_longitude]}
						homePosition={[detection.home_latitude, detection.home_longitude]}
						locationVariance={detection.location_variance}
						isWhitelisted={detection.state === 'whitelisted'}
						isSelected={true}
					/>
				</>
			)}
			{(alwaysShowRf || noPosition) &&
				!detection.raw_drone_locator_detection &&
				detection.detection_contributions
					.filter(
						({ sensor_type }) =>
							sensor_type === 'rfSensor' || sensor_type.includes('dsx')
					)
					.map((contribution) => {
						const sensorAndItsLocation = getRfSensorAndItsLocation(
							contribution.sensor_id
						)

						if (sensorAndItsLocation)
							return (
								<RfDetectionSector
									key={firstDetection.id}
									latitude={sensorAndItsLocation.latitude}
									longitude={sensorAndItsLocation.longitude}
									sensorBearing={sensorAndItsLocation.direction}
									model={sensorAndItsLocation.sensor.model}
									classification={firstDetection.classification}
									reach={sensorAndItsLocation.sensor.reach ?? 1000}
									aoa={contribution.aoa}
									aoaError={contribution.aoa_error}
									droneLocatorConfirmed={detection.drone_locator_confirmed}
									showSector={sensorAndItsLocation.sensor.show_sectors}
									showLine={sensorAndItsLocation.sensor.show_sector_as_line}
									showOutside={
										sensorAndItsLocation.sensor.show_outside_detections
									}
									showNoAoa={sensorAndItsLocation.sensor.show_720_detection}
									isWhitelisted={firstDetection.state === 'whitelisted'}
								/>
							)
						else return null
					})}
		</>
	)
}

export default EventReplayDetections
