import { useEffect, useRef } from 'react'
import L, { type LatLngExpression } from 'leaflet'
import { useMap } from 'react-leaflet'

type useCenterMapArgs = {
	coordinates: LatLngExpression[]
}

const useMapBounds = ({ coordinates }: useCenterMapArgs) => {
	const map = useMap()
	const hasSetInitialBounds = useRef(false)

	const maxZoom = map.getMaxZoom()

	useEffect(() => {
		if (map && coordinates.length > 1 && !hasSetInitialBounds.current) {
			// Initial bounds object
			let bounds = L.latLngBounds(coordinates[0], coordinates[0])

			coordinates.forEach((coord) => {
				bounds = bounds.extend(coord)
			})

			const options = {
				maxZoom,
			}

			map.fitBounds(bounds, options)
			hasSetInitialBounds.current = true
		}
	}, [map, coordinates, maxZoom])
}

export default useMapBounds
