import { isAnyOf } from '@reduxjs/toolkit'
import type { AppStartListening } from '@Store/middleware'
import { sitesApi } from '../sites/sitesApi'
import { sitesWsApi } from '../sites/sitesWsApi'
import { setActiveTargetId } from '../ui/uiSlice'
import { systemApi } from '../system/systemApi'
import type { SystemInfo } from '../types'

let isDemoSystem: boolean | undefined = undefined

const addSimulatorDemoMiddleware = (startListening: AppStartListening) => {
	startListening({
		matcher: isAnyOf(systemApi.endpoints.getSystemInfo.matchFulfilled),
		effect: (action) => {
			// determine if this is a demo system
			const { payload } = action
			isDemoSystem = (payload as SystemInfo).demo_system
		},
	})

	startListening({
		matcher: isAnyOf(sitesApi.endpoints.updateHeartbeat.matchFulfilled),
		effect: (action, { dispatch, getState }) => {
			const { payload, meta } = action
			const siteId = (meta as any)?.arg?.originalArgs?.siteId

			if (isDemoSystem) {
				// determine is a dbsim simulator running
				const isSimulatorRunning =
					(payload as unknown as { message: string }).message !==
					'Skip - Simulation not running'

				if (isSimulatorRunning) {
					if (siteId) {
						// fetch the getSiteLive state
						const state = getState()
						const result =
							sitesWsApi.endpoints.getSiteLive.select(siteId)(state)
						const { data } = result

						// pull out the latest new target_id
						const latestTargetId =
							data?.detections &&
							data?.detections.length > 0 &&
							data?.detections[data?.detections.length - 1]?.target_id

						// set the active detection
						if (latestTargetId) dispatch(setActiveTargetId(latestTargetId))
					}
				}
			}
		},
	})
}

// Exports
export { addSimulatorDemoMiddleware }
