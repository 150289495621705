import { defineStyleConfig } from '@chakra-ui/react'

const BadgeStyles = defineStyleConfig({
	baseStyle: {
		textTransform: 'unset',
		fontWeight: 500,
		fontSize: 'xs',
		backgroundColor: 'badge_bg',
		borderRadius: 4,
		padding: 1,
		paddingInline: 2,
	},
})

export const Badge = {
	...BadgeStyles,
}
