// React
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
// Components
import Field from '@Components/FormElements'
import { FormButtons } from '@Components/FormElements/FormButtons/FormButtons'
import UserFormSitesAssociation from '@Forms/UserForm/UserFormSitesAssociation'
import type { FormSelectValueType } from '@Components/FormElements/Select/Select'
// Forms
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
	userFormEditSchema,
	type UserEditSchema,
} from '@Forms/UserForm/UserForm.schema'
// Store
import { useUpdateUserMutation } from '@Store/user/usersApi'
import type { User } from '@Store/types'
import { useAppSelector } from '@/store'
import { selectUserSiteAssociations } from '@Store/ui/uiSlice'

const UserFormEdit = ({
	defaultValues,
	onClose,
	userId,
	refetch,
	clientsOptionsReady,
	clientsOptions,
	roleOptions,
}: {
	defaultValues: UserEditSchema
	onClose: () => void
	userId: number
	refetch: () => void
	clientsOptionsReady: boolean
	clientsOptions: FormSelectValueType[]
	roleOptions: FormSelectValueType[]
}) => {
	const { t } = useTranslation('forms', { keyPrefix: 'userForm' })
	const methods = useForm<UserEditSchema>({
		resolver: zodResolver(userFormEditSchema),
		defaultValues,
	})

	const {
		register,
		formState: { errors, isSubmitting, dirtyFields },
		handleSubmit,
		setError,
		watch,
		setValue,
	} = methods

	const [updateUser] = useUpdateUserMutation()

	// Clients

	const { client_id, role, site_associations } = watch()
	const isDirty = Object.values(dirtyFields).length > 0
	const isAdminRoleSelected = role === 'admin'
	const showClientsOptions = clientsOptionsReady && !isAdminRoleSelected

	const handleSave = async (payload: User | UserEditSchema) => {
		try {
			await updateUser({
				...{ ...payload, site_associations },
				userId: Number(userId),
			}).unwrap()
			refetch()
			onClose()
		} catch (errors) {
			// Surface server-side validation errors to react-hook-form
			for (const field in errors as {
				[name in keyof UserEditSchema]: string
			}) {
				setError(field as keyof UserEditSchema, {
					type: 'custom',
					message: (errors as { [name in keyof UserEditSchema]: string })[
						field as keyof UserEditSchema
					] as string,
				})
			}
		}
	}

	// Handle site associations
	const siteAssociations = useAppSelector(selectUserSiteAssociations)
	useEffect(() => {
		setValue('site_associations', siteAssociations, {
			shouldDirty: !!siteAssociations?.length,
		})
	}, [siteAssociations, setValue])

	const handleCancel = () => onClose()

	const isFormDirty = (() => {
		if (role === 'user' && !siteAssociations?.length) {
			return false
		}
		return isDirty
	})()

	return (
		<>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(handleSave)}>
					<Field.TextInput
						isRequired
						title={t('api.name')}
						register={register('name')}
						error={errors?.name?.message}
						testId='name'
					/>

					<Field.TextInput
						isRequired
						title={t('api.email')}
						register={register('email')}
						error={errors?.email?.message}
						testId='email'
					/>

					<Field.TextInput
						isRequired
						title={t('api.username')}
						register={register('username')}
						error={errors?.username?.message}
						testId='username'
					/>

					<Field.TextInput
						title={t('api.company')}
						register={register('company')}
						error={errors?.company?.message}
						testId='company'
					/>

					<Field.PhoneNumberInput
						title={t('api.phone')}
						register={register('phone')}
						countryCodeField={'phone_region'}
						error={errors?.phone?.message}
						testId='phone'
					/>

					{!!roleOptions.length && (
						<Field.Select
							title={t('headings.role')}
							register={register('role')}
							defaultValue={defaultValues?.role}
							options={roleOptions}
							error={errors?.role?.message}
							testId='role'
						/>
					)}

					{showClientsOptions && (
						<>
							<Field.Select
								title={t('headings.client')}
								options={clientsOptions}
								register={register('client_id', { valueAsNumber: true })}
								error={errors?.client_id?.message}
								testId='client'
							/>
						</>
					)}

					<UserFormSitesAssociation
						clientId={
							(client_id as User['client_id']) ||
							(Number(defaultValues.client_id) as User['client_id'])
						}
						role={role as User['role']}
						user={defaultValues as UserEditSchema}
					/>

					<FormButtons
						isSubmitting={isSubmitting}
						isDirty={isFormDirty}
						handleCancel={handleCancel}
					/>
				</form>
			</FormProvider>
		</>
	)
}

export default UserFormEdit
